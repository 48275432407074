import React, { useState } from "react";
import { AppBar, Grid, Button, Typography, Tooltip, Select, MenuItem } from '@mui/material'
import { Menu, Settings, Home, Error } from '@mui/icons-material'
import * as i18 from 'i18next'
import { GoogleLogout } from 'react-google-login'

const CLIENT_ID = '387159493695-djqsqn9ltpgi3lro5bljk23gqnlrnhee.apps.googleusercontent.com'

const MainAppBar = ({ stack, navigation, xs, app, appnav, scopes, scopeIndex, mainMenuIsOpen, showSettings, setScopeIndex, setMainMenuIsOpen, updateMe, updateScope }) => {

//	console.log('MainAppBar')

	const scopeDepth = scopes?.[scopeIndex]?.depth ? scopes?.[scopeIndex]?.depth : 0
	
	return (
		<Grid item id='main-view-appbar'>
			<AppBar position="static" color="inherit" >
				<Grid container direction="row" alignItems='center' >

					<Tooltip title={i18.t('main_show_main_menu_tooltip')} >
						<Button style={{margin: 0, padding: 0, minWidth: 30}} onClick={()=>setMainMenuIsOpen(!mainMenuIsOpen)}><Menu/></Button>
					</Tooltip>
					
					{ showSettings ?
						<Tooltip title={i18.t('main_back_to_main_tooltip')} >
							<Button style={{margin: 0, padding: 0}} size="small" onClick={()=>{navigation.reset('assignment_working_list')}}><Home/></Button>
						</Tooltip>
					: 
						<Tooltip title={i18.t('main_show_settings_tooltip')} >
							<Button onClick={()=>{navigation.reset('profile')}}><Settings/></Button>
						</Tooltip>						
					}
					
					{/* Scope selection */}
					{ scopes.length > 1 ?
						<Grid item>
							<Grid container direction='row' alignItems='center'>
								<Typography>{i18.t('main_scope_label')}</Typography>
								&nbsp;&nbsp;
								<Select 
									variant='standard'
									value={scopeIndex}
									onChange={(event)=>{setScopeIndex(event.target.value);updateScope(scopes[event.target.value]);}}
									renderValue={() => (<Typography style={{paddingLeft: 5, paddingRight: 5}}>{scopes[scopeIndex].name ? scopes[scopeIndex].name : scopes[scopeIndex].email}</Typography>)}
								>
									{scopes.map((scope1, index) => <MenuItem key={index} value={index} selected={index===scopeIndex} >{scope1.name ? scope1.name : scope1.email}</MenuItem> )}
								</Select>
								{ scopeIndex !== 0 ?
									<Grid item>
										<Grid container direction='row' alignItems='center'>
											&nbsp;&nbsp;
											<Typography>{i18.t('main_scope_depth')}</Typography>
											&nbsp;&nbsp;
											<Button size='small' style={{padding: 0}} color="primary" disabled={scopeDepth<=1} onClick={()=>{}} >{i18.t('main_scope_depth_decr')}</Button>
											<Typography>{scopeDepth}</Typography>
											<Button size='small' style={{padding: 0}} color="primary" disabled={scopeDepth>=99} onClick={()=>{}} >{i18.t('main_scope_depth_incr')}</Button>
										</Grid>
									</Grid>
								: null }
							</Grid>
						</Grid>
					: null }

					{ scopeIndex !== 0 ?
						<Typography style={{color: 'orange', paddingLeft: 10}} variant={xs ? "h8" : "h6"} >Scoped View</Typography>
					: null }
					
					<Typography noWrap style={{paddingLeft: 10}} variant={xs ? "h8" : "h6"} >{i18.t(`screen_${stack[stack.length - 1]?.screen}`)}</Typography>
					
					<Grid item  style={{flex: 1}} />

					{appnav.main?.errors ? 
						<Tooltip describeChild title={<Typography variant='h6'>{JSON.stringify(appnav.main?.errors)}</Typography>}>
							<Button color="secondary" onClick={()=>{}} >
								<Error/>
								{'Errors'}
							</Button>
						</Tooltip>											
					: null}
						
					<GoogleLogout
						clientId={CLIENT_ID}
						buttonText={i18.t('main_logout_label')}
						icon={xs ? false : true}
						onLogoutSuccess={()=>{
							// FYI - logout doesn't appear to work on localhost, but does work otherwise.
							localStorage.setItem('googleSession', null)
							window.location.reload()
						}}
						onFailure={(err) => {
							console.log(err)
						}}

					/>
					
				</Grid>
			</AppBar>
		</Grid>
	)
}

export default MainAppBar
