import React from 'react'
import { connect } from 'react-redux'
import Discussion from '../components/Discussion'
import { postComment } from '../reducers/comments'


const DiscussionView = ({ sourceType, sourceId, comments, disabled=false, postComment }) => {

//	console.log('DiscussionView')
	
	return (
		<Discussion
			comments={comments}
			disabled={disabled}
			postComment={(parentCommentId, comment, setStatus) => postComment(sourceType, sourceId, parentCommentId, comment, setStatus)}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	comments: state[`${props.sourceType}s`][props.sourceId]?.comments,
})

export default connect(mapStateToProps, { postComment })(DiscussionView)
