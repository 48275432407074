import React, { useState } from 'react'
import { FormControlLabel, Button, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Typography } from '@mui/material'
import * as i18 from 'i18next'


const TermsAndConditionsDialog = ({ me, acceptTerms }) => {

//	console.log('TermsAndConditionsDialog')
	
	if (!me) return null

	const [enableSubmit, setEnableSubmit] = useState(false)
	const [open, setOpen] = useState(!me?.terms?.acceptedDate)
	
	return (
		<Dialog maxWidth='sm' fullWidth open={open} >
			<DialogTitle><b>{i18.t('dialog_tandc_title')}</b></DialogTitle>
			<DialogContent>
				<Grid container direction={'column'} alignItems='flex-start' >
					<Typography>{i18.t('dialog_tandc_statement')}</Typography>
					<br/>
					<FormControlLabel
						control={<Checkbox onChange={(event)=>setEnableSubmit(event.target.checked)}/>}
						label={i18.t('dialog_accept_terms')}
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button disabled={!enableSubmit} onClick={()=>{acceptTerms(me.terms.version);setOpen(false)}} color="primary" variant="contained" >{i18.t('dialog_submit')}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default TermsAndConditionsDialog