import React, { useState } from 'react'
import { Grid, ListItem, Typography, Divider } from '@mui/material'
import * as i18 from 'i18next'

import KVPExtractionRule from '../components/extractionrules/KVPExtractionRule'
import TableExtractionRule from '../components/extractionrules/TableExtractionRule'
import CompositeExtractionRule from '../components/extractionrules/CompositeExtractionRule'
import PropertyView from '../containers/PropertyView'


const ExtractionRule = ({ org, orgProperties, rootparentId, property, properties, selectedProperty, highlightedRule, avgwordheight, designTime,
							setSelectedProperty, setHighlightedRule, setSelectionAttribute, setUpdateOverlays,
							updateProperty, changePropertyType, deleteProperty, deleteCompositeProperty, resetCompositeProperty,
							absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log("ExtractionRule", JSON.stringify(property.extractionRule))

	if (! rootparentId, !property || !property.extractionRule) return null

	const [hover, setHover] = useState()

	return (		
		<Grid
			item
			style={{backgroundColor: hover ? 'ghostwhite' : 'transparent'}}
			onMouseMove={() => setHover(true)}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>						
			{ designTime ?
				property.extractionRule.type === 'kvp' ?
					<KVPExtractionRule
						org={org}
						orgProperties={orgProperties}
						rootparentId={rootparentId}
						property={property}
						properties={properties}
						selectedProperty={selectedProperty}
						highlightedRule={highlightedRule}
						hover={hover}
						designTime={designTime}

						setSelectedProperty={setSelectedProperty}
						setSelectionAttribute={setSelectionAttribute}
						setUpdateOverlays={setUpdateOverlays}

						updateProperty={updateProperty}
						changePropertyType={changePropertyType}
						absoluteToProportionalRect={absoluteToProportionalRect}
						proportionalToAbsoluteRect={proportionalToAbsoluteRect}
					/>
				: property.extractionRule.type === 'vtable' ?
					<TableExtractionRule
						property={property}
						properties={properties}
						selectedProperty={selectedProperty}
						highlightedRule={highlightedRule}
						hover={hover}
						orgProperties={orgProperties}
						avgwordheight={avgwordheight}
						designTime={designTime}

						setSelectedProperty={setSelectedProperty}
						setSelectionAttribute={setSelectionAttribute}
						setUpdateOverlays={setUpdateOverlays}

						updateProperty={updateProperty}
						absoluteToProportionalRect={absoluteToProportionalRect}
						proportionalToAbsoluteRect={proportionalToAbsoluteRect}
					/>
				: property.extractionRule.type === 'object' ?
					<CompositeExtractionRule
						rootparentId={rootparentId}
						property={property}
						properties={properties}
						selectedProperty={selectedProperty}
						highlightedRule={highlightedRule}
						hover={hover}
						avgwordheight={avgwordheight}
						designTime={designTime}

						setSelectedProperty={setSelectedProperty}
						setHighlightedRule={setHighlightedRule}
						setSelectionAttribute={setSelectionAttribute}
						setUpdateOverlays={setUpdateOverlays}

						updateProperty={updateProperty}
						deleteProperty={deleteProperty}
						deleteCompositeProperty={deleteCompositeProperty}
						resetCompositeProperty={resetCompositeProperty}
						absoluteToProportionalRect={absoluteToProportionalRect}
						proportionalToAbsoluteRect={proportionalToAbsoluteRect}
					/>
				:
				null
			:
				<PropertyView 
					rootparentType={'extract'}
					rootparentId={rootparentId} 
					propertyId={property.propertyId}
					designTime={true}
				/>					
			}

			{/* If there are any validation errors - display them here. */}
			{(property.validationErrors && property.validationErrors.length > 0) ?
				property.validationErrors.map((err, index) => {
					if (err.warning)
						return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
								
					if (err.error)
						return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
				})
			: null }
			
			<Divider/>
			
		</Grid>	
	)
}

export default ExtractionRule