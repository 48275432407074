import { RECEIVE_ERRORS } from './actiontypes'
import { store } from '../App'


export const gqlInput = (states, tags, skip, includeItems=true, includeTotalCount=false, includeTagList=false) => {
	const scope = store.getState().app.scope
	let statesInput = states ? `states: ${JSON.stringify(states)},` : ''
	let tagFilter = tags ? `tagFilter: ${JSON.stringify(tags)},` : ''
	let scopeInput = scope && scope.orgentityId ? `scope: {orgentityId: "${scope.orgentityId}"},` : ''
	let skipInput = skip !== 0 ? `skip: ${skip},` : ``
	return `(${statesInput} ${tagFilter} ${scopeInput} ${skipInput} includeItems: ${includeItems}, includeTagList: ${includeTagList}, includeTotalCount: ${includeTotalCount})`
}

export const gqlIdInput = (sourceType, sourceId) => {
	let idInput = sourceId ? `${sourceType}Id: "${sourceId}"` : ``
	return idInput !== '' || scopeInput !== '' ? `(${idInput})` : ``
}

//const CLIENT_ID = '387159493695-djqsqn9ltpgi3lro5bljk23gqnlrnhee.apps.googleusercontent.com'

export const gqlExec = async (statement) => {

	let googleSession = JSON.parse(localStorage.getItem('googleSession'))

	try {

		if (!googleSession) {window.location.reload()}

		statement = statement.replace(/"(\w+)"\s*:/g, '$1:');
		const headers = {'Content-Type': 'application/json; charset=UTF-8', 'Authorization': googleSession?.access_token}
	
		let result = await fetch(`https://api.tournamentbuilders.com/graphql`, {
			method: "POST",
			body: JSON.stringify({query: statement}),
//			headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization': googleSession?.access_token, 'clientId': CLIENT_ID}, 
			headers: headers, 
		})

		result = await result.json()

// TBD - remove in production code
let temp = JSON.stringify(result)
if (temp?.length > 100000) console.log('Large object:', temp.length, temp.substring(0,400))

		if (!result || result?.errors) throw (result)
		
		return result.data

	} catch (err) {
console.log('here0', err?.name, err?.code, err?.message)
console.log('here1', err)
		if (err?.name === 'TypeError') {
			localStorage.setItem('googleSession', null)
		}
//		location.reload()
//		await refreshToken(googleSession)
//		store.dispatch({type: RECEIVE_ERRORS, errors: [err]})
		throw(err)
	}
}

export const workflowReturnFragment = `
	fragment workflowReturnFields on WorkflowReturnType {
		totalCount
		tagList
		items {
			...workflowFields
		}
	}
`
	
export const workflowMinFragment = `
	fragment workflowFields on WorkflowType {
		workflowId
		domain
		name
		state
	}
`
	
export const workflowMaxFragment = `
	fragment workflowFields on WorkflowType {
		workflowId
		domain
		templateWorkflowId
		name
		description
		imageref
		state
		properties {
			...propertyFields
			items {
				...propertyFields
				items {
					...propertyFields
				}
				properties {
					...propertyFields
				}
			}
			properties {
				...propertyFields
				items {
					...propertyFields
					items {
						...propertyFields
					}
					properties {
						...propertyFields
					}
				}
				properties {
					...propertyFields
					items {
						...propertyFields
						items {
							...propertyFields
						}
						properties {
							...propertyFields
						}
					}
					properties {
						...propertyFields
						items {
							...propertyFields
							items {
								...propertyFields
							}
							properties {
								...propertyFields
							}
						}
						properties {
							...propertyFields
							items {
								...propertyFields
								items {
									...propertyFields
								}
								properties {
									...propertyFields
								}
							}
							properties {
								...propertyFields
								items {
									...propertyFields
									items {
										...propertyFields
									}
									properties {
										...propertyFields
									}
								}
							}
						}
					}
				}
			}
		}
		attachments {
			...attachmentFields
		}
		instanceData
		defaultInstanceData
		tasks {
			...taskFields
			tasks {
				...taskFields
				tasks {
					...taskFields
					tasks {
						...taskFields
						tasks {
							...taskFields
						}
					}
				}
			}
		}
		taskinstances {
			...taskinstanceFields
			taskinstances {
				...taskinstanceFields
				taskinstances {
					...taskinstanceFields
					taskinstances {
						...taskinstanceFields
						taskinstances {
							...taskinstanceFields
						}
					}
				}
			}
		}
		comments {
			...commentFields
			comments {
				...commentFields
				comments {
					...commentFields
					comments {
						...commentFields
					}
				}
			}
		}
		tags
		validationErrors {error warning data}
		permissions {
			...permissionFields
		}
		created
		createdBy
		updated
		updatedBy
		version
	}
`
	
export const attachmentFragment = `
	fragment attachmentFields on DocumentAttachmentType {
		attachmentId
		name
		pname
		defaultDocumentId
		documentId
		validationErrors {error warning data}
	}
`

export const taskFragment = `
	fragment taskFields on TaskType {
		taskId
		type
		name
		instructions
		assignees {
			type
			propertyId
			assignees {
				...limitedOrgEntityFields
			}
			validationErrors {error warning data}
		}
		milestoneActions {
			...milestoneActionFields
		}
		parallelExecution
		routeCondition {
			...routeconditionFields
		}
		showCommentTab
		form {
			...formFields
		}
		validationErrors {error warning data}
	}`


/* Temporarily removed
		assignments {
			...assignmentFields
		}
*/
export const taskInstanceFragment = `
	fragment taskinstanceFields on TaskInstanceType {
		taskinstanceId
		taskId
		type
		state
		name
		instructions
		milestoneActions {
			...milestoneActionFields
		}
		routeCondition {
			...routeconditionFields
		}
		parallelExecution
		assignees {
			type
			propertyId
			assignees {
				...limitedOrgEntityFields
			}
		}
		assignments {
			...assignmentFields
		}
		showCommentTab		
		created
		updated
	}
`

export const commentFragment = `
	fragment commentFields on CommentType {
		commentId
		comment
		created
		createdBy {
			...limitedOrgEntityFields
		}
		version
	}`

export const assigneeFragment = `
	fragment assigneeFields on GranteeType {
		...limitedOrgEntityFields
	}
`

export const routeConditionFragment = `
	fragment routeconditionFields on RouteConditionType {
		quorum
		options {
			name
			type
			expression {
				...expressionFields
			}
			destination
			validationErrors {error warning data}
		}
		defaultOption {
			type
			destination
		}
		validationErrors {error warning data}
	}
`

export const expressionFragment = `
	fragment expressionFields on ExpressionType {
		...expressionBaseFields
		left {
			...expressionBaseFields
			left {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
			right {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
			params {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
		}
		right {
			...expressionBaseFields
			left {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
			right {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
			params {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
		}
		params {
			...expressionBaseFields
			left {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
			right {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
			params {
				...expressionBaseFields
				left {
					...expressionBaseFields
				}
				right {
					...expressionBaseFields
				}
				params {
					...expressionBaseFields
				}
			}
		}
	}
`

export const expressionBaseFragment = `
	fragment expressionBaseFields on ExpressionType {
		typeId
		type
		subType
		value
		operator
	}
`
	
export const assignmentReturnFragment = `	
	fragment assignmentReturnFields on AssignmentReturnType {
		totalCount
		tagList
		items {
			...assignmentFields
		}
	}
`

export const assignmentMinFragment = `	
	fragment assignmentFields on AssignmentType {
		assignmentId
		domain
		name
		state
	}
`

export const assignmentMaxFragment = `	
	fragment assignmentFields on AssignmentType {
		assignmentId
		domain
		workflowId
		linkId
		name
		instructions
		imageref
		routeCondition {
			...routeconditionFields
		}
		selectedRoute
		state
		properties {
			...propertyFields
			items {
				...propertyFields
				items {
					...propertyFields
				}
				properties {
					...propertyFields
				}
			}
			properties {
				...propertyFields
				items {
					...propertyFields
					items {
						...propertyFields
					}
					properties {
						...propertyFields
					}
				}
				properties {
					...propertyFields
					items {
						...propertyFields
						items {
							...propertyFields
						}
						properties {
							...propertyFields
						}
					}
					properties {
						...propertyFields
						items {
							...propertyFields
							items {
								...propertyFields
							}
							properties {
								...propertyFields
							}
						}
						properties {
							...propertyFields
							items {
								...propertyFields
								items {
									...propertyFields
								}
								properties {
									...propertyFields
								}
							}
							properties {
								...propertyFields
								items {
									...propertyFields
									items {
										...propertyFields
									}
									properties {
										...propertyFields
									}
								}
							}
						}
					}
				}
			}
		}
		attachments {
			...attachmentFields
		}
		instanceData
		form {
			...formFields
		}
		comments {
			...commentFields
			comments {
				...commentFields
				comments {
					...commentFields
					comments {
						...commentFields
					}
				}
			}
		}
		tags
		showCommentTab		
		permissions {
			...permissionFields
		}
		validationErrors {error warning data}
		created
		createdBy
		updated
		updatedBy
		version
	}
`

export const assignmentCompleteFragment = `	
	fragment assignmentCompleteFields on AssignmentType {
		assignmentId
		instanceData
		selectedRoute
		updated
		state
	}
`

export const formFragment = `
	fragment formFields on FormType {
		formId
		pages {
			...formItemFields
			items {
				...formItemFields
				style
				items {
					...formItemFields
					items {
						...formItemFields
						items {
							...formItemFields
							items {
								...formItemFields
							}
						}
					}
				}
			}
		}
	}
`

export const formItemFragment = `
	fragment formItemFields on FormItemType {
		type
		itemId
		header {
			type
			itemId
			value
			style
		}
		footer {
			type
			itemId
			value
			style
		}
		xs
		style
	}
`

export const orgentityReturnFragment = `	
	fragment orgentityReturnFields on OrgEntityReturnType {
		totalCount
		tagList
		items {
			...orgentityTopFields		
		}
	}
`

export const orgentityTopFragment = `	
	fragment orgentityTopFields on OrgEntityType {
		...orgentityFields		
		subEntities {
			...orgentityFields		
			subEntities {
				...orgentityFields						
				subEntities {
					...orgentityFields						
					subEntities {
						...orgentityFields						
					}
				}
			}
		}
	}
`

export const orgentityMinFragment = `	
	fragment orgentityFields on OrgEntityType {
		orgentityId
		name
		type
		domain
		principalId
		email
		terms {
			version
			acceptedDate
		}
		updated
		updatedBy
	}
`

export const orgentityMaxFragment = `	
	fragment orgentityFields on OrgEntityType {
		orgentityId
		name
		type
		domain
		principalId
		email
		settings {
			...settingFields
		}
		properties {
			...propertyFields
			items {
				...propertyFields
				items {
					...propertyFields
				}
				properties {
					...propertyFields
				}
			}
			properties {
				...propertyFields
				items {
					...propertyFields
					items {
						...propertyFields
					}
					properties {
						...propertyFields
					}
				}
				properties {
					...propertyFields
					items {
						...propertyFields
						items {
							...propertyFields
						}
						properties {
							...propertyFields
						}
					}
					properties {
						...propertyFields
						items {
							...propertyFields
							items {
								...propertyFields
							}
							properties {
								...propertyFields
							}
						}
						properties {
							...propertyFields
							items {
								...propertyFields
								items {
									...propertyFields
								}
								properties {
									...propertyFields
								}
							}
							properties {
								...propertyFields
								items {
									...propertyFields
									items {
										...propertyFields
									}
									properties {
										...propertyFields
									}
								}
							}
						}
					}
				}
			}
		}
		expressionTypes {
			...expressionFields
		}
		terms {
			version
			acceptedDate
		}
		permissions {
			...permissionFields
		}
		updated
		updatedBy
		version
	}
`

export const limitedOrgEntityFragment = `
	fragment limitedOrgEntityFields on GranteeType {
		orgentityId
		domain
		principalId
		email
	}
`

export const propertyFragment = `
	fragment propertyFields on PropertyType {
		propertyId
		name
		pname
		group
		description
		subType
		type
		control
		tags
		enum
		pattern
		placeholder
		mask
		uniqueItems
		minLength
		maxLength
		minItems
		maxItems
		minProperties
		maxProperties
		minimum
		maximum
		enabled
		extractionRule {
			...extractionruleFields
		}
		validationErrors {error warning data}
	}	
`	

export const propertyUpdateFragment = `
	fragment propertyUpdateFields on PropertyType {
		propertyId
		value
	}	
`	

export const extractionRuleFragment = `
	fragment extractionruleFields on ExtractionRuleType {
		type
		page
		key {
			rect {
				top
				left
				width
				height
			}
			words
		}
		value {
			rect {
				top
				left
				width
				height
			}
			words
		}
		numIterations
	}
`

export const settingFragment = `
	fragment settingFields on SettingType {
		name
		allowOrgOverride
		allowMemberOverride
		allowOrgUnitOverride
		group
		value
		valueOptions
	}
`

export const permissionFragment = `
	fragment permissionFields on PermissionType {
		grantee {
			...limitedOrgEntityFields
		}
		grant
	}
`

export const permissionDelegateFragment = `
	fragment permissionDelegateFields on PermissionDelegateType {
		delegateType
		delegateId
		grant
	}
`

export const extractReturnFragment = `
	fragment extractReturnFields on ExtractReturnType {
		totalCount
		tagList
		items {
			...extractFields
		}
	}
`
	
export const extractMinFragment = `
	fragment extractFields on ExtractType {
		extractId
		name
		type
		documentId
		tags
	}	
`	

export const extractMaxFragment = `
	fragment extractFields on ExtractType {
		extractId
		name
		type
		documentId
		properties {
			...propertyFields
			items {
				...propertyFields
				items {
					...propertyFields
				}
				properties {
					...propertyFields
				}
			}
			properties {
				...propertyFields
				items {
					...propertyFields
					items {
						...propertyFields
					}
					properties {
						...propertyFields
					}
				}
				properties {
					...propertyFields
					items {
						...propertyFields
						items {
							...propertyFields
						}
						properties {
							...propertyFields
						}
					}
					properties {
						...propertyFields
						items {
							...propertyFields
							items {
								...propertyFields
							}
							properties {
								...propertyFields
							}
						}
						properties {
							...propertyFields
							items {
								...propertyFields
								items {
									...propertyFields
								}
								properties {
									...propertyFields
								}
							}
							properties {
								...propertyFields
								items {
									...propertyFields
									items {
										...propertyFields
									}
									properties {
										...propertyFields
									}
								}
							}
						}
					}
				}
			}
		}
		extractdata
		comments {
			...commentFields
			comments {
				...commentFields
				comments {
					...commentFields
					comments {
						...commentFields
					}
				}
			}
		}
		tags
		permissions {
			...permissionFields
		}
		validationErrors {error warning data}
		created
		createdBy
		updated
		updatedBy
		version
	}	
`	

export const supportcaseReturnFragment = `
	fragment supportcaseReturnFields on SupportCaseReturnType {
		totalCount
		tagList
		items {
			...supportcaseFields
		}
	}
`

export const supportcaseMinFragment = `
	fragment supportcaseFields on SupportCaseType {
		supportcaseId
		summary
		state
		severity
	}	
`

export const supportcaseMaxFragment = `
	fragment supportcaseFields on SupportCaseType {
		supportcaseId
		summary
		detail
		state
		severity
		comments {
			...commentFields
			comments {
				...commentFields
				comments {
					...commentFields
					comments {
						...commentFields
					}
				}
			}
		}
		tags
		permissions {
			...permissionFields
		}
		created
		createdBy
		updated
		updatedBy
		version
	}	
`

export const automationReturnFragment = `
	fragment automationReturnFields on AutomationReturnType {
		totalCount
		tagList
		items {
			...automationFields
		}
	}	
`	

export const automationMinFragment = `
	fragment automationFields on AutomationType {
		automationId
		name
		description
		state
	}	
`	

export const automationMaxFragment = `
	fragment automationFields on AutomationType {
		automationId
		name
		description
		state
		eventId
		eventData
		actionId
		actionData
		comments {
			...commentFields
			comments {
				...commentFields
				comments {
					...commentFields
					comments {
						...commentFields
					}
				}
			}
		}
		tags
		permissions {
			...permissionFields
		}
		validationErrors {error warning data}
		created
		createdBy
		updated
		updatedBy
		version
	}	
`	

export const eventFragment = `
	fragment eventFields on EventType {
		eventId
		sourceTypes
		type
	}	
`	

export const milestoneActionFragment = `
	fragment milestoneActionFields on MilestoneActionType {
		actionId
		type
		expression {
			...expressionFields
		}
	}	
`	

export const actionFragment = `
	fragment actionFields on ActionType {
		actionId
		targetTypes
		type
	}	
`	

export const versionFragment = `
	fragment versionFields on VersionType {
		key
		versionId
		size
		updated
	}	
`	

export const roleFragment = `
	fragment roleFields on RoleType {
		roleId
		type
		version
}	
`	

export const documentReturnFragment = `
	fragment documentReturnFields on DocumentReturnType {
		totalCount
		tagList
		items {
			...documentFields
		}
	}
`
	
export const documentMinFragment = `
	fragment documentFields on DocumentType {
		documentId
		domain
		name
		description
		state
	}
`

export const documentMaxFragment = `
	fragment documentFields on DocumentType {
		documentId
		domain
		name
		description
		state
		templateDocumentId
		properties {
			...propertyFields
			items {
				...propertyFields
				items {
					...propertyFields
				}
				properties {
					...propertyFields
				}
			}
			properties {
				...propertyFields
				items {
					...propertyFields
					items {
						...propertyFields
					}
					properties {
						...propertyFields
					}
				}
				properties {
					...propertyFields
					items {
						...propertyFields
						items {
							...propertyFields
						}
						properties {
							...propertyFields
						}
					}
					properties {
						...propertyFields
						items {
							...propertyFields
							items {
								...propertyFields
							}
							properties {
								...propertyFields
							}
						}
						properties {
							...propertyFields
							items {
								...propertyFields
								items {
									...propertyFields
								}
								properties {
									...propertyFields
								}
							}
							properties {
								...propertyFields
								items {
									...propertyFields
									items {
										...propertyFields
									}
									properties {
										...propertyFields
									}
								}
							}
						}
					}
				}
			}
		}
		instanceData
		defaultInstanceData
		analysisMode
		analysisFilter
		hasContent
		comments {
			...commentFields
			comments {
				...commentFields
				comments {
					...commentFields
					comments {
						...commentFields
					}
				}
			}
		}
		tags
		permissions {
			...permissionFields
		}
		delegatedPermissions {
			...permissionDelegateFields
		}
		validationErrors {error warning data}
		created
		createdBy
		updated
		updatedBy
		version
	}	
`	

export const contentFragment = `
	fragment contentFields on ContentType {
		partNumber
		totalParts
		data
		hash
		size
		mimeType
	}
`

