import React from 'react'
import { Grid, TextField } from '@mui/material'
import * as i18 from 'i18next'
import { red } from '@mui/material/colors'


function isJsonString(str) {
    try {
		if (str) {
			JSON.parse(str)
		}
    } catch (e) {
        return false
    }
    return true
}

// Runtime view of component
const PropertyTextMulti = ({ nameTypeComponent, property, updateProperty, designTime, disabled }) => {

//	console.log('PropertyTextMulti')

	let value = designTime ?  property.default : property.value
	
	let placeholder
	if (designTime) 
		placeholder = i18.t(`property_${property.subType}_designtime_placeholder`)
	else
		placeholder = i18.t(`property_${property.subType}_runtime_placeholder`)

	let style = {}
	if (property?.subType === 'json' && !isJsonString(value)) {
		style = {backgroundColor: red[50]}
	}
		
	return (
		<Grid container direction={'row'} alignItems={designTime ? 'flex-start' : 'center'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
	
				<TextField
					variant='standard'
					style={{...style, flex: 1}}
					placeholder={placeholder}
					value={value !== null ? value : ''}
					onChange={(event)=>{
						designTime ?
							property.default = event.target.value
						:
							property.value = event.target.value
						updateProperty(property)
					}}
					multiline={true}
					rows={2}
					InputProps={{readOnly: disabled}}		
				/>
		</Grid>
	)
}

export default (PropertyTextMulti)
