import { RECEIVE_ERRORS } from './actiontypes'
import { gqlExec } from '../reducers/graphql'
import { retrieveAssignment } from '../reducers/assignments'
import { retrieveAutomation } from '../reducers/automations'
import { retrieveDocument } from '../reducers/documents'
import { retrieveExtract } from '../reducers/extracts'
import { retrieveSupportCase } from '../reducers/supportcases'
import { retrieveWorkflow } from '../reducers/workflows'

export const postComment  = (sourceType, sourceId, parentCommentId, comment, setStatusIndicator) => async dispatch => {
	
//	console.log('postComment', comment);
	
	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		if (!parentCommentId || !comment) return
		
		if (parentCommentId) {
			const response = await gqlExec(`mutation {postComment (sourceType: "${sourceType}", sourceId: "${sourceId}", parentCommentId: "${parentCommentId}", comment: "${comment}") {commentId}} `)
		} else {
			const response = await gqlExec(`mutation {postComment (sourceType: "${sourceType}", sourceId: "${sourceId}", comment: "${comment}") {commentId}} `)
		}
		
		switch (sourceType) {
			case 'assignment': dispatch(retrieveAssignment(sourceId)); break
			case 'automation': dispatch(retrieveAutomation(sourceId)); break
			case 'document': dispatch(retrieveDocument(sourceId, sourceType, sourceId)); break
			case 'extract': dispatch(retrieveExtract(sourceId)); break
			case 'supportcase': dispatch(retrieveSupportCase(sourceId)); break
			case 'workflow': dispatch(retrieveWorkflow(sourceId)); break
			default: break
		}
	}
	catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}



const initialState = {
}

const comments = (state = initialState, action) => {
	switch (action.type) {
		
// Comment Events

		default:
			break
	}

	return state;
}

export default comments