import React, { useState } from 'react'
import { Grid, Button, Divider, Tooltip, Typography, Chip, Hidden, Icon } from '@mui/material'
import { ArrowBack, Create, Publish, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import VirtualList from '../components/VirtualList'
import WorkflowListItem from '../components/WorkflowListItem'
import TagFilterBar from '../components/TagFilterBar'
import UppyButton from '../components/UppyButton'


/* Max height of list equal to VH minus combined height of AppBar and TabBar */	
//const listHeight = 'calc(100vh - 145px)'

const WorkflowList = ({ navigation, xs, tagFilter, app, workflows, screen, tasks, taskinstances, retrieveWorkflows, retrieveWorkflow, createWorkflow }) => {

//	console.log('WorkflowList')

	let list = []
	let listCount = 0
	let retrieve = ()=>{}
	if (screen === 'workflow_template_list') {
		list = app.templateWorkflowsList
		listCount = app.templateWorkflowsCount ? app.templateWorkflowsCount : 0
		retrieve = (skip) => retrieveWorkflows('templateWorkflows', skip, tagFilter)
	} else if (screen === 'workflow_working_list') {
		list = app.workingWorkflowsList
		listCount = app.workingWorkflowsCount ? app.workingWorkflowsCount : 0
		retrieve = (skip) => retrieveWorkflows('workingWorkflows', skip, tagFilter)
	} else if (screen === 'workflow_draft_list') {
		list = app.draftWorkflowsList
		listCount = app.draftWorkflowsCount ? app.draftWorkflowsCount : 0
		retrieve = (skip) => retrieveWorkflows('draftWorkflows', skip, tagFilter)
	} else if (screen === 'workflow_completed_list') {
		list = app.completedWorkflowsList
		listCount = app.completedWorkflowsCount ? app.completedWorkflowsCount : 0
		retrieve = (skip) => retrieveWorkflows('completedWorkflows', skip, tagFilter)
	}

	const onFileComplete = async (file) => {
		if (file) {
			let reader = new FileReader()
			reader.readAsText(file)
			reader.onload = function() {
				let workflow = JSON.parse(reader.result)
				delete workflow.permissions	// TBD - should we allow import of permissions?  will fail if orgentityId doesn't exist
				if (screen === 'workflow_draft_list') {
					workflow.state = 'draft'
				} else if (screen === 'workflow_template_list') {
					workflow.state = 'template'
				}
				createWorkflow(navigation, workflow)
			}
			
		}
	}

	return (
		<Grid item id='list' >

			<Grid container id='buttonbar' direction={'row'} alignItems='center' >
			
				<Button disabled={!xs} color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				
				{ screen === 'workflow_draft_list' ?
					<Tooltip title={i18.t('workflow_tooltip_create_draft')}>
						<Button color="primary" onClick={()=>createWorkflow(navigation)}><Hidden smUp><Create/></Hidden><Hidden smDown >{i18.t('list_new')}</Hidden></Button>
					</Tooltip>
				: null }

				{ screen === 'workflow_template_list' ?
					<Tooltip title={i18.t('workflow_tooltip_create_template')}>
						<Button color="primary" onClick={()=>createWorkflow(navigation, {state: 'template'}, tagFilter)}><Hidden smUp><Create/></Hidden><Hidden smDown >{i18.t('list_new')}</Hidden></Button>
					</Tooltip>
				: null }

				{ screen === 'workflow_draft_list' || screen === 'workflow_template_list' ?
					<Tooltip title={i18.t('workflow_tooltip_import_workflow')}>
						<Grid item>
						<UppyButton inMemoryFileUpload={true} onFileComplete={onFileComplete}><Hidden smUp><Publish/></Hidden><Hidden smDown >{i18.t('list_import')}</Hidden></UppyButton>
						</Grid>
					</Tooltip>
				: null }
			
				{ tagFilter && tagFilter.length > 0 ?
					<TagFilterBar tagFilter={tagFilter} />
				: null }
				
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={()=>retrieve(0)} ><Refresh/></Button>					
				</Tooltip>
			</Grid>

			{ listCount === 0 ?
				<Typography style={{padding: 20, color: 'grey'}}>{i18.t('general_no_items_to_display')}</Typography>
			: null }

			<VirtualList objList={workflows} idList={list} listCount={listCount} retrieve={retrieve} >
				{(childProps) => <WorkflowListItem navigation={navigation} me={app.me} workflow={childProps.item} tasks={tasks} taskinstances={taskinstances} retrieveWorkflow={retrieveWorkflow} />}
			</VirtualList>
			
		</Grid>					
	)
}

export default WorkflowList
