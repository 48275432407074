import { CLEAR_ALL_ORGS, RECEIVE_ORGS, RECEIVE_ORG, UPDATE_ORG, ADD_PROPERTY_EVENT, DELETE_PROPERTY_EVENT, MOVE_PROPERTY_EVENT, RECEIVE_ERRORS } from './actiontypes'
import { schema, normalize, denormalize } from 'normalizr'
import cloneDeep from 'lodash.clonedeep'

import { store } from '../App'
import { orgentityTopFragment, orgentityReturnFragment, orgentityMinFragment, orgentityMaxFragment } from '../reducers/graphql'
import { settingFragment, permissionFragment, limitedOrgEntityFragment, propertyFragment, expressionFragment, expressionBaseFragment, extractionRuleFragment } from '../reducers/graphql'
import { removeMeta, propertySchema } from '../reducers/workflows'
import { gqlExec, gqlIdInput } from '../reducers/graphql'


const subentity = new schema.Entity(
	"subEntities", 
	{
	}, 
	{
		idAttribute: value => value.orgentityId,
	}
)

const subentities = new schema.Array(subentity)
subentity.define({subentities})

const org = new schema.Entity(
	"orgs", 
	{
	},
	{
		idAttribute: value => value.orgentityId,
	}
)


// Org Functions

export const postOrgUpdate = (orgs) => {

	if (orgs) {
		// Normalize the properties
		let properties={}

		for (let i=0; i<orgs.length; ++i) {
					
			let org = orgs[i]
			
			if (org.properties) {
				let propertydata = normalize(org.properties, [propertySchema])
				properties[org.orgentityId] = propertydata.entities.properties
				org.properties = propertydata.result
			}
		}

		// Now normalize the org data
		const data = normalize(orgs, [org])
				
		let orgs2 = {...data.entities.orgs, ...data.entities.subEntities}
		let orglist = data.result
		
		// Filter out any subEntities from our top-level list so they don't show up twice.
		orglist = orglist.filter(orgentityId => !Object.keys(data.entities.subEntities ? data.entities.subEntities : []).includes(orgentityId))

		return {orglist: orglist, orgs: orgs2, properties: properties}
	}
}

export const retrieveOrgs = (skip=0) => async dispatch => {
	
//	console.log('retrieveOrgs')

	document.body.style.cursor = 'wait'

	try {
		let skipInput = skip !== 0 ? `skip: ${skip}` : ``
		let input = skipInput !== '' ? `(${skipInput})` : ``

		const response = await gqlExec(`query RetrieveOrgs {orgs ${input} {...orgentityReturnFields}} ${orgentityReturnFragment} ${orgentityMinFragment} ${orgentityTopFragment} `)

		let orgs = response.orgs ? response.orgs.items : null
		if (orgs) {
			let action = postOrgUpdate(orgs)
			action.type = RECEIVE_ORGS
			action.skip = skip
			action.listCount = orgs.totalCount
			action.tagList = orgs.tagList
			dispatch(action)
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const retrieveOrg = (orgentityId) => async dispatch => {
	
//	console.log('retrieveOrg')

	document.body.style.cursor = 'wait'

	try {
//		const scope = store.getState().app.scope
//		const scopeInput = scope && scope.orgentityId ? `scope: {orgentityId: "${scope.orgentityId}"}` : ``
//		const idInput = orgentityId ? `orgentityId: "${orgentityId}"` : ``
//		const input = idInput !== '' || scopeInput !== '' ? `(${idInput} ${scopeInput})` : ``

		const response = await gqlExec(`query RetrieveOrg {orgs ${gqlIdInput('orgentity', orgentityId)} {...orgentityReturnFields}} ${orgentityReturnFragment} ${orgentityMaxFragment} ${orgentityTopFragment} ${settingFragment} ${propertyFragment} ${expressionFragment} ${expressionBaseFragment} ${extractionRuleFragment}  ${permissionFragment} ${limitedOrgEntityFragment}`)

		let orgs = response && response.orgs ? response.orgs.items : null

		if (orgs) {
			let action = postOrgUpdate(orgs)
			action.type = RECEIVE_ORG
			dispatch(action)
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const updateOrg = (org) => dispatch => {
	dispatch({type: UPDATE_ORG, org: org})
}

export const saveOrg = (org) => async dispatch => {

//	console.log('updateOrg')

	document.body.style.cursor = 'wait'
	
	try {

		// Make a deep copy
		org = cloneDeep(org)	

		let properties = store.getState().properties

		// De-normalize the property list back into the org
		org.properties = denormalize(org.properties, [propertySchema], {properties: properties[org.orgentityId]});

		org.settings = org.settings.map(setting => {delete setting.group; delete setting.valueOptions; return setting})

		delete org.type
		delete org.subEntities
		delete org.expressionTypes
		delete org.terms
		delete org.name
		delete org.domain
		delete org.principalId
		delete org.email
		delete org.version

// TBD - only save if something in the org has changed???
		
		// Remove non-updateable property attributes
		removeMeta(org, 'validationErrors')
		removeMeta(org, 'created')			
		removeMeta(org, 'createdBy')			
		removeMeta(org, 'updatedBy')			
		removeMeta(org, 'version')	
		removeMeta(org, 'parentpropertyId')				
		
		// TBD - only save settings which are different from the parentSettings
		for (let i=0; org.settings && i<org.settings.length; ++i) {
			delete org.settings[i].type
			delete org.settings[i].valueOptions
		}
	
		const response = await gqlExec(`mutation {updateOrgEntity (input: ${JSON.stringify(org)}){orgentityId}}`)
	
		postOrgUpdate([response.updateOrgEntity])
			
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}


const initialState = {
}

const orgs = (state = initialState, action) => {
	switch (action.type) {
		
// Org Events

		case CLEAR_ALL_ORGS:
			state = {}
			break

		case RECEIVE_ORG:
		case RECEIVE_ORGS:
			for (let i=0; i<action.orglist.length; ++i) {
				state[action.orglist[i]] = action.orgs[action.orglist[i]]
			}
			break
			
		case UPDATE_ORG:
			state = {...state,
				[action.org.orgentityId]: action.org
			}
			break


// Property Events

		case ADD_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]) {
				if (!state[action.rootparentId].properties) state[action.rootparentId].properties=[]			
				if (state[action.rootparentId].properties.indexOf(action.property.propertyId) === -1) {
					state[action.rootparentId].properties.splice(0, 0, action.property.propertyId)
				}
			}
		}
		break

		case DELETE_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId] && state[action.rootparentId].properties) {
				let index = state[action.rootparentId].properties.findIndex(propertyId => propertyId === action.propertyId)
				state[action.rootparentId].properties.splice(index, 1)
			}
		} break

		case MOVE_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]  && state[action.rootparentId].properties) {
				state[action.rootparentId].properties.splice(action.toIndex, 0, state[action.rootparentId].properties.splice(action.fromIndex, 1)[0])
			}
		}
		break

			
		default:
			break
	}

	return state
}

export default orgs