import React, { useState } from 'react'
import { Grid, Typography, Tooltip, Button, Hidden } from '@mui/material'
import { ArrowBack, Create, Publish, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import VirtualList from './VirtualList'
import ExtractListItem from './ExtractListItem'
import TagFilterBar from './TagFilterBar'
import UppyButton from './UppyButton'


const ExtractList = ({ navigation, tagFilter, extractsList, extractsCount, extracts, documents, selectedExtract, 
						onSelect, createDocument, putContent, retrieveContent, createExtract, retrieveExtracts, retrieveExtract }) => {

//	console.log('ExtractList')

	const onFileComplete = async (file) => {
		if (file) {
			const doc = await createDocument(navigation, {name: file.name, state: 'working'})
			if (doc && file) {
				await putContent(doc.documentId, file)
			}
			const extractId = await createExtract(doc.documentId)
			if (extractId) {
				navigation?.navigate('extract', {extractId: extractId})
			}
		}
	}

	const onImportFileComplete = async (file) => {
		if (file) {
			let reader = new FileReader()
			reader.readAsText(file)
			reader.onload = function() {
				let extract = JSON.parse(reader.result)
console.log('Extract support TBD:', extract)				
// TBD - need to add import API to server (or augment existing create API)
			}
		}
	}

	let retrieve = (skip) => retrieveExtracts('extracts', skip, tagFilter)

	return (
		<Grid item id='list' >

			<Grid container id='buttonbar' direction={'row'} alignItems='center' >

				<Button disabled={true} color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>

				<UppyButton inMemoryFileUpload={true} onFileComplete={onFileComplete}><Hidden smUp><Create/></Hidden><Hidden smDown >{i18.t('extract_new_extract')}</Hidden></UppyButton>

				<UppyButton inMemoryFileUpload={true} onFileComplete={onImportFileComplete}><Hidden smUp><Publish/></Hidden><Hidden smDown >{i18.t('list_import')}</Hidden></UppyButton>
			
				{ tagFilter && tagFilter.length > 0 ?
					<TagFilterBar tagFilter={tagFilter} />
				: null }
								
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={()=>retrieve(0)} ><Refresh/></Button>					
				</Tooltip>
				
			</Grid>

			{ extractsCount === 0 ?
				<Typography style={{padding: 20, color: 'grey'}}>{i18.t('general_no_items_to_display')}</Typography>
			: null }
			
			<VirtualList objList={extracts} idList={extractsList} listCount={extractsCount} retrieve={retrieve} >
				{(childProps) =>
					<ExtractListItem extract={childProps.item} documents={documents} selectedExtract={selectedExtract} onSelect={onSelect} />
				}
			</VirtualList>
		
		</Grid>					
	)
}

export default ExtractList
