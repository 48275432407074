import React, { useState } from 'react'
import { Grid } from '@mui/material'

import AssignmentDetail from '../components/AssignmentDetail'
import DiscussionView from '../containers/DiscussionView'
import TabBar from '../components/TabBar'


const Assignment = ({ assignment, designTime=false, disabled=false, preview=false, updateAssignment }) => {
 
//	console.log('Assignment', assignment)

	if (!assignment) return null

	const [tab, setTab] = useState('assignment_tab_details')

	if (assignment.state !== 'working')
		disabled = true

	let tabValues = []
	tabValues.push('assignment_tab_details')
	if (assignment.showCommentTab === true) {tabValues.push('assignment_tab_comments')}

//	let style
//	if (!preview) {
//		style={width: '70%', height: 'calc(100% - 100px)', position: 'absolute', overflow: 'auto'}
//	}

	return (
		<Grid item id='assignment' >

			<TabBar tabValues={tabValues} tab={tab} setTab={setTab} />	
			
			{tab === 'assignment_tab_details' ?
				<AssignmentDetail
					assignment={assignment}
					designTime={designTime}
					disabled={disabled}
					updateAssignment={updateAssignment}
				/>
			: tab === 'assignment_tab_comments' ?
				<DiscussionView
					sourceType={'assignment'}
					sourceId={assignment.assignmentId}
					disabled={disabled}
				/>
			: null}


		</Grid>
	)

}

export default Assignment
