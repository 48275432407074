import React from 'react'
import { Grid, TextField, Typography } from '@mui/material'
import * as i18 from 'i18next'


const SaveToJSON = ({ workflowId, task, milestoneActionIndex, designTime, disabled, updateTask }) => {

//	console.log('SaveToJSON')
	
	let milestoneAction = task.milestoneActions[milestoneActionIndex]

// TBD - we no longer have value on milestoneAction - change value to ???

	let value = null
	if (milestoneAction.value) {
		value = JSON.parse(atob(milestoneAction.value))
	}

	return (
		<Grid container direction={'row'} alignItems='center'>				

			<Typography>{i18.t('action_save_to_json')}</Typography>
			
			&nbsp;&nbsp;
					
			<TextField
				style={{flex: 1}}
				variant='standard'
				disabled={disabled}
				InputLabelProps={{shrink: true}}
				placeholder={i18.t('savetojson_title_placeholder')}
				value={value?.title}
				onChange={(e)=>{milestoneAction.value=btoa(JSON.stringify({title: e.target.value}));updateTask(workflowId, task)}}
			/>
			
		</Grid>
	)
}

export default SaveToJSON