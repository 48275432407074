import React, { useState } from 'react'
import { Grid, TextField, Typography, Select, MenuItem, Tooltip, Switch } from '@mui/material'
import GooglePicker from 'react-google-picker'
import * as i18 from 'i18next'

const CLIENT_ID = '387159493695-djqsqn9ltpgi3lro5bljk23gqnlrnhee.apps.googleusercontent.com'
const DEVELOPER_KEY = 'AIzaSyD5Fo1rJa-rM0n4_Il2I2J8-bNFqiK6_0w'
const SCOPE = ['https://www.googleapis.com/auth/contacts.readonly']

export const folderPicker = (update) => (
	<GooglePicker
		id={'picker-dialog'}
		clientId={CLIENT_ID}
		developerKey={DEVELOPER_KEY}
		scope={SCOPE}
		style={{zIndex: '2000'}}
		createPicker={ (google, oauthToken) => {
			const docsView = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
				.setIncludeFolders(true)
				.setSelectFolderEnabled(true)

			const picker = new window.google.picker.PickerBuilder()
				.addView(docsView)
				.setOAuthToken(oauthToken)
				.setDeveloperKey(DEVELOPER_KEY)
				.setCallback((data)=>{
					if (data.action==='picked'){
						update(data.docs[0])
					}
				})

			picker.build().setVisible(true)
			var elements= document.getElementsByClassName('picker-dialog');
			for (var i = 0; i < elements.length; i++) {
				elements[i].style.zIndex = '2000';
			}
		}}
	>
		<Button color="primary" >{i18.t('milestone_action_select')}</Button>
	</GooglePicker>
)


const AutomationDetail = ({ navigation, automation, events, actions, workflows, documents, 
							updateAutomation, retrieveDocumentDetail }) => {
 
//	console.log('AutomationDetail')

	if (!automation) return null

	let eventlist = events ? Object.keys(events) : []
	let actionlist = actions ? Object.keys(actions).filter(actionId => actions[actionId].sourceType === 'event') : []
	let workflowlist = workflows ? Object.keys(workflows).filter(workflowId => workflows[workflowId].state === 'template') : []
	
	const [folder, setFolder] = useState()
	const [retrieveFolder, setRetrieveFolder] = useState()

	let createAutomationDisabled = !automation.eventId || !automation.actionId || !automation.eventData?.id

	let folder2
	if (automation?.eventData?.id && documents) {
		folder2 = documents[automation?.eventData?.id]
	}
	if (!folder2?.name && automation?.eventData?.id && !retrieveFolder) {
		retrieveDocumentDetail(automation?.eventData?.id)
		setRetrieveFolder(true)
	}		

	return (
		<Grid container direction={'column'} >

			{/* name */}
			<TextField
				variant='standard'
				autoFocus={true}
				InputLabelProps={{shrink: true}}				
				label={i18.t('automation_name_label')}
				placeholder={i18.t('automation_name_placeholder')}
				value={automation.name ? automation.name : ''}
				onChange={(e)=>{automation.name = e.target.value; updateAutomation(automation);}}
			/>

			<br/>

			{/* description */}
			<TextField
				variant='standard'
				autoFocus={true}
				InputLabelProps={{shrink: true}}				
				label={i18.t('automation_desc_label')}
				placeholder={i18.t('automation_desc_placeholder')}
				value={automation.description ? automation.description : ''}
				onChange={(e)=>{automation.description = e.target.value; updateAutomation(automation);}}
			/>

			<br/>

			{/* state */}
			<TextField
				variant='standard'
				sx={{input: {color: automation.state === 'deleted' || automation.state === 'disabled' ? 'red' : null}}}
				InputLabelProps={{shrink: true}}				
				label={i18.t('properties_state')}
				value={automation.state ? i18.t(`automation_state_${automation.state}`) : ''}
				InputProps={{readOnly: true}}										
			/>

			<br/>

			<Grid container direction='row' alignItems='center'>
				<Typography>{i18.t('automation_event_label')}</Typography>
				&nbsp;&nbsp;
				<Select
					variant='standard'
					value={automation.eventId ? automation.eventId : ''}
					style={{flex: 1, color: 'blue'}}
					onChange={(e)=>{automation.eventId = e.target.value; updateAutomation(automation);}}
				>
					{eventlist.map((eventId, index) => (
						<MenuItem 
							key={index}
							value={eventId}
							selected={automation.eventId === eventId}
						>
							{i18.t(`event_${events[eventId].type}`)}
						</MenuItem>
					))}										
				</Select>
			</Grid>
			
			<Grid container direction='row' alignItems='center'>
				<Typography>{i18.t('automation_folder_label')}</Typography>
				&nbsp;&nbsp;
				<Typography>{folder2?.name}</Typography>
				<Tooltip title={i18.t('automation_tooltip_select_folder')} >
					<div>
					{folderPicker((doc)=>{automation.eventData = {...automation.eventData, id: doc.id}; updateAutomation(automation);})}
					</div>
				</Tooltip>
			</Grid>
			
			<br/>
			
			<Grid container direction='row' alignItems='center'>
				<Typography>{i18.t('automation_action_label')}</Typography>
				&nbsp;&nbsp;
				<Select
					variant='standard'
					value={automation.actionId ? automation.actionId : ''}
					style={{flex: 1, color: 'blue'}}
					onChange={(e)=>{automation.actionId = e.target.value; updateAutomation(automation);}}
				>
					{actionlist.map((actionId, index) => (
						<MenuItem 
							key={index}
							value={actionId}
							selected={automation.actionId === actionId}
						>
							{i18.t(`action_${actions[actionId].type}`)}
						</MenuItem>
					))}										
				</Select>
			</Grid>
			
			<Grid container style={{marginTop: 5}} direction='row' alignItems='center'>
				<Typography>{i18.t('automation_workflow_label')}</Typography>
				&nbsp;&nbsp;
				<Select
					variant='standard'
					value={automation.actionData?.workflowId ? automation.actionData?.workflowId : ''}
					style={{flex: 1, color: 'blue'}}
					onChange={(e)=>{automation.actionData={...automation.actionData, workflowId: e.target.value}; updateAutomation(automation);}}
				>
					{workflowlist.map((workflowId, index) => (
						<MenuItem 
							key={index}
							value={workflowId}
							selected={automation.actionData?.workflowId === workflowId}
						>
							{workflows[workflowId].name}
						</MenuItem>
					))}										
				</Select>
			</Grid>
			
		</Grid>				
	)
}

export default AutomationDetail

