import { RECEIVE_WORKFLOWS, RECEIVE_WORKFLOW } from './actiontypes'
import { ADD_TASK_EVENT, UPDATE_TASK_EVENT, DELETE_TASK_EVENT, MOVE_TASK_EVENT } from './actiontypes'
import { MAKE_CHILD_TASK_EVENT, MAKE_PARENT_TASK_EVENT, SET_PARALLEL_EXECUTION_EVENT, SET_ASSIGNEES_TYPE_EVENT, SET_ASSIGNEES_GROUPFIELD_EVENT } from './actiontypes'
import { SET_ASSIGNEES_EVENT, DELETE_ASSIGNEE_EVENT } from './actiontypes'
import { ADD_MILESTONE_ACTION_EVENT, DELETE_MILESTONE_ACTION_EVENT, MOVE_MILESTONE_ACTION_EVENT } from './actiontypes'
import { DELETE_PROPERTY_EVENT } from './actiontypes'
import { setExpanded } from './appnav'
import UUID from 'uuidjs'
import { store } from '../App'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'


const checkForEmptyParent = (substate, pTask) => {

	// Check to see if the parent task has any children left, if not - clean it up
	if (pTask && pTask.parenttaskId) {

		// If there are no more children in the parent task, then delete it
		if (pTask.tasks.length === 0) {

			let gpTask = substate[pTask.parenttaskId]

			// Get the index of the parent within the grandparents children list
			let pIndex = gpTask.tasks.indexOf(pTask.taskId)

			gpTask.tasks.splice(pIndex, 1)
			delete substate[pTask.taskId]
			
			checkForEmptyParent(substate, gpTask)
		}
	}
}

const deleteFormProperty = (form, propertyId) => {
	if (!form || !propertyId) return

	for (let i=0; form.pages && i<form.pages.length; ++i) {
		let page = form.pages[i]
		deleteFormProperty(page, propertyId)
	}

	for (let i=0; form.items && i<form.items.length; ++i) {
		let item = form.items[i]
		if (item.type === 'property') {
			if (item.itemId === propertyId) {
				form.items.splice(i, 1)
			}
		} else {
			deleteFormProperty(item, propertyId)
		}
	}
}

const deleteTaskProperty = (task, propertyId) => {
	deleteFormProperty(task.form, propertyId)

	if (task.tasks) {
		for (const task2 of task.tasks) {
			deleteTaskProperty(task2, propertyId)
		}
	}
}  

// Task Functions

export const addTask = (workflowId, parenttaskId, index) => dispatch => {
	let newTaskId = UUID.genV4().toString()
	dispatch({type: ADD_TASK_EVENT, workflowId: workflowId, parenttaskId: parenttaskId, index: index, newTaskId: newTaskId})
	dispatch(setExpanded(newTaskId, true))
	return newTaskId
}

export const updateTask = (workflowId, task) => dispatch => {
	dispatch({type: UPDATE_TASK_EVENT, workflowId: workflowId, task: task})
}

export const deleteTask = (workflowId, taskId) => dispatch => {
	dispatch({type: DELETE_TASK_EVENT, workflowId: workflowId, taskId: taskId})
}

export const moveTask = (workflowId, parenttaskId, fromIndex, toIndex) => dispatch => {	
	dispatch({type: MOVE_TASK_EVENT, workflowId: workflowId, parenttaskId: parenttaskId, fromIndex: fromIndex, toIndex: toIndex})
}

export const makeChildTask = (workflowId, taskId) => dispatch => {
	dispatch({type: MAKE_CHILD_TASK_EVENT, workflowId: workflowId, taskId: taskId})
	let task = store.getState().tasks[workflowId][taskId]
	dispatch(setExpanded(task.parenttaskId, true))
}

export const makeParentTask = (workflowId, taskId) => dispatch => {
	dispatch({type: MAKE_PARENT_TASK_EVENT, workflowId: workflowId, taskId: taskId})
}

export const setParallelExecution = (workflowId, taskId, value) => dispatch => {
	dispatch({type: SET_PARALLEL_EXECUTION_EVENT, workflowId: workflowId, taskId: taskId, value: value})
}

export const setAssigneesType = (workflowId, taskId, assigneesType) => dispatch => {
	dispatch({type: SET_ASSIGNEES_TYPE_EVENT, workflowId: workflowId, taskId: taskId, assigneesType: assigneesType})
}

export const setAssigneesGroupProperty = (workflowId, taskId, propertyId) => dispatch => {
	dispatch({type: SET_ASSIGNEES_GROUPFIELD_EVENT, workflowId: workflowId, taskId: taskId, propertyId: propertyId})
}


// Assignee Functions

export const setAssignees = (workflowId, taskId, assignees) => dispatch => {
	dispatch({type: SET_ASSIGNEES_EVENT, workflowId: workflowId, taskId: taskId, assignees: assignees})
}

export const deleteAssignee = (workflowId, taskId, assigneeIndex) => dispatch => {
	dispatch({type: DELETE_ASSIGNEE_EVENT, workflowId: workflowId, taskId: taskId, assigneeIndex: assigneeIndex})
}


// Milestone Action Functions

export const addMilestoneAction = (workflowId, taskId, maction) => dispatch => {
	dispatch({type: ADD_MILESTONE_ACTION_EVENT, workflowId: workflowId, taskId: taskId, maction: maction})
}

export const deleteMilestoneAction = (workflowId, taskId, mactionIndex) => dispatch => {
	dispatch({type: DELETE_MILESTONE_ACTION_EVENT, workflowId: workflowId, taskId: taskId, mactionIndex: mactionIndex})
}

export const moveMilestoneAction = (workflowId, taskId, fromIndex, toIndex) => dispatch => {
	dispatch({type: MOVE_MILESTONE_ACTION_EVENT, workflowId: workflowId, taskId: taskId, fromIndex: fromIndex, toIndex: toIndex})
}

// Form Functions

export const moveFormProperty = (workflowId, taskId, propertyId, srcContainerId, destContainerId, destIndex) => dispatch => {

	let task = cloneDeep(store.getState().tasks[workflowId][taskId])
	let deletedProp

	let deleteProp = (container, containerId, propertyId) => {
		
		if (!container || !propertyId) return

		if (container.type === 'container' && container.itemId === containerId) {
			let index = container.items.findIndex(item => item.itemId === propertyId)
			if (index !== -1) {
				deletedProp = container.items.splice(index, 1)?.[0]
			}
		} else if (container.type === 'page' || container.type === 'parentcontainer') {
			for (const container2 of container.items) {
				deleteProp(container2, containerId, propertyId)
			}
		}
	}

	let insertProp = (container, property, index) => {
		
		if (!container || !property) return
		
		if (container.type === 'container' && container.itemId === destContainerId) {
			container.items.splice(index, 0, property)
		} else if (container.type === 'page' || container.type === 'parentcontainer') {
			for (const container2 of container.items) {
				insertProp(container2, property, index)
			}
		}
	}
	
	if (task) {

		// delete property from dest container
		task.form && task.form.pages ? deleteProp(task.form.pages[0], destContainerId, propertyId) : null

		// delete property from source container
		task.form && task.form.pages ? deleteProp(task.form.pages[0], srcContainerId, propertyId) : null
	
		if (deletedProp) {
			// insert property into dest container
			task.form && task.form.pages ? insertProp(task.form.pages[0], deletedProp, destIndex) : null
			dispatch(updateTask(workflowId, task))
		}
	}
}


const initialState = {}

const tasks = (state=initialState, action) => {
	
	switch (action.type) {

// Workflow Events

		case RECEIVE_WORKFLOW:
		case RECEIVE_WORKFLOWS: {
			state = {...state, ...action.tasks}		// TBD - should this be a merge?
		}
		break

			
// Task Events

		case ADD_TASK_EVENT: {
			state[action.workflowId] = cloneDeep(state[action.workflowId])
			
			let optionName = i18.t('route_condition_option_prefix') + '1'
			let newTask = {
						taskId: action.newTaskId, 
						parenttaskId: action.parenttaskId, 
						type: 'user',
						assignees: {type: 'direct'}, 
						tasks: [], 
						routeCondition: {quorum: "any", options: [{name: optionName, type: 'nexttask'}]},
						parallelExecution: false,
					}
					
			state[action.workflowId][newTask.taskId] = newTask
			if (!state[action.workflowId][action.parenttaskId].tasks) {
				state[action.workflowId][action.parenttaskId].tasks=[]
			}
			state[action.workflowId][action.parenttaskId].tasks.splice(action.index, 0, newTask.taskId)
		}
		break

		case UPDATE_TASK_EVENT: {
			state = {...state}			
			state[action.workflowId][action.task.taskId] = cloneDeep(action.task)
			state[action.workflowId][action.task.taskId].modified = Date.now()
		}
		break
			
		case DELETE_TASK_EVENT: {
			state = {...state}
			state[action.workflowId] = cloneDeep(state[action.workflowId])
			
			// delete this task from it's parent
			let task = state[action.workflowId][action.taskId]
			let pTask = state[action.workflowId][task.parenttaskId]
			if (pTask && pTask.tasks && pTask.tasks.length > 0) {
				let index = pTask.tasks.indexOf(action.taskId)
				pTask.tasks.splice(index,1)
			}
			
			// now delete this task from our task list
			delete state[action.workflowId][action.taskId]
			
			// Check to see if the parent task has any children left, if not - clean it up
			checkForEmptyParent(state[action.workflowId], pTask)
		}
		break

		case MOVE_TASK_EVENT: {
			state = {...state}
			if (state[action.workflowId][action.parenttaskId]) {
				let tasks = state[action.workflowId][action.parenttaskId].tasks
				if (tasks && tasks.length > 0) {
					tasks.splice(action.toIndex, 0, tasks.splice(action.fromIndex, 1)[0])
				}
				state.now = Date.now()
			}
		}
		break
			
		case MAKE_CHILD_TASK_EVENT: {
			state = {...state}
			state[action.workflowId] = cloneDeep(state[action.workflowId])

			let task = state[action.workflowId][action.taskId]
			let currentParentTask = state[action.workflowId][task.parenttaskId]
			
			// Remove this task from its previous parent
			let currentIndex = currentParentTask.tasks.indexOf(task.taskId)
			currentParentTask.tasks.splice(currentIndex, 1)
			
			let previousTask = null
			if (currentIndex !== 0) {
				previousTask = state[action.workflowId][currentParentTask.tasks[currentIndex-1]]
			}
			
			if (previousTask === null || previousTask.type !== 'parent') {
			
				// Make a new parent task and move this task to it as a child
				let optionName = i18.t('route_condition_option_prefix') + '1'
				let newParentTask = {
					taskId: UUID.genV4().toString(), 
					parenttaskId: task.parenttaskId, 
					type: 'parent',
					assignees: {type: 'direct'}, 
					tasks: [], 
					routeCondition: {quorum: 'all', options: [{type: 'nexttask'}]},
					parallelExecution: false,
				}
				newParentTask.tasks.push(task.taskId)				
				task.parenttaskId = newParentTask.taskId
				
				// Set the previous parent of this task to be the parent of new parent task
				currentParentTask.tasks.splice(currentIndex, 0, newParentTask.taskId)
				
				// Update redux state with new parent
				state[action.workflowId][newParentTask.taskId] = newParentTask				
			} else {
				// The previous task is already a parent task, move this task to it as a child
				if (!previousTask.tasks) previousTask.tasks = []
				previousTask.tasks.push(task.taskId)
				task.parenttaskId = previousTask.taskId
			}
		}
		break

		case MAKE_PARENT_TASK_EVENT: {
			state = {...state}

			state[action.workflowId] = cloneDeep(state[action.workflowId])
			
			let task = state[action.workflowId][action.taskId]
			let pTask = state[action.workflowId][task.parenttaskId]
			
			if ((task.type === 'user' || task.type === 'milestone') && pTask.parenttaskId) {

				let gpTask = state[action.workflowId][pTask.parenttaskId]
			
				// Remove this task from its previous parent
				let currentIndex = pTask.tasks.indexOf(task.taskId)
				pTask.tasks.splice(currentIndex, 1)

				// Get the index of the parent within the grandparents children list
				let pIndex = gpTask.tasks.indexOf(pTask.taskId)
				
				// Move this task into the grandparent children list just after the current parent
				gpTask.tasks.splice(pIndex+1, 0, task.taskId)
				
				// Set this task's parenttaskId to its new parent
				task.parenttaskId = gpTask.taskId
				
				// If there are no more children in the parent task, then delete it
				checkForEmptyParent(state[action.workflowId], pTask)
			}
		}
		break

		case SET_PARALLEL_EXECUTION_EVENT: {
			state = {...state}
			
			state[action.workflowId][action.taskId] = {...state[action.workflowId][action.taskId],
				parallelExecution: action.value ? action.value : false
			}
		}
		break
			
		case SET_ASSIGNEES_TYPE_EVENT: {
			state = {...state}
			state[action.workflowId][action.taskId].assignees.type = action.assigneesType
			if (action.assigneesType === 'direct') {
				delete state[action.workflowId][action.taskId].assignees.propertyId			
			}
			state[action.workflowId][action.taskId].modified = Date.now()
		}
		break
			
		case SET_ASSIGNEES_GROUPFIELD_EVENT: {
			state = {...state}
			state[action.workflowId][action.taskId].assignees = {...state[action.workflowId][action.taskId].assignees,
				propertyId: action.propertyId
			}
			state[action.workflowId][action.taskId].modified = Date.now()
		}
		break


// Property Events

		case DELETE_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]) {
				let keys = Object.keys(state[action.rootparentId])
				keys.forEach(taskId => {
					if (state[action.rootparentId][taskId]) {
						deleteTaskProperty(state[action.rootparentId][taskId], action.propertyId)
					}
				})
			}
		}
		break
		
						
// Assignee Events

		case SET_ASSIGNEES_EVENT: {
			state = {...state}
			state[action.workflowId][action.taskId] = cloneDeep(state[action.workflowId][action.taskId])
			state[action.workflowId][action.taskId].assignees = action.assignees
		}
		break
			
		case DELETE_ASSIGNEE_EVENT: {
			state = {...state}
			state[action.workflowId][action.taskId] = cloneDeep(state[action.workflowId][action.taskId])
			
			if (state[action.workflowId][action.taskId].assignees && state[action.workflowId][action.taskId].assignees.type === 'direct') {
				state[action.workflowId][action.taskId].assignees.assignees.splice(action.assigneeIndex, 1)
			}
		}
		break


// Milestone Action Events

		case ADD_MILESTONE_ACTION_EVENT: {
			state = {...state}
			state[action.workflowId][action.taskId] = cloneDeep(state[action.workflowId][action.taskId])

			if (!state[action.workflowId][action.taskId].milestoneActions) {
				state[action.workflowId][action.taskId].milestoneActions=[]
			}
			state[action.workflowId][action.taskId].milestoneActions.push(action.maction)
		}
		break
			
		case DELETE_MILESTONE_ACTION_EVENT: {
			state = {...state}
			state[action.workflowId][action.taskId] = cloneDeep(state[action.workflowId][action.taskId])

			if (state[action.workflowId][action.taskId].milestoneActions) {
				state[action.workflowId][action.taskId].milestoneActions.splice(action.mactionIndex, 1)
			}
		}
		break
			
		case MOVE_MILESTONE_ACTION_EVENT: {
			state = {...state}
			if (state[action.workflowId][action.taskId]  && state[action.workflowId][action.taskId].milestoneActions) {
				state[action.workflowId][action.taskId].milestoneActions.splice(action.toIndex, 0, state[action.workflowId][action.taskId].milestoneActions.splice(action.fromIndex, 1)[0])
			}
		}
		break
		
		default:
		break
			
	}
	
	return state
}

export default tasks