import { RECEIVE_ACTIONS, DELETE_ACTION, RECEIVE_ERRORS } from './actiontypes'
import { normalize, schema } from 'normalizr'
import { actionFragment } from '../reducers/graphql'
import { store } from '../App'
import { gqlExec } from '../reducers/graphql'


export const actionSchema = new schema.Entity(
	'actions', 
	{
	}, 
	{
		idAttribute: value => value.actionId
	}
)

export const retrieveActions = (skip=0) => async dispatch => {
	
//	console.log('retrieveActions');

	try {
		document.body.style.cursor = 'wait'

		let scope = store.getState().appnav.scope
		let input = scope ? `(scope: {orgentityId: "${scope.orgentityId}"})` : ``

		const response = await gqlExec(`query RetrieveActions {actions ${input} {...actionFields}} ${actionFragment}`)

		let actions = response.actions

		if (actions) {
			const data = normalize(actions, [actionSchema]);		
				
			if (data.entities.actions) {
				dispatch({type: RECEIVE_ACTIONS, actionlist: data.result, actions: data.entities.actions, skip: skip})
			}
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}



const initialState = {
}

const actions = (state = initialState, action) => {
	switch (action.type) {
		
// Action Actions

		case RECEIVE_ACTIONS:
			state = {...state, ...action.actions}
			break

		default:
			break
	}

	return state;
}

export default actions