import { RECEIVE_WORKFLOW, RECEIVE_WORKFLOWS, RECEIVE_ASSIGNMENT, RECEIVE_ASSIGNMENTS } from './actiontypes'
import { CREATE_LAUNCH_ASSIGNMENT, CREATE_ATTACHMENT, UPDATE_ATTACHMENT, DELETE_ATTACHMENT, MOVE_ATTACHMENT, RECEIVE_ERRORS } from './actiontypes'
import UUID from 'uuidjs'


export const createAttachment = (rootparentId) => dispatch => {
	let attachment = {attachmentId: UUID.genV4().toString(), type: 'document'}
	dispatch({type: CREATE_ATTACHMENT, rootparentId: rootparentId, attachment: attachment})
}

export const updateAttachment = (rootparentId, attachment) => dispatch => {
	if (!rootparentId || !attachment) return
	dispatch({type: UPDATE_ATTACHMENT, rootparentId: rootparentId, attachment: attachment})
}

export const deleteAttachment = (rootparentId, attachmentId) => dispatch => {
	dispatch({type: DELETE_ATTACHMENT, rootparentId: rootparentId, attachmentId: attachmentId})
}

export const moveAttachment = (rootparentId, fromIndex, toIndex) => dispatch => {
	dispatch({type: MOVE_ATTACHMENT, rootparentId: rootparentId, fromIndex: fromIndex, toIndex: toIndex})
}


const initialState = {
}

const attachments = (state = initialState, action) => {
	switch (action.type) {
		
		case CREATE_LAUNCH_ASSIGNMENT:
		case RECEIVE_WORKFLOW:
		case RECEIVE_WORKFLOWS:
		case RECEIVE_ASSIGNMENT:
		case RECEIVE_ASSIGNMENTS:
			state = {...state, ...action.attachments}		// TBD - should this be a merge?
			break
			
		case UPDATE_ATTACHMENT:
		case CREATE_ATTACHMENT:
			state = {...state}
			if (!state[action.rootparentId]) state[action.rootparentId] = {}
			state[action.rootparentId][action.attachment.attachmentId] = action.attachment
			state[action.rootparentId][action.attachment.attachmentId].modified = Date.now()
			break
		
		case DELETE_ATTACHMENT:
			state = {...state}			
			delete state[action.rootparentId][action.attachmentId]
			break
			
		default:
			break
	}

	return state;
}

export default attachments