import React from 'react'
import { Grid, FormControl, FormControlLabel, Checkbox } from '@mui/material'
import UUID from 'uuidjs'
import cloneDeep from 'lodash.clonedeep'


// Runtime view of component
const PropertyListMulti = ({ nameTypeComponent, property, properties, updateProperty, disabled, addCompositeProperty, deleteCompositeProperty }) => {

//	console.log('PropertyListMulti')

	let value = property?.properties?.map(propertyId => properties[propertyId].value)

	return (
		<Grid container direction={'row'} alignItems={'center'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
	
			<Grid item style={{paddingLeft: 10}} >
				<FormControl>
					{property.items && property.items.enum && property.items.enum.map((option, index) =>
						<FormControlLabel  
							key={index} 
							label={option}
							control={
								<Checkbox 
									checked={value && value.includes(option) ? true : false}
									value={option}
									disabled={disabled}
									onChange={(event)=>{
										let subpropertyindex = property?.properties?.findIndex(propertyId => properties[propertyId].value === option)
										if (subpropertyindex !== undefined && subpropertyindex !== -1) {
											let subpropertyId = property.properties[subpropertyindex]
											deleteCompositeProperty(property.propertyId, subpropertyId)
										} else {
											let newProp = cloneDeep(property.items)
											newProp.propertyId = UUID.genV4().toString()
											newProp.value = option
											addCompositeProperty(property.propertyId, newProp)
										}
									}}
								/>
							} 
						/>
					)}
				</FormControl>
			</Grid>
		</Grid>
	)
}

export default (PropertyListMulti)
