import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'

import Property from '../components/Property'

import { addCompositeProperty, deleteCompositeProperty, updateProperty, changePropertyType } from '../reducers/properties'
import { retrieveContacts } from '../reducers/app'
import { createExtract } from '../reducers/extracts'
import { analyzeWorkflowAttachment } from '../reducers/workflows'
import { createDocument, createPropertiesFromExtract } from '../reducers/documents'


const PropertyView = ({ org, orgProperties, contacts, extractTemplates, extractsTagList, me, rootparentType, rootparentId,
						property, properties, documents, sourceObj, 
						designTime=false, disabled=false, level=0, showDisabledProps=false, slimStyle=false,
						retrieveContacts, addCompositeProperty, deleteCompositeProperty, updateProperty, changePropertyType, 
						createDocument, analyzeWorkflowAttachment, 
						createExtract, createPropertiesFromExtract }) => {

//	console.log('PropertyView', property.pname)

	if (!property) return null

	return (
		<Property
			org={org}
			orgProperties={orgProperties}
			me={me}
			rootparentType={rootparentType}
			rootparentId={rootparentId}
			property={property}
			properties={properties}
			documents={documents}
			contacts={contacts}
			extractTemplates={extractTemplates}
			extracts={sourceObj?.extracts}
			extractsTagList={extractsTagList}
			designTime={designTime}
			disabled={disabled}
			level={level}
			showDisabledProps={showDisabledProps}
			slimStyle={slimStyle}
			addCompositeProperty={addCompositeProperty}
			deleteCompositeProperty={deleteCompositeProperty}
			updateProperty={(property)=>updateProperty(rootparentId, property)}
			changePropertyType={changePropertyType}
			createDocument={createDocument}
			retrieveContacts={retrieveContacts}
			analyzeWorkflowAttachment={analyzeWorkflowAttachment}
			createExtract={createExtract}
			createPropertiesFromExtract={createPropertiesFromExtract}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	org: state.app.me ? state.app.me : null,
	orgProperties: state.app.me ? state.properties[state.app.me.orgentityId] : null,
	contacts:  state.app.contacts,
	extractTemplates: state.extracts,
	extractsTagList: state.app.extractsTagList,
	me: state.app.me,
	property: state.properties[props.rootparentId] ? state.properties[props.rootparentId][props.propertyId] : null,
	properties: state.properties[props.rootparentId],
	documents: state.documents,
	sourceObj: state?.[props.rootparentType === 'launchassignment' ? 'workflows' : props.rootparentType + 's']?.[props.rootparentId],
	modified: props.propertyId && state.properties[props.rootparentId] ? state.properties[props.rootparentId][props.propertyId]?.modified : null
})

export default connect(mapStateToProps, { retrieveContacts, addCompositeProperty, deleteCompositeProperty, 
											updateProperty, changePropertyType, createDocument,
											analyzeWorkflowAttachment, createExtract, createPropertiesFromExtract })(PropertyView)