import workflow_simple from './workflow_simple.json'
import testfragments from './testfragments'
import cloneDeep from 'lodash.clonedeep'


const stringify = (str) => {
	return JSON.stringify(str).replace(/"(\w+)"\s*:/g, '$1:')
}

let workflow = {...workflow_simple, workflowId: "<<workflowId>>"}

// Test workflow name

// WFD-0001
var nullwfnamewf = cloneDeep(workflow)
nullwfnamewf.name = null

// WFD-0002
var shortwfnamewf = cloneDeep(workflow)
shortwfnamewf.name = testfragments.text1

// WFD-0003
var longwfnamewf = cloneDeep(workflow)
longwfnamewf.name = testfragments.text100

// WFD-0004
var toolongwfnamewf = cloneDeep(workflow)
toolongwfnamewf.name = testfragments.text101

// WFD-0005
var specialcharwfnamewf = cloneDeep(workflow)
specialcharwfnamewf.name = testfragments.textspecial


// Test workflow description

// WFD-0006
var nullwfdescwf = cloneDeep(workflow)
nullwfdescwf.description = null

// WFD-0007
var shortwfdescwf = cloneDeep(workflow)
shortwfdescwf.description = testfragments.text1

// WFD-0008
var longwfdescwf = cloneDeep(workflow)
longwfdescwf.description = testfragments.text1000

// WFD-0009
var toolongwfdescwf = cloneDeep(workflow)
toolongwfdescwf.description = testfragments.text1001

// WFD-0010
var specialcharwfdescwf = cloneDeep(workflow)
specialcharwfdescwf.description = testfragments.textspecial


let prop

// Test property name

// WFD-0011
var nullpropnamewf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.name = null
nullpropnamewf.properties.push(prop)

// WFD-0012
var shortpropnamewf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.name = testfragments.text1
shortpropnamewf.properties.push(prop)

// WFD-0013
var longpropnamewf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.name = testfragments.text100
longpropnamewf.properties.push(prop)

// WFD-0014
var toolongpropnamewf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.name = testfragments.text101
toolongpropnamewf.properties.push(prop)

// WFD-0015
var specialcharpropnamewf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.name = testfragments.textspecial
specialcharpropnamewf.properties.push(prop)


// Test property description

// WFD-0016
var nullpropdescwf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.description = null
nullpropdescwf.properties.push(prop)

// WFD-0017
var shortpropdescwf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.description = testfragments.text1
shortpropdescwf.properties.push(prop)

// WFD-0018
var longpropdescwf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.description = testfragments.text1000
longpropdescwf.properties.push(prop)

// WFD-0019
var toolongpropdescwf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.description = testfragments.text1001
toolongpropdescwf.properties.push(prop)

// WFD-0020
var specialcharpropdescwf = cloneDeep(workflow)
prop = {...testfragments.string}
prop.description = testfragments.textspecial
specialcharpropdescwf.properties.push(prop)


// Test property types

// WFD-0021
var stringwf = cloneDeep(workflow)
prop = {...testfragments.string}
stringwf.properties.push(prop)

// WFD-0022
var string_multiwf = cloneDeep(workflow)
prop = {...testfragments.string_multi}
string_multiwf.properties.push(prop)

// WFD-0023
var dropdown_multiwf = cloneDeep(workflow)
prop = {...testfragments.dropdown_multi}
dropdown_multiwf.properties.push(prop)

// WFD-0024
var list_multiwf = cloneDeep(workflow)
prop = {...testfragments.list_multi}
list_multiwf.properties.push(prop)

// WFD-0025
var list_singlewf = cloneDeep(workflow)
prop = {...testfragments.list_single}
list_singlewf.properties.push(prop)

// WFD-0026
var dropdown_singlewf = cloneDeep(workflow)
prop = {...testfragments.dropdown_single}
dropdown_singlewf.properties.push(prop)

// WFD-0028
//var passwordwf = cloneDeep(workflow)
//prop = {...testfragments.password}
//passwordwf.properties.push(prop)

// WFD-0029
var telephonewf = cloneDeep(workflow)
prop = {...testfragments.telephone}
telephonewf.properties.push(prop)

// WFD-0030
var usstateabbrevwf = cloneDeep(workflow)
prop = {...testfragments.usstateabbrev}
usstateabbrevwf.properties.push(prop)

// WFD-0031
var emailwf = cloneDeep(workflow)
prop = {...testfragments.email}
emailwf.properties.push(prop)

// WFD-0032
var uszipcodewf = cloneDeep(workflow)
prop = {...testfragments.uszipcode}
uszipcodewf.properties.push(prop)

// WFD-0033
var numberwf = cloneDeep(workflow)
prop = {...testfragments.number}
numberwf.properties.push(prop)

// WFD-0034
var objectwf = cloneDeep(workflow)
prop = {...testfragments.object}
objectwf.properties.push(prop)

// WFD-0035
var object_maxwf = cloneDeep(workflow)
prop = {...testfragments.object_max}
object_maxwf.properties.push(prop)

// WFD-0036
var object_overmaxwf = cloneDeep(workflow)
prop = {...testfragments.object_overmax}
object_overmaxwf.properties.push(prop)

// WFD-0037
var jsonwf = cloneDeep(workflow)
prop = {...testfragments.json}
jsonwf.properties.push(prop)



// Test property types - default values

// WFD-0038
var stringwf_max = cloneDeep(workflow)
prop = {...testfragments.string}
prop.name = `prop1`
stringwf_max.defaultInstanceData = {prop1: testfragments.text1000}
stringwf_max.properties.push(prop)

// WFD-0039
var stringwf_overmax = cloneDeep(workflow)
prop = {...testfragments.string}
prop.name = `prop1`
stringwf_overmax.defaultInstanceData = {prop1: testfragments.text1001}
stringwf_overmax.properties.push(prop)

// WFD-0040
var string_multiwf_max = cloneDeep(workflow)
prop = {...testfragments.string_multi}
prop.name = `prop1`
string_multiwf_max.defaultInstanceData = {prop1: testfragments.text2500}
string_multiwf_max.properties.push(prop)

// WFD-0041
var string_multiwf_overmax = cloneDeep(workflow)
prop = {...testfragments.string_multi}
prop.name = `prop1`
string_multiwf_overmax.defaultInstanceData = {prop1: testfragments.text2501}
string_multiwf_overmax.properties.push(prop)

// WFD-0042
var dropdown_multiwf_valid = cloneDeep(workflow)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
dropdown_multiwf_valid.defaultInstanceData = {prop1: ['Option 1', 'Option 2']}
dropdown_multiwf_valid.properties.push(prop)

// WFD-0043
var dropdown_multiwf_invalid = cloneDeep(workflow)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
dropdown_multiwf_invalid.defaultInstanceData = {prop1: ['Option 1', 'Option A']}
dropdown_multiwf_invalid.properties.push(prop)

// WFD-0044
var list_multiwf_valid = cloneDeep(workflow)
prop = {...testfragments.list_multi}
prop.name = `prop1`
list_multiwf_valid.defaultInstanceData = {prop1: ['Option 1','Option 2']}
list_multiwf_valid.properties.push(prop)

// WFD-0045
var list_multiwf_invalid = cloneDeep(workflow)
prop = {...testfragments.list_multi}
prop.name = `prop1`
list_multiwf_invalid.defaultInstanceData = {prop1: ['Option 1', 'Option A']}
list_multiwf_invalid.properties.push(prop)

// WFD-0046
var list_singlewf_valid = cloneDeep(workflow)
prop = {...testfragments.list_single}
prop.name = `prop1`
list_singlewf_valid.defaultInstanceData = {prop1: 'Option 1'}
list_singlewf_valid.properties.push(prop)

// WFD-0047
var list_singlewf_invalid = cloneDeep(workflow)
prop = {...testfragments.list_single}
prop.name = `prop1`
list_singlewf_invalid.defaultInstanceData = {prop1: 'Option A'}
list_singlewf_invalid.properties.push(prop)

// WFD-0048
var dropdown_singlewf_valid = cloneDeep(workflow)
prop = {...testfragments.dropdown_single}
prop.name = `prop1`
dropdown_singlewf_valid.defaultInstanceData = {prop1: 'Option 1'}
dropdown_singlewf_valid.properties.push(prop)

// WFD-0049
var dropdown_singlewf_invalid = cloneDeep(workflow)
prop = {...testfragments.dropdown_single}
prop.name = `prop1`
dropdown_singlewf_invalid.defaultInstanceData = {prop1: 'Option A'}
dropdown_singlewf_invalid.properties.push(prop)

// WFD-0050
//var membergroupwf = cloneDeep(workflow)
//prop = {...testfragments.membergroup}
//membergroupwf.properties.push(prop)

// WFD-0051
//var passwordwf = cloneDeep(workflow)
//prop = {...testfragments.password}
//passwordwf.properties.push(prop)

// WFD-0052
var telephonewf_valid = cloneDeep(workflow)
prop = {...testfragments.telephone}
prop.name = `prop1`
telephonewf_valid.defaultInstanceData = {prop1: '555-555-5555'}
telephonewf_valid.properties.push(prop)

// WFD-0053
var telephonewf_invalid = cloneDeep(workflow)
prop = {...testfragments.telephone}
prop.name = `prop1`
telephonewf_invalid.defaultInstanceData = {prop1: '555-555-55554'}
telephonewf_invalid.properties.push(prop)

// WFD-0054
var usstateabbrevwf_valid = cloneDeep(workflow)
prop = {...testfragments.usstateabbrev}
prop.name = `prop1`
usstateabbrevwf_valid.defaultInstanceData = {prop1: 'CA'}
usstateabbrevwf_valid.properties.push(prop)

// WFD-0055
var usstateabbrevwf_invalid = cloneDeep(workflow)
prop = {...testfragments.usstateabbrev}
prop.name = `prop1`
usstateabbrevwf_invalid.defaultInstanceData = {prop1: 'ZZ'}
usstateabbrevwf_invalid.properties.push(prop)

// WFD-0056
var emailwf_valid = cloneDeep(workflow)
prop = {...testfragments.email}
prop.name = `prop1`
emailwf_valid.defaultInstanceData = {prop1: 'joe@acme.com'}
emailwf_valid.properties.push(prop)

// WFD-0057
var emailwf_invalid = cloneDeep(workflow)
prop = {...testfragments.email}
prop.name = `prop1`
emailwf_invalid.defaultInstanceData = {prop1: 'joe@acme@com'}
emailwf_invalid.properties.push(prop)

// WFD-0058
var uszipcodewf_valid = cloneDeep(workflow)
prop = {...testfragments.uszipcode}
prop.name = `prop1`
uszipcodewf_valid.defaultInstanceData = {prop1: '90000'}
uszipcodewf_valid.properties.push(prop)

// WFD-0059
var uszipcodewf_invalid = cloneDeep(workflow)
prop = {...testfragments.uszipcode}
prop.name = `prop1`
uszipcodewf_invalid.defaultInstanceData = {prop1: '90000A'}
uszipcodewf_invalid.properties.push(prop)

// WFD-0060
var numberwf_valid = cloneDeep(workflow)
prop = {...testfragments.number}
prop.name = `prop1`
numberwf_valid.defaultInstanceData = {prop1: 100}
numberwf_valid.properties.push(prop)

// WFD-0061
var numberwf_invalid = cloneDeep(workflow)
prop = {...testfragments.number}
prop.name = `prop1`
numberwf_invalid.defaultInstanceData = {prop1: 'AA'}
numberwf_invalid.properties.push(prop)

// WFD-0062
var dropdown_multiwf_valid2 = cloneDeep(workflow)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
prop.maxItems = 2
dropdown_multiwf_valid2.defaultInstanceData = {prop1: ['Option 1', 'Option 2']}
dropdown_multiwf_valid2.properties.push(prop)

// WFD-0063
var dropdown_multiwf_invalid2 = cloneDeep(workflow)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
prop.maxItems = 2
dropdown_multiwf_invalid2.defaultInstanceData = {prop1: ['Option 1', 'Option 2', 'Option 3']}
dropdown_multiwf_invalid2.properties.push(prop)

// WFD-0064
var list_multiwf_valid2 = cloneDeep(workflow)
prop = {...testfragments.list_multi}
prop.name = `prop1`
prop.maxItems = 2
list_multiwf_valid2.defaultInstanceData = {prop1: ['Option 1','Option 2']}
list_multiwf_valid2.properties.push(prop)

// WFD-0065
var list_multiwf_invalid2 = cloneDeep(workflow)
prop = {...testfragments.list_multi}
prop.name = `prop1`
prop.maxItems = 2
list_multiwf_invalid2.defaultInstanceData = {prop1: ['Option 1', 'Option 2', 'Option 3']}
list_multiwf_invalid2.properties.push(prop)


// WFD-0066
//var objectwf = cloneDeep(workflow)
//prop = {...testfragments.object}
//objectwf.properties.push(prop)

// WFD-0067
//var object_maxwf = cloneDeep(workflow)
//prop = {...testfragments.object_max}
//object_maxwf.properties.push(prop)

// WFD-0068
//var object_overmaxwf = cloneDeep(workflow)
//prop = {...testfragments.object_overmax}
//object_overmaxwf.properties.push(prop)

// WFD-0069
var jsonwf_valid = cloneDeep(workflow)
prop = {...testfragments.json}
prop.name = `prop1`
jsonwf_valid.defaultInstanceData = {prop1: `{"validjsonprop": "validjsonvalue"}`}
jsonwf_valid.properties.push(prop)

// WFD-0070
var jsonwf_invalid = cloneDeep(workflow)
prop = {...testfragments.json}
prop.name = `prop1`
jsonwf_invalid.defaultInstanceData = {prop1: `{invalidjsonprop: "validjsonvalue"}`}
jsonwf_invalid.properties.push(prop)

// WFD-0071
var jsonwf_invalid2 = cloneDeep(workflow)
prop = {...testfragments.json}
prop.name = `prop1`
jsonwf_invalid2.defaultInstanceData = {prop1: `{"validjsonprop": invalidjsonvalue}`}
jsonwf_invalid2.properties.push(prop)




const workflowdetails = [{
	name: "Workflow details test collection",
	tests: [

		{
			id: 'WFD-SETUP',
			name: 'create simple workflow.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(workflow_simple)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [

				// null name
				{
					id: 'WFD-0001',
					name: 'null name',
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(nullwfnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				// short name
				{
					id: 'WFD-0002',
					name: 'short name', 			
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(shortwfnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},

				// long name
				{
					id: 'WFD-0003',
					name: 'long name', 			
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(longwfnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// too long name
				{
					id: 'WFD-0004',
					name: 'too long name', 			
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(toolongwfnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},
			
				// special char name
				{
					id: 'WFD-0005',
					name: 'special char name', 	
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(specialcharwfnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},

				// null description
				{
					id: 'WFD-0006',
					name: 'null desc', 				
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(nullwfdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// short description
				{
					id: 'WFD-0007',
					name: 'short desc', 			
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(shortwfdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// long description
				{
					id: 'WFD-0008',
					name: 'long desc', 			
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(longwfdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// too long description
				{
					id: 'WFD-0009',
					name: 'too long desc', 			
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(toolongwfdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},
				
				// special char description
				{
					id: 'WFD-0010',
					name: 'special char desc', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(specialcharwfdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},

				// null prop name
				{
					id: 'WFD-0011',
					name: 'null prop name', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(nullpropnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_invalid_property_name"`]
				},
													
				
				// short prop name
				{
					id: 'WFD-0012',
					name: 'short prop name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(shortpropnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// long prop name
				{
					id: 'WFD-0013',
					name: 'long prop name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(longpropnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
		
				// too long prop name
				{
					id: 'WFD-0014',
					name: 'too long prop name', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(toolongpropnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},

				// special char prop name
				{
					id: 'WFD-0015',
					name: 'special char prop name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(specialcharpropnamewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// null prop description
				{
					id: 'WFD-0016',
					name: 'null prop description', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(nullpropdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// short prop description
				{
					id: 'WFD-0017',
					name: 'short prop description', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(shortpropdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// long prop description
				{
					id: 'WFD-0018',
					name: 'long prop description', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(longpropdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// too long prop description
				{
					id: 'WFD-0019',
					name: 'too long prop description', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(toolongpropdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},

				// special char prop description
				{
					id: 'WFD-0020',
					name: 'special char prop description', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(specialcharpropdescwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},



				// stringwf
				{
					id: 'WFD-0021',
					name: 'stringwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(stringwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// string_multiwf
				{
					id: 'WFD-0022',
					name: 'string_multiwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(string_multiwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// dropdown_multiwf
				{
					id: 'WFD-0023',
					name: 'dropdown_multiwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_multiwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":null},"validationErrors":[],"properties":null}]}`
				},

				// list_multiwf
				{
					id: 'WFD-0024',
					name: 'list_multiwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(list_multiwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":null},"validationErrors":[],"properties":null}]}`
				},

				// list_singlewf
				{
					id: 'WFD-0025',
					name: 'list_singlewf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(list_singlewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// dropdown_singlewf
				{
					id: 'WFD-0026',
					name: 'dropdown_singlewf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_singlewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// passwordwf
//				{
//					id: 'WFD-0028',
//					name: 'passwordwf', 
//					type: 'positive',
//					operation: `mutation {updateWorkflow (input: ${stringify(passwordwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
//					assertResult: `{"validationErrors":[],"properties":[{"validationErrors":[],"properties":null},{"validationErrors":[],"properties":null}]}`
//				},

				// telephonewf
				{
					id: 'WFD-0029',
					name: 'telephonewf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(telephonewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// usstateabbrevwf
				{
					id: 'WFD-0030',
					name: 'usstateabbrevwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(usstateabbrevwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// emailwf
				{
					id: 'WFD-0031',
					name: 'emailwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(emailwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// uszipcodewf
				{
					id: 'WFD-0032',
					name: 'uszipcodewf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(uszipcodewf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// numberwf
				{
					id: 'WFD-0033',
					name: 'numberwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(numberwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// objectwf
				{
					id: 'WFD-0034',
					name: 'objectwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(objectwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null},{"items":null,"validationErrors":[],"properties":null}]}]}`
				},

				// object_maxwf
				{
					id: 'WFD-0035',
					name: 'object_maxwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(object_maxwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null},{"items":null,"validationErrors":[],"properties":null}]}]}]}]}`
				},

				// object_overmaxwf
				{
					id: 'WFD-0036',
					name: 'object_overmaxwf', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(object_overmaxwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_property_nested_too_deeply`]
				},

				// jsonwf
				{
					id: 'WFD-0037',
					name: 'jsonwf', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(jsonwf)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},



				// stringwf_max
				{
					id: 'WFD-0038',
					name: 'stringwf_max', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(stringwf_max)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// stringwf_overmax
				{
					id: 'WFD-0039',
					name: 'stringwf_overmax', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(stringwf_overmax)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxlength`]
				},

				// string_multiwf_max
				{
					id: 'WFD-0040',
					name: 'string_multiwf_max', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(string_multiwf_max)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// string_multiwf_overmax
				{
					id: 'WFD-0041',
					name: 'string_multiwf_overmax', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(string_multiwf_overmax)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxlength`]
				},


				// dropdown_multiwf_valid
				{
					id: 'WFD-0042',
					name: 'dropdown_multiwf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_multiwf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},

				// dropdown_multiwf_invalid
				{
					id: 'WFD-0043',
					name: 'dropdown_multiwf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_multiwf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// list_multiwf_valid
				{
					id: 'WFD-0044',
					name: 'list_multiwf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(list_multiwf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},

				// list_multiwf_invalid
				{
					id: 'WFD-0045',
					name: 'list_multiwf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(list_multiwf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// list_singlewf_valid
				{
					id: 'WFD-0046',
					name: 'list_singlewf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(list_singlewf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// list_singlewf_invalid
				{
					id: 'WFD-0047',
					name: 'list_singlewf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(list_singlewf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// dropdown_singlewf_valid
				{
					id: 'WFD-0048',
					name: 'dropdown_singlewf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_singlewf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// dropdown_singlewf_invalid
				{
					id: 'WFD-0049',
					name: 'dropdown_singlewf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_singlewf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},


//50
//var membergroupwf = cloneDeep(workflow)
//prop = {...testfragments.membergroup}
//membergroupwf.properties.push(prop)

//51
//var passwordwf = cloneDeep(workflow)
//prop = {...testfragments.password}
//passwordwf.properties.push(prop)

				// telephonewf_valid
				{
					id: 'WFD-0052',
					name: 'telephonewf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(telephonewf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// telephonewf_invalid
				{
					id: 'WFD-0053',
					name: 'telephonewf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(telephonewf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// usstateabbrevwf_valid
				{
					id: 'WFD-0054',
					name: 'usstateabbrevwf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(usstateabbrevwf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// usstateabbrevwf_invalid
				{
					id: 'WFD-0055',
					name: 'usstateabbrevwf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(usstateabbrevwf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// emailwf_valid
				{
					id: 'WFD-0056',
					name: 'emailwf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(emailwf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// emailwf_invalid
				{
					id: 'WFD-0057',
					name: 'emailwf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(emailwf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// uszipcodewf_valid
				{
					id: 'WFD-0058',
					name: 'uszipcodewf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(uszipcodewf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// uszipcodewf_invalid
				{
					id: 'WFD-0059',
					name: 'uszipcodewf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(uszipcodewf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// numberwf_valid
				{
					id: 'WFD-0060',
					name: 'numberwf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(numberwf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// numberwf_invalid
				{
					id: 'WFD-0061',
					name: 'numberwf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(numberwf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// dropdown_multiwf_valid2
				{
					id: 'WFD-0062',
					name: 'dropdown_multiwf_valid2', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_multiwf_valid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},

				// dropdown_multiwf_invalid2
				{
					id: 'WFD-0063',
					name: 'dropdown_multiwf_invalid2', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(dropdown_multiwf_invalid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxitems`]
				},

				// list_multiwf_valid2
				{
					id: 'WFD-0064',
					name: 'list_multiwf_valid2', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(list_multiwf_valid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},

				// list_multiwf_invalid2
				{
					id: 'WFD-0065',
					name: 'list_multiwf_invalid2', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(list_multiwf_invalid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxitems`]
				},


//66
//var objectwf = cloneDeep(workflow)

//67
//var object_maxwf = cloneDeep(workflow)

//68
//var object_overmaxwf = cloneDeep(workflow)


				// jsonwf_valid
				{
					id: 'WFD-0069',
					name: 'jsonwf_valid', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(jsonwf_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// jsonwf_invalid
				{
					id: 'WFD-0070',
					name: 'jsonwf_invalid', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(jsonwf_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_invalid_json`]
				},

				// jsonwf_invalid2
				{
					id: 'WFD-0071',
					name: 'jsonwf_invalid2', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(jsonwf_invalid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_invalid_json`]
				},

				// now delete the draft workflow
				{
					id: 'WFD-CLEANUP',
					name: 'delete base workflow',
					type: 'cleanup',
					operation: `mutation {deleteWorkflow (workflowId: "<<workflowId>>") {workflowId}}`, 
				},

			]
		},

	],
}]

export default workflowdetails

