import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import ExtractList from '../components/ExtractList'
import { retrieveExtract, retrieveExtracts, createExtract } from '../reducers/extracts'
import { createDocument, putContent, retrieveContent } from '../reducers/documents'


const ExtractListView = ({ screen, navigation, tagFilter, extractsList, extractsCount, extracts, documents, 
							createDocument, putContent, retrieveContent, createExtract, retrieveExtracts, retrieveExtract }) => {
 
//	console.log('ExtractListView')

	const onSelect = (extract) => {
		if (!extract.created) {retrieveExtract(extract.extractId)}
		navigation.navigate('extract', {extractId: extract.extractId})
	}

	return (
		<ExtractList
			navigation={navigation}
			tagFilter={tagFilter}
			extractsList={extractsList}
			extractsCount={extractsCount}
			extracts={extracts}
			documents={documents}
			onSelect={onSelect}
			createDocument={createDocument}
			putContent={putContent}
			retrieveContent={retrieveContent}
			createExtract={createExtract}
			retrieveExtracts={retrieveExtracts}
			retrieveExtract={retrieveExtract}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	extractsList: state.app.extractsList,
	extractsCount: state.app.extractsCount,
	extracts: state.extracts,
	documents: state.documents,
})

export default connect(mapStateToProps, { retrieveExtracts, retrieveExtract, createDocument, putContent, retrieveContent, createExtract })(ExtractListView)
