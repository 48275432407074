import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { ImageList, ImageListItem, ListSubheader, ImageListItemBar } from '@mui/material'
import { Info } from '@mui/icons-material'
import * as i18 from 'i18next'


const images = [
"https://images.pexels.com/photos/1040157/pexels-photo-1040157.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/374079/pexels-photo-374079.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/1024248/pexels-photo-1024248.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/684385/pexels-photo-684385.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/826349/pexels-photo-826349.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/1015568/pexels-photo-1015568.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/585419/pexels-photo-585419.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/273222/pexels-photo-273222.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/935756/pexels-photo-935756.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/110078/pexels-photo-110078.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/315785/pexels-photo-315785.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/669610/pexels-photo-669610.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/259132/pexels-photo-259132.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/164467/pexels-photo-164467.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/318820/pexels-photo-318820.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/210607/pexels-photo-210607.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
"https://images.pexels.com/photos/209137/pexels-photo-209137.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=330",
]

const ImageRefDialog = ({ imageDialogIsOpen, workflow, updateWorkflow, setImageDialogIsOpen }) => {

	return (
		<Dialog style={{flex: 1}} open={imageDialogIsOpen}>
			<DialogTitle><b>{i18.t('dialog_image_ref_title')}</b></DialogTitle>
			<DialogContent style={{flex: 1}}>
				<ImageList>
					<ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }}>
						<ListSubheader component="div">{i18.t('dialog_image_ref_desc')}</ListSubheader>
					</ImageListItem>
					{images.map((img, index) => (
						<ImageListItem key={index} >
							<img src={img} alt={img} onClick={(event)=>{workflow.imageref=event.target.src;updateWorkflow(workflow);setImageDialogIsOpen(false);}} />
							<ImageListItemBar
								title={img}
								subtitle={<span>by: {img}</span>}
								actionIcon={<Button><Info/></Button>}
							/>
						</ImageListItem>
					))}
				</ImageList>
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setImageDialogIsOpen(false)} color="secondary">
					{i18.t('dialog_cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ImageRefDialog