import { schema, normalize, denormalize } from 'normalizr'
import { Buffer } from "buffer"
import cloneDeep from 'lodash.clonedeep'

import { RECEIVE_DOCUMENT, RECEIVE_DOCUMENTS, UPDATE_DOCUMENT, DELETE_DOCUMENT, RECEIVE_DOCUMENT_CONTENT, RECEIVE_PROPERTIES, RECEIVE_ERRORS } from './actiontypes'
import { ADD_PROPERTY_EVENT, DELETE_PROPERTY_EVENT, MOVE_PROPERTY_EVENT, MOVE_TO_COMPOSITE_PROPERTY_EVENT } from './actiontypes'
import { removeMeta, removeValueAndDefault, removeArrayProperties, propertySchema, setValuesFromInstanceData, setDefaultsFromDefaultInstanceData, getInstanceDataFromValues, getInstanceDataFromDefaults } from '../reducers/workflows'
import { gqlExec, gqlInput, gqlIdInput } from '../reducers/graphql'
import { documentReturnFragment, documentMaxFragment, documentMinFragment, propertyFragment, permissionFragment, permissionDelegateFragment, limitedOrgEntityFragment } from '../reducers/graphql'
import { commentFragment, extractionRuleFragment, contentFragment, extractReturnFragment, extractMaxFragment } from '../reducers/graphql'
import { setNewPropertyIds } from '../reducers/properties'
import { generateHashCode } from '../reducers/utils'
import { store } from '../App'


export const documentSchema = new schema.Entity(
	'documents', 
	{ 
	}, 
	{ 
		idAttribute: value => value.documentId,
	}
)


// Document Functions
const getImageSize = (buffer, mimeType) => {
	if (!['image/png', 'image/jpeg', 'png'].includes(mimeType)) return
	return new Promise((resolve, reject) => {
		let img = document.createElement('img')
		img.src = `data:${mimeType};base64,${buffer}`
		img.onload = function() {
			resolve({width: this.width, height: this.height})
		}
	})
}

const postDocumentUpdates = (documents) => {

//	console.log('postDocumentUpdates', documents)

	if (documents) {

		let properties={}

		for (let i=0; i<documents.length; ++i) {

			if(!documents[i]) throw ("Invalid document")
			
			let doc = documents[i]

			// Set values from instance data
			if (doc.instanceData && doc.properties) {
				for (const property of doc.properties) {
					setValuesFromInstanceData(property, doc?.instanceData?.[property.pname])
				}
			}

			// Set defaults from default instance data
			if (doc.defaultInstanceData && doc.properties) {
				for (const property of doc.properties) {
					setDefaultsFromDefaultInstanceData(property, doc?.defaultInstanceData?.[property.pname])
				}
			}

			// Normalize the properties
			if (doc.properties) {
				let propertydata = normalize(doc.properties, [propertySchema])
				properties[doc.documentId] = propertydata.entities.properties
				doc.properties = propertydata.result
			}

			doc.detailRetrieved = Date.now()
		}
							
		// Now normalize the document data
		let documentdata = normalize(documents, [documentSchema])
	
		return {list: documentdata.result, documents: documentdata.entities.documents, properties: properties}
	}
}

export const retrieveDocuments = (listType, skip=0, tagFilter, scope) => async dispatch => {

//	console.log('retrieveDocuments', listType)

	try {
		document.body.style.cursor = 'wait'

		let states = []
		states = (listType === 'templateDocuments') ?
			states = ['template']
		: (listType === 'workingDocuments') ?
			states = ['working']
		: null

		const response = await gqlExec(`{documents ${gqlInput(states, tagFilter, skip, true, true, true)} {...documentReturnFields}} ${documentReturnFragment} ${documentMinFragment}`)		

		const documents = response && response.documents ? response.documents : null

		if (documents) {
			let action = postDocumentUpdates(documents.items)
			action.type = RECEIVE_DOCUMENTS
			action.listType = listType
			action.skip = skip
			action.listCount = documents.totalCount
			action.tagList = documents.tagList
			dispatch(action)
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

function removeNullProps(data) {
	
	let keys = Object.keys(data)
	if (keys) {
		for (const key of keys) {
			let prop = data[key]
			if (prop === null) {
				delete data[key]
			} else if (typeof prop === 'object' || typeof prop === 'array') {
				removeNullProps(prop)
			}
		}
	}
}

export const retrieveDocument = (documentId, sourceType, sourceId) => async dispatch => {

//	console.log('retrieveDocument', documentId)

	if (!documentId) return
	
	try {
		document.body.style.cursor = 'wait'

		const sourceTypeIdConstruct = sourceType && sourceId ? `, sourceType: "${sourceType}", sourceId: "${sourceId}"` : ``
		const response = await gqlExec(`query RetrieveDocument {getDocument (documentId: "${documentId}" ${sourceTypeIdConstruct}) {...documentFields}} ${documentMaxFragment} ${propertyFragment} ${permissionFragment} ${permissionDelegateFragment} ${limitedOrgEntityFragment} ${commentFragment} ${extractionRuleFragment} `)

		let doc = response && response.getDocument ? response.getDocument : null

		if (doc) {
			removeNullProps(doc)
			doc.hash = generateHashCode(doc)
			let action = postDocumentUpdates([doc])
			action.type = RECEIVE_DOCUMENT
			dispatch(action)
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const denormalizeDocument = (documentId) => {

	let doc = cloneDeep(store.getState().documents[documentId])

	let properties = store.getState().properties[documentId]

	if (!doc) return

	// De-normalize the property list back into the doc
	doc.properties = denormalize(doc.properties, [propertySchema], {properties: properties});

	// Extract the instance data from the properties
	doc.instanceData = {}
	doc.defaultInstanceData = {}
	if (doc.properties) {
		for (const property of doc.properties) {
			if (property.pname) {
				doc.instanceData[property.pname] = getInstanceDataFromValues(property)
				doc.defaultInstanceData[property.pname] = getInstanceDataFromDefaults(property)
			}
		}
	}

	return doc
}

export const saveDocument = (documentId, sourceType, sourceId, setStatusIndicator) => async dispatch => {

//	console.log('saveDocument', doc)

	try {
		document.body.style.cursor = 'wait'

		if (setStatusIndicator) {setStatusIndicator(true)}
	
		let doc = denormalizeDocument(documentId)

		if (doc) {

			let hash = doc.hash
			const content = doc.content
			
			// Delete properties added for operational activities
			delete doc.detailRetrieved
			delete doc.hash
			removeMeta(doc, 'parentpropertyId')
			removeMeta(doc, 'modified')

			if (generateHashCode(doc) === hash) {
console.log(`Save not needed!`)
				return
			}

			if (doc.state === 'working') {
				// Can't update properties, or defaultInstanceData on a working document
				delete doc.properties
				delete doc.defaultInstanceData
			} else {
				// Can't update instanceData on a non-working document
				delete doc.instanceData
			}

			delete doc.state
			delete doc.templateDocumentId
			delete doc.documentdata
			delete doc.content
			delete doc.hasContent
			delete doc.comments
			delete doc.created
			delete doc.createdBy
			delete doc.updatedBy

			removeMeta(doc, 'validationErrors')
			removeMeta(doc, 'parentpropertyId')
			removeMeta(doc, 'version')
			removeMeta(doc, 'domain')
			removeMeta(doc, 'terms')
			removeMeta(doc.properties, 'tags')

			removeValueAndDefault(doc.properties)
			removeArrayProperties(doc.properties)
				
			const sourceTypeIdConstruct = sourceType && sourceId ? `, sourceType: "${sourceType}", sourceId: "${sourceId}"` : ``
			const response = await gqlExec(`mutation {updateDocument (input: ${JSON.stringify(doc)} ${sourceTypeIdConstruct}) {...documentFields}} ${documentMaxFragment} ${propertyFragment} ${permissionFragment} ${permissionDelegateFragment} ${limitedOrgEntityFragment} ${commentFragment} ${extractionRuleFragment} `)

			let document2 = response ? response.updateDocument : null

			if (document2) {
				if (content) document2.content = content			// if we've already retrieved the content - then hold onto it.
				document2.hash = generateHashCode(document2)
				let action = postDocumentUpdates([document2])
				action.type = RECEIVE_DOCUMENT
				dispatch(action)
			}
		}
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
}

export const saveDocumentAs = (documentId, state, setStatusIndicator) => async dispatch => {
}

export const deleteDocument = (documentId, state, navigation, setStatusIndicator) => async dispatch => {

//	console.log('deleteDocument', documentId)

	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`mutation DeleteDocument {deleteDocument (documentId: "${documentId}") {documentId}}`)		

		dispatch({type: DELETE_DOCUMENT, documentId: response?.deleteDocument})
		
		if (state === 'template') {
			dispatch(retrieveDocuments("templateDocuments"))
			navigation.reset('document_template_list')
		}
		
		if (state === 'working') {		
			dispatch(retrieveDocuments("workingDocuments"))
			navigation.reset('document_working_list')
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}

export const updateDocument = (doc) => dispatch => {
	dispatch({type: UPDATE_DOCUMENT, doc: doc})
}

export const retrieveDocumentDetail = (documentId) => async dispatch => {

//	console.log('retrieveDocumentDetail')

	if (!documentId) return
	
	const googleSession = JSON.parse(localStorage.getItem('googleSession'))

	let headers = {headers: {'Authorization': `${googleSession?.token_type} ${googleSession.access_token}`}, responseType: 'blob'}		

	let res = await fetch(`https://www.googleapis.com/drive/v3/files/${documentId}?fields=kind,id,name,mimeType,webViewLink,iconLink,thumbnailLink,imageMediaMetadata`, headers)

	if (res.status === 200) {
		const buffer = []
		let reader = res.body.getReader()
		reader.read().then(async function processText({done, value}) {
			if (done) {
				let metadata = JSON.parse(Buffer.concat(buffer).toString())
				dispatch({type: RECEIVE_DOCUMENT_CONTENT, documentId: documentId, metadata: metadata})
			} else {
				buffer.push(Buffer.from(value))
				return reader.read().then(processText)
			}
		})
	}
}

export const createDocument = (navigation, input, tagFilter) => async dispatch => {

//	console.log('createDocument', input)

	try {
		document.body.style.cursor = 'wait'

		if (!input) input = {}
		if (!input.name) input.name = '<Document>'
		if (!input.state) input.state = 'working'
		
		const response = await gqlExec(`mutation {createDocument (input: ${JSON.stringify(input)}) {...documentFields}} ${documentMaxFragment} ${propertyFragment} ${permissionFragment} ${permissionDelegateFragment} ${limitedOrgEntityFragment} ${commentFragment} ${extractionRuleFragment} `)

		let doc = response.createDocument

		if (doc) {
			doc.hash = generateHashCode(doc)
			let action = postDocumentUpdates([doc])
			action.type = RECEIVE_DOCUMENT
			await dispatch(action)

			if (doc.state === 'working') {
				dispatch(retrieveDocuments('workingDocuments', 0, tagFilter))
				navigation.reset('document_working_list')
			} else if (doc.state === 'template') {
				dispatch(retrieveDocuments('templateDocuments', 0, tagFilter))
				navigation.reset('document_template_list')
			}
			navigation.navigate('document', {documentId: doc.documentId, name: doc.name})
		}
		
		return doc
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const createDocumentInstance = (documentId, permissionDelegate, sourceType, sourceId, setStatusIndicator) => async dispatch => {

//	console.log('createDocumentInstance')

	try {
		document.body.style.cursor = 'wait'

		if (setStatusIndicator) {setStatusIndicator(true)}

		// Save the current document before calling createDocumentInstance
		await dispatch(saveDocument(documentId))

		let doc = cloneDeep(store.getState().documents[documentId])

		// If supplied, set the permissionDelegate
		if (permissionDelegate) doc.delegatedPermissions = [permissionDelegate]
		
		// Update mutation if there are initial properties
		if (doc && doc.properties) {

			const properties = store.getState().properties[doc.documentId]

			if (properties) {
				doc.properties = denormalize(doc.properties, [propertySchema], {properties: properties});

				doc.instanceData = {}
				if (doc.properties) {
					for (const property of doc.properties) {
						if (property.pname) {
							doc.instanceData[property.pname] = getInstanceDataFromValues(property)
						}
					}
				}	
			}
		}

		let input = {
			documentId: doc.documentId,
			instanceData: doc.instanceData,
		}

		if (sourceType === 'launchassignment') sourceType = 'workflow'
		
		const sourceTypeIdConstruct = sourceType && sourceId ? `, sourceType: "${sourceType}", sourceId: "${sourceId}"` : ``
		const response = await gqlExec(`mutation {createDocumentInstance (input: ${JSON.stringify(input)} ${sourceTypeIdConstruct}) {...documentFields}} ${documentMaxFragment} ${propertyFragment} ${permissionFragment} ${permissionDelegateFragment} ${limitedOrgEntityFragment} ${commentFragment} ${extractionRuleFragment} `)

		doc = response.createDocumentInstance

		if (doc) {
			doc.hash = generateHashCode(doc)
			let action = postDocumentUpdates([doc])
			action.type = RECEIVE_DOCUMENT
			await dispatch(action)
		}

		return doc

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
}

export const putContent = (documentId, file, sourceType, sourceId) => async dispatch => {

	return new Promise((resolve, reject) => {

		try {
			document.body.style.cursor = 'wait'

			const fileSize = file.size;
			let offset = 0;
			let index = 0;
			const chunkSize = 2 * 1024 * 1024; // 2 MB chunks (adjust as needed)

console.log('upload file in chunks', fileSize, offset, file)

			const readChunk = async () => {
				const reader = new FileReader();
				const chunk = file.slice(offset, offset + chunkSize);

				reader.onload = async (event) => {
					if (event.target.result) {
						// Send the chunk to the API
						await sendChunkToAPI(event.target.result, index++);

						// Update the offset for the next chunk
						offset += chunkSize;

						// Continue reading the next chunk if there is more to read
						if (offset < fileSize) {
							await readChunk();
						} else {
							// File reading is complete
							dispatch(retrieveDocument(documentId, sourceType, sourceId))
							console.log('File reading complete ', index);
							resolve(documentId)
						}
					}
				}

				await reader.readAsArrayBuffer(chunk);
			};

			const sendChunkToAPI = async (chunk, index) => {

				try {
					const base64String = Buffer.from(chunk).toString('base64')

					const sourceTypeIdConstruct = sourceType && sourceId ? `, sourceType: "${sourceType}", sourceId: "${sourceId}"` : ``
					const response = await gqlExec(`mutation {putContent (documentId: "${documentId}", data: "${base64String}", mimeType: "${file.type}", size: ${file.size} ${sourceTypeIdConstruct}) {...documentFields}} ${documentMaxFragment} ${propertyFragment} ${permissionFragment} ${permissionDelegateFragment} ${limitedOrgEntityFragment} ${commentFragment} ${extractionRuleFragment} `)

					let document = response.putContent

					if (document) {
						document.hash = generateHashCode(document)
						dispatch(retrieveDocuments("workingDocuments"))
					}
				} catch(err) {		
					dispatch({type: RECEIVE_ERRORS, errors: err.errors})
				}
			}

			readChunk()
			
		} catch(err) {		
			console.log(err)
			dispatch({type: RECEIVE_ERRORS, errors: err.errors})
			reject(err)
		} finally {
			document.body.style.cursor = 'default'
		}
	})
}

export const retrieveContent = (documentId, sourceType, sourceId) => async dispatch => {

//	console.log('retrieveContent');

	if (!documentId) return
	
	try {
		document.body.style.cursor = 'wait'

		const sourceTypeIdConstruct = sourceType && sourceId ? `, sourceType: "${sourceType}", sourceId: "${sourceId}"` : ``
		const response = await gqlExec(`query {getContent (documentId: "${documentId}" ${sourceTypeIdConstruct}) {...contentFields}} ${contentFragment} `)

		let content = response ? response.getContent : null

		if (content) {
			content.dimensions = await getImageSize(content.data, content.mimeType)			
			dispatch({type: RECEIVE_DOCUMENT_CONTENT, documentId: documentId, content: content})
		}
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const createPropertiesFromExtract = (doc, extractId, setStatusIndicator) => async dispatch => {

	try {
		document.body.style.cursor = 'wait'

		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`query {extracts ${gqlIdInput('extract', extractId)} {...extractReturnFields}} ${extractReturnFragment} ${extractMaxFragment} ${propertyFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${extractionRuleFragment} ${commentFragment} `)

		let extracts = response && response.extracts ? response.extracts.items : null

		if (extracts && extracts[0]) {

			const extract = extracts[0]

			// set the document name & description from the extract
			doc.name = extract.name
			doc.description = extract.description
			
			// clone the properties from the selected extract and give them all new property ids
			doc.properties = cloneDeep(extract.properties)
			setNewPropertyIds(doc.properties)

			if (doc) {
				doc.hash = generateHashCode(doc)
				let action = postDocumentUpdates([doc])
				action.type = RECEIVE_DOCUMENT
				await dispatch(action)
			}

			dispatch(saveDocument(doc.documentId))			
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
	
}

export const analyzeDocument = (documentId, setShowAnalyzeFailDialog, setStatusIndicator) => async dispatch => {

	if (!documentId) return

	try {
		document.body.style.cursor='wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`mutation {analyzeDocument ( documentId: "${documentId}") {...documentFields}} ${documentMaxFragment} ${propertyFragment} ${permissionFragment} ${permissionDelegateFragment} ${limitedOrgEntityFragment} ${commentFragment} ${extractionRuleFragment} ` )

		let doc = response.analyzeDocument

		if (doc) {
			doc.hash = generateHashCode(doc)
			let action = postDocumentUpdates([doc])
			action.type = RECEIVE_DOCUMENT
//			action.listType = 'documents'
			dispatch(action)
		}

	} catch(err) {
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
		setShowAttachmentAnalyzeFailDialog(true)
	} finally {
		document.body.style.cursor = 'default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}


const initialState = {
}

const documents = (state = initialState, action) => {
	switch (action.type) {

		case RECEIVE_DOCUMENT:
		case RECEIVE_DOCUMENTS:
			state = {...state}
			for (let i=0; action.list && i<action.list.length; ++i) {
				state[action.list[i]] = action.documents[action.list[i]]
			}
			break

		case RECEIVE_DOCUMENT_CONTENT:
			state = {...state}
			if (action.content) {
				state[action.documentId].content = action.content
			}
			break

		case UPDATE_DOCUMENT:
			state = {...state,
				[action.doc.documentId]: action.doc,
			}			
			break

// Property Events

		case ADD_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]) {
				if (!state[action.rootparentId].properties) state[action.rootparentId].properties=[]			
				if (state[action.rootparentId].properties.indexOf(action.property.propertyId) === -1) {
					state[action.rootparentId].properties.splice(0, 0, action.property.propertyId)
				}
			}
		}
		break

		case DELETE_PROPERTY_EVENT: {
			state = {...state}
			state[action.rootparentId] = {...state[action.rootparentId]}
			if (state[action.rootparentId] && state[action.rootparentId].properties) {
				let index = state[action.rootparentId].properties.findIndex(propertyId => propertyId === action.propertyId)
				state[action.rootparentId].properties.splice(index, 1)
			}
		} break

		case MOVE_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]  && state[action.rootparentId].properties) {
				state[action.rootparentId].properties.splice(action.toIndex, 0, state[action.rootparentId].properties.splice(action.fromIndex, 1)[0])
			}
		} break

		case MOVE_TO_COMPOSITE_PROPERTY_EVENT: {
			// If this property was a child of the root parent - remove it from there
			state = {...state}
			let keys = Object.keys(state)
			for (const key of keys) {
				let rootParent = state[key][action.rootparentId]
				let index = rootParent && rootParent.properties ? rootParent.properties.indexOf(action.propertyId) : -1
				if (index !== -1) rootParent.properties.splice(index,1)
			}
		} break
		
		default:
			break
	}

	return state;
}

export default documents