import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

import AssignmentListView from '../containers/AssignmentListView'
import AssignmentView from '../containers/AssignmentView'
import WorkflowListView from '../containers/WorkflowListView'
import WorkflowView from '../containers/WorkflowView'
import OrgPropertyListView from '../containers/OrgPropertyListView'
import DocumentListView from '../containers/DocumentListView'
import DocumentView from '../containers/DocumentView'
import ExtractListView from '../containers/ExtractListView'
import ExtractView from '../containers/ExtractView'
import AutomationListView from '../containers/AutomationListView'
import AutomationView from '../containers/AutomationView'
import SupportCaseListView from '../containers/SupportCaseListView'
import GraphiQLView from '../containers/GraphiQLView'
import SupportCaseView from '../containers/SupportCaseView'
import LaunchAssignmentView from '../containers/LaunchAssignmentView'
//import ErrorsView from '../containers/ErrorsView'
import Profile from '../components/Profile'
import OrgSettings from '../components/OrgSettings'
import TestConsole from '../components/TestConsole'

import { Grid } from '@mui/material'
import { AssignmentInd, AccountTree, Share, Settings, ContactSupport, SnippetFolder } from '@mui/icons-material'
import * as i18 from 'i18next'


export const screens = {

	main_menu: {
		title1:  i18.t('main_menu_lg'), 
		title2:  i18.t('main_menu_sm'),
		level: 0,
	},

	assignment_list: {
		title1:  i18.t('menu_assignments_lg'), 
		title2:  i18.t('menu_assignments_sm'),
		level: 0,
		icon: <AssignmentInd/>, 
	},
	assignment_working_list: {
		title1:  i18.t('menu_assignments_inprocess_lg'), 
		title2:  i18.t('menu_assignments_inprocess_sm'), 
		level: 1,
	},
	assignment_completed_list: {
		title1:  i18.t('menu_assignments_completed_lg'), 
		title2:  i18.t('menu_assignments_completed_sm'), 
		level: 1,
	},
	assignment: {
		title1:  i18.t('menu_assignment_lg'), 
		title2:  i18.t('menu_assignment_sm'), 
		level: 0,
		icon: <AssignmentInd/>, 
	},
	launch_assignment: {
		title1:  i18.t('menu_assignment_lg'), 
		title2:  i18.t('menu_assignment_sm'), 
		level: 0,
		icon: <AssignmentInd/>, 
	},

	document_list: {
		title1:  i18.t('menu_documents_lg'), 
		title2:  i18.t('menu_documents_sm'), 
		level: 0,
		icon: <SnippetFolder/>, 
	},
	document_working_list: {
		title1:  i18.t('menu_documents_working_lg'), 
		title2:  i18.t('menu_documents_working_sm'), 
		level: 1,
	},
	document: {
		title1:  i18.t('menu_document_lg'), 
		title2:  i18.t('menu_document_sm'), 
		level: 0,
	},

	workflow_list: {
		title1:  i18.t('menu_workflows_lg'), 
		title2:  i18.t('menu_workflows_sm'), 
		level: 0,
		icon: <AccountTree/>, 
	},
	workflow_draft_list: {
		title1:  i18.t('menu_workflows_draft_lg'), 
		title2:  i18.t('menu_workflows_draft_sm'), 
		level: 1,
	},
	workflow_working_list: {
		title1:  i18.t('menu_workflows_inprocess_lg'), 
		title2:  i18.t('menu_workflows_inprocess_sm'), 
		level: 1,
	},
	workflow_completed_list: {
		title1:  i18.t('menu_workflows_completed_lg'), 
		title2:  i18.t('menu_workflows_completed_sm'), 
		level: 1,
	},
	workflow_template_list: {
		title1:  i18.t('menu_templates_active_lg'), 
		title2:  i18.t('menu_templates_active_sm'), 
		level: 1,
	},
	workflow: {
		title1:  i18.t('menu_workflow_lg'), 
		title2:  i18.t('menu_workflow_sm'), 
		level: 0,
		icon: <AccountTree/>, 
	},

	template_list: {
		title1:  i18.t('menu_templates_lg'), 
		title2:  i18.t('menu_templates_sm'), 
		level: 0,
		icon: <Share/>, 
	},
	document_template_list: {
		title1:  i18.t('menu_documents_template_lg'), 
		title2:  i18.t('menu_documents_template_sm'), 
		level: 1,
	},

	settings: {
		title1:  i18.t('menu_settings_lg'), 
		title2:  i18.t('menu_settings_sm'), 
		level: 0,
		icon: <Settings/>, 
	},
	settings_list: {
		title1:  i18.t('menu_settingslist_lg'), 
		title2:  i18.t('menu_settingslist_sm'), 
		level: 1,
	},
	extract_list: {
		title1:  i18.t('menu_extracttemplates_lg'), 
		title2:  i18.t('menu_extracttemplates_sm'), 
		level: 1,
	},
	extract: {
		title1:  i18.t('menu_extract_lg'), 
		title2:  i18.t('menu_extract_sm'), 
		level: 0,
	},
	automation_list: {
		title1:  i18.t('menu_automations_lg'), 
		title2:  i18.t('menu_automations_sm'), 
		level: 1,
	},
	automation: {
		title1:  i18.t('menu_automation_lg'), 
		title2:  i18.t('menu_automation_sm'), 
		level: 0,
	},
	propertydefinition_list: {
		title1:  i18.t('menu_propertiesdefs_lg'), 
		title2:  i18.t('menu_propertiesdefs_sm'), 
		level: 1,
	},
	profile: {
		title1:  i18.t('menu_profile_lg'), 
		title2:  i18.t('menu_profile_sm'), 
		level: 1,
	},
	test: {
		title1:  i18.t('menu_test_lg'), 
		title2:  i18.t('menu_test_sm'), 
		level: 1,
	},
	graphiql: {
		title1:  i18.t('menu_graphiql_lg'), 
		title2:  i18.t('menu_graphiql_sm'), 
		level: 1,
	},
	supportcase_list: {
		title1:  i18.t('menu_supportcases_lg'), 
		title2:  i18.t('menu_supportcases_sm'), 
		level: 0,
		icon: <ContactSupport/>, 
	},
	supportcase_working_list: {
		title1:  i18.t('menu_supportcases_open_lg'), 
		title2:  i18.t('menu_supportcases_open_sm'), 
		level: 1,
	},
	supportcase_completed_list: {
		title1:  i18.t('menu_supportcases_closed_lg'), 
		title2:  i18.t('menu_supportcases_closed_sm'), 
		level: 1,
	},
	supportcase: {
		title1:  i18.t('menu_supportcase_lg'), 
		title2:  i18.t('menu_supportcase_sm'), 
		level: 0,
	},

}	


const Main = ({ stack, navigation, scope, showSettings, tags, tagFilter, app, orgs, supportcases, documents,
				setTagFilter, retrieveOrg, updateOrg, saveOrg, retrieveMe, updateMe, saveMe, executeTests }) => {

//	console.log('Main')

	var sourceObj
	var sourceId
	var update
	var retrieve
	var save

	let createExtract
	let preUpdateExtract
	let updateExtract
	let deleteExtract

	const googleSession = JSON.parse(localStorage.getItem('googleSession'))
	if (googleSession) {
		if (scope && scope.orgentityId) {
			sourceId = scope.orgentityId
			sourceObj = scope
			
			if (!sourceObj) return null

			// If we dont' have the max org details - retrieve them
			if (!sourceObj.settings) {
				retrieveOrg(sourceId)
			}
			
			retrieve = (orgentityId)=>retrieveOrg(orgentityId)	
			update = (org)=>updateOrg(org)
			save = (org)=>saveOrg(org)
		} else if (app.me) {
			sourceId = app.me.orgentityId
			sourceObj = app.me
			
			if (!sourceObj) return null

			retrieve = (id)=>retrieveMe()
			update = (me)=>updateMe(me)
			save = (me)=>saveMe(me)
		}
	}
	
	navigation.params = stack[stack.length-1].params
	
	const theme = useTheme()
	const xs = useMediaQuery(theme.breakpoints.only('xs'))

	screens.assignment_working_list.control = ()=>(<AssignmentListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='assignment_working_list' />)
	screens.assignment_completed_list.control = ()=>(<AssignmentListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='assignment_completed_list' />)
	screens.assignment.control = ()=>(<AssignmentView navigation={navigation} xs={xs} />)
	screens.launch_assignment.control = ()=>(<LaunchAssignmentView navigation={navigation} xs={xs} />)

	screens.document_working_list.control =  ()=>(<DocumentListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='document_working_list' />)
	screens.document_template_list.control =  ()=>(<DocumentListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='document_template_list' />)
	screens.document.control = ()=>(<DocumentView navigation={navigation} xs={xs} />)
	
	screens.workflow_draft_list.control = ()=>(<WorkflowListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='workflow_draft_list' />)
	screens.workflow_working_list.control = ()=>(<WorkflowListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='workflow_working_list' />)
	screens.workflow_completed_list.control = ()=>(<WorkflowListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='workflow_completed_list' />)
	screens.workflow_template_list.control = ()=>(<WorkflowListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='workflow_template_list' />)
	screens.workflow.control = ()=>(<WorkflowView navigation={navigation} />)

	screens.profile.control = ()=>(<Profile navigation={navigation} xs={xs} app={app} org={sourceObj} orgs={orgs} />)
	screens.settings_list.control = ()=>(<OrgSettings navigation={navigation} xs={xs} documents={documents} org={sourceObj} save={()=>save(sourceObj)} retrieve={()=>retrieve(sourceId)} update={update} />)
	screens.extract_list.control = ()=>(<ExtractListView navigation={navigation} xs={xs} app={app} tagFilter={tagFilter} screen='extract_list' />)
	screens.extract.control = ()=>(<ExtractView navigation={navigation} xs={xs} />)
	screens.automation_list.control = ()=>(<AutomationListView navigation={navigation} xs={xs} app={app} screen='automation_list' />)
	screens.automation.control = ()=>(<AutomationView navigation={navigation} xs={xs} />)
	screens.propertydefinition_list.control = ()=>(<OrgPropertyListView navigation={navigation} xs={xs} sourceId={sourceId} tagFilter={tagFilter} />)
	screens.test.control = ()=>(<TestConsole executeTests={executeTests}/>)
	screens.graphiql.control = ()=>(<GraphiQLView />)

	screens.supportcase_working_list.control = ()=>(<SupportCaseListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='supportcase_working_list' />)
	screens.supportcase_completed_list.control = ()=>(<SupportCaseListView navigation={navigation} xs={xs} tagFilter={tagFilter} screen='supportcase_completed_list' />)
	screens.supportcase.control = ()=>(<SupportCaseView navigation={navigation} xs={xs} />)

//	screens.errors.control = ()=>(<ErrorsView/>)

	const height = document.getElementById('outermain')?.clientHeight
	const width = document.getElementById('outermain')?.clientWidth

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='main' style={{width: width, height: height}} >
			{screens[stack[stack.length-1]?.screen] ? screens[stack[stack.length-1].screen].control() : null}
		</Grid>
	)
}

export default Main
