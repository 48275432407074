import React, { useState } from 'react'
import { Grid, Dialog, DialogContent, DialogActions, Button, TextField, Checkbox, Typography, Divider  } from '@mui/material'
import * as i18 from 'i18next'

import TabBar from '../TabBar'


export const openInNewTab = (url) => {
	if (url) {
		var win = window.open(url, '_blank')
		win.focus()
	}
}

const DocumentAdvDetailsDialog = ({ navigation, doc, versions, designTime, 
									setDetailsDialogIsOpen, updateWorkflow, retrieveVersions, restoreVersion }) => {

	const [tab, setTab] = useState('workflow_advanced_details')
	const [versionsRetrieved, setVersionsRetrieved] = useState()
	const [hover, setHover] = useState()

	if (tab === 'workflow_advanced_versions' && !versionsRetrieved) {
		retrieveVersions('document', doc.documentId)
		setVersionsRetrieved(true)
	}
	
	if (versions) {
		versions = versions.sort((a, b) => a.updated > b.updated ? -1 : 1)
	}

	return (
		<Dialog open={true} fullWidth >
			<DialogContent>
				<TabBar tabValues={['workflow_advanced_details','workflow_advanced_versions']} tab={tab} setTab={setTab} />	

				{tab === 'workflow_advanced_details' ?
					<Grid container direction={'column'} >

						<br/>

						{/* documentId */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							InputProps={{readOnly: true}}										
							label={i18.t('properties_id')}
							value={doc.documentId ? doc.documentId : ''}
						/>

						<br/>

						{/* created/createdBy */}
						<Grid container direction='row' alignItems='center' justifyContent='space-between'>

							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_created')}
								value={doc.created ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(doc.created)) : ''}
							/>

							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_created_by')}
								value={doc.createdBy ? doc.createdBy : ''}
							/>

						</Grid>

						<br/>

						{/* updated/updatedBy */}
						<Grid container direction='row' alignItems='center' justifyContent='space-between'>

							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_updated')}
								value={doc.updated ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(doc.updated)) : ''}
							/>
							
							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_updated_by')}
								value={doc.updatedBy?doc.updatedBy:''}
							/>

						</Grid>
						
					</Grid>
				: null }

				{tab === 'workflow_advanced_versions' && versions ?
					<Grid item>
						<br/>
						{ versions.map((version, index) => (
							<Grid 
								item key={index} 
								onMouseMove={() => setHover(index)}
								onMouseEnter={() => setHover(index)}
								onMouseLeave={() => setHover(null)}
							>
								<Grid container direction='row' alignItems='center' justifyContent='space-between' style={{backgroundColor: hover === index ? 'ghostwhite' : null}} >
									<Typography>{`${version.updated ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(version.updated)) : ''} (${version.size.toLocaleString()} bytes)`}</Typography>
									{ hover === index ?
										<Button 
											style={{padding: 0}}
											onClick={()=>{
												restoreVersion('workflow', doc.documentId, version.key, version.versionId)
											}}
										>
											{i18.t('workflow_advanced_restore_version')}
										</Button>
									: null }
								</Grid>
								<Divider/>
							</Grid>
						))}
					</Grid>
				: null}
				
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setDetailsDialogIsOpen(false)} color="primary" variant="contained" >
					{i18.t('dialog_close')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DocumentAdvDetailsDialog