import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'
import * as i18 from 'i18next'

import Workflow from '../components/Workflow'
import WorkflowToolBar from '../components/WorkflowToolBar'

import { moveTask, moveFormProperty, moveMilestoneAction } from '../reducers/tasks'
import { moveProperty, moveCompositeProperty } from '../reducers/properties'
import { moveAttachment } from '../reducers/attachments'
import { retrieveWorkflow, cancelWorkflow, saveWorkflow, saveWorkflowAs } from '../reducers/workflows'
import { createLaunchAssignment, deleteWorkflow, denormalizeWorkflow } from '../reducers/workflows'
import { retrieveDocument } from '../reducers/documents'


const onDragEnd = (workflowId, result, moveTask, moveProperty, moveCompositeProperty, moveFormProperty, moveMilestoneAction, moveAttachment) => {
	if (result.source && result.destination) {
		let type = result.type.split('::')[0]		
		let droppableId = result.destination.droppableId.split('::',2)[1]
		if (type === 'task') {
			moveTask(workflowId, droppableId, result.source.index, result.destination.index)
		} else if (type === 'workflowproperty') {
			moveProperty(workflowId, result.source.index, result.destination.index)
		} else if (type === 'workflowattachment') {
			moveAttachment(workflowId, result.source.index, result.destination.index)
		} else if (type === 'compositeproperty') {
			moveCompositeProperty(workflowId, droppableId, result.source.index, result.destination.index)
		} else if (type === 'formcontainer') {
			let propertyId = result.draggableId.split('::')[2]
			let taskId = result.destination && result.destination.droppableId ? result.destination.droppableId.split('::')[1] : null
			let srcContainerId = result.source && result.source.droppableId ? result.source.droppableId.split('::')[2] : null
			let destContainerId = result.destination && result.destination.droppableId ? result.destination.droppableId.split('::')[2] : null
			let destIndex = result.destination ? result.destination.index : 0
			moveFormProperty(workflowId, taskId, propertyId, srcContainerId, destContainerId, destIndex)
		} else if (type === 'milestoneaction') {
			let taskId = result.destination && result.destination.droppableId ? result.destination.droppableId.split('::')[1] : null
			moveMilestoneAction(workflowId, taskId, result.source.index, result.destination.index)
		}
	}
}

const hasPropertyValidationMarks = (propertylist, properties, type='error') => {
	if (propertylist) {
		for (const propertyId of propertylist) {
			let property = properties[propertyId]
			if (property) {
				if (property.validationErrors && property.validationErrors.filter(err => err[type]).length > 0) {return true}
				if (property.properties) return hasPropertyValidationMarks(property.properties, properties)
			}
		}
	}
	return false
}

const hasTaskValidationMarks = (tasklist, tasks, properties, type='error') => {
	if (tasklist) {
		for (const taskId of tasklist) {
			let task = tasks[taskId]
			if (task) {
				if (task.validationErrors && task.validationErrors.filter(err => err[type]).length > 0) {return true}
				if (task.tasks && hasTaskValidationMarks(task.tasks, tasks, task)) {return true}
			}
		}
	}
	return false
}

const WorkflowView = ({ navigation, appnav, me, workflow, properties=[], tasks=[], attachments=[], documents=[],
						moveTask, moveProperty, moveCompositeProperty, moveFormProperty, moveMilestoneAction, moveAttachment,
						retrieveWorkflow, cancelWorkflow, saveWorkflow, saveWorkflowAs, deleteWorkflow, 
						createLaunchAssignment, retrieveDocument }) => {

//	console.log('WorkflowView',workflow,navigation.params.workflowId)

	if (!workflow) return null

	let hasWorkflowErrors = workflow.validationErrors && workflow.validationErrors.filter(err => err.error).length > 0
	let hasWorkflowWarnings = workflow.validationErrors && workflow.validationErrors.filter(err => err.warning).length > 0

	let hasPropertyErrors = hasPropertyValidationMarks(workflow.properties, properties, 'error')
	let hasPropertyWarnings = hasPropertyValidationMarks(workflow.properties, properties, 'warning')

	let hasTaskErrors = hasTaskValidationMarks(workflow.tasks, tasks, properties, 'error')
	let hasTaskWarnings = hasTaskValidationMarks(workflow.tasks, tasks, properties, 'warning')
	
	const exportWorkflow = (workflow) => {
		const workflow2 = denormalizeWorkflow(workflow)
		const blob = new Blob([JSON.stringify(workflow2, null, 2)], {type: "application/json"})
		const element = document.createElement("a")
		element.href = URL.createObjectURL(blob)
		element.download = `${workflow2.name}.json`
		document.body.appendChild(element)
		element.click()
	}

	const height = document.getElementById('main')?.clientHeight ? document.getElementById('main')?.clientHeight : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 40
	const width = document.getElementById('main')?.clientWidth ? document.getElementById('main')?.clientWidth : 0

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<DragDropContext style={{background: 'transparent'}} onDragEnd={(result)=>{onDragEnd(workflow.workflowId, result, moveTask, moveProperty, moveCompositeProperty, moveFormProperty, moveMilestoneAction, moveAttachment)}} >

			<WorkflowToolBar
				navigation={navigation}
				appnav={appnav}
				workflow={workflow}
				tasks={tasks}
				hasErrors={hasWorkflowErrors || hasPropertyErrors || hasTaskErrors}
				saveWorkflow={saveWorkflow}
				saveWorkflowAs={saveWorkflowAs}
				cancelWorkflow={cancelWorkflow}
				deleteWorkflow={deleteWorkflow}
				createLaunchAssignment={createLaunchAssignment}
				exportWorkflow={exportWorkflow}
				retrieveWorkflow={retrieveWorkflow}
			/>					
			
			<Grid container id='outer-workflow' style={{width: width, height: (height - toolbarHeight)}} >
				<Workflow
					navigation={navigation}
					workflow={workflow}
					tasks={tasks}
					attachments={attachments}
					documents={documents}
					hasDetailErrors={hasWorkflowErrors || hasPropertyErrors}
					hasDetailWarnings={hasWorkflowWarnings || hasPropertyWarnings}
					hasTaskErrors={hasTaskErrors}
					hasTaskWarnings={hasTaskWarnings}
					retrieveWorkflow={retrieveWorkflow}
					retrieveDocument={retrieveDocument}
				/>
			</Grid>

		</DragDropContext>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	appnav: state.appnav,	
	me: state.app.me,
	workflow: state.workflows[props.navigation.params.workflowId],
	properties: state.properties[props.navigation.params.workflowId],
	tasks: state.tasks[props.navigation.params.workflowId],
	attachments: state.attachments[props.navigation.params.workflowId],
	documents: state.documents,
})

export default connect(mapStateToProps, { moveTask, moveProperty, moveCompositeProperty, moveFormProperty, moveMilestoneAction, moveAttachment,
														retrieveWorkflow, cancelWorkflow, saveWorkflow, saveWorkflowAs, deleteWorkflow, 
														createLaunchAssignment, retrieveDocument })(WorkflowView)
