import React, { useState } from 'react'
import { Grid, Typography, TextField, Select, MenuItem } from '@mui/material'
import * as i18 from 'i18next'


const SupportCaseDetail = ({ navigation, supportcase, retrieveSupportCase, saveSupportCase, updateSupportCase, closeSupportCase }) => {
 
//	console.log('SupportCaseDetail')

	if (!supportcase) return null

	return (
		<Grid container direction='column' >

			<Typography>{i18.t('general_created_label')}:  {supportcase.created ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(supportcase.created)) : ''}</Typography>
			<Typography>{i18.t('general_updated_label')}:  {supportcase.updated}</Typography>
			<Typography>{i18.t('supportcase_state_label')}:  {supportcase.state}</Typography>

			<br/>

			<Grid container direction={'row'} alignItems='center' >
				<Typography style={{marginRight: 10}} >Severity:</Typography>
				<Select
					variant='standard'
					value={supportcase.severity}
					onChange={(e)=>{supportcase.severity=e.target.value;updateSupportCase(supportcase)}}
				>
					<MenuItem value={1}>{i18.t('supportcase_severity_1')}</MenuItem>
					<MenuItem value={2}>{i18.t('supportcase_severity_2')}</MenuItem>
					<MenuItem value={3}>{i18.t('supportcase_severity_3')}</MenuItem>
				</Select>
			</Grid>

			<br/>
						
			<TextField
				InputLabelProps={{shrink: true}}
				label={i18.t('dialog_support_summary_desc')}
				onChange={(event) => {supportcase.summary = event.target.value;updateSupportCase(supportcase)}}
				required
				value={supportcase.summary ? supportcase.summary : ''}
				InputProps={{readOnly: supportcase.state !== 'working'}}										
			/>
			
			<br/><br/>
			
			<TextField
				InputLabelProps={{shrink: true}}
				label={i18.t('dialog_support_detail_desc')}
				onChange={(event) => {supportcase.detail = event.target.value;updateSupportCase(supportcase)}}
				multiline={true}
				rows={4}
				required
				value={supportcase.detail ? supportcase.detail : ''}
				InputProps={{readOnly: supportcase.state !== 'working'}}										
			/>

		</Grid>
	)
}

export default SupportCaseDetail
