import styles from '../../styles'
import React from 'react'
import GooglePicker from 'react-google-picker'
import { Grid, Box, Typography, TextField, Button, Card, CardMedia } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

 
const CLIENT_ID = '387159493695-djqsqn9ltpgi3lro5bljk23gqnlrnhee.apps.googleusercontent.com'
const DEVELOPER_KEY = 'AIzaSyD5Fo1rJa-rM0n4_Il2I2J8-bNFqiK6_0w'
const SCOPE = ['https://www.googleapis.com/auth/drive.readonly']

export const folderPicker = (workflowId, task, actions, milestoneActionIndex, designTime, disabled, updateTask) => (
	<GooglePicker
		clientId={CLIENT_ID}
		developerKey={DEVELOPER_KEY}
		scope={SCOPE}
		createPicker={ (google, oauthToken) => {
			const docsView = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
				.setIncludeFolders(true)
				.setSelectFolderEnabled(true)

			const picker = new window.google.picker.PickerBuilder()
				.addView(docsView)
				.setOAuthToken(oauthToken)
				.setDeveloperKey(DEVELOPER_KEY)
				.setCallback((data)=>{
					if (data.action==='picked'){
						task.milestoneActions[milestoneActionIndex].value = JSON.stringify({
							attachmentType: 'google',
							attachment: data.docs[0],
						})
						updateTask(workflowId, task)
					}
				})

			picker.build().setVisible(true)
		}}
	>
		<Button color="primary" >{i18.t('milestone_action_select')}</Button>
	</GooglePicker>
)

export const openInNewTab = (url) => {
	if (url) {
		var win = window.open(url, '_blank')
		win.focus()
	}
}


const SaveToPDF = ({ workflowId, task, milestoneActionIndex, updateTask }) => {

//	console.log('SaveToPDF')
	
	let milestoneAction = task.milestoneActions[milestoneActionIndex]

// TBD - we no longer have value on milestoneAction - change value to ???

	let attachment = null
	if (milestoneAction.value) {
		if (milestoneAction.type === 'SaveToPDF') {
			attachment = JSON.parse(milestoneAction.value).attachment
		}
	}
			
	return (
		<Box style={styles.fieldinner} >

			<Grid container direction={'row'}>
				<TextField
					disabled={disabled}
					style={{flex: 1, cursor: 'default'}}
					InputLabelProps={{shrink: true}}				
					value={milestoneAction.name}
				/>

				{ designTime ?
					<RemoveCircle style={{cursor: 'default', height: 20, width: 20}} color="primary" onClick={()=>{task.milestoneActions.splice(milestoneActionIndex, 1);updateTask(workflowId, task);}} />
				: null }
			</Grid>

			{ attachment !== undefined && attachment !== null ?
				<Grid container direction={'row'}>								
					<Button color="primary" onClick={()=>{delete milestoneAction.value;updateTask(workflowId, task)}} >{i18.t('milestone_action_clear')}</Button>

					<Card>
						<CardMedia 
							style={{ height: 20, width: 20 }} 
							image={attachment.iconUrl} 
							onClick={()=>openInNewTab(attachment.embedUrl)}
						/>
					</Card>
					
					<Typography style={{margin: 5}}>{attachment.pname}</Typography>
				</Grid>
			: 
				<Grid container direction={'row'}>
					{folderPicker(workflowId, task, milestoneActionIndex, updateTask)}
					<Typography style={styles.greytext}>{'<no destination folder>'}</Typography>
				</Grid>
			}

		</Box>
	)
}

export default SaveToPDF