import { CLEAR_ALL_SUPPORTCASES, RECEIVE_SUPPORTCASES, RECEIVE_SUPPORTCASE, UPDATE_SUPPORTCASE, RECEIVE_ERRORS } from './actiontypes'
import { store } from '../App'
import { supportcaseMinFragment, supportcaseMaxFragment, supportcaseReturnFragment, commentFragment, limitedOrgEntityFragment, permissionFragment } from '../reducers/graphql'
import { schema, normalize } from 'normalizr'
import { gqlExec, gqlInput, gqlIdInput } from '../reducers/graphql'
import cloneDeep from 'lodash.clonedeep'


export const supportcaseSchema = new schema.Entity(
	'supportcases', 
	{ 
	}, 
	{ 
		idAttribute: value => value.supportcaseId,
	}
)

// SupportCases Functions

export const retrieveSupportCases = (listType, skip=0, tagFilter) => async dispatch => {
	
//	console.log('retrieveSupportCases', listType)
	
	try {
		document.body.style.cursor = 'wait'

		let states = []
		states = (listType === 'openSupportCases') ?
			states = ['working']
		: (listType === 'closedSupportCases') ?
			states = ['completed','canceled']
		: null
		
		const response = await gqlExec(`query RetrieveSupportCases {supportcases ${gqlInput(states, tagFilter, skip, true, true, true)} {...supportcaseReturnFields}} ${supportcaseReturnFragment} ${supportcaseMinFragment} `)

		let supportcases = response && response.supportcases ? response.supportcases : null

		if (supportcases) {
			
			const data = normalize(supportcases.items, [supportcaseSchema])

			dispatch({type: RECEIVE_SUPPORTCASES, list: data.result, supportcases: data.entities.supportcases, listType: listType, listCount: supportcases.totalCount, tagList: supportcases.tagList})
								
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const retrieveSupportCase = (supportcaseId) => async dispatch => {

//	console.log('retrieveSupportCase');

	if (!supportcaseId)
		return
	
	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`query RetrieveSupportCase {supportcases ${gqlIdInput('supportcase', supportcaseId)} {...supportcaseReturnFields}} ${supportcaseReturnFragment} ${supportcaseMaxFragment} ${commentFragment} ${permissionFragment} ${limitedOrgEntityFragment} `)

		let supportcases = response && response.supportcases ? response.supportcases : null

		if (supportcases) {

			let data = normalize(supportcases.items, [supportcaseSchema])

//			let listType = 'openSupportCases'
//			if (['closed'].includes(supportcases.items[0].state)) {listType = 'closedSupportCases'}

			dispatch({type: RECEIVE_SUPPORTCASE, list: data.result, supportcases: data.entities.supportcases})
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const createSupportCase = (severity, summary, detail) => async dispatch => {

	try {
		document.body.style.cursor = 'wait'

		const input = {
			severity: severity,
			summary: summary,
			detail: detail,
		}			   			
		const response = await gqlExec(`mutation {createSupportCase (input: ${JSON.stringify(input)}) {...supportcaseFields}} ${supportcaseMaxFragment} ${commentFragment} ${permissionFragment} ${limitedOrgEntityFragment} `)

		const supportcase = response ? response.createSupportCase : null

		// TBD - need to do this to update list counts, is there a better way?
		await dispatch(retrieveSupportCases('openSupportCases'))
		await dispatch(retrieveSupportCase(supportcase.supportcaseId))

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const saveSupportCase = (supportcaseId, setStatusIndicator) => async dispatch => {

	try {
		document.body.style.cursor = 'wait'

		let supportcase = cloneDeep(store.getState().supportcases[supportcaseId])

		if (setStatusIndicator) {setStatusIndicator(true)}

		delete supportcase.state
		delete supportcase.comments
		delete supportcase.tags
		delete supportcase.created
		delete supportcase.createdBy
		delete supportcase.updatedBy
		delete supportcase.version

		const response = await gqlExec(`mutation {updateSupportCase (input: ${JSON.stringify(supportcase)}) {...supportcaseFields}} ${supportcaseMaxFragment} ${commentFragment} ${permissionFragment} ${limitedOrgEntityFragment} `)

		dispatch({type: UPDATE_SUPPORTCASE, supportcase: response.updateSupportCase})	

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
}

export const closeSupportCase = (supportcaseId) => async dispatch => {

	try {
		document.body.style.cursor = 'wait'
		
		const response = await gqlExec(`mutation {closeSupportCase (supportcaseId: "${supportcaseId}") {...supportcaseFields}} ${supportcaseMaxFragment} ${commentFragment} ${permissionFragment} ${limitedOrgEntityFragment} `)

		dispatch({type: UPDATE_SUPPORTCASE, supportcase: response.closeSupportCase})	

		// TBD - need to do this to update list counts, is there a better way?
		dispatch(retrieveSupportCases('openSupportCases'))
		dispatch(retrieveSupportCases('closedSupportCases'))

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const updateSupportCase = (supportcase) => async dispatch => {
	dispatch({type: UPDATE_SUPPORTCASE, supportcase: supportcase})
}


const initialState = {}

const supportcases = (state=initialState, action) => {

	switch (action.type) {

// SupportCase Events

		case CLEAR_ALL_SUPPORTCASES:
			state = {}
			break

		case RECEIVE_SUPPORTCASE:
		case RECEIVE_SUPPORTCASES:
			state = {...state}
			for (let i=0; i<action.list.length; ++i) {
				state[action.list[i]] = action.supportcases[action.list[i]]
			}
			break			

		case UPDATE_SUPPORTCASE:
			state = {...state,
				[action.supportcase.supportcaseId]: action.supportcase,
			}
			break
			

		default:
			break
	}

	return state
}

export default supportcases


