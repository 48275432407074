import styles from '../../styles'
import React from 'react'
import GooglePicker from 'react-google-picker'
import { Grid, Typography, Button, Card, CardMedia } from '@mui/material'
import * as i18 from 'i18next'

 
const CLIENT_ID = '387159493695-djqsqn9ltpgi3lro5bljk23gqnlrnhee.apps.googleusercontent.com'
const DEVELOPER_KEY = 'AIzaSyD5Fo1rJa-rM0n4_Il2I2J8-bNFqiK6_0w'
const SCOPE = ['https://www.googleapis.com/auth/drive.readonly']

export const sheetPicker = (workflowId, task, milestoneActionIndex, designTime, disabled, updateTask) => {

	const googleSession = JSON.parse(localStorage.getItem('googleSession'))

	return (
		<Grid container direction={'row'} alignItems='center'>				

			<Typography>{i18.t('action_save_to_sheet')}</Typography>
			
			&nbsp;&nbsp;
					
			<GooglePicker
				clientId={CLIENT_ID}
				developerKey={DEVELOPER_KEY}
				scope={SCOPE}
				createPicker={ (google, oauthToken) => {
					const picker = new google.picker.PickerBuilder()
						.addView(google.picker.ViewId.SPREADSHEETS)
						.setOAuthToken(googleSession.access_token)
						.setDeveloperKey(DEVELOPER_KEY)
						.setCallback((data)=>{
							if (data.action==='picked'){
								if (!task.milestoneActions[milestoneActionIndex]) task.milestoneActions[milestoneActionIndex] = {}
								task.milestoneActions[milestoneActionIndex].value = btoa(JSON.stringify({
									attachmentType: 'google',
									attachment: data.docs[0],
								}))
								updateTask(workflowId, task)
							}
						})
					picker.build().setVisible(true)
				}}
			>
				<Button color="primary" >{i18.t('milestone_action_select')}</Button>
			</GooglePicker>

		</Grid>
	)
}


export const openInNewTab = (url) => {
	if (url) {
		var win = window.open(url, '_blank');
		win.focus();
	}
}


const SaveToSheet = ({ workflowId, task, milestoneActionIndex, updateTask }) => {

//	console.log('SaveToSheet')
	
	let milestoneAction = task.milestoneActions[milestoneActionIndex]

// TBD - we no longer have value on milestoneAction - change value to ???

	let attachment = null
	if (milestoneAction.value) {
		attachment = JSON.parse(atob(milestoneAction.value)).attachment
	}
			
	return (
		<Grid container direction={'row'} alignItems='center'>				

			{ attachment !== undefined && attachment !== null ?

				<Grid item>
				
					{ designTime ?
						<Button color="primary" onClick={()=>{delete milestoneAction.value;updateTask(workflowId, task)}} >{i18.t('milestone_action_clear')}</Button>
					: null }
						
					<Card>
						<CardMedia 
							style={{ height: 20, width: 20 }} 
							image={attachment.iconUrl} 
							onClick={()=>openInNewTab(attachment.embedUrl)}
						/>
					</Card>
				
					<Typography style={{margin: 5}}>{attachment.pname}</Typography>
					
				</Grid>
			: 
				<Grid container direction={'row'} alignItems='center'>	
					<Grid item>
						{sheetPicker(workflowId, task, milestoneActionIndex, updateTask)}
					</Grid>
					<Typography style={styles.greytext}>{'<no target sheet>'}</Typography>
				</Grid>
			}

		</Grid>
	)
}

export default SaveToSheet