import React, { useState } from 'react'
import { ListItem, Typography } from '@mui/material'


const ExtractListItem = ({ extract, documents, selectedExtract, onSelect }) => {

//	console.log('ExtractListItem')

	if (!extract) return null
	
// TBD - generate or pass in thumbnails for documents???
//			<img id='thumbnail' src={iconLink} />

	return (
		<ListItem selected={selectedExtract === extract.extractId} onClick={()=>onSelect(extract)} button>
			<Typography noWrap>{`${extract.name ? extract.name : '<unknown>'}`}</Typography>
		</ListItem>
	)
}

export default ExtractListItem
