import React, { useState } from 'react'
import { connect } from 'react-redux'

import Automation from '../components/Automation'

import { retrieveAutomation, updateAutomation, saveAutomation, deleteAutomation, setEnabled } from '../reducers/automations'
import { retrieveDocumentDetail } from '../reducers/documents'


const AutomationView = ({ navigation, automation, events, actions, workflows, documents, 
							retrieveAutomation, updateAutomation, saveAutomation, deleteAutomation, retrieveDocumentDetail, setEnabled }) => {

//	console.log('AutomationView')
	
	if (!automation) return null

	return (
		<Automation
			navigation={navigation}
			automation={automation}
			events={events}
			actions={actions}
			workflows={workflows}
			documents={documents}
			retrieveAutomation={retrieveAutomation}
			updateAutomation={updateAutomation}
			saveAutomation={saveAutomation}
			deleteAutomation={deleteAutomation}
			retrieveDocumentDetail={retrieveDocumentDetail}
			setEnabled={setEnabled}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	automation: state.automations[props.navigation.params.automationId],
	name: state.automations[props.navigation.params.automationId]?.name,
	eventId: state.automations[props.navigation.params.automationId]?.eventId,
	eventData: state.automations[props.navigation.params.automationId]?.eventData,
	actionId: state.automations[props.navigation.params.automationId]?.actionId,
	actionData: state.automations[props.navigation.params.automationId]?.actionData,
	description: state.automations[props.navigation.params.automationId]?.description,
	enabled: state.automations[props.navigation.params.automationId]?.enabled,
	events: state.events,
	actions: state.actions,
	workflows: state.workflows,
	documents: state.documents,
})

export default connect(mapStateToProps, { retrieveAutomation, updateAutomation, saveAutomation, deleteAutomation, retrieveDocumentDetail, setEnabled })(AutomationView)
