import React, { useState } from 'react'
import { Grid, Button, TextField, Tooltip, Hidden } from '@mui/material'
import { Save, Delete, Refresh, ArrowBack } from '@mui/icons-material'
import * as i18 from 'i18next'

import PermissionsView from '../containers/PermissionsView'
import DiscussionView from '../containers/DiscussionView'
import AutomationDetail from '../components/AutomationDetail'
import TabBar from '../components/TabBar'
import ConfirmDeletionDialog from '../components/dialogs/ConfirmDeletionDialog'


const Automation = ({ navigation, automation, events, actions, workflows, documents, 
					retrieveAutomation, updateAutomation, saveAutomation, deleteAutomation, retrieveDocumentDetail, setEnabled }) => {
 
//	console.log('Automation')

	if (!automation) return null

	const [tab, setTab] = useState('tab_details')
	const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState()

	let tabValues = ['tab_details','tab_permissions','tab_discussion']
	let tabLabels = [i18.t('tab_details'), i18.t('tab_permissions'), i18.t('tab_discussion')]

	return (
		<Grid item>

			<Grid container direction="row" >

				<Tooltip title={i18.t('general_back_tooltip')} >
					<Button color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				</Tooltip>

				<Tooltip title={i18.t('automation_tooltip_save')} >
					<Button color="primary" onClick={()=>saveAutomation(automation.automationId)} >
						<Hidden smUp >
							<Save/>
						</Hidden>
						<Hidden smDown >
							{i18.t('automation_save')}
						</Hidden>
					</Button>
				</Tooltip>

				{ automation.state === 'disabled' || automation.state === 'working' ?
					<Tooltip title={i18.t(automation.state === 'working' ? 'automation_tooltip_disable' : 'automation_tooltip_enable')} >
						<Button color="primary" onClick={()=>setEnabled(automation.automationId, automation.state === 'working' ? false : true)} >
							<Hidden smUp >
								<Save/>
							</Hidden>
							<Hidden smDown >
								{i18.t(automation.state === 'working' ? 'automation_disable' : 'automation_enable')}
							</Hidden>
						</Button>
					</Tooltip>
				: null }
				
				<Tooltip title={i18.t('workflow_tooltip_discard')} disableInteractive={true} >
					<Button style={{minWidth: 20}} color="primary" onClick={()=>{setDeleteDialogIsOpen(true)}} >
						<Hidden smUp >
							<Delete/>
						</Hidden>
						<Hidden smDown >
							{i18.t('workflow_discard')}
						</Hidden>
					</Button>
				</Tooltip>

				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_item_tooltip')} >
					<Button color="primary" onClick={()=>retrieveAutomation(automation.automationId)} >
						<Refresh/>
					</Button>
				</Tooltip>
				
			</Grid>

			<TabBar tabValues={tabValues} tabLabels={tabLabels} tab={tab} setTab={setTab} />	

			<br/>
		
			{ tab === 'tab_details' ?
				<AutomationDetail
					navigation={navigation}
					automation={automation}
					events={events}
					actions={actions}
					workflows={workflows}
					documents={documents}
					updateAutomation={updateAutomation}
					retrieveDocumentDetail={retrieveDocumentDetail}
				/>
			: tab === 'tab_permissions' ?
				<PermissionsView
					sourceType={'automation'}
					sourceId={automation.automationId}
					designTime={true}
				/>
			: tab === 'tab_discussion' ?
				<DiscussionView
					sourceType={'automation'}
					sourceId={automation.automationId}
				/>
			: null }
			
			{ deleteDialogIsOpen ?
				<ConfirmDeletionDialog 
					dialogTitle={i18.t('dialog_delete_automation_title')} 
					dialogContent={i18.t('dialog_delete_automation_desc')}
					onDelete={()=>{deleteAutomation(automation.automationId);navigation.pop();}}
					setDialogIsOpen={setDeleteDialogIsOpen}
				/>
			: null }

		</Grid>				
	)
}

export default Automation

