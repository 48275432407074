import React, { useState } from 'react'
import { ListItem, Typography } from '@mui/material'
import * as i18 from 'i18next'


const AutomationListItem = ({ automation, automations, onSelect }) => {

//	console.log('AutomationListItem')

	if (!automation) return null

	return (
		<ListItem onClick={()=>onSelect(automation)} button>
			<Typography noWrap>{`${automations[automation.automationId]?.name ? automations[automation.automationId].name : '<unknown>'}`}</Typography>
		</ListItem>
	)
}

export default AutomationListItem
