import React, { useState } from 'react'
import { Grid, Button, Typography, TextField, Select, MenuItem, Tooltip, Divider } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

import TaskListView from '../containers/TaskListView'
import ExpressionTuple from '../components/ExpressionTuple'
//import ExpressionDialog from '../components/dialogs/ExpressionDialog'


const exprDisplayText = (expr) => {
	if (expr && expr.typeId) {
		if (expr.typeId.startsWith('ref::')) {
			return expr.value
		} else {
			return i18.t(`expression_${expr.typeId}`.replace(/[^A-Za-z0-9]/ig, "_"))
		}
	}
}

const generateExpressionString = (expression) => {

	let text = ''

	if (expression && expression.typeId) {
		expression.typeId.startsWith('cop') || expression.typeId.startsWith('lop') || expression.typeId.startsWith('aop')  ?
			text += `( ${generateExpressionString(expression.left)} ${exprDisplayText(expression)} ${generateExpressionString(expression.right)} )`
		: expression.typeId.startsWith('func') ?
			text += `${i18.t(`expression_${expression.typeId}`.replace(/[^A-Za-z0-9]/ig, "_"))} ( ${expression?.params?.map(param => generateExpressionString(param))} )`
		: expression.typeId.startsWith('const') ?
			text += expression.typeId === 'const::string' ? `'${expression.value}'` : expression.value
		: expression.typeId.startsWith('ref::property') ?
			text += expression.value
		: null
	}
	
	return text
}

const TaskRouteConditionExpression = ({ me, workflowId, task, index=0, attachments, allProperties, documents, focus,
										updateTask, tasktemplates, designTime, setFocus }) => {

//	console.log('TaskRouteConditionExpression')

	const [exprDialogIsOpen, setExprDialogIsOpen] = useState(false)
	const [hover, setHover] = useState()
	
	let expression = task.routeCondition.options[index].expression

	let setExpression = (expr) => {
		task.routeCondition.options[index].expression=expr
		updateTask(workflowId, task)
	}

	let middleGroupFilter = expression?.left?.type === 'boolean' ? ['lop'] : ['cop'] 

	// Set styling
	let style = {flex: 1, boxShadow: '0 3px 6px rgba(0,0,0,0.16)', padding: 5}
	
	return (
		<Grid
			item
			style={{backgroundColor: hover ? 'ghostwhite' : 'transparent', ...style}}
			onMouseMove={() => {if (!hover) setHover(true)}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>						
			<Grid container direction={'row'} alignItems="center" >

				<Grid item>
					<Grid container direction='row' alignItems='center' style={{flex: 1}} >
						<ExpressionTuple
							expressionParentId={`${task.taskId}::${index}`}
							rootparentId={workflowId}
							expressionTypes={me.expressionTypes} 
							expression={expression} 
							attachments={attachments}
							allProperties={allProperties} 
							documents={documents}
							leftGroupFilter={['func','ref']} 
							middleGroupFilter={middleGroupFilter} 
							rightGroupFilter={['const', 'func', 'lop', 'cop', 'aop', 'ref']}
							top={true}
							focus={focus}
							hover={hover}
							designTime={designTime}
							setExpression={setExpression}
							onDelete={()=>{}}
							setFocus={setFocus}
						/>
					</Grid>
					<Divider/>
				</Grid>
				
				<Typography style={{marginLeft: 5, fontWeight: "bold"}} >{i18.t('route_condition_then_proceed')}&nbsp;</Typography>
					
				<TaskListView 
					workflowId={workflowId}
					task={task}
					selectedOptionType={task.routeCondition.options[index].type}
					selectedOptionDest={task.routeCondition.options[index].destination}
					update={(type, destination) => {
						task.routeCondition.options[index].type = type
						task.routeCondition.options[index].destination = destination
						updateTask(workflowId, task)
					}}				
					tasktemplates={tasktemplates}
					designTime={designTime}
				/>

				{ designTime && hover ?
					<Tooltip title={i18.t('route_condition_delete_tooltip')} >
						<RemoveCircle 
							style={{cursor: 'default', height: 20, width: 20}}
							onClick={() => {
								task.routeCondition.options.splice(index,1)
								updateTask(workflowId, task)
							}}
						/>
					</Tooltip>
				: null}
				
			</Grid>
		</Grid>
	)

}

export default TaskRouteConditionExpression