import React from 'react'
import { connect } from 'react-redux'
import Permissions from '../components/Permissions'
import { retrieveContacts } from '../reducers/app'
import { updateAutomation } from '../reducers/automations'
import { updateExtract } from '../reducers/extracts'
import { updateSupportCase } from '../reducers/supportcases'
import { updateWorkflow } from '../reducers/workflows'
import { updateDocument } from '../reducers/documents'
import { navigate } from '../reducers/appnav'


const PermissionsView = ({ me, contacts=[], roles=[], sourceType, sourceId, sourceObj, designTime=false, disabled=false,
							navigate, updateAutomation, updateExtract, updateSupportCase, updateWorkflow, updateDocument, retrieveContacts }) => {

//	console.log('PermissionsView')

	if (!sourceObj) return null

	// Re-retrieve contacts if needed
	retrieveContacts()

	roles = roles.filter(role => role.type === sourceType)
	let defaultRole
	let update
	
	switch (sourceType) {
		case 'automation': 
			defaultRole = roles.filter(role => role.roleId === 'automationviewer')[0]
			update = (obj) => updateAutomation(obj)
			break
			
		case 'extract': 
			defaultRole = roles.filter(role => role.roleId === 'extractviewer')[0]
			update = (obj) => updateExtract(obj)
			break

		case 'supportcase': 
			defaultRole = roles.filter(role => role.roleId === 'supportcaseviewer')[0]
			update = (obj) => updateSupportCase(obj)
			break

		case 'workflow': 
			defaultRole = roles.filter(role => role.roleId === 'workflowviewer')[0]
			update = (obj) => updateWorkflow(obj)
			break

		case 'document': 
			defaultRole = roles.filter(role => role.roleId === 'documentviewer')[0]
			update = (obj) => updateDocument(obj)
			break
	}
	
	
	return (
		<Permissions
			navigate={navigate}
			me={me}
			contacts={contacts}
			sourceObj={sourceObj}		
			roles={roles}
			defaultRole={defaultRole}
			designTime={designTime}
			disabled={disabled}
			update={update}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	me: state.app.me,
	contacts:  state.app.contacts,
	roles: state.app.roles,
	workflows: state.workflows,
	sourceObj: state[`${props.sourceType}s`][props.sourceId],
})

export default connect(mapStateToProps, { navigate, updateAutomation, updateExtract, updateSupportCase, updateWorkflow, updateDocument, retrieveContacts })(PermissionsView)
