import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import DocumentList from '../components/DocumentList'
import { retrieveDocuments, createDocument, putContent } from '../reducers/documents'


const DocumentListView = ({ navigation, xs, tagFilter, app, documents, screen, retrieveDocuments, createDocument, putContent }) => {

//	console.log('DocumentListView')

	return (
		<DocumentList
			navigation={navigation}
			xs={xs}
			tagFilter={tagFilter}
			app={app}
			documents={documents}
			screen={screen}
			createDocument={createDocument}
			putContent={putContent}
			retrieveDocuments={retrieveDocuments}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	app: state.app,
	documents: state.documents,
})

export default connect(mapStateToProps, { retrieveDocuments, createDocument, putContent })(DocumentListView)
