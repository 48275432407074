import React from 'react'
import { connect } from 'react-redux'
import DocumentDetail from '../components/DocumentDetail'
import { addProperty, deleteProperty, addCompositeProperty } from '../reducers/properties'
import { updateDocument, putContent } from '../reducers/documents'
import { retrieveVersions, restoreVersion } from '../reducers/versions'


const DocumentDetailView = ({ navigation, tags, doc, versions, properties, memberProperties, extractsTagList, designTime,
							updateDocument, putContent, addProperty, deleteProperty, addCompositeProperty, 
							retrieveVersions, restoreVersion }) => {

//	console.log('DocumentDetailView')

	if (!doc) return null

	return (
		<DocumentDetail
			navigation={navigation}
			tags={tags}
			doc={doc}
			versions={versions}
			properties={properties}
			memberProperties={memberProperties}
			extractsTagList={extractsTagList}
			designTime={designTime}

			updateDocument={updateDocument}
			putContent={putContent}
			addProperty={(property) => addProperty(doc.documentId, property)}
			deleteProperty={(propertyId)=>deleteProperty(doc.documentId, propertyId)}
			addCompositeProperty={addCompositeProperty}
			retrieveVersions={retrieveVersions}
			restoreVersion={restoreVersion}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	doc: state.documents[props.documentId],
	versions: state.versions[props.documentId],
	properties: state.properties[props.navigation.params.documentId],
	memberProperties: state.properties[state.app.me?.orgentityId],
	documentName: state.documents[props.documentId] ? state.documents[props.documentId]?.name : null,
	documentDescription: state.documents[props.documentId] ? state.documents[props.documentId]?.description : null,
	tags: state.app.allDocumentsTagList,
	tags2: state.documents[props.documentId].tags,
	extractsTagList: state.app.extractsTagList,	
})

export default connect(mapStateToProps, { updateDocument, putContent, addProperty, deleteProperty, addCompositeProperty, 
											retrieveVersions, restoreVersion })(DocumentDetailView)
