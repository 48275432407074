import workflow_simple_twotasks from './workflow_simple_twotasks.json'
import workflow_subtasks_simple from './workflow_subtasks_simple.json'
import workflow_user_route_test from './workflow_user_route_test.json'
import workflow_parent_route_test from './workflow_parent_route_test.json'
import testfragments from './testfragments'
import cloneDeep from 'lodash.clonedeep'


// This test must be run concurrently under three users:  admin@tournamentbuilders.com && development93012@gmail.com && ???

const stringify = (str) => {
	return JSON.stringify(str).replace(/"(\w+)"\s*:/g, '$1:')
}


// Task serial vs parallel execution

// WFR-0001
var sequentialtaskwf = cloneDeep(workflow_simple_twotasks)
sequentialtaskwf.tasks[0].parallelExecution = false

// WFR-0002
var paralleltaskwf = cloneDeep(workflow_simple_twotasks)
paralleltaskwf.tasks[0].parallelExecution = true

// WFR-0003
var sequentialsubtaskwf = cloneDeep(workflow_subtasks_simple)
sequentialsubtaskwf.tasks[0].tasks[0].parallelExecution = false

// WFR-0004
var parallelsubtaskwf = cloneDeep(workflow_subtasks_simple)
parallelsubtaskwf.tasks[0].tasks[0].parallelExecution = true


// Routing past other tasks

// WFR-0005
var userroutetestwf = cloneDeep(workflow_user_route_test)

// WFR-0006
var parentroutetestwf = cloneDeep(workflow_parent_route_test)


// Direct vs group assignees

// WFR-00 - complete assignment with 1 direct assignee and "ANY" completion criteria

// WFR-00 - complete assignment with 3 direct assignees and "ANY" completion criteria


// WFR-00 - complete assignment with a group assignee that has 1 member and "ANY" completion criteria

// WFR-00 - complete assignment with a group assignee that has 3 members and "ANY" completion criteria


// WFR-00 - complete assignment with 1 direct assignee and "ALL" completion criteria

// WFR-00 - complete assignment with 3 direct assignees and "ALL" completion criteria


// WFR-00 - complete assignment with a group assignee that has 1 member and "ALL" completion criteria

// WFR-00 - complete assignment with a group assignee that has 3 members and "ALL" completion criteria


// WFR-00 - complete assignment with 1 direct assignee and "QUORUM" completion criteria

// WFR-00 - complete assignment with 3 direct assignees and "QUORUM" completion criteria


// WFR-00 - complete assignment with a group assignee that has 1 member and "QUORUM" completion criteria

// WFR-00 - complete assignment with a group assignee that has 3 members and "QUORUM" completion criteria




const workflowruntime = [{
	name: "Workflow runtime test collection",
	tests: [

		{
			// run base workflow, complete assignment
			id: 'WFR-0001',
			name: 'create workflow multiple tasks and complete sequential assignments.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(sequentialtaskwf)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'WFR-0001a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {workflowId, state, taskinstances {taskinstances {assignments {assignmentId name}}}}}`, 
				assertResultIncludes: [`"state":"working"`,`task1`],
				onEachItem: [
				{
					id: 'WFR-0001a1',
					name: 'complete first assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
					assertResultIncludes: [`"state":"completed"`],
					onEachItem: [
					{
						id: 'WFR-0001a1a',
						name: 'query next assignment.',
						type: 'setup',
						operation: `query {workflows (workflowId: "<<workflowId>>") {totalCount, items{workflowId, state, taskinstances {taskinstances {assignments {assignmentId name state}}}}}}`, 
						assertResultIncludes: [`"name":"task2","state":"working"`,`"name":"task1","state":"completed"`],
						onEachItem: [
						{
							id: 'WFR-0001a1a1',
							name: 'complete second assignment.',
							type: 'positive',
							operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
							assertResultIncludes: [`"state":"completed"`],
						}]
					}]
				}]
			}]
		},

		{
			id: 'WFR-0002',
			name: 'create workflow multiple tasks and complete parallel assignments.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(paralleltaskwf)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'WFR-0002a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {workflowId, state, taskinstances {taskinstances {assignments {assignmentId name}}}}}`, 
				assertResultIncludes: [`"state":"working"`,`task1`,`task2`],
				onEachItem: [
					{
						id: 'WFR-0002a1',
						name: 'complete first assignment.',
						type: 'positive',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
						assertResultIncludes: [`"state":"completed"`],
					},
					{
						id: 'WFR-0002a2',
						name: 'complete second assignment.',
						type: 'positive',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[1].assignments[0].assignmentId>>" }) {state}}`,
						assertResultIncludes: [`"state":"completed"`],
					}
				]
			}]
		},

		{
			id: 'WFR-0003',
			name: 'create workflow w/ subtasks and complete sequential assignments.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(sequentialsubtaskwf)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'WFR-0003a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {workflowId, state, taskinstances {taskinstances {taskinstances {assignments {assignmentId name}}}}}}`, 
				assertResultIncludes: [`"state":"working"`,`task1`],
				onEachItem: [
				{
					id: 'WFR-0003a1',
					name: 'complete first assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
					assertResultIncludes: [`"state":"completed"`],
					onEachItem: [
					{
						id: 'WFR-0003a1a',
						name: 'query next assignment.',
						type: 'setup',
						operation: `query {workflows (workflowId: "<<workflowId>>", states: ["working"]) {totalCount, items{workflowId, state, taskinstances {taskinstances {taskinstances {assignments {assignmentId name state}}}}}}}`, 
						onEachItem: [
						{
							id: 'WFR-0003a1a1',
							name: 'complete second assignment.',
							type: 'positive',
							operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
							assertResultIncludes: [`"state":"completed"`],
						}]
					}]
				}]
			}]
		},

		{
			id: 'WFR-0004',
			name: 'create workflow w/ subtasks and complete parallel assignments.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(parallelsubtaskwf)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'WFR-0004a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {workflowId, state, taskinstances {taskinstances {taskinstances {assignments {assignmentId name}}}}}}`, 
				assertResultIncludes: [`"state":"working"`,`subtask1`],
				onEachItem: [
					{
						id: 'WFR-0004a1',
						name: 'complete first assignment.',
						type: 'positive',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
						assertResultIncludes: [`"state":"completed"`],
					},
					{
						id: 'WFR-0004a2',
						name: 'complete second assignment.',
						type: 'positive',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].taskinstances[1].assignments[0].assignmentId>>" }) {state}}`,
						assertResultIncludes: [`"state":"completed"`],
					}
				]
			}]
		},

		{
			id: 'WFR-0005',
			name: 'route user task forward and backward to user task.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(userroutetestwf)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'WFR-0005a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {workflowId, state, taskinstances {taskinstances {assignments {assignmentId name}}}}}`, 
				assertResultIncludes: [`"state":"working"`,`task1`],
				onEachItem: [
				{
					id: 'WFR-0005a1',
					name: 'complete first assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", selectedRoute: 0}) {state}}`,
					assertResultIncludes: [`"state":"completed"`],
					onEachItem: [
					{
						id: 'WFR-0005a1a',
						name: 'query/verify task3 assignment.',
						type: 'setup',
						operation: `query {workflows (workflowId: "<<workflowId>>", states: ["working"]) {totalCount, items{workflowId, state, taskinstances {taskinstances {assignments {assignmentId name state}}}}}}`, 
						assertResultIncludes: [`"state":"working"`,`task3`],
						onEachItem: [
						{
							id: 'WFR-0005a1a1',
							name: 'complete second assignment.',
							type: 'positive',
							operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", selectedRoute: 0}) {state}}`,
							assertResultIncludes: [`"state":"completed"`],
							onEachItem: [
							{
								id: 'WFR-0005a1a1a',
								name: 'query/verify task2 assignment.',
								type: 'setup',
								operation: `query {workflows (workflowId: "<<workflowId>>", states: ["working"]) {totalCount, items{workflowId, state, taskinstances {taskinstances {assignments {assignmentId name state}}}}}}`, 
								assertResultIncludes: [`"state":"working"`,`task2`],
							}]
						}]
					},
					{
						id: 'WFR-CLEANUP',
						name: 'cancel workflow.',
						type: 'cleanup',
						operation: `mutation {cancelWorkflow (workflowId: "<<workflowId>>") {workflowId, state}}`,
						assertResultIncludes: [`"state":"canceled"`],
					}]
				}]
			}]
		},

		{
			id: 'WFR-0006',
			name: 'route user task forward and backward to parent task.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(parentroutetestwf)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'WFR-0006a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {workflowId, state, taskinstances {taskinstances {assignments {assignmentId name}}}}}`, 
				assertResultIncludes: [`"state":"working"`,`task0`],
				onEachItem: [
				{
					id: 'WFR-0006a1',
					name: 'complete first assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", selectedRoute: 0}) {state}}`,
					assertResultIncludes: [`"state":"completed"`],
					onEachItem: [
					{
						id: 'WFR-0006a1a',
						name: 'query/verify task2 assignment.',
						type: 'setup',
						operation: `query {workflows (workflowId: "<<workflowId>>", states: ["working"]) {totalCount, items{workflowId, state, taskinstances {taskinstances {taskinstances {assignments {assignmentId name state}}}}}}}`, 
						assertResultIncludes: [`"state":"working"`,`task2`],
						onEachItem: [
						{
							id: 'WFR-0006a1a1',
							name: 'complete task2 assignment.',
							type: 'positive',
							operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].taskinstances[0].assignments[0].assignmentId>>"}) {state}}`,
							assertResultIncludes: [`"state":"completed"`],
							onEachItem: [
							{
								id: 'WFR-0006a1a1a',
								name: 'query/verify task3 assignment.',
								type: 'setup',
								operation: `query {workflows (workflowId: "<<workflowId>>", states: ["working"]) {totalCount, items{workflowId, state, taskinstances {taskinstances {assignments {assignmentId name state}}}}}}`, 
								assertResultIncludes: [`"state":"working"`,`task3`],
									onEachItem: [
									{
										id: 'WFR-0006a1a1a1',
										name: 'complete task3 assignment.',
										type: 'positive',
										operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", selectedRoute: 0}) {state}}`,
										assertResultIncludes: [`"state":"completed"`],
										onEachItem: [
										{
											id: 'WFR-0006a1a1a1a',
											name: 'query/verify task1 assignment.',
											type: 'setup',
											operation: `query {workflows (workflowId: "<<workflowId>>", states: ["working"]) {totalCount, items{workflowId, state, taskinstances {taskinstances {taskinstances {assignments {assignmentId name state}}}}}}}`, 
											assertResultIncludes: [`"state":"working"`,`task1`],
										}]
									}]
							}]
						}]
					},
					{
						id: 'WFR-CLEANUP',
						name: 'cancel workflow.',
						type: 'cleanup',
						operation: `mutation {cancelWorkflow (workflowId: "<<workflowId>>") {workflowId, state}}`,
						assertResultIncludes: [`"state":"canceled"`],
					}]
				}]
			}]
		},

	],
}]

export default workflowruntime


