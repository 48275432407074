import React from 'react'
import { connect } from 'react-redux'
import { Select, MenuItem } from '@mui/material'
import * as i18 from 'i18next'


const TaskListView = ({ workflowId, task, selectedOptionType, selectedOptionDest, update, tasks, designTime }) => {
	
//	console.log('TaskListView')
	
	let taskParent = tasks[task.parenttaskId]
	let options = []

		
	// Make the first choice in our drop down list - the 'Next task' choice
	options.push({label: i18.t('route_condition_nexttask'), type: 'nexttask', destination: null, quorum: 'any'})
			
	// Now add the rest of our tasks, except the current task, as explicit, 'task' options
	taskParent && taskParent.tasks && taskParent.tasks.map((tId) => {
		if (task.taskId !== tId) {
			let label = tasks[tId].name
			if (label && label.length > 15)
				label = `${label.slice(0, 12)}...`
			options.push({label: label, type: 'task', destination: tId, quorum: 'any'})
		}
		return null
	})
		
	// Now add the finish route - which routes back to the parent task
	options.push({label: i18.t('route_condition_finish'), type: 'finish', destination: null, quorum: 'any'})

	let startingIndex = options.findIndex(option => 
		(option.type===selectedOptionType && selectedOptionType==='nexttask') || 
		(option.type===selectedOptionType && selectedOptionType==='finish') || 
		(option.type===selectedOptionType && selectedOptionType==='task' && option.destination===selectedOptionDest)
	)
	
	return (
		<Select 
			variant='standard'
			value={startingIndex}
			disabled={!designTime}
			onChange={(event) => {update(options[event.target.value].type, options[event.target.value].destination)}}
		>
			{options.map( (option, optionIndex) => <MenuItem key={optionIndex} value={optionIndex} > {option.label}  </MenuItem> )}
		</Select>	
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	tasks: state.tasks[props.workflowId],
	modified: state.tasks[props.workflowId] && state.tasks[props.workflowId][props.taskId] ? state.tasks[props.workflowId][props.taskId].modified : false,
})

export default connect(mapStateToProps, {  })(TaskListView)