import React, { useState } from 'react'
import MilestoneAction from '../components/MilestoneAction'
import TaskInstanceView from '../containers/TaskInstanceView'
import AssignmentInstanceView from '../containers/AssignmentInstanceView'
import { Grid, Box, Typography, TextField, Tooltip, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

//									taskinstances2 && taskinstances2.map((taskinstance, index) => (
//										{assignments2 && assignments2.map((assignment, index) => (

	
const TaskInstance = ({ me, workflowId, taskinstance, taskinstances, assignments, expanded=false, setExpanded }) => {
	
//	console.log('TaskInstance')
	
	if (!taskinstance) return null

	let taskTypeParent = taskinstance.type === 'parent'
	let taskTypeUser = taskinstance.type === 'user'
	let taskTypeStart = taskinstance.type === 'start'
	let taskTypeMilestone = taskinstance.type === 'milestone'
	let taskTypeFinish = taskinstance.type === 'finish'

	// Set background color based on taskinstance type
	let taskStyle={ flex: 1, backgroundColor: 'white', boxShadow: '0 3px 6px rgba(0,0,0,0.16)' }
	
	if (taskTypeStart || taskTypeFinish)
		taskStyle={ flex: 1, backgroundColor: 'ghostwhite' }
	else if (taskTypeParent)
		taskStyle={ flex: 1, backgroundColor: 'whitesmoke' }
	
	// Set the default task styling
	let vStyle = { flex: 1, flexDirection: 'row', alignItems: 'center', borderLeftWidth: 2, borderLeftColor: 'white'}

	// Check for 'working' state first since there will be only one of these, if it exists, 
	// and it will represent the most current task state (which we want to represent here)
	if (taskinstance && taskinstance.state === 'working')
		vStyle.borderLeftColor='green'
	else if (taskinstance && taskinstance.state === 'completed')
		vStyle.borderLeftColor='blue'
	else if (taskinstance && taskinstance.state === 'canceled')
		vStyle.borderLeftColor='orange'


	// Sort task instances by create date
//	let taskinstances2 = taskinstances.filter(taskinstance1 => taskinstance.taskinstances && taskinstance.taskinstances.includes(taskinstance1.taskinstanceId))
//	taskinstances2 = taskinstances2.sort((taskinstance1, taskinstance2) => Date.parse(taskinstance1.created) - Date.parse(taskinstance2.created))

	// Sort assignment instances by create date
//	let assignments2 = []
//	if (taskinstance.assignments && taskinstance.assignments.length > 0) {
//		assignments2 = taskinstance.assignments.sort((assignment1, assignment2) => Date.parse(assignment1.created) - Date.parse(assignment2.created))
//	}

	let taskinstances2 = cloneDeep(taskinstance.taskinstances)
	taskinstances2 = taskinstances2?.sort((taskinstanceId1, taskinstanceId2) => Date.parse(taskinstances?.[taskinstanceId1]?.updated) - Date.parse(taskinstances?.[taskinstanceId2]?.updated))

	let assignments2 = cloneDeep(taskinstance.assignments)
	assignments2 = assignments2?.sort((assignmentId1, assignmentId2) => Date.parse(assignments?.[assignmentId1]?.updated) - Date.parse(assignments?.[assignmentId2]?.updated))

	return (
		<Grid item>
			<Grid container direction={'row'} alignItems="center" style={{cursor: 'default'}} >
				<Accordion style={taskStyle} expanded={expanded} onChange={(event, expanded)=>{setExpanded(taskinstance.taskinstanceId, expanded)}} >
					<AccordionSummary expandIcon={<Tooltip title={i18.t('general_expand_item')}><ExpandMore/></Tooltip>}>
					
						<Grid container direction={'row'} justify="space-between" >
							<Grid item  xs={9} md={10} lg={11} >
								<Grid container direction={'row'}>

									{taskTypeStart || taskTypeFinish ?
										<Typography style={{flex: 1, marginRight: 5, fontWeight: 'bold'}}>
											{taskTypeStart ? 
												i18.t(`task_start_label`) 
											: taskTypeFinish ?
												i18.t(`task_finish_label`)
											: null}
										</Typography>			
									: null }

										
									{taskTypeUser || taskTypeParent || taskTypeMilestone ?
										<Grid container direction={'row'} alignItems='center' >
											{taskTypeUser ? 
												<Typography style={{marginRight: 5}}>{i18.t(`general_usertask_label`)}</Typography>
											: taskTypeParent ?
												<Typography style={{marginRight: 5}}>{i18.t(`general_parenttask_label`)}</Typography>
											: null }

											<Typography style={{marginRight: 5}}>{taskinstance.name}</Typography>
											
										</Grid>
									: null }
									
								</Grid>
							</Grid>
									
							<Typography style={{marginRight: 5}}>{i18.t(`taskinstance_state_${taskinstance.state}`)}</Typography>

						</Grid>

					</AccordionSummary>
					
					<AccordionDetails>	
						<Grid container>

							{/* If this is a parent taskinstance - show a control to set whether subtasks run in parallel or serially */}
							{(taskTypeParent || taskTypeStart) ?
								<Typography>{taskinstance.parallelExecution ? i18.t('task_parallel_execution_on') : i18.t('task_parallel_execution_off')}</Typography>
							: 
								null 
							}

							<Grid container direction={'row'}>
								<Typography style={{marginRight: 5}}>{i18.t(`general_created_label`)}</Typography>
								<Typography style={{marginRight: 5}}>{new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(taskinstance.created))}</Typography>
							</Grid>
								
							{taskinstance.updated ?
								<Grid container direction={'row'}>
									<Typography style={{marginRight: 5}}>{i18.t(`general_updated_label`)}</Typography>
									<Typography style={{marginRight: 5}}>{new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(taskinstance.updated))}</Typography>
								</Grid>
							: null }

							<Grid item style={{flex: 1}}>
							
								{/* If this is a parent taskinstance - show the child taskinstances */}
								{taskTypeParent ?
									taskinstances2?.map((taskinstanceId, index) => (
										<Box key={index} >
											<TaskInstanceView
												workflowId={workflowId}
												taskinstanceId={taskinstanceId}
											/>
										</Box>
									))
								: null }
								
								{/* If this is a user taskinstance - show the child assignments */}
								{taskTypeUser ?
									<Box style={{flex: 1}}>
										<Typography style={{marginRight: 5, fontWeight: 'bold'}}>{i18.t(`general_assignment_label`)}</Typography>		

										{assignments2?.map((assignment, index) => (
											<Box key={index} >
												<AssignmentInstanceView
													assignment={assignment}
												/>
											</Box>
										))}
									</Box>
								: null }

								<br/>
								
								{taskTypeMilestone ?
									taskinstance?.milestoneActions?.map((action, index)=> (
										<MilestoneAction
											key={index}
											me={me}
											workflowId={workflowId}
											task={taskinstance}
											milestoneActionIndex={index}
											designTime={false}
											disabled={true}
											slimStyle={true}
										/>

									))
								: null }

							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
			</Grid>

			<br/>

			<Grid item>			
				{/* If this is the top-level start taskinstance - show the child taskinstances */}
				{taskTypeStart ?
					taskinstances2?.map((taskinstanceId, index) => (
						<Grid key={index} style={{marginLeft: 20}} >
							<TaskInstanceView
								workflowId={workflowId}
								taskinstanceId={taskinstanceId}
							/>
						</Grid>
					))
				: null }
			</Grid>
			
		</Grid>
		
		
	)
}

export default TaskInstance
