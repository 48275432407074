import React from 'react'
import { connect } from 'react-redux'
import AssignmentList from '../components/AssignmentList'
import { retrieveAssignments, retrieveAssignment } from '../reducers/assignments'


const AssignmentListView = ({ navigation, xs, tagFilter, app, assignments, screen, retrieveAssignments, retrieveAssignment }) => {
  
//	console.log('AssignmentListView')
		
	return (
		<AssignmentList 
			navigation={navigation}
			xs={xs}
			tagFilter={tagFilter}
			app={app}
			assignments={assignments} 
			screen={screen}			
			refreshing={false} 
			retrieveAssignments={retrieveAssignments}
			retrieveAssignment={retrieveAssignment}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	app: state.app,
	assignments: state.assignments,
})

export default connect(mapStateToProps, { retrieveAssignments, retrieveAssignment })(AssignmentListView)
