import React from 'react'
import { connect } from 'react-redux'
import UUID from 'uuidjs'

import OrgPropertyList from '../components/OrgPropertyList'
import { addProperty } from '../reducers/properties'


const OrgPropertyListView = ({ sourceId, tagFilter, properties, addProperty }) => {

//	console.log('OrgPropertyListView')

	if (!sourceId || !properties) return null

	const addOrgProperty = () => {
		var property = {propertyId: UUID.genV4().toString(), name: 'Field Name', name: 'Field_Name', subType: 'string', type: 'string', control: 'text'}
		addProperty(sourceId, property)
	}

	return (
		<OrgPropertyList
			sourceId={sourceId}
			tagFilter={tagFilter}
			sourceProperties={properties[sourceId]}
			addProperty={addOrgProperty}
		/>	
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	properties: state.properties,
})

export default connect(mapStateToProps, { addProperty })(OrgPropertyListView)
