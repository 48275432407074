import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'

import AutomationList from '../components/AutomationList'
import { retrieveAutomation, retrieveAutomations, createAutomation } from '../reducers/automations'


const AutomationListView = ({ screen, navigation, automationsList, automationsCount, automations, events, actions, workflows, retrieveAutomations, retrieveAutomation, createAutomation }) => {
 
//	console.log('AutomationListView')

	const onSelect = (automation) => {
		if (!automation.created) {retrieveAutomation(automation.automationId)}
		navigation.navigate('automation', {automationId: automation.automationId})
	}
	
	return (
		<Grid item>
			<AutomationList
				navigation={navigation}
				automationsList={automationsList}
				automationsCount={automationsCount}
				automations={automations}
				events={events}
				actions={actions}
				workflows={workflows}
				onSelect={onSelect}
				retrieveAutomations={retrieveAutomations}
				createAutomation={createAutomation}
			/>
		</Grid>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	automationsList: state.app.automationsList,
	automationsCount: state.app.automationsCount,
	automations: state.automations,
	events: state.events,
	actions: state.actions,
	workflows: state.workflows,
})

export default connect(mapStateToProps, { retrieveAutomations, retrieveAutomation, createAutomation })(AutomationListView)
