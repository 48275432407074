

export const resources = {
	en: {
		translation: {

			action_add_tag: "Add tag",
			action_delete_tag: "Delete tag",
			action_launch_workflow: "Launch workflow",
			action_save_to_csv: "Save properties to CSV file",
			action_save_to_json: "Save properties to JSON file",
			action_save_to_sheet: "Save properties to sheet",
			action_set_property_value: "Set property value",

			assignment_canceled_label: "Canceled",
			assignment_complete: "Complete",
			assignment_complete_tooltip: "Complete the current assignment.",
			assignment_completed_label: "Completed",
			assignment_routing_label: "Routing options:",
			assignment_save: "Save",
			assignment_save_tooltip: "Save the current assignment.",
			assignment_state_working: "working",
			assignment_state_completed: "completed",
			assignment_state_canceled: "canceled",

			assignment_tab_in_process: "In Process",
			assignment_tab_in_process_tooltip: "Assignments that are 'In Process' are in an active, working state.",
			assignment_tab_completed: "Completed",
			assignment_tab_completed_tooltip: "Assignments that are completed no longer have any active updates being made.",
			assignment_tab_details: "Details",
			assignment_tab_details_tooltip: "The details tab shows details of a given assignment such as data to enter, documents to upload, etc.",
			assignment_tab_comments: "Comments",
			assignment_tab_comments_tooltip: "The comments tab shows the entire discussion thread associated with a given assignment.",

			attachment_analysis_filter_tooltip: "Add filter tags to scope the set of extraction templates used to analyze this attachment.",
			attachment_analyze_auto: "Auto",
			attachment_analyze_document: "Analyze",
			attachment_analyze_filter: "Analysis filter:",
			attachment_analyze_manual: "Manual",
			attachment_analyze_none: "None",
			attachment_analyze_type: "Analysis:",
			attachment_analyzing_document: "Analyzing...",
			attachment_create_document_from_upload: "Select Document",
			attachment_create_template_document: "Create Template Document",
			attachment_create_template_document_tooltip: "Create template document as a placeholder.",
			attachment_create_working_document: "Create empty document",
			attachment_create_working_document_from_template: "Create empty document from this template",
			attachment_create_working_document_tooltip: "Create working document as a placeholder.",
			attachment_delete_tooltip: "Remove document associated with this attachment and allow it to be re-selected.",
			attachment_generate_schema_label: "Generate schema",
			attachment_generate_schema_label_small: "Generate schema",
			attachment_generate_schema_tooltip: "Select from available extraction templates to pre-define a set of property elements against which extracted data will be mappted.",
			attachment_name_placeholder: "Attachment name",
			attachment_template_label: "Template:",
			attachment_view_tooltip: "Click on document to view the document and any extracted data elements.",

			automation_action_label: "Action:",
			automation_create: "Create",
			automation_desc_label: "Description",
			automation_desc_placeholder: "Enter automation description here",
			automation_disable: "Disable",
			automation_enable: "Enable",
			automation_event_label: "Event:",
			automation_folder_label: "Folder:",
			automation_name_label: "Name",
			automation_name_placeholder: "Enter automation description here",
			automation_save: "Save",
			automation_state_disabled: "disabled",
			automation_state_working: "working",
			automation_state_deleted: "deleted",
			automation_tooltip_create: "Create an automation.",
			automation_tooltip_disable: "Disable automation",
			automation_tooltip_enable: "Enable automation",
			automation_tooltip_save: "Save automation",
			automation_tooltip_select_folder: "Select folder to monitor.",
			automation_workflow_label: "Workflow:",

			comment_enter_comment_here: "Enter new comment here...",
			comment_enter_reply: "Enter reply here...",
			comment_num_replies: "replies: {{count}}",
			comment_post_label: "Post",
			comment_post_tooltip: "Click here or press 'Shift-Enter' to post comment.",

			dialog_accept_terms: "Accept terms and conditions.",
			dialog_add_composite_extraction_rule: "Add composite",
			dialog_add_kvp_extraction_rule: "Add KVP",
			dialog_add_property: "Add Attachments / Properties",
			dialog_add_table_extraction_rule: "Add table",
			dialog_add_tag: "Add Tag",
			dialog_add_tag_name_button: "Add",
			dialog_add_tag_name_label: "Tag",
			dialog_cancel: "Cancel",
			dialog_cancel_workflow_desc: "This will cancel the project, all related tasks, and any oustanding assignments.  If you're sure you wish to cancel this project - press OK.",
			dialog_cancel_workflow_title: "Confirm Cancellation",
			dialog_close: "Close",
			dialog_delete: "Delete",
			dialog_delete_automation_desc: "This will permanently delete this automation.  This cannot be reversed.  If you're sure you wish to delete this automation - press Delete.",
			dialog_delete_automation_title: "Confirm Delete",
			dialog_delete_document_desc: "This will permanently delete this document template.  This cannot be reversed.  If you're sure you wish to delete this document template - press Delete.",
			dialog_delete_document_title: "Confirm Delete",
			dialog_delete_extract_desc: "This will permanently delete this extraction template.  This cannot be reversed.  If you're sure you wish to delete this extraction template - press Delete.",
			dialog_delete_extract_title: "Confirm Delete",
			dialog_delete_workflow_desc: "This will permanently delete this project.  This cannot be reversed.  If you're sure you wish to delete this project - press Delete.",
			dialog_delete_workflow_title: "Confirm Delete",
			dialog_done: "Done",
			dialog_er_settings: "Extraction rule settings",
			dialog_extractionlist_title: "Select extraction template",
			dialog_image_ref_desc: "Select an image:",
			dialog_image_ref_title: "Images",
			dialog_no: "No",
			dialog_ok: "OK",
			dialog_save: "Save",
			dialog_submit: "Submit",
			dialog_support_detail_desc: "Detailed description",
			dialog_support_intro: "Enter a summary and detailed description of your support request here.",
			dialog_support_summary_desc: "Summary description",
			dialog_support_title: "Support Request",
			dialog_tandc_statement: "There are the terms and conditions..........",
			dialog_tandc_title: "Terms and Conditions",
			dialog_unable_to_extract_data_desc: "We were unable to extract data from the this document.  Would you like to create an extraction template for this document so we can help you extract data from it?",
			dialog_unable_to_extract_data_title: "Extraction Failed",
			dialog_workflow_details: "Advanced Details",
			dialog_yes: "Yes",

			document_create_instance_label: "Create instance",
			document_create_instance_tooltip: "Create working document instance from this document template.",
			document_download_content: "Download Content",
			document_state_draft: "draft",
			document_state_template: "template",
			document_state_working: "working",
			document_state_deleted: "deleted",
			document_upload_content: "Upload Content",

			event_on_create: "On Create",
			event_on_update: "On Update",
			event_on_delete: "On Delete",
			event_on_run: "On Run",
			event_on_finish: "On Finish",
			event_on_analyze: "On Analyze",

			expression_tag__add: "Add Tag",
			expression_tag__delete: "Delete Tag",
			expression_const__string: "String Constant",
			expression_const__number: "Numeric Constant",
			expression_const__boolean: "Boolean Constant",
			expression_const__date: "Date Constant",
			expression_func__to_lower: "toLower",
			expression_func__to_upper: "toUpper",
			expression_func__concat: "concat",
			expression_func__splice: "splice",
			expression_func__unique_id: "uniqueId",
			expression_func__abs: "abs",
			expression_func__round: "round",
			expression_func__save_to_csv: "Save To CSV",
			expression_lop__and: "&&",
			expression_lop__or: "||",
			expression_cop__eq: "==",
			expression_cop__neq: "!=",
			expression_cop__lt: "<",
			expression_cop__lte: "<=",
			expression_cop__gt: ">",
			expression_cop__gte: ">=",
			expression_aop__plus: "+",
			expression_aop__minus: "-",
			expression_aop__mult: "*",
			expression_aop__exp: "**",
			expression_aop__div: "/",
			expression_aop__mod: "%",
			expression_ass__eq: "=",

			extract_new_extract: "Create From Document",

			extraction_composite_select_region_label: "Region",
			extraction_composite_reset_label: "Region",
			extraction_discard: "Discard",
			extraction_discard_tooltip: "Permanently discard this extraction template",
			extraction_key_placeholder: "&lt;no key selected&gt;",
			extraction_key_select_label: "Key",
			extraction_key_reset_label: "Key",
			extraction_name: "Name:",
			extraction_name_placeholder: "Enter extraction template name here",
			extraction_page: "Page:",
			extraction_page_next: "+",
			extraction_page_prev: "-",
			extraction_properties: "Properties / extraction rules",
			extraction_rule_col_name_label: "Column name",
			extraction_rule_col_name_placeholder: "Column name",
			extraction_rule_column_count_label: "Columns:",
			extraction_rule_value_label: "Value",
			extraction_save_tooltip: "Save the current extraction template.",
			extraction_table_select_label: "Table",
			extraction_table_reset_label: "Table",
			extraction_tags: "Tags (max = 10)",
			extraction_value_button_label: "Value",
			extraction_value_placeholder: "&lt;no value selected&gt;",
			extraction_zoom: "Zoom:",
			extraction_zoom_in: "-",
			extraction_zoom_out: "+",

			form_section_alignment_label: "Text alignment",
			form_section_border_width_label: "Border width",
			form_section_inner_margin_label: "Inner margin",
			form_section_outer_margin_label: "Outer margin",
			form_section_width_label: "Section width (1-12)",

			formitem_add_property_label: "Add property / attachment",
			formitem_add_property_tooltip: "Add a property and/or attachment to this form section.",
			formitem_settings_tooltip: "Set additional sizing & style attributes for this section.",
			formitem_split_horizontal_label: "Add section right",
			formitem_split_horizontal_tooltip: "Add a new form section to the right of the current / selected section.",
			formitem_split_vertical_label: "Add section below",
			formitem_split_vertical_tooltip: "Add new form section below the current / selected section.",

			general_assignment_label: "Assignments:",
			general_back_tooltip: "Go back one level.",
			general_refresh_list_tooltip: "Refresh this list",
			general_refresh_item_tooltip: "Refresh this item",
			general_created_label: "Created:",
			general_expand_item: "Click to expand / collapse this item",
			general_export: "Export",
			general_new: "New",
			general_no_items_to_display: "No items to display.",
			general_parenttask_label: "Subflow:",
			general_updated_label: "Updated:",
			general_usertask_label: "User Task:",

			launch_assignment_cancel: "Cancel",
			launch_assignment_cancel_tooltip: "Cancel launch.",
			launch_assignment_run: "Run",
			launch_assignment_run_tooltip: "Run workflow.",

			list_filtered_by: "Filtered by:",
			list_import: "Import",
			list_new: "New",

			main_back_to_main_tooltip: "Click to return to the main view.",
			main_logout_label: "Logout",
			main_menu_lg: "Main Menu",
			main_menu_sm: "Menu",
			main_scope_depth: "Depth",
			main_scope_depth_decr: "-",
			main_scope_depth_incr: "+",
			main_scope_label: "Scope",
			main_show_main_menu_tooltip: "Show / hide main menu.",
			main_show_settings_tooltip: "Show settings / support options.",
			main_view_uninitialized: "Loading...",

			member_select_choose: "Please choose...",
			member_select_placeholder: "Select or enter email",

			menu_assignment_lg: "Assignment",
			menu_assignment_sm: "Assignment",
			menu_assignments_completed_lg: "Completed / Canceled",
			menu_assignments_completed_sm: "Completed",
			menu_assignments_inprocess_lg: "In Process",
			menu_assignments_inprocess_sm: "In Process",
			menu_assignments_lg: "My assignments",
			menu_assignments_sm: "Assignments",
			menu_automation_lg: "Automation",
			menu_automation_sm: "Automation",
			menu_automations_lg: "Automations",
			menu_automations_sm: "Automations",
			menu_clear_tags: "Clear filter",
			menu_document_lg: "Document",
			menu_document_sm: "Document",
			menu_documents_lg: "My Documents",
			menu_documents_sm: "Documents",
			menu_documents_template_lg: "Document Templates",
			menu_documents_template_sm: "Templates",
			menu_documents_working_lg: "Most Recent",
			menu_documents_working_sm: "Recent",
			menu_errors_lg: "Errors",
			menu_errors_sm: "Errors",
			menu_extract_lg: "Extract",
			menu_extract_sm: "Extract",
			menu_extracttemplates_lg: "Extract Templates",
			menu_extracttemplates_sm: "Extracts",
			menu_graphiql_lg: "GraphiQL",
			menu_graphiql_sm: "GraphiQL",
			menu_profile_lg: "Profile",
			menu_profile_sm: "Profile",
			menu_propertiesdefs_lg: "Property Definitions",
			menu_propertiesdefs_sm: "Property Definitions",
			menu_settings_lg: "Settings & Configuration",
			menu_settings_sm: "Settings",
			menu_settingslist_lg: "Settings",
			menu_settingslist_sm: "Settings",
			menu_supportcase_lg: "Support Case",
			menu_supportcase_sm: "Support",
			menu_supportcases_closed_lg: "Closed",
			menu_supportcases_closed_sm: "Closed",
			menu_supportcases_lg: "Support Cases",
			menu_supportcases_open_lg: "Open",
			menu_supportcases_open_sm: "Open",
			menu_supportcases_sm: "Support",
			menu_templates_active_lg: "Project Templates",
			menu_templates_active_sm: "Project",
			menu_templates_lg: "My templates",
			menu_templates_sm: "Templates",
			menu_test_lg: "Test",
			menu_test_sm: "Test",
			menu_workflow_lg: "Task List",
			menu_workflow_sm: "Task List",
			menu_workflows_completed_lg: "Completed / Canceled",
			menu_workflows_completed_sm: "Completed",
			menu_workflows_draft_lg: "Drafts",
			menu_workflows_draft_sm: "Drafts",
			menu_workflows_inprocess_lg: "In Process",
			menu_workflows_inprocess_sm: "In Process",
			menu_workflows_lg: "My projects",
			menu_workflows_sm: "Projects",

			milestone_action_clear: "Clear",
			milestone_action_select: "Select",

			org_save: "Save",
			org_save_tooltip: "Save member and organization updates.",

			permission_delegate_tooltip: "Click to navigate to the delegated object.",
			permission_list_delegated_permissions: "Delegated Permissions:",
			permission_list_direct_permissions: "Direct Permissions:",
			permission_list_object_label: "Object",
			permission_list_permission_label: "Permission",
			permission_list_user_label: "User",

			profile_close_support_request: "Close Support Request",
			profile_update_support_request: "Save",

			properties_created: "Created",
			properties_created_by: "Created By",
			properties_description: "Description",
			properties_id: "ID",
			properties_state: "State",
			properties_updated: "Last Updated",
			properties_updated_by: "Last Updated By",
			properties_workflow_name: "Name",

			property_attachment_select_button: "Google documents",
			property_expand_tooltip: "Click to expand / collapse this property",
			property_name_placeholder: "Property name",
			property_option_placeholder: "Property option name",
			property_option_prefix: "Option ",
			property_tooltip_add_property_option: "Click to add another option",
			property_tooltip_delete_property_option: "Click to delete this option",
			property_tooltip_drag_property: "Drag this property to reorder it",

			property_gen1: "General (basic)",
			property_gen2: "General (advanced)",

			property_object: "Object",
			property_list_multi: "List (multi choice)",
			property_dropdown_single: "Dropdown (single choice)",
			property_dropdown_multi: "Dropdown (multi choice)",
			property_list_single: "List (single choice)",
			property_attachment: "Attachment",
			property_docref: "docref",
			property_json: "JSON",
			property_json_designtime_placeholder: "JSON value",
			property_json_runtime_placeholder: "JSON value",
			property_member: "Member",
			property_membergroup: "Member group",
			property_string_multi: "Text (multi line)",
			property_string_multi_designtime_placeholder: "Text (multi line)",
			property_string_multi_runtime_placeholder: "",
			property_string: "Text (single line)",	
			property_string_designtime_placeholder: "Text (single line)",
			property_string_runtime_placeholder: "",
			property_date: "Date",
			property_date_designtime_placeholder: "YYYY/MM/DD",
			property_date_runtime_placeholder: "YYYY/MM/DD",
			property_table: "Table",
			property_number: "Number",
			property_number_designtime_placeholder: "Numeric value",
			property_number_runtime_placeholder: "0",
			property_boolean: "Boolean",
			property_currency: "Currency",
			property_currency_designtime_placeholder: "$0.00",
			property_currency_runtime_placeholder: "$0.00",
			property_password: "Password",
			property_password_designtime_placeholder: "Password",
			property_password_runtime_placeholder: "Password",
			property_telephone: "Telephone (U.S.)",
			property_telephone_designtime_placeholder: "+1-555-555-5555",
			property_telephone_runtime_placeholder: "+1-555-555-5555",
			property_email: "Email",
			property_email_designtime_placeholder: "email@address.com",
			property_email_runtime_placeholder: "email@address.com",
			property_usstateabbrev: "States Abbrev (U.S.)",
			property_usstateabbrev_designtime_placeholder: "CA",
			property_usstateabbrev_runtime_placeholder: "CA",
			property_uszipcode: "Zipcode (U.S.)",
			property_uszipcode_designtime_placeholder: "00000-0000",
			property_uszipcode_runtime_placeholder: "00000-0000",
			property_guid: "GUID",
			property_guid_designtime_placeholder: "GUID",
			property_guid_runtime_placeholder: "GUID",

			role_orgadministrator: "Org Administrator",
			role_workflowowner: "Workflow Owner",
			role_workfloweditor: "Workflow Editor",
			role_workflowviewer: "Workflow Viewer",
			role_workflowlauncher: "Workflow Launcher",
			role_assignmentowner: "Assignment Owner",
			role_assignmentviewer: "Assignment Viewer",
			role_extractowner: "Extract Owner",
			role_extracteditor: "Extract Editor",
			role_extractviewer: "Extract Viewer",
			role_automationowner: "Automation Owner",
			role_automationeditor: "Automation Editor",
			role_automationviewer: "Automation Viewer",
			role_supportcaseowner: "Support Case Owner",
			role_supportcaseeditor: "Support Case Editor",
			role_supportcaseviewer: "Support Case Viewer",
			role_documentowner: "Document Owner",
			role_documenteditor: "Document Editor",
			role_documentviewer: "Document Viewer",

			route_condition_add_expr_tooltip: "Add expression",
			route_condition_add_route_tooltip: "Add route condition",
			route_condition_allcomplete: "ALL assignees complete this task",
			route_condition_allselect: "ALL assignees select",
			route_condition_allsubcomplete: "ALL subtasks completed for this parent task",
			route_condition_anycomplete: "ANY assignee completes this task",
			route_condition_anyselect: "ANY assignee selects",
			route_condition_delete_tooltip: "Click to delete this route",
			route_condition_else: "else proceed to the following task",
			route_condition_expression: "the first expression evaluates to true",
			route_condition_expression2: "all subtasks are complete AND the first expression evaluates to true",
			route_condition_finish: "End",
			route_condition_if: "When",
			route_condition_majoritycomplete: "a MAJORITY of assignees complete this task",
			route_condition_majorityselect: "a MAJORITY of assignees select",
			route_condition_nexttask: "Next task",
			route_condition_no_routes: "No routes to display.",
			route_condition_option_label: "Routing option name",
			route_condition_option_prefix: "Option ",
			route_condition_then_proceed: "then proceed to",

			savetojson_title_placeholder: "Document title (optional)",

			screen_main_menu: "Main Menu",
			screen_assignment_list: "Assignments",
			screen_assignment_working_list: "Working Assignments",
			screen_assignment_completed_list: "Completed Assignments",
			screen_assignment: "Assignment",
			screen_document_list: "Documents",
			screen_document_working_list: "Working Documents",
			screen_document: "Document",
			screen_workflow_list: "Workflows",
			screen_workflow_draft_list: "Draft Workflows",
			screen_workflow_working_list: "Working Workflows",
			screen_workflow_completed_list: "Completed Workflows",
			screen_workflow: "Workflow",
			screen_template_list: "Templates",
			screen_workflow_template_list: "Workflow Templates",
			screen_document_template_list: "Document Templates",
			screen_settings: "Settings",
			screen_settings_list: "Settings",
			screen_extract_list: "Extracts",
			screen_extract: "Extract",
			screen_automation_list: "Automations",
			screen_automation: "Automation",
			screen_propertydefinition_list: "Property Definitions",
			screen_profile: "Profile",
			screen_test: "Test",
			screen_graphiql: "GraphiQL",
			screen_supportcase_list: "Suport Cases",
			screen_supportcase_working_list: "Open Support Cases",
			screen_supportcase_completed_list: "Closed Support Cases",
			screen_supportcase: "Support Case",
			screen_launch_assignment: "Launch Assignment",
			screen_errors: "Error Page",

			settings_allow_member_override: "Allow Member Override",
			settings_allow_orgunit_override: "Allow Org Unit Override",
			settings_tooltip_add_extract_template: "Define a new extract template",
			settings_default_grant_assignees_viewer_role: "By Default, Grant Assignees Viewer Role on Project",			
			settings_enable_autoclassification: "By Default, Enable Autoclassification on Attachments",			
			settings_enable_conditional_routing: "Enable Conditional Routing",
			settings_enable_parallel_routing: "Enable Parallel Routing",
			settings_receive_notifications_for_workflow_startstop: "Receive Project Notifications",
			settings_receive_notifications_for_assignment_startstop: "Receive Assignment Notifications",
			settings_receive_milestone_notifications: "Receive Milestone Notifications",
			settings_enable_subflows: "Enable Subflows",
			settings_tasks_per_workflow: "Tasks Allowed Per Project",
			settings_properties_per_workflow: "Properties Allowed Per Project",
			settings_oevs_per_workflow: "Owners, Editors, Viewers (combined) Allowed Per Project",
			settings_assignees_per_task: "Assignees Allowed Per Task",
			settings_concurrent_workflows_per_user: "Concurrent, Running Workflows Allowed Per User",
			settings_total_workflows_per_user: "Total Workflows (in any state) Allowed Per User",
			settings_enable_object_properties: "Enable Composite Properties",
			settings_add_extract_template: "New extract template from a sample document:",
			settings_tooltip_add_extract_template: "Define a new extract template",
			settings_pick_extract_title: "Select a sample document to define extraction rules",
			settings_google_file_picker: "Google",
			settings_general_type: "General Settings",
			settings_feature_type: "Feature Toggles",
			settings_notification_type: "Notification Settings",
			settings_limit_type: "System Limits",

			supportcase_close_confirmation: "This will close this support case.  This cannot be reversed.  If you're sure you wish to close this support case - press Close.",
			supportcase_create: "New Support Case",
			supportcase_severity_1: "1 - significant impact to operations",
			supportcase_severity_2: "2 - moderate impact to operations",
			supportcase_severity_3: "3 - intermittent/low impact to operations",
			supportcase_state_label: "State",

			tab_authored_view: "Authored View",
			tab_authored_view_tooltip: "The authored view shows the authored (draft/template) state of the given project.",
			tab_details: "Details",
			tab_details_tooltip: "The details tab shows details of a given project such as properties, current state, etc.",
			tab_discussion: "Discussion",
			tab_discussion_tooltip: "The discussion tab shows the entire discussion thread associated with a given project.",
			tab_permissions: "Permissions",
			tab_permissions_tooltip: "The permissions tab shows all the permissions for the given object.",
			tab_runtime_view: "Runtime View",
			tab_runtime_view_tooltip: "The runtime view shows that running state of the given project.",
			tab_tasks: "Tasks",
			tab_tasks_tooltip: "The tasks tab shows any tasks associated with this project.",
			tab_who: "Who",
			tab_who_tooltip: "The who tab defines who is being assigned to the given task.",
			tab_what: "What",
			tab_what_tooltip: "The what tab defines what data (properties, attachments, etc) will be visible to the end user on this task/assignment.",
			tab_when: "When",
			tab_when_tooltip: "The when tab defines when a given task should be completed.",
			tab_where: "Where",
			tab_where_tooltip: "The where tab defines where this task will go after it is completed.",

			tagbar_add_tag_tooltip: "Click to add a new tag.",
			tagbar_select_tag_tooltip: "Click tag to select/filter.",
			tagbar_unselect_tag_tooltip: "Click tag to unselect/delete.",

			task_add_milestone_actions: "+ Add milestone action",
			task_assign_members_directly: "Assign members directly",
			task_assign_members_from_property: "Assign members from a property",
			task_attachments_select_label: "Select from existing attachments...",
			task_finish_label: "On Finish",
			task_no_actions_to_display: "No actions to display.",
			task_no_milestone_actions: "No milestone actions defined.",
			task_no_properties_to_display: "No properties to display.",
			task_parallel_execution_off: "Parallel (off)",
			task_parent_instr_placeholder: "Subflow description...",
			task_preview_button: "Preview",
			task_properties_select_label: "Select from existing properties...",
			task_properties_to_present_label: "What should be shown on this task assignment:",
			task_properties_to_present_launch_label: "What should be shown when this project is run:",
			task_show_comment_tab_label: "Allow comments to be submitted on this assignment?",
			task_start_label: "On Launch",
			task_tooltip_add_task: "Add task",
			task_tooltip_delete_task: "Delete task",
			task_tooltip_drag_task: "Drag this task to reorder it",
			task_tooltip_move_to_parent: "Move this task up to its parent flow",
			task_tooltip_move_to_subflow: "Move this task into a subflow",
			task_tooltip_preview: "Click to see what this task will look like to an assigned user",
			task_type_milestone_label: "Milestone",
			task_type_user_label: "User",
			task_user_instr_placeholder: "Enter task item here...",
			task_when_label: "TBD:  When is this task due?",
			task_who_label: "Who should perform this task assignment?",
			task_who_no_groups_avail: "No member group properties defined.",

			taskinstance_state_working: "working",
			taskinstance_state_completed: "completed",
			taskinstance_state_canceled: "canceled",

			tooltip_prn_copy: "Click to copy property resource name (PRN).",

			"validation_error_pattern": "'{{data.name}}' does not match the required format (value: {{data.value}})",
			"validation_error_maxlength": "'{{data.name}}' does not meet the maximum length requirement (value: {{data.value}})",
			"validation_error_minlength": "'{{data.name}}' does not meet the minimum length requirement (value: {{data.value}})",
			"validation_error_enum": "'{{data.name}}' contains an invalid enum value (value: {{data.value}})",
			"validation_error_type": "'{{data.name}}' has an invalid type/value (value: {{data.value}})",
			
			"validation_error_nonnull": "'{{data.name}}' does not meet the nonnull requirement",
			"validation_error_too_few_items": "'{{data.name}}' does not meet the maximum items requirement",
			"validation_error_too_many_items": "'{{data.name}}' does not meet the maximum items requirement",

			"validation_error": "Validation error:  {{data.name}}, {{data.message}}",
			"validation_error_no_assignees": "No one is assigned to this user task",
			"validation_error_no_children": "There must be at least one child task",
			"validation_error_invalid_task_type": "Invalid task type",
			"validation_error_too_many_tasks": "Maximum allowed number of tasks exceeded",
			"validation_error_too_many_properties": "Maximum allowed number of properties exceeded",
			"validation_error_missing_task_instr": "Missing task instructions",
			"validation_error_no_property_name": "Empty property name",
			"validation_error_no_task_name": "Empty task name",
			"validation_error_duplicate_property_name": "Duplicate property name",
			"validation_error_duplicate_task_name": "Duplicate task name",
			"validation_error_invalid_property_name": "Invalid property name",
			"validation_error_tasks_nested_too_deeply": "Tasks nested too deeply",
			"validation_error_property_nested_too_deeply": "Composite property nested too deeply",
			"validation_error_invalid_json": "'{{data.name}}' has an invalid JSON value: {{data.message}}",
			"validation_error_invalid_numeric_value": "Invalid numeric value: ({{data}})",
			"validation_error_schema_validation_error": "Schema validation error ({{data}})",
			"validation_error_property_schema": "Validation error ({{data.name}}, {{data.message}})",
			"validation_error_no_user_tasks": "No user tasks.",
			"validation_error_property_errors": "Property errors.",
			"validation_error_too_many_initiating_attachments": "Too many initiating attachments.  There can be at most one initiating attachment.",
			"validation_error_invalid_rcoption_name": "Invalid route condition option name",
			"validation_error_invalid_rcoption_type": "Invalid route condition option type",
			"validation_error_invalid_rcoption_expr": "Invalid route condition option expression",
			"validation_error_invalid_rcoption_dest": "Invalid route condition option destination",
			"validation_error_invalid_rc_quorum": "Invalid route condition quorum",
			"validation_error_invalid_rc_defaultoption": "Invalid route condition default option",
			"validation_error_invalid_workflow_name": "Invalid workflow name",
			"validation_error_workflow_desc_too_long": "Workflow description exceeds maximum length.",
			"validation_error_property_name_too_short": "Property name too short",
			"validation_error_property_name_too_long": "Property name too long",
			"validation_error_property_desc_too_short": "Property description too short",
			"validation_error_property_desc_too_long": "Property description too long",
			"validation_error_invalid_milestone": "Invalid milestone action",

			workflow_add_attachment: "Add attachment",
			workflow_add_property: "Add property",
			workflow_add_sub_property: "Add sub property",
			workflow_advanced_details: "Details",
			workflow_advanced_details_tooltip: "Display advanced details for this project.",
			workflow_advanced_versions: "Versions",
			workflow_advanced_versions_tooltip: "Display versions for this project.",
			workflow_advanced_restore_version: "Restore",
			workflow_attachments: "Attachments",
			workflow_cancel_workflow: "Cancel",
			workflow_collapse_all_tasks: "Collapse All",
			workflow_create_automation: "Create Launch Automation",
			workflow_create_draft: "New project",
			workflow_create_draft_sm: "Project",
			workflow_desc_placeholder: "Enter project description here",
			workflow_discard: "Discard",
			workflow_expand_all_tasks: "Expand All",
			workflow_export: "Export",
			workflow_name_placeholder: "Enter project name here",
			workflow_optimizeForHVI: "Optimize for high-volume-ingestion (HVI)",
			workflow_properties: "Properties",
			workflow_run_workflow: "Run",
			workflow_save: "Save",
			workflow_save_as_draft: "Save as draft",
			workflow_save_as_template_lg: "Save as template",
			workflow_save_copy: "Save copy",
			workflow_state_draft: "draft",
			workflow_state_template: "template",
			workflow_state_working: "working",
			workflow_state_completed: "completed",
			workflow_state_canceled: "canceled",
			workflow_tags: "Tags (max = 10)",

			workflow_tooltip_cancel_workflow: "Cancel this project",
			workflow_tooltip_collapse_all: "Collapse all tasks",
			workflow_tooltip_create_automation: "Create an automation against this project",
			workflow_tooltip_create_draft: "Create a new draft project",
			workflow_tooltip_create_template: "Create a new project template",
			workflow_tooltip_delete_member: "Click to delete this member",
			workflow_tooltip_discard: "Permanently discard this project",
			workflow_tooltip_expand_all: "Expand all tasks",
			workflow_tooltip_export_workflow: "Export this project",
			workflow_tooltip_form_section_footer: "Enter section footer text here.",
			workflow_tooltip_form_section_header: "Enter section header text here.",
			workflow_tooltip_form_page_header: "Enter page header text here.",
			workflow_tooltip_form_page_footer: "Enter page footer text here.",
			workflow_tooltip_import_workflow: "Import this project",
			workflow_tooltip_run_workflow: "Run this project which will instantiate the initial tasks/assignments, set the state to working, and provide an active project environment for members to collaborate in.",
			workflow_tooltip_save: "Save this project",
			workflow_tooltip_save_as_draft: "Save this project as a draft",
			workflow_tooltip_save_as_template: "Save this project as a reusable template",
			workflow_tooltip_save_copy: "Save another copy of this project template"
			
		}
	},
	de: {
		translation: {
			
			menu_assignments: "German My task assignments",
			menu_templates: "German My templates",
			menu_workflows: "German My workflows",
			menu_assignment: "German Assignment",
			
		}
	},
	es: {
		translation: {
			menu_assignments_lg: "Mis asignaciones",
			menu_assignments_sm: "Asignaciones",
			menu_assignments_inprocess_lg: "En proceso",
			menu_assignments_inprocess_sm: "En proceso",
			menu_assignments_completed_lg: "Completado / Cancelado",
			menu_assignments_completed_sm: "Completado",
		}
	},
}