import React from 'react'
import { FixedSizeList } from 'react-window'
import { Grid, Button, Divider, Typography, Tooltip } from '@mui/material'
import { ArrowBack, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import VirtualList from '../components/VirtualList'
import AssignmentListItem from '../components/AssignmentListItem'
import TagFilterBar from '../components/TagFilterBar'


const AssignmentList = ({ navigation, xs, tagFilter, app, assignments, screen, retrieveAssignments, retrieveAssignment }) => {

//	console.log('AssignmentList')

	let list = []
	let listCount = 0
	let retrieve = ()=>{}
	if (screen === 'assignment_working_list') {
		list = app.workingAssignmentsList
		listCount = app.workingAssignmentsCount ? app.workingAssignmentsCount : 0
		retrieve = (skip) => retrieveAssignments('workingAssignments', skip)
	} else if (screen === 'assignment_completed_list') {
		list = app.completedAssignmentsList
		listCount = app.completedAssignmentsCount ? app.completedAssignmentsCount : 0
		retrieve = (skip) => retrieveAssignments('completedAssignments', skip)
	}
	
	return (
		<Grid item id='list' >

			<Grid container id='buttonbar' direction={'row'} alignItems='center' >

				<Button disabled={!xs} color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				
				{ tagFilter && tagFilter.length > 0 ?
					<TagFilterBar tagFilter={tagFilter} />
				: null }
				
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={()=>retrieve(0)} ><Refresh/></Button>					
				</Tooltip>
			</Grid>

			{ listCount === 0 ?
				<Typography style={{padding: 20, color: 'grey'}}>{i18.t('general_no_items_to_display')}</Typography>
			: null }

			<VirtualList objList={assignments} idList={list} listCount={listCount} retrieve={retrieve} >
				{(childProps) => <AssignmentListItem navigation={navigation} me={app.me} assignment={childProps.item} retrieveAssignment={retrieveAssignment} />}
			</VirtualList>

		</Grid>
	)
}

export default AssignmentList
