import React from 'react'
import { ListItem, Typography } from '@mui/material'


const SupportCaseListItem = ({ navigation, supportcase, retrieveSupportCase }) => {

//	console.log('SupportCaseListItem')
		
	if (!supportcase)
		return null

	return (

		<ListItem 
			onClick={()=>{
				if (!supportcase.created) {retrieveSupportCase(supportcase.supportcaseId)}
				navigation.navigate('supportcase', {supportcaseId: supportcase.supportcaseId})
			}}
			button 
		>
			<Typography noWrap >{`${supportcase.summary} (sev${supportcase.severity})`}</Typography>
		</ListItem>
	)
}

export default SupportCaseListItem
