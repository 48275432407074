import React, { useState } from 'react'
import { Grid, FormControlLabel, Switch, Select, MenuItem } from '@mui/material'
import * as i18 from 'i18next'


const Setting = ({ setting, orgType='member', documents, update }) => {
 
//	console.log('Setting')

	if (!setting) return null

	const [retrieveDocDetailStatus, setRetrieveDocDetailStatus] = useState()
	
	let enabled = (orgType === 'org' && setting.allowOrgOverride) ||
				  (orgType === 'orgunit' && setting.allowOrgUnitOverride) ||
				  (orgType === 'member' && setting.allowMemberOverride)
	
	return (
		<Grid container direction='row' >
			
			<Grid item xs={7} >
				{(typeof setting.value === 'boolean') ? (
					<FormControlLabel
						label={i18.t(setting.name)}
						control={<Switch size="small" checked={setting.value} onChange={(event)=>{setting.value=event.target.checked;update()}} disabled={!enabled} />}
						labelPlacement="start"
					/>
				) : null }

				{(typeof setting.value === 'number' && setting.valueOptions) ? (
					<FormControlLabel
						label={i18.t(setting.name)}
						control={
							<Select 
								variant='standard'
								style={{marginLeft: 5}} 
								value={setting.value} 
								onChange={(event)=>{setting.value=event.target.value;update()}} disabled={!enabled} 
							>
								{setting.valueOptions.map(option => (
									<MenuItem key={option} value={option} >{option}</MenuItem>
								))}
							</Select>
						}
						labelPlacement="start"
					/>
				) : null }

			</Grid>
			
			<Grid item xs={5} >
				{ orgType === 'org' && setting.allowOrgOverride ?
					<FormControlLabel
						label={i18.t('settings_allow_orgunit_override')}
						control={<Switch size="small" disabled={!setting.allowOrgOverride} checked={setting.allowOrgUnitOverride} onChange={(event)=>{setting.allowOrgUnitOverride=event.target.checked;update()}} />}
						labelPlacement="start"
					/>
				: null }

				{ (orgType === 'org' || orgType === 'orgunit') && setting.allowOrgUnitOverride ?
					<FormControlLabel
						label={i18.t('settings_allow_member_override')}
						control={<Switch size="small" disabled={!setting.allowOrgUnitOverride} checked={setting.allowMemberOverride} onChange={(event)=>{setting.allowMemberOverride=event.target.checked;update()}} />}
						labelPlacement="start"
					/>
				: null }
				
			</Grid>
			
		</Grid>
	)
}

export default Setting
