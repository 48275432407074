import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as i18 from 'i18next'

import ExtractionRule from '../components/ExtractionRule'

import { updateProperty, changePropertyType, deleteProperty, deleteCompositeProperty, resetCompositeProperty } from '../reducers/properties'


const ExtractionRuleView = ({ org, orgProperties, rootparentId, propertyId, property, properties, 
								selectedProperty, highlightedRule, avgwordheight, designTime,
								setSelectedProperty, setHighlightedRule, setSelectionAttribute, setUpdateOverlays,
								updateProperty, changePropertyType, deleteProperty, deleteCompositeProperty, resetCompositeProperty,
								absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log("ExtractionRuleView", rootparentId, propertyId)

	return (
		<ExtractionRule
			org={org}
			orgProperties={orgProperties}
			rootparentId={rootparentId}
			property={property}
			properties={properties}
			selectedProperty={selectedProperty}
			highlightedRule={highlightedRule}
			avgwordheight={avgwordheight}
			designTime={designTime}
			
			setSelectedProperty={setSelectedProperty}
			setHighlightedRule={setHighlightedRule}
			setSelectionAttribute={setSelectionAttribute}
			setUpdateOverlays={setUpdateOverlays}
			
			updateProperty={(property) => updateProperty(rootparentId, property)}
			changePropertyType={changePropertyType}
			deleteProperty={() => deleteProperty(rootparentId, property.propertyId)}
			deleteCompositeProperty={deleteCompositeProperty}
			resetCompositeProperty={resetCompositeProperty}
			absoluteToProportionalRect={absoluteToProportionalRect}
			proportionalToAbsoluteRect={proportionalToAbsoluteRect}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	org: state.app.me ? state.app.me : null,
	orgProperties: state.app.me ? state.properties[state.app.me.orgentityId] : null,
	property: state.properties[props.rootparentId] ? state.properties[props.rootparentId][props.propertyId] : null,
	properties: state.properties[props.rootparentId],
	modified: props.rootparentId && props.propertyId && state.properties[props.rootparentId] && state.properties[props.rootparentId][props.propertyId] ? state.properties[props.rootparentId][props.propertyId].modified : false,
})

export default connect(mapStateToProps, { updateProperty, changePropertyType, deleteProperty, deleteCompositeProperty, resetCompositeProperty })(ExtractionRuleView)