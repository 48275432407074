import React, { useState } from 'react'
import { Grid, Button, Typography, Tooltip, TextField, Select, MenuItem } from '@mui/material'
import { Refresh, ArrowBack } from '@mui/icons-material'
import * as i18 from 'i18next'

import PermissionsView from '../containers/PermissionsView'
import DiscussionView from '../containers/DiscussionView'
import SupportCaseDetail from '../components/SupportCaseDetail'
import SupportCloseDialog from '../components/dialogs/SupportCloseDialog'
import TabBar from '../components/TabBar'


const SupportCase = ({ navigation, supportcase, retrieveSupportCase, saveSupportCase, updateSupportCase, closeSupportCase }) => {
 
//	console.log('SupportCase')

	if (!supportcase) return null

	const [tab, setTab] = useState('tab_details')
	const [closeDialogIsOpen, setCloseDialogIsOpen] = useState()

	let tabValues = ['tab_details','tab_permissions','tab_discussion']
	let tabLabels = [i18.t('tab_details'), i18.t('tab_permissions'), i18.t('tab_discussion')]

	return (
		<Grid container direction='column' >

			<Grid container direction="row" >

				<Tooltip title={i18.t('general_back_tooltip')} >
					<Button color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				</Tooltip>

				{supportcase.state === 'working' ?
					<Grid item>
						<Button color="primary" onClick={()=>saveSupportCase(supportcase.supportcaseId)} >{i18.t('profile_update_support_request')}</Button>
						<Button color="primary" onClick={()=>setCloseDialogIsOpen(true)} >{i18.t('profile_close_support_request')}</Button>
					</Grid>
				: null }
				
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_item_tooltip')} >
					<Button color="primary" onClick={()=>retrieveSupportCase(supportcase.supportcaseId)} >
						<Refresh/>
					</Button>
				</Tooltip>
				
			</Grid>

			<TabBar tabValues={tabValues} tabLabels={tabLabels} tab={tab} setTab={setTab} />	

			<br/>

			{ tab === 'tab_details' ?
				<SupportCaseDetail
					navigation={navigation}
					supportcase={supportcase}
					retrieveSupportCase={retrieveSupportCase}
					saveSupportCase={saveSupportCase}
					updateSupportCase={updateSupportCase}
					closeSupportCase={closeSupportCase}
				/>
			: tab === 'tab_permissions' ?
				<PermissionsView
					sourceType={'supportcase'}
					sourceId={supportcase.supportcaseId}
					designTime={true}
				/>
			: tab === 'tab_discussion' ?
				<DiscussionView
					sourceType={'supportcase'}
					sourceId={supportcase.supportcaseId}
				/>
			: null }

			{ closeDialogIsOpen ?
				<SupportCloseDialog closeSupportCase={()=>closeSupportCase(supportcase.supportcaseId)} setDialogIsOpen={setCloseDialogIsOpen} />
			: null }
			
		</Grid>
	)
}

export default SupportCase
