import React, { useState } from 'react'
import { Grid, Divider, Typography, Tooltip, Button, Hidden } from '@mui/material'
import { ArrowBack, Create, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import VirtualList from './VirtualList'
import AutomationListItem from './AutomationListItem'


const AutomationList = ({ navigation, automationsList, automationsCount, automations, events, actions, workflows, onSelect, retrieveAutomations, createAutomation }) => {

//	console.log('AutomationList')

	let retrieve = () => retrieveAutomations('automations')

	return (
		<Grid item>

			<Grid container id='buttonbar' direction={'row'} alignItems='center' >

				<Button disabled={true} color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>

				<Tooltip title={i18.t('automation_tooltip_create')} >
					<Button 
						color="primary" 
						onClick={()=>{createAutomation({}, (automationId)=> {
							navigation.reset('automation_list')
							navigation.navigate('automation', {automationId: automationId})
						})}} 
					>
						<Hidden smUp >
							<Create/>
						</Hidden>
						<Hidden smDown >
							{i18.t('automation_create')}
						</Hidden>
					</Button>
				</Tooltip>

				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={retrieve} ><Refresh/></Button>					
				</Tooltip>
			</Grid>

			{ automationsCount === 0 ?
				<Typography style={{margin: 20, color: 'grey'}}>{i18.t('general_no_items_to_display')}</Typography>
			: null }
			
			<VirtualList objList={automations} idList={automationsList} listCount={automationsCount} retrieve={retrieve} >
				{(childProps) =>
					<AutomationListItem automation={childProps.item} automations={automations} onSelect={onSelect} />
				}
			</VirtualList>

		</Grid>					
	)
}

export default AutomationList
