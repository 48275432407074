import { RECEIVE_AUTOMATIONS, RECEIVE_AUTOMATION, DELETE_AUTOMATION, UPDATE_AUTOMATION, RECEIVE_ERRORS } from './actiontypes'
import { normalize, schema } from 'normalizr'
import cloneDeep from 'lodash.clonedeep'
import { automationReturnFragment, automationMinFragment, automationMaxFragment, permissionFragment, limitedOrgEntityFragment, commentFragment } from '../reducers/graphql'
import { store } from '../App'
import { gqlExec, gqlInput, gqlIdInput } from '../reducers/graphql'


export const automationSchema = new schema.Entity(
	'automations', 
	{
	}, 
	{
		idAttribute: value => value.automationId
	}
)

export const removeMeta = (obj, propName) => {
	for(let prop in obj) {
		if (prop === propName) {
			delete obj[prop]
		} else if (typeof obj[prop] === 'object') {
			removeMeta(obj[prop], propName)
		}
	}
}

export const createAutomation = (input={}, onCreate) => async dispatch => {

//	console.log('createAutomation');

	try {
		document.body.style.cursor = 'wait'

		// Remove additive properties
		delete input.comments
		delete input.tags

		removeMeta(input, 'created')
		removeMeta(input, 'createdBy')
		removeMeta(input, 'updatedBy')
		removeMeta(input, 'version')
		removeMeta(input, 'domain')
	
		const response = await gqlExec(`mutation CreateAutomation {createAutomation (input: ${JSON.stringify(input)}) {...automationFields}} ${automationMaxFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${commentFragment} `)		

		await dispatch(retrieveAutomations())	// TBD - just refresh redux by deleting this one object rather than refreshing all

		if (onCreate) {onCreate(response?.createAutomation?.automationId)}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}}

const postAutomationUpdates = (automations) => {

//	console.log('postExtractUpdates', automations)

	if (automations) {

		// Now normalize the automation data
		const data = normalize(automations ? automations : [], [automationSchema]);		
	
		return {list: data.result, automations: data.entities.automations}
	}
}

export const retrieveAutomations = (listType='automations', skip=0, tagFilter, scope) => async dispatch => {
	
//	console.log('retrieveAutomations');

	try {
		document.body.style.cursor = 'wait'

		let states = []
		states = (listType === 'automations') ?
			states = ['working','disabled']
		: null

		const response = await gqlExec(`{automations ${gqlInput(states, tagFilter, skip, true, true, true)} {...automationReturnFields}} ${automationReturnFragment} ${automationMinFragment}`)		
		
		const automations = response.automations

		if (automations) {
			let action = postAutomationUpdates(automations.items)
			action.type = RECEIVE_AUTOMATIONS
			action.listType = listType
			action.skip = skip
			action.listCount = automations.totalCount
			dispatch(action)
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const retrieveAutomation = (automationId) => async dispatch => {

//	console.log('retrieveExtract');

	if (!automationId)
		return
	
	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`query RetrieveAutomation {automations ${gqlIdInput('automation', automationId)} {...automationReturnFields}} ${automationReturnFragment} ${automationMaxFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${commentFragment} `)

		const automations = response.automations

		if (automations) {
			let action = postAutomationUpdates(automations.items)
			action.type = RECEIVE_AUTOMATION
//			action.listType = 'automations'
			dispatch(action)
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const saveAutomation = (automationId, setStatusIndicator) => async dispatch => {
	
//	console.log('saveAutomation', automationId)
	
	try {
		if (setStatusIndicator) {setStatusIndicator(true)}

		let automation = cloneDeep(store.getState().automations[automationId])

		// Remove additive properties
		delete automation.state
		delete automation.comments
		delete automation.tags

		removeMeta(automation, 'validationErrors')
		removeMeta(automation, 'created')
		removeMeta(automation, 'createdBy')
		removeMeta(automation, 'updatedBy')
		removeMeta(automation, 'version')
		removeMeta(automation, 'domain')

		const response = await gqlExec(`mutation {updateAutomation (input: ${JSON.stringify(automation)}){...automationFields}} ${automationMaxFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${commentFragment} `)

		let automation2 = response.updateAutomation

		let action = postAutomationUpdates([automation2])
		action.type = RECEIVE_AUTOMATION
//		action.listType = 'automations'
		dispatch(action)
		
		return automation2
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}

export const updateAutomation = (automation) => async dispatch => {
	dispatch({type: UPDATE_AUTOMATION, automation: automation})
}

export const setEnabled = (automationId, enabled) => async dispatch => {
	
//	console.log('setEnabled');

	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`mutation {setEnabled (automationId: "${automationId}", enabled: ${enabled}) {...automationFields}} ${automationMaxFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${commentFragment} `)

		let automation2 = response.setEnabled

		let action = postAutomationUpdates([automation2])
		action.type = RECEIVE_AUTOMATION
		dispatch(action)
		
		return automation2
	}
	catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const deleteAutomation = (automationId, onDelete) => async dispatch => {
	
//	console.log('deleteAutomation');
	
	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`mutation {deleteAutomation (automationId: "${automationId}") {automationId}} `)

		await dispatch(retrieveAutomations())	// TBD - just refresh redux by deleting this one object rather than refreshing all
	}
	catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
		if (onDelete) {onDelete()}
	}
}


const initialState = {
}

const automations = (state = initialState, action) => {
	switch (action.type) {
		
// Automation Events
			
		case RECEIVE_AUTOMATION:
		case RECEIVE_AUTOMATIONS:
			state = {...state, ...action.automations}
			break

		case UPDATE_AUTOMATION:
			state = {...state,
				[action.automation.automationId]: action.automation,
			}
			break
			
		case DELETE_AUTOMATION:
			delete state[action.automationId]
			break
			
		default:
			break
	}

	return state;
}

export default automations