//
//  Basic Tenants
//  - Assignments stored under taskInstanceId since each assignment property will have different values per taskInstance
//
import { CLEAR_ALL_ASSIGNMENTS, RECEIVE_ASSIGNMENTS, RECEIVE_ASSIGNMENT, COMPLETE_ASSIGNMENT_EVENT } from './actiontypes'
import { CREATE_LAUNCH_ASSIGNMENT, UPDATE_ASSIGNMENT_EVENT, RECEIVE_ERRORS } from './actiontypes'
import { schema, normalize, denormalize } from 'normalizr'
import { retrieveWorkflows, removeMeta } from '../reducers/workflows'
import { assignmentReturnFragment, assignmentMinFragment, assignmentMaxFragment, formFragment, formItemFragment, extractionRuleFragment } from '../reducers/graphql' 
import { assignmentCompleteFragment, propertyFragment, attachmentFragment, routeConditionFragment, expressionFragment, expressionBaseFragment, permissionFragment, limitedOrgEntityFragment, commentFragment } from '../reducers/graphql'
import { updateProperty } from '../reducers/properties'
import { propertySchema, attachmentSchema, setValuesFromInstanceData, getInstanceDataFromValues, removeValueAndDefault } from '../reducers/workflows'
import { store } from '../App'
import { gqlExec, gqlInput, gqlIdInput } from '../reducers/graphql'
import { generateHashCode } from '../reducers/utils'
import cloneDeep from 'lodash.clonedeep'
import { reset } from '../reducers/appnav'


export const assignmentSchema = new schema.Entity(
	'assignments', 
	{	
	}, 
	{
		idAttribute: value => value.assignmentId
	}
)


// Assignment Functions

export const clearAssignments = () => dispatch => {
	dispatch({type: CLEAR_ALL_ASSIGNMENTS})
}

export const postAssignmentUpdate = (assignments, action) => dispatch => {

	if (!assignments) return 
	
	// Normalize the properties
	let properties={}
	let attachments={}

	for (const assignment of assignments) {
				
		if (assignment.properties) {

			// Set values from instance data
			if (assignment.instanceData) {
				assignment.properties.forEach(property => {
					setValuesFromInstanceData(property, assignment.instanceData[property.pname])
				})
			}
			
			// Normalize the properties
			if (assignment.properties) {
				let propertydata = normalize(assignment.properties, [propertySchema])
				properties[assignment.assignmentId] = propertydata.entities.properties
				assignment.properties = propertydata.result
			}

			// Normalize the attachments
			if (assignment.attachments) {
				let attachmentdata = normalize(assignment.attachments, [attachmentSchema])
				attachments[assignment.assignmentId] = attachmentdata.entities.attachments
				assignment.attachments = attachmentdata.result
			}
			
		}		
	}
	
	// Now normalize the assignment data
	let assignmentdata = normalize(assignments, [assignmentSchema]);	
	
	action.list = assignmentdata.result
	action.assignments = assignmentdata.entities.assignments
	action.properties = properties
	action.attachments = attachments
	
	dispatch(action)
}

export const retrieveAssignments = (listType, skip=0, tagFilter) => async dispatch => {
	
//	console.log('retrieveAssignments');
		
	try {
		document.body.style.cursor = 'wait'

		let states = []
		states = (listType === 'workingAssignments') ?
			states = ['working']
		: (listType === 'completedAssignments') ?
			states = ['completed','canceled']
		: null

		const response = await gqlExec(`query RetrieveAssignments {assignments ${gqlInput(states, tagFilter, skip, true, true, true)} {...assignmentReturnFields}} ${assignmentReturnFragment} ${assignmentMinFragment} `)
		const assignments = response && response.assignments ? response.assignments : null
		if (assignments) {
			let action = {type: RECEIVE_ASSIGNMENTS, skip: skip, listType: listType, listCount: assignments.totalCount, tagList: assignments.tagList}
			dispatch(postAssignmentUpdate(assignments.items, action))
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const retrieveAssignment = (assignmentId) => async dispatch => {
	
//	console.log('retrieveMinAssignments');
		
	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`query RetrieveAssignment {assignments ${gqlIdInput('assignment', assignmentId)} {...assignmentReturnFields}} ${assignmentReturnFragment} ${assignmentMaxFragment} ${commentFragment} ${formFragment} ${formItemFragment} ${propertyFragment} ${attachmentFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${extractionRuleFragment} `)

		let assignments = response && response.assignments ? response.assignments.items : null

		if (assignments && assignments[0]) {
			assignments[0].hash = generateHashCode(assignments[0])
			dispatch(postAssignmentUpdate(assignments, {type: RECEIVE_ASSIGNMENT}))
		}
	
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const saveAssignment = (assignmentId, setStatusIndicator) => async dispatch => {
	
//	console.log('saveAssignment')

	if (!assignmentId) return

	try {
		document.body.style.cursor = 'wait'

		if (setStatusIndicator) {setStatusIndicator(true)}

		let assignment = cloneDeep(store.getState().assignments[assignmentId])
		let properties = cloneDeep(store.getState().properties[assignmentId])
		let attachments = cloneDeep(store.getState().attachments[assignmentId])
		
		// De-normalize the property list back into the assignment
		assignment.properties = denormalize(assignment.properties, [propertySchema], {properties: properties});

		// De-normalize the attachment list back into the assignment
		assignment.attachments = denormalize(assignment.attachments, [attachmentSchema], {attachments: attachments});

		// Get instance data from property values
		assignment.instanceData = {}
		if (assignment.properties) {
			for (const property of assignment.properties) {
				assignment.instanceData[property.pname] = getInstanceDataFromValues(property)
			}
		}	

		let hash = assignment.hash
	
		// Delete properties added for operational activities
		delete assignment.hash
		removeValueAndDefault(assignment.properties)
		removeMeta(assignment, 'parentpropertyId')
		removeMeta(assignment, 'modified')

		if (generateHashCode(assignment) === hash) {
console.log(`Save not needed!`)
			return
		}

console.log(`Saving assignment at ${new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(new Date())}`)

		// Delete system properties not allowed on input		
		// TBD - replace this with an input definition that we filter against
		delete assignment.domain
		delete assignment.workflowId
		delete assignment.linkId
		delete assignment.name
		delete assignment.instructions
		delete assignment.imageref
		delete assignment.routeCondition
		delete assignment.state
		delete assignment.properties
		delete assignment.comments
		delete assignment.permissions
		delete assignment.showCommentTab
		delete assignment.form
		delete assignment.created
		delete assignment.createdBy
		delete assignment.updatedBy
		delete assignment.version
		delete assignment.defaultInstanceData
  
		delete assignment.tags			// TBD - ???
		delete assignment.extracts		// TBD - ???

		removeMeta(assignment, 'validationErrors')
		removeMeta(assignment.properties, 'tags')

		const response = await gqlExec(`mutation{updateAssignment (input: ${JSON.stringify(assignment)}) {...assignmentFields}} ${assignmentMaxFragment} ${formFragment} ${formItemFragment} ${commentFragment} ${propertyFragment} ${attachmentFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${extractionRuleFragment} `)

		let assignment2 = response ? response.updateAssignment : null

		if (assignment2) {
			assignment2.hash = generateHashCode(assignment2)

			if (assignment2) {
				let action = {type: RECEIVE_ASSIGNMENT}
				dispatch(postAssignmentUpdate([assignment2], action))
			} else {
				dispatch({type: RECEIVE_ERRORS, errors: [{message: `updateAssignment failed: ${JSON.stringify([assignment2])}`}]})
			}
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
}

export const completeAssignment = (assignmentId, selectedRouteIndex, setStatusIndicator) => async dispatch => {
	
//	console.log('completeAssignment')
	
	if (!assignmentId) return
	
	try {
		document.body.style.cursor = 'wait'

		if (setStatusIndicator) {setStatusIndicator(true)}

		let assignment = cloneDeep(store.getState().assignments[assignmentId])
		let properties = cloneDeep(store.getState().properties[assignmentId])
		let attachments = cloneDeep(store.getState().attachments[assignmentId])

		let input = {
			assignmentId: assignment.assignmentId,									 
			selectedRoute: selectedRouteIndex,
		}

		// De-normalize the property list back into the workflow
		assignment.properties = denormalize(assignment.properties, [propertySchema], {properties: properties});

		// De-normalize the attachment list back into the assignment
		input.attachments = denormalize(assignment.attachments, [attachmentSchema], {attachments: attachments});
	
		// Get instance data from property values
		input.instanceData = {}
		if (assignment.properties) {
			for (const property of assignment.properties) {
				input.instanceData[property.pname] = getInstanceDataFromValues(property)
			}
		}	

		removeMeta(input, 'validationErrors')
		removeMeta(input, 'modified')

		const response = await gqlExec(`mutation{completeAssignment (input: ${JSON.stringify(input)}) {...assignmentCompleteFields}} ${assignmentCompleteFragment} `)
			
		let action = {type: RECEIVE_ASSIGNMENT}

		dispatch(postAssignmentUpdate([response.completeAssignment], action))

		dispatch(clearAssignments())

		dispatch(retrieveAssignments('workingAssignments'))
		dispatch(retrieveAssignments('completedAssignments'))

		dispatch(retrieveWorkflows('workingWorkflows'))
		dispatch(retrieveWorkflows('completedWorkflows'))

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
}

export const updateAssignment = (assignment) => dispatch => {
	dispatch({type: UPDATE_ASSIGNMENT_EVENT, assignment: assignment})
}


const initialState = {}

const assignments = (state = initialState, action) => {
	switch (action.type) {

// Assignment Events

		case CLEAR_ALL_ASSIGNMENTS:
			state = {}
			break
		
		case RECEIVE_ASSIGNMENT:
		case RECEIVE_ASSIGNMENTS:
			state = {...state, ...action.assignments}
			break

		case UPDATE_ASSIGNMENT_EVENT:
		case COMPLETE_ASSIGNMENT_EVENT:
			state = {...state,
				[action.assignment.assignmentId]: action.assignment
			}
			break
			
		default:
			break
	}

	return state
}

export default assignments