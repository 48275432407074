import React, { useState } from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField  } from '@mui/material'
import * as i18 from 'i18next'

import TagBar from '../../components/TagBar'


const TagAddDialog = ({ tagList=[], selectedTags=[], onUpdate, setShowTagAddDialog }) => {

	const [showPropertyList, setShowPropertyList] = useState(false)
	const [tagList2, setTagList2] = useState(tagList ? tagList : [])
	const [selectedTags2, setSelectedTags2] = useState(selectedTags ? selectedTags : [])
	const [newTag, setNewTag] = useState()

	// Make the displayed tag list a combination of the current tag list + anything we've added
	let tagList3 = [...tagList2]
	for (const tag of selectedTags2) {
		if (tagList3.indexOf(tag) === -1) {
			tagList3.push(tag)
		}
	}
	
	// Filter the displayed tag list based on the new tag being typed to make it easier to see existing/matching tags
	if (newTag && newTag.length > 0) {
		tagList3 = tagList3.filter(tag => tag.includes(newTag))
	}
	
	return (
		<Dialog open={true} fullWidth >

			<DialogTitle><b>{i18.t('dialog_add_tag')}</b></DialogTitle>

			<DialogContent>

				<Grid container direction={'row'} alignItems='flex-end' >
					<TextField
						label={i18.t('dialog_add_tag_name_label')}
						value={newTag ? newTag : ''}
						onChange={(e)=>{
							const value = e.target.value.toUpperCase()
							const valid = value.length===0 || /^[A-Z0-9-/_/./&/ ]*$/.test(value)
							if (valid) {setNewTag(value)}
						}}
						onKeyDown={(e)=>{
								if (e.keyCode === 13) {
								const newTag2 = newTag.trim()
								let index = selectedTags2.indexOf(newTag2)
								if (newTag2 && newTag2.length > 0 && index === -1) {
									let tags = [...selectedTags2]
									tags.push(newTag2)
									setSelectedTags2(tags)
								}
								setNewTag(null)
							}
						}}
					/>
					<Button
						color="primary"
						onClick={(e)=>{
							const newTag2 = newTag.trim()
							let index = selectedTags2.indexOf(newTag2)
							if (newTag2 && newTag2.length > 0 && index === -1) {
								let tags = [...selectedTags2]
								tags.push(newTag2)
								setSelectedTags2(tags)
							}
							setNewTag(null)
						}} 
					>
						{i18.t('dialog_add_tag_name_button')}
					</Button>
				</Grid>
				
				<TagBar 
					tagList={tagList3} 
					selectedTags={selectedTags2} 
					onSelectionUpdate={(selectedTags)=>setSelectedTags2(selectedTags)} 
					editMode={false} 
				/>

			</DialogContent>

			<DialogActions>
				<Button onClick={(e)=>{e.stopPropagation();onUpdate(selectedTags2);setShowTagAddDialog(false)}} color="primary" variant="contained" >
					{i18.t('dialog_done')}
				</Button>
			</DialogActions>

		</Dialog>
	)
}

export default TagAddDialog