import React from 'react'
import { Grid, TextField, Typography, Divider, Avatar, Tooltip, Button } from '@mui/material'
import { Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'


const Profile = ({ navigation, app, org, orgs }) => {
 
//	console.log('Profile')
	
	if (!app.me && !app.scope) return null
	
	const parentOrg = app.me?.parentId ? orgs[app.me.parentId] : null

	return (
		<Grid item>

			<Grid container direction='row' alignItems='center'>
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={()=>{}} ><Refresh/></Button>
				</Tooltip>
			</Grid>

		{ app.scope ?
			<Grid container direction='column' >		
				<br/>
				<TextField 
					inputProps={{readOnly: false}} 
					InputLabelProps={{shrink: true}} 
					label={'Domain'} 
					value={org?.domain}
				/>
				
				<br/>
				
				<TextField 
					inputProps={{readOnly: true}} 
					InputLabelProps={{shrink: true}} 
					label={'Org Id'} 
					value={app.scope.orgentityId ? app.scope.orgentityId : ''} 
					/>

				<br/>
				<br/>
				
				{org.subEntities && org.subEntities.length > 0 ?
					<Grid item>
						<Typography variant='h6'>Sub-orgs:</Typography>
						<Divider/>
						{org.subEntities && org.subEntities.map((suborg, index) => (
							<Grid key={index} container direction={'row'}>
								<Typography style={{flex: 1}} >{suborg.name}</Typography>				
							</Grid>					
						))}
					</Grid>
				: null }
				
			</Grid>
		:
			<Grid container direction='column' >		
				<Avatar src={app.me.thumbnailPhotoUrl} />
				<br/>
				<TextField inputProps={{readOnly: true}} InputLabelProps={{shrink: true}} label={'Name'} value={app.me.fullName ? app.me.fullName : ''} />
				<br/>
				<TextField inputProps={{readOnly: true}} InputLabelProps={{shrink: true}} label={'Email'} value={app.me.email ? app.me.email : ''} />
				<br/>
				<TextField inputProps={{readOnly: true}} InputLabelProps={{shrink: true}} label={'Member Id'} value={app.me.orgentityId ? app.me.orgentityId : ''} />
				<br/>
				{ parentOrg ?
					<TextField inputProps={{readOnly: true}} InputLabelProps={{shrink: true}} label={'Organization'} value={parentOrg.name} />
				: null}
			</Grid>
		}

		</Grid>
	)
}

export default Profile
