import React from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'


const TaskWhen = ({ task }) => {
	
//	console.log('TaskWhen')
	
	if (!task) return null
		
	return (
		
		<Grid item xs={12}>
  
			<Typography variant="subtitle1" style={{marginTop: 10}} >{i18.t('task_when_label')}</Typography>
			
		</Grid>	
				
	)
}

export default TaskWhen
