import React from 'react'
import { List, ListSubheader } from '@mui/material'

import CommentListItem from '../components/CommentListItem'


const CommentList = ({ comments, listSelection, setListSelection, disabled, level=0, postComment }) => {

//	console.log('CommentList')
		
	if (!comments || comments.length === 0)
		return null

	let datelist = comments.map(comment => new Intl.DateTimeFormat("en-US", {dateStyle: 'full'}).format(Date.parse(comment.created)))
	let sections = [...new Set(datelist)]

	return (
		<List subheader={<li/>}>
			{sections.map((section, index) => (
				<li key={index} >
					<ul>
						{ level === 0 ?
							<ListSubheader inset>{section}</ListSubheader>
						: null }
						
						{datelist.map((date, index2) => {
							if (date === section) {
								return (
									<CommentListItem
										key={index2}
										comment={comments[index2]} 
										listSelection={listSelection} 
										setListSelection={setListSelection}
										disabled={disabled}
										postComment={postComment}
									/>
								)
							}
						})}
					</ul>
				</li>
			))}
		</List>	
	)
}

export default CommentList
