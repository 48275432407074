import React, { useState } from 'react'
import { Grid, Button, Tooltip, Hidden, CircularProgress } from '@mui/material'
import { ArrowBack, Save, PlayArrow, Delete, Cancel, Refresh, ListAlt, GetApp } from '@mui/icons-material'
import * as i18 from 'i18next'

import ActionButton from '../components/ActionButton'
import ConfirmDeletionDialog from '../components/dialogs/ConfirmDeletionDialog'
import ExtractionListDialog from '../components/dialogs/ExtractionListDialog'
import AttachmentAnalyzeFailDialog from '../components/dialogs/AttachmentAnalyzeFailDialog'


const DocumentToolBar = ({ navigation, doc, workflowId, hasErrors, 
							createDocument, saveDocument, saveDocumentAs, deleteDocument, exportDocument, createPropertiesFromExtract,
							analyzeDocument, createDocumentInstance, retrieveDocument }) => {

//	console.log('DocumentToolBar')

	const [deleteConfirmationDialogIsOpen, setDocumentDeleteDialogIsOpen] = useState(false)

	const [isSaving, setIsSaving] = useState(false)
	const [isSavingAsTemplate, setIsSavingAsTemplate] = useState(false)
	const [isSavingCopy, setIsSavingCopy] = useState(false)
	const [isExporting, setIsExporting] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const [isCreatingInstance, setIsCreatingInstance] = useState(false)
	const [isAnalyzing, setIsAnalyzing] = useState(false)
	const [showExtractionListDialog, setShowExtractionListDialog] = useState(false)
	const [showAnalyzeFailDialog, setShowAnalyzeFailDialog] = useState(false)

	return (
		<Grid item id='toolbar' >

			<Grid container direction="row" alignItems='center' >

				<Tooltip title={i18.t('general_back_tooltip')} disableInteractive={true} >
					<Button color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				</Tooltip>
	
				{/* save */}
				{ doc?.state === 'template' || doc?.state === 'working' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_save')} mdUp={i18.t('workflow_save')} mdDown={<Save/>} inProgress={isSaving} onClick={async ()=>{await saveDocument(doc.documentId, null, null, setIsSaving)}} />
				: null}
										
				{/* save as template */}
				{ doc?.state === 'working' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_save_as_template')} mdUp={i18.t('workflow_save_as_template_lg')} mdDown={<Grid container direction='row'><Save/>+</Grid>} inProgress={isSavingAsTemplate} onClick={async ()=>{await saveDocumentAs(doc.documentId, 'template', setIsSavingAsTemplate)}} />
				: null}
				
				{/* save copy */}
				{ doc?.state === 'template' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_save_copy')} mdUp={i18.t('workflow_save_copy')} mdDown={<Grid container direction='row'><Save/>+</Grid>} inProgress={isSavingCopy} onClick={async ()=>{await saveDocumentAs(doc.documentId, 'template', setIsSavingCopy)}} />
				: null}

				{/* generate schema from template */}
				{ doc?.state === 'template' ?
					<ActionButton tooltipText={i18.t('attachment_generate_schema_tooltip')} mdUp={i18.t('attachment_generate_schema_label')} mdDown={<ListAlt/>} onClick={()=>{setShowExtractionListDialog(true)}} />
				: null}

				{/* create document instance from template */}
				{ doc?.state === 'template' ?
					<ActionButton tooltipText={i18.t('document_create_instance_tooltip')} mdUp={i18.t('document_create_instance_label')} mdDown={<PlayArrow/>} inProgress={isCreatingInstance} 
						onClick={async ()=>{
							const doc2 = await createDocumentInstance(doc.documentId, null, null, null, setIsCreatingInstance)
							navigation.reset('document_working_list')
							navigation.navigate('document', {documentId: doc2.documentId})
						}}
					/>
				: null}

				{/* analyze button */}
				{ doc?.content && doc?.analysisMode === 'manual' ?
					<ActionButton 
						tooltipText={i18.t('workflow_tooltip_save')} 
						mdUp={analyzing ? i18.t('attachment_analyzing_document') : doc?.extractdata ? i18.t('attachment_reanalyze_document') : i18.t('attachment_analyze_document')} 
						mdDown={<Save/>} 
						inProgress={isAnalyzing} 
						onClick={async ()=>{await analyzeDocument(doc.documentId, setShowAnalyzeFailDialog, setIsAnalyzing)}} 
					/>
				: null }
							
				{/* discard */}
				{ doc ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_discard')} mdUp={i18.t('workflow_discard')} mdDown={<Delete/>} inProgress={isDeleting} onClick={async ()=>{await setDocumentDeleteDialogIsOpen(true)}} />
				: null }
					
				{/* export */}
				{ doc ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_export_workflow')} mdUp={i18.t('workflow_export')} mdDown={<GetApp/>} onClick={async ()=>{await exportDocument(doc.documentId)}} />
				: null }

				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_item_tooltip')} >
					<Button color="primary" onClick={async ()=>{await retrieveDocument(workflowId)}} >
						<Refresh/>
					</Button>
				</Tooltip>
								
			</Grid>

			{/* delete confirmation dialog */}
			{ deleteConfirmationDialogIsOpen ?
				<ConfirmDeletionDialog 
					dialogTitle={i18.t('dialog_delete_document_title')} 
					dialogContent={i18.t('dialog_delete_document_desc')}
					onDelete={()=>deleteDocument(doc.documentId, doc.state, navigation, setIsDeleting)}
					setDialogIsOpen={setDocumentDeleteDialogIsOpen}
				/>
			: null }

			{ showExtractionListDialog ?
				<ExtractionListDialog
					onComplete={(extractId) => createPropertiesFromExtract(doc, extractId, setIsCreatingSchema)}
					closeDialog={()=>setShowExtractionListDialog(false)}
				/>
			: null }

			{ showAnalyzeFailDialog ?
				<AttachmentAnalyzeFailDialog 
					documentId={doc.documentId}
					createExtract={createExtract}
					closeDialog={()=>setShowAnalyzeFailDialog(false)} 
				/>
			: null }

		</Grid>
	)
}

export default DocumentToolBar
