import { Button } from '@mui/material'
import Uppy, { debugLogger } from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import XHRUpload from '@uppy/xhr-upload'
import RemoteSources from '@uppy/google-drive'
//import ImageEditor from '@uppy/image-editor'
import Form from '@uppy/form'
//import Webcam from '@uppy/webcam'
//import Audio from '@uppy/audio'
//import ScreenCapture from '@uppy/screen-capture'
//import Tus from '@uppy/tus'
import DropTarget from '@uppy/drop-target'
//import Compressor from '@uppy/compressor'
import { Buffer } from "buffer"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
//import "@uppy/audio/dist/style.css"
//import "@uppy/screen-capture/dist/style.css"
//import "@uppy/image-editor/dist/style.css"



const UppyButton = ({ children, inMemoryFileUpload=false, onFileComplete, disabled=false }) => {
 
/*
	restrictions: {
		maxFileSize: null,
		minFileSize: null,
		maxTotalFileSize: null,
		maxNumberOfFiles: null,
		minNumberOfFiles: null,
		allowedFileTypes: null,
		requiredMetaFields: [],
	},
*/

	const COMPANION_URL = "http://companion.uppy.io"
	const COMPANION_ALLOWED_HOSTS = ['https://localhost:3000']
//	const TUS_ENDPOINT = "https://tusd.tusdemo.net/files/";

//	const uppy = new Uppy({ logger: debugLogger, restrictions: {maxNumberOfFiles: 1} })
	const uppy = new Uppy({ restrictions: {maxNumberOfFiles: 1} })
		// The main UI that shows files, progress and holds all plugins
		.use(Dashboard, {
			target: 'body',
			inline: false,
			height: 300,
			metaFields: [
				{ id: 'name', name: 'Name', placeholder: 'file name' },
				{ id: 'caption', name: 'Caption', placeholder: 'add description' },
			],
			note: 'A place to put a note about what files can be uploaded...',
			proudlyDisplayPoweredByUppy: false,
			closeModalOnClickOutside: true,
			closeAfterFinish: true,
		})
		// All remote services like Instagram and Google Drive in one package
		.use(RemoteSources, {
		// You can manually specify `sources` here, by default all available are included. 
		// See docs: https://uppy.io/docs/remote-sources/#sources.
			companionUrl: COMPANION_URL,
			companionAllowedHosts: COMPANION_ALLOWED_HOSTS,
//			sources: ['GoogleDrive'],
		})
//		.use(Webcam, { target: Dashboard })
//		.use(Audio, { target: Dashboard, showRecordingLength: true })
//		.use(ScreenCapture, { target: Dashboard })
//		.use(Form, { target: '#upload-form' })
//		.use(ImageEditor, { target: Dashboard })
		// Allow dropping files on any element or the whole document
		.use(DropTarget, { target: document.body })
		// Optimize images
//		.use(Compressor)
		// Upload
//		.use(Tus, { endpoint: 'https://tusd.tusdemo.net/files/' })
//		.use(Webcam, { target: Dashboard })

	if (inMemoryFileUpload) {
		// do nothing here
	} else {
		uppy.use(XHRUpload, {
			headers: {
				'Authorization': `Bearer ${JSON.parse(localStorage.getItem('googleSession'))?.access_token}`,
				'Content-Security-Policy': `default-src 'self'; script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self'; base-uri 'self'; form-action 'self'`,
				'Access-Control-Allow-Origin': window.location.origin,
			},
			endpoint: `https://www.googleapis.com/upload/drive/v3/files?fields=id,name,mimeType,webViewLink,iconLink,thumbnailLink`,
			formData: true,
		})
	}
	
	uppy.on('file-added', (file) => {
		uppy.setFileMeta(file.id, {
			metadata: new Blob([JSON.stringify({
				name: file.name,
				mimeType: file.type,
			})], {type: 'application/json'}),
		})
	})

//let fileIDs=null
//	uppy.on('upload', (data) => {
//fileIDs = data.fileIDs
//console.log('here1', fileIDs)
//	})

	uppy.on('complete', result => {

//const file = uppy.getFile(fileIDs[0])

//console.log('here2a', file)
//console.log('here2b', fileIDs)
//console.log('here2', result.successful[0].data)

//const file2 = document.getElementById(fileIDs[0])?.files[0]
//console.log('here3', file2)
//const result2 = document.getElementById('result')
//const reader = new FileReader
//reader.addEventListener('load', () => {
//    result2.innerHTML = reader.result
//})
//const data = reader.readAsText(file, 'UTF-8')
//const data = reader.readAsText(result.successful[0].data, 'UTF-8')
//console.log('here4', data)
		if (result.successful && result.successful.length > 0) {
			if (inMemoryFileUpload) {
				onFileComplete(result.successful?.[0]?.data)
			} else {
				onFileComplete(result.successful?.[0]?.response?.body)
			}
		}
	})

//	uppy.on('upload-success', (file, response) => {
//	})

	uppy.on('upload-error', (file, error, response) => {
		console.log('uppy upload error', file, error, response)
	})

	uppy.on('error', (error) => {
		console.log('uppy error', error)
	})

	return (
		<Button disabled={disabled} id='uppy_button' color='primary' onClick={()=>uppy.getPlugin('Dashboard').openModal()} >{children}</Button>
	)	
}

export default UppyButton
