import React, { useState } from 'react'
import { Box, Grid, Typography, TextField, Icon, Accordion, AccordionSummary, AccordionDetails, InputLabel, Tooltip } from '@mui/material'
import { ExpandMore, ExpandLess, ChevronRight, ChevronLeft, DragIndicator, RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'
import { withStyles } from '@mui/styles'

import TaskDetail from '../components/TaskDetail'


const MuiAccordion = withStyles({
  root: {
//    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion)

const MuiAccordionSummary = withStyles({
  root: {
    paddingLeft: 0,
    marginBottom: 5,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
	margin: 4,
    '&$expanded': {
      margin: 4,
    },
  },
  expanded: {},
})(AccordionSummary)

const MuiAccordionDetails = withStyles({
  root: {
    margin: 0,
    minHeight: 40,
  },
})(AccordionDetails)

//				workflowId, workflowProperties, workflowAttachments, 

const Task = ({ appnav, me, contacts, actions, workflow, task, focusTaskId, parentTask,
				attachments, allProperties, documents, expanded=false, designTime, index, level=0,
				setFocusTaskId, addTask, deleteTask, updateTask, makeChildTask, makeParentTask,
				onParallelExecutionChange,
				setAssignees, onAssigneeDelete, onMilestoneActionAdd, onMilestoneActioneDelete,
				setAssigneesType, setAssigneesGroupProperty, retrieveContacts, setExpanded }) => {
	
//	console.log('Task')

	if (!workflow || !task)
		return null

	let taskId = task.taskId
	let workflowId = workflow.workflowId
	
	let taskTypeParent = task.type === 'parent'
	let taskTypeUser = task.type === 'user'
	let taskTypeStart = task.type === 'start'
	let taskTypeMilestone = task.type === 'milestone'
	let taskTypeFinish = task.type === 'finish'

	const [hover, setHover] = useState(false)
	const [tabnav, setTabNav] = useState(taskTypeUser ? 'tab_who' : (taskTypeStart || taskTypeMilestone || taskTypeParent) ? 'tab_what' : null)
//	const [nameAutoFocus, setNameAutoFocus] = useState(focusTaskId===task.taskId)

	// Determine feature enablement based on this users settings
	let settings = me && me && me.settings ? [...me.settings] : []

	let setting = settings.find(setting => setting.name==='settings_enable_parallel_routing')
	let enableParallelRouting = setting ? setting.value : false
	
	setting = settings.find(setting => setting.name==='settings_enable_conditional_routing')
	let enableConditionalRouting = setting ? setting.value : false

	setting = settings.find(setting => setting.name==='settings_enable_subflows')
	let enableSubflows = setting ? setting.value : false

	// Set background color based on task type
	let taskStyle = {flex: 1, boxShadow: '0 3px 6px rgba(0,0,0,0.16)'}
//	let taskStyle = {flex: 1, boxShadow: 0, padding: 2, margin: 2, borderWidth: 1, borderColor: 'black', borderStyle: 'solid'}
	
	if (taskTypeStart || taskTypeFinish)
		taskStyle = {flex: 1, backgroundColor: 'ghostwhite', boxShadow: '0 3px 6px rgba(0,0,0,0.16)' }
	else if (taskTypeParent)
		taskStyle = {flex: 1, backgroundColor: 'whitesmoke', boxShadow: '0 3px 6px rgba(0,0,0,0.16)' }

	if (task.validationErrors && task.validationErrors.filter(err => err.error).length > 0) {
		// If this task has an error then highlight it in red
		taskStyle.boxShadow = '0 2px 4px rgba(255,0,0,0.7)'
	} else if (task.validationErrors && task.validationErrors.filter(err => err.warning).length > 0) {
		// If this task has a warning then highlight it in orange
		taskStyle.boxShadow = '0 2px 4px rgba(255,165,0,0.7)'
	}
	
	let placeholder = taskTypeStart ? 'task_start_instr_placeholder' : taskTypeParent ? 'task_parent_instr_placeholder' : 'task_user_instr_placeholder'
	
	return (
		
		<Grid 
			container
			direction={'row'} 
			alignItems="center" 
			wrap="nowrap"
			onMouseMove={() => {if (!hover) setHover(true)}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>

			<Grid item>
				{/* Icon buttons for moving a task down into a subflow, or up to its parent flow */}
				
				{ designTime ?
					(hover && level === 0 && (taskTypeUser || taskTypeMilestone) && enableSubflows) ?
						<Grid container direction={'column'}>
							<Tooltip title={i18.t('task_tooltip_move_to_subflow')} ><ChevronRight style={{cursor: 'default'}} onClick={()=>{makeChildTask(workflowId, taskId)}}/></Tooltip>
						</Grid>				
					: (hover && level === 1 && (taskTypeUser || taskTypeMilestone) && enableSubflows) ?
						<Grid container direction={'column'}>
							<Tooltip title={i18.t('task_tooltip_move_to_subflow')} ><ChevronRight style={{cursor: 'default'}} onClick={()=>{makeChildTask(workflowId, taskId)}}/></Tooltip>
							<Tooltip title={i18.t('task_tooltip_move_to_parent')} ><ChevronLeft style={{cursor: 'default'}} onClick={()=>makeParentTask(workflowId, taskId)}/></Tooltip>
						</Grid>				
					: (hover && level >= 2 && (taskTypeUser || taskTypeMilestone) && enableSubflows) ?
						<Grid container direction={'column'}>
							<Tooltip title={i18.t('task_tooltip_move_to_parent')} ><ChevronLeft style={{cursor: 'default'}} onClick={()=>makeParentTask(workflowId, taskId)}/></Tooltip>
						</Grid>				
					:
						<Grid container direction={'column'}>
							<Icon/>
						</Grid>
				:
					<Grid container direction={'column'}>
						<Icon/>
					</Grid>
				}
				
			</Grid>
			
			<Grid container direction={'row'} alignItems="center" wrap="nowrap" >						

				{/* Drag icon & tooltip */}
				{ (taskTypeUser || taskTypeParent || taskTypeMilestone) && designTime ?
					hover ?
						<Tooltip title={i18.t('task_tooltip_drag_task')} >
							<DragIndicator style={{cursor: 'grab', alignSelf: 'center'}} />
						</Tooltip>
					: 
						<Icon/>
				:
					null
				}
									
				<Grid item xs={12} >

					<MuiAccordion 
						style={taskStyle} 
						expanded={expanded}
						onClick={()=>setExpanded(task.taskId, !expanded)}
					>

						<MuiAccordionSummary 
							style={{backgroundColor:'transparent'}}
							onClick={()=>setFocusTaskId(task.taskId)} 
						>

							<Grid container direction={'column'} >
							
								<Grid container direction={'row'} >
								
									{/* Use predefined name/label for start and finish tasks */}
									{taskTypeStart ?
										<Typography style={{flex: 1, marginRight: 6, marginTop: 4, fontWeight: 'bold'}}>
											{i18.t(`task_start_label`)}
										</Typography>			
									: taskTypeFinish ?
										<Typography style={{flex: 1, marginRight: 6, marginTop: 4, fontWeight: 'bold'}}>
											{i18.t(`task_finish_label`)}
										</Typography>			
									: null }

									{/* Only parent and user steps can have their name edited */}
									{ taskTypeUser || taskTypeParent || taskTypeMilestone ?
										<TextField
											variant='standard'
											style={{flex: 1}}				
											InputLabelProps={{shrink: true}}	
											InputProps={{ disableUnderline: true }}
											placeholder={i18.t(placeholder)}
											value={task.name ? task.name : ''}
											onClick={(event)=>{event.stopPropagation()}}
											onChange={(item)=>{task.name=item.target.value;updateTask(workflowId, task)}}
											fullWidth
											disabled={!designTime}
											onKeyPress={(e) => {
												if (e.key === 'Enter' && e.shiftKey) {         
													setExpanded(task.taskId, !expanded)
												} else if (e.key === 'Enter' && !expanded) {
													let newTaskId = addTask(workflowId, task.parenttaskId, index+1)
													setFocusTaskId(newTaskId)
												}
											}}														
										/>
									: null }

									{ hover ?
										expanded ?
											<Tooltip title={i18.t('general_expand_item')} ><ExpandLess style={{cursor: 'default'}} /></Tooltip>
										:
											<Tooltip title={i18.t('general_expand_item')} ><ExpandMore style={{cursor: 'default'}} /></Tooltip>
									:
										null
									}
																					
								</Grid>
																
							</Grid>
							
						</MuiAccordionSummary>
								
						<MuiAccordionDetails style={{backgroundColor:'transparent'}} >

							<TaskDetail
								me={me}
								contacts={contacts}
								actions={actions}
								workflow={workflow}
								task={task}
								focusTaskId={focusTaskId}
								parentTask={parentTask}
								attachments={attachments}
								allProperties={allProperties}
								documents={documents}
								tabnav={tabnav}
								designTime={designTime}
								level={level}
								setFocusTaskId={setFocusTaskId}
								addTask={addTask}
								updateTask={updateTask}
								onParallelExecutionChange={onParallelExecutionChange}
								setAssignees={setAssignees}
								onAssigneeDelete={onAssigneeDelete}
								setAssigneesGroupProperty={setAssigneesGroupProperty}
								setAssigneesType={setAssigneesType}
								onMilestoneActionAdd={onMilestoneActionAdd}
								retrieveContacts={retrieveContacts}
								setTabNav={setTabNav}
							/>

						</MuiAccordionDetails>
					</MuiAccordion>	
				</Grid>

				{ designTime && hover && (taskTypeUser || taskTypeParent || taskTypeMilestone) ?
					<Grid item>
						<Tooltip title={i18.t('task_tooltip_delete_task')} >
							<RemoveCircle style={{cursor: 'default', height: 20, width: 20}} onClick={(e)=>{deleteTask(workflowId, task.taskId, index);e.stopPropagation()}}/>
						</Tooltip>
						<Icon/>
					</Grid>
				:
					designTime && (taskTypeUser || taskTypeParent || taskTypeMilestone) ?
						<Grid item>
							<Icon/>
							<Icon/>
						</Grid>
					:
						null
				}
																					
			</Grid>
		</Grid>
				
	)
}

export default Task
