import React from 'react'
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio, Typography } from '@mui/material'
import * as i18 from 'i18next'

const AssignmentRouteConditionList = ({ assignment, disabled, updateAssignment, }) => {
 
//	console.log('AssignmentRouteConditionList')

	if (!assignment.routeCondition || !assignment.routeCondition.options || assignment.routeCondition.options.length <= 1 || assignment.routeCondition.quorum === 'any+expr') return null

	let optionIndex = assignment.selectedRoute ? assignment.selectedRoute : 0

	return (
		<Grid item >
			<Typography variant='subtitle1' >{i18.t('assignment_routing_label')}</Typography>
			<FormControl disabled={disabled} >
				<RadioGroup 
					value={`` + optionIndex} 
					onChange={(event) => {
						assignment.selectedRoute = Number(event.target.value)
						updateAssignment(assignment)
					}}
				>
					{assignment.routeCondition.options.map((routeCondition, index) =>
						<FormControlLabel 
							key={index} 
							value={`` + index}
							control={<Radio style={{padding: 5}}/>} 
							label={routeCondition.name}
						/>
					)}
				</RadioGroup>
			</FormControl>
		</Grid>
	)
}

export default AssignmentRouteConditionList
