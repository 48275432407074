import { QUERY_RESULT, UPDATE_QUERY, RECEIVE_ERRORS } from './actiontypes'
import { gqlExec } from '../reducers/graphql'


export const query = (query) => async dispatch => {
	
//	console.log('query')
	
	try {		
		document.body.style.cursor = 'wait'

		const response = await gqlExec(query)		

		dispatch({type: QUERY_RESULT, result: response})

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const updateQuery = (query) => dispatch => {
	dispatch({type: UPDATE_QUERY, query: query})
}


const initialState = {
	query: 'query{workflows{items{workflowId}}}'
}

const app = (state=initialState, action) => {
	
	switch (action.type) {

// Query Events
	
		case UPDATE_QUERY:
			state = {...state, 
				query: action.query
			}
			break
			
		case QUERY_RESULT:
			state = {...state, 
				result: action.result
			}
			break
			
		default:
			break
	}

	return state
}

export default app


