import React from 'react'
import { Grid, Button, List, ListItem, Divider } from '@mui/material'
import assignmentdetails from '../testdata/assignmentdetails'
import documentdetails from '../testdata/documentdetails'
import workflowdetails from '../testdata/workflowdetails'
import workflowtasks from '../testdata/workflowtasks'
import workflowdiscussion from '../testdata/workflowdiscussion'															   
import workflowmilestones from '../testdata/workflowmilestones'
import workflowruntime from '../testdata/workflowruntime'
import { performanceCreate, performanceRun, performanceQuery, performanceDocumentCreate } from '../testdata/performance'


const testgroups = [
	assignmentdetails,
	documentdetails,
	workflowdetails,
	workflowtasks,
	workflowmilestones,
	workflowdiscussion,					
	workflowruntime,
	performanceCreate,
	performanceRun,
	performanceQuery,
	performanceDocumentCreate,
]

const TestConsole = ({ executeTests }) => {
 
//	console.log('TestConsole')
	
	return (
		<List>
			{testgroups.map((testgroup, index) => (
				<Grid item key={index}>
					<Divider/>
					{testgroup.map((testcollection, index) => (
						<ListItem key={index} >
							<Grid container direction='row' alignItems='center' >		
								{testcollection.name}
								<Button onClick={()=>executeTests(testcollection)} style={{padding: 0, color: 'green', fontWeight: 'bold'}} >Run</Button>
							</Grid>
						</ListItem>
					))}
				</Grid>
			))}
		</List>
	)
}

export default TestConsole
