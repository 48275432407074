import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'

import Form from '../components/Form'
import AssignmentRouteConditionList from '../components/AssignmentRouteConditionList'


const AssignmentDetail = ({ rootparentType='assignment', assignment, designTime, disabled, preview, updateAssignment }) => {

//	console.log('AssignmentDetail')

	if (!assignment) return null

	let height = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('assignment-toolbar')?.clientHeight ? document.getElementById('assignment-toolbar')?.clientHeight : 50
	const tabbarHeight = document.getElementById('tabbar')?.clientHeight ? document.getElementById('tabbar')?.clientHeight : 50

	// This is to make regular assignments and assignment preview windows size correctly - TBD: is there a better way???
//	if (preview) width = document.getElementById('assignment')?.clientWidth - 10

	height = height - (toolbarHeight + tabbarHeight)

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item>		{/* needed for the assignment toolbar to paint correctly */}
			<Grid item id='AssignmentDetail' style={{width: width, height: height, position: 'absolute', overflow: 'auto'}} >

				&nbsp;

				<Form 
					workflowId={assignment.workflowId}
					rootparentType={rootparentType}
					rootparentId={assignment.assignmentId}
					form={assignment.form}
					parentId={assignment.assignmentId}
					designTime={designTime}
					disabled={disabled}
				/>

				<Grid item style={{border: '30px solid transparent'}} >
					<AssignmentRouteConditionList 
						assignment={assignment}
						disabled={disabled}
						updateAssignment={updateAssignment}
						designTime={designTime}
					/>
					
					&nbsp;

					{assignment.state === 'completed' ?
						<Typography variant='subtitle1' >{i18.t('assignment_completed_label')}</Typography>
					: assignment.state === 'canceled' ?
						<Typography variant='subtitle1' >{i18.t('assignment_canceled_label')}</Typography>
					: null }
				</Grid>
				
			</Grid>
		</Grid>
	)
}

export default AssignmentDetail
