import React, { useState } from 'react'
import { Grid, Box, Typography, Avatar, Button } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import { jwtDecode } from 'jwt-decode'


const MemberList = ({ me, members, designTime, onDelete }) => {

//	console.log('MemberList')

	if (!members || members.length === 0) return null

	const [hover, setHover] = useState()
	
	const googleSession = JSON.parse(localStorage.getItem('googleSession'))
	const id = googleSession ? jwtDecode (googleSession.id_token) : null
	const you = id ? id.sub : null
	
	return (
		<Grid 
			container 
			direction={'column'} 
			onMouseMove={() => {if (!hover) setHover(true)}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{members.map((member, index) => (
				<Button key={index}>
					<Grid container direction={'row'} alignItems='center' >
						<Avatar src={member ? member.thumbnailPhotoUrl : null} style={{width: 30, height: 30}} />
						
						&nbsp;&nbsp;
						
						<Typography style={{fontWeight: 'bold'}} >
							{member ? member.fullName : <br/>}
							{member && you && you === member.orgentityId ? ' (you)' : null}
						</Typography>
						
						<Typography>
							{member ? member.email : null}
						</Typography>
						
						<Grid item style={{flex: 1}}/>
						
						{ designTime && hover ?
							<RemoveCircle style={{cursor: 'default', height: 20, width: 20}} color="primary" onClick={()=>onDelete(index)} />
						: null }
					</Grid>
				</Button>
			))}
		</Grid>
	)
}

export default MemberList