import React, { useState, useRef } from 'react'


String.prototype.hashCode = function() {
	var hash = 0, i, chr
	if (this.length === 0) return hash
	for (i = 0; i < this.length; i++) {
		chr = this.charCodeAt(i)
		hash = ((hash << 5) - hash) + chr
		hash |= 0 // Convert to 32bit integer
	}
	return hash
}

export const generateHashCode = (obj) => {
//console.log(JSON.stringify(Object.keys(obj).sort().reduce((accumulator, key) => {accumulator[key] = obj[key] return accumulator}, {})))
	return JSON.stringify(Object.keys(obj)
		.sort()
		.reduce((accumulator, key) => {
			accumulator[key] = obj[key]
			return accumulator
		}, {}))
		.hashCode()
}

/*
export const enablePeriodicSave = (save) => {

	const [saveCount, setSaveCount] = useState(0)

	let timeoutId

	const ref = useRef(false)
	useEffect(() => {
		ref.current = true
		return () => {
			ref.current = false
		}
	}, [])

	const periodicSave = () => {
		clearTimeout(timeoutId)
		save()
		if (ref.current) {
			timeoutId = setTimeout(periodicSave, 15000)
		}
	}

	const periodicSaveSetup = () => {
		clearTimeout(timeoutId)
		timeoutId = setTimeout(periodicSave, 15000)
	}

	if (saveCount === 0) {
		periodicSaveSetup()
		setSaveCount(saveCount + 1)
	}
}
*/


