import React, { useState } from 'react'
import { Grid, TextField, Button, Typography, Tooltip, Accordion, AccordionSummary, AccordionDetails, List, Divider, Icon } from '@mui/material'
import { AspectRatio, AspectRatioTwoTone, ContentCopy, ExpandMore, ExpandLess } from '@mui/icons-material'
import * as i18 from 'i18next'

import ExtractionRuleDetail from '../../components/ExtractionRuleDetail'
import ExtractionRuleList from '../../components/ExtractionRuleList'


const CompositeExtractionRule = ({ rootparentId, property, properties, selectedProperty, highlightedRule, hover, avgwordheight, designTime,
								setSelectedProperty, setHighlightedRule, setSelectionAttribute, setUpdateOverlays,
								updateProperty, deleteProperty, resetCompositeProperty, deleteCompositeProperty,
								absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log("CompositeExtractionRule", property.name)

	if (!property || !property.extractionRule) return null

	let propertyId = property.propertyId

	const [expanded, setExpanded] = useState(false)
	const [updated, setUpdated] = useState()
	const [showSizing, setShowSizing] = useState()
	const [refCopied, setRefCopied] = useState()
	
	let propertyRefName = property.pname
	let parentpropertyId = property.parentpropertyId
	while (parentpropertyId) {
		propertyRefName = properties[parentpropertyId].pname + '.' + propertyRefName
		parentpropertyId = properties[parentpropertyId].parentpropertyId
	}

	const key = property.extractionRule.key
	const value = property.extractionRule.value
	
	return (		
		<Accordion 
			style={{boxShadow: 'none', backgroundColor: 'transparent'}} 
			expanded={expanded}
			onClick={()=>{
				if (selectedProperty === propertyId) {
					setExpanded(!expanded)
				} else {
					setExpanded(true)
				}
			}} 
		>
			<AccordionSummary style={{padding: 0, backgroundColor: 'transparent'}} expandIcon={<Tooltip title={i18.t('property_expand_tooltip')}>{hover ? <ExpandMore/> : <Icon/>}</Tooltip>}>
				<Grid container direction='column'>
				
					<Grid container direction='row' alignItems='center' >
						{/* Property name */}
						<TextField
							variant='standard'
							placeholder={i18.t('property_name_placeholder')}
							value={property.name ? property.name : ''}
							onChange={(event)=>{
								property.name = event.target.value
								property.pname = property.name.toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
								updateProperty(property)
							}}
							disabled={!designTime}
							onClick={(e)=>{if (designTime) {setSelectedProperty(propertyId);setSelectionAttribute(null);e.stopPropagation()}}}
							required={true}
							InputProps={{readOnly: !designTime}}										
							style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
						/>
					</Grid>
					
					<Grid container direction='row' alignItems='center' >

						{/* Key */}
						<Grid item xs={6} >
							<Grid container direction='row' alignItems='center' >
								<Button 
									size="small"
									color="primary"
									onClick={(e)=>{
										if (designTime) {
											property.extractionRule.key = null
											updateProperty(property)
											setSelectionAttribute('key')
											setSelectedProperty(propertyId)
										}
										e.stopPropagation()
									}}
								>
									{key && key.rect ? i18.t('extraction_key_reset_label') : i18.t('extraction_key_select_label')}
								</Button>

								<Typography>{key && key.words ? key.words.join(' ') : i18.t('extraction_key_placeholder')}</Typography>
							</Grid>
						</Grid>

						{/* Region */}
						<Grid item xs={6} >
							<Grid item>
								<Button 
									size="small"
									style={{color: 'green'}}
									onClick={(e)=>{
										if (designTime) {
											resetCompositeProperty(rootparentId, property)
											setSelectionAttribute('value')
											setSelectedProperty(propertyId)
										}
										e.stopPropagation()
										setExpanded(true)
									}}
								>
									{value ? i18.t('extraction_composite_reset_label') : i18.t('extraction_composite_select_region_label')}
								</Button>
							</Grid>
						</Grid>
						
					</Grid>					
				</Grid>
			</AccordionSummary>

			<AccordionDetails style={{padding: 0}}>	
				<Grid container direction='column' >
				
					<Grid style={{paddingLeft: 10}} container direction='row' alignItems='flex-end' >

						<Grid item>
							<Grid container direction='column' >

								{/* Property resource name (prn) */}
								<Grid container direction='row' >
									<Typography noWrap onClick={(e)=>e.stopPropagation()} >{propertyRefName}</Typography>
									<Tooltip title={refCopied ? i18.t('tooltip_prn_copied') : i18.t('tooltip_prn_copy')} >
										<ContentCopy fontSize="small" style={{marginLeft: 5}} onClick={(e)=>{e.stopPropagation();navigator.clipboard.writeText(propertyRefName);setRefCopied(true);setTimeout(()=>setRefCopied(false), 2000)}}/>
									</Tooltip>
								</Grid>
								
							</Grid>
						</Grid>

						<Grid item style={{flex: 1}} />

						{ showSizing ?
							<AspectRatioTwoTone onClick={(e)=>{e.stopPropagation();setShowSizing(false)}} style={{cursor: 'default'}} color='primary' />
						:
							<AspectRatio onClick={(e)=>{e.stopPropagation();setShowSizing(true)}} style={{cursor: 'default'}} color='primary' />
						}
							
					</Grid>
					
					{ showSizing ?
						<Grid item style={{flex: 1}} >
							<Divider/>

							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >Key</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='key'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>

							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >Region</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='region'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>
				
							<Divider/>
						</Grid>
					: null }
							
					<Grid item style={{marginLeft: 20, flex: 1}} onClick={(e)=>e.stopPropagation()} >
						{ property.properties ?
							<ExtractionRuleList
								rootparentId={rootparentId}
								parentType={'compositeproperty'} 
								parentId={property.propertyId} 
								properties={property.properties}
								selectedProperty={selectedProperty}
								highlightedRule={highlightedRule}
								avgwordheight={avgwordheight}
								designTime={designTime}

								setSelectedProperty={setSelectedProperty}
								setHighlightedRule={setHighlightedRule}
								setSelectionAttribute={setSelectionAttribute}
								setUpdateOverlays={setUpdateOverlays}
								deleteProperty={(rootparentId, propertyId) => {deleteCompositeProperty(rootparentId, property.propertyId, propertyId)}}
								absoluteToProportionalRect={absoluteToProportionalRect}
								proportionalToAbsoluteRect={proportionalToAbsoluteRect}
							/>
						: null }
					</Grid>
						
				</Grid>					
			</AccordionDetails>
		</Accordion>
	)
}

export default CompositeExtractionRule