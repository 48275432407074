import React, { useState } from 'react'
import { Grid, Typography, Tooltip, Button } from '@mui/material'
import { ArrowBack, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import VirtualList from './VirtualList'
import DocumentListItem from './DocumentListItem'
import TagFilterBar from './TagFilterBar'
import UppyButton from './UppyButton'


const DocumentList = ({ navigation, xs, tagFilter, app, documents, screen, createDocument, putContent, retrieveDocuments }) => {

//	console.log('DocumentList')

	const onFileComplete = async (file) => {
		if (file) {
			const doc2 = await createDocument({name: file.name, state: 'working'})
			await putContent(doc2.documentId, file)
		}
	}

	let list = []
	let listCount = 0
	let retrieve = ()=>{}
	if (screen === 'document_template_list') {
		list = app.templateDocumentsList
		listCount = app.templateDocumentsCount ? app.templateDocumentsCount : 0
		retrieve = (skip) => retrieveDocuments('templateDocuments', skip, tagFilter)
	} else if (screen === 'document_working_list') {
		list = app.workingDocumentsList
		listCount = app.workingDocumentsCount ? app.workingDocumentsCount : 0
		retrieve = (skip) => retrieveDocuments('workingDocuments', skip, tagFilter)
	}
	
	return (
		<Grid item id='list' >

			<Grid container id='buttonbar' direction={'row'} alignItems='center' >

				<Button disabled={!xs} color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>

				{ screen === 'document_template_list' ?
					<Tooltip title={i18.t('general_refresh_list_tooltip')} >
						<Button 
							color="primary" 
							onClick={async()=>{await createDocument(navigation, {state: 'template'})}} 
						>
							{i18.t('general_new')}
						</Button>					
					</Tooltip>
				: null }
				
				{ screen === 'document_working_list' ?
					<UppyButton inMemoryFileUpload={true} onFileComplete={onFileComplete}>{i18.t('general_new')}</UppyButton>
				: null }
				
				{ tagFilter && tagFilter.length > 0 ?
					<TagFilterBar tagFilter={tagFilter} />
				: null }
								
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={()=>retrieve(0)} ><Refresh/></Button>					
				</Tooltip>
				
			</Grid>

			{ listCount === 0 ?
				<Typography style={{padding: 20, color: 'grey'}}>{i18.t('general_no_items_to_display')}</Typography>
			: null }

			<VirtualList objList={documents} idList={list} listCount={listCount} retrieve={retrieve} >
				{(childProps) => <DocumentListItem navigation={navigation} doc={childProps.item} documents={documents} />}
			</VirtualList>
			
		</Grid>					
	)
}

export default DocumentList
