import React, { useState } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { Grid, Button, Typography, Divider, TextField } from '@mui/material'
import UUID from 'uuidjs'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

import ImagePane from '../components/ImagePane'
import ExtractionRuleList from '../components/ExtractionRuleList'
import TagBar from '../components/TagBar'


const ExtractDetail = ({ rootparentId, parentType, parentId, parent, properties, memberProperties, tags, doc, designTime, disabled,
						updateParent, addProperty, deleteProperty, 
						updateProperty, moveToCompositeProperty }) => {
 
//	console.log('ExtractDetail', extract, properties)

	if (!rootparentId || !parentType || !parentId || !parent) return null

	if (!parent.properties) parent.properties = []

	const [zoom, setZoom] = useState(1)
	const [selectionAttribute, setSelectionAttribute] = useState()
	const [selectedProperty, setSelectedProperty] = useState()
	const [highlightedRule, setHighlightedRule] = useState()
	const [updateOverlays, setUpdateOverlays] = useState()
	const [width, setWidth] = useState(document.getElementById('imagepane')?.clientWidth)
	const [height, setHeight] = useState(document.getElementById('imagepane')?.clientHeight)
	const [pageNumber, setPageNumber] = useState(1)
	const [maxPages, setMaxPages] = useState(1)

	let attribute
	let wblocks = []
	let avgwordheight = 0.05

	const page = {
		width: doc?.content?.dimensions ? doc.content.dimensions.width : document.getElementById('imagepane')?.clientWidth,
		height: doc?.content?.dimensions ? doc.content.dimensions.height : document.getElementById('imagepane')?.clientHeight
	}

	if (parent.extractdata) {
		wblocks = parent.extractdata.Blocks.filter(block => block.BlockType === 'WORD' && (block.Page ? block.Page === pageNumber : true))
		let sumheight = 0
		wblocks.forEach(block => {
			let rect = {top: block.Geometry.BoundingBox.Top, left: block.Geometry.BoundingBox.Left, height: block.Geometry.BoundingBox.Height, width: block.Geometry.BoundingBox.Width}
			sumheight += Number(block.Geometry.BoundingBox.Height)
		})
		avgwordheight = sumheight ? ((sumheight / wblocks.length) * 1.4) : .02
	}

	const setSelectedProperty2 = (value) => {setSelectedProperty(value);setHighlightedRule(null)}
	
	const addKVPRule = () => {
		let keys = Object.keys(memberProperties)
		keys.forEach(key => {
			if (memberProperties[key].subType === 'string') {
				let property = cloneDeep(memberProperties[key])
				property.propertyId = UUID.genV4().toString()
				property.extractionRule = {type: 'kvp', page: pageNumber}
				addProperty(parentId, property)
				setSelectedProperty2(property.propertyId)
			}
		})
	}

	const addTableRule = () => {
		let keys = Object.keys(memberProperties)
		for (const key of keys) {
			if (memberProperties[key].subType === 'table') {
				let property = cloneDeep(memberProperties[key])
				property.propertyId = UUID.genV4().toString()
				property.extractionRule = {type: 'vtable', page: pageNumber}
				addProperty(parentId, property)
				setSelectedProperty2(property.propertyId)
			}
		}
	}

	const addCompositeRule = () => {
		let keys = Object.keys(memberProperties)
		for (const key of keys) {
			if (memberProperties[key].subType === 'object') {
				let property = cloneDeep(memberProperties[key])
				property.propertyId = UUID.genV4().toString()
				property.extractionRule = {type: 'object', page: pageNumber}
				addProperty(parentId, property)
				setSelectedProperty2(property.propertyId)
			}
		}
	}
	
	const absoluteToProportionalRect = (rect) => {

		if (!rect) return {}

		let newRect = {
			top: (rect.top / zoom / page.height),
			left: (rect.left / zoom / page.width),
			height: (rect.height / zoom / page.height),
			width: (rect.width / zoom / page.width),
		}

		return newRect
	}

	const proportionalToAbsoluteRect = (rect) => {

		if (!rect) return {}

		let newRect = {
			top: (rect.top * page.height * zoom),
			left: (rect.left * page.width * zoom),
			height: (rect.height * page.height * zoom),
			width: (rect.width * page.width * zoom),
		}

		newRect.bottom = Math.round(newRect.top + newRect.height)
		newRect.right = Math.round(newRect.left + newRect.width)

		return newRect
	}

	let height2 = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width2 = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 50
	const tabbarHeight = document.getElementById('tabbar')?.clientHeight ? document.getElementById('tabbar')?.clientHeight : 50

	height2 = height2 - (toolbarHeight + tabbarHeight)

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='extract-detail' style={{width: width2, height: height2, position: 'absolute'}} >
		
			<Grid container direction={'row'} style={{height: height2}} >
				
				<Grid item id='details' xs={4} lg={5} style={{height: `100%`, overflowX: 'auto'}}>

					<Grid container direction={'column'} >

						<br/>
						
						{/* If there are any validation errors - display them here. */}
						{(parent.validationErrors && parent.validationErrors.length > 0) ?
							parent.validationErrors.map((err, index) => {
								if (err.warning)
									return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
											
								if (err.error)
									return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
							})
						: null }

						{/* name */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							autoFocus={true}
							label={i18.t('extraction_name')}
							placeholder={i18.t('extraction_name_placeholder')}
							value={parent.name ? parent.name : ''}
							onChange={(event)=>{parent.name=event.target.value;updateParent(parent)}}
						/>
						
						<br/>

						{/* description */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_description')}
							placeholder={i18.t('workflow_desc_placeholder')}
							value={parent.description ? parent.description : ''}
							onChange={(event)=>{parent.description=event.target.value;updateParent(parent)}}
						/>
						
						<br/>

						{/* updated */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_updated')}
							value={parent.updated ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(parent.updated)) : ''}
							InputProps={{readOnly: true}}										
						/>
						
						<br/>
												
						{/* tags */}
						<Grid container direction='row' alignItems='center'>
							<Typography variant='subtitle2' style={{color: 'grey'}}>{i18.t('extraction_tags')}</Typography>
							<TagBar 
								tagList={parent.tags} 
								selectedTags={parent.tags} 
								allTags={tags} 
								editMode={true} 
								onUpdate={(selectedTags)=>{parent.tags=selectedTags;updateParent(parent)}}
							/>
						</Grid>
						
						<br/>

						{/* property label / toolbar */}
						<Grid container direction='row' alignItems='center'>
							<Typography variant='subtitle2' style={{color: 'grey', paddingRight: 20}}>{i18.t('extraction_properties')}</Typography>
							{ designTime ?
								<Button style={{fontSize: '12px'}} color="primary" onClick={addKVPRule} >{i18.t('dialog_add_kvp_extraction_rule')}</Button>
							: null }
							{ designTime ?
								<Button style={{fontSize: '12px'}} color="primary" onClick={addTableRule}>{i18.t('dialog_add_table_extraction_rule')}</Button>
							: null }
							{ designTime ?
								<Button style={{fontSize: '12px'}} color="primary" onClick={addCompositeRule} >{i18.t('dialog_add_composite_extraction_rule')}</Button>						
							: null }
						</Grid>
						
						{/* properties / extraction rules */}
						{(!parent.properties || parent.properties.length===0)?
							<Typography>{i18.t('task_no_properties_to_display')}</Typography>
						:
							<ExtractionRuleList
								rootparentId={rootparentId}
								parentType={'extract'}
								parentId={parentId}
								properties={parent.properties.filter(propId => properties?.[propId]?.extractionRule?.page === pageNumber || properties?.[propId]?.extractionRule?.value?.page === pageNumber)}
								selectedProperty={selectedProperty}
								highlightedRule={highlightedRule}
								avgwordheight={avgwordheight}
								designTime={designTime}

								setSelectedProperty={setSelectedProperty2}
								setHighlightedRule={setHighlightedRule}
								setSelectionAttribute={setSelectionAttribute}
								deleteProperty={deleteProperty}
								setUpdateOverlays={setUpdateOverlays}
								absoluteToProportionalRect={absoluteToProportionalRect}
								proportionalToAbsoluteRect={proportionalToAbsoluteRect}
							/>
						}
						
					</Grid>
				</Grid>
				
				<Grid item id='image_pane2' xs={8} lg={7} >

						<Grid container direction='row' alignItems='center'>

							{/* set zoom */}
							<Grid container direction='row' style={{flex: 1}} alignItems='center' justifyContent='center' >
								<Typography>{i18.t('extraction_zoom')}</Typography>
								<Button size='small' style={{padding: 0}} color="primary" onClick={()=>setZoom(Math.max(zoom-.1, .1))} >{i18.t('extraction_zoom_in')}</Button>
								<Typography>{`${Math.round(zoom*100)}%`}</Typography>
								<Button size='small' style={{padding: 0}} color="primary" onClick={()=>setZoom(Math.min(zoom+.1, 5))} >{i18.t('extraction_zoom_out')}</Button>
							</Grid>
							
							{/* select page */}
							{ maxPages > 1 ?
								<Grid container direction='row' style={{flex: 1}} alignItems='center' justifyContent='center' >
									<Typography>{i18.t('extraction_page')}</Typography>
									<Button size='small' style={{padding: 0}} color="primary" disabled={pageNumber<=1} onClick={()=>{setPageNumber(Math.max(1, pageNumber-1))}} >{i18.t('extraction_page_prev')}</Button>
									<Typography>{pageNumber}</Typography>
									<Button size='small' style={{padding: 0}} color="primary" disabled={pageNumber>=maxPages} onClick={()=>{setPageNumber(Math.min(maxPages, pageNumber+1))}} >{i18.t('extraction_page_next')}</Button>
								</Grid>
							: null }
							
						</Grid>

						{/* content view */}
						{ doc?.content?.data ?
							<Grid item id='imagepaneP1' style={{width: document.getElementById('image_pane2')?.clientWidth, height: 'calc(100vh - 200px)', position: 'absolute', overflow: 'auto', cursor: selectionAttribute && designTime ? 'crosshair' : 'default' }} >	
								<ImagePane
									content={doc?.content}
									page={page}
									pageNumber={pageNumber}
									zoom={zoom}
									wblocks={wblocks}
									properties={properties}
									selectionAttribute={selectionAttribute}
									selectedProperty={selectedProperty}
									highlightedRule={highlightedRule}
									avgwordheight={avgwordheight}
									designTime={designTime}
									setMaxPages={setMaxPages}
									setWidth={setWidth}
									setHeight={setHeight}
									setSelectedProperty={setSelectedProperty}
									setHighlightedRule={setHighlightedRule}
									setSelectionAttribute={setSelectionAttribute}
									updateProperty={(property)=>updateProperty(rootparentId, property)}
									moveToCompositeProperty={(compositePropertyId, propertyId)=>moveToCompositeProperty(rootparentId, compositePropertyId, propertyId)}
								/>
							</Grid>
					:
						<Typography style={{paddingLeft: 30}} >No associated content.</Typography>
					}
					
				</Grid>
				
			</Grid>
		</Grid>
	)
}

export default ExtractDetail

