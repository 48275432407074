import React, { useState } from 'react'
import { Grid, TextField, Tooltip, Icon } from '@mui/material'
import { AddCircle, RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'


// Design time view of component
const PropertySelectMultiEdit = ({ nameTypeComponent, classes, property, updateProperty, designTime, disabled }) => {

//console.log('PropertySelectMultiEdit')

	if (!property || !property.items ) return null
		
	if (!property.items.enum || property.items.enum.length === 0)
		property.items.enum = ['Option 1']

	const [hover, setHover] = useState(false)

	return (
		<Grid container direction={'row'} alignItems='center'>

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
			
			<Grid item>
				<Grid container direction={'column'} >
					{property.items.enum.map((option, optionIndex) => 
						<Grid item key={optionIndex}>
							<Grid 
								container direction={'row'} 
								alignItems="center" 
								onMouseMove={() => setHover(optionIndex)}
								onMouseEnter={() => {setHover(optionIndex)}}
								onMouseLeave={() => setHover(null)}
							>
							
								<TextField
									variant='standard'
									disabled={disabled}
									margin="dense"
									id="propertyoption"
									InputLabelProps={{shrink: true}}				
									placeholder={designTime ? i18.t('property_option_placeholder') : null}
									value={option ? option : ''}
									onChange={(event)=>{property.items.enum[optionIndex]=event.target.value;updateProperty(property)}}
									/>

								{ designTime && (hover === optionIndex) && (property.items.enum.length > 1) ?
									<Tooltip title={i18.t('property_tooltip_delete_property_option')} >
										<RemoveCircle
											style={{cursor: 'default', height: 20, width: 20}}
											color={disabled ? "disabled" : "primary"}
											onClick={()=>{
												property.items.enum.splice(optionIndex, 1)
												updateProperty(property)
											}} 
											disabled={disabled} 
										/>
									</Tooltip>
								: null }
								
								{ designTime && (hover === optionIndex) && (property.items.enum.length-1 === optionIndex) ?					
									<Tooltip title={i18.t('property_tooltip_add_property_option')} >
										<AddCircle 
											style={{cursor: 'default', height: 20, width: 20}}
											color={disabled ? "disabled" : "primary"}
											onClick={disabled ? null : ()=>{
												property.items.enum.push(i18.t('property_option_prefix') + (property.items.enum.length + 1))
												updateProperty(property)
											}} 
											disabled={disabled} 
										/>
									</Tooltip>
								: null }

							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			
		</Grid>
	)
}

export default (PropertySelectMultiEdit)
