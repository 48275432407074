import React, { useState } from 'react'
import { Grid, Button, Tooltip, Hidden } from '@mui/material'
import { Save, ArrowBack, AssignmentTurnedIn, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import ActionButton from '../components/ActionButton'


const AssignmentToolBar = ({ navigation, assignment, properties, onSave, onComplete, retrieveAssignment}) => {

//	console.log('AssignmentToolBar')

	const [isSaving, setIsSaving] = useState(false)
	const [isCompleting, setIsCompleting] = useState(false)

	return (
		<Grid item id='assignment-toolbar' >
			<Grid container direction="row" alignItems='center' >

				<Tooltip title={i18.t('general_back_tooltip')} >
					<Button color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				</Tooltip>

				{ assignment.state === 'working' ?
					<ActionButton tooltipText={i18.t('assignment_save_tooltip')} mdUp={i18.t('assignment_save')} mdDown={<Save/>} inProgress={isSaving} onClick={async ()=>{await onSave(assignment.assignmentId, setIsSaving)}} />
				: null }

				{ assignment.state === 'working' ?
					<ActionButton tooltipText={i18.t('assignment_complete_tooltip')} mdUp={i18.t('assignment_complete')} mdDown={<AssignmentTurnedIn/>} inProgress={isCompleting} onClick={async ()=>{await onComplete(assignment.assignmentId, assignment.selectedRoute, setIsCompleting);navigation.reset('assignment_working_list')}} />
				: null }

				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_item_tooltip')} >
					<Button color="primary" onClick={async ()=>{await retrieveAssignment(assignment.assignmentId)}} >
						<Refresh/>
					</Button>
				</Tooltip>
			
			</Grid>
		</Grid>
	)
}

export default AssignmentToolBar
