import React, { useState } from 'react'
import { Grid, Typography, TextField, Tooltip } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import * as i18 from 'i18next'

import DocumentAttachment from '../components/attachmenttypes/DocumentAttachment'
import DocumentAttachmentEdit from '../components/attachmenttypes/DocumentAttachmentEdit'


const Attachment = ({ navigation, workflowId, rootparentType, rootparentId, rootparentState, attachment, docs, extractsTagList, slimStyle, designTime, disabled, 
						updateAttachment, retrieveDocument, createDocument, createDocumentInstance, updateDocument, saveDocument, 
						retrieveContent, putContent, analyzeDocument, createExtract, deleteProperty }) => {

//	console.log('Attachment', attachment)

	if (!attachment) return null

	const defaultDoc = docs[attachment.defaultDocumentId]
	const doc = docs[attachment.documentId]

	const [hover, setHover] = useState()
	const [refCopied, setRefCopied] = useState()

	// Set background color based on attachment type
	let attachmentStyle = slimStyle ? {} : {flex: 1, boxShadow: '0 3px 6px rgba(0,0,0,0.16)', padding: 5}

	if (attachment.validationErrors && attachment.validationErrors.filter(err => err.error).length > 0) {
		// If this task has an error then highlight it in red
		attachmentStyle.boxShadow = '0 2px 4px rgba(255,0,0,0.7)'
	} else if (attachment.validationErrors && attachment.validationErrors.filter(err => err.warning).length > 0) {
		// If this task has a warning then highlight it in orange
		attachmentStyle.boxShadow = '0 2px 4px rgba(255,165,0,0.7)'
	}

	return (
		<Grid
			item
			style={{backgroundColor: hover ? 'ghostwhite' : 'transparent', ...attachmentStyle}}
			onMouseMove={() => {if (!hover) setHover(true)}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>						

				<Grid container direction={'row'} >

					<Grid item xs={5} md={4} xl={3}>
						<Grid item style={{paddingLeft: 5, paddingRight: 5}}>
							{ designTime ?
								<Grid container direction='column'>
									{/* Property name */}
									<TextField
										variant='standard'
										id={attachment.attachmentId}
										placeholder={i18.t('attachment_name_placeholder')}
										value={attachment.name ? attachment.name : ''}
										onChange={(event)=>{
											attachment.name = event.target.value
											if (attachment.name) {
												attachment.pname = attachment.name.toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
											}
											updateAttachment(rootparentId, attachment)
											event.stopPropagation()
										}}
										onClick={(event)=>{event.stopPropagation()}}
										required={true}
									/>

									{/* Property resource name (prn) */}
									<Grid container direction='row'>
										<Typography style={{overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={(e)=>e.stopPropagation()} >{attachment.pname}</Typography>
										&nbsp;&nbsp;
										<Tooltip title={refCopied ? i18.t('tooltip_prn_copied') : i18.t('tooltip_prn_copy')} >
											<ContentCopy fontSize="small"  onClick={(e)=>{e.stopPropagation();navigator.clipboard.writeText(attachment.pname);setRefCopied(true);setTimeout(()=>setRefCopied(false), 2000)}}/>
										</Tooltip>
									</Grid>
								</Grid>
							:
								<Typography style={{paddingRight: 10, color: attachment.name ? 'default' : 'lightgrey' }} >{attachment.name ? attachment.name : i18.t('property_name_placeholder')}</Typography>
							}
						</Grid>
					</Grid>
					
					{ designTime ?
						<DocumentAttachmentEdit
							navigation={navigation}
							workflowId={workflowId}
							rootparentType={rootparentType} 
							rootparentId={rootparentId} 
							rootparentState={rootparentState}
							attachment={attachment} 
							doc={defaultDoc}
							extractsTagList={extractsTagList}
							hover={hover}
							designTime={designTime}
							disabled={disabled}
							updateAttachment={(attachment)=>updateAttachment(rootparentId, attachment)}
							retrieveDocument={retrieveDocument}
							createDocument={createDocument}
							createDocumentInstance={createDocumentInstance}
							updateDocument={updateDocument}
							retrieveContent={retrieveContent}
							putContent={putContent}
							analyzeDocument={analyzeDocument}
							deleteProperty={(propertyId)=>{deleteProperty(doc.documentId, propertyId)}}
						/>
					:
						<DocumentAttachment
							navigation={navigation}
							workflowId={workflowId}
							rootparentType={rootparentType} 
							rootparentId={rootparentId} 
							rootparentState={rootparentState}
							attachment={attachment} 
							doc={doc}
							defaultDoc={defaultDoc}
							hover={hover}
							designTime={designTime}
							disabled={disabled}
							updateAttachment={(attachment)=>updateAttachment(rootparentId, attachment)}
							retrieveDocument={retrieveDocument}
							createDocument={createDocument}
							createDocumentInstance={createDocumentInstance}
							updateDocument={updateDocument}
							retrieveContent={retrieveContent}
							putContent={putContent}
							analyzeDocument={analyzeDocument}
							deleteProperty={(propertyId)=>{deleteProperty(doc.documentId, propertyId)}}
						/>
					}

				</Grid>
				
				{/* If there are any validation errors - display them here. */}
				{(attachment.validationErrors && attachment.validationErrors.length > 0) ?
					attachment.validationErrors.map((err, index) => {
						if (err.warning)
							return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
									
						if (err.error)
							return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
					})
				: null }

			</Grid>
	)
}

export default Attachment
