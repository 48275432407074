import React from 'react'
import { Grid, Switch, Typography } from '@mui/material'


const PropertyBoolean = ({ nameTypeComponent, property, updateProperty, designTime, disabled }) => {

//	console.log('PropertyBoolean')
	
	if (!property) return null
	
	let value
	if (designTime) {
		value = property.default ? property.default : false
	} else {
		value = property.value ? property.value : false
	}
	
	const onChange = (event) => {
		designTime ?
			property.default = event.target.checked
		:
			property.value = event.target.checked
		updateProperty(property)
	}
	
	return (
		<Grid container direction={'row'} alignItems={'center'} >

			{/* Property name / type */}
			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>

			<Typography>False</Typography>
			<Switch checked={value} onChange={onChange} />
			<Typography>True</Typography>
		
		</Grid>
	)
}

export default (PropertyBoolean)
