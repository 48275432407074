import React, { useState } from 'react'
import { Grid, Typography, MenuItem, Button, Select } from '@mui/material'
import * as i18 from 'i18next'

import PermissionList from '../components/PermissionList'
import DelegatedPermissionList from '../components/DelegatedPermissionList'
import MemberSelect from '../components/MemberSelect'


const Permissions = ({ navigate, me, contacts=[], sourceObj, roles, defaultRole, designTime, disabled, update }) => {

//	console.log('Permissions')
	
	if (!sourceObj) return null

	const [roleSelection, setRoleSelection] = useState(defaultRole?.roleId)
	
	let roleList = []
	roles && roles.forEach(role =>
		roleList.push({
			value: role.roleId,
			label: i18.t(`role_${role.roleId}`)
		})
	)

	let filteredContacts = [...contacts]
	if (filteredContacts.findIndex(contact => contact.email === me.email) === -1) {
		filteredContacts.push(me)	
	}
	
	// Remove any contacts that already have a permission grant
	sourceObj.permissions && sourceObj.permissions.forEach(permission => {
		let index = filteredContacts ? filteredContacts.findIndex(contact => permission.grantee && permission.grantee.email === contact.email) : -1
		if (index !== -1) filteredContacts.splice(index, 1)
	})

	const selectionUpdate = (member) => {
		if (!sourceObj.permissions) sourceObj.permissions = []
		sourceObj.permissions.push({grantee: {email: member}, grant: roleSelection})
		update(sourceObj)
	}

	return (
		<Grid item>

			&nbsp;
			
			<Typography style={{fontWeight: 'bold'}}>{i18.t('permission_list_direct_permissions')}</Typography>
			
			<Grid item style={{padding: 5, backgroundColor: 'ghostwhite'}}>
				<Grid container direction={'row'} alignItems='flex-end' >

					<Grid item xs={6} md={4} style={{paddingLeft: 5}}>
						<Select
							variant='standard'
							disabled={disabled}
							fullWidth
							value={roleSelection}
							onChange={(e)=>{setRoleSelection(e.target.value)}}
						>
							{ roles.map(role =>
								<MenuItem key={role.roleId} value={role.roleId} >{i18.t(`role_${role.roleId}`)}</MenuItem>
							)}
						</Select>
					</Grid>

					<Grid item xs={6} md={4} style={{paddingLeft: 20}}>
						<MemberSelect targetList={sourceObj.permissions} contacts={filteredContacts} clearAfterSelect={true} designTime={designTime} disabled={disabled} update={selectionUpdate} />
					</Grid>
					
				</Grid>
				
				<PermissionList sourceObj={sourceObj} designTime={designTime} disabled={disabled} update={update} />
			</Grid>
			
			{ sourceObj.delegatedPermissions ?
				<Grid item>
					<br/><br/><br/>
					<Typography style={{fontWeight: 'bold'}}>{i18.t('permission_list_delegated_permissions')}</Typography>
					<Grid item style={{padding: 5, backgroundColor: 'ghostwhite'}}>
						<DelegatedPermissionList navigate={navigate} sourceObj={sourceObj} designTime={designTime} update={update} />
					</Grid>
				</Grid>
			: null }

			<br/><br/>

			<Button size="small" color="primary">
				Learn More
			</Button>
				
		</Grid>
	)
}

export default Permissions
