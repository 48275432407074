import { RECEIVE_EVENTS, RECEIVE_ERRORS } from './actiontypes'
import { normalize, schema } from 'normalizr'
import { eventFragment } from '../reducers/graphql'
import { store } from '../App'
import { gqlExec } from '../reducers/graphql'


export const eventSchema = new schema.Entity(
	'events', 
	{
	}, 
	{
		idAttribute: value => value.eventId
	}
)

export const retrieveEvents = (skip=0) => async dispatch => {
	
//	console.log('retrieveEvents');

	try {
		document.body.style.cursor = 'wait'

		const scope = store.getState().app.scope
		let input = scope ? `(scope: {orgentityId: "${scope.orgentityId}"})` : ``

		const response = await gqlExec(`query RetrieveEvents {events ${input} {...eventFields}} ${eventFragment}`)		

		let events = response ? response.events : null

		if (events) {
			const data = normalize(events, [eventSchema]);		
			
			if (data.entities.events) {
				dispatch({type: RECEIVE_EVENTS, eventlist: data.result, events: data.entities.events, skip: skip})
			}
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}



const initialState = {
}

const events = (state = initialState, action) => {
	switch (action.type) {
		
// Event Events

		case RECEIVE_EVENTS:
			state = {...state, ...action.events}
			break

		default:
			break
	}

	return state;
}

export default events