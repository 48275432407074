import React, { useState } from 'react'
import { Grid, Button, Divider, Tooltip, Typography, Hidden } from '@mui/material'
import { ArrowBack, Create, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import VirtualList from '../components/VirtualList'
import SupportCaseListItem from '../components/SupportCaseListItem'
import SupportCreateDialog from '../components/dialogs/SupportCreateDialog'
import TagFilterBar from '../components/TagFilterBar'


const SupportCaseList = ({ navigation, tagFilter, app, supportcases, screen, retrieveSupportCases, retrieveSupportCase, createSupportCase }) => {

//	console.log('SupportCaseList')

	const [showCreateSupportCreateDialog, setShowCreateSupportCreateDialog] = useState()

	let list = []
	let listCount = 0
	let retrieve = ()=>{}
	if (screen === 'supportcase_working_list') {
		list = app.openSupportCasesList
		listCount = app.openSupportCasesCount ? app.openSupportCasesCount : 0
		retrieve = (skip) => retrieveSupportCases('openSupportCases', skip, tagFilter)
	} else if (screen === 'supportcase_completed_list') {
		list = app.closedSupportCasesList
		listCount = app.closedSupportCasesCount ? app.closedSupportCasesCount : 0
		retrieve = (skip) => retrieveSupportCases('closedSupportCases', skip, tagFilter)
	}

	return (
		<Grid item style={{flex: 1}}>

			<Grid container id='buttonbar' direction={'row'} >

				<Button disabled={true} color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>

				<Tooltip title={i18.t('general_refresh_item_tooltip')} >
					<Button color="primary" onClick={()=>setShowCreateSupportCreateDialog(true)}><Hidden smUp><Create/></Hidden><Hidden smDown >{i18.t('supportcase_create')}</Hidden></Button>
				</Tooltip>

				{ tagFilter && tagFilter.length > 0 ?
					<TagFilterBar tagFilter={tagFilter} />
				: null }
				
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={()=>retrieve(0)} ><Refresh/></Button>					
				</Tooltip>
			</Grid>
						
			{ listCount === 0 ?
				<Typography style={{margin: 20, color: 'grey'}}>{i18.t('general_no_items_to_display')}</Typography>
			: null }

			<VirtualList objList={supportcases} idList={list} listCount={listCount} retrieve={retrieve} >
				{(childProps) => <SupportCaseListItem navigation={navigation} supportcase={childProps.item} retrieveSupportCase={retrieveSupportCase} />}
			</VirtualList>
			
			{ showCreateSupportCreateDialog ?
				<SupportCreateDialog 
					onSubmit={(severity, summary, detail)=>createSupportCase(severity, summary, detail)}
					closeDialog={()=>setShowCreateSupportCreateDialog(false)} 
				/>		
			: null }
			
		</Grid>					
	)
}

export default SupportCaseList
