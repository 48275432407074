import React, { useState, useRef } from 'react'
import { Grid, TextField, Button, Typography, Tooltip, Accordion, AccordionSummary, AccordionDetails, Divider, FormControl, Select, MenuItem, Icon } from '@mui/material'
import { AspectRatio, AspectRatioTwoTone, ContentCopy, ExpandMore, ExpandLess } from '@mui/icons-material'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

import ExtractionRuleDetail from '../ExtractionRuleDetail'
import TagBar from '../TagBar'


const KVPExtractionRule = ({ org, orgProperties, rootparentId, property, properties, selectedProperty, highlightedRule, hover, designTime, 
							setSelectedProperty, setSelectionAttribute, updateProperty, changePropertyType, setUpdateOverlays,
							absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log("KVPExtractionRule", property.name)

	if (!property || !property.extractionRule) return null

	let propertyId = property.propertyId
	
	const [expanded, setExpanded] = useState(false)
	const [updated, setUpdated] = useState()
	const [showSizing, setShowSizing] = useState()
	const [refCopied, setRefCopied] = useState()
	const [tagFilter, setTagFilter] = useState()
	
	let propertyRefName = property.pname
	let parentpropertyId = property.parentpropertyId
	while (parentpropertyId && properties[parentpropertyId]) {
		propertyRefName = properties[parentpropertyId].pname + '.' + propertyRefName
		parentpropertyId = properties[parentpropertyId].parentpropertyId
	}

	let properties2 = [...org.properties]
	if (tagFilter?.length > 0) {
		let filteredPropertyList = []
		for (const propertyId of org.properties) {
			let property = orgProperties[propertyId]
			for (const tag of tagFilter) {
				if (property.tags?.includes(tag)) {
					filteredPropertyList.push(propertyId)
				}
			}
		}
		properties2 = [...new Set(filteredPropertyList)]
	}

	let props = properties2.map(propertyId => orgProperties[propertyId])
	props = props.filter(prop => prop.enabled === true)

	let tagList = []
	for (const prop of props) {
		tagList = [...tagList, ...prop.tags]
		tagList = [...new Set(tagList)]
	}

	if (tagFilter?.length > 0) {
		props = props.filter(prop => prop?.tags?.some(tag => tagFilter.includes(tag)))
	}

	const key = property.extractionRule.key && property.extractionRule.key.words ? property.extractionRule.key.words.join(' ') : property.extractionRule.key && property.extractionRule.key.rect ? '' : i18.t('extraction_key_placeholder')
	let value
	if (designTime) {
		value = property.extractionRule.value && property.extractionRule.value.words ? property.extractionRule.value.words.join(' ') : property.extractionRule.value && property.extractionRule.value.rect ? '' : i18.t('extraction_value_placeholder')
	} else {
		value = property.value
	}

	return (		
		<Accordion 
			style={{boxShadow: 'none', backgroundColor: 'transparent'}} 
			expanded={expanded} 
			onClick={()=>{
				if (selectedProperty === propertyId) {
					setExpanded(!expanded)
				} else {
					setExpanded(true)
				}
			}} 
		>
			<AccordionSummary style={{padding: 0, backgroundColor: 'transparent'}} expandIcon={<Tooltip title={i18.t('property_expand_tooltip')}>{hover ? <ExpandMore/> : <Icon/>}</Tooltip>}>
				<Grid container direction='column'>

					<Grid container direction='row' alignItems='center' >
						{/* Property name */}
						<TextField
							variant='standard'
							placeholder={i18.t('property_name_placeholder')}
							value={property.name ? property.name : ''}
							onChange={(event)=>{
								property.name = event.target.value
								if (property.name) {
									property.pname = property.name.toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
								}
								updateProperty(property)
							}}
							disabled={!designTime}
							onClick={(e)=>{if (designTime) {setSelectedProperty(propertyId);setSelectionAttribute(null);e.stopPropagation()}}}
							required={true}
							InputProps={{readOnly: !designTime}}
							style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
						/>
					</Grid>
					
					<Grid container direction='row' alignItems='center' >

						{/* Key */}
						<Grid item lg={12} xl={6}>
							<Grid style={{flexWrap: 'noWrap'}} container direction='row' alignItems='center' >
								<Tooltip title={property.extractionRule.key && property.extractionRule.key.rect ? i18.t('extraction_key_reset_label') : i18.t('extraction_key_select_label')} >
									<Button
										size="small"
										style={{color: 'blue'}}
										onClick={(e)=>{
											if (designTime) {
												property.extractionRule.key = null
												updateProperty(property)
												setSelectionAttribute('key')
												setSelectedProperty(propertyId)
											}
											e.stopPropagation()
										}}
									>
										<Typography noWrap variant='inherit'>{property.extractionRule.key && property.extractionRule.key.rect ? i18.t('extraction_key_reset_label') : i18.t('extraction_key_select_label')}</Typography>
									</Button>
								</Tooltip>
								<Typography style={{overflow: 'hidden', textOverflow: 'ellipsis'}}  >{key}</Typography>
							</Grid>
						</Grid>
					
						{/* Property value */}
						<Grid item lg={12} xl={6}>
							<Grid style={{flexWrap: 'noWrap'}} container direction='row' alignItems='center'>												
								<Tooltip title={i18.t('extraction_value_button_label')} >
									<div>
										<Button 
											disabled={!designTime}
											size="small"
											style={{color: 'green'}}
											onClick={(e)=>{
												if (designTime) {
													property.extractionRule.value=null
													updateProperty(property)
													setSelectionAttribute('value')
													setSelectedProperty(propertyId)
												}
												e.stopPropagation()
											}}
										>
											<Typography noWrap variant='inherit'>{i18.t('extraction_value_button_label')}</Typography>
										</Button>
									</div>
								</Tooltip>

								<Typography style={{overflow: 'hidden', textOverflow: 'ellipsis'}} >{value}</Typography>
							</Grid>
						</Grid>
					</Grid>
						
				</Grid>
			</AccordionSummary>
			<AccordionDetails style={{padding: 0}}>	
				<Grid container direction='column' >				

					<Grid style={{paddingLeft: 10}} container direction='row' alignItems='flex-end' >

						<Grid item>
							<Grid container direction='column' >

								{/* Property type */}
								<FormControl required={true} >
									<Select
										variant='standard'
										disabled={!designTime}
										value={property.subType}
										onChange={(e)=>{if (e.target.value) {changePropertyType(rootparentId, orgProperties, property, e.target.value);e.stopPropagation()}}}
									>
										<Button>Filter...</Button>
										<TagBar 
											tagList={tagList} 
											selectedTags={tagFilter} 
											editMode={false} 
											onSelectionUpdate={(selectedTags)=>setTagFilter(selectedTags)}
										/>									
										{props.map( (prop, propIndex) => {
											let menuLabel = i18.t(`property_${prop.subType}`)
											return <MenuItem onClick={(e)=>e.stopPropagation()} key={propIndex} value={prop.subType} >{menuLabel}</MenuItem>
										})}
									</Select>
								</FormControl>
								
								<br/>
								
								{/* Property resource name (prn) */}
								<Grid container direction='row' >
									<Typography noWrap onClick={(e)=>e.stopPropagation()} >PRN: {propertyRefName}</Typography>
									<Tooltip title={refCopied ? i18.t('tooltip_prn_copied') : i18.t('tooltip_prn_copy')} >
										<ContentCopy fontSize="small"  onClick={(e)=>{e.stopPropagation();navigator.clipboard.writeText(propertyRefName);setRefCopied(true);setTimeout(()=>setRefCopied(false), 2000)}}/>
									</Tooltip>
								</Grid>
								
							</Grid>
						</Grid>

						<Grid item style={{flex: 1}}/>

						{ showSizing ?
							<AspectRatioTwoTone onClick={(e)=>{e.stopPropagation();setShowSizing(false)}} style={{cursor: 'default'}} color='primary' />
						:
							<AspectRatio onClick={(e)=>{e.stopPropagation();setShowSizing(true)}} style={{cursor: 'default'}} color='primary' />
						}		
						
					</Grid>

					<br/>
					
					{ showSizing ?
						<Grid item >
							<Divider/>

							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >Key</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='key'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>

							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >Value</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='value'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>

							<Divider/>

						</Grid>
					: null }
					
				</Grid>
			</AccordionDetails>
		</Accordion>
				
	)
}

export default KVPExtractionRule