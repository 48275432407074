import React from 'react'
import { connect } from 'react-redux'

import WorkflowDetail from '../components/WorkflowDetail'
import { addWorkflowProperty, deleteProperty, addCompositeProperty } from '../reducers/properties'
import { createAttachment, deleteAttachment } from '../reducers/attachments'
import { updateWorkflow } from '../reducers/workflows'
import { retrieveAutomation, createAutomation } from '../reducers/automations'
import { retrieveVersions, restoreVersion } from '../reducers/versions'


const WorkflowDetailView = ({ navigation, tags, workflow, versions, designTime, disabled,
							updateWorkflow, addWorkflowProperty, deleteProperty, createAttachment, deleteAttachment, addCompositeProperty, 
							retrieveAutomation, createAutomation, retrieveVersions, restoreVersion }) => {

//	console.log('WorkflowDetailView', designTime)

	if (!workflow) return null

	return (
		<WorkflowDetail
			navigation={navigation}
			tags={tags}
			workflow={workflow}
			versions={versions}
			designTime={designTime}
			disabled={disabled}

			updateWorkflow={updateWorkflow}
			retrieveAutomation={retrieveAutomation}
			createAutomation={createAutomation}
			addWorkflowProperty={addWorkflowProperty}
			deleteProperty={(propertyId)=>deleteProperty(workflow.workflowId, propertyId)}
			createAttachment={()=>createAttachment(workflow.workflowId)}
			deleteAttachment={(attachmentId)=>deleteAttachment(workflow.workflowId, attachmentId)}
			addCompositeProperty={addCompositeProperty}
			retrieveVersions={retrieveVersions}
			restoreVersion={restoreVersion}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	workflow: state.workflows[props.workflowId],
	versions: state.versions[props.workflowId],
	workflowName: state.workflows[props.workflowId] ? state.workflows[props.workflowId].name : null,
	workflowDescription: state.workflows[props.workflowId] ? state.workflows[props.workflowId].description : null,
	properties: state.properties,
	length: state.workflows[props.workflowId].attachments?.length,
	tags: state.app.allWorkflowsTagList,
	tags2: state.workflows[props.workflowId].tags,
})

export default connect(mapStateToProps, { updateWorkflow, addWorkflowProperty, deleteProperty, createAttachment, deleteAttachment, addCompositeProperty, 
											retrieveAutomation, createAutomation, retrieveVersions, restoreVersion })(WorkflowDetailView)
