import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'

import TabBar from '../components/TabBar'
import WorkflowDetailView from '../containers/WorkflowDetailView'
import WorkflowTasksView from '../containers/WorkflowTasksView'
import DiscussionView from '../containers/DiscussionView'
import WorkflowPermissions from '../components/WorkflowPermissions'
import WorkflowDiscussion from '../components/WorkflowDiscussion'


const Workflow = ({ navigation, workflow, tasks, attachments, documents, hasDetailErrors, hasDetailWarnings, hasTaskErrors, hasTaskWarnings, 
					retrieveWorkflow, retrieveDocument }) => {
 
//	console.log('Workflow')

	const [tab, setTab] = useState('tab_details')
	const [retrieveAttempted, setRetrieveAttempted] = useState(false)

	const designTime = ['draft','template'].includes(workflow.state)
	const disabled = ['completed','canceled','deleted'].includes(workflow.state)

	const tabValues = ['tab_details','tab_tasks','tab_permissions','tab_discussion']
	const tabLabels = [
		<Typography style={{color: hasDetailErrors ? 'red' : hasDetailWarnings ? 'orange' : ''}}>{i18.t('tab_details')}</Typography>,
		<Typography style={{color: hasTaskErrors ? 'red' : hasTaskWarnings ? 'orange' : ''}}>{i18.t('tab_tasks')}</Typography>,
		i18.t('tab_permissions'),
		i18.t('tab_discussion')]

	if (!workflow.created && !retrieveAttempted) {
		retrieveWorkflow(workflow.workflowId)
		setRetrieveAttempted(true)
	}

	for (const attachmentId of workflow.attachments ? workflow.attachments : []) {
		const attachment = attachments[attachmentId]
		if (attachment) {
			const documentId = designTime ? attachment.defaultDocumentId : attachment.documentId
			const doc = documents[documentId]
			if (!doc?.created) {
//				retrieveDocument(documentId, 'workflow', workflow.workflowId)
			}
		}
	}

	let height = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 40

	height = height - toolbarHeight

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='workflow' style={{width: width, height: height}}>

			<TabBar tabValues={tabValues} tabLabels={tabLabels} tab={tab} setTab={setTab} />	
			
			{ tab === 'tab_details' ?
				<WorkflowDetailView
					navigation={navigation}
					workflowId={workflow.workflowId}
					designTime={designTime}
					disabled={disabled}
				/>
			: tab === 'tab_tasks' ?
				<WorkflowTasksView
					workflowId={workflow.workflowId}
					designTime={designTime}
					disabled={disabled}
				/>
			: tab === 'tab_permissions' ?
				<WorkflowPermissions workflowId={workflow.workflowId} designTime={designTime} disabled={disabled} />
			: tab === 'tab_discussion' ?
				<WorkflowDiscussion workflowId={workflow.workflowId} designTime={designTime} disabled={disabled} />
			: null }
			
		</Grid>
	)
}

export default Workflow
