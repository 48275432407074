import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'

import SaveToPDF from '../components/actions/SaveToPDF'
import SaveToSheet from '../components/actions/SaveToSheet'
import SaveToJSON from '../components/actions/SaveToJSON'
import SaveToCSV from '../components/actions/SaveToCSV'
import SetPropertyValue from '../components/actions/SetPropertyValue'
import AddDeleteTag from '../components/actions/AddDeleteTag'
import LaunchWorkflow from '../components/actions/LaunchWorkflow'

 
const MilestoneAction = ({ me, workflowId, task, attachments, allProperties, documents, milestoneActionIndex, focus, designTime, disabled, slimStyle=false, updateTask, setFocus }) => {

//	console.log('MilestoneAction')

	const [hover, setHover] = useState(false)

	let action = task.milestoneActions[milestoneActionIndex]

	if (!action) return null

	let actionStyle = slimStyle ? {} : {flex: 1, boxShadow: '0 3px 6px rgba(0,0,0,0.16)', padding: 5}

	if (action.validationErrors && action.validationErrors.filter(err => err.error).length > 0) {
		// If this action has an error then highlight it in red
		actionStyle.boxShadow = '0 2px 4px rgba(255,0,0,0.7)'
	} else if (action.validationErrors && action.validationErrors.filter(err => err.warning).length > 0) {
		// If this action has a warning then highlight it in orange
		actionStyle.boxShadow = '0 2px 4px rgba(255,165,0,0.7)'
	}

	const [expanded, setExpanded] = useState(false)
	
	return (
		<Grid
			item
			style={{...actionStyle, backgroundColor: hover ? 'ghostwhite' : 'transparent'}}
			onMouseMove={(e) => {e.stopPropagation();if(!hover){setHover(true)};}}
			onMouseEnter={(e) => {e.stopPropagation();setHover(true);}}
			onMouseLeave={(e) => {e.stopPropagation();setHover(false);}}
		>
									
			{ action.type === 'save_to_pdf' ?
				<SaveToPDF workflowId={workflowId} task={task} milestoneActionIndex={milestoneActionIndex} focus={focus} hover={hover} designTime={designTime} disabled={disabled} updateTask={updateTask} />
			: action.type === 'save_to_sheet' ?
				<SaveToSheet workflowId={workflowId} task={task} milestoneActionIndex={milestoneActionIndex} focus={focus} hover={hover} designTime={designTime} disabled={disabled} updateTask={updateTask} />
			: action.type === 'save_to_json' ?
				<SaveToJSON workflowId={workflowId} task={task} milestoneActionIndex={milestoneActionIndex} focus={focus} hover={hover} designTime={designTime} disabled={disabled} updateTask={updateTask} />
			: action.type === 'save_to_csv' ?
				<SaveToCSV workflowId={workflowId} task={task} milestoneActionIndex={milestoneActionIndex} focus={focus} hover={hover} designTime={designTime} disabled={disabled} updateTask={updateTask} />
			: action.type === 'set_property_value' ?
				<SetPropertyValue me={me} workflowId={workflowId} task={task} attachments={attachments} allProperties={allProperties} milestoneActionIndex={milestoneActionIndex} focus={focus} hover={hover} designTime={designTime} disabled={disabled} updateTask={updateTask} setFocus={setFocus} />
			: action.type === 'add_tag' || action.type === 'delete_tag' ?
				<AddDeleteTag me={me} workflowId={workflowId} task={task} attachments={attachments} allProperties={allProperties} documents={documents} milestoneActionIndex={milestoneActionIndex} focus={focus} hover={hover} designTime={designTime} disabled={disabled} updateTask={updateTask} setFocus={setFocus} />
			: action.type === 'launch_workflow' ?
				<LaunchWorkflow workflowId={workflowId} task={task} milestoneActionIndex={milestoneActionIndex} focus={focus} hover={hover} designTime={designTime} disabled={disabled} updateTask={updateTask} />
			: null }
			
			{/* If there are any validation errors - display them here. */}
			{(action.validationErrors && action.validationErrors.length > 0) ?
				action.validationErrors.map((err, index) => {
					if (err.warning)
						return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
								
					if (err.error)
						return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
				})
			: null }
		
		</Grid>		
	)
}

export default MilestoneAction