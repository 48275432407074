import { gqlExec } from '../reducers/graphql'
import UUID from 'uuidjs'


let colorred = '\x1b[31m\x1b[0m'
let colorgreen = '\x1b[32m\x1b[0m'
let colorblue = '\x1b[34m\x1b[0m'
//let colordefault = '\x1b[0m'

let outputRed = "color:red;"
let outputGreen = "color:green;"

const processTokenizedString = (str, data) => {
try {
	if (!data) return str

	// Extract the tokenized expressions (identified by <<...>>)
	let regexp = new RegExp('<<(?:.*?)>>', 'g')
	let tokens1 = str.match(regexp)

	tokens1 && tokens1.forEach(token => {

		let origtoken = token

		// Strip off the token brackets (<< >>)
		token = token.substring(2, token.length-2)

		// Now parse out the tokens of a multi-part variable expression (prop, array index, etc)
		regexp = new RegExp('[a-zA-Z0-9]+', 'g')
		let tokens2 = token.match(regexp)

		// Now traverse the variable data to get at the bottom level element
		let replacement = data
		for (const t2 of tokens2) {
			replacement = replacement?.[t2] ? JSON.parse(JSON.stringify(replacement[t2])) : null
		}

		if (token === 'RND') {
			str = str.replace(origtoken, Math.floor((Math.random() * (data.totalCount ? Math.min(data.totalCount, 1000) : 0))))
		} else if (token === 'RND10') {
			str = str.replace(origtoken, Math.floor((Math.random() * 10)))
		} else if (token === 'RND20') {
			str = str.replace(origtoken, Math.floor((Math.random() * 20)))
		} else if (token === 'RND100') {
			str = str.replace(origtoken, Math.floor((Math.random() * 100)))
		} else if (token === 'RND1000') {
			str = str.replace(origtoken, Math.floor((Math.random() * 1000)))
		} else if (token === 'UUID') {
			str = str.replace(origtoken, UUID.genV4().toString())
		} else if (token.startsWith('INPUTS')) {
			let inputName = token.split('::')[1]
			str = str.replace(origtoken, data?.[inputdata]?.[inputName] ? data[inputdata][inputName] : null)
		} else {
			if (typeof replacement === 'object') {
				str = str.replace(origtoken, JSON.stringify(replacement))
			} else {
				str = str.replace(origtoken, replacement ? replacement : ``)
			}
		}
	})
	
	return str
} catch (err) {
	console.log(err)
}

}


const executeTest = async (test, positiveTestCaseResults, negativeTestCaseResults, level=1) => {
	
//	console.log('executeTest', test)
	
	let indent=`\t`
	for (let i=0; i<level; ++i)
		indent += `\t`
	
	if (!test.iterations) test.iterations = 1
		
	console.log(' ')
	console.log(`${indent}${test.id}: ${test.name}`)

	if (test.iterations > 1) {
		console.log(`${indent}Number of iterations: ${test.iterations}`)
		indent += `\t`
	}

	let data 

	if (test.assertIterationCount !== undefined && test.assertIterationCount !== test.iterations) {
		console.log(`%c ${indent}${test.id}: TEST FAILED, Iterations: ${test.iterations}`, outputRed)
		if (test.type === 'positive') {
			positiveTestCaseResults.push("failed")
		} else if (test.type === 'negative') {
			negativeTestCaseResults.push("failed")
		}
	} else {
		for (let i=0; i<test.iterations; ++i) {

			try {

				if (test.iterations > 1) {
					console.log(' ')
					console.log(`${indent}${test.id}: ${i}`)
				}
		
				let ti1 = Date.now()

				if (!test.data) test.data = {}
				let operation = processTokenizedString(test.operation, test.data)
				const response = await gqlExec(operation)		
				data = response[Object.keys(response)[0]]
			} catch(err) {		
				data = err
			} finally {

				if (test.assertResult) {

					if (test.assertResult === JSON.stringify(data)) {
						if (test.type === 'positive') {
							positiveTestCaseResults.push("succeeded")
							console.log(`%c ${indent}${test.id}: POSITIVE TEST SUCCEEDED`, outputGreen)
						} else if (test.type === 'negative') {
							negativeTestCaseResults.push("succeeded")
							console.log(`%c ${indent}${test.id}: NEGATIVE TEST SUCCEEDED`, outputGreen)
						}
					} else {
						console.log(`%c ${indent}${test.id}: TEST FAILED, Result data: ${JSON.stringify(data)}`, outputRed)
						if (test.type === 'positive') {
							positiveTestCaseResults.push("failed")
						} else if (test.type === 'negative') {
							negativeTestCaseResults.push("failed")
						}
					}
				} else if (test.assertResultIncludes) {
					let result = true

					for (const testdata of test.assertResultIncludes) {
						result = result && JSON.stringify(data).includes(testdata)
					}

					
					if (result) {
						if (test.type === 'positive') {
							positiveTestCaseResults.push("succeeded")
							console.log(`%c ${indent}${test.id}: POSITIVE TEST SUCCEEDED`, outputGreen)
						} else if (test.type === 'negative') {
							negativeTestCaseResults.push("succeeded")
							console.log(`%c ${indent}${test.id}: NEGATIVE TEST SUCCEEDED`, outputGreen)
						}
					} else {
						console.log(`%c ${indent}${test.id}: TEST FAILED, Result data: ${JSON.stringify(data)}`, outputRed)
						if (test.type === 'positive') {
							positiveTestCaseResults.push("failed")
						} else if (test.type === 'negative') {
							negativeTestCaseResults.push("failed")
						}
					}
				} else {
					// nothing to assert against - assume success if no exception thrown
					if (test.type === 'positive') {
						positiveTestCaseResults.push("succeeded")
						console.log(`%c ${indent}${test.id}: POSITIVE TEST SUCCEEDED`, outputGreen)
					} else if (test.type === 'negative') {
						negativeTestCaseResults.push("succeeded")
						console.log(`%c ${indent}${test.id}: NEGATIVE TEST SUCCEEDED`, outputGreen)
					}
				}
			}
			
			if (test.onEachItem) {
				for (let ii=0; ii<test.onEachItem.length; ++ii) {
					if (data && data.items) {
						for (let iii=0; iii<data.items.length; ++iii) {
							let nexttest = {...test.onEachItem[ii]}
							nexttest.data = {...nexttest.data, ...test.data}
							nexttest.data = {...nexttest.data, ...data.items[iii]}
							nexttest.data.totalCount = data.totalCount
							await executeTest(nexttest, positiveTestCaseResults, negativeTestCaseResults, level+2)
						}
					} else {
						let nexttest = {...test.onEachItem[ii]}
						nexttest.data = {...nexttest.data, ...test.data}
						nexttest.data = {...nexttest.data, ...data}
						await executeTest(nexttest, positiveTestCaseResults, negativeTestCaseResults, level+2)
					}
				}
			}

			if (test.onOneRandomItem) {
				for (let ii=0; ii<test.onOneRandomItem.length; ++ii) {
					if (data && data.items) {
						let index = Math.floor(Math.random() * data.items.length)
						let nexttest = {...test.onOneRandomItem[ii]}
						nexttest.data = {...nexttest.data, ...test.data}
						nexttest.data = {...nexttest.data, ...data.items[index]}
						nexttest.data.totalCount = data.totalCount
						await executeTest(nexttest, positiveTestCaseResults, negativeTestCaseResults, level+2)
					} else {
						let nexttest = {...test.onOneRandomItem[ii]}
						nexttest.data = {...nexttest.data, ...test.data}
						nexttest.data = {...nexttest.data, ...data}
						await executeTest(nexttest, positiveTestCaseResults, negativeTestCaseResults, level+2)
					}
				}
			}
			
			if (test.onResult) {
				for (let ii=0; ii<test.onResult.length; ++ii) {
					let nexttest = {...test.onResult[ii]}
					nexttest.data = {...nexttest.data, ...data}
					await executeTest(nexttest, positiveTestCaseResults, negativeTestCaseResults, level+2)
				}
			}
		}
	}
}

export const executeTests = (testcollection) => async dispatch => {

	if (!testcollection || !testcollection.tests) return null
	
	console.log(`Test collection: ${testcollection.name}`, new Date())

	let positiveTestCaseResults = []
	let negativeTestCaseResults = []
	
	let t1 = Date.now()

	for (let i=0; testcollection.tests && i<testcollection.tests.length; ++i) {
		await executeTest(testcollection.tests[i], positiveTestCaseResults, negativeTestCaseResults)
	}

	let elapsedTime = Date.now() - t1
	
	console.log(' ')
	console.log(`Finished: ${testcollection.name}`)
	console.log(`Positive test cases:  total (${positiveTestCaseResults.length})    succeeded (${positiveTestCaseResults.filter(res => res === 'succeeded').length})    failed (${positiveTestCaseResults.filter(res => res === 'failed').length})`)
	console.log(`Negative test cases:  total (${negativeTestCaseResults.length})    succeeded (${negativeTestCaseResults.filter(res => res === 'succeeded').length})    failed (${negativeTestCaseResults.filter(res => res === 'failed').length})`)
	console.log(`Elapsed time: ${elapsedTime}, Avg iteration time: ${Math.round(elapsedTime/(positiveTestCaseResults.length + negativeTestCaseResults.length))}`)
	console.log(new Date())

}

