import React, { useState } from 'react'
import AttachmentListItem from '../components/AttachmentListItem'
import { Grid } from '@mui/material'
import { Droppable, Draggable  } from 'react-beautiful-dnd'


const AttachmentList = ({ workflowId, rootparentType, rootparentId, rootparentState, attachmentList, designTime=false, disabled=false, level=0,
							deleteAttachment }) => {
								
//	console.log('AttachmentList')
	
	if (!attachmentList) return null

	const [focusId, setFocusId] = useState()
	
	return (
		<Grid item style={{flex: 1}} id='AttachmentList' >

			{ designTime ?
				<Droppable isDropDisabled={!designTime} droppableId={"attachmentdrop::" + rootparentId} type={`${rootparentType}attachment::${level}`} >
					{(provided, snapshot) => (
						<div ref={provided.innerRef} >					
							{attachmentList.map((attachmentId, index) =>			
								<Draggable isDragDisabled={!designTime} key={`attachmentdrag::${rootparentId}::${attachmentId}`} draggableId={`attachmentdrag::${rootparentId}::${attachmentId}`} index={index} >
									{(provided, snapshot) => (
										<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
											<AttachmentListItem
												workflowId={workflowId}
												rootparentType={rootparentType}
												rootparentId={rootparentId}
												rootparentState={rootparentState}
												attachmentId={attachmentId}
												focusId={focusId}
												designTime={designTime}
												disabled={disabled}
												deleteAttachment={deleteAttachment}
												setFocusId={setFocusId}
											/>
										</div>
									)}
								</Draggable>
							)}
						{provided.placeholder}
						</div>
					)}
				</Droppable>
			: 
				attachmentList.map((attachmentId, index) =>			
					<AttachmentListItem
						key={index}
						workflowId={workflowId}
						rootparentType={rootparentType}
						rootparentId={rootparentId}
						attachmentId={attachmentId}
						focusId={focusId}
						designTime={designTime}
						disabled={disabled}
						deleteAttachment={deleteAttachment}
						setFocusId={setFocusId}
					/>
				)
			}
		</Grid>
	)
}

export default AttachmentList