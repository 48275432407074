import React, { useState } from 'react'
import { Grid, Button, Tooltip, Hidden, CircularProgress } from '@mui/material'
import { ArrowBack, Save, PlayArrow, Delete, Cancel, GetApp, Refresh, Error } from '@mui/icons-material'
import * as i18 from 'i18next'

import ActionButton from '../components/ActionButton'
import WorkflowCancelDialog from '../components/dialogs/WorkflowCancelDialog'
import ConfirmDeletionDialog from '../components/dialogs/ConfirmDeletionDialog'


const WorkflowToolBar = ({ navigation, workflow, tasks, hasErrors, 
							saveWorkflow, saveWorkflowAs, cancelWorkflow, deleteWorkflow, 
							createLaunchAssignment, exportWorkflow, retrieveWorkflow }) => {

//	console.log('WorkflowToolBar')

	const workflowId = workflow.workflowId
	const workflowState = workflow.state
	const workflowTasks = workflow.tasks

	const [cancelConfirmationDialogIsOpen, setWorkflowCancelDialogIsOpen] = useState(false)
	const [deleteConfirmationDialogIsOpen, setWorkflowDeleteDialogIsOpen] = useState(false)

	const [isSaving, setIsSaving] = useState(false)
	const [isSavingAsDraft, setIsSavingAsDraft] = useState(false)
	const [isSavingAsTemplate, setIsSavingAsTemplate] = useState(false)
	const [isSavingCopy, setIsSavingCopy] = useState(false)
	const [isCanceling, setIsCanceling] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)

	return (
		<Grid item id='toolbar' >

			<Grid container direction="row" alignItems='center' >

				<Tooltip title={i18.t('general_back_tooltip')} >
					<Button color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				</Tooltip>
	
				{workflowState === 'draft' || workflowState === 'template' || workflowState === 'working' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_save')} mdUp={i18.t('workflow_save')} mdDown={<Save/>} inProgress={isSaving} onClick={async ()=>{ await saveWorkflow(workflowId, setIsSaving)}} />
				: null }

				{workflowState === 'template' || workflowState === 'working' || workflowState === 'completed' || workflowState === 'canceled' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_save_as_draft')} mdUp={i18.t('workflow_save_as_draft')} mdDown={<Grid container direction='row'><Save/>-</Grid>} inProgress={isSavingAsDraft} onClick={async ()=>{await saveWorkflowAs(workflowId, 'draft', navigation, setIsSavingAsDraft)}} />
				: null }
				
				{workflowState === 'template' || workflowState === 'draft' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_save_copy')} mdUp={i18.t('workflow_save_copy')} mdDown={<Grid container direction='row'><Save/>+</Grid>} inProgress={isSavingCopy} onClick={async ()=>{await saveWorkflowAs(workflowId, workflowState, navigation, setIsSavingCopy)}} />
				: null}
	
				{workflowState === 'draft' || workflowState === 'working' || workflowState === 'completed' || workflowState === 'canceled' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_save_as_template')} mdUp={i18.t('workflow_save_as_template_lg')} mdDown={<Grid container direction='row'><Save/>+</Grid>} inProgress={isSavingAsTemplate} onClick={async ()=>{await saveWorkflowAs(workflowId, 'template', navigation, setIsSavingAsTemplate)}} />
				: null}
				
				{workflowState === 'draft' || workflowState === 'template' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_run_workflow')} mdUp={i18.t('workflow_run_workflow')} mdDown={<PlayArrow/>} disabled={hasErrors} 
						onClick={async ()=>{
							await createLaunchAssignment(workflowId, workflowTasks, tasks)
							navigation.navigate('launch_assignment', {workflowId: workflowId})
						}} 
					/>
				: null}

				{workflowState === 'draft' || workflowState === 'template' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_discard')} mdUp={i18.t('workflow_discard')} mdDown={<Delete/>} inProgress={isDeleting} onClick={async ()=>{await setWorkflowDeleteDialogIsOpen(true)}} />
				: null}
								
				{workflowState === 'working' ?
					<ActionButton tooltipText={i18.t('workflow_tooltip_cancel_workflow')} mdUp={i18.t('workflow_cancel_workflow')} mdDown={<Cancel/>} inProgress={isCanceling} onClick={async ()=>{await setWorkflowCancelDialogIsOpen(true)}} />
				: null}

				<ActionButton tooltipText={i18.t('workflow_tooltip_export_workflow')} mdUp={i18.t('general_export')} mdDown={<GetApp/>} onClick={async ()=>{await exportWorkflow(workflow)}} />
				
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_item_tooltip')} >
					<Button color="primary" onClick={async ()=>{await retrieveWorkflow(workflowId)}} >
						<Refresh/>
					</Button>
				</Tooltip>						
				
			</Grid>

			{ cancelConfirmationDialogIsOpen ?
				<WorkflowCancelDialog 
						navigation={navigation}
						workflowId={workflowId}
						cancelWorkflow={()=>cancelWorkflow(workflowId, navigation, setIsCanceling)}
						setWorkflowCancelDialogIsOpen={setWorkflowCancelDialogIsOpen}
				/>
			: null }
			
			{ deleteConfirmationDialogIsOpen ?
				<ConfirmDeletionDialog 
					dialogTitle={i18.t('dialog_delete_workflow_title')} 
					dialogContent={i18.t('dialog_delete_workflow_desc')}
					onDelete={()=>deleteWorkflow(workflowId, workflowState, navigation, setIsDeleting)}
					setDialogIsOpen={setWorkflowDeleteDialogIsOpen}
				/>
			: null }

		</Grid>
	)
}

export default WorkflowToolBar
