import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, Tooltip } from '@mui/material'
import * as i18 from 'i18next'

import WorkflowAuthored from '../components/WorkflowAuthored'
import WorkflowRuntime from '../components/WorkflowRuntime'


const WorkflowTasks = ({ workflow, tasks, taskinstances, assignments, designTime, addTask, setExpanded }) => {
 
//	console.log('WorkflowTasks')
	
	if (!workflow) return null

	const [authoredView, setAuthoredView] = useState(false)

	let setAuthoredView2
	useEffect(() => {
		let isMounted = true;
		setAuthoredView2 = (e) => {if (isMounted) setAuthoredView(e)}
		return () => {isMounted = false}
	}, [])

	let expandAllTasks = () => {
		tasks && Object.keys(tasks).forEach(taskId => setExpanded(taskId, true))
		taskinstances && Object.keys(taskinstances).forEach(taskinstanceId => {
			setExpanded(taskinstanceId, true)
			let taskinstance = taskinstances[taskinstanceId]
			taskinstance.assignments?.forEach(assignment => setExpanded(assignment.assignmentId, true))
		})
		
		assignments && Object.keys(assignments).forEach(assignmentId => setExpanded(assignmentId, true))
	}
	let collapseAllTasks = () => {
		tasks && Object.keys(tasks).forEach(taskId => setExpanded(taskId, false))
		taskinstances && Object.keys(taskinstances).forEach(taskinstanceId => {
			setExpanded(taskinstanceId, false)
			let taskinstance = taskinstances[taskinstanceId]
			taskinstance.assignments?.forEach(assignment => setExpanded(assignment.assignmentId, false))
		})
	}

	let height = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 50
	const tabbarHeight = document.getElementById('tabbar')?.clientHeight ? document.getElementById('tabbar')?.clientHeight : 50

	height = height - (toolbarHeight + tabbarHeight)

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='workflow-tasks' style={{width: width, height: height, position: 'absolute', overflow: 'auto'}} >
				
			<Grid container direction="row" alignItems='center' >
			
				{workflow.state === 'draft' || workflow.state === 'template' ?
					<Grid container style={{flex: 1}} />
				:
					<Grid container direction='row' style={{flex: 1}} alignItems='center' justify='center' >
						<Tooltip title={i18.t('tab_runtime_view_tooltip')} >
							<Typography color={!authoredView ? "primary" : "initial"} onClick={()=>setAuthoredView2(false)} >{i18.t('tab_runtime_view')}</Typography>
						</Tooltip>
						&nbsp;&nbsp;&nbsp;&nbsp;				
						<Tooltip title={i18.t('tab_authored_view_tooltip')} >
							<Typography color={authoredView ? "primary" : "initial"} onClick={()=>setAuthoredView2(true)} >{i18.t('tab_authored_view')}</Typography>
						</Tooltip>
					</Grid>
				}
				
				<Grid item>
					{/* Expand All / Collapse All buttons */}
					<Tooltip title={i18.t('workflow_tooltip_expand_all')} >
						<Button color="primary" onClick={expandAllTasks} >
							{i18.t('workflow_expand_all_tasks')}
						</Button>
					</Tooltip>
						
					<Tooltip title={i18.t('workflow_tooltip_collapse_all')} >
						<Button color="primary" onClick={collapseAllTasks} >
							{i18.t('workflow_collapse_all_tasks')}
						</Button>
					</Tooltip>
				</Grid>
				
			</Grid>
		
			<Grid item>	

				{ workflow.state === 'draft' || workflow.state === 'template' || authoredView ?
					<WorkflowAuthored
						workflow={workflow}
						tasks={tasks}
						assignments={assignments}
						addTask={addTask}
						designTime={designTime}
					/>					
				:
					<WorkflowRuntime
						workflow={workflow}
						taskinstances={taskinstances}
					/>
				}
			</Grid>		
			
		</Grid>
	)
}

export default WorkflowTasks
