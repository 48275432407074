import { schema, normalize, denormalize } from 'normalizr'
import cloneDeep from 'lodash.clonedeep'

import { RECEIVE_EXTRACT, RECEIVE_EXTRACTS, UPDATE_EXTRACT, DELETE_EXTRACT, RECEIVE_ERRORS } from './actiontypes'
import { ADD_PROPERTY_EVENT, DELETE_PROPERTY_EVENT, MOVE_PROPERTY_EVENT, MOVE_TO_COMPOSITE_PROPERTY_EVENT, RESET_COMPOSITE_PROPERTY_EVENT } from './actiontypes'
import { extractReturnFragment, extractMinFragment, extractMaxFragment, extractionRuleFragment, propertyFragment, permissionFragment, limitedOrgEntityFragment, commentFragment } from '../reducers/graphql'
import { gqlExec, gqlInput, gqlIdInput } from '../reducers/graphql'
import { generateHashCode } from '../reducers/utils'
import { removeMeta, removeValueAndDefault, removeArrayProperties, propertySchema, setDefaultsFromDefaultInstanceData } from '../reducers/workflows'
import { reset, navigate } from '../reducers/appnav'
import { store } from '../App'


const properties = new schema.Array(propertySchema)
propertySchema.define({properties})

export const extractSchema = new schema.Entity(
	'extracts', 
	{ 
	}, 
	{ 
		idAttribute: value => value.extractId,
	}
)


// Extract Functions

const postExtractUpdates = (extracts) => {

//	console.log('postExtractUpdates', extracts)

	if (extracts) {

		let properties={}

		for (let i=0; i<extracts.length; ++i) {

			if(!extracts[i]) throw ("Invalid extract")
			
			let extract = extracts[i]

			// Set defaults from default instance data
			if (extract.defaultInstanceData && extract.properties) {
				for (const property of extract.properties) {
					setDefaultsFromDefaultInstanceData(property, extract?.defaultInstanceData?.[property.pname])
				}
			}
		
			// Normalize the properties
			if (extract.properties) {
				let propertydata = normalize(extract.properties, [propertySchema])
				properties[extract.extractId] = propertydata.entities.properties
				extract.properties = propertydata.result
			}

			extract.detailRetrieved = Date.now()
		}
							
		// Now normalize the extract data
		let extractdata = normalize(extracts, [extractSchema])
	
		return {list: extractdata.result, extracts: extractdata.entities.extracts, properties: properties}
	}
}

export const retrieveExtracts = (listType, skip=0, tagFilter) => async dispatch => {

//	console.log('retrieveExtracts', listType)

	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`{extracts ${gqlInput(null, tagFilter, skip, true, true, true)} {...extractReturnFields}} ${extractReturnFragment} ${extractMinFragment}`)		

		const extracts = response && response.extracts ? response.extracts : null

		if (extracts) {
			let action = postExtractUpdates(extracts.items)
			action.type = RECEIVE_EXTRACTS
			action.listType = listType
			action.skip = skip
			action.listCount = extracts.totalCount
			action.tagList = extracts.tagList
			dispatch(action)
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const retrieveExtract = (extractId) => async dispatch => {

//	console.log('retrieveExtract');

	if (!extractId)
		return
	
	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`query {extracts ${gqlIdInput('extract', extractId)} {...extractReturnFields}} ${extractReturnFragment} ${extractMaxFragment} ${propertyFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${extractionRuleFragment} ${commentFragment} `)

		let extracts = response && response.extracts ? response.extracts.items : null

		if (extracts && extracts[0]) {

			extracts[0].hash = generateHashCode(extracts[0])

			let action = postExtractUpdates(extracts)
			action.type = RECEIVE_EXTRACT
//			action.listType = 'extracts'
			dispatch(action)
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const createExtract = (documentId) => async dispatch => {

//	console.log('createExtract', extract)

	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`mutation {createExtract (documentId: "${documentId}") {...extractFields}} ${extractMaxFragment} ${propertyFragment} ${extractionRuleFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${commentFragment} `)

		const extract = response.createExtract

		if (extract) {
			extract.hash = generateHashCode(extract)
			dispatch(retrieveExtracts("extracts"))
			dispatch(retrieveExtract(extract.extractId))
			dispatch(reset('extract_list'))
			dispatch(navigate('extract', {extractId: extract.extractId}))
			return extract.extractId
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const saveExtract = (extractId, setStatusIndicator) => async dispatch => {

//	console.log('updateExtract', extract)

	try {
		document.body.style.cursor = 'wait'

		if (setStatusIndicator) {setStatusIndicator(true)}
	
		let extract = cloneDeep(store.getState().extracts[extractId])

		if (extract) {
			let allProperties = store.getState().properties
		
			let properties = allProperties[extractId]
			extract.properties = denormalize(extract.properties, [propertySchema], {properties: properties})

			let hash = extract.hash

			// Delete properties added for operational activities
			delete extract.detailRetrieved
			delete extract.hash
			removeMeta(extract, 'parentpropertyId')
			removeMeta(extract, 'modified')

			if (generateHashCode(extract) === hash) {
	console.log(`Save not needed!`)
				return
			}

	console.log(`Saving extract at ${new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(new Date())}`)

			delete extract.comments
			delete extract.extractdata
			delete extract.documentId
	//		delete extract.sourceExtractId
			delete extract.type
			delete extract.created
			delete extract.createdBy
			delete extract.updatedBy

			removeMeta(extract, 'validationErrors')
			removeMeta(extract, 'parentpropertyId')
			removeMeta(extract, 'version')
			removeMeta(extract, 'domain')
			removeMeta(extract, 'terms')
			removeMeta(extract.properties, 'tags')
	//		removeMeta(extract.properties, 'words')

			removeValueAndDefault(extract.properties)
			removeArrayProperties(extract.properties)
				
			const response = await gqlExec(`mutation {updateExtract (input: ${JSON.stringify(extract)}) {...extractFields}} ${extractMaxFragment} ${propertyFragment} ${permissionFragment} ${limitedOrgEntityFragment} ${extractionRuleFragment} ${commentFragment} `)

			let extract2 = response ? response.updateExtract : null

			if (extract2) {
				extract2.hash = generateHashCode(extract2)

				let action = postExtractUpdates([extract2])
				action.type = RECEIVE_EXTRACT
//				action.listType = 'extracts'
				dispatch(action)
			}
		}
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
}

export const deleteExtract = (extractId) => async dispatch => {

//	console.log('deleteExtract', extractId)

	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`mutation {deleteExtract (extractId: "${extractId}")} `)		

		dispatch({type: DELETE_EXTRACT, extractId: response?.deleteExtract})
		dispatch(retrieveExtracts("extracts"))
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const updateExtract = (extract) => dispatch => {
	dispatch({type: UPDATE_EXTRACT, extract: extract})
}


const initialState = {}

const extracts = (state=initialState, action) => {
	
	switch (action.type) {

// Extract Events

		case RECEIVE_EXTRACT:
		case RECEIVE_EXTRACTS:
			state = {...state}
			for (let i=0; action.list && i<action.list.length; ++i) {
				state[action.list[i]] = action.extracts[action.list[i]]
			}
			break

		case UPDATE_EXTRACT:
			state = {...state,
				[action.extract.extractId]: action.extract,
			}			
			break

		case DELETE_EXTRACT: {
			state = {...state}
			delete state[action.extractId]
		} break
	
	
// Property Events

		case ADD_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]) {
				if (!state[action.rootparentId].properties) state[action.rootparentId].properties=[]			
				if (state[action.rootparentId].properties.indexOf(action.property.propertyId) === -1) {
					state[action.rootparentId].properties.splice(0, 0, action.property.propertyId)
				}
			}
		}
		break

		case DELETE_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId] && state[action.rootparentId].properties) {
				let index = state[action.rootparentId].properties.findIndex(propertyId => propertyId === action.propertyId)
				state[action.rootparentId].properties.splice(index, 1)
			}
		} break

		case MOVE_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]  && state[action.rootparentId].properties) {
				state[action.rootparentId].properties.splice(action.toIndex, 0, state[action.rootparentId].properties.splice(action.fromIndex, 1)[0])
			}
		}
		break

		case MOVE_TO_COMPOSITE_PROPERTY_EVENT: {
			// If this property was a child of the root parent - remove it from there
			let rootParent = state[action.rootparentId]
			let index = rootParent && rootParent.properties ? rootParent.properties.indexOf(action.propertyId) : -1
			if (index !== -1) {rootParent.properties.splice(index,1)}
		} break

		case RESET_COMPOSITE_PROPERTY_EVENT: {
			if (action.property && !action.property.parentpropertyId) {
				for (const propertyId of action.property.properties) {
					if (state[action.rootparentId].properties.indexOf(propertyId) === -1) {
						state[action.rootparentId].properties.push(propertyId)
					}
				}
			}
		} break

		default:
		break

	}
	
	return state
}

export default extracts