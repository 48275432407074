import React, { useState } from 'react'
import { Grid, Chip, Button, Tooltip } from '@mui/material'
import * as i18 from 'i18next'

import TagAddDialog from '../components/dialogs/TagAddDialog'


const TagBar = ({ tagList=[], selectedTags=[], allTags=[], onUpdate=()=>{}, onSelectionUpdate=()=>{}, editMode=false }) => {

	const [showTagAddDialog, setShowTagAddDialog] = useState()

	return (
		<Grid item style={{padding: 5}} >

			<Grid container direction="row" wrap="wrap" >
				{ editMode ?
					<Tooltip title={i18.t('tagbar_add_tag_tooltip')}>
						<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();setShowTagAddDialog(true);}}>+</Button>
					</Tooltip>
				: null }
				{tagList?.map((tag, index) => 
					<Tooltip key={index} title={selectedTags?.includes(tag) ? i18.t('tagbar_unselect_tag_tooltip') : i18.t('tagbar_select_tag_tooltip')} >
						<Chip 
							style={{margin: 2}} 
							key={index} 
							label={tag} 
							variant={(selectedTags?.indexOf(tag) === -1) ? 'outlined' : 'default'} 
							onClick={(e)=> {
								if (editMode) {
									let newTags = [...tagList]
									let index = tagList.indexOf(tag)
									if (index !== -1) {
										newTags.splice(index,1)
										onUpdate(newTags)
									}
								} else {
									let newSelection = [...selectedTags]
									let index = selectedTags.indexOf(tag)
									if (index === -1) {
										newSelection.push(tag)
									} else {
										newSelection.splice(index,1)
									}
									onSelectionUpdate(newSelection)
								}
								e.stopPropagation()
							}}
						/>
					</Tooltip>
				)}
			</Grid>

			{ showTagAddDialog ?
				<TagAddDialog tagList={allTags} selectedTags={tagList} onUpdate={onUpdate} setShowTagAddDialog={setShowTagAddDialog} />
			: null }

		</Grid>
	)
}

export default TagBar