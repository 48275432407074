import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'

import TaskListView from '../containers/TaskListView'


const TaskRouteConditionElse = ({ workflowId, task, updateTask, designTime }) => {

//	console.log('TaskRouteConditionElse')

	if (!task.routeCondition.defaultOption) {task.routeCondition.defaultOption = {type: 'nexttask'}}
	
	return (
		<Grid container direction={'row'} alignItems="center" >

			<Typography style={{marginLeft: 5, fontWeight: "bold"}} >{i18.t('route_condition_else')}</Typography>
			
			<TaskListView 
				workflowId={workflowId}
				task={task}
				selectedOptionType={task.routeCondition.defaultOption?.type}
				selectedOptionDest={task.routeCondition.defaultOption?.destination}
				updateTask={updateTask}
				update={(type, destination) => {
					task.routeCondition.defaultOption.type = type
					task.routeCondition.defaultOption.destination = destination
					updateTask(workflowId, task)
				}}				

				designTime={designTime}
			/>

		</Grid>
	)

}

export default TaskRouteConditionElse