import React, { useState } from 'react'
import { Grid, Tooltip, Icon } from '@mui/material'
import { DragIndicator, RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

import ExtractionRuleView from '../containers/ExtractionRuleView'


const ExtractionRuleListItem = ({ rootparentId, propertyId, selectedProperty, highlightedRule, avgwordheight, designTime,
									setSelectedProperty, setHighlightedRule, setSelectionAttribute, deleteProperty, setUpdateOverlays,
									absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log('ExtractionRuleListItem')

	if (!rootparentId || !propertyId) return null

//	const [highlightedRule2, setHighlightedRule2] = useState()
//	const [selectedProperty2, setSelectedProperty2] = useState()

	let hasFocus = (propertyId === selectedProperty)
	let borderLeft
	if (designTime && hasFocus) {
		borderLeft = '2px solid blue'
	} else if (designTime && !hasFocus) {
		borderLeft = '.5px solid lightgrey'
	}

	return (
		<Grid container 
			style={{paddingTop: 10, paddingBottom: 10}}
			direction={'row'} 
			alignItems="center" 
			wrap="nowrap"
			onMouseMove={(e) => {e.stopPropagation();if (selectedProperty !== propertyId) {setHighlightedRule(propertyId)};}}
			onMouseEnter={(e) => {e.stopPropagation();if (selectedProperty !== propertyId) {setHighlightedRule(propertyId)};}}
			onMouseLeave={(e) => {e.stopPropagation();setHighlightedRule(null);}}
			onClick={()=>{setSelectedProperty(propertyId);setHighlightedRule(null);setSelectionAttribute(null);}} 
//			onMouseMove={(e) => {e.stopPropagation();if (selectedProperty2 !== propertyId) {setHighlightedRule(propertyId);setHighlightedRule2(propertyId)}}}
//			onMouseEnter={(e) => {e.stopPropagation();setHighlightedRule(propertyId);setHighlightedRule2(propertyId)}}
//			onMouseLeave={(e) => {e.stopPropagation();setHighlightedRule(null);setHighlightedRule2(null)}}
//			onClick={()=>{setSelectedProperty(propertyId);setSelectedProperty2(propertyId);setHighlightedRule(null);setHighlightedRule2(null)}} 
		>			
								
			{ designTime && (highlightedRule === propertyId) ?
				<Tooltip title={i18.t('property_tooltip_drag_property')} >
					<DragIndicator style={{cursor: 'grab'}}/>
				</Tooltip>
			: 
				<Icon/>
			}
			
			<Grid item style={{flex: 1, cursor: document.body.style.cursor, borderLeft: borderLeft}} >
				<ExtractionRuleView
					rootparentId={rootparentId}
					propertyId={propertyId}
					selectedProperty={selectedProperty}
					highlightedRule={highlightedRule}
					avgwordheight={avgwordheight}
					designTime={designTime}
					
					setSelectedProperty={setSelectedProperty}
					setHighlightedRule={setHighlightedRule}
					setSelectionAttribute={setSelectionAttribute}
					setUpdateOverlays={setUpdateOverlays}
					absoluteToProportionalRect={absoluteToProportionalRect}
					proportionalToAbsoluteRect={proportionalToAbsoluteRect}
				/>
			
			</Grid>

			{designTime && (highlightedRule === propertyId || selectedProperty === propertyId) ?
				<RemoveCircle 
					color="primary" 
					style={{cursor: 'default', height: 20, width: 20}}
					onClick={()=>{
						deleteProperty(rootparentId, propertyId)
						setSelectedProperty(null)
					}}
				/>	
			: 
				<Icon/> 
			}
			
		</Grid>
	)
}

export default ExtractionRuleListItem