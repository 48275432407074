import React from 'react'
import { Grid, FormControl, Select, MenuItem } from '@mui/material'
import UUID from 'uuidjs'
import cloneDeep from 'lodash.clonedeep'


// Runtime view of component
const PropertyDropdownMulti = ({ nameTypeComponent, property, properties, disabled, updateProperty, addCompositeProperty, deleteCompositeProperty }) => {

	let value = property?.properties?.map(propertyId => properties[propertyId].value)

	return (
		<Grid container direction={'row'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
	
			<Grid item style={{paddingLeft: 10}} >
				<FormControl>
					<Select
						variant='standard'
						multiple
						value={value ? value : []}
						disabled={disabled}
					>
						{property.items.enum.map((option, index) =>
							<MenuItem 
								key={index} 
								value={option}
								selected={value && value.includes(option) ? true : false}
								onClick={(event) => {
									let subpropertyindex = property?.properties?.findIndex(propertyId => properties[propertyId].value === option)
									if (subpropertyindex !== undefined && subpropertyindex !== -1) {
										let subpropertyId = property.properties[subpropertyindex]
										deleteCompositeProperty(property.propertyId, subpropertyId)
									} else {
										let newProp = cloneDeep(property.items)
										newProp.propertyId = UUID.genV4().toString()
										newProp.value = option
										addCompositeProperty(property.propertyId, newProp)
									}
								}}
							>
								{JSON.stringify(option)}
							</MenuItem>
						)}				
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	)
}

export default (PropertyDropdownMulti)
