import React, { useState } from 'react'
import { Grid, Typography, Avatar, Hidden, Icon, Divider, Tooltip } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import { jwtDecode  } from 'jwt-decode'
import * as i18 from 'i18next'


const PermissionList = ({ sourceObj, designTime, disabled, update }) => {

//	console.log('PermissionList')

	if (!sourceObj) return null

	const googleSession = JSON.parse(localStorage.getItem('googleSession'))
	const id = googleSession ? jwtDecode (googleSession.id_token) : null
	const you = id ? id.sub : null

	const [hover, setHover] = useState(false)
		
	return (
				<Grid item >
					<br/>

					<Grid container direction={'row'} >
						<Typography style={{flex: 1, paddingLeft: 60}}>{i18.t('permission_list_user_label')}</Typography>
						<Typography style={{paddingRight: 80}}>{i18.t('permission_list_permission_label')}</Typography>
					</Grid>

					<Divider/>

					{sourceObj.permissions && sourceObj.permissions.map((permission, index) => (

						<Grid container direction={'column'} key={index} >

							<Grid item >
								<Grid 
									container 
									direction={'row'} 
									alignItems='center' 
									style={{marginTop: 5, marginBottom: 5}}
									onMouseMove={() => setHover(index)}
									onMouseEnter={() => setHover(index)}
									onMouseLeave={() => setHover(false)}
								>
									<Hidden mdUp >
										<Avatar src={permission.grantee ? permission.grantee.thumbnailPhotoUrl : ''} style={{width: 30, height: 30}} />
									</Hidden>
									&nbsp;&nbsp;
									<Typography>
										{permission.grantee ? permission.grantee.fullName : <br/>}
										{permission.grantee && you && you === permission.grantee.orgentityId ? ' (you)' : null}
									</Typography>				
									<Typography style={{flex: 1}} >
										{permission.grantee ? permission.grantee.email : <br/>}
									</Typography>
									<Typography style={{paddingRight: 10}}>
										{i18.t(`role_${permission.grant}`)}
									</Typography>
									{ (hover === index) && !disabled ?
										<Tooltip title={i18.t('workflow_tooltip_delete_member')} >
											<RemoveCircle
												style={{cursor: 'default', height: 20, width: 20}}
												onClick={() => {
													sourceObj.permissions.splice(index,1)
													sourceObj.permissions = [...sourceObj.permissions]
													update(sourceObj)
												}}
											/>
										</Tooltip>
									:
										<Icon/>
									}
								</Grid>
							</Grid>

							<Divider/>

						</Grid>
					))}
				</Grid>
	)
}

export default PermissionList