import React, { useState } from 'react'
import { Grid, TextField, Tooltip, Icon } from '@mui/material'
import { AddCircle, RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'


// Design time view of component
const PropertySelectSingleEdit = ({ nameTypeComponent, property, updateProperty, designTime, disabled }) => {

//console.log('PropertySelectMultiEdit')

	if (!property) return null

	const [hover, setHover] = useState(false)
	
	return (
		<Grid container direction={'row'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
			
			<Grid item>
				<Grid container direction={'column'} >
					{property.enum.map((option, optionIndex) => 
						<Grid item key={optionIndex}>
							<Grid 
								container direction={'row'} 
								alignItems="center" 
								onMouseMove={() => {setHover(optionIndex)}}
								onMouseEnter={() => {setHover(optionIndex)}}
								onMouseLeave={() => {setHover(null)}}
							>
							
								<TextField
									variant='standard'
									disabled={disabled}
									margin="dense"
									id="propertyoption"
									InputLabelProps={{shrink: true}}				
									placeholder={designTime ? i18.t('property_option_placeholder') : null}
									value={option ? option : ''}
									onChange={(event)=>{property.enum[optionIndex]=event.target.value;updateProperty(property)}}
									/>

								{ designTime && (hover === optionIndex) && (property.enum.length > 1) ?
									<Tooltip title={i18.t('property_tooltip_delete_property_option')} >
										<RemoveCircle
											style={{cursor: 'default', height: 20, width: 20}}
											color={disabled ? "disabled" : "primary"}
											onClick={()=>{
												property.enum.splice(optionIndex, 1)
												updateProperty(property)
											}} 
											disabled={disabled} 
										/>
									</Tooltip>
								: null }
								
								{ designTime && (hover === optionIndex) && (property.enum.length-1 === optionIndex) ?					
									<Tooltip title={i18.t('property_tooltip_add_property_option')} >
										<AddCircle 
											style={{cursor: 'default', height: 20, width: 20}}
											color={disabled ? "disabled" : "primary"}
											onClick={disabled ? null : ()=>{
												property.enum.push(i18.t('property_option_prefix') + (property.enum.length + 1))
												updateProperty(property)
											}} 
											disabled={disabled} 
										/>
									</Tooltip>
								: null }
								
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
			
		</Grid>
	)
}

export default (PropertySelectSingleEdit)
