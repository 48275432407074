import React, { useState } from 'react'
import PropertyListItem from '../components/PropertyListItem'
import { Grid } from '@mui/material'
import { Droppable, Draggable  } from 'react-beautiful-dnd'


const PropertyList = ({ rootparentType, rootparentId, parentType, parentId, propertyList, selectedProperty, highlightedProperty, 
						designTime, disabled=false, level=0, 
						setSelectedProperty, setHighlightedRule, deleteProperty }) => {

//	console.log('PropertyList', parentType)

	if (!parentId || !propertyList) return null

	return (
		<Grid item id='PropertyList' >

			{ designTime ?
				<Droppable isDropDisabled={!designTime} droppableId={`propertydrop::${parentId}`} type={`${parentType}::${level}`} >
					{(provided, snapshot) => (
						<div ref={provided.innerRef} >					
							{propertyList.map((propertyId, index) =>			
								<Draggable isDragDisabled={!designTime} key={`propertydrag::${parentId}::${propertyId}`} draggableId={`propertydrag::${parentId}::${propertyId}`} index={index} >
									{(provided, snapshot) => (
										<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
											<PropertyListItem
												rootparentType={rootparentType}
												rootparentId={rootparentId}
												propertyId={propertyId}
												selectedProperty={selectedProperty}
												designTime={designTime}
												disabled={disabled}
												level={level}
												deleteProperty={deleteProperty}
												setSelectedProperty={setSelectedProperty}
												setHighlightedRule={setHighlightedRule}
											/>
										</div>
									)}
								</Draggable>
							)}
						{provided.placeholder}
						</div>
					)}
				</Droppable>
			: 
				propertyList.map((propertyId, index) =>			
					<PropertyListItem
						key={index}
						rootparentType={rootparentType}
						rootparentId={rootparentId}
						propertyId={propertyId}
						selectedProperty={selectedProperty}
						designTime={designTime}
						disabled={disabled}
						level={level}
						deleteProperty={deleteProperty}
						setSelectedProperty={setSelectedProperty}
						setHighlightedRule={setHighlightedRule}
					/>
				)
			}
		</Grid>
	)
}

export default PropertyList