import React from 'react'
import { Grid, Card, CardMedia, ListItem, Icon, Stepper, Step, StepLabel, Typography, Hidden } from '@mui/material'
import { CheckBoxOutlineBlank, MoreHoriz } from '@mui/icons-material'
import * as i18 from 'i18next'

const WorkflowListItem = ({ navigation, me, workflow, tasks, taskinstances, retrieveWorkflow }) => {

//	console.log('WorkflowListItem')
	
	if (!workflow) return null
	
	let workflowId = workflow.workflowId
	let startTaskId = workflow.tasks ? workflow.tasks[0] : null

	return (
		<ListItem onClick={()=>{navigation.navigate('workflow', {workflowId: workflow.workflowId, name: workflow.name })}} button>

				<Grid item xs={6} sm={5} md={4} lg={3} >
					<Grid container direction={'row'}>
							
						{ workflow.imageref && workflow.imageref !== null ?
							<Hidden only={['xs', 'sm', 'md']}>
								<Card><CardMedia style={{ height: 20, width: 20 }} image={workflow.imageref} /></Card>
								&nbsp;
							</Hidden>
						: null}
				
						<Typography noWrap >{workflow.name}</Typography>
							
						{ workflow.domain !== me?.domain ?
							<Typography style={{color: 'red'}}>*</Typography>
						:
							null
						}		
						
					</Grid>
				</Grid>

				<Grid item xs={6} sm={7} md={8} lg={9} >
					<Stepper style={{backgroundColor: 'transparent', padding: 5, flex: '0 0 100%'}} >
						{ startTaskId && tasks[workflowId] && tasks[workflowId][startTaskId] && tasks[workflowId][startTaskId].tasks ?
							tasks[workflowId][startTaskId].tasks.map((taskId, index) => {

								if (index > 3) return null
								
								let keys = taskinstances[workflowId] ? Object.keys(taskinstances[workflowId]) : []
								let taskInstances = []
								for (let i=0; i<keys.length; ++i) {
									if (taskinstances[workflowId][keys[i]].taskId === taskId) {
										taskInstances.push(taskinstances[workflowId][keys[i]])
									}
								}
								taskInstances = taskInstances.sort((task1, task2) => Date.parse(task2.updated) - Date.parse(task1.updated))
								let taskInstance = taskInstances[0]
								
								let task = tasks[workflowId][taskId]
								let icon = <CheckBoxOutlineBlank style={{color: 'grey'}}/>
								let name = task ? task.name : ''
								
								if (name && name.length > 15)
									name = `${name.slice(0, 12)}...`
								
								if (taskInstance) {
									if (taskInstance.state === 'working' && taskInstances.length === 1) {
									} else if (taskInstance.state === 'working' && taskInstances.length > 1) {
										icon = <CheckBoxOutlineBlank style={{color: 'green'}}/>
									} else if (taskInstance.state === 'completed') {
										icon = <CheckBoxOutlineBlank style={{color: 'blue'}}/>
									} else if (taskInstance.state === 'canceled') {
										icon = <CheckBoxOutlineBlank style={{color: 'orange'}}/>
									}												
								}
								
								return (
									<Step key={index} active={taskInstance && taskInstance.state === 'working'} completed={taskInstance && taskInstance.state === 'completed'} >
										<StepLabel icon={icon}>{name}</StepLabel>
									</Step>
								)
							})
						: null }

						{ startTaskId && tasks[workflowId] && tasks[workflowId][startTaskId] && tasks[workflowId][startTaskId].tasks?.length > 3 ?
							<Step>
								<StepLabel icon={<MoreHoriz style={{color: 'grey'}}/>} />
							</Step>
						:
							null
						}
							
					</Stepper>
				</Grid>

		</ListItem>
	)
}

export default WorkflowListItem
