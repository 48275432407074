import React, { useState } from 'react'
import { Grid, Box, Tooltip } from '@mui/material'


var mouse = {x: 0, y: 0, startX: 0, startY: 0}
var element
var canvas

const absoluteToProportionalRect = (page, rect, zoom) => {

	if (!page || !rect || !zoom) return null

	let newRect = {
		top: (rect.top / zoom / page.height),
		left: (rect.left / zoom / page.width),
		height: (rect.height / zoom / page.height),
		width: (rect.width / zoom / page.width),
	}

	return newRect
}

const resetTableColumnProps = (page, pageNumber, zoom, block, avgwordheight) => {

	let tableRect = absoluteToProportionalRect(page, block?.rect, zoom)
	let numChildProperties = (block.property.items.properties ? block.property.items.properties.length : 0)
	let colwidth = tableRect.width / numChildProperties								

	if (tableRect) {
		block.property.items.properties.map((prop, index) => {

			if (!prop.extractionRule.key) {prop.extractionRule.key = {rect: {}, words: []}}
			prop.extractionRule.key.rect.top = tableRect.top
			prop.extractionRule.key.rect.left = tableRect.left + (colwidth * index)
			prop.extractionRule.key.rect.width = colwidth
			prop.extractionRule.key.rect.height = avgwordheight
			
			if (!prop.extractionRule.value) {prop.extractionRule.value = {rect: {}, words: []}}
			prop.extractionRule.value.rect.top = prop.extractionRule.key.rect.top + prop.extractionRule.key.rect.height
			prop.extractionRule.value.rect.left = tableRect.left + (colwidth * index)
			prop.extractionRule.value.rect.width = colwidth
			prop.extractionRule.value.rect.height = avgwordheight
		})
	}
}

const adjustOverlayRectToMousePosition = (el, direction, rect) => {

	if (!el || !canvas || !direction || !rect) return null

	let canvasRect = canvas.getBoundingClientRect()
	let x = el.pageX + window.pageXOffset - canvasRect.x + canvas.scrollLeft
	let y = el.pageY + window.pageYOffset - canvasRect.y + canvas.scrollTop

	if (direction === 'nw-resize') {
		rect.top = y
		rect.left = x
		rect.width = rect.right - rect.left
		rect.height = rect.bottom - rect.top
	} else if (direction === 'ne-resize') {
		rect.top = y
		rect.right = x
		rect.width = rect.right - rect.left
		rect.height = rect.bottom - rect.top
	} else if (direction === 'sw-resize') {
		rect.bottom = y
		rect.left = x
		rect.width = rect.right - rect.left
		rect.height = rect.bottom - rect.top
	} else if (direction === 'se-resize') {
		rect.bottom = y
		rect.right = x
		rect.width = rect.right - rect.left
		rect.height = rect.bottom - rect.top
	} else if (direction === 'n-resize') {
		rect.top = y
		rect.height = rect.bottom - rect.top
	} else if (direction === 's-resize') {
		rect.bottom = y
		rect.height = rect.bottom - rect.top
	} else if (direction === 'e-resize') {
		rect.right = x
		rect.width = rect.right - rect.left
	} else if (direction === 'w-resize') {
		rect.left = x
		rect.width = rect.right - rect.left
	}
	
	return rect
}

const getResizeInfo = (el, zoom, overlays, direction, selectedProperty) => {

	if (!el || !overlays || !canvas) return null

	// We only want to allow resizing on the parent or child elements of a selected rule (e.g. table, table-header, table-row, etc)
	const overlays2 = overlays.filter(block2 => block2.property.propertyId === selectedProperty || block2.parentpropertyId === selectedProperty)

	for (const block of overlays2) {

		if (true) {
			// We don't allow resizing of composite regions - you must reset and redraw
			if (block.property.subType === 'object') return null

			let rect = block.rect

			let canvasRect = canvas.getBoundingClientRect()

			let x = el.pageX + window.pageXOffset - canvasRect.x + canvas.scrollLeft
			let y = el.pageY + window.pageYOffset - canvasRect.y + canvas.scrollTop

			if (!direction) {
				let w = (x < (rect.left + 10)) && (x > (rect.left - 10)) && (y < (rect.bottom + 10)) && (y > (rect.top - 10))
				let e = (x > (rect.left + rect.width - 10)) && (x < (rect.left + rect.width + 10)) && (y < (rect.bottom + 10)) && (y > (rect.top - 10))
				let n = (y < (rect.top + 10)) && (y > (rect.top - 10)) && (x < (rect.right + 10)) && (x > (rect.left - 10))
				let s = (y > (rect.top + rect.height - 10)) && (y < (rect.top + rect.height + 10)) && (x < (rect.right + 10)) && (x > (rect.left - 10))
				
				if (block.property.extractionRule.type === 'tablecolumn') {
					if (n && w && block.sizeTop && block.sizeLeft) {direction="w-resize"}
					else if (n && e && block.sizeTop && block.sizeRight) {direction="e-resize"}
					else if (s && w && block.sizeBottom && block.sizeLeft) {direction="w-resize"}
					else if (s && e && block.sizeBottom && block.sizeRight) {direction="e-resize"}
					else if (n && block.sizeTop) {direction="n-resize"}
					else if (s && block.sizeBottom) {direction="s-resize"}
					else if (w && block.sizeLeft) {direction="w-resize"}
					else if (e && block.sizeRight) {direction="e-resize"}
				} else {
					if (n && w && block.sizeTop && block.sizeLeft) {direction="nw-resize"}
					else if (n && e && block.sizeTop && block.sizeRight) {direction="ne-resize"}
					else if (s && w && block.sizeBottom && block.sizeLeft) {direction="sw-resize"}
					else if (s && e && block.sizeBottom && block.sizeRight) {direction="se-resize"}
					else if (n && block.sizeTop) {direction="n-resize"}
					else if (s && block.sizeBottom) {direction="s-resize"}
					else if (w && block.sizeLeft) {direction="w-resize"}
					else if (e && block.sizeRight) {direction="e-resize"}
				}
			}
		}
		
		if (direction) {
			return {direction: direction, block: block}
		}
	}
	
	return null
}

const ImagePaneOverlay = ({ page, pageNumber, zoom, overlays, selectedProp, selectionAttribute, selectedProperty, avgwordheight, wblocks, designTime,
							fuzzyMatchValue, setSelectionAttribute, onSelection, updateProperty, movePropertiesToComposite }) => {

//	console.log("ImagePaneOverlay")

	if (!page) return null

	const [el, setElement] = useState()
	const [resizing, setResizing] = useState()
	const [maxColWidth, setMaxColWidth] = useState()
	const [originalWidth, setOriginalWidth] = useState()
	const [originalTop, setOriginalTop] = useState()

	canvas = document.getElementById('imagepaneoverlay')

	let resizeInfo
	if (resizing) {
		resizeInfo = getResizeInfo(el, zoom, [resizing.block], resizing ? resizing.direction : null, selectedProperty)
	} else {
		resizeInfo = getResizeInfo(el, zoom, overlays, resizing ? resizing.direction : null, selectedProperty)
	}

	const direction = resizeInfo ? resizeInfo.direction : null
	const cursor = direction ? direction : selectionAttribute ? 'crosshair' : 'default'

	if (resizing) {
				
		// Get the general resized dimensions from the block being resized
		resizing.block.rect = adjustOverlayRectToMousePosition(el, direction, resizing.block.rect)

		if (maxColWidth) {
			if (resizing.block.rect && resizing.block.rect.width > maxColWidth) {resizing.block.rect.width = maxColWidth}
		}
		
		if (resizing.block.rect && resizing.block.rect.width < 10) {resizing.block.rect.width = 10}
		
		if (resizing.block.property.extractionRule.type === 'tablecolumn') {

			for (const block2 of overlays) {
				if (block2.property.extractionRule.type === 'tablecolumn') {
					// If the item being resized is a table column then coordinate the resizing of peer elements 
					if (block2.parentpropertyId && block2.parentpropertyId === resizing.block.parentpropertyId) {

						// Adjust the width of items in the column to the right
						if (block2.rect && block2.columnIndex === (resizing.block.columnIndex + 1)) {
							let delta = block2.rect.left - (resizing.block.rect.left + resizing.block.rect.width)
							block2.rect.left = resizing.block.rect.left + resizing.block.rect.width
							block2.rect.width = block2.rect.width + delta
						}

						// Keep the height of all elments in a row the same
						if (block2.rect && resizing.block.rect && block2.attribute === resizing.block.attribute) {
							block2.rect.height = resizing.block.rect.height
							if (block2.attribute === 'key') {
								// If the item being resized is a table column header then coordinate the resizing of the underlying value items
								let blockIndex = overlays.findIndex(block3 => block3.property.propertyId === block2.property.propertyId && block3.attribute === 'value')
								let block3 = overlays[blockIndex]
								block3.rect.top = block2.rect.top + block2.rect.height
							}
						}
						
						// Keep the width of all elements in a column the same
						if (block2.rect && block2.columnIndex === resizing.block.columnIndex) {
							block2.rect.width = resizing.block.rect.width
						}
						
					}
				}
			}
		}
	}

	const onmousedown = (e) => {

		if (designTime) {
			if (!selectionAttribute) {

				setResizing(resizeInfo)
				setOriginalWidth(resizeInfo && resizeInfo.block && resizeInfo.block.rect ? resizeInfo.block.rect.width : null)
				setOriginalTop(resizeInfo && resizeInfo.block && resizeInfo.block.rect ? resizeInfo.block.rect.top : null)

				if (resizeInfo && resizeInfo.block.property.extractionRule.type === 'tablecolumn') {
					let blocksToTheRight = overlays.filter(block => block.columnIndex === (resizeInfo.block.columnIndex + 1))
					if (blocksToTheRight.length > 0) {
						setMaxColWidth(resizeInfo.block.rect.width + blocksToTheRight[0].rect.width - 10)
					}
				}
			}
			
			if (selectionAttribute && !element) {

				let canvasRect = canvas.getBoundingClientRect()
				
				mouse.x = e.pageX + window.pageXOffset - canvasRect.x + canvas.scrollLeft
				mouse.y = e.pageY + window.pageYOffset - canvasRect.y + canvas.scrollTop
				mouse.startX = mouse.x
				mouse.startY = mouse.y

				element = document.createElement('div')
				element.className = 'rectangle'
				element.style.position = 'absolute'
				element.style.left = mouse.x + 'px'
				element.style.top = mouse.y + 'px'
				element.style.border = '2px dashed red'

				canvas.appendChild(element)
			}
		}
	}

	const onmouseup = (e) => {

		if (designTime) {

			if (!selectionAttribute && resizing) {

				if (resizing.block.property.extractionRule.type === 'vtable') {

					let block = overlays.filter(overlay => overlay.property.propertyId === resizing.block.property.propertyId)?.[0]

					block.property.extractionRule[block.attribute].rect = absoluteToProportionalRect(page, block.rect, zoom)

					let widthChange = block?.rect && (block?.rect?.width !== originalWidth) ? true : false
					let topChange = block?.rect && (block?.rect?.top !== originalTop) ? true : false

					if (widthChange || topChange) {
						resetTableColumnProps(page, pageNumber, zoom, block, avgwordheight)
					}
					
					updateProperty(block.property)
					
				} else if (resizing.block.property.extractionRule.type === 'tablecolumn' && resizing.direction !== 'default') {

					let block = overlays.filter(overlay => overlay.property.propertyId === resizing.block.property.propertyId)?.[0]

					// If the item being resized is a table column then coordinate the updating of all peer elements 
					for (const block2 of overlays) {
						if (block2.property.extractionRule.type === 'tablecolumn') {
							if (block2.parentpropertyId && block2.parentpropertyId === resizing.block.parentpropertyId) {

								if (block2.property.extractionRule[block2.attribute]) {
									block2.property.extractionRule[block2.attribute].rect = absoluteToProportionalRect(page, block2.rect, zoom)
									if (wblocks && wblocks.length > 0) {
										block2.property.extractionRule[block2.attribute].words = fuzzyMatchValue(page, block2.property.extractionRule[block2.attribute].rect, wblocks)
									}
								}

								if (block2.property.extractionRule.key) {
									block2.property.name = block2.property.extractionRule.key.words?.join(' ')
									if (block2.property.name) {
										block2.property.pname = block2.property.extractionRule.key.words?.join('_').toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
									}
								}

								if (block2.property.name && block2.property.name.length === 0) {
									block2.property.name = null
									block2.property.pname = null
								}

								updateProperty(block2.property)
							}
						}
					}
				} else if (resizing.block.property.extractionRule.type === 'object') {
					updateProperty(resizing.block.property)
				} else {
					
					// Get the general resized dimensions from the block being resized
					resizing.block.property.extractionRule[resizing.block.attribute].rect = absoluteToProportionalRect(page, resizing.block.rect, zoom)
					if (wblocks && wblocks.length > 0) {
						resizing.block.property.extractionRule[resizing.block.attribute].words = fuzzyMatchValue(page, resizing.block.property.extractionRule[resizing.block.attribute].rect, wblocks)
					}
					if (resizing.block.property.extractionRule.key) {
						resizing.block.property.name = resizing.block.property.extractionRule.key.words?.join(' ')
						if (resizing.block.property.name) {
							resizing.block.property.pname = resizing.block.property.extractionRule.key.words?.join('_').toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
						}
					}
					
					if (resizing.block.property.name && resizing.block.property.name.length === 0) {
						resizing.block.property.name = null
						resizing.block.property.pname = null
					}
					
					updateProperty(resizing.block.property)
				}
			}
		
			if (selectionAttribute && element) {

				let rect = element.getBoundingClientRect()
				
				let canvasRect = canvas.getBoundingClientRect()
				let selectedRect = {
					// Subtracting 3 below is to compensate for the visual effect of the bounding rect that appears larger than what was selected
					left: Math.round((rect.left - canvasRect.x + canvas.scrollLeft) / zoom) / page.width,
					right: (Math.round((rect.right - canvasRect.x + canvas.scrollLeft) / zoom) - 3) / page.width,
					width: (Math.round(rect.width / zoom) - 3) / page.width,

					// Subtracting 3 below is to compensate for the visual effect of the bounding rect that appears larger than what was selected
					top: Math.round((rect.top - canvasRect.y + canvas.scrollTop) / zoom) / page.height,
					bottom: (Math.round((rect.bottom - canvasRect.y + canvas.scrollTop) / zoom) - 3) / page.height,
					height: (Math.round(rect.height / zoom) - 3) / page.height,
				}

				if (canvas.contains(element)) {canvas.removeChild(element)}
				element = null

				onSelection(selectedRect)

				if (selectedProp?.extractionRule?.type === 'object') {	
					// Find all properties contained within the "value" rect and move them to be sub-properties of this composite property
					movePropertiesToComposite(selectedProp)
				}

				// If the item being resized is a 'vtable' then reset all the column property dimensions
				// TBD - redo this to adjust column widths but not reset header / value height
				if (selectedProp?.extractionRule?.type === 'vtable') {

					if (!selectedProp.items.properties) {selectedProp.items.properties = []}
					if (selectedProp.items.properties.length === 0) {
						selectedProp.items.properties.push({
							type: "tablecolumn",
							extractionRule: {page: selectedProp.extractionRule.page, key: {rect: {}, words: []}, value: {rect: {}, words: []}},
						})
					}
					
					selectedProp.items.properties.map((prop, index) => {

						if (!prop.extractionRule.page) {prop.extractionRule.page = selectedProp.extractionRule.page}

						if (!prop.extractionRule.key) {prop.extractionRule.key = {rect: {}, words: []}}
						prop.extractionRule.key.rect.top = selectedRect.top
						prop.extractionRule.key.rect.left = selectedRect.left
						prop.extractionRule.key.rect.width = selectedRect.width
						prop.extractionRule.key.rect.height = avgwordheight

						if (!prop.extractionRule.value) {prop.extractionRule.value = {rect: {}, words: []}}
						prop.extractionRule.value.rect.top = prop.extractionRule.key.rect.top + prop.extractionRule.key.rect.height
						prop.extractionRule.value.rect.left = selectedRect.left
						prop.extractionRule.value.rect.width = selectedRect.width
						prop.extractionRule.value.rect.height = avgwordheight
						
						let bodyHeight = selectedRect.height - prop.extractionRule.key.rect.height
						prop.extractionRule.numIterations = Math.floor(bodyHeight / prop.extractionRule.value.rect.height)
					})
				}
			}

			setMaxColWidth(null)
			setOriginalWidth(null)
			setOriginalTop(null)
			setResizing(null)
			setSelectionAttribute(null)
		}
	}

	const onmousemove = (e) => {
		if (designTime) {
			let el = {}
			
			el.pageX = e.pageX + e.movementX
			el.pageY = e.pageY + e.movementY

			setElement(el)

			if (selectionAttribute && element) {

				let canvasRect = canvas.getBoundingClientRect()

				mouse.x = e.pageX + window.pageXOffset - canvasRect.x + canvas.scrollLeft
				mouse.y = e.pageY + window.pageYOffset - canvasRect.y + canvas.scrollTop

				element.style.left = Math.min(mouse.x, mouse.startX) + 'px'
				element.style.top = Math.min(mouse.y, mouse.startY) + 'px'
				element.style.width = Math.abs(mouse.x - mouse.startX) + 'px'
				element.style.height = Math.abs(mouse.y - mouse.startY) + 'px'
			}
		}
	}

	const imageWidth = document.getElementById('image')?.clientWidth
	const imageHeight = document.getElementById('image')?.clientHeight

//	const imageWidth = document.getElementById('imagepaneP2') ? (document.getElementById('imagepaneP2')?.clientWidth) : null\
//			ref={el => {canvas = el}}
//			style={{position: 'absolute', left: 0, top: 0, cursor: selectionAttribute ? 'crosshair' : 'default', width: imageWidth, height: '100%', overflow: 'hidden'}} 
	return (

		<Grid item
			id='imagepaneoverlay'
			style={{position: 'absolute', left: 0, top: 0, cursor: cursor, width: imageWidth, height: imageHeight}} 
			onMouseDown={onmousedown}
			onMouseUp={onmouseup}
			onMouseMove={onmousemove}
		>
			{/* Put a grid between img and overlays to prevent blue-screen selection from occurring on img during mouse actions */}
			<Grid style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }} />

			{ overlays && overlays.map((block, index) => 
				<Tooltip key={index} title={block.tooltip ? block.tooltip : ''} >
					<Box
						key={index}
						style={{...block.border,
								cursor: cursor,
								position: 'absolute', 
								padding: block.padding,
								margin: block.margin,
								left: block && block.rect && block.rect.left ? block.rect.left : 0,
								top: block && block.rect && block.rect.top ? block.rect.top : 0,
								width: block && block.rect && block.rect.width ? block.rect.width - (2 * block.border.width) : 0,
								height: block && block.rect && block.rect.height ? block.rect.height - (2 * block.border.width) : 0,
								border: `${block.border.width}px ${block.border.style}`,
						}}
					/>
				</Tooltip>					
			)}
			
		</Grid>
		
	)
}

export default ImagePaneOverlay