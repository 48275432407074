import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { Close, Done } from '@mui/icons-material'
import * as i18 from 'i18next'

import ActionButton from '../components/ActionButton'


const LaunchAssignmentToolBar = ({ navigation, workflow, retrieveWorkflow, runWorkflow }) => {

//	console.log('LaunchAssignmentToolBar')

	const [isCanceling, setIsCanceling] = useState(false)
	const [isRunning, setIsRunning] = useState(false)

	return (
		<Grid item id='toolbar' >
			<Grid container direction="row" alignItems='center' >

				<ActionButton tooltipText={i18.t('launch_assignment_cancel_tooltip')} mdUp={i18.t('launch_assignment_cancel')} mdDown={<Close/>} inProgress={isCanceling}
					await onClick={async () => {
						setIsCanceling(true);
						await retrieveWorkflow(workflow.workflowId);
						navigation.pop();
						setIsCanceling(false)
					}}
				/>

				<ActionButton tooltipText={i18.t('launch_assignment_run_tooltip')} mdUp={i18.t('launch_assignment_run')} mdDown={<Done/>} inProgress={isRunning}
					onClick={async () => {
						setIsRunning(true)
						workflow = await runWorkflow(workflow.workflowId, navigation)
						navigation.reset('workflow_working_list')
						navigation.navigate('workflow', {workflowId: workflow.workflowId, name: workflow.name})
						setIsRunning(false)
					}}
				/>
				
			</Grid>
		</Grid>
	)
}

export default LaunchAssignmentToolBar
