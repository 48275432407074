import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'

import Expression from '../Expression'
import ExpressionConst from '../ExpressionConst'
import ExpressionFunc from '../ExpressionFunc'


const AddDeleteTag = ({ me, workflowId, task, attachments, allProperties, documents, milestoneActionIndex, focus, hover, designTime, disabled, updateTask, setFocus }) => {

//	console.log('AddDeleteTag')

	let milestoneAction = task.milestoneActions[milestoneActionIndex]

	return (
		<Grid item >						
			<Grid container direction={'row'} alignItems='center' >

				<Typography>{i18.t(`action_${milestoneAction.type}`)}</Typography>

				&nbsp;&nbsp;&nbsp;
				
				{ milestoneAction.expression?.typeId?.startsWith('const') ?
					<ExpressionConst 
						me={me}
						expressionParentId={`${milestoneAction.actionId}::${milestoneActionIndex}`}
						expression={milestoneAction.expression} 
						allProperties={allProperties} 
						focus={focus}
						hover={hover}
						designTime={designTime}
						disabled={disabled}
						setExpression={(expr) => {
							milestoneAction.expression = expr
							updateTask(workflowId, task)
						}} 
						setFocus={setFocus}
					/>
				: milestoneAction.expression?.typeId?.startsWith('func') ?
					<ExpressionFunc 
						me={me} 
						expressionParentId={`${milestoneAction.actionId}`}
						rootparentId={workflowId} 
						expressionTypes={me?.expressionTypes} 
						expression={milestoneAction.expression} 
						attachments={attachments} 
						documents={documents} 
						groupFilter={['const','func','aop','ref']} 
						allProperties={allProperties} 
						focus={focus} 
						hover={hover} 
						designTime={designTime} 
						disabled={disabled} 
						setExpression={(expr) => {
							milestoneAction.expression = expr
							updateTask(workflowId, task)
						}} 
						setFocus={setFocus} />
				:
					<Expression 
						rootparentId={workflowId}
						expressionTypes={me.expressionTypes} 
						expression={milestoneAction.expression} 
						type={'string'} 
						groupFilter={['const','func','aop','ref']} 
						attachments={attachments}
						allProperties={allProperties} 
						documents={documents}
						hover={hover}
						designTime={designTime}
						disabled={disabled}
						setExpression={(expr) => {
							milestoneAction.expression = expr
							updateTask(workflowId, task)
						}} 
					/>
				}
			</Grid>
		</Grid>
	)
}

export default AddDeleteTag
