import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material'
import * as i18 from 'i18next'

import ExtractSelectionView from '../../containers/ExtractSelectionView'


const ExtractionListDialog = ({ onComplete, closeDialog }) => {

//	console.log('ExtractionListDialog')
	
	const [selectedExtract, setSelectedExtract] = useState()

	return (
		<Dialog maxWidth='md' fullWidth open={true} >
			<DialogTitle><b>{i18.t('dialog_extractionlist_title')}</b></DialogTitle>

			<DialogContent>
				<ExtractSelectionView navigation={navigation} selectedExtract={selectedExtract} onSelect={(extract)=>setSelectedExtract(extract.extractId)} />
			</DialogContent>

			<DialogActions>
				<Button onClick={closeDialog} color="secondary" variant="contained" >{i18.t('dialog_cancel')}</Button>
				<Button disabled={!selectedExtract} onClick={()=>{onComplete(selectedExtract);closeDialog()}} color="primary" variant="contained" >{i18.t('dialog_ok')}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ExtractionListDialog