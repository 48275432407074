import './App.css'
import React, { useState } from 'react'
import { Provider } from 'react-redux'
import { Grid, Button } from '@mui/material'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import reducers from './reducers'
import MainView from './containers/MainView'

import { useGoogleLogin } from '@react-oauth/google';

const middleware = [ thunk ]
export const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)))

const CLIENT_ID = '387159493695-djqsqn9ltpgi3lro5bljk23gqnlrnhee.apps.googleusercontent.com'
const CLIENT_SECRET = 'rU7AEyORE-8fEr77fNoz2u3M'
const DEVELOPER_KEY = 'AIzaSyD5Fo1rJa-rM0n4_Il2I2J8-bNFqiK6_0w'
const SCOPE = 'profile https://www.googleapis.com/auth/contacts.readonly'

export const refreshToken = async (token) => {
	console.log(`refreshing token... ${new Date().toString()}`)
	try {
		const googleSession = JSON.parse(localStorage.getItem('googleSession'))
		if (token) {
			const result = await fetch(`https://api.tournamentbuilders.com/auth/refresh`, {method: "POST", body: JSON.stringify(token), headers: {'Content-Type': 'application/json; charset=UTF-8', 'Authorization': token?.access_token, 'clientId': CLIENT_ID, 'sec-fetch-site': 'cross-site'}})
			const newToken = await result.json()
			if (newToken && newToken.access_token) {
				const newGoogleSession = {...googleSession, ...newToken}
				const expiration = token.expires_in * 1000
				localStorage.setItem('googleSession', JSON.stringify(newGoogleSession))
				localStorage.setItem('googleExpiration', Date.now() + expiration)
				setTimeout(()=>refreshToken(googleSession), expiration)
			} else if (gooleSession) {
				localStorage.setItem('googleSession', null)
				localStorage.setItem('googleExpiration', null)
				window.location.reload()
			}
		}
	} catch(err) {
		console.log('Refresh token error: ', err)
	}
}

//export const refreshTokenSetup = (token) => {
//	const expiration = token.expires_in * 1000
//	setTimeout(()=>refreshToken(token), expiration)
//}

const App = () => {
	
//	console.log('App')

	try {
		const [refresh, setRefresh] = useState()

		const googleSession = JSON.parse(localStorage.getItem('googleSession'))
		const googleExpiration = JSON.parse(localStorage.getItem('googleExpiration'))

		if (googleSession && (googleExpiration > Date.now()) && !refresh) {
			setTimeout(()=>refreshToken(googleSession), googleExpiration - Date.now())
			setRefresh(true)
		}

//console.log('gs', googleSession, window.location)

		if (window.location.host === 'tournamentbuilders.com' || window.location.host === 'www.tournamentbuilders.com') {
			window.location.replace('https://app.tournamentbuilders.com' + window.location.search)
		} else if (window.location.host === 'app.tournamentbuilders.com' && !googleSession && !window.location.search.includes('auth=true')) {
			window.location.replace('https://home.tournamentbuilders.com' + window.location.search)
		} else if (window.location.host === 'app.tournamentbuilders.com' && googleSession && window.location.search.includes('auth=true')) {
			window.location.replace('https://app.tournamentbuilders.com')
		}

		const login = useGoogleLogin({
			scope: SCOPE,
			ux_mode: 'popup',
			flow: 'auth-code',
			responseType: "code",
			select_account: 'true',
			onSuccess: async (resp) => {
				const resp2 = await fetch(`https://api.tournamentbuilders.com/auth`, {method: "POST", body: JSON.stringify(resp)})
				const token = await resp2.json()

				if (token && token.access_token) {
					const expiration = token.expires_in * 1000
					localStorage.setItem('googleSession', JSON.stringify(token))
					localStorage.setItem('googleExpiration', Date.now() + expiration)
//					refreshTokenSetup(token)
					window.location.reload()
				} else if (googleSession) {
					localStorage.setItem('googleSession', null)
					localStorage.setItem('googleExpiration', null)
					window.location.reload()
				}
			},

			onError: (err) => {
				console.log('onError', err)
			},

			onNonOAuthError: (err) => {
				console.log('onNonOAuthError', err)
			}
		})
		
		return (
			<Provider store={store} >
				{ !googleSession ?

                    <Grid container direction='column' alignItems='center' >
						<Button onClick={() => window.location.assign('http://home.tournamentbuilders.com')} alignself='flex-start'>Home</Button>
                        <h1>Login page...</h1>
						<Button onClick={() => login()}>Sign in with Google 🚀</Button>
                    </Grid>
	
				:
					<MainView/>
				}
			</Provider>
		)
	} catch (err) {
		console.log('err:', err)
	}		
}

export default App