import React, { useState } from 'react'
import PropertyList from '../../components/PropertyList'
import { Grid, Button, Icon, Accordion, AccordionSummary, AccordionDetails, Tooltip } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import * as i18 from 'i18next'
import UUID from 'uuidjs'


// Design time view of component
const PropertyCompositeEdit = ({ nameTypeComponent, rootparentType, rootparentId, property, hover, disabled, level, 
								addCompositeProperty, deleteCompositeProperty }) => {

//	console.log('PropertyComposite')

	let propertyId = property.propertyId
	
	const [expanded, setExpanded] = useState(false)
	const [selectedProperty, setSelectedProperty] = useState()

	return (
		<Accordion style={{boxShadow: 'none', backgroundColor: 'transparent'}} expanded={expanded} onChange={(event, expanded)=>{setExpanded(expanded)}} >
			<AccordionSummary style={{padding: 0, backgroundColor: 'transparent'}} expandIcon={<Tooltip title={i18.t('property_expand_tooltip')}>{<ExpandMore/>}</Tooltip>}>
				<Grid container direction='row' >

					<Grid item xs={6} md={5} xl={4}>
						{nameTypeComponent}
					</Grid>
					
					<Grid item style={{paddingLeft: 10}} >
						{ level < 3 ?
							<Grid container direction={'row'} >
								<Icon/>
								<Button 
									style={{justifySelf: "center"}} 
									color="primary" 
									onClick={(e) => {
										let newProp = {propertyId: UUID.genV4().toString(), subType: 'string', type: 'string', control: 'text', maxLength: 1000}
										addCompositeProperty(rootparentId, propertyId, newProp)
										e.stopPropagation()
										setExpanded(true)
									}}
								>
									{i18.t('workflow_add_sub_property')}
								</Button>
							</Grid>
						:
							null 
						}
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails style={{padding: 0}}>	
				<PropertyList 
					rootparentType={rootparentType}
					rootparentId={rootparentId} 
					parentType={'compositeproperty'} 
					parentId={propertyId} 
					propertyList={property.properties}
					selectedProperty={selectedProperty}
					designTime={true}
					disabled={disabled}
					level={level+1} 
					setSelectedProperty={setSelectedProperty}
					deleteProperty={(propertyId)=>deleteCompositeProperty(rootparentId, property.propertyId, propertyId)}
				/>
			</AccordionDetails>
		</Accordion>
	)
}

export default (PropertyCompositeEdit)
