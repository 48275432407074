import React from 'react'
import { connect } from 'react-redux'
import { Grid, Tooltip, Button } from '@mui/material'
import { Refresh } from '@mui/icons-material'
import { DragDropContext } from 'react-beautiful-dnd'
import * as i18 from 'i18next'

import AssignmentToolBar from '../components/AssignmentToolBar'
import Assignment from '../components/Assignment'

import { saveAssignment, completeAssignment, retrieveAssignment, updateAssignment } from '../reducers/assignments'


const AssignmentView = ({ navigation, assignment, properties, retrieveAssignment, saveAssignment, completeAssignment, updateAssignment }) => {

//	console.log('AssignmentView')
	
	if (!assignment) return null

	return (
		<DragDropContext style={{background: 'transparent'}} >

			<AssignmentToolBar
				navigation={navigation}
				assignment={assignment}
				onSave={saveAssignment}
				onComplete={completeAssignment}
				retrieveAssignment={retrieveAssignment}
			/>					
						
			<Assignment
				assignment={assignment}
				updateAssignment={updateAssignment}
			/>
			
		</DragDropContext>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	assignment: state.assignments[props.navigation.params.assignmentId],
	properties: state.properties[props.navigation.params.assignmentId],
})

export default connect(mapStateToProps, { retrieveAssignment, saveAssignment, completeAssignment, updateAssignment })(AssignmentView)
