import React, { useState } from 'react'
import { Grid, Button, TextField, Typography, CardActions, Select, MenuItem, Tooltip, Hidden, Divider } from '@mui/material'
import { GetApp, Publish } from '@mui/icons-material'
import * as i18 from 'i18next'
import UUID from 'uuidjs'
import cloneDeep from 'lodash.clonedeep'

import TagBar from '../components/TagBar'
import PropertyList from '../components/PropertyList'
import ImagePane from '../components/ImagePane'
import DocumentAdvDetailsDialog from '../components/dialogs/DocumentAdvDetailsDialog'
import UppyButton from './UppyButton'


const DocumentDetail = ({ navigation, tags, doc, versions, properties, memberProperties, extractsTagList, designTime, 
						updateDocument, putContent, addProperty, deleteProperty, addCompositeProperty, 
						retrieveVersions, restoreVersion, addTag, deleteTag }) => {
 
//	console.log('DocumentDetail')

	if (!doc) return null

	const [zoom, setZoom] = useState(.5)
	const [selectionAttribute, setSelectionAttribute] = useState()
	const [selectedProperty, setSelectedProperty] = useState()
	const [highlightedRule, setHighlightedRule] = useState()
	const [width, setWidth] = useState(document.getElementById('imagepaneP1')?.clientWidth)
	const [height, setHeight] = useState(document.getElementById('imagepaneP1')?.clientHeight)
	const [pageNumber, setPageNumber] = useState(1)
	const [maxPages, setMaxPages] = useState(1)
	const [detailsDialogIsOpen, setDetailsDialogIsOpen] = useState(false)
	
	let attribute
	let wblocks = []
	let avgwordheight = 0.05

	const page = {
		width: doc?.content?.dimensions ? doc.content.dimensions.width : document.getElementById('imagepaneP1')?.clientWidth,
		height: doc?.content?.dimensions ? doc.content.dimensions.height : document.getElementById('imagepaneP1')?.clientHeight
	}

	const onFileComplete = async (file) => {
		if (file) {
			await putContent(doc.documentId, file)
		}
	}  

	let height2 = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width2 = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 50
	const tabbarHeight = document.getElementById('tabbar')?.clientHeight ? document.getElementById('tabbar')?.clientHeight : 50

	height2 = height2 - (toolbarHeight + tabbarHeight)

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='document-detail' style={{width: width2, height: height2, position: 'absolute'}} >
		
			<Grid container direction={'row'} style={{height: height2}} >
			
				<Grid item id='details' xs={doc.state === 'template' ? 12 : 6} lg={doc.state === 'template' ? 12 : 5} style={{height: `100%`, overflowX: 'auto'}}>

					<Grid container direction={'column'} > 

						<br/>
						
						{/* If there are any validation errors - display them here. */}
						{(doc.validationErrors && doc.validationErrors.length > 0) ?
							doc.validationErrors.map((err, index) => {
								if (err.warning)
									return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
											
								if (err.error)
									return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
							})
						: null }
															
						{/* name */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							autoFocus={true}
							label={i18.t('properties_workflow_name')}
							placeholder={i18.t('workflow_name_placeholder')}
							value={doc.name ? doc.name : ''}
							onChange={(event)=>{doc.name=event.target.value;updateDocument(doc);}}
						/>
						
						<br/>
						
						{/* description */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_description')}
							placeholder={i18.t('workflow_desc_placeholder')}
							value={doc.description?doc.description:''}
							onChange={(event)=>{doc.description=event.target.value;updateDocument(doc)}}
						/>
						
						<br/>

						{/* state */}
						<TextField
							variant='standard'
							sx={{input: {color: doc.state === 'deleted' ? 'red' : null}}}
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_state')}
							value={doc.state ? i18.t(`document_state_${doc.state}`) : ''}
							InputProps={{readOnly: true}}										
						/>

						<br/>

						{/* updated */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_updated')}
							value={doc.updated ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(doc.updated)) : ''}
							InputProps={{readOnly: true}}										
						/>

						{/* advanced details dialog */}
						<CardActions>
							<Button size="small" color="primary" onClick={()=>{setDetailsDialogIsOpen(true)}}>
								{i18.t('dialog_workflow_details')}
							</Button>
							{ detailsDialogIsOpen ?
								<DocumentAdvDetailsDialog
									navigation={navigation}
									doc={doc}
									versions={versions}
									designTime={designTime}
									setDetailsDialogIsOpen={setDetailsDialogIsOpen}
									updateDocument={updateDocument}
									retrieveVersions={retrieveVersions}
									restoreVersion={restoreVersion}
								/>		
							: null }
						</CardActions>
						
						{/* analysis mode */}
						<Grid container direction={'row'} alignItems='center' >
							<Typography style={{marginRight: 10}} >{i18.t('attachment_analyze_type')}</Typography>
							<Select
								variant='standard'						
								value={doc.analysisMode ? doc.analysisMode : 'none'}
								onChange={(e)=>{}}
								onChange={(e)=>{doc.analysisMode=e.target.value;updateDocument(doc);}}
								onClick={(e)=>{e.stopPropagation()}}
							>
								<MenuItem value={'none'}>{i18.t('attachment_analyze_none')}</MenuItem>
								<MenuItem value={'manual'}>{i18.t('attachment_analyze_manual')}</MenuItem>
								<MenuItem value={'auto'}>{i18.t('attachment_analyze_auto')}</MenuItem>
							</Select>
						</Grid>
							
						{/* analysis filter */}
						<Grid container direction={'row'} alignItems='center' >
							<Tooltip title={i18.t('attachment_analysis_filter_tooltip')} >
								<Typography>{i18.t('attachment_analyze_filter')}</Typography>							
							</Tooltip>
							<TagBar 
								tagList={doc.analysisFilter} 
								selectedTags={doc.analysisFilter} 
								allTags={extractsTagList} 
								editMode={true} 
								onUpdate={(selectedTags)=>{doc.analysisFilter=selectedTags;updateDocument(doc)}}
							/>
						</Grid>

						{/* tags */}
						<Grid container direction='row' alignItems='center'>
							<Typography variant='subtitle2'>{i18.t('workflow_tags')}</Typography>
							<TagBar 
								tagList={doc.tags} 
								selectedTags={doc.tags} 
								allTags={tags} 
								editMode={!['canceled','completed'].includes(doc.state)} 
								onUpdate={(selectedTags)=>{doc.tags=selectedTags;updateDocument(doc)}}
							/>
						</Grid>

						{/* add property */}
						<Grid container id='123' direction='row' spacing={4} alignItems='center'>
						
							<Grid item>
								<Typography variant='subtitle2' style={{color: 'grey'}}>{i18.t('workflow_properties')}</Typography>
							</Grid>
							
							{ designTime ?
								<Grid item>
									<Button 
										color="primary" 
										onClick={() => {
											let keys = Object.keys(memberProperties)
											keys?.forEach(key => {
												if (memberProperties[key].subType === 'string') {
													let property = cloneDeep(memberProperties[key])
													property.propertyId = UUID.genV4().toString()
													addProperty(property)
												}
											})
										}}
									>
										{i18.t('workflow_add_property')}
									</Button>
								</Grid>
							: null }

						</Grid>

						{/* property list */}
						<PropertyList 
							rootparentType={'document'}
							rootparentId={doc.documentId} 
							parentType={'documentproperty'} 
							parentId={doc.documentId} 
							propertyList={doc.properties}
							selectedProperty={selectedProperty}
							highlightedRule={highlightedRule}							
							designTime={doc.state === 'template'}
							disabled={false}
							setSelectedProperty={setSelectedProperty}
							setHighlightedRule={setHighlightedRule}
							deleteProperty={deleteProperty}
						/>
						
					</Grid>

				</Grid>

				{ doc.state !== 'template' ?
					<Grid item id='contentpane' xs={6} lg={7} style={{border: '2px solid lightgrey', height: `100%`, overflowX: 'auto'}} >

						<Grid container direction='row' alignItems='center'>

							{/* upload content */}
							{ doc?.state === 'working' && !doc.hasContent ?
								<UppyButton inMemoryFileUpload={true} onFileComplete={onFileComplete}><Publish/>{i18.t('document_upload_content')}</UppyButton>
							: null }

							{/* download content */}
							{ doc?.state === 'working' && doc.hasContent ?
								<Tooltip title={i18.t('assignment_save_tooltip')} >
									<Button><GetApp/>{i18.t('document_download_content')}</Button>
								</Tooltip>
							: null }

							{/* set zoom */}
							<Grid container direction='row' style={{flex: 1}} alignItems='center' justifyContent='center' >
								<Typography>{i18.t('extraction_zoom')}</Typography>
								<Button size='small' style={{padding: 0}} color="primary" onClick={()=>setZoom(Math.max(zoom-.1, .1))} >{i18.t('extraction_zoom_in')}</Button>
								<Typography>{`${Math.round(zoom*100)}%`}</Typography>
								<Button size='small' style={{padding: 0}} color="primary" onClick={()=>setZoom(Math.min(zoom+.1, 5))} >{i18.t('extraction_zoom_out')}</Button>
							</Grid>
							
							{/* select page */}
							{ maxPages > 1 ?
								<Grid container direction='row' style={{flex: 1}} alignItems='center' justifyContent='center' >
									<Typography>{i18.t('extraction_page')}</Typography>
									<Button size='small' style={{padding: 0}} color="primary" disabled={pageNumber<=1} onClick={()=>{setPageNumber(Math.max(1, pageNumber-1))}} >{i18.t('extraction_page_prev')}</Button>
									<Typography>{pageNumber}</Typography>
									<Button size='small' style={{padding: 0}} color="primary" disabled={pageNumber>=maxPages} onClick={()=>{setPageNumber(Math.min(maxPages, pageNumber+1))}} >{i18.t('extraction_page_next')}</Button>
								</Grid>
							: null }
							
						</Grid>

						{/* content view */}
						{ doc?.hasContent ?
							<Grid item id='imagepaneP1' style={{width: document.getElementById('contentpane')?.clientWidth, height: `calc(100vh - 190px)`, position: 'absolute', overflow: 'auto', cursor: selectionAttribute && designTime ? 'crosshair' : 'default' }} >	
								<ImagePane
									content={doc?.content}
									page={page}
									pageNumber={pageNumber}
									zoom={zoom}
									wblocks={wblocks}
									properties={properties}
									selectionAttribute={selectionAttribute}
									selectedProperty={selectedProperty}
									highlightedRule={highlightedRule}
									avgwordheight={avgwordheight}
									designTime={designTime}
									setMaxPages={setMaxPages}
									setWidth={setWidth}
									setHeight={setHeight}
									setSelectionAttribute={setSelectionAttribute}
									updateProperty={(property)=>updateProperty(rootparentId, property)}
									moveToCompositeProperty={(compositePropertyId, propertyId)=>moveToCompositeProperty(rootparentId, compositePropertyId, propertyId)}
								/>
							</Grid>
						:
							<Typography style={{paddingLeft: 30}} >No associated content.</Typography>
						}
						
					</Grid>
				: null }
				
			</Grid>
						
		</Grid>
	)	
}

export default DocumentDetail
