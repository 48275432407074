import React, { useState } from 'react'
import UUID from 'uuidjs'
import { Grid, Typography, Button, Icon, Tooltip, FormGroup, FormControlLabel } from '@mui/material'
import { FormControl, InputLabel, Select, MenuItem, Checkbox, Card, CardMedia } from '@mui/material'
import { Visibility } from '@mui/icons-material'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

import Form from '../components/Form'
import PropertyList from '../components/PropertyList'
import MilestoneActionList from '../components/MilestoneActionList'


const TaskWhat = ({ me, workflow, attachments, allProperties, documents, task, actionlist, designTime,
					updateTask, onMilestoneActionAdd }) => {

//	console.log('TaskWhat')
	
	if (!task) return null

	if (!task.form) {
		task.form = {
			formId: UUID.genV4().toString(), 
			pages: [
				{
					itemId: UUID.genV4().toString(), 
					type: 'page', 
					xs: 12, 
					items: [
						{
							itemId: UUID.genV4().toString(), 
							type: 'container', 
							xs: 12, 
							items: []
						}
					]
				}
			]
		}
	}

	return (
		
		<Grid item xs={12} >
			
			{ task.type === 'start' ?
				<Typography  variant="subtitle1" style={{marginTop: 10}} >{i18.t('task_properties_to_present_launch_label')}</Typography>
			: null }

			{ task.type === 'user' ?
				<Typography  variant="subtitle1" style={{marginTop: 10}} >{i18.t('task_properties_to_present_label')}</Typography>
			: null }

			{ task.type === 'start' || task.type === 'user' ?
				<Form
					workflowId={workflow.workflowId}
					task={task}
					rootparentType={'workflow'} 
					rootparentId={workflow.workflowId}
					form={task.form}
					parentId={task.taskId}
					attachmentList={workflow.attachments}
					attachments={attachments}
					propertyList={workflow.properties} 
					properties={allProperties[workflow.workflowId]} 
					designTime={designTime} 
					updateTask={()=>{updateTask(workflow.workflowId, task)}}
				/>
			: null }

			{ task.type === 'user' ?
				<Grid container direction='row' alignItems='center' >
					<Checkbox
						style={{paddingLeft: 0}}
						checked = {task.showCommentTab ? task.showCommentTab : false}
						onChange = {(event) => {
							task.showCommentTab = event.target.checked
							updateTask(workflow.workflowId, task)
						}}
						disabled = {!designTime}
					/>
					<Typography>{i18.t('task_show_comment_tab_label')}</Typography>
				</Grid>
			: null }
			
			{ task.type === 'milestone' ?
				<Grid container direction={'column'} >				
				
					<FormControl>
						<InputLabel shrink={false} style={{color: 'black'}} >{i18.t('task_add_milestone_actions')}</InputLabel>
						<Select 
							variant='standard'
							disabled={!designTime}
							value={''} 
							multiple={false} 
							onChange={(event) => {
								let action = cloneDeep(event.target.value)
								action.actionId = UUID.genV4().toString()
								delete action.targetTypes
								onMilestoneActionAdd(action)
							}}
							inputProps={{ IconComponent: () => null }}
						>
							{actionlist.map((action, index) => (
								<MenuItem key={index} value={action} >{i18.t(`action_${action.type}`)}</MenuItem>
							))}
							
						</Select>
					</FormControl>
																	
					{(task.milestoneActions===undefined || task.milestoneActions === null || task.milestoneActions.length===0) ? 
						<Typography style={{marginLeft: 10, color: 'orange'}} >{i18.t('task_no_milestone_actions')}</Typography>
					:
						<MilestoneActionList
							me={me}
							workflowId={workflow.workflowId}
							task={task}
							attachments={attachments}
							allProperties={allProperties}
							documents={documents}
							designTime={designTime}
							updateTask={updateTask}
						/>
					}
						
				</Grid>
			: null }

		</Grid>				
	)
}

export default TaskWhat
