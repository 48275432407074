import React, { useState } from 'react'
import { connect } from 'react-redux'

import Attachment from '../components/Attachment'

import { updateAttachment } from '../reducers/attachments'
import { retrieveDocument, createDocument, createDocumentInstance, updateDocument, saveDocument, retrieveContent, putContent, analyzeDocument } from '../reducers/documents'
import { createExtract } from '../reducers/extracts'
import { deleteProperty } from '../reducers/properties'
import { navigate, reset } from '../reducers/appnav'


const AttachmentView = ({ navigate, workflowId, rootparentType, rootparentId, rootparentState, attachment, docs, extractsTagList, slimStyle=false, designTime, disabled, 
							updateAttachment, retrieveDocument, createDocument, createDocumentInstance, updateDocument, saveDocument, 
							retrieveContent, putContent, analyzeDocument, createExtract, deleteProperty }) => {

//	console.log('AttachmentView', attachment)

	if (!attachment) return null

	const navigation = {navigate: navigate, reset: reset}
	
	return (
		<Attachment
			navigation={navigation}
			workflowId={workflowId}
			rootparentType={rootparentType}
			rootparentId={rootparentId}
			rootparentState={rootparentState}
			attachment={attachment}
			docs={docs}
			extractsTagList={extractsTagList}
			slimStyle={slimStyle}
			designTime={designTime}
			disabled={disabled}
			updateAttachment={updateAttachment}
			retrieveDocument={retrieveDocument}
			createDocument={createDocument}
			createDocumentInstance={createDocumentInstance}
			updateDocument={updateDocument}
			retrieveContent={retrieveContent}
			putContent={putContent}
			analyzeDocument={analyzeDocument}
			createExtract={createExtract}
			deleteProperty={deleteProperty}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	attachment: state.attachments?.[props.rootparentId]?.[props.attachmentId],
	docs: state.documents,
	extractsTagList: state.app.extractsTagList,	
	modified: state.attachments?.[props.rootparentId]?.[props.attachmentId]?.modified,
})

export default connect(mapStateToProps, { navigate, updateAttachment, retrieveDocument, createDocument, createDocumentInstance, updateDocument, saveDocument, 
											retrieveContent, putContent, analyzeDocument, createExtract, deleteProperty })(AttachmentView)