import { RECEIVE_ME, USER_TOKEN, UPDATE_ME, UPDATE_SCOPE, RECEIVE_SUPPORTCASES, RECEIVE_ROLES, RECEIVE_CONTACTS, RECEIVE_EXTRACTS, RECEIVE_AUTOMATIONS } from './actiontypes'
import { RECEIVE_DOCUMENTS, RECEIVE_WORKFLOWS, RECEIVE_ASSIGNMENTS, RECEIVE_ERRORS } from './actiontypes'
import { CLEAR_ALL_WORKFLOWS, CLEAR_ALL_ASSIGNMENTS, CLEAR_ALL_ORGS, CLEAR_ALL_SUPPORTCASES } from './actiontypes'
import { store } from '../App'
import { schema, normalize, denormalize } from 'normalizr'
import { Buffer } from "buffer"
import cloneDeep from 'lodash.clonedeep'
import * as i18 from 'i18next'

import { config } from '../App'
import { memberFragment, limitedOrgEntityFragment, settingFragment, orgentityTopFragment, orgentityMinFragment, orgentityMaxFragment, permissionFragment } from '../reducers/graphql'
import { propertyFragment, expressionFragment, expressionBaseFragment, roleFragment, extractionRuleFragment } from '../reducers/graphql'
import { removeMeta, removeValueAndDefault, removeArrayProperties, propertySchema } from '../reducers/workflows'
import { getInstanceDataFromValues, setValuesFromInstanceData, setDefaultsFromDefaultInstanceData } from '../reducers/workflows'
import { retrieveWorkflow } from '../reducers/workflows'
import { retrieveWorkflows } from '../reducers/workflows'
import { retrieveAssignments } from '../reducers/assignments'
import { retrieveOrgs } from '../reducers/orgs'
import { retrieveSupportCases } from '../reducers/supportcases'
import { gqlExec } from '../reducers/graphql'
import { resources } from '../reducers/localization.js'


const DEVELOPER_KEY = 'AIzaSyD5Fo1rJa-rM0n4_Il2I2J8-bNFqiK6_0w'

i18.init({
	lng: navigator.language,
	debug: true,
	resources: resources,	
})

const extractSchema = new schema.Entity(
	'extracts', 
	{
	}, 
	{
		idAttribute: value => value.extractId
	}
)

let id = 10000
export function getUniqueId(): string {
  return id++ + ''
}

// App Functions

/*
export const setToken = (accessToken, idToken, email) => dispatch => {
	dispatch({type: USER_TOKEN, token: accessToken})	
}
*/

export const postMeUpdate = (me) => dispatch => {

	if (!me) return 

	// Normalize the properties
	let properties={}
	let extracts={}
	
	if (me) {

		// Normalize properties
		if (me.properties) {
			let propertydata = normalize(me.properties, [propertySchema])
			properties[me.orgentityId] = propertydata.entities.properties
			me.properties = propertydata.result
		}

		
		// Normalize extracts
		if (me.extracts) {
			for (let i=0; i<me.extracts.length; ++i) {
				let extract = me.extracts[i]
				if (extract.properties) {
					if (extract.instanceData) {
						extract.properties.forEach(property => {
							setValuesFromInstanceData(property, extract?.instanceData?.[property.pname])
							setDefaultsFromDefaultInstanceData(property, extract?.defaultInstanceData?.[property.pname])
						})
					}

					let propertydata = normalize(extract.properties, [propertySchema])
					properties[extract.extractId] = propertydata.entities.properties
					extract.properties = propertydata.result
				}
			}

			let extractdata = normalize(me.extracts, [extractSchema])
			me.extracts = extractdata.result
			extracts = extractdata.entities.extracts										
		}
						
	}

	dispatch({type: RECEIVE_ME, me: me, properties: properties, extracts: extracts})
}

export const retrieveMe = (tryRefresh=false) => async dispatch => {
	
//	console.log('retrieveMe')
	
	try {		
		document.body.style.cursor = 'wait'

console.log('fetch me', tryRefresh)
		const response = await gqlExec(`query RetrieveMe {me{...orgentityFields}} ${settingFragment} ${orgentityMaxFragment} ${propertyFragment} ${expressionFragment} ${expressionBaseFragment} ${extractionRuleFragment} ${permissionFragment} ${limitedOrgEntityFragment} `)

		dispatch(postMeUpdate(response.me))

	} catch(err) {
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: [err]})
console.log('exception', tryRefresh, err)
		if (tryRefresh) {
//			await refreshToken(JSON.parse(localStorage.getItem('googleSession')))
			dispatch(retrieveMe(false))
		}
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const updateMe = (me, scope) => dispatch => {
	dispatch({type: UPDATE_ME, me: me})	
}

export const updateScope = (scope) => dispatch => {

	dispatch({type: UPDATE_SCOPE, scope: scope})	

//	dispatch({type: CLEAR_ALL_ASSIGNMENTS})
//	dispatch({type: CLEAR_ALL_WORKFLOWS})
//	dispatch({type: CLEAR_ALL_ORGS})
//	dispatch({type: CLEAR_ALL_SUPPORTCASES})
		
	dispatch(retrieveAssignments('workingAssignments'))
	dispatch(retrieveWorkflows('draftWorkflows'))
	dispatch(retrieveWorkflows('templateWorkflows'))
	dispatch(retrieveWorkflows('workingWorkflows'))
	dispatch(retrieveAssignments('completedAssignments'))
	dispatch(retrieveWorkflows('completedWorkflows'))
	dispatch(retrieveSupportCases('openSupportCases'))
	dispatch(retrieveSupportCases('closedSupportCases'))
	dispatch(retrieveOrgs())
	
}

export const saveMe = (me) => async dispatch => {
	
//	console.log('saveMe', me)
	
	try {		
		document.body.style.cursor = 'wait'

		// Make a deep copy
		me = cloneDeep(me)

		delete me.type
		delete me.subEntities
		delete me.expressionTypes
		delete me.terms
		delete me.name
		delete me.domain
		delete me.principalId
		delete me.email
		delete me.version
		delete me.properties	// TBD - need properties when we allow editing/adding properties
		
		delete me.fullName
		delete me.thumbnailPhotoUrl
		delete me.resourceName
		delete me.isSuperAdmin

		removeMeta(me, 'validationErrors')
		removeMeta(me, 'created')			
		removeMeta(me, 'createdBy')			
		removeMeta(me, 'updatedBy')			
		removeMeta(me, 'version')	
		removeMeta(me, 'parentpropertyId')				
		removeMeta(me, 'modified')
				
		let allProperties = store.getState().properties
		let allExtracts = store.getState().extracts
		
		// De-normalize member properties
		let properties = allProperties[me.orgentityId]
		me.properties = denormalize(me.properties, [propertySchema], {properties: properties})
	
 		me.settings = me.settings.map(setting => {delete setting.group; delete setting.valueOptions; return setting})
				
		// Issue the graphql mutation
		const response = await gqlExec(`mutation {updateOrgEntity (input: ${JSON.stringify(me)}){orgentityId}}`)

		// Use the retrieveMe method to refresh since we need to get both the member and the org to update with
		dispatch(retrieveMe()) //TBD - return the entire member from the update and dispatch that change only					
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const acceptTerms = (version) => async dispatch => {
	
//	console.log('acceptTerms')
	
	try {		
		document.body.style.cursor = 'wait'

		// Issue the graphql mutation
		const response = await gqlExec(`mutation AcceptTerms {acceptTerms (version: "${version}") {...orgentityTopFields}} ${orgentityTopFragment} ${orgentityMaxFragment} ${settingFragment} ${propertyFragment} ${expressionFragment} ${expressionBaseFragment} ${extractionRuleFragment} ${permissionFragment} ${limitedOrgEntityFragment} `)

		dispatch(postMeUpdate(response.acceptTerms))
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const retrieveRoles = () => async dispatch => {
	
//	console.log('retrieveRoles')
	
	try {		
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`query RetrieveRoles {roles{...roleFields}} ${roleFragment} `)

		dispatch({type: RECEIVE_ROLES, roles: response.roles})

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const retrieveContacts = (ignoreExpiration=false) => dispatch => {

//	console.log('retrieveContacts')
	
	try {		

		if (!ignoreExpiration) {
			let contactsExpiration = store.getState().app.contactsExpiration
			if (contactsExpiration && contactsExpiration > Date.now()) {return}
		}
		
		let googleSession = JSON.parse(localStorage.getItem('googleSession'))

		if (googleSession) {
			let headers = {
				headers: {
					'Authorization': `Bearer ${googleSession.access_token}`,
				}, 
			}

			let url = `https://content-people.googleapis.com/v1/people/me/connections?personFields=names%2CemailAddresses%2Cphotos&key=AIzaSyD5Fo1rJa-rM0n4_Il2I2J8-bNFqiK6_0w`

			fetch(url, headers).then(result => {
				const buffers = []
				let reader = result.body.getReader()
				reader.read().then(function processText({done, value}) {
					if (done) {

						let result = Buffer.concat(buffers).toString()

						result = JSON.parse(result)

						let contacts = result.connections && result.connections.map(item => ({
							resourceName: item.resourceName,
							fullName: item.names && item.names[0] ? item.names[0].name : null,
							email: item.emailAddresses && item.emailAddresses[0] ? item.emailAddresses[0].value : null,
							thumbnailPhotoUrl: item.photos && item.photos[0] ? item.photos[0].url : null,
						}))

						dispatch({type: RECEIVE_CONTACTS, contacts: contacts, contactsExpiration: Date.now() + 60000})

						return
					}
					buffers.push(Buffer.from(value))
					return reader.read().then(processText);
				}).catch(err => {  
					console.log(err);  
				})
			}).catch(err => {
				console.log(err)
			})
		}
	}
	catch(err) {		
		console.log(err)
	}
}


const initialState = {}
let list = {}

const app = (state=initialState, action) => {
	switch (action.type) {

// App Events

		case RECEIVE_ME:
			state = {...state, 
				me: action.me
			}
			break

		case UPDATE_ME:
			state = {...state, 
				me: action.me
			}
			break

		case UPDATE_SCOPE:
			state = {...state, 
				scope: action.scope
			}
			break
			
		case USER_TOKEN:
			state = {...state,
				token: action.token
			}
			break
			
		case RECEIVE_ROLES:
			state = {...state, 
				roles: action.roles
			}
			break

		case RECEIVE_CONTACTS:
			state = {...state, 
				contacts: action.contacts,
				contactsExpiration: action.contactsExpiration,
			}
			break

		case RECEIVE_ASSIGNMENTS:
		case RECEIVE_DOCUMENTS:
		case RECEIVE_WORKFLOWS:
		case RECEIVE_EXTRACTS:
		case RECEIVE_SUPPORTCASES:
		case RECEIVE_AUTOMATIONS:

			state[action.listType + 'Count'] = action.listCount
			state[action.listType + 'TagList'] = action.tagList

			switch (action.listType) {
				case 'draftWorkflows':
				case 'templateWorkflows':
				case 'workingWorkflows':
				case 'completedWorkflows':
				case 'workingAssignments':
				case 'completedAssignments':
				case 'extracts':
				case 'workingDocuments':
				case 'templateDocuments':
				case 'automations':
				case 'openSupportCases':
				case 'closedSupportCases':
					const type = action.listType + 'List'
					if (!state[type]) {state[type]=[]}
					if (action.list) {
//						state[action.listType + 'List'].splice(action.skip, action.list.length, ...action.list)
						for (let i=state[type].length; i<action.skip; ++i) {
							state[type].splice(i, 1, null)
						}
						for (let i=0; i<action.list.length; ++i) {
							state[type].splice(action.skip + i, 1, action.list[i])
						}
					}
				break					
			}
			state.modified = Date.now()
			break

		default:
			break
	}

	return state
}

export default app


