import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import * as i18 from 'i18next'


const SupportCloseDialog = ({ closeSupportCase, setDialogIsOpen }) => {

	return (
		<Dialog style={{flex: 1}} open={true} >
			<DialogTitle><b>{i18.t('dialog_delete_workflow_title')}</b></DialogTitle>
			<DialogContent style={{flex: 1}}>
				{i18.t('supportcase_close_confirmation')}
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setDialogIsOpen(false)} color="secondary">
					{i18.t('dialog_cancel')}
				</Button>
				<Button onClick={()=>{setDialogIsOpen(false);closeSupportCase()}} color="primary">
					{i18.t('dialog_close')}
				</Button>
			</DialogActions>
		</Dialog>	
	)
}

export default SupportCloseDialog