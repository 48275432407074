import React from 'react'
import { connect } from 'react-redux'

import WorkflowTasks from '../components/WorkflowTasks'
import { addTask } from '../reducers/tasks'
import { setExpanded } from '../reducers/appnav'


const WorkflowTasksView = ({ workflow, tasks, taskinstances, assignments, designTime, addTask, setExpanded }) => {

//	console.log('WorkflowTasksView')

	if (!workflow) return null

	return (	
		<WorkflowTasks
			workflow={workflow}
			tasks={tasks}
			taskinstances={taskinstances}
			assignments={assignments}
			designTime={designTime}
			addTask={addTask}
			setExpanded={setExpanded}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	workflow: state.workflows[props.workflowId],
	tasks: state.tasks[props.workflowId],
	taskinstances: state.taskinstances[props.workflowId],
	assignments: state.assignments,
})

export default connect(mapStateToProps, { addTask, setExpanded })(WorkflowTasksView)
