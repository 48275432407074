import workflow_empty from './workflow_empty.json'
import testfragments from './testfragments'


const stringify = (str) => {
	return JSON.stringify(str).replace(/"(\w+)"\s*:/g, '$1:')
}

const workflowdiscussion = [{
	name: "Workflow discussion test collection",
	tests: [
		{
			// create draft workflow from base workflow template and test various updates
			id: 'WFD2-SETUP',
			name: 'create base workflow.',
			type: 'setup',
			operation: `mutation {createWorkflow {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [

				// nullcomment
				{
					id: 'WFD2-0001',
					name: 'nullcomment',
					type: 'negative',
					operation: `mutation {postComment (sourceType: "workflow", sourceId: "<<workflowId>>", comment: ${null}) {commentId comment}}`, 
					assertResultIncludes: [`"message":"Invalid comment."`]
				},

				// validcomment
				{
					id: 'WFD2-0002',
					name: 'validcomment',
					type: 'positive',
					operation: `mutation {postComment (sourceType: "workflow", sourceId: "<<workflowId>>", comment: "${testfragments.text100}") {commentId comment}}`, 
					assertResultIncludes: [`"comment":"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"`]
				},

				// invalidcomment
				{
					id: 'WFD2-0003',
					name: 'invalidcomment',
					type: 'negative',
					operation: `mutation {postComment (sourceType: "workflow", sourceId: "<<workflowId>>", comment: "${testfragments.text1001}") {commentId comment}}`, 
					assertResultIncludes: [`Comment exceeds max length.`]
				},

				// validandinvalidsubcomment
				{
					id: 'WFD2-0004',
					name: 'validcomment',
					type: 'positive',
					operation: `mutation {postComment (sourceType: "workflow", sourceId: "<<workflowId>>", comment: "${testfragments.text100}") {commentId comment}}`, 
					assertResultIncludes: [`"comment":"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"`],
					onEachItem: [

						{
							id: 'WFD2-0004a',
							name: 'validsubcomment',
							type: 'positive',
							operation: `mutation {postComment (sourceType: "workflow", sourceId: "<<workflowId>>", parentCommentId: "<<commentId>>", comment: "${testfragments.text100}") {comment}}`, 
							assertResult: `{"comment":"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"}`,
						},

						{
							id: 'WFD2-0004b',
							name: 'invalidsubcomment',
							type: 'negative',
							operation: `mutation {postComment (sourceType: "workflow", sourceId: "<<workflowId>>", parentCommentId: "<<commentId>>", comment: "${testfragments.text1001}") {comment}}`, 
							assertResultIncludes: [`Comment exceeds max length.`]
						},

					]
				},


				// now delete the draft workflow
				{
					id: 'WFD2-CLEANUP',
					name: 'delete base workflow',
					type: 'cleanup',
					operation: `mutation {deleteWorkflow (workflowId: "<<workflowId>>") {state}}`, 
				},

			]
		},

	],
}]

export default workflowdiscussion

