import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Typography, Select, MenuItem } from '@mui/material'
import * as i18 from 'i18next'


const SupportCreateDialog = ({ supportcase, onSubmit, closeDialog }) => {

//	console.log('SupportCreateDialog')
	
	const [severity, setSeverity] = useState(supportcase ? supportcase.severity : 3)
	const [summary, setSummary] = useState(supportcase ? supportcase.summary : null)
	const [detail, setDetail] = useState(supportcase ? supportcase.detail : null)

	let submitEnabled = summary && detail && summary.length > 0 && detail.length > 0
	
	return (
		<Dialog maxWidth='sm' fullWidth open={true} >
			<DialogTitle><b>{i18.t('dialog_support_title')}</b></DialogTitle>
			<DialogContent>
				<Grid container direction={'column'}>
				
					<Grid container direction={'row'} alignItems='center' >
						<Typography style={{marginRight: 10}} >Severity:</Typography>
						<Select
							value={severity}
							onChange={(e)=>setSeverity(e.target.value)}
						>
							<MenuItem value={1}>{i18.t('supportcase_severity_1')}</MenuItem>
							<MenuItem value={2}>{i18.t('supportcase_severity_2')}</MenuItem>
							<MenuItem value={3}>{i18.t('supportcase_severity_3')}</MenuItem>
						</Select>
					</Grid>

					<br/>

					<Typography>{i18.t('dialog_support_intro')}</Typography>
					
					<br/>

					<TextField
						InputLabelProps={{shrink: true}}
						label={i18.t('dialog_support_summary_desc')}
						onChange={(event) => {setSummary(event.target.value)}}
						required
						value={summary ? summary : ''}
					/>
					
					<br/><br/>
					
					<TextField
						InputLabelProps={{shrink: true}}
						label={i18.t('dialog_support_detail_desc')}
						onChange={(event) => {setDetail(event.target.value)}}
						multiline={true}
						rows={4}
						required
						value={detail ? detail : ''}
					/>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog} color="secondary" variant="contained" >{i18.t('dialog_cancel')}</Button>
				<Button disabled={!submitEnabled} onClick={()=>{onSubmit(severity, summary, detail);closeDialog()}} color="primary" variant="contained" >{i18.t('dialog_submit')}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SupportCreateDialog