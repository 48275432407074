import { RECEIVE_ERRORS } from './actiontypes'
import { gqlExec } from '../reducers/graphql'
import { retrieveAssignment } from '../reducers/assignments'
import { retrieveAutomation } from '../reducers/automations'
import { retrieveDocument } from '../reducers/documents'
import { retrieveExtract } from '../reducers/extracts'
import { retrieveSupportCase } from '../reducers/supportcases'
import { retrieveWorkflow } from '../reducers/workflows'

export const addTag  = (sourceType, sourceId, tag, setStatusIndicator) => async dispatch => {
	
//	console.log('addTag', tag);
	
	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`mutation {addTag (sourceType: "${sourceType}", sourceId: "${sourceId}", tag: "${tag}") {tag}}`)
		
		switch (sourceType) {
			case 'assignment': dispatch(retrieveAssignment(sourceId)); break
			case 'automation': dispatch(retrieveAutomation(sourceId)); break
			case 'document': dispatch(retrieveDocument(sourceId, sourceType, sourceId)); break
			case 'extract': dispatch(retrieveExtract(sourceId)); break
			case 'supportcase': dispatch(retrieveSupportCase(sourceId)); break
			case 'workflow': dispatch(retrieveWorkflow(sourceId)); break
			default: break
		}
	}
	catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}

export const deleteTag  = (sourceType, sourceId, tag, setStatusIndicator) => async dispatch => {
	
//	console.log('deleteTag', tag);
	
	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`mutation {deleteTag (sourceType: "${sourceType}", sourceId: "${sourceId}", tag: "${tag}") {tag}}`)
		
		switch (sourceType) {
			case 'assignment': dispatch(retrieveAssignment(sourceId)); break
			case 'automation': dispatch(retrieveAutomation(sourceId)); break
			case 'document': dispatch(retrieveDocument(sourceId, sourceType, sourceId)); break
			case 'extract': dispatch(retrieveExtract(sourceId)); break
			case 'supportcase': dispatch(retrieveSupportCase(sourceId)); break
			case 'workflow': dispatch(retrieveWorkflow(sourceId)); break
			default: break
		}
	}
	catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}


const initialState = {
}

const tags = (state = initialState, action) => {
	switch (action.type) {
		
// Tag Events

		default:
			break
	}

	return state;
}

export default tags