import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'

import DocumentDetailView from '../containers/DocumentDetailView'
import PermissionsView from '../containers/PermissionsView'
import DiscussionView from '../containers/DiscussionView'
import TabBar from '../components/TabBar'


const Document = ({ navigation, doc, hasDetailErrors, hasDetailWarnings, retrieveDocument }) => {
 
//	console.log('Document')

	if (!doc) return null

	const [tab, setTab] = useState('tab_details')
	const [retrieveAttempted, setRetrieveAttempted] = useState(false)

	const designTime = ['template'].includes(doc.state)
	const disabled = ['deleted'].includes(doc.state)

	let tabValues = ['tab_details','tab_permissions','tab_discussion']
	let tabLabels = [
		<Typography style={{color: hasDetailErrors ? 'red' : hasDetailWarnings ? 'orange' : ''}}>{i18.t('tab_details')}</Typography>,
		i18.t('tab_permissions'),
		i18.t('tab_discussion')]

	if (!doc.created && !retrieveAttempted) {
		retrieveDocument(doc.documentId)
		setRetrieveAttempted(true)
	}

	return (
			
		<Grid item id='document'>

			<TabBar tabValues={tabValues} tabLabels={tabLabels} tab={tab} setTab={setTab} />	
			
			{ tab === 'tab_details' ?
				<DocumentDetailView
					navigation={navigation}
					documentId={doc.documentId}
					designTime={designTime}
				/>
			: tab === 'tab_permissions' ?
				<PermissionsView
					sourceType={'document'}
					sourceId={doc.documentId}
					designTime={designTime}
				/>
			: tab === 'tab_discussion' ?
				<DiscussionView
					sourceType={'document'}
					sourceId={doc.documentId}
				/>
			: null }
			
		</Grid>
			
	)
}

export default Document
