import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Typography } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'
import * as i18 from 'i18next'

import Document from '../components/Document'
import DocumentToolBar from '../components/DocumentToolBar'

import { moveProperty, moveCompositeProperty } from '../reducers/properties'
import { retrieveDocument, retrieveContent, saveDocument, saveDocumentAs, deleteDocument, denormalizeDocument, createPropertiesFromExtract, analyzeDocument, createDocumentInstance } from '../reducers/documents'


const onDragEnd = (documentId, result, moveProperty, moveCompositeProperty) => {
	if (result.source && result.destination) {
		let type = result.type.split('::')[0]		
		let droppableId = result.destination.droppableId.split('::',2)[1]
		if (type === 'documentproperty') {
			moveProperty(documentId, result.source.index, result.destination.index)
		} else if (type === 'compositeproperty') {
			moveCompositeProperty(documentId, droppableId, result.source.index, result.destination.index)
		}
	}
}

const hasPropertyValidationMarks = (propertylist, properties, type='error') => {
	if (propertylist) {
		for (const propertyId of propertylist) {
			let property = properties[propertyId]
			if (property) {
				if (property.validationErrors && property.validationErrors.filter(err => err[type]).length > 0) {return true}
				if (property.properties) return hasPropertyValidationMarks(property.properties, properties)
			}
		}
	}
	return false
}

const DocumentView = ({ navigation, doc, workflowId, properties=[],
						moveProperty, moveCompositeProperty, retrieveDocument, retrieveContent, saveDocument, saveDocumentAs, deleteDocument,
						createPropertiesFromExtract, analyzeDocument, createDocumentInstance }) => {
 
//	console.log('DocumentView')

	const [contentRetrieveAttempted, setContentRetrieveAttempted] = useState(false)

	if (doc && !doc.content && doc.hasContent && !contentRetrieveAttempted) {retrieveContent(doc.documentId, 'workflow', workflowId);setContentRetrieveAttempted(true);}

	let documentId = doc?.documentId

	let hasDocumentErrors = doc?.validationErrors && doc?.validationErrors.filter(err => err.error).length > 0
	let hasDocumentWarnings = doc?.validationErrors && doc?.validationErrors.filter(err => err.warning).length > 0

	let hasPropertyErrors = hasPropertyValidationMarks(doc?.properties, properties, 'error')
	let hasPropertyWarnings = hasPropertyValidationMarks(doc?.properties, properties, 'warning')

	const exportDocument = (documentId) => {
		let doc = denormalizeDocument(documentId)
		const blob = new Blob([JSON.stringify(doc, null, 2)], {type: "application/json"})
		const element = document.createElement("a")
		element.href = URL.createObjectURL(blob)
		element.download = `${doc.name}.json`
		document.body.appendChild(element)
		element.click()
	}

	const height = document.getElementById('main')?.clientHeight ? document.getElementById('main')?.clientHeight : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 40
	const width = document.getElementById('main')?.clientWidth ? document.getElementById('main')?.clientWidth : 0

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<DragDropContext style={{background: 'transparent'}} onDragEnd={(result)=>{onDragEnd(documentId, result, moveProperty, moveCompositeProperty)}} >

			<DocumentToolBar
				navigation={navigation}
				doc={doc}
				workflowId={workflowId}
				hasErrors={hasDocumentErrors || hasPropertyErrors}
				saveDocument={saveDocument}
				saveDocumentAs={saveDocumentAs}
				deleteDocument={deleteDocument}
				exportDocument={exportDocument}
				createPropertiesFromExtract={createPropertiesFromExtract}
				analyzeDocument={analyzeDocument}
				createDocumentInstance={createDocumentInstance}
				retrieveDocument={(workflowId)=>retrieveDocument(documentId, 'workflow', workflowId)}
			/>					
			
			{ doc ?
				<Grid container id='outer-extract' style={{width: width, height: (height - toolbarHeight)}} >
					<Document
						navigation={navigation}
						doc={doc}
						workflowId={workflowId}
						hasDetailErrors={hasDocumentErrors || hasPropertyErrors}
						hasDetailWarnings={hasDocumentWarnings || hasPropertyWarnings}
						designTime={['template','working'].includes(doc.state)}
						retrieveDocument={retrieveDocument}
					/>			
				</Grid>
			:
				<Typography style={{padding: 40}} >Document Unavailable</Typography>
			}
			
		</DragDropContext>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	doc: state.documents[props.navigation.params.documentId],
	workflowId: props.navigation.params.workflowId,
	properties: state.properties[props.navigation.params.documentId],
})

export default connect(mapStateToProps, { moveProperty, moveCompositeProperty, retrieveDocument, retrieveContent, saveDocument, saveDocumentAs, deleteDocument,
													createPropertiesFromExtract, analyzeDocument, createDocumentInstance })(DocumentView)
