import React, { useState } from 'react'
import { Grid, Typography, Switch, TextField } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'
import { red } from '@mui/material/colors'

import { getUniqueId } from '../reducers/app'


const exprDisplayText = (expr) => {
	if (expr && expr.typeId) {
		if (expr.typeId.startsWith('ref::')) {
			return expr.typeId.split('::')[1]
		} else {
			return i18.t(`expression_${expr.typeId}`.replace(/[^A-Za-z0-9]/ig, "_"))
		}
	}
}

// Number('$-1,123.01'.replace(/[^0-9.-]+/g, ""))	// replace everything but numeric characters (different for EU/euro currency - get func from chatgpt)

const validValue = (value, pattern) => {
	if (!value) return false
	if (!pattern) return true
	return (new RegExp(pattern, 'g')).test(value)
}

const validNumber = (value) => {
	if (!value) return false
	return /^[$0-9.,]*$/.test(value)
}

const ExpressionConst = ({ me, expressionParentId, strictMode=false, expression, allProperties, focus, hover, designTime, disabled, setExpression, setFocus }) => {
	
//	console.log('ExpressionConst')

	if (!expression) return null

	const type = strictMode ? expression.subType : expression.type
	const properties = allProperties?.[me?.orgentityId]
	const propertyId = me?.properties?.filter(propertyId => properties?.[propertyId]?.subType === type)[0]
	const property = properties?.[propertyId]

	let textStyle = {}
	if (property) {
		if (expression.value && !validValue(expression.value, property.pattern)) {
			textStyle = {backgroundColor: red[50]}
		}
	}

	let result = []

	expression.typeId === 'const::boolean' ?
		result.push(
			<Grid item key={getUniqueId()}>
				<Grid container direction='row' alignItems='center' >
					<Typography>False</Typography>
					<Switch 
						checked={expression.value === 'true'} 
						onChange={(e) => {
							expression.value = e.target.checked ? 'true' : 'false'
							setExpression(expression)
							setFocus(expressionParentId)
						}} 
					/>
					<Typography>True</Typography>
				</Grid>
			</Grid>
		)
	:
		result.push(
			<TextField 
				key={getUniqueId()}
				variant='standard'
				disabled={disabled}
				autoFocus={focus === expressionParentId}
				size='small'
				style={{...textStyle, width: 140}}
				placeholder={exprDisplayText(expression)}
				onChange={(e) => {
					expression.value = e.target.value
					setExpression(expression)
					setFocus(expressionParentId)
				}}
				onClick={(e) => {
					setFocus(expressionParentId)
				}}
				value={expression.value ? expression.value : ''}
			/>
		)
			
	designTime && expression && hover ?
		result.push(<RemoveCircle style={{cursor: 'default', height: 20, width: 20}} key={getUniqueId()} onClick={()=>setExpression(null)} />)
	: null		

	return result		
}

export default ExpressionConst
