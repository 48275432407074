import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import cloneDeep from 'lodash.clonedeep'

import Expression from '../components/Expression'
import ExpressionConst from '../components/ExpressionConst'
import ExpressionFunc from '../components/ExpressionFunc'
import { getUniqueId } from '../reducers/app'

const ExpressionTuple = ({ me, expressionParentId, rootparentId, expressionTypes, expression, type, subType,
							leftGroupFilter, middleGroupFilter, rightGroupFilter, 
							attachments, allProperties, documents, strictMode=false, top=false, focus, hover, designTime=false, disabled=false,
							setExpression, onDelete, setFocus }) => {
	
//	console.log('ExpressionTuple')

	if (!expression) top ? expression = { "typeId": "ass::eq", "type": "any", "operator": "=" } : expression = { "typeId": "cop::eq", "type": "boolean", "operator": "==" }

	let setLeft = (expr) => {
		expression.left = cloneDeep(expr)
		if (expression.right) {
			if (expression.left.type !== expression.right.type) {
				expression.right = null
			} else {
				expression.right.subType = expression.left?.subType
			}
		}
		setExpression(expression)
	}
	
	let setRight = (expr) => {
		expression.right = cloneDeep(expr)
		if (expression.right) {
			expression.right.subType = expression.left?.subType
		}
		setExpression(expression)
	}

	let setOperator = (expr) => {
		expression.typeId = expr.typeId
		setExpression(expression)
	}	
	
	const left = expression?.left
	const middle = expression?.middle
	const right = expression?.right

	let result = []

	!top ?
	result.push(<Typography key={getUniqueId()} style={{fontSize: 24}} >(</Typography>)
	: null

	let leftType = type
	if (leftType === 'boolean' && (expression?.typeId?.startsWith('cop') || expression?.typeId?.startsWith('lop'))) leftType = null

	left?.typeId?.startsWith('cop') || left?.typeId?.startsWith('lop') || left?.typeId?.startsWith('aop') ?
		result.push(<ExpressionTuple key={getUniqueId()} me={me} expressionParentId={expressionParentId + '_l'} rootparentId={rootparentId} expressionTypes={expressionTypes} strictMode={strictMode} expression={left} type={type} subType={subType} attachments={attachments} allProperties={allProperties} documents={documents} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setLeft} onDelete={()=>setExpression(null)} setFocus={setFocus} />)
	: left?.typeId?.startsWith('func') ?
		result.push(<ExpressionFunc key={getUniqueId()} me={me} expressionParentId={expressionParentId + '_l'} rootparentId={rootparentId} expressionTypes={expressionTypes} strictMode={strictMode} expression={left} attachments={attachments} allProperties={allProperties} documents={documents} groupFilter={leftGroupFilter} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setLeft} setFocus={setFocus} />)
	: left?.typeId?.startsWith('const') ?
		result.push(<ExpressionConst key={getUniqueId()} me={me} expressionParentId={expressionParentId + '_l'} strictMode={strictMode} expression={left} allProperties={allProperties} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setLeft} setFocus={setFocus} />)
	:
		result.push(<Expression key={getUniqueId()} rootparentId={rootparentId} expressionTypes={expressionTypes} strictMode={strictMode} expression={left} type={top ? null : leftType} subType={top ? null : subType} groupFilter={leftGroupFilter} attachments={attachments} allProperties={allProperties} documents={documents} designTime={designTime} disabled={disabled} setExpression={setLeft} />)

	
	result.push(<Typography key={getUniqueId()} >&nbsp;&nbsp;</Typography>)
	
	expression?.typeId?.startsWith('ass') ?
		result.push(<Typography key={getUniqueId()}>=</Typography>)
	: left || expression?.typeId ?
		result.push(<Expression key={getUniqueId()} rootparentId={rootparentId} expressionTypes={expressionTypes} expression={expression} type={type} subType={subType} groupFilter={middleGroupFilter} attachments={attachments} allProperties={allProperties} documents={documents} designTime={designTime} disabled={disabled} setExpression={setOperator} />)
	: null 

	result.push(<Typography key={getUniqueId()} >&nbsp;&nbsp;</Typography>)

	rightGroupFilter = [...new Set(expressionTypes?.filter(exprType => (exprType.type === 'any' || exprType.type === expression.left?.type)).map(exprType => exprType.typeId.split('::')[0]))]
	rightGroupFilter = rightGroupFilter.filter(ref => ref !== 'ass')
	rightGroupFilter = rightGroupFilter.filter(ref => ref !== 'tag')
	rightGroupFilter.push('ref')

	right?.typeId?.startsWith('cop') || right?.typeId?.startsWith('lop') || right?.typeId?.startsWith('aop') ?
		result.push(<ExpressionTuple key={getUniqueId()} me={me} expressionParentId={expressionParentId + '_r'} rootparentId={rootparentId} expressionTypes={expressionTypes} strictMode={strictMode} expression={right} type={left?.type} subType={left?.subType} rightGroupFilter={rightGroupFilter} attachments={attachments} allProperties={allProperties} documents={documents} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setRight} onDelete={()=>setExpression(null)} setFocus={setFocus} />)
	: right?.typeId?.startsWith('func') ?
		result.push(<ExpressionFunc key={getUniqueId()} me={me} expressionParentId={expressionParentId + '_r'} rootparentId={rootparentId} expressionTypes={expressionTypes} strictMode={strictMode} expression={right} attachments={attachments} allProperties={allProperties} documents={documents} groupFilter={rightGroupFilter} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setRight} setFocus={setFocus} />)
	: right?.typeId?.startsWith('const') ?
		result.push(<ExpressionConst key={getUniqueId()} me={me} expressionParentId={expressionParentId + '_r'} strictMode={strictMode} expression={right} allProperties={allProperties} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setRight} setFocus={setFocus} />)
	:
		result.push(<Expression key={getUniqueId()} rootparentId={rootparentId} expressionTypes={expressionTypes} strictMode={strictMode} expression={right} type={left?.type} subType={left?.subType} groupFilter={rightGroupFilter} attachments={attachments} allProperties={allProperties} documents={documents} designTime={designTime} disabled={disabled} setExpression={setRight} />)
			
	!top ?
		result.push(<Typography key={getUniqueId()} style={{fontSize: 24}} >)</Typography>)
	: null
	
	designTime && onDelete && hover ?
		result.push(<RemoveCircle style={{cursor: 'default', height: 20, width: 20}} key={getUniqueId()} onClick={onDelete} />)
	: null

	return result
}

export default ExpressionTuple
