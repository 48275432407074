import React from 'react'
import { Grid, Button, Typography, TextField, Select, MenuItem, Tooltip } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

import TaskListView from '../containers/TaskListView'


const TaskRouteConditionOption = ({ workflowId, task, rcIndex=0, updateTask, designTime }) => {

//	console.log('TaskRouteConditionOption')
		
	return (
		<Grid container direction={'row'} alignItems="center" >

			{ task.routeCondition.options.length > 1 ?
				<TextField
					variant='standard'
					disabled={!designTime}
					style={{marginLeft: 5}}
					InputLabelProps={{shrink: true}}
					placeholder={i18.t('route_condition_option_label')}
					value={task.routeCondition.options[rcIndex].name ? task.routeCondition.options[rcIndex].name : ''}
					onChange={(event) => {
						task.routeCondition.options[rcIndex].name=event.target.value
						updateTask(workflowId, task)
					}}
				/>	
			: null }
			
			<Typography style={{marginLeft: 5, fontWeight: "bold"}} >{i18.t('route_condition_then_proceed')}&nbsp;</Typography>
				
			<TaskListView 
				workflowId={workflowId}
				task={task}
				selectedOptionType={task.routeCondition.options[rcIndex].type}
				selectedOptionDest={task.routeCondition.options[rcIndex].destination}
				update={(type, destination) => {
					task.routeCondition.options[rcIndex].type = type
					task.routeCondition.options[rcIndex].destination = destination
					updateTask(workflowId, task)
				}}				
				designTime={designTime}
			/>

			{ designTime && task.routeCondition.options.length > 1 ?
				<Tooltip title={i18.t('route_condition_delete_tooltip')} >
					<RemoveCircle 
						style={{cursor: 'default', height: 20, width: 20}}
						onClick={() => {
							task.routeCondition.options.splice(rcIndex,1)
							updateTask(workflowId, task)
						}}
					/>
				</Tooltip>
			: null }

		</Grid>
	)

}

export default TaskRouteConditionOption