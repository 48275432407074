import React, { useState } from 'react'
import PropertyList from '../../components/PropertyList'
import { Grid } from '@mui/material'


const PropertyComposite = ({ nameTypeComponent, rootparentType, rootparentId, property, designTime, disabled, deleteCompositeProperty }) => {

	if (!property)
		return null

	const [selectedProperty, setSelectedProperty] = useState()
			
	return (
		<Grid item>
			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
			
			<PropertyList 
				rootparentType={rootparentType}
				rootparentId={rootparentId} 
				parentType={'compositeproperty'} 
				parentId={property.propertyId} 
				propertyList={property.properties}
				selectedProperty={selectedProperty}
				designTime={false}
				disabled={disabled}
				setSelectedProperty={setSelectedProperty}
				deleteProperty={(propertyId)=>deleteCompositeProperty(rootparentId, property.propertyId, propertyId)}
			/>
		</Grid>
	)
}

export default PropertyComposite
