import React, { useState } from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography  } from '@mui/material'
import { FormControl, FormControlLabel, ButtonGroup, Button } from '@mui/material'
import { FormatAlignLeft, FormatAlignCenter, FormatAlignRight } from '@mui/icons-material'
import * as i18 from 'i18next'

import TabBar from '../../components/TabBar'


const FormItemStyleDialog = ({ item, updateTask, setXS, setDialogIsOpen }) => {

	const [tab, setTab] = useState('tab_form_section_style')

	let tabValues = []
	tabValues.push('tab_form_section_style')
	tabValues.push('tab_form_header_style')
	tabValues.push('tab_form_footer_style')

	let item2
	if (tab === 'tab_form_section_style') {
		item2 = item
	} else if (tab === 'tab_form_header_style') {
		if (!item.header) item.header = {}
		item2 = item.header
	} else if (tab === 'tab_form_footer_style') {
		if (!item.footer) item.footer = {}
		item2 = item.footer
	}
	
	if (!item2.style) {item2.style={}}
	item2.style.borderWidth = item2.style.borderWidth ? item2.style.borderWidth : 0
	item2.style.padding = item2.style.padding ? item2.style.padding : 0
	item2.style.outerPadding = item2.style.outerPadding ? item2.style.outerPadding : 0

	return (
		<Dialog open={true} fullWidth >
			<DialogTitle><TabBar tabValues={tabValues} tab={tab} setTab={setTab} /></DialogTitle>
			<DialogContent>
				<Grid container direction='column' >

					{item.type === 'header' || item.type === 'footer' || item.type === 'property' ?
						<div/>
					: null }
					
					{ setXS && tab === 'tab_form_section_style' ?
						<Grid container direction='row' alignItems='center'>
							<Typography>{i18.t('form_section_width_label')}</Typography>
							<Grid item style={{flex: 1}}/>
							<Button variant='standard' style={{padding: 0}} disabled={item.xs < 2} onClick={()=>{setXS(item.xs-1)}} > - </Button>
							<Typography>{item.xs}</Typography>
							<Button variant='standard' style={{padding: 0}} disabled={item.xs >= 11} onClick={()=>{setXS(item.xs+1)}} > + </Button>
						</Grid>
					: null }

					{ tab === 'tab_form_header_style' || tab === 'tab_form_footer_style'?
						<Grid container direction='row' alignItems='center'>
							<Typography>{i18.t('form_section_alignment_label')}</Typography>
							<Grid item style={{flex: 1}}/>
							<Button size="small" style={{backgroundColor: item2.style.textAlign === 'left' ? 'lightgrey' : null}} onClick={()=>{item2.style.textAlign = 'left';updateTask()}} ><FormatAlignLeft/></Button>
							<Button size="small" style={{backgroundColor: item2.style.textAlign === 'center' ? 'lightgrey' : null}} onClick={()=>{item2.style.textAlign = 'center';updateTask()}} ><FormatAlignCenter/></Button>
							<Button size="small" style={{backgroundColor: item2.style.textAlign === 'right' ? 'lightgrey' : null}} onClick={()=>{item2.style.textAlign = 'right';updateTask()}} ><FormatAlignRight/></Button>			
						</Grid>
					: null }
					
					<br/>
					
					<Grid container direction='row' alignItems='center'>
						<Typography>{i18.t('form_section_outer_margin_label')}</Typography>
						<Grid item style={{flex: 1}}/>
						<Button variant='standard' style={{padding: 0}} disabled={item2.style.outerPadding < 1} onClick={()=>{--item2.style.outerPadding;updateTask()}} > - </Button>
						<Typography style={{textAlign: 'center'}}>{item2.style.outerPadding}</Typography>
						<Button variant='standard' style={{padding: 0}} disabled={item2.style.outerPadding >= 20} onClick={()=>{item2.style.outerPadding++;updateTask()}} > + </Button>
					</Grid>
					
					<Grid container direction='row' alignItems='center'>
						<Typography>{i18.t('form_section_inner_margin_label')}</Typography>
						<Grid item style={{flex: 1}}/>
						<Button variant='standard' style={{padding: 0}} disabled={item2.style.padding < 1} onClick={()=>{--item2.style.padding;updateTask()}} > - </Button>
						<Typography style={{textAlign: 'center'}}>{item2.style.padding}</Typography>
						<Button variant='standard' style={{padding: 0}} disabled={item2.style.padding >= 20} onClick={()=>{item2.style.padding++;updateTask()}} > + </Button>
					</Grid>
					
					<Grid container direction='row' alignItems='center'>
						<Typography>{i18.t('form_section_border_width_label')}</Typography>
						<Grid item style={{flex: 1}}/>
						<Button variant='standard' style={{padding: 0}} disabled={item2.style.borderWidth < 1} onClick={()=>{--item2.style.borderWidth;updateTask()}} > - </Button>
						<Typography style={{textAlign: 'center'}}>{item2.style.borderWidth}</Typography>
						<Button variant='standard' style={{padding: 0}} disabled={item2.style.borderWidth >= 20} onClick={()=>{if (item2.style.borderWidth===0) item2.style.borderStyle='solid';item2.style.borderWidth++;updateTask()}} > + </Button>
					</Grid>
					
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setDialogIsOpen(false)} color="primary" variant="contained" >
					{i18.t('dialog_done')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default FormItemStyleDialog