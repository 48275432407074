import React from 'react'
import { Grid, Typography, Button, Card, CardMedia } from '@mui/material'
import * as i18 from 'i18next'

 
const LaunchWorkflow = ({ workflowId, task, milestoneActionIndex, designTime, disabled, updateTask }) => {

//	console.log('LaunchWorkflow')
	
	let milestoneAction = task.milestoneActions[milestoneActionIndex]

	let targetWorkflowId = null
	if (milestoneAction.value) {
		targetWorkflowId = JSON.parse(milestoneAction.value).workflowId
	}
			
	return (
		<Grid container direction={'row'} alignItems='center'>				

			{ !targetWorkflowId ?

				<Grid item>
				
					<Typography style={{margin: 5}}>No workflow selected</Typography>
					
				</Grid>
			: 
				<Grid container direction={'row'} alignItems='center'>	
					<Grid item>
					</Grid>
					<Typography>{targetWorkflowId}</Typography>
				</Grid>
			}

		</Grid>
	)
}

export default LaunchWorkflow