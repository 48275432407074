import React from 'react'
import { Grid, TextField, Typography } from '@mui/material'
import * as i18 from 'i18next'


const SaveToCSV = ({ workflowId, task, milestoneActionIndex, designTime, disabled, updateTask }) => {

//	console.log('SaveToCSV')
	
	let milestoneAction = task.milestoneActions[milestoneActionIndex]
	const value = milestoneAction.expression?.params?.[0]?.value

	return (
		<Grid container direction={'row'} alignItems='center'>				

			<Typography>{i18.t('action_save_to_csv')}</Typography>
			
			&nbsp;&nbsp;
					
			<TextField
				style={{flex: 1}}
				variant='standard'
				disabled={disabled}
				InputLabelProps={{shrink: true}}
				placeholder={i18.t('savetojson_title_placeholder')}
				value={value ? value : ''}
				onChange={(e)=>{
					milestoneAction.expression = {typeId: "func::save_to_csv", params: [{typeId: "const::string", type: "string", value: e.target.value}], type: "void"}
					updateTask(workflowId, task)
				}}
			/>
			
		</Grid>
	)
}

export default SaveToCSV