import React, { useState } from 'react'
import { Grid, Card, Paper, List, Select, MenuItem, TextField, FormControl, Typography } from '@mui/material'
import * as i18 from 'i18next'

import PropertyNameType from '../components/PropertyNameType'
import PropertyEmail from '../components/propertytypes/PropertyEmail'
import PropertyNumber from '../components/propertytypes/PropertyNumber'
import PropertyDate from '../components/propertytypes/PropertyDate'
import PropertyTextMulti from '../components/propertytypes/PropertyTextMulti'
import PropertyTextSingle from '../components/propertytypes/PropertyTextSingle'
import PropertyListMulti from '../components/propertytypes/PropertyListMulti'
import PropertyListSingle from '../components/propertytypes/PropertyListSingle'
import PropertySelectSingleEdit from '../components/propertytypes/PropertySelectSingleEdit'
import PropertySelectMultiEdit from '../components/propertytypes/PropertySelectMultiEdit'
import PropertyComposite from '../components/propertytypes/PropertyComposite'
import PropertyCompositeEdit from '../components/propertytypes/PropertyCompositeEdit'
import PropertyDropdownMulti from '../components/propertytypes/PropertyDropdownMulti'
import PropertyDropdownSingle from '../components/propertytypes/PropertyDropdownSingle'
import PropertyTable from '../components/propertytypes/PropertyTable'
import PropertyBoolean from '../components/propertytypes/PropertyBoolean'


const Property = ({ org, orgProperties, contacts, extractTemplates, extractsTagList, extracts, me, rootparentType, rootparentId, 
					property, properties, documents, 
					designTime=false, level=0, disabled=false, showDisabledProps=false, slimStyle=false,
					addCompositeProperty, deleteCompositeProperty, updateProperty, changePropertyType, 
					createDocument, retrieveContacts, 
					createExtract, createPropertiesFromExtract }) => {

//	console.log('Property', property.pname)

	if (!property) return null

	const [hover, setHover] = useState()

	// Set background color based on property type
	let propertyStyle = slimStyle ? {} : {flex: 1, boxShadow: '0 3px 6px rgba(0,0,0,0.16)', padding: 5}

	if (property.validationErrors && property.validationErrors.filter(err => err.error).length > 0) {
		// If this property has an error then highlight it in red
		propertyStyle.boxShadow = '0 2px 4px rgba(255,0,0,0.7)'
	} else if (property.validationErrors && property.validationErrors.filter(err => err.warning).length > 0) {
		// If this property has a warning then highlight it in orange
		propertyStyle.boxShadow = '0 2px 4px rgba(255,165,0,0.7)'
	}

	let nameTypeComponent = slimStyle ? null : 
								<PropertyNameType
									org={org}
									orgProperties={orgProperties}
									rootparentId={rootparentId}
									property={property}
									properties={properties}
									designTime={designTime}
									updateProperty={updateProperty}
									changePropertyType={changePropertyType}
									showDisabledProps={showDisabledProps}
								/>

	return (
		<Grid
			item
			style={{...propertyStyle, backgroundColor: hover ? 'ghostwhite' : 'transparent'}}
			onMouseMove={() => {if (!hover) setHover(true)}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>						
			
			{['text','password'].includes(property.control) ?
				<PropertyTextSingle nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={disabled} />
			: null }

			{property.control === 'textarea' ?
				<PropertyTextMulti nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={disabled} />
			: null }

			{property.control === 'number' ?
				<PropertyNumber nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={disabled} />
			: null }

			{property.control === 'boolean' ?
				<PropertyBoolean nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={disabled} />
			: null }

			{property.control === 'datetime-local' ?
				<PropertyDate nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={disabled} />
			: null }

			{property.control === 'email' ?
				<PropertyEmail
					nameTypeComponent={nameTypeComponent} 
					me={me} 
					property={property}
					contacts={contacts} 
					designTime={designTime} 
					disabled={disabled} 
					updateProperty={updateProperty} 
					retrieveContacts={retrieveContacts} />
			: null }
			
			{ designTime ?
				['radio','dropdownsingle'].includes(property.control) ?
					<PropertySelectSingleEdit nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={!designTime} />
				: null
			: 
				property.control === 'radio' ?
					<PropertyListSingle nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={disabled} />
				: property.control === 'dropdownsingle' ?
					<PropertyDropdownSingle nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={disabled} />
				: null
			}

			{ designTime ?
				['listmulti','dropdownmulti'].includes(property.control) ?
					<PropertySelectMultiEdit nameTypeComponent={nameTypeComponent} property={property} updateProperty={updateProperty} designTime={designTime} disabled={!designTime} />
				:null
			:
				property.control === 'listmulti' ?
					<PropertyListMulti 
						nameTypeComponent={nameTypeComponent} 
						property={property} 
						properties={properties} 
						disabled={disabled} 
						updateProperty={updateProperty} 
						addCompositeProperty={(parentId, property) => addCompositeProperty(rootparentId, parentId, property)} 
						deleteCompositeProperty={(parentId, propertyId) => deleteCompositeProperty(rootparentId, parentId, propertyId)} 
					/>
				: property.control === 'dropdownmulti' ?
					<PropertyDropdownMulti 
						nameTypeComponent={nameTypeComponent} 
						property={property} 
						properties={properties} 
						disabled={disabled} 
						updateProperty={updateProperty} 
						addCompositeProperty={(parentId, property) => addCompositeProperty(rootparentId, parentId, property)} 
						deleteCompositeProperty={(parentId, propertyId) => deleteCompositeProperty(rootparentId, parentId, propertyId)} 
					/>
				: null
			}

			{property.control === 'object'?
				designTime ?
					<PropertyCompositeEdit 
						nameTypeComponent={nameTypeComponent} 
						rootparentType={rootparentType}
						rootparentId={rootparentId} 
						property={property} 
						hover={hover}
						disabled={!designTime} 
						level={level} 
						addCompositeProperty={addCompositeProperty} 
						deleteCompositeProperty={deleteCompositeProperty}
					/>
				:
					<PropertyComposite 
						nameTypeComponent={nameTypeComponent} 
						rootparentType={rootparentType} 
						rootparentId={rootparentId} 
						property={property} 
						designTime={designTime} 
						disabled={disabled} 
					/>
			: null}

			{property.control === 'table'?
				<PropertyTable 
					nameTypeComponent={nameTypeComponent} 
					rootparentType={rootparentType}
					rootparentId={rootparentId} 
					property={property} 
					properties={properties}
					designTime={designTime} 
					disabled={disabled} />
			: null}
			
			{/* If there are any validation errors - display them here. */}
			{(property.validationErrors && property.validationErrors.length > 0) ?
				property.validationErrors.map((err, index) => {
					if (err.warning)
						return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
								
					if (err.error)
						return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
				})
			: null }

		</Grid>
	)
}

export default Property
