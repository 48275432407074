import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { GoogleOAuthProvider } from '@react-oauth/google';

import './index.css'
import App from './App'

const CLIENT_ID = '387159493695-djqsqn9ltpgi3lro5bljk23gqnlrnhee.apps.googleusercontent.com'


ReactDOM.render(
	<React.Fragment>
		<GoogleOAuthProvider clientId={CLIENT_ID} >
			<App />
		</GoogleOAuthProvider>
	</React.Fragment>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
