import React, { useState } from 'react'
import { Grid, Typography, Avatar, Hidden, Icon, Divider, Tooltip, Button } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'


const DelegatedPermissionList = ({ navigate, sourceObj, designTime, update }) => {

//	console.log('DelegatedPermissionList')

	if (!sourceObj) return null

	const [hover, setHover] = useState(false)
		
	return (
				<Grid item >
					<br/>

					<Grid container direction={'row'} >
						<Typography style={{flex: 1, paddingLeft: 60}}>{i18.t('permission_list_object_label')}</Typography>
						<Typography style={{paddingRight: 80}}>{i18.t('permission_list_permission_label')}</Typography>
					</Grid>

					<Divider/>

					{sourceObj.delegatedPermissions?.map((permission, index) => (

						<Grid container direction={'column'} key={index} >
						
							<Grid item >
								<Grid 
									container 
									direction={'row'} 
									alignItems='center' 
									style={{marginTop: 5, marginBottom: 5}}
									onMouseMove={() => setHover(index)}
									onMouseEnter={() => setHover(index)}
									onMouseLeave={() => setHover(false)}
								>
									{/* link to delegated object */}
									<Tooltip title={i18.t('permission_delegate_tooltip')} >
										<Button onClick={(e) => {navigate(permission.delegateType, {[`${permission.delegateType}Id`]: permission.delegateId})}}>
											{permission.delegateType ? permission.delegateType : null}
										</Button>
									</Tooltip>
									
									<div style={{flex: 1}}/>
									
									<Typography style={{paddingRight: 10}}>
										{i18.t(`role_${permission.grant}`)}
									</Typography>
									{ designTime && (hover === index) ?
										<Tooltip title={i18.t('workflow_tooltip_delete_member')} >
											<RemoveCircle
												style={{cursor: 'default', height: 20, width: 20}}
												onClick={() => {
													sourceObj.delegatedPermissions.splice(index,1)
													update(sourceObj)
												}}
											/>
										</Tooltip>
									:
										<Icon/>
									}
								</Grid>
							</Grid>

							<Divider/>

						</Grid>
					))}
				</Grid>
	)
}

export default DelegatedPermissionList