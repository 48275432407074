import React from 'react'
import { connect } from 'react-redux'
import Task from '../components/Task'
import { addTask, deleteTask, updateTask, makeChildTask, makeParentTask } from '../reducers/tasks'
import { setParallelExecution, setAssignees, deleteAssignee, addMilestoneAction, deleteMilestoneAction, setAssigneesType, setAssigneesGroupProperty } from '../reducers/tasks'
import { setExpanded } from '../reducers/appnav'
import { retrieveContacts } from '../reducers/app'


const TaskView = ({ me, contacts=[], actions=[], focusTaskId, workflow, task, parentTask, attachments, allProperties, documents, expanded, index, level=0, designTime,
					setFocusTaskId, addTask, updateTask, deleteTask, makeChildTask, makeParentTask,
					setParallelExecution, setAssignees, deleteAssignee, addMilestoneAction, deleteMilestoneAction,
					setAssigneesType, setAssigneesGroupProperty, retrieveContacts, setExpanded }) => {

//	console.log('TaskView')

	if (!workflow || !task)
		return null

	let taskId = task.taskId
	let workflowId = workflow.workflowId
	
	return (
		<Task
			me={me}
			
			contacts={contacts}
			actions={actions}
			workflow={workflow}
			task={task}
			focusTaskId={focusTaskId}
			parentTask={parentTask}
			attachments={attachments}
			allProperties={allProperties}
			documents={documents}
			expanded={expanded}
			designTime={designTime}
			index={index}
			
			level={level}
			
			setFocusTaskId={setFocusTaskId}
			addTask={addTask}
			deleteTask={deleteTask}
			updateTask={updateTask}
			
			makeChildTask={makeChildTask}
			makeParentTask={makeParentTask}

			onParallelExecutionChange={(value)=>setParallelExecution(workflowId, taskId, value)}
			
			setAssignees={(assignees)=>setAssignees(workflowId, taskId, assignees)}
			onAssigneeDelete={(assigneeIndex)=>deleteAssignee(workflowId, taskId, assigneeIndex)}
			onMilestoneActionAdd={(maction)=>addMilestoneAction(workflowId, taskId, maction)}
			onMilestoneActionDelete={(mactionIndex)=>deleteMilestoneAction(workflowId, taskId, mactionIndex)}
			
			setAssigneesType={(type)=>setAssigneesType(workflowId, taskId, type)}
			setAssigneesGroupProperty={(propertyId)=>setAssigneesGroupProperty(workflowId, taskId, propertyId)}

			retrieveContacts={retrieveContacts}
			
			setExpanded={setExpanded}
		/>	
	)
		
}

const mapStateToProps = (state, props) => ({
	...props,
//	nav: state.tasknav,
	me: state.app.me,
	contacts:  state.app.contacts,
	actions:  state.actions,

	workflow: state.workflows[props.workflowId],

//	workflowId: state.workflows[props.workflowId] ? state.workflows[props.workflowId].workflowId : null,
//	workflowState: state.workflows[props.workflowId] ? state.workflows[props.workflowId].state : null,
//	workflowProperties: state.workflows[props.workflowId] ? state.workflows[props.workflowId].properties : null,
//	workflowAttachments: state.workflows[props.workflowId] ? state.workflows[props.workflowId].attachments : null,

	task: state.tasks[props.workflowId] ? state.tasks[props.workflowId][props.taskId] : null,
	parentTask: state.tasks[props.workflowId] && state.tasks[props.workflowId][props.taskId] ? state.tasks[props.workflowId][state.tasks[props.workflowId][props.taskId].parenttaskId] : null,
	attachments: state.attachments[props.workflowId],
	allProperties: state.properties,
	documents: state.documents,
	expanded: state.appnav.expanded?.[props.taskId],
	modified: state.tasks[props.workflowId] && state.tasks[props.workflowId][props.taskId] ? state.tasks[props.workflowId][props.taskId].modified : false,
})

export default connect(mapStateToProps, { addTask, updateTask, deleteTask, makeChildTask, makeParentTask,
											setParallelExecution,
											setAssignees, deleteAssignee, addMilestoneAction, deleteMilestoneAction,
											setAssigneesType, setAssigneesGroupProperty, retrieveContacts, setExpanded })(TaskView)
