import React from 'react'
import { connect } from 'react-redux'
import { Grid, Tooltip, Button, Hidden } from '@mui/material'
import { Close, Done } from '@mui/icons-material'
import { DragDropContext } from 'react-beautiful-dnd'
import * as i18 from 'i18next'

import LaunchAssignmentToolBar from '../components/LaunchAssignmentToolBar'
import AssignmentDetail from '../components/AssignmentDetail'

import { runWorkflow, retrieveWorkflow } from '../reducers/workflows'


const LaunchAssignmentView = ({ navigation, workflow, tasks, runWorkflow, retrieveWorkflow }) => {

//	console.log('LaunchAssignmentView')
	
	if (!workflow) return null

	let startTask = tasks?.[workflow?.tasks?.[0]]

	return (
		<DragDropContext style={{background: 'transparent'}} onDragEnd={(result)=>{}} >
			<Grid container direction="column" >

				<LaunchAssignmentToolBar 
					navigation={navigation} 
					workflow={workflow} 
					retrieveWorkflow={retrieveWorkflow}
					runWorkflow={runWorkflow}
				/>

				{/* Assignment panel */}
				<AssignmentDetail
					workflowId={workflow.workflowId}
					rootparentType={'launchassignment'}
					assignment={{...startTask, state: 'working', assignmentId: workflow.workflowId, workflowId: workflow.workflowId}}
					designTime={false}
					disabled={false}
					updateAssignment={()=>{}}	// updateAssignment is only needed for updating the route condition which doesn't exist on launch assignments
				/>
						
			</Grid>
		</DragDropContext>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	workflow: state.workflows[props.navigation.params.workflowId],
	tasks: state.tasks[props.navigation.params.workflowId],
	
})

export default connect(mapStateToProps, { runWorkflow, retrieveWorkflow })(LaunchAssignmentView)
