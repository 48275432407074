import React from 'react'
import { connect } from 'react-redux'
import { Grid, Button, TextField } from '@mui/material'
import { updateQuery, query } from '../reducers/graphiql'
import ReactJson from 'react-json-view'
import { QueryBuilder } from '@mui/icons-material'


const GraphiQLView = ({ graphiql, updateQuery, query }) => {
 
//	console.log('GraphiQLView')

	return (
		<Grid>
		<br/>
			<Grid container direction="row" justify="space-between" >
				<TextField
					style={{ flex: 1 }}
					label="Query Request:"
					placeholder="enter graphql query here."
					value={graphiql.query}
					onChange={(event)=>updateQuery(event.target.value)}
					variant='outlined'
					multiline={true}
					rows={20}
				/>
				<Grid item xs={1} sm={1} md={1} lg={1}>
					<Button onClick={()=>query(graphiql.query)} ><QueryBuilder/>Query</Button>
					<Button onClick={()=>query(`{__schema {queryType {name task {name}} mutationType {name task {name}}}}`)} ><QueryBuilder/>Schema</Button>
					<Button onClick={()=>query(`{__schema{types {
													name
													description
													task {name description}
													enumValues {name description}
												}}}`)} ><QueryBuilder/>Types</Button>					
				</Grid>
			</Grid>
			<ReactJson src={graphiql.result} />
		</Grid>
	)

}

const mapStateToProps = (state, props) => ({
	...props,
	graphiql: state.graphiql,
})

export default connect(mapStateToProps, { updateQuery, query })(GraphiQLView)