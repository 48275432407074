import React, { useState, useMemo } from 'react'
import { Grid, Typography } from '@mui/material'
import { Document, Page, pdfjs  } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

import ImagePaneOverlay from '../components/ImagePaneOverlay'


const matchRect = (containerRect, containeeRect) => {

	let newRect = {}
	
	if (containeeRect.left > containerRect.left) {
		if (containeeRect.left < containerRect.right) {
			newRect.left = containeeRect.left
		} else {
			// no part of the containeeRect is within the containerRect
			return false
		}
	} else {
		newRect.left = containerRect.left
	}
	
	if (containeeRect.top > containerRect.top) {
		if (containeeRect.top < containerRect.bottom) {
			newRect.top = containeeRect.top
		} else {
			// no part of the containeeRect is within the containerRect
			return false
		}
	} else {
		newRect.top = containerRect.top
	}

	if (containeeRect.right < containerRect.right) {
		if (containeeRect.right > containerRect.left) {
			newRect.right = containeeRect.right
		} else {
			// no part of the containeeRect is within the containerRect
			return false
		}
	} else {
		newRect.right = containerRect.right
	}
	
	if (containeeRect.bottom < containerRect.bottom) {
		if (containeeRect.bottom > containerRect.top) {
			newRect.bottom = containeeRect.bottom
		} else {
			// no part of the containeeRect is within the containerRect
			return false
		}
	} else {
		newRect.bottom = containerRect.bottom
	}
		
	let area1 = (containeeRect.right - containeeRect.left)*(containeeRect.bottom - containeeRect.top)	
	let area2 = (newRect.right - newRect.left)*(newRect.bottom - newRect.top)
	
	return area2 >= area1 * .5
}

const fuzzyMatchValue = (page, itemRect, wblocks) => {

	if (!page || !itemRect || !wblocks) return []
	
	let words = []

	let containerRect = itemRect ? {top: (page.height * itemRect.top), left: (page.width * itemRect.left), bottom: (page.height * itemRect.top) + (page.height * itemRect.height), right: (page.width * itemRect.left) + (page.width * itemRect.width)} : {top: 0, left: 0, bottom: 0, right: 0}

	wblocks && wblocks.map((block, index) => {

		let wRect = block.Geometry.BoundingBox

		let containeeRect = wRect ? {top: (page.height * wRect.Top), left: (page.width * wRect.Left), bottom: (page.height * wRect.Top) + (page.height * wRect.Height), right: (page.width * wRect.Left) + (page.width * wRect.Width)} : {top: 0, left: 0, bottom: 0, right: 0}

		if (matchRect(containerRect, containeeRect)) {
			words.push(block.Text)
		} 
	})
	
	return words
}
	
const proportionalToAbsoluteRect = (page, rect, zoom) => {

	if (!page || !rect || !zoom) return null

	let newRect = {
		top: (rect.top * page.height * zoom),
		left: (rect.left * page.width * zoom),
		height: (rect.height * page.height * zoom),
		width: (rect.width * page.width * zoom),
	}

	newRect.bottom = newRect.top + newRect.height
	newRect.right = newRect.left + newRect.width

	return newRect
}

const getPropertyOverlays = (properties, wblocks, page, pageNumber, zoom, parentProperty, property, 
								propertyIndex, maxProperties, overlays, selectedProperty, highlightedRule) => {

	if (!property.extractionRule) return

	let selected = property.propertyId === selectedProperty
	
	let highlighted = property.propertyId === highlightedRule
	
	let borderWidth = 1
	if (selected) {
		borderWidth = 3
	} else if (highlighted) {
		borderWidth = 2
	} else if (parentProperty?.extractionRule.type === 'vtable' && !selected && parentProperty?.propertyId === selectedProperty) {
		borderWidth = 3
	}

	let keyBorder = highlighted && !selected ? {width: 3, style: `dashed brown`} : {width: borderWidth, style: `dashed blue`}
	let valueBorder = highlighted && !selected ? {width: 3, style: `dashed brown`} : {width: borderWidth, style: `dashed green`}
	let valueBorderThin = {width: 1, style: `dashed green`}

	let titleBorder = highlighted && !selected ? {width: 3, style: `dashed brown`} : {width: borderWidth, style: `dashed red`}
	let tableBorder = highlighted && !selected ? {width: 3, style: `dashed brown`} : {width: borderWidth, style: `dashed orange`}


	// Create the base part of the overlay
	let block = {
//		extractId: extractId,
		property: property,
		parentpropertyId: parentProperty?.propertyId,
		selected: selected,
		columnIndex: propertyIndex,
		padding: property.extractionRule.type === 'tablecolumn' ? 0 : 2,
		margin: property.extractionRule.type === 'tablecolumn' ? 2 : 0,		
		tooltip: property.name,
	}

	// Based on the type of property and it's position relative to it's peers - set the supported sizing options (left, right, top, bottom)
	if (property.extractionRule.type === 'tablecolumn') {
		if (propertyIndex === 0) {
			block.sizeLeft = false
			block.sizeRight = true
			block.sizeTop = false
			block.sizeBottom = true
		} else if (propertyIndex === (maxProperties - 1)) {
			block.sizeLeft = false
			block.sizeRight = false
			block.sizeTop = false
			block.sizeBottom = true
		} else {
			block.sizeLeft = false
			block.sizeRight = true
			block.sizeTop = false
			block.sizeBottom = true
		}
	} else {
		block.sizeLeft = true
		block.sizeRight = true
		block.sizeTop = true
		block.sizeBottom = true
	}

	let rect0 = property.extractionRule.key ? proportionalToAbsoluteRect(page, property.extractionRule.key.rect, zoom) : null
	let rect1 = property.extractionRule.value ? proportionalToAbsoluteRect(page, property.extractionRule.value.rect, zoom) : null

	if (wblocks && wblocks.length > 0) {
		property.extractionRule.key ? property.extractionRule.key.words = fuzzyMatchValue(page, property.extractionRule.key.rect, wblocks) : null
	
		if (property.extractionRule.type === 'kvp' && property.extractionRule.page === pageNumber) {
			property.extractionRule.value ? property.extractionRule.value.words = fuzzyMatchValue(page, property.extractionRule.value.rect, wblocks) : null
		}
	}
	
	if (property.extractionRule.type === 'kvp' && property.extractionRule.page === pageNumber) {
		if (property.extractionRule.key && property.extractionRule.key.rect) {overlays.push({...block, blockId: `${property.propertyId}::0`, attribute: 'key', border: keyBorder, rect: rect0})}
		if (property.extractionRule.value && property.extractionRule.value.rect) {overlays.push({...block, blockId: `${property.propertyId}::1`, attribute: 'value', border: valueBorder, rect: rect1})}
	} else if (property.extractionRule.type === 'vtable' && property.extractionRule.page === pageNumber) {
		if (property.extractionRule.key && property.extractionRule.key.rect) {overlays.push({...block, blockId: `${property.propertyId}::0`, attribute: 'key', border: titleBorder, rect: rect0})}
		if (property.extractionRule.value && property.extractionRule.value.rect) {overlays.push({...block, blockId: `${property.propertyId}::1`, attribute: 'value', border: tableBorder, rect: rect1})}
		if (property.extractionRule.value) {
			if (property.items && property.items.properties && property.items.properties.length > 0) {
				property.items.properties.forEach((prop, index)=> {getPropertyOverlays(properties, wblocks, page, pageNumber, zoom, property, prop, index, property.items.properties.length, overlays, selectedProperty, highlightedRule)})
			}
		}
	} else if (property.extractionRule.type === 'tablecolumn' && property.extractionRule.page === pageNumber) {
		if (property.extractionRule.key && property.extractionRule.key.rect) {overlays.push({...block, blockId: `${property.propertyId}::0`, attribute: 'key', border: keyBorder, rect: rect0})}
		if (property.extractionRule.value && property.extractionRule.value.rect) {overlays.push({...block, blockId: `${property.propertyId}::1`, attribute: 'value', border: valueBorder, rect: rect1})}

		if (property.extractionRule.value && property.extractionRule.page === pageNumber) {
			let tableRect = parentProperty && parentProperty.extractionRule && parentProperty.extractionRule.value ? proportionalToAbsoluteRect(page, parentProperty.extractionRule.value.rect, zoom) : null
			let columnValueRect = {...rect1}

			if (tableRect && columnValueRect && columnValueRect.height > 0) {
				let bodyHeight = tableRect.height - (columnValueRect.top - tableRect.top)
				let numRows = (bodyHeight / columnValueRect.height) - 1

				block.sizeLeft = false
				block.sizeRight = false
				block.sizeTop = false
				block.sizeBottom = false
					
				for (let i=0; i<numRows; ++i) {
					overlays.push({...block, blockId: `${property.propertyId}::1-${i}`, attribute: 'secondary_values', border: valueBorderThin, rect: {...columnValueRect}})
					columnValueRect.top += columnValueRect.height
				}
			}
		}
	} else if (property.extractionRule.type === 'object' && property.extractionRule.page === pageNumber) {
		if (property.extractionRule.key && property.extractionRule.key.rect) {overlays.push({...block, blockId: `${property.propertyId}::0`, attribute: 'key', border: titleBorder, rect: rect0})}
		if (property.extractionRule.value && property.extractionRule.value.rect) {overlays.push({...block, blockId: `${property.propertyId}::1`, attribute: 'value', border: tableBorder, rect: rect1})}
		if (property.extractionRule.value) {
			if (property.properties) {
				property.properties.forEach((propId, index)=> {
					getPropertyOverlays(properties, wblocks, page, pageNumber, zoom, property, properties[propId], index, property.properties.length, overlays, selectedProperty, highlightedRule)
				})
			}
		}
	}
}

export const getOverlays = (extractProperties, wblocks, page, pageNumber, zoom, selectedProperty, highlightedRule) => {

	if (!extractProperties) return null

	let overlays = []
	const keys = Object.keys(extractProperties)
	const numProps = keys.length
	keys.forEach((key, index) => {
		const property = extractProperties[key]
		if (property) {
			let selected = (property && selectedProperty === property.propertyId)
			let highlighted = (property && (highlightedRule === property.propertyId))
		
			getPropertyOverlays(extractProperties, wblocks, page, pageNumber, zoom, null, property, index, numProps, overlays, selectedProperty, highlightedRule )
		}
	})
	
	return overlays
}

const isContained = (containerRect, containeeRect) => (
	containerRect &&
	containeeRect &&
	containeeRect.left >= containerRect.left &&
	containeeRect.top >= containerRect.top &&
	containeeRect.left + containeeRect.width <= containerRect.left + containerRect.width &&
	containeeRect.top + containeeRect.height <= containerRect.top + containerRect.height
)

/*
const fetchpdf = async (url) => {
	const googleSession = JSON.parse(localStorage.getItem('googleSession'))
	const data = {method: "GET", headers: {'Authorization': `${googleSession?.token_type} ${googleSession.access_token}`}}
	return await fetch(url, data)
}
*/


const supportedMimeTypes = ['image/png', 'image/jpeg', 'png', 'application/pdf', 'application/json', 'application/rtf', 'text/plain', 'text/csv', 'text/html', 'text/calendar', 'text/javascript']

const ImagePane = ({ content, page, pageNumber, zoom=1, wblocks, properties,
					selectionAttribute, selectedProperty, highlightedRule, avgwordheight, designTime,
					setMaxPages, setWidth, setHeight, setSelectionAttribute, updateProperty, moveToCompositeProperty }) => {

//	console.log("ImagePane")

	if (!content || !page) return null

//	if (!supportedMimeTypes.includes(content.mimeType)) return <Typography>{`Image type (${content.mimeType}) not supported.`}</Typography>

	const getData = (data) => {return {data: data}}
	
	const cdata = useMemo(() => getData(atob(content.data)), [content])
//	const cdata = useMemo(() => getData(content), [content])

	const documentWidth = Math.max(document.body.scrollWidth,
									document.documentElement.scrollWidth,
									document.body.offsetWidth,
									document.documentElement.offsetWidth,
									document.body.clientWidth,
									document.documentElement.clientWidth
								)
	const documentHeight = Math.max(document.body.scrollHeight,
									document.documentElement.scrollHeight,
									document.body.offsetHeight,
									document.documentElement.offsetHeight,
									document.body.clientHeight,
									document.documentElement.clientHeight
								)								

	let width2 = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
	let scalingfactor = 1
	if (documentWidth && page.width) {
		(documentWidth * 8 / 12) / page.width
	}

	if (content.mimeType !== 'application/pdf') {
		zoom = zoom * scalingfactor
	}
	
	let selectedProp = selectedProperty ? properties[selectedProperty] : null

	let overlays
	if (content.mimeType === 'application/pdf') {
		overlays = getOverlays(properties, wblocks, page, pageNumber, 1, selectedProperty, highlightedRule)
	} else {
		overlays = getOverlays(properties, wblocks, page, pageNumber, zoom, selectedProperty, highlightedRule)
	}

	const onSelection = (rect) => {

		if (selectedProp && selectedProp.extractionRule && selectionAttribute) {

			if (selectionAttribute) {
				if (!selectedProp.extractionRule[selectionAttribute]) {selectedProp.extractionRule[selectionAttribute]={}}
				selectedProp.extractionRule[selectionAttribute].rect = rect
				selectedProp.extractionRule[selectionAttribute].words = fuzzyMatchValue(page, rect, wblocks)
			}

			if (selectionAttribute === 'key') {
				selectedProp.name = selectedProp.extractionRule.key.words.join(' ')
				if (selectedProp.name) {
					selectedProp.pname = selectedProp.extractionRule.key.words.join('_').toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
				}
			}

			updateProperty(selectedProp)
		}
		setSelectionAttribute(null)
	}

	const movePropertiesToComposite = (compositeProperty) => {
		let keys = Object.keys(properties)

		for (const key of keys) {			
			let property = properties[key]
			if ((property.propertyId !== compositeProperty.propertyId)) {
				if (
					(compositeProperty.extractionRule.value && compositeProperty.extractionRule.value.rect) &&
					(property.extractionRule.page === compositeProperty.extractionRule?.page) &&
					(property.extractionRule.key || property.extractionRule.value) &&
					(!property.extractionRule.key || property.extractionRule.key && isContained(compositeProperty.extractionRule.value.rect, property.extractionRule.key.rect)) &&
					(!property.extractionRule.value || property.extractionRule.value && isContained(compositeProperty.extractionRule.value.rect, property.extractionRule.value.rect))
				) {
					moveToCompositeProperty( compositeProperty.propertyId, property.propertyId)
				}
			}
		}
	}

	const width = page.width && zoom ? page.width * zoom : 0
	const height = page.height && zoom ? page.height * zoom : 0

// application/rtf
// audio/*
// video/*

	return (
		<Grid item id='imagepane'>

			{ (['application/pdf2'].includes(content.mimeType)) ?
				<iframe src={`data:${content.mimeType};base64,` + content.data} width='100%' height='900' ></iframe>
			: (['application/pdf'].includes(content.mimeType)) ?
				<div id='image'>
				<Document file={cdata} onLoadSuccess={(doc)=>{setMaxPages(doc.numPages)}} onLoadError={(err)=>{console.log(err)}} >
					<Page scale={zoom} pageNumber={pageNumber} renderMode='canvas' renderTextLayer={false} renderAnnotationLayer={false} onLoadSuccess={(page)=>{setWidth(page.width);setHeight(page.height);}} onLoadError={(err)=>{console.log(err)}} />
				</Document>
				</div>
			: (content.mimeType.startsWith('image/') || ['png', 'jpeg', 'jpg', 'bmp'].includes(content.mimeType)) ?
				<img id='image' src={`data:${content.mimeType};base64,${content.data}`} width={width} height={height} />
			: (content.mimeType.startsWith('text/') || ['application/json', 'application/ld+json', 'application/xml'].includes(content.mimeType)) ?
				<pre>{atob(content.data)}</pre>
			:
				<Typography>Can't display document</Typography>
			}

			<ImagePaneOverlay
				page={page}
				pageNumber={pageNumber}
				zoom={zoom}
				overlays={overlays}
				selectedProp={selectedProp}
				selectionAttribute={selectionAttribute}
				selectedProperty={selectedProperty}
				avgwordheight={avgwordheight}
				wblocks={wblocks}
				designTime={designTime}
				fuzzyMatchValue={fuzzyMatchValue}
				setSelectionAttribute={setSelectionAttribute}
				onSelection={onSelection}
				updateProperty={updateProperty}
				movePropertiesToComposite={movePropertiesToComposite}
			/>
			
		</Grid>
	)
}

export default ImagePane