import React, { useState } from 'react'
import { Grid, Button, TextField, CardMedia, Divider, Typography, CardActions } from '@mui/material'
import * as i18 from 'i18next'

import ImageRefDialog from '../components/dialogs/ImageRefDialog'
import PropertyList from '../components/PropertyList'
import AttachmentList from '../components/AttachmentList'
import WorkflowAdvDetailsDialog from '../components/dialogs/WorkflowAdvDetailsDialog'
import TagBar from '../components/TagBar'


const WorkflowDetail = ({ navigation, tags, workflow, versions, designTime, disabled, updateWorkflow, retrieveAutomation, createAutomation, 
							addWorkflowProperty, deleteProperty, createAttachment, deleteAttachment, addCompositeProperty,
							retrieveVersions, restoreVersion }) => {
 
//	console.log('WorkflowDetail')

	if (!workflow) return null

	const [imageDialogIsOpen, setImageDialogIsOpen] = useState(false)
	const [detailsDialogIsOpen, setDetailsDialogIsOpen] = useState(false)
	const [selectedProperty, setSelectedProperty] = useState()

	let height = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 50
	const tabbarHeight = document.getElementById('tabbar')?.clientHeight ? document.getElementById('tabbar')?.clientHeight : 50

	height = height - (toolbarHeight + tabbarHeight)
	
	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='workflow-detail' style={{width: width, height: height, position: 'absolute', overflow: 'auto'}} >

			<br/>
			
			<Grid container direction={'row'} >
			
				<Grid item xs={12} sm={9} md={7}>
				
					<Grid container direction={'column'} >

						{/* If there are any validation errors - display them here. */}
						{(workflow.validationErrors && workflow.validationErrors.length > 0) ?
							workflow.validationErrors.map((err, index) => {
								if (err.warning)
									return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
											
								if (err.error)
									return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
							})
						: null }
															
						{/* name */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							autoFocus={true}
							label={i18.t('properties_workflow_name')}
							placeholder={i18.t('workflow_name_placeholder')}
							value={workflow.name ? workflow.name : ''}
							onChange={(event)=>{workflow.name=event.target.value;updateWorkflow(workflow);}}
							InputProps={{readOnly: disabled}}
						/>
						
						<br/>
						
						{/* description */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_description')}
							placeholder={i18.t('workflow_desc_placeholder')}
							value={workflow.description?workflow.description:''}
							onChange={(event)=>{workflow.description=event.target.value;updateWorkflow(workflow)}}
							InputProps={{readOnly: disabled}}
						/>
						
						<br/>

						{/* state */}
						<TextField
							variant='standard'
							sx={{input: {color: workflow.state === 'deleted' ? 'red' : null}}}
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_state')}
							value={workflow.state ? i18.t(`workflow_state_${workflow.state}`) : ''}
							InputProps={{readOnly: true}}										
						/>

						<br/>

						{/* updated */}
						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							label={i18.t('properties_updated')}
							value={workflow.updated ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(workflow.updated)) : ''}
							InputProps={{readOnly: true}}										
						/>

						<br/>

						{/* advanced details */}
						<Grid item>
							<Button size="small" color="primary" onClick={()=>{setDetailsDialogIsOpen(true)}}>
								{i18.t('dialog_workflow_details')}
							</Button>

							{ detailsDialogIsOpen ?
								<WorkflowAdvDetailsDialog
									navigation={navigation}
									workflow={workflow}
									versions={versions}
									designTime={designTime}
									setDetailsDialogIsOpen={setDetailsDialogIsOpen}
									updateWorkflow={updateWorkflow}
									retrieveAutomation={retrieveAutomation}
									createAutomation={createAutomation}
									retrieveVersions={retrieveVersions}
									restoreVersion={restoreVersion}
								/>		
							: null }
						</Grid>
						
					</Grid>
				</Grid>
								
				<Grid item xs={0} sm={3} md={5} >
				
					<Grid item style={{paddingLeft: 20, paddingRight: 20}} >

						{ workflow.imageref !== undefined && workflow.imageref !== null ?
							<CardMedia
								style={{ height: 225 }}
								image={workflow.imageref ? workflow.imageref : null}
								title={workflow.name}
								onClick={()=>setImageDialogIsOpen(true)}
							/>
						: null}

						<ImageRefDialog 
							imageDialogIsOpen={imageDialogIsOpen}
							workflow={workflow} 
							updateWorkflow={updateWorkflow} 
							setImageDialogIsOpen={setImageDialogIsOpen}
						/>		

					</Grid>						
				</Grid>
			</Grid>
		
			<Grid item xs={12}>
				
				{/* tag list */}
				<Grid container direction='row' alignItems='center'>
					<Typography variant='subtitle2' style={{color: 'grey'}}>{i18.t('workflow_tags')}</Typography>
					<TagBar 
						tagList={workflow.tags}
						selectedTags={workflow.tags}
						allTags={tags} 
						editMode={!['canceled','completed'].includes(workflow.state)} 
						onUpdate={(selectedTags)=>{workflow.tags=selectedTags;updateWorkflow(workflow)}}
					/>
				</Grid>
				
				<br/>

				{/* attachments */}
				<Grid container id='123' direction='row' spacing={4} alignItems='center'>
					<Grid item>
						<Typography variant='subtitle2' style={{color: 'grey'}}>{i18.t('workflow_attachments')}</Typography>
					</Grid>
					{ designTime ?
						<Grid item>
							<Button color="primary" onClick={createAttachment} >
								{i18.t('workflow_add_attachment')}
							</Button>
						</Grid>
					: null }
				</Grid>

				<AttachmentList
					workflowId={workflow.workflowId}
					rootparentType={'workflow'}
					rootparentId={workflow.workflowId} 
					rootparentState={workflow.state}
					attachmentList={workflow.attachments}
					designTime={designTime}
					disabled={workflow.state === 'completed' || workflow.state === 'canceled'}
					deleteAttachment={deleteAttachment}
				/>

				<br/>

				{/* properties */}
				<Grid container id='123' direction='row' spacing={4} alignItems='center'>
					<Grid item>
						<Typography variant='subtitle2' style={{color: 'grey'}}>{i18.t('workflow_properties')}</Typography>
					</Grid>
					{ designTime ?
						<Grid item>
							<Button color="primary" onClick={()=>addWorkflowProperty(workflow.workflowId)} >
								{i18.t('workflow_add_property')}
							</Button>
						</Grid>
					: null }
				</Grid>

				<PropertyList 
					rootparentType={'workflow'}
					rootparentId={workflow.workflowId} 
					parentType={'workflowproperty'} 
					parentId={workflow.workflowId} 
					propertyList={workflow.properties}
					selectedProperty={selectedProperty}
					designTime={designTime}
					disabled={workflow.state === 'completed' || workflow.state === 'canceled'}
					setSelectedProperty={setSelectedProperty}
					deleteProperty={deleteProperty}
				/>
				
			</Grid>

		</Grid>
	)	
}

export default WorkflowDetail
