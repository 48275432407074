import { useState } from 'react'
import { Grid, Hidden, Button, Divider, List, ListItem, Tooltip, Typography, FormControl, MenuItem, CircularProgress, Chip, Icon } from '@mui/material'
import { screens } from '../components/Main'
import * as i18 from 'i18next'
import UUID from 'uuidjs'

import TagBar from '../components/TagBar'


const MainMenu = ({ stack, navigation, showSettings, app, tagFilter, createWorkflow, setTagFilter }) => {
	
//	console.log('MainMenu')

	let menu = []

	if (showSettings) {
		menu.push({screen: 'settings'})
		menu.push({screen: 'profile',})
		menu.push({screen: 'settings_list',})
		menu.push({screen: 'propertydefinition_list',})
		menu.push({screen: 'extract_list', count: app.extractsCount})
		menu.push({screen: 'automation_list', count: app.automationsCount})
		menu.push({screen: 'graphiql',})
		menu.push({screen: 'test',})

		menu.push({screen: 'supportcase_list'})
		menu.push({screen: 'supportcase_working_list', count: app.openSupportCasesCount})
		menu.push({screen: 'supportcase_completed_list', count: app.closedSupportCasesCount})
	} else {
		menu.push({screen: 'assignment_list'})
		menu.push({screen: 'assignment_working_list', count: app.workingAssignmentsCount})
		menu.push({screen: 'assignment_completed_list', count: app.completedAssignmentsCount})

		menu.push({screen: 'document_list'})
		menu.push({screen: 'document_working_list', count: app.workingDocumentsCount === null ? -1 : app.workingDocumentsCount})
//		menu.push({screen: 'document_template_list', count: app.templateDocumentsCount === null ? -1 : app.templateDocumentsCount})

		menu.push({screen: 'workflow_list'})
		menu.push({screen: 'workflow_draft_list', count: app.draftWorkflowsCount === null ? -1 : app.draftWorkflowsCount})
		menu.push({screen: 'workflow_working_list', count: app.workingWorkflowsCount === null ? -1 : app.workingWorkflowsCount})
		menu.push({screen: 'workflow_completed_list', count: app.completedWorkflowsCount === null ? -1 : app.completedWorkflowsCount})
		
		menu.push({screen: 'template_list'})
		menu.push({screen: 'workflow_template_list', count: app.templateWorkflowsCount === null ? -1 : app.templateWorkflowsCount})
		menu.push({screen: 'document_template_list', count: app.templateDocumentsCount === null ? -1 : app.templateDocumentsCount})
	}
	
	let selected = menu.findIndex(menu => menu.screen === stack[1]?.screen)

	let tags
	switch (stack?.[1]?.screen) {
		case 'assignment_working_list': tags = app.workingAssignmentsTagList ? app.workingAssignmentsTagList : []; break
		case 'assignment_completed_list': tags = app.completedAssignmentsTagList ? app.completedAssignmentsTagList : []; break
		case 'document_working_list': tags = app.workingDocumentsTagList ? app.workingDocumentsTagList : []; break
		case 'document_template_list': tags = app.templateDocumentsTagList ? app.templateDocumentsTagList : []; break
		case 'workflow_draft_list': tags = app.draftWorkflowsTagList ? app.draftWorkflowsTagList : []; break
		case 'workflow_working_list': tags = app.workingWorkflowsTagList ? app.workingWorkflowsTagList : []; break
		case 'workflow_completed_list': tags = app.completedWorkflowsTagList ? app.completedWorkflowsTagList : []; break
		case 'workflow_template_list': tags = app.templateWorkflowsTagList ? app.templateWorkflowsTagList : []; break
		case 'supportcase_working_list': tags = app.openSupportCasesTagList ? app.openSupportCasesTagList : []; break
		case 'supportcase_completed_list': tags = app.closedSupportCasesTagList ? app.closedSupportCasesTagList : []; break
		case 'extract_list': tags = app.extractsTagList ? app.extractsTagList : []; break
		case 'propertydefinition_list': tags = app.propertyTagList; break
	}

	const height = document.getElementById('main-view-row2')?.clientHeight

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='main-menu' style={{width: '100%', height: '100%', overflow: 'auto'}} >

			<Grid container direction='column'>

				{ showSettings ?
					<Icon/>
				:
					<Tooltip title={i18.t('workflow_tooltip_create_draft')} style={{paddingTop: 10}} >
						<Button 
							style={{width: '90%'}}
							size='small'
							variant="contained" 
							color="primary" 
							onClick={()=>createWorkflow(navigation)}
						>
							<Hidden only={['xs', 'sm']}>
								{i18.t('workflow_create_draft')}
							</Hidden>
							<Hidden only={['md', 'lg', 'xl']}>
								{i18.t('workflow_create_draft_sm')}
							</Hidden>				
						</Button>
					</Tooltip>
				}
							
				<Grid item style={{paddingTop: 20}} >
					{menu.map((item, index) => 
						<Grid item key={index} >
							{screens[item.screen].level === 0 ?
								<Grid item >
									{ index !== 0 ?
										<br/>
									: null }
									<Grid container direction='row'>
										<Divider/>
										{screens[item.screen].icon ? screens[item.screen].icon : null}
										<Hidden mdDown >
											<Typography style={{paddingLeft: 5}}><b>{screens[item.screen].title1}</b></Typography>
										</Hidden>
										<Hidden mdUp >
											<Typography><b>{screens[item.screen].title2}</b></Typography>
										</Hidden>
									</Grid>
								</Grid>
							:
								<MenuItem 
									value={index}
									onClick={()=>{index !== selected ? setTagFilter([]) : null;navigation.reset(item.screen);}} 
									selected={index === selected}
								>
									<Grid container>
										{screens[item.screen].icon ? screens[item.screen].icon : null}
										<Hidden mdDown >
											<Typography style={{paddingLeft: 5, paddingRight: 5}}>{screens[item.screen].title1}</Typography>
											{ screens[item.screen].level && item.count !== undefined ? 
												item.count === -1 ? 
													<Grid><CircularProgress size={15}/></Grid> 
												: 
													<Typography> ({item.count > 10000 ? '10,000+' : Number(item.count).toLocaleString("en-US")})</Typography>
											: null}
										</Hidden>
										<Hidden mdUp >
											<Typography>{screens[item.screen].title2}</Typography>
										</Hidden>
									</Grid>
								</MenuItem>
							}
						</Grid>
					)}

					{ (tagFilter && stack.length <= 2) ?
						<Grid item>
							<br/>
							<Button onClick={()=>setTagFilter([])} color='primary' disabled={tagFilter.length === 0}>{i18.t('menu_clear_tags')}</Button>
							<TagBar 
								tagList={tags} 
								selectedTags={tagFilter} 
								editMode={false} 
								onSelectionUpdate={(selectedTags) => {setTagFilter(selectedTags)}}
							/>
						</Grid>
					: null }
					
				</Grid>
				
			</Grid>
		</Grid>
	)
}

export default MainMenu
