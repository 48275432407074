import React, { useState } from 'react'
import { Grid, Button, Tooltip, Hidden, CircularProgress } from '@mui/material'
import { ArrowBack, Save, PlayArrow, Delete, Cancel, GetApp, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'
import { denormalize } from 'normalizr'
import cloneDeep from 'lodash.clonedeep'

import ActionButton from '../components/ActionButton'
import ConfirmDeletionDialog from '../components/dialogs/ConfirmDeletionDialog'
import { propertySchema } from '../reducers/workflows'


const ExtractToolBar = ({ navigation, extract, properties, saveExtract, deleteExtract }) => {

//	console.log('ExtractToolBar')

	const [isSaving, setIsSaving] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

	const exportExtract = (extract) => {

		if (!extract) return

		extract = cloneDeep(extract)
		extract.properties = denormalize(extract.properties, [propertySchema], {properties: properties});

		const blob = new Blob([JSON.stringify(extract, null, 2)], {type: "application/json"})
		const element = document.createElement("a")
		element.href = URL.createObjectURL(blob)
		element.download = `${extract.name}.json`
		document.body.appendChild(element)
		element.click()
	}

	return (
		<Grid item id='toolbar' >

			<Grid container direction="row" alignItems='center' >

				<Tooltip title={i18.t('general_back_tooltip')} >
					<Button color="primary" onClick={()=>navigation.pop()} ><ArrowBack/></Button>
				</Tooltip>

				<ActionButton tooltipText={i18.t('extraction_save_tooltip')} mdUp={i18.t('workflow_save')} mdDown={<Save/>} inProgress={isSaving} onClick={async ()=>{ await saveExtract(extract.extractId, setIsSaving)}} />
				
				<ActionButton tooltipText={i18.t('extraction_discard_tooltip')} mdUp={i18.t('extraction_discard')} mdDown={<Delete/>} inProgress={isDeleting} onClick={async ()=>{setIsDeleteDialogOpen(true)}} />

				<ActionButton tooltipText={i18.t('workflow_tooltip_export_workflow')} mdUp={i18.t('general_export')} mdDown={<GetApp/>} onClick={async ()=>{ await exportExtract(extract)}} />

				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_item_tooltip')} >
					<Button color="primary" onClick={async ()=>{await retrieveWorkflow(workflowId)}} >
						<Refresh/>
					</Button>
				</Tooltip>						
				
			</Grid>

			{ isDeleteDialogOpen ?
				<ConfirmDeletionDialog 
					dialogTitle={i18.t('dialog_delete_extract_title')} 
					dialogContent={i18.t('dialog_delete_extract_desc')}
					onDelete={()=>{deleteExtract(extract.extractId);navigation.reset('extract_list')}}
					setDialogIsOpen={setIsDeleteDialogOpen}
				/>
			: null }

		</Grid>
	)
}

export default ExtractToolBar
