import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Button, Tooltip } from '@mui/material'
import * as i18 from 'i18next'

import ExtractList from '../components/ExtractList'
import TagBar from '../components/TagBar'
import { retrieveExtract, retrieveExtracts, createExtract } from '../reducers/extracts'


const ExtractSelectionView = ({ navigation, extractsList, extractsCount, extracts, documents, tags, selectedExtract, onSelect, 
								retrieveExtract, createExtract }) => {
 
//	console.log('ExtractSelectionView')

	const [tagFilter, setTagFilter] = useState([])

	// Filter extract list accordingly
	extractsList = extractsList?.filter(extractId => {
		if (tagFilter && tagFilter.length > 0) {			
			for (const tag of tagFilter) {
				if (extracts[extractId].tags && extracts[extractId].tags.includes(tag)) {
					return extractId
				}
			}
		} else {
			return extractId
		}
	})

	return (
		<Grid item>
		
			<TagBar 
				tagList={tags} 
				selectedTags={tagFilter} 
				editMode={false} 
				onSelectionUpdate={(selectedTags)=>setTagFilter(selectedTags)}
			/>

			<ExtractList 
				navigation={navigation}
				tagFilter={tagFilter}
				extractsList={extractsList}
				extractsCount={extractsCount}
				extracts={extracts}
				documents={documents}
				selectedExtract={selectedExtract}
				onSelect={onSelect}
				retrieveExtract={retrieveExtract}
			/>
		</Grid>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	extractsList: state.app.extractsList,
	extractsCount: state.app.extractsCount,
	extracts: state.extracts,
	documents: state.documents,
	tags: state.app.extractsTagList,
})

export default connect(mapStateToProps, { retrieveExtracts, retrieveExtract, createExtract })(ExtractSelectionView)
