import { NAVIGATE_PUSH_EVENT, NAVIGATE_POP_EVENT, NAVIGATE_RESET_EVENT } from './actiontypes'
import { RECEIVE_ERRORS, CLOSE_ERROR, CLEAR_ERRORS, SET_SCOPE, EXPAND_OBJECT_EVENT } from './actiontypes'


export const setExpanded = (objectId, expanded) => dispatch => {
	dispatch({type: EXPAND_OBJECT_EVENT, objectId: objectId, expanded: expanded})			
}

export const navigate = (screen, params) => dispatch => {
	dispatch({type: NAVIGATE_PUSH_EVENT, screen: screen, params: params})
}

export const pop = () => dispatch => {
	dispatch({type: NAVIGATE_POP_EVENT})
}

export const reset = (screen, params={}) => dispatch => {
	dispatch({type: NAVIGATE_RESET_EVENT, screen: screen, params: params})
}

export const closeError = (index) => dispatch => {
	dispatch({type: CLOSE_ERROR, index: index})
}

export const clearErrors = () => dispatch => {
	dispatch({type: CLEAR_ERRORS})
}

export const initialState = {
	main: {
		stack: [],
		mainMenuOpen: true,
	},
}

const appnav = (state = initialState, action) => {
	switch (action.type) {

		case EXPAND_OBJECT_EVENT:
			state = {...state,
				expanded: {...state.expanded,
					[action.objectId]: action.expanded
				}
			}
		break
		
		case NAVIGATE_PUSH_EVENT:
			state = {...state}
			state.main.stack.push({screen: action.screen, params: action.params})
			break
		
		case NAVIGATE_POP_EVENT:
			state = {...state}
			state.main.stack.pop()
			break
		
		case NAVIGATE_RESET_EVENT:
			state = {...state}
			state.main.stack = [{screen: 'main_menu'}, {screen: action.screen, params: action.params}]
			break
		
		case RECEIVE_ERRORS:
			state = {...state}
			if (action.errors) {
				if (!state.main.errors) state.main.errors = []
				state.main.errors = [...state.main.errors, ...action.errors]
			}
			break
		
		case CLOSE_ERROR:
			state = {...state}
			state.main.errors.splice(action.index, 1)
			break

		case CLEAR_ERRORS:
			state = {...state}
			state.main.errors = []
			break

//		case SET_SCOPE:
//			state = {...state,
//				scope: action.scope.orgentityId ? action.scope : null
//			}
//			break

		default:
			break
	}

	return state
}

export default appnav


