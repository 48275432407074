import { Grid, Tabs, Tooltip, Tab } from '@mui/material'
import * as i18 from 'i18next'


const TabBar = ({ tabValues, tabLabels, tab, setTab }) => (
	<Grid item id='tabbar' >
		<Tabs 
			value={tab} 
			onChange={(event, value)=>setTab(value)} 
			indicatorColor="primary" 
			textColor="primary" 
			variant="scrollable"
			scrollButtons="auto"
		>
			{tabValues.map((value, index) =>
				<Tooltip key={index} value={value} title={i18.t(`${value}_tooltip`)} ><Tab label={tabLabels ? tabLabels[index] : i18.t(value)} style={{minWidth: 80}} /></Tooltip>
			)}
		</Tabs>	
	</Grid>
)

export default TabBar