import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'

import Extract from '../components/Extract'
import ExtractToolBar from '../components/ExtractToolBar'

import { retrieveDocument, retrieveContent } from '../reducers/documents'
import { moveProperty, addProperty, deleteExtractProperty, moveCompositeProperty, updateProperty, moveToCompositeProperty } from '../reducers/properties'
import { updateExtract, saveExtract, retrieveExtract, deleteExtract } from '../reducers/extracts'


const ExtractView = ({ navigation, extract, properties, memberProperties, documents, tags, 
						updateExtract, saveExtract, retrieveExtract, deleteExtract, addProperty, deleteExtractProperty, 
						moveProperty, moveCompositeProperty, updateProperty, moveToCompositeProperty, retrieveDocument, retrieveContent }) => {

//	console.log('ExtractView')
	
	if (!extract) return null

	const onDragEnd = (extractId, result, moveProperty, moveCompositeProperty) => {
		if (result.source && result.destination) {
			let type = result.type.split('::')[0]
			let droppableId = result.destination.droppableId.split('::',2)[1]
			if (type === 'extract') {
				moveProperty(extractId, result.source.index, result.destination.index)
			} else if (type === 'compositeproperty') {
				moveCompositeProperty(extractId, droppableId, result.source.index, result.destination.index)
			}
		}
	}

	const height = document.getElementById('main')?.clientHeight ? document.getElementById('main')?.clientHeight : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 40
	const width = document.getElementById('main')?.clientWidth ? document.getElementById('main')?.clientWidth : 0

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<DragDropContext style={{background: 'transparent'}} onDragEnd={(result)=>{onDragEnd(extract.extractId, result, moveProperty, moveCompositeProperty)}} >

			<ExtractToolBar
				navigation={navigation}
				extract={extract}
				properties={properties}
				saveExtract={saveExtract}
				deleteExtract={deleteExtract}
				exportExtract
			/>					
			
			<Grid container id='outer-workflow' style={{width: width, height: (height - toolbarHeight)}} >
				<Extract
					navigation={navigation}
					extract={extract}
					properties={properties}
					memberProperties={memberProperties}
					documents={documents}
					tags={tags}
					designTime={true}

					updateExtract={updateExtract}
					retrieveExtract={retrieveExtract}
					addProperty={(extractId, property) => addProperty(extractId, property)}
					deleteProperty={(extractId, propertyId) => deleteExtractProperty(extractId, propertyId)}
					updateProperty={updateProperty}
					moveToCompositeProperty={moveToCompositeProperty}
					retrieveDocument={retrieveDocument}
					retrieveContent={retrieveContent}
				/>
			</Grid>

		</DragDropContext>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	extract: state.extracts[props.navigation.params.extractId],
	name: state.extracts[props.navigation.params.extractId]?.name,
	properties: state.properties[props.navigation.params.extractId],
	memberProperties: state.properties[state.app.me.orgentityId],
	documents: state.documents,
	tags: state.app.extractsTagList,
	tags2: state.extracts[props.navigation.params.extractId]?.tags,
})

export default connect(mapStateToProps, { updateExtract, saveExtract, retrieveExtract, deleteExtract, addProperty, deleteExtractProperty,
											moveProperty, moveCompositeProperty, updateProperty, moveToCompositeProperty, retrieveDocument, retrieveContent })(ExtractView)
