import { combineReducers } from 'redux'
import app from './app'
import actions from './actions'
import assignments from './assignments'
import automations from './automations'
import documents from './documents'
import events from './events'
import extracts from './extracts'
import graphiql from './graphiql'
import orgs from './orgs'
import properties from './properties'
import supportcases from './supportcases'
import taskinstances from './taskinstances'
import tasks from './tasks'
import workflows from './workflows'
import attachments from './attachments'
import comments from './comments'
import tags from './tags'
import versions from './versions'

import appnav from './appnav'


export default combineReducers({
	app: app,
	orgs: orgs,
	actions: actions,
	assignments: assignments,
	automations: automations,
	documents: documents,
	attachments: attachments,
	events: events,
	extracts: extracts,
	graphiql: graphiql,
	properties: properties,
	supportcases: supportcases,
	taskinstances: taskinstances,
	tasks: tasks,
	workflows: workflows,
	comments: comments,
	tags: tags,
	versions: versions,
	
	appnav: appnav,
})


