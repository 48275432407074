import React from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import * as i18 from 'i18next'

import TaskRouteCondition from '../components/TaskRouteCondition'


const TaskWhere = ({ me, workflowId, task, attachments, allProperties, documents, updateTask, designTime }) => {
	
//	console.log('TaskWhere')

	if (!workflowId || !task)
		return null

	return (
	
		<Grid item xs={12}>

			<TaskRouteCondition 
				me={me} 
				workflowId={workflowId} 
				task={task} 
				attachments={attachments}
				allProperties={allProperties} 
				documents={documents}
				updateTask={updateTask} 
				designTime={designTime} 
			/>
			
		</Grid>
	)
}

export default TaskWhere
