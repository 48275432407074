import React, { useState } from 'react'
import { Grid, Tooltip, Icon } from '@mui/material'
import { DragIndicator, RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

import MilestoneAction from '../components/MilestoneAction'

 
const MilestoneActionListItem = ({ me, workflowId, task, attachments, allProperties, documents, milestoneActionIndex, focus, designTime, updateTask, setFocus }) => {

//	console.log('MilestoneActionListItem')

	const [hover, setHover] = useState(false)

	let action = task.milestoneActions[milestoneActionIndex]

	if (!action) return null

	const [expanded, setExpanded] = useState(false)
	
	return (
		<Grid
			container
			direction={'row'} 
			alignItems="center" 
			wrap="nowrap"
			onMouseMove={(e) => {e.stopPropagation();if(!hover){setHover(true)};}}
			onMouseEnter={(e) => {e.stopPropagation();setHover(true);}}
			onMouseLeave={(e) => {e.stopPropagation();setHover(false);}}
		>
									
			{ designTime && hover ?
				<Tooltip title={i18.t('property_tooltip_drag_property')} >
					<DragIndicator style={{cursor: 'grab'}}/>
				</Tooltip>
			: 
				<Icon/>
			}

			<MilestoneAction
				me={me}
				workflowId={workflowId}
				task={task}
				attachments={attachments}
				allProperties={allProperties}
				documents={documents}
				milestoneActionIndex={milestoneActionIndex}
				focus={focus}
				designTime={designTime}
				updateTask={updateTask}
				setFocus={setFocus}
			/>

			
			{ designTime && hover ?
				<RemoveCircle style={{cursor: 'default', height: 20, width: 20}} color="primary" onClick={()=>{task.milestoneActions.splice(milestoneActionIndex, 1);updateTask(workflowId, task);}} />
			: 
				<Icon/> 
			}
			
		</Grid>	
	)
}

export default MilestoneActionListItem