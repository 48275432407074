import React, { useState } from 'react'
import { Grid, Tooltip, Button, List, ListItem, ListSubheader, ListItemText, Typography } from '@mui/material'
import { Save, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import PropertyView from '../containers/PropertyView'


const OrgPropertyList = ({ sourceId, tagFilter, sourceProperties, addProperty, retrieve, save, update }) => {
 
//	console.log('OrgPropertyList', sourceId)

	if (!sourceId || !sourceProperties) return null

	let propertyList = sourceProperties ? Object.keys(sourceProperties) : []
	
	if (tagFilter?.length > 0) {
		let filteredPropertyList = []
		for (const propertyId of propertyList) {
			let property = sourceProperties[propertyId]
			for (const tag of tagFilter) {
				if (property.tags?.includes(tag)) {
					filteredPropertyList.push(propertyId)
				}
			}
		}
		propertyList = [...new Set(filteredPropertyList)]
	}
	
	const [selectedProperty, setFocusPropertyId] = useState(propertyList?.[0])

	if (selectedProperty && !propertyList.includes(selectedProperty)) {
		setFocusPropertyId(null)
	}
	
	let properties = propertyList.map(propertyId => sourceProperties[propertyId])
	
	let sections = [...new Set(properties.map(property => property.group).filter(section => section !== null))]

	return (
		<Grid item>

			<Grid container direction={'row'}>
				<Tooltip title={i18.t('org_save_tooltip')} >
					<Button color="primary" onClick={save} ><Save/>{i18.t('org_save')}</Button>
				</Tooltip>
					
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={retrieve} ><Refresh/></Button>
				</Tooltip>
			</Grid>
		
				<Grid container direction={'row'} spacing={2} >

					<Grid item xs={6} style={{cursor: 'default'}} >
						<Grid item style={{width: '40%', height: 'calc(100% - 100px)', position: 'absolute', overflow: 'auto'}} >	
							<List subheader={<li />}>
								{sections.map((section, index) => (
									<li key={index} >
									<ul>
										<ListSubheader><Typography variant='h6'>{i18.t('property_' + section)}</Typography></ListSubheader>
										{properties.filter(property => property.group === section).map((property, index2) => (
											<ListItem
												selected={selectedProperty === property.propertyId}
												key={index2}
												onClick={()=> setFocusPropertyId(property.propertyId)} 
												button
												style={{paddingLeft: 15}}
											>
												<ListItemText primary={i18.t(`property_${property.subType}`)} />
											</ListItem>
										))}
									</ul>
									</li>
								))}
							</List>
						</Grid>
					</Grid>
									
					<Grid item xs={6} >
						<PropertyView 
							rootparentType={'org'}
							rootparentId={sourceId} 
							parentId={sourceId} 
							propertyId={selectedProperty}
							disabled={true}
							showDisabledProps={true}
						/>					
					</Grid>
					
			</Grid>
		</Grid>				
	)
}

export default OrgPropertyList
