import React, { useState } from "react";
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { Grid, Divider } from '@mui/material'


const VirtualList = ({ children: Child, objList, idList, listCount, retrieve }) => {

	let mainHeight = document.getElementById('main-view-row')?.clientHeight ? document.getElementById('main-view-row')?.clientHeight : 0
	let buttonbarHeight = document.getElementById('buttonbar')?.clientHeight ? document.getElementById('buttonbar')?.clientHeight : 0
	
	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)
	
	return (
		<Grid item id='virtuallist'>
			<InfiniteLoader
				itemCount={listCount}
				isItemLoaded={(index) => objList[idList[index]]}
				loadMoreItems={(startIndex, stopIndex)=>retrieve(startIndex)}
			>
			{({ onItemsRendered, ref}) => (
			<FixedSizeList
				height={mainHeight - buttonbarHeight}
				onItemsRendered={onItemsRendered}
				itemSize={50}
				itemCount={listCount}
				loadMoreItems={()=>console.log('here888')}
				ref={ref}
			>
				{({ index, style }) => {

					let obj = null
					if (objList && idList) {
						obj = objList[idList[index]]
					}
		
					if (!obj) return null

					return (
						<div style={style} >
							<Child item={obj} index={index}/>
							<Divider/>					
						</div>
					)
				}}
			</FixedSizeList>
			)}
			</InfiniteLoader>
		</Grid>
	)
}

export default VirtualList