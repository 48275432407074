import workflow_simple from './workflow_simple.json'


const stringify = (str) => {
	return JSON.stringify(str).replace(/"(\w+)"\s*:/g, '$1:')
}

let newworkflow_base = {...workflow_simple}

export const performanceCreate = [
	{
		name: 'Create 10 draft simple workflows and run them.',
		tests: [
			{
				id: 'PERF-CREATED10',
				name: 'Create draft simple workflow and run.',
				type: 'setup',
				iterations: 10,
				operation: `mutation {createWorkflow (input: ${stringify(newworkflow_base)}, optimizeForHVP: true) {workflowId, state}}`,
				onEachItem: [{
					id: 'PERF-CREATED10a',
					name: 'Run draft workflow.',
					type: 'positive',
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 100 draft simple workflows and run them.',
		tests: [
			{
				id: 'PERF-CREATED100',
				name: 'Create draft simple workflow and run.',
				type: 'setup',
				iterations: 100,
				operation: `mutation {createWorkflow (input: ${stringify(newworkflow_base)}, optimizeForHVP: true) {workflowId, state}}`,
				onEachItem: [{
					id: 'PERF-CREATED100a',
					name: 'Run draft workflow.',
					type: 'positive',
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 500 draft simple workflows and run them.',
		tests: [
			{
				id: 'PERF-CREATED500',
				name: 'Create draft simple workflow and run.',
				type: 'setup',
				iterations: 500,
				operation: `mutation {createWorkflow (input: ${stringify(newworkflow_base)}, optimizeForHVP: true) {workflowId, state}}`,
				onEachItem: [{
					id: 'PERF-CREATED500a',
					name: 'Run draft workflow.',
					type: 'positive',
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 1000 draft simple workflows and run them.',
		tests: [
			{
				id: 'PERF-CREATED1000',
				name: 'Create draft simple workflow and run.',
				type: 'setup',
				iterations: 1000,
				operation: `mutation {createWorkflow (input: ${stringify(newworkflow_base)}, optimizeForHVP: true) {workflowId, state}}`,
				onEachItem: [{
					id: 'PERF-CREATED1000a',
					name: 'Run draft workflow.',
					type: 'positive',
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 2000 draft simple workflows and run them.',
		tests: [
			{
				id: 'PERF-CREATED2000',
				name: 'Create draft simple workflow and run.',
				type: 'setup',
				iterations: 2000,
				operation: `mutation {createWorkflow (input: ${stringify(newworkflow_base)}, optimizeForHVP: true) {workflowId, state}}`,
				onEachItem: [{
					id: 'PERF-CREATED2000a',
					name: 'Run draft workflow.',
					type: 'positive',
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 5000 draft simple workflows and run them.',
		tests: [
			{
				id: 'PERF-CREATED5000',
				name: 'Create draft simple workflow and run.',
				type: 'setup',
				iterations: 5000,
				operation: `mutation {createWorkflow (input: ${stringify(newworkflow_base)}, optimizeForHVP: true) {workflowId, state}}`,
				onEachItem: [{
					id: 'PERF-CREATED5000a',
					name: 'Run draft workflow.',
					type: 'positive',
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
]

export const performanceRun = [
	{
		name: 'Run 10 simple workflows from a template.',
		tests: [
			{
				id: 'PERF-CREATET10',
				name: 'Query for simple workflow template.',
				type: 'setup',
				operation: `query {workflows (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{workflowId}}}`,
				onEachItem: [{
					id: 'PERF-CREATET10a',
					name: 'Run simple workflow from template.',
					type: 'positive',
					iterations: 10,
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Run 100 simple workflows from a template.',
		tests: [
			{
				id: 'PERF-CREATET100',
				name: 'Query for simple workflow template.',
				type: 'setup',
				operation: `query {workflows (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{workflowId}}}`,
				onEachItem: [{
					id: 'PERF-CREATET100a',
					name: 'Run simple workflow from template.',
					type: 'positive',
					iterations: 100,
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Run 500 simple workflows from a template.',
		tests: [
			{
				id: 'PERF-CREATET500',
				name: 'Query for simple workflow template.',
				type: 'setup',
				operation: `query {workflows (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{workflowId}}}`,
				onEachItem: [{
					id: 'PERF-CREATET500a',
					name: 'Run simple workflow from template.',
					type: 'positive',
					iterations: 500,
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Run 1000 simple workflows from a template.',
		tests: [
			{
				id: 'PERF-CREATET1000',
				name: 'Query for simple workflow template.',
				type: 'setup',
				operation: `query {workflows (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{workflowId}}}`,
				onEachItem: [{
					id: 'PERF-CREATET1000a',
					name: 'Run simple workflow from template.',
					type: 'positive',
					iterations: 1000,
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Run 5000 simple workflows from a template.',
		tests: [
			{
				id: 'PERF-CREATET5000',
				name: 'Query for simple workflow template.',
				type: 'setup',
				operation: `query {workflows (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{workflowId}}}`,
				onEachItem: [{
					id: 'PERF-CREATET5000a',
					name: 'Run simple workflow from template.',
					type: 'positive',
					iterations: 5000,
					operation: `mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}, optimizeForHVP: true) {workflowId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
]

export const performanceQuery = [
	{
		name:"Complete 10 iterations of Query 50 assignments.",
		tests: [
			{
				id: 'PERF-QUERYONLY10',
				name: 'Query assignments.',
				type: 'positive',
				iterations: 10,
				operation: `query {assignments (states: ["working"], limit: 50, includeTotalCount: true) {totalCount, items{assignmentId, instanceData, state}}}`,
				assertResultIncludes: ["totalCount","items"],
			},
		]
	},
	{
		name:"Complete 100 iterations of query 10 workflows and then query the detail for each workflow.",
		tests: [
			{
				id: 'PERF-QUERY100/10',
				name: 'Query workflows.',
				type: 'setup',
				iterations: 100,
				operation: `query {workflows (states: ["working"], limit: 10, includeTotalCount: true) {totalCount, items{workflowId, instanceData, state}}}`,
				onEachItem: [{
					id: 'PERF-QUERY100/10a',
					name: 'Query worfklow detail.',
					type: 'positive',
					operation: `query {workflows (workflowId: "<<workflowId>>") {totalCount, items{workflowId, instanceData, state}}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name:"Query and complete 10 assignments.",
		tests: [
			{
				id: 'PERF-QUERY10',
				name: 'Query assignments.',
				type: 'setup',
				operation: `query {assignments (states: ["working"], limit: 10, includeTotalCount: true) {totalCount, items{assignmentId, instanceData, state}}}`,
				onEachItem: [{
					id: 'PERF-QUERY10a',
					name: 'Complete assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId: "<<assignmentId>>", instanceData: <<instanceData>>}) {assignmentId, state}}`,
					assertResultIncludes: [`"state":"completed"`],
				}]
			},
		]
	},
	{
		name:"Query and complete 10 iterations of 1 assignments (100 total).",
		tests: [{
			id: 'PERF-QUERY100',
			name: 'Query assignments.',
			type: 'setup',
			iterations: 10,
			operation: `query {assignments (states: ["working"], limit: 1, includeTotalCount: true) {totalCount, items{assignmentId, instanceData, state}}}`,
			onEachItem: [{
				id: 'PERF-QUERY100a',
				name: 'Query assignments.',
				type: 'setup',
				operation: `query {assignments (states: ["working"], skip: <<RND>>, limit: 1, includeTotalCount: false) {totalCount, items{assignmentId, instanceData, state}}}`,
				onEachItem: [{
					id: 'PERF-QUERY100aa',
					name: 'Complete assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>", instanceData: <<instanceData>>}, optimizeForHVP: true) {assignmentId, state}}`,
					assertResultIncludes: [`"state":"completed"`],
				}]
			}]
		}]
	},
	{
		name:"Query and complete 100 iterations of 1 assignments (100 total).",
		tests: [{
			id: 'PERF-QUERY100',
			name: 'Query assignments.',
			type: 'setup',
			iterations: 100,
			operation: `query {assignments (states: ["working"], limit: 1, includeTotalCount: true) {totalCount, items{assignmentId, instanceData, state}}}`,
			onEachItem: [{
				id: 'PERF-QUERY100a',
				name: 'Query assignments.',
				type: 'setup',
				operation: `query {assignments (states: ["working"], skip: <<RND>>, limit: 1, includeTotalCount: false) {totalCount, items{assignmentId, instanceData, state}}}`,
				onEachItem: [{
					id: 'PERF-QUERY100aa',
					name: 'Complete assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>", instanceData: <<instanceData>>}, optimizeForHVP: true) {assignmentId, state}}`,
					assertResultIncludes: [`"state":"completed"`],
				}]
			}]
		}]
	},
	{
		name:"Query and complete 500 iterations of 1 assignments (500 total).",
		tests: [{
			id: 'PERF-QUERY500',
			name: 'Query assignments.',
			type: 'setup',
			iterations: 500,
			operation: `query {assignments (states: ["working"], limit: 1, includeTotalCount: true) {totalCount, items{assignmentId, instanceData, state}}}`,
			onEachItem: [{
				id: 'PERF-QUERY500a',
				name: 'Query assignments.',
				type: 'setup',
				operation: `query {assignments (states: ["working"], skip: <<RND>>, limit: 1, includeTotalCount: false) {totalCount, items{assignmentId, instanceData, state}}}`,
				onEachItem: [{
					id: 'PERF-QUERY500aa',
					name: 'Complete assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>", instanceData: <<instanceData>>}, optimizeForHVP: true) {assignmentId, state}}`,
					assertResultIncludes: [`"state":"completed"`],
				}]
			}]
		}]
	},
	{
		name:"Query and complete 1000 iterations of 1 assignments (1000 total).",
		tests: [{
			id: 'PERF-QUERY1000',
			name: 'Query assignments.',
			type: 'setup',
			iterations: 1000,
			operation: `query {assignments (states: ["working"], limit: 1, includeTotalCount: true) {totalCount, items{assignmentId, instanceData, state}}}`,
			onEachItem: [{
				id: 'PERF-QUERY1000a',
				name: 'Query assignments.',
				type: 'setup',
				operation: `query {assignments (states: ["working"], skip: <<RND>>, limit: 1, includeTotalCount: false) {totalCount, items{assignmentId, instanceData, state}}}`,
				onEachItem: [{
					id: 'PERF-QUERY1000aa',
					name: 'Complete assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>", instanceData: <<instanceData>>}, optimizeForHVP: true) {assignmentId, state}}`,
					assertResultIncludes: [`"state":"completed"`],
				}]
			}]
		}]
	},
	{
		name:"Query and complete 5000 iterations of 1 assignments (5000 total).",
		tests: [{
			id: 'PERF-QUERY5000',
			name: 'Query assignments.',
			type: 'setup',
			iterations: 5000,
			operation: `query {assignments (states: ["working"], limit: 1, includeTotalCount: true) {totalCount, items{assignmentId, instanceData, state}}}`,
			onEachItem: [{
				id: 'PERF-QUERY5000a',
				name: 'Query assignments.',
				type: 'setup',
				operation: `query {assignments (states: ["working"], skip: <<RND>>, limit: 1, includeTotalCount: false) {totalCount, items{assignmentId, instanceData, state}}}`,
				onEachItem: [{
					id: 'PERF-QUERY5000aa',
					name: 'Complete assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>", instanceData: <<instanceData>>}, optimizeForHVP: true) {assignmentId, state}}`,
					assertResultIncludes: [`"state":"completed"`],
				}]
			}]
		}]
	},
	
/*
	{
		name:"Query and complete 500 random assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: `query {assignments (states: ["working"]) {totalCount, items{assignmentId}}}`,
				iterations: 500,
				assertResult:  'success',
				onResult: [{
					name: 'Query random assignment from totalCount.',
					operation: `query {assignments (states: ["working"], skip: <<RND>>, limit: 1) {items{assignmentId}}}`,
					assertResult:  'success',
					onEachItem: [{
						name: 'Complete assignment.',
						operation: `mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>"}) {assignmentId, state}}`,
						assertResult: 'success',
						logResult: 'result',
					}]
				}]
			},
		]
	},
	{
		name:"Query and complete 1000 random assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"]) {totalCount, items{assignmentId}}}',
				iterations: 1000,
				assertResult:  'success',
				onResult: [{
					name: 'Query random assignment from totalCount.',
					operation: 'query {assignments (states: ["working"], skip: <<RND>>, limit: 1) {items{assignmentId}}}',
					assertResult:  'success',
					onEachItem: [{
						name: 'Complete assignment.',
						operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>"}) {assignmentId, state}}',
						assertResult: 'success',
						logResult: 'result',
					}]
				}]
			},
		]
	},
	{
		name:"Query and complete first 500 assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 0, limit: 500) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>"}) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and complete second 500 assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 500, limit: 500) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>"}) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and complete 500 assignments and increment number property in each.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 0, limit: 500) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>", instanceData: {number: <<itemindex>>} }) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and complete first 2000 assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 0, limit: 2000) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>" }) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and complete second 2000 assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 2000, limit: 2000) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>" }) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and complete third 2000 assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 4000, limit: 2000) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>" }) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and complete fourth 2000 assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 6000, limit: 2000) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>" }) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and complete fifth 2000 assignments.",
		tests: [
			{
				name: 'Query all assignments.',
				operation: 'query {assignments (states: ["working"], skip: 8000, limit: 2000) {items{assignmentId}}}',
				iterations: 1,
				assertResult:  'success',
				onEachItem: [{
					name: 'Complete assignment.',
					operation: 'mutation {completeAssignment (input: {assignmentId:"<<assignmentId>>" }) {assignmentId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Create 100 draft workflows.",
		tests: [
			{
				name: 'Create draft workflow.',
				operation: 'mutation {createWorkflow {workflowId}}',
				iterations: 100,
				assertResult:  'success',
				logResult: 'result',
			},
		]
	},
	{
		name:"Query and run all (up to 1000) draft workflows.",
		tests: [
			{
				name: 'Query all draft (up to 1000) workflows.',
				operation: 'query {workflows (states: ["draft"], limit: 1000) {items{workflowId}}}',
				iterations: 1,
				assertResult:  'success',
				logResult: 'result',
				onEachItem: [{
					name: 'Run workflow.',
					operation: 'mutation {runWorkflow (input: {workflowId:"<<workflowId>>" }) {workflowId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Query and delete all (up to 1000) draft workflows.",
		tests: [
			{
				name: 'Query all draft (up to 1000) workflows.',
				operation: 'query {workflows (states: ["draft"], limit: 1000) {items{workflowId}}}',
				iterations: 1,
				assertResult:  'success',
				logResult: 'result',
				onEachItem: [{
					name: 'Delete workflow.',
					operation: 'mutation {deleteWorkflow (workflowId:"<<workflowId>>") {workflowId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Create 100 workflows from template.",
		tests: [
			{
				name: 'Create workflow from template.',
				operation: 'mutation {saveWorkflowAs (workflowId:"5d254d24-c126-4956-80c0-949ce944a56e", state:"draft") {workflowId}}',
				iterations: 100,
				assertResult:  'success',
				logResult: 'result',
				onEachItem: [{
					name: 'Run workflow.',
					operation: 'mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}) {workflowId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Create 100 large workflows from template.",
		tests: [
			{
				name: 'Create workflow from template.',
				operation: 'mutation {saveWorkflowAs (workflowId:"131da6bf-8d09-4d03-a42b-fa6b44a1ecae", state:"draft") {workflowId}}',
				iterations: 100,
				assertResult:  'success',
				logResult: 'result',
				onEachItem: [{
					name: 'Run workflow.',
					operation: 'mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}) {workflowId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Create 100 medium workflows from template.",
		tests: [
			{
				name: 'Create workflow from template.',
				operation: 'mutation {saveWorkflowAs (workflowId:"5d254d24-c126-4956-80c0-949ce944a56e", state:"draft") {workflowId}}',
				iterations: 100,
				assertResult:  'success',
				logResult: 'result',
				onEachItem: [{
					name: 'Run workflow.',
					operation: 'mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}) {workflowId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Create 100 small workflows from template.",
		tests: [
			{
				name: 'Create workflow from template.',
				operation: 'mutation {saveWorkflowAs (workflowId:"f3893088-870c-4fb5-804d-de738731a2de", state:"draft") {workflowId}}',
				iterations: 100,
				assertResult:  'success',
				logResult: 'result',
				onEachItem: [{
					name: 'Run workflow.',
					operation: 'mutation {runWorkflow (input: {workflowId:"<<workflowId>>"}) {workflowId, state}}',
					iterations: 1,
					assertResult: 'success',
					logResult: 'result',
				}]
			},
		]
	},
	{
		name:"Create 1000 support cases.",
		tests: [
			{
				name: 'Create support case.',
				operation: 'mutation {createSupportCase (input: {summary:"support case", detail:"sc detail"}) {supportcaseId}}',
				iterations: 1000,
				assertResult:  'success',
			},
		]
	},
*/	
]

export const performanceDocumentCreate = [
	{
		name: 'Create 10 working documents from a template.',
		tests: [
			{
				id: 'PERF-CREATEDOC100',
				name: 'Query for document template.',
				type: 'setup',
				operation: `query {documents (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{documentId}}}`,
				onEachItem: [{
					id: 'PERF-CREATEDOC100a',
					name: 'Create working document instance from template.',
					type: 'positive',
					iterations: 10,
					operation: `mutation {createDocumentInstance (input: {documentId:"<<documentId>>"}, optimizeForHVP: true) {documentId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 100 working documents from a template.',
		tests: [
			{
				id: 'PERF-CREATEDOC100',
				name: 'Query for document template.',
				type: 'setup',
				operation: `query {documents (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{documentId}}}`,
				onEachItem: [{
					id: 'PERF-CREATEDOC100a',
					name: 'Create working document instance from template.',
					type: 'positive',
					iterations: 100,
					operation: `mutation {createDocumentInstance (input: {documentId:"<<documentId>>"}, optimizeForHVP: true) {documentId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 500 working documents from a template.',
		tests: [
			{
				id: 'PERF-CREATEDOC100',
				name: 'Query for document template.',
				type: 'setup',
				operation: `query {documents (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{documentId}}}`,
				onEachItem: [{
					id: 'PERF-CREATEDOC100a',
					name: 'Create working document instance from template.',
					type: 'positive',
					iterations: 500,
					operation: `mutation {createDocumentInstance (input: {documentId:"<<documentId>>"}, optimizeForHVP: true) {documentId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
	{
		name: 'Create 1000 working documents from a template.',
		tests: [
			{
				id: 'PERF-CREATEDOC100',
				name: 'Query for document template.',
				type: 'setup',
				operation: `query {documents (states: ["template"], limit: 1, includeTotalCount: true) {totalCount, items{documentId}}}`,
				onEachItem: [{
					id: 'PERF-CREATEDOC100a',
					name: 'Create working document instance from template.',
					type: 'positive',
					iterations: 1000,
					operation: `mutation {createDocumentInstance (input: {documentId:"<<documentId>>"}, optimizeForHVP: true) {documentId, state}}`,
					assertResultIncludes: [`"state":"working"`],
				}]
			},
		]
	},
]
