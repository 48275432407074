import { Tooltip, Button, Hidden, CircularProgress } from '@mui/material'

const ActionButton = ({ tooltipText, mdUp, mdDown, inProgress, disabled, onClick }) => (
	<Tooltip title={tooltipText} >
		<Button disabled={disabled} style={{minWidth: 20}} color="primary" onClick={onClick} >
			<Hidden mdUp >
				{mdDown}
			</Hidden>
			<Hidden mdDown >
				{mdUp}
			</Hidden>
			{inProgress ? <CircularProgress size={25} style={{paddingLeft: 5}} /> : null}
		</Button>
	</Tooltip>	
)

export default ActionButton
