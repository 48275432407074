import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@mui/material'
import WorkflowList from '../components/WorkflowList'
import { retrieveWorkflow, retrieveWorkflows, createWorkflow } from '../reducers/workflows'


const WorkflowListView = ({ navigation, xs, tagFilter, app, workflows, screen, tasks, taskinstances, retrieveWorkflows, retrieveWorkflow, createWorkflow }) => {

//	console.log('WorkflowListView', workflows)

	return (
		<WorkflowList 
			navigation={navigation}
			xs={xs}
			tagFilter={tagFilter}
			app={app}
			workflows={workflows} 
			screen={screen}
			tasks={tasks}
			taskinstances={taskinstances}
			refreshing={false} 
			retrieveWorkflows={retrieveWorkflows}
			retrieveWorkflow={retrieveWorkflow}
			createWorkflow={createWorkflow}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	app: state.app,
	workflows: state.workflows,
	tasks: state.tasks,	
	taskinstances: state.taskinstances,	
})

export default connect(mapStateToProps, { retrieveWorkflows, retrieveWorkflow, createWorkflow })(WorkflowListView)
