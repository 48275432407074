import React, { useState } from 'react'
import * as i18 from 'i18next'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Grid } from '@mui/material'

import ExtractionRuleListItem from '../components/ExtractionRuleListItem'


const ExtractionRuleList = ({ rootparentId, parentType, parentId, properties, 
							selectedProperty, highlightedRule, avgwordheight, designTime, level=0,
							setSelectedProperty, setHighlightedRule, setSelectionAttribute, deleteProperty, setUpdateOverlays,
							absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log("ExtractionRuleList", parentType, parentId, properties)

	if (!properties) return null

	return (
		<Droppable isDropDisabled={!designTime} droppableId={"extractdrop::" + parentId} type={`${parentType}::${level}`} >
			{(provided, snapshot) => (
				<div ref={provided.innerRef} >	
					{properties && properties.map((propertyId, index) => (
						<Draggable isDragDisabled={!designTime} key={`extractdrag::${parentId}::${propertyId}`} draggableId={`extractdrag::${parentId}::${propertyId}`} index={index} >
							{(provided, snapshot) => (
							<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
								<ExtractionRuleListItem
									rootparentId={rootparentId}
									propertyId={propertyId}
									selectedProperty={selectedProperty}
									highlightedRule={highlightedRule}
									avgwordheight={avgwordheight}
									designTime={designTime}
									
									setSelectedProperty={setSelectedProperty}
									setHighlightedRule={setHighlightedRule}
									setSelectionAttribute={setSelectionAttribute}
									deleteProperty={deleteProperty}
									setUpdateOverlays={setUpdateOverlays}
									absoluteToProportionalRect={absoluteToProportionalRect}
									proportionalToAbsoluteRect={proportionalToAbsoluteRect}
								/>
							</div>
							)}
						</Draggable>			
					))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	)
}

export default ExtractionRuleList