import React, { useState } from 'react'
import { Grid, Typography, Avatar, Tooltip, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { FormControl, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import * as i18 from 'i18next'

	
const AssignmentInstance = ({ assignment, expanded, setExpanded }) => {
	
//	console.log('AssignmentInstance')
	
	if (!assignment)
		return null
	
	// Set background color based on assignment type
	let assignmentStyle={ flex: 1, backgroundColor: 'rgb(207,216,220)', boxShadow: '0 3px 6px rgba(0,0,0,0.16)' }

	// Set the left border color on user / parent tasks based on their state
	let vStyle = { flex: 1, flexDirection: 'row', alignItems: 'center'}

	vStyle.borderLeftWidth=2
	vStyle.borderLeftColor='lightgrey'
		
	// Check for 'working' state first since there will be only one of these, if it exists, 
	// and it will represent the most current task state (which we want to represent here)
	if (assignment && assignment.state === 'working')
		vStyle.borderLeftColor='green'
	else if (assignment && assignment.state === 'completed')
		vStyle.borderLeftColor='blue'
	else if (assignment && assignment.state === 'canceled')
		vStyle.borderLeftColor='orange'

	return (
		<Accordion style={assignmentStyle} expanded={expanded} onChange={(event, expanded)=>{setExpanded(assignment.assignmentId, expanded)}} >
			<AccordionSummary expandIcon={<Tooltip title={i18.t('general_expand_item')}><ExpandMore/></Tooltip>}>
				<Grid container direction={'row'} justify="space-between" alignItems="center" >
					<Grid item>
						<Grid container direction={'row'} alignItems='center' >
							<Avatar style={{marginRight: 5, width: '10%', height: '10%'}} src={assignment.permissions ? assignment.permissions[0].grantee.thumbnailPhotoUrl : ''} />
							<Typography style={{marginRight: 5}}>{assignment.permissions ? assignment.permissions[0].grantee.email : ''}</Typography>
						</Grid>
					</Grid>
					<Typography style={{marginRight: 5}}>{i18.t(`assignment_state_${assignment.state}`)}</Typography>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>	
				<Grid item style={{flex: 1}}>

					{assignment.routeCondition && assignment.routeCondition.options && assignment.routeCondition.options.length > 1 ? (
						<Grid item>
							<Typography variant='subtitle1' >{i18.t('assignment_routing_label')}</Typography>
							<FormControl disabled={assignment.state !== 'working'} style={{flex: 1}} >
								<RadioGroup 
									value={`` + assignment.selectedRoute} 
									onChange={(event) => {
										assignment.selectedRoute = Number(event.target.value)
										updateAssignment(assignment)
									}}
								>
									{assignment.routeCondition.options.map((routeCondition, index) =>
										<FormControlLabel 
											key={index} 
											value={`` + index}
											control={<Radio style={{padding: 5}}/>} 
											label={routeCondition.name}
										/>
									)}
								</RadioGroup>
							</FormControl>
						</Grid>
					) : null}
			
					<br/>
					
					<Grid container direction={'row'} >
						<Typography style={{marginRight: 5}}>{i18.t(`general_created_label`)}</Typography>
						<Typography style={{marginRight: 5}}>{new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(assignment.created))}</Typography>
					</Grid>
						
					{assignment.updated ?
						<Grid container direction={'row'} >
							<Typography style={{marginRight: 5}}>{i18.t(`general_updated_label`)}</Typography>
							<Typography style={{marginRight: 5}}>{new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(assignment.updated))}</Typography>
						</Grid>
					: null }

				</Grid>
			</AccordionDetails>
		</Accordion>
	)
}

export default AssignmentInstance
