import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import * as i18 from 'i18next'


const ConfirmDeletionDialog = ({ dialogTitle, dialogContent, onDelete, setDialogIsOpen }) => {

	return (
		<Dialog style={{flex: 1}} open={true}>
			<DialogTitle>{dialogTitle}</DialogTitle>
			<DialogContent style={{flex: 1}}>
				{dialogContent}
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setDialogIsOpen(false)} color="secondary">
					{i18.t('dialog_cancel')}
				</Button>
				<Button onClick={()=>{onDelete();setDialogIsOpen(false);}} color="primary">
					{i18.t('dialog_delete')}
				</Button>
			</DialogActions>
		</Dialog>	
	)
}

export default ConfirmDeletionDialog