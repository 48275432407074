import React from 'react'
import { Grid, Typography, List, ListItem, Divider, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import * as i18 from 'i18next'

import MemberList from '../components/MemberList'
import MemberSelect from '../components/MemberSelect'


const TaskWho = ({ me, contacts=[], workflowId, workflowProperties, allProperties, task, designTime, disabled,
					setAssignees, onAssigneeDelete, setAssigneesType, setAssigneesGroupProperty, retrieveContacts }) => {
	
//	console.log('TaskWho')
	
	if (!task) return null

	let isGroupSelection = task.assignees ? task.assignees.type==='property' : false

	let memberGroupProps = []
	if (isGroupSelection) {
		memberGroupProps = workflowProperties.map(propertyId => allProperties[workflowId][propertyId]).filter(property => property.subType === 'email')
	}

	// Re-retrieve contacts if needed
	retrieveContacts()

	let filteredContacts = [...contacts]
	if (filteredContacts.findIndex(contact => contact.email === me.email) === -1) {
		filteredContacts.push(me)	
	}

	// Remove any contacts that have already been assigned
	if (task.assignees && task.assignees.type === 'direct') {
		task.assignees.assignees && task.assignees.assignees.forEach(assignee => {
			let index = filteredContacts ? filteredContacts.findIndex(contact => contact.email === assignee.email) : -1
			if (index !== -1) filteredContacts.splice(index, 1)
		})
	}

	const selectionUpdate = (member) => {
		if (!task.assignees.assignees) {task.assignees.assignees=[]}
		task.assignees.assignees.push({email: member})
		setAssignees(task.assignees)
	}
		
	return (
		
		<Grid item xs={12}>
  
			<Typography variant="subtitle1" style={{marginTop: 10}} >{i18.t('task_who_label')}</Typography>

			<RadioGroup value={task.assignees.type} onChange={(event)=>setAssigneesType(event.target.value)}>
				<Grid container direction="row" alignItems='center' >
					<Grid item xs={12} md={6} lg={4}>
						<FormControlLabel value="direct" control={<Radio disabled={!designTime}  />} label={i18.t('task_assign_members_directly')} />
					</Grid>
					<Grid item xs={12} md={6} lg={8}>
						<FormControlLabel value="property" control={<Radio disabled={!designTime}  />} label={i18.t('task_assign_members_from_property')} />
					</Grid>
				</Grid>
			</RadioGroup>			

			{ isGroupSelection ?
				memberGroupProps && memberGroupProps.length > 0 ?
					<List style={{maxHeight: 300}} >
						{ memberGroupProps.map((property, index) => 
							<ListItem 
								key={index}
								style={{background: property.propertyId === task.assignees.propertyId ? 'lightgrey' : 'transparent'}}
								onClick={() => setAssigneesGroupProperty(property.propertyId)}
							>
								{property.name}
								<Divider/>
							</ListItem>
						)}
					</List>
				:
					<Typography>{i18.t('task_who_no_groups_avail')}</Typography>
			:
				<Grid item>

					<Grid item xs={8}>
						<MemberSelect targetList={task.assignees.assignees} contacts={filteredContacts} designTime={designTime} disabled={disabled} update={selectionUpdate} />
					</Grid>
					
					<MemberList me={me} members={task.assignees ? task.assignees.assignees : []} designTime={designTime} onDelete={onAssigneeDelete} />
					
				</Grid>
			}
			
		</Grid>	
				
	)
}

export default TaskWho
