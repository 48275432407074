import workflow_simple from './workflow_simple.json'
import workflow_subtasks_maxdepth from './workflow_subtasks_maxdepth.json'
import workflow_subtasks_exceedsmaxdepth from './workflow_subtasks_exceedsmaxdepth.json'
import workflow_no_user_tasks from './workflow_no_user_tasks.json'
import testfragments from './testfragments'
import cloneDeep from 'lodash.clonedeep'


const stringify = (str) => {
	return JSON.stringify(str).replace(/"(\w+)"\s*:/g, '$1:')
}

let workflow = {...workflow_simple, workflowId: "<<workflowId>>"}

// TODO
// > Add test for case when parent task has no children
// > Add test for case when parent task has too many children


// Task name

// WFT-0001
var nulltasknamewf = cloneDeep(workflow)
nulltasknamewf.tasks[0].tasks[0].name = null

// WFT-0002
var shorttasknamewf = cloneDeep(workflow)
shorttasknamewf.tasks[0].tasks[0].name = testfragments.text1

// WFT-0003
var longtasknamewf = cloneDeep(workflow)
longtasknamewf.tasks[0].tasks[0].name = testfragments.text100

// WFT-0004
var toolongtasknamewf = cloneDeep(workflow)
toolongtasknamewf.tasks[0].tasks[0].name = testfragments.text101

// WFT-0005
var specialchartasknamewf = cloneDeep(workflow)
specialchartasknamewf.tasks[0].tasks[0].name = testfragments.textspecial


// Task Who

// 		Task assignee(s) type

// WFT-0006
var nullassigneetypewf = cloneDeep(workflow)
nullassigneetypewf.tasks[0].tasks[0].assignees.type=null

// WFT-0007
var directassigneetypewf = cloneDeep(workflow)
directassigneetypewf.tasks[0].tasks[0].assignees.type='direct'

// WFT-0008
var invalidassigneetypewf = cloneDeep(workflow)
invalidassigneetypewf.tasks[0].tasks[0].assignees.type='xxx'


// WFT-0009 - author task with 1, direct assignee and "any" completion criteria
var taskdirect1assigneeany = cloneDeep(workflow)
taskdirect1assigneeany.tasks[0].tasks[0].assignees = {"type": "direct", "assignees":[{"email":"admin@tournamentbuilders.com"}]}
taskdirect1assigneeany.tasks[0].tasks[0].routeCondition.quorum = 'any'

// WFT-0010 - author task with 3, direct assignees and "any" completion criteria
var taskdirect3assigneeany = cloneDeep(workflow)
taskdirect3assigneeany.tasks[0].tasks[0].assignees = {"type": "direct", "assignees":[{"email":"admin@tournamentbuilders.com"},{"email":"development93012@gmail.com"},{"email":"development805@gmail.com"}]}
taskdirect3assigneeany.tasks[0].tasks[0].routeCondition.quorum = 'any'

// WFT-0011 - author task with 100, direct assignees and "any" completion criteria

// WFT-0012 - author task with 101, direct assignees and "any" completion criteria

// WFT-0013 - author task with group member assignment that has 1 member and "any" completion criteria

// WFT-0014 - author task with group member assignment that has 3 members and "any" completion criteria

// WFT-0015 - author task with group member assignment that has 100 members and "any" completion criteria

// WFT-0016 - author task with group member assignment that has 101 members and "any" completion criteria

// WFT-0017 - author task with 1, direct assignee and "all" completion criteria
var taskdirect1assigneeall = cloneDeep(workflow)
taskdirect1assigneeall.tasks[0].tasks[0].assignees = {"type": "direct", "assignees":[{"email":"admin@tournamentbuilders.com"}]}
taskdirect1assigneeall.tasks[0].tasks[0].routeCondition.quorum = 'all'

// WFT-0018 - author task with 3, direct assignees and "all" completion criteria
var taskdirect3assigneeall = cloneDeep(workflow)
taskdirect3assigneeall.tasks[0].tasks[0].assignees = {"type": "direct", "assignees":[{"email":"admin@tournamentbuilders.com"},{"email":"development93012@gmail.com"},{"email":"development805@gmail.com"}]}
taskdirect3assigneeall.tasks[0].tasks[0].routeCondition.quorum = 'all'

// WFT-0019 - author task with group member assignment that has 1 member and "all" completion criteria

// WFT-0020 - author task with group member assignment that has 3 members and "all" completion criteria

// WFT-0021 - author task with 1, direct assignee and "majority" completion criteria
var taskdirect1assigneemajority = cloneDeep(workflow)
taskdirect1assigneemajority.tasks[0].tasks[0].assignees = {"type": "direct", "assignees":[{"email":"admin@tournamentbuilders.com"}]}
taskdirect1assigneemajority.tasks[0].tasks[0].routeCondition.quorum = 'majority'

// WFT-0022 - author task with 3, direct assignees and "majority" completion criteria
var taskdirect3assigneemajority = cloneDeep(workflow)
taskdirect3assigneemajority.tasks[0].tasks[0].assignees = {"type": "direct", "assignees":[{"email":"admin@tournamentbuilders.com"},{"email":"development93012@gmail.com"},{"email":"development805@gmail.com"}]}
taskdirect3assigneemajority.tasks[0].tasks[0].routeCondition.quorum = 'majority'

// WFT-0023 - author task with group member assignment that has 1 member and "majority" completion criteria

// WFT-0024 - author task with group member assignment that has 3 members and "majority" completion criteria



// Task What - forms, parallel exec, etc.


// WFT-0050
var subtaskmaxdepthwf = cloneDeep(workflow_subtasks_maxdepth)
subtaskmaxdepthwf.tasks[0].tasks[0].tasks[0].tasks[0].parallelExecution = true

// WFT-0051
var subtaskexceedsmaxdepthwf = cloneDeep(workflow_subtasks_exceedsmaxdepth)
subtaskexceedsmaxdepthwf.tasks[0].tasks[0].tasks[0].tasks[0].parallelExecution = true

// WFT-0052
var nousertaskswf = cloneDeep(workflow_no_user_tasks)




// Task Where - route condition attributes (name, type, etc)

// 		Task route condition(s) name

// WFT-0100
var nullrcnamewf = cloneDeep(workflow)
nullrcnamewf.tasks[0].tasks[0].routeCondition.options[0].name = null

// WFT-0101
var shortrcnamewf = cloneDeep(workflow)
shortrcnamewf.tasks[0].tasks[0].routeCondition.options[0].name = testfragments.text1

// WFT-0102
var longrcnamewf = cloneDeep(workflow)
longrcnamewf.tasks[0].tasks[0].routeCondition.options[0].name = testfragments.text100

// WFT-0103
var toolongrcnamewf = cloneDeep(workflow)
toolongrcnamewf.tasks[0].tasks[0].routeCondition.options[0].name = testfragments.text101

// WFT-0104
var specialcharrcnamewf = cloneDeep(workflow)
specialcharrcnamewf.tasks[0].tasks[0].routeCondition.options[0].name = testfragments.textspecial


// 		Task route condition(s) type

// WFT-0105
var nullrctypewf = cloneDeep(workflow)
nullrctypewf.tasks[0].tasks[0].routeCondition.options[0].type = null

// WFT-0106
var nextrctypewf = cloneDeep(workflow)
nextrctypewf.tasks[0].tasks[0].routeCondition.options[0].type = 'nexttask'

// WFT-0107
var finishrctypewf = cloneDeep(workflow)
finishrctypewf.tasks[0].tasks[0].routeCondition.options[0].type = 'finish'

// WFT-0108
var singlerctypewf = cloneDeep(workflow)
singlerctypewf.tasks[0].tasks[0].routeCondition.options[0].type = 'task'

// WFT-0109
var invalidrctypewf = cloneDeep(workflow)
invalidrctypewf.tasks[0].tasks[0].routeCondition.options[0].type = 'tas'


// 		Task route condition(s) quorum

// WFT-0110
var nullrcquorumwf = cloneDeep(workflow)
nullrcquorumwf.tasks[0].tasks[0].routeCondition.quorum = null

// WFT-0111
var anyrcquorumwf = cloneDeep(workflow)
anyrcquorumwf.tasks[0].tasks[0].routeCondition.quorum = 'any'

// WFT-0112
var allrcquorumwf = cloneDeep(workflow)
allrcquorumwf.tasks[0].tasks[0].routeCondition.quorum = 'all'

// WFT-0113
var majorityrcquorumwf = cloneDeep(workflow)
majorityrcquorumwf.tasks[0].tasks[0].routeCondition.quorum = 'majority'

// WFT-0114
var invalidrcquorumwf = cloneDeep(workflow)
invalidrcquorumwf.tasks[0].tasks[0].routeCondition.quorum = 'xxx'





const workflowtasks = [{
	name: "Workflow tasks test collection",
	tests: [

// Task Who

		{
			// create draft workflow from base workflow template and test various updates
			id: 'WFT-SETUP',
			name: 'create base workflow.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(workflow_simple)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [

				// null task name
				{
					id: 'WFT-0001',
					name: 'null task name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(nulltasknamewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResult: `{"validationErrors":[],"tasks":[{"assignees":null,"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":[{"assignees":{"validationErrors":null},"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":null}]},{"assignees":null,"routeCondition":null,"validationErrors":[],"tasks":null}]}`
					
				},

				// short task name
				{
					id: 'WFT-0002',
					name: 'short task name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(shorttasknamewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResult: `{"validationErrors":[],"tasks":[{"assignees":null,"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":[{"assignees":{"validationErrors":null},"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":null}]},{"assignees":null,"routeCondition":null,"validationErrors":[],"tasks":null}]}`
				},
			
				// long task name
				{
					id: 'WFT-0003',
					name: 'long task name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(longtasknamewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResult: `{"validationErrors":[],"tasks":[{"assignees":null,"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":[{"assignees":{"validationErrors":null},"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":null}]},{"assignees":null,"routeCondition":null,"validationErrors":[],"tasks":null}]}`
				},
		
				// too long task name
				{
					id: 'WFT-0004',
					name: 'too long task name', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(toolongtasknamewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`validation_error_maxlength`],
				},	

				// special char task name
				{
					id: 'WFT-0005',
					name: 'special char task name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(specialchartasknamewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResult: `{"validationErrors":[],"tasks":[{"assignees":null,"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":[{"assignees":{"validationErrors":null},"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":null}]},{"assignees":null,"routeCondition":null,"validationErrors":[],"tasks":null}]}`
				},


				// null assignee type
				{
					id: 'WFT-0006',
					name: 'null assignee type', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(nullassigneetypewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`has invalid value`]
				},
				
				// direct assignee type
				{
					id: 'WFT-0007',
					name: 'direct assignee type', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(directassigneetypewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResult: `{"validationErrors":[],"tasks":[{"assignees":null,"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":[{"assignees":{"validationErrors":null},"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":null}]},{"assignees":null,"routeCondition":null,"validationErrors":[],"tasks":null}]}`
				},

				// invalid assignee type
				{
					id: 'WFT-0008',
					name: 'invalid assignee type', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(invalidassigneetypewf)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				{
					id: 'WFT-0009',
					name: 'author task with 1, direct assignee and "any" completion criteria.', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(taskdirect1assigneeany)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResult: `{"validationErrors":[],"tasks":[{"assignees":null,"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":[{"assignees":{"validationErrors":null},"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":null}]},{"assignees":null,"routeCondition":null,"validationErrors":[],"tasks":null}]}`
				},

				{
					id: 'WFT-0010',
					name: 'author task with 3, direct assignees and "any" completion criteria.',
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(taskdirect3assigneeany)}) {workflowId state validationErrors {error warning data}}}`,
					assertResultIncludes: [`"state":"draft","validationErrors":[]}`],
				},

				{
					id: 'WFT-0017',
					name: 'author task with 1, direct assignee and "all" completion criteria.',
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(taskdirect1assigneeall)}) {workflowId state validationErrors {error warning data}}}`,
					assertResultIncludes: [`"state":"draft","validationErrors":[]}`],
				},

				{
					id: 'WFT-0018',
					name: 'author task with 3, direct assignees and "all" completion criteria.',
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(taskdirect3assigneeall)}) {workflowId state validationErrors {error warning data}}}`,
					assertResultIncludes: [`"state":"draft","validationErrors":[]}`],
				},

				{
					id: 'WFT-0021',
					name: 'author task with 1, direct assignee and "majority" completion criteria.',
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(taskdirect1assigneemajority)}) {workflowId state validationErrors {error warning data}}}`,
					assertResultIncludes: [`"state":"draft","validationErrors":[]}`],
				},

				{
					id: 'WFT-0022',
					name: 'author task with 3, direct assignees and "majority" completion criteria.',
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(taskdirect3assigneemajority)}) {workflowId state validationErrors {error warning data}}}`,
					assertResultIncludes: [`"state":"draft","validationErrors":[]}`],
				},

				// now delete the draft workflow
				{
					id: 'WFT-CLEANUP',
					name: 'delete base workflow', 
					type: 'cleanup',
					operation: `mutation {deleteWorkflow (workflowId: "<<workflowId>>") {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":null}`
				},
			]
		},


// Task What

		{
			id: 'WFT-0050',
			name: 'create workflow w/ maxdepth subtasks and complete assignments.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(subtaskmaxdepthwf)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'WFT-0050a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {state, taskinstances {taskinstances {taskinstances {taskinstances {taskinstances {assignments {assignmentId name}}}}}}}}`, 
				assertResultIncludes: [`"state":"working"`,`subtask1`],
				onEachItem: [
					{
						id: 'WFT-0050a1',
						name: 'complete first assignment.',
						type: 'positive',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].taskinstances[0].taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
						assertResultIncludes: [`"state":"completed"`],
					},
					{
						id: 'WFT-0050a2',
						name: 'complete second assignment.',
						type: 'positive',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].taskinstances[0].taskinstances[0].taskinstances[1].assignments[0].assignmentId>>" }) {state}}`,
						assertResultIncludes: [`"state":"completed"`],
					}
				]
			}]
		},

		{
			id: 'WFT-0051',
			name: 'create a workflow that exceeds maxdepth subtasks.',
			type: 'negative',
			operation: `mutation {createWorkflow (input: ${stringify(subtaskexceedsmaxdepthwf)}) {workflowId state validationErrors {error warning data}}}`,
			assertResultIncludes: [`"state":"draft"`,`"error":"validation_error_tasks_nested_too_deeply"`],
			onEachItem: [{
				id: 'WFT-0051a',
				name: 'discard workflow', 				
				type: 'cleanup',
				operation: `mutation {deleteWorkflow (workflowId: "<<workflowId>>") {workflowId}}`, 
			}]
		},

		{
			id: 'WFT-0052',
			name: 'create a workflow with no user tasks and verify validation error.',
			type: 'negative',
			operation: `mutation {createWorkflow (input: ${stringify(nousertaskswf)}) {workflowId state validationErrors {error warning data}}}`,
			assertResultIncludes: [`"state":"draft"`,`"validation_warning_no_user_tasks"`],
			onEachItem: [{
				id: 'WFT-0052a',
				name: 'discard workflow', 				
				type: 'cleanup',
				operation: `mutation {deleteWorkflow (workflowId: "<<workflowId>>") {workflowId}}`, 
			}]
		},


// Task Where

		{
			// create draft workflow from base workflow template and test various updates
			id: 'WFT-SETUP',
			name: 'create base workflow.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(workflow_simple)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [

				// null rc name
				{
					id: 'WFT-0100',
					name: 'null rc name', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(nullrcnamewf)}) {instanceData ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`In field \\"name\\": Expected \\"String!\\", found null.`]
				},

				// short rc name
				{
					id: 'WFT-0101',
					name: 'short rc name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(shortrcnamewf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},
				
				// long rc name
				{
					id: 'WFT-0102',
					name: 'long rc name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(longrcnamewf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},
			
				// too long rc options name
				{
					id: 'WFT-0103',
					name: 'too long rc options name', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(toolongrcnamewf)}) {instanceData ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},

				// special char rc name
				{
					id: 'WFT-0104',
					name: 'special char rc name', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(specialcharrcnamewf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},

				// null rc type
				{
					id: 'WFT-0105',
					name: 'null rc type', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(nullrctypewf)}) {instanceData ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`In field \\"type\\": Expected \\"String!\\", found null.`]
				},
				
				// next rc type
				{
					id: 'WFT-0106',
					name: 'next rc type', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(nextrctypewf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},
				
				// finish rc type
				{
					id: 'WFT-0107',
					name: 'finish rc type', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(finishrctypewf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},
				
				// single route rc type
				{
					id: 'WFT-0108',
					name: 'single route rc type', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(singlerctypewf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},

				// invalid rc type
				{
					id: 'WFT-0109',
					name: 'invalid rc type', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(invalidrctypewf)}) {instanceData ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// null rc quorum
				{
					id: 'WFT-0110',
					name: 'null rc quorum', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(nullrcquorumwf)}) {instanceData ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`In field \\"quorum\\": Expected \\"String!\\", found null.`]
				},
				

				// any rc quorum
				{
					id: 'WFT-0111',
					name: 'any rc quorum', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(anyrcquorumwf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},
				
				// all rc quorum
				{
					id: 'WFT-0112',
					name: 'all rc quorum', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(allrcquorumwf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},
				
				// majority route rc quorum
				{
					id: 'WFT-0113',
					name: 'majority route rc quorum', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(majorityrcquorumwf)}) {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":[]}`
				},

				// invalid rc quorum
				{
					id: 'WFT-0114',
					name: 'invalid rc quorum', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(invalidrcquorumwf)})  {instanceData ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`validation_error_invalid_rc_quorum`]
				},

				// now delete the draft workflow
				{
					id: 'WFT-CLEANUP',
					name: 'delete base workflow', 
					type: 'cleanup',
					operation: `mutation {deleteWorkflow (workflowId: "<<workflowId>>") {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":null}`
				},
			]
		},

	],
}]

export default workflowtasks

