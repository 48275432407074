import React, { useState } from 'react'
import { Grid, Select, MenuItem, TextField, FormControl, Typography, Tooltip } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import * as i18 from 'i18next'

import TagBar from '../components/TagBar'


const PropertyNameType = ({ org, orgProperties, rootparentId, property, properties, designTime, updateProperty, changePropertyType, showDisabledProps }) => {

//	console.log('PropertyNameType')

	if (!property) return null

	const [selectedTags, setSelectedTags] = useState()
	const [refCopied, setRefCopied] = useState()

	let properties2 = org?.properties ? [...org?.properties] : []
	if (selectedTags?.length > 0) {
		let filteredPropertyList = []
		for (const propertyId of org.properties) {
			let property = orgProperties[propertyId]
			for (const tag of selectedTags) {
				if (property.tags?.includes(tag)) {
					filteredPropertyList.push(propertyId)
				}
			}
		}
		properties2 = [...new Set(filteredPropertyList)]
	}

	let props = properties2.map(propertyId => orgProperties[propertyId])

	if (!showDisabledProps) {
		props = props.filter(prop => prop.enabled === true)
	}

	let tagList = []
	for (const prop of props) {
		tagList = [...tagList, ...prop.tags]
		tagList = [...new Set(tagList)]
	}

	if (selectedTags?.length > 0) {
		props = props.filter(prop => prop?.tags?.some(tag => selectedTags.includes(tag)))
	}

	let propertyRefName = property.pname
	let parentpropertyId = property.parentpropertyId
	while (parentpropertyId) {
		propertyRefName = properties[parentpropertyId].pname + (propertyRefName ? '.' + propertyRefName : '')
		parentpropertyId = properties[parentpropertyId].parentpropertyId
	}
	
	return (
		<Grid item style={{paddingLeft: 5, paddingRight: 5}}>

			{designTime ?
				<Grid container direction='column'>

					{/* Property name */}
					<TextField
						variant='standard'
						id={property.propertyId}
						placeholder={i18.t('property_name_placeholder')}
						value={property.name ? property.name : ''}
						onChange={(event)=>{
							property.name = event.target.value
							if (property.name) {
								property.pname = property.name.toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
							}
							updateProperty(property)
							event.stopPropagation()
						}}
						onClick={(event)=>{event.stopPropagation()}}
						required={true}
					/>

					{/* Property resource name (prn) */}
					<Grid container direction='row'>
						<Typography style={{overflow: 'hidden', textOverflow: 'ellipsis'}} onClick={(e)=>e.stopPropagation()} >{propertyRefName ? propertyRefName : ''}</Typography>
						&nbsp;&nbsp;
						<Tooltip title={refCopied ? i18.t('tooltip_prn_copied') : i18.t('tooltip_prn_copy')} >
							<ContentCopy fontSize="small"  onClick={(e)=>{e.stopPropagation();navigator.clipboard.writeText(propertyRefName);setRefCopied(true);setTimeout(()=>setRefCopied(false), 2000)}}/>
						</Tooltip>
					</Grid>
					
					{/* Property type */}
					<FormControl required={true} >
						<Select
							variant='standard'
							value={property.subType}
							onChange={(e)=>{if (e.target.value) {changePropertyType(rootparentId, orgProperties, property, e.target.value)}}}
						>
							<TagBar 
								tagList={tagList} 
								selectedTags={selectedTags} 
								editMode={false} 
								onSelectionUpdate={(selectedTags)=>setSelectedTags(selectedTags)}
							/>
					
							{props.map( (prop, propIndex) => {
								let menuLabel = i18.t(`property_${prop.subType}`)
								return <MenuItem key={propIndex} value={prop.subType} >{menuLabel}</MenuItem>
							})}
						</Select>
					</FormControl>
			
				</Grid>
			:
				<Typography style={{paddingRight: 10, color: property.name ? 'default' : 'lightgrey' }} >{property.name ? property.name : i18.t('property_name_placeholder')}</Typography>
			}
		</Grid>
	)
}

export default PropertyNameType