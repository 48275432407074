export const RECEIVE_ME = 'RECEIVE_ME'
export const USER_TOKEN = 'USER_TOKEN'
export const UPDATE_ME = 'UPDATE_ME'
export const UPDATE_SCOPE = 'UPDATE_SCOPE'
export const SET_SCOPE = 'SET_SCOPE'
export const SET_USER = 'SET_USER'
export const RECEIVE_ROLES = 'RECEIVE_ROLES'
export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS'

export const EXPAND_OBJECT_EVENT = 'EXPAND_OBJECT_EVENT'

export const CLEAR_ALL_SUPPORTCASES = 'CLEAR_ALL_SUPPORTCASES'
export const RECEIVE_SUPPORTCASES = 'RECEIVE_SUPPORTCASES'
export const RECEIVE_SUPPORTCASE = 'RECEIVE_SUPPORTCASE'
export const UPDATE_SUPPORTCASE = 'UPDATE_SUPPORTCASE'

export const CLEAR_ALL_ORGS = 'CLEAR_ALL_ORGS'
export const RECEIVE_ORGS = 'RECEIVE_ORGS'
export const RECEIVE_ORG = 'RECEIVE_ORG'
export const UPDATE_ORG = 'UPDATE_ORG'

export const CLEAR_ALL_WORKFLOWS = 'CLEAR_ALL_WORKFLOWS'
export const RECEIVE_WORKFLOWS = 'RECEIVE_WORKFLOWS'
export const RECEIVE_WORKFLOW = 'RECEIVE_WORKFLOW'
export const CREATE_LAUNCH_ASSIGNMENT = 'CREATE_LAUNCH_ASSIGNMENT'
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW'
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW'
//export const WORKFLOW_MOVE_OTHER_ATTACHMENT = 'WORKFLOW_MOVE_OTHER_ATTACHMENT'

export const CLEAR_ALL_ASSIGNMENTS = 'CLEAR_ALL_ASSIGNMENTS'
export const RECEIVE_ASSIGNMENTS = 'RECEIVE_ASSIGNMENTS'
export const RECEIVE_ASSIGNMENT = 'RECEIVE_ASSIGNMENT'
export const COMPLETE_ASSIGNMENT_EVENT = 'COMPLETE_ASSIGNMENT_EVENT'
export const UPDATE_ASSIGNMENT_EVENT = 'UPDATE_ASSIGNMENT_EVENT'

export const ADD_TASK_EVENT = 'ADD_TASK_EVENT'
export const UPDATE_TASK_EVENT = 'UPDATE_TASK_EVENT'
export const DELETE_TASK_EVENT = 'DELETE_TASK_EVENT'
export const MOVE_TASK_EVENT = 'MOVE_TASK_EVENT'
export const MAKE_CHILD_TASK_EVENT = 'MAKE_CHILD_TASK_EVENT'
export const MAKE_PARENT_TASK_EVENT = 'MAKE_PARENT_TASK_EVENT'
export const SET_PARALLEL_EXECUTION_EVENT = 'SET_PARALLEL_EXECUTION_EVENT'
export const SET_TASK_EXPANDED_EVENT = 'SET_TASK_EXPANDED_EVENT'
export const SET_ASSIGNEES_TYPE_EVENT = 'SET_ASSIGNEES_TYPE_EVENT'
export const SET_ASSIGNEES_GROUPFIELD_EVENT = 'SET_ASSIGNEES_GROUPFIELD_EVENT'

export const SET_ASSIGNEES_EVENT = 'SET_ASSIGNEES_EVENT'
export const ADD_ASSIGNEE_EVENT = 'ADD_ASSIGNEE_EVENT'
export const DELETE_ASSIGNEE_EVENT = 'DELETE_ASSIGNEE_EVENT'

export const ADD_MILESTONE_ACTION_EVENT = 'ADD_MILESTONE_ACTION_EVENT'
export const DELETE_MILESTONE_ACTION_EVENT = 'DELETE_MILESTONE_ACTION_EVENT'
export const MOVE_MILESTONE_ACTION_EVENT = 'MOVE_MILESTONE_ACTION_EVENT'

export const NAVIGATE_PUSH_EVENT = 'NAVIGATE_PUSH_EVENT'
export const NAVIGATE_POP_EVENT = 'NAVIGATE_POP_EVENT'
export const NAVIGATE_RESET_EVENT = 'NAVIGATE_RESET_EVENT'

export const ADD_PROPERTY_EVENT = 'ADD_PROPERTY_EVENT'
export const UPDATE_PROPERTY_EVENT = 'UPDATE_PROPERTY_EVENT'
export const MOVE_PROPERTY_EVENT = 'MOVE_PROPERTY_EVENT'
export const DELETE_PROPERTY_EVENT = 'DELETE_PROPERTY_EVENT'
export const CHANGE_PROPERTY_TYPE_EVENT = 'CHANGE_PROPERTY_TYPE_EVENT'
export const ADD_COMPOSITE_PROPERTY_EVENT = 'ADD_COMPOSITE_PROPERTY_EVENT'
export const DELETE_COMPOSITE_PROPERTY_EVENT = 'DELETE_COMPOSITE_PROPERTY_EVENT'
export const MOVE_COMPOSITE_PROPERTY_EVENT = 'MOVE_COMPOSITE_PROPERTY_EVENT'
export const MOVE_TO_COMPOSITE_PROPERTY_EVENT = 'MOVE_TO_COMPOSITE_PROPERTY_EVENT'
export const RESET_COMPOSITE_PROPERTY_EVENT = 'RESET_COMPOSITE_PROPERTY_EVENT'
export const RECEIVE_PROPERTIES = 'RECEIVE_PROPERTIES'

export const WORKFLOW_ADD_PROPERTY = 'WORKFLOW_ADD_PROPERTY'
export const MOVE_WORKFLOW_PROPERTY_EVENT = 'MOVE_WORKFLOW_PROPERTY_EVENT'
export const ADD_EXTRACT_PROPERTY='ADD_EXTRACT_PROPERTY'
export const MOVE_EXTRACT_PROPERTY = 'MOVE_EXTRACT_PROPERTY'

export const QUERY_RESULT = 'QUERY_RESULT'
export const UPDATE_QUERY = 'UPDATE_QUERY'

export const RECEIVE_DOCUMENTS = 'RECEIVE_DOCUMENTS'
export const RECEIVE_DOCUMENT = 'RECEIVE_DOCUMENT'
export const RECEIVE_DOCUMENT_CONTENT = 'RECEIVE_DOCUMENT_CONTENT'
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT'

export const RECEIVE_EXTRACT = 'RECEIVE_EXTRACT'
export const RECEIVE_EXTRACTS = 'RECEIVE_EXTRACTS'
export const UPDATE_EXTRACT = 'UPDATE_EXTRACT'
export const DELETE_EXTRACT = 'DELETE_EXTRACT'


export const RECEIVE_AUTOMATIONS = "RECEIVE_AUTOMATIONS"
export const RECEIVE_AUTOMATION = "RECEIVE_AUTOMATION"
export const DELETE_AUTOMATION = "DELETE_AUTOMATION"
export const UPDATE_AUTOMATION = "UPDATE_AUTOMATION"

export const CREATE_ATTACHMENT = "CREATE_ATTACHMENT"
export const UPDATE_ATTACHMENT = "UPDATE_ATTACHMENT"
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT"
export const MOVE_ATTACHMENT = "MOVE_ATTACHMENT"

export const RECEIVE_EVENTS = "RECEIVE_EVENTS"

export const RECEIVE_ACTIONS = "RECEIVE_ACTIONS"

export const RECEIVE_VERSIONS = "RECEIVE_VERSIONS"

export const RECEIVE_ERRORS = 'RECEIVE_ERRORS'
export const CLOSE_ERROR = 'CLOSE_ERROR'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

