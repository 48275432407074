import React, { useState } from 'react'
import { Grid, ListItem, Typography, TextField, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Tooltip } from '@mui/material'
import { Reply, ExpandMore } from '@mui/icons-material'
import * as i18 from 'i18next'

import CommentList from '../components/CommentList'

//						<Box fontWeight="fontWeightBold">{comment.createdBy.email}</Box>

const CommentListItem = ({ comment, listSelection, setListSelection, disabled, level=0, postComment }) => {

//	console.log('CommentListItem')
		
	if (!comment) return null

	const [comment2, setComment2] = useState()
	const [commenting, setCommenting] = useState(false)
	const [expanded, setExpanded] = useState(false)

	return (
		<ListItem 
			onClick={(e) => {
				if (listSelection !== comment.commentId) {
					setComment2(null)
					setListSelection(comment.commentId)
					e.stopPropagation()
				}
			}}
		>
			<Accordion 
				style={{width: '100%'}} 
				expanded={expanded} 
				onChange={(event, expanded)=>{
					if (listSelection === comment.commentId) {
						setExpanded(expanded)
					} else {
						setExpanded(true)
					}
				}}
			>
				<AccordionSummary expandIcon={<Tooltip title={i18.t('general_expand_item')}><ExpandMore/></Tooltip>}>
					<Grid item style={{flex: 1}}>
						<Grid container direction='row' alignItems='center' >
							<Typography style={{color: 'grey'}}>{comment.createdBy.email}</Typography>
							&nbsp;&nbsp;
							<Typography style={{color: 'grey'}}>{new Intl.DateTimeFormat("en-US", {timeStyle: 'long'}).format(Date.parse(comment.created))}</Typography>
							<div style={{flex: 1}} />					
							{comment.comments?.length > 0 ?
								<Typography style={{color: 'grey'}} >{i18.t('comment_num_replies', {count: comment.comments?.length})}</Typography>
							: null}
						</Grid>
						
						<Typography style={{marginLeft: 10, marginTop: 5}} >{comment.comment}</Typography>

					</Grid>
				</AccordionSummary>
				<AccordionDetails>	
					<Grid item>
						{comment.comments ?
							<CommentList 
								comments={comment.comments} 
								listSelection={listSelection}
								setListSelection={setListSelection}
								disabled={disabled}
								level={level+1}
								postComment={postComment}
							/>
						: null }
						
							<Grid container direction='row'>
								<Grid item style={{marginLeft: 55, flex: 1}} >
									<TextField
										style={{backgroundColor: 'ghostwhite'}}
										value={comment2 ? comment2 : ''}
										fullWidth
										multiline
										rows={2}
										placeholder={i18.t('comment_enter_reply')}
										variant="outlined"
										disabled={disabled}
										onChange={(e)=>{setComment2(e.target.value)}}
										onKeyPress={async (e) => {
											if (e.key === 'Enter' && e.shiftKey && comment2) {
												await postComment(comment.commentId, comment2)
												setComment2(null)
												e.stopPropagation()
											}
										}}																		
										onClick={(e)=>{e.stopPropagation()}}
									/>
								</Grid>
								<Button disabled={disabled || !comment2 || comment2.length === 0} style={{marginLeft: 10}} onClick={()=>{postComment(comment.commentId, comment2, setCommenting);setComment2(null);}} >
									<Typography>Reply</Typography>
									{commenting ? 
										<CircularProgress size={25} /> 
									: 
										<Reply/>
									}
								</Button>
							</Grid>
						
					</Grid>
				</AccordionDetails>
			</Accordion>		
		</ListItem>
	)
}

export default CommentListItem
