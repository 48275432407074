import React, { useState } from 'react'
import { Grid, Typography, Button } from '@mui/material'
import { Accordion, AccordionSummary, AccordionDetails, Tooltip, Icon } from '@mui/material'
import { RemoveCircle, ExpandMore, InsertDriveFile } from '@mui/icons-material'
import * as i18 from 'i18next'

import PropertyList from '../PropertyList'
import UppyButton from '../UppyButton'


// Design view of component
const DocumentAttachmentEdit = ({ navigation, workflowId, rootparentType, rootparentId, rootparentState, attachment, doc, extractsTagList, hover, designTime, disabled,
								updateAttachment, retrieveDocument, createDocument, createDocumentInstance, updateDocument, saveDocument, retrieveContent, putContent, analyzeDocument,
								deleteProperty }) => {

	if (!attachment) return null

	const [expanded, setExpanded] = useState(false)
	const [retrieveDocDetailStatus, setRetrieveDocDetailStatus] = useState()
	const [tagFilter, setTagFilter] = useState()
	const [showTagFilterDialog, setShowTagFilterDialog] = useState()
	const [selectedProperty, setSelectedProperty] = useState()
	const [defaultDocRetrieveAttempted, setDefaultDocRetrieveAttempted] = useState(false)

	const onFileComplete = async (file) => {
		if (file) {
			const permissionDelegate = {delegateType: 'workflow', delegateId: workflowId, grant: 'documenteditor'}
			if (!doc) {
				const doc2 = await createDocument({name: file.name, delegatedPermissions: [permissionDelegate]})
				if (doc2) {
					attachment.defaultDocumentId = doc2.documentId
					await updateAttachment(attachment)			
				}
			} else if (doc?.state === 'template') {
				const doc2 = await createDocumentInstance(doc.documentId, permissionDelegate, rootparentType, rootparentId)
				if (doc2) {
					await putContent(doc2.documentId, file, 'workflow', workflowId)
					attachment.documentId = doc2.documentId
					await updateAttachment(attachment)			
				}
			} else if (doc?.state === 'working') {
				await putContent(attachment.defaultDocumentId, file, 'workflow', workflowId)
			}
		}
	}

	if (expanded && !doc?.permissions && !defaultDocRetrieveAttempted) {
		setDefaultDocRetrieveAttempted(true)
		retrieveDocument(attachment.defaultDocumentId, 'workflow', workflowId)
	}

	return (
		<Accordion 
			sx={{'&:before': {display: 'none'}}}
			style={{cursor: 'default', boxShadow: 'none', backgroundColor: 'transparent', flex: 1}} 
			expanded={expanded}
			onChange={(event, expanded)=>{setExpanded(expanded)}}
		>

			<AccordionSummary id='accordionSummary' style={{cursor: 'default', padding: 0, backgroundColor: 'transparent'}} expandIcon={<Tooltip title={i18.t('property_expand_tooltip')}>{<ExpandMore/>}</Tooltip>}>

				<Grid container direction={'row'} alignItems='center' >

					<Grid item>
						<Grid container direction={'column'} alignItems='flex-start' >

							{ attachment.defaultDocumentId ?
								<Grid container direction={'row'} alignItems="center" >									

									&nbsp;&nbsp;
									
									{ doc?.state === 'template' ?
										<Typography>{i18.t('attachment_template_label')}</Typography>
									: null }

									{/* document name / navigate to document button */}
									<Tooltip title={i18.t('attachment_view_tooltip')} >
										<Button onClick={(e) => {
											e.stopPropagation()
											if (!doc?.permissions && !defaultDocRetrieveAttempted) {
												setDefaultDocRetrieveAttempted(true)
												retrieveDocument(attachment.defaultDocumentId, 'workflow', workflowId)
											}
											navigation.navigate('document', {documentId: attachment?.defaultDocumentId, workflowId: workflowId})
										}}>
											<InsertDriveFile style={{height: 20, width: 20}} />
											{doc?.name ? doc.name : '<document>'}
										</Button>
									</Tooltip>

									{/* delete attachment icon */}
									{ hover && !disabled ?
										<Tooltip title={i18.t('attachment_delete_tooltip')} >
											<RemoveCircle 
												style={{cursor: 'default', height: 20, width: 20}}
												onClick={(e)=>{
													delete attachment.defaultDocumentId
													updateAttachment(attachment)
													e.stopPropagation()
												}}
											/>
										</Tooltip>
									: <Icon/> }
									
								</Grid>
							:
								<Grid item>
									<Grid container direction={'column'} alignItems='flex-start' >
									
										<Grid container direction={'row'} alignItems='center' >

											{/* select document button */}
											<UppyButton disabled={disabled} inMemoryFileUpload={true} onFileComplete={onFileComplete}>{i18.t('attachment_create_document_from_upload')}</UppyButton>

											<Typography>/</Typography>
										
											{/* create working document placeholder */}
											<Tooltip title={i18.t('attachment_create_working_document_tooltip')} >
												<div>
													<Button
														disabled={disabled}
														onClick={async (e)=>{
															e.stopPropagation()
															const permissionDelegate = {delegateType: 'workflow', delegateId: workflowId, grant: 'documenteditor'}
															const doc = await createDocument(navigation, {state: 'working', delegatedPermissions: [permissionDelegate]})
															attachment.defaultDocumentId = doc.documentId
															updateAttachment(attachment)	
														}}
													>
														{i18.t('attachment_create_working_document')}
													</Button>
												</div>
											</Tooltip>

										</Grid>
										
										{/* create template document placeholder */}
										<Tooltip title={i18.t('attachment_create_template_document_tooltip')} >
											<Button
												disabled={disabled}
												onClick={async (e)=>{
													const permissionDelegate = {delegateType: 'workflow', delegateId: workflowId, grant: 'documenteditor'}
													const doc = await createDocument(navigation, {state: 'template', delegatedPermissions: [permissionDelegate]})
													// TBD - make default name localizable
													attachment.defaultDocumentId = doc?.documentId
													updateAttachment(attachment)	
												e.stopPropagation()
												}}
											>
												{i18.t('attachment_create_template_document')}
											</Button>
										</Tooltip>

									</Grid>							
								</Grid>
							}

						</Grid>
					</Grid>
				</Grid>
				
			</AccordionSummary>

			{ expanded ?
				<AccordionDetails style={{cursor: 'default', padding: 0}}>	
					{ doc?.properties?.length > 0 ?
						<PropertyList 
							rootparentType={'document'}
							rootparentId={doc?.documentId}
							parentType={'documentproperty'} 
							parentId={doc?.documentId} 
							propertyList={doc?.properties}
							selectedProperty={selectedProperty}
							designTime={false}
							setSelectedProperty={setSelectedProperty}
							deleteProperty={deleteProperty}
						/>
					:
						<Typography>{i18.t('task_no_properties_to_display')}</Typography>
					}
				</AccordionDetails>
			: null }
			
			{ showTagFilterDialog ?
				<TagFilterDialog 
					tagList={[]} 
					tagFilter={tagFilter} 
					setTagFilter={setTagFilter} 
					setShowTagFilterDialog={setShowTagFilterDialog} 
				/>
			: null }

		</Accordion>
	)
}

export default (DocumentAttachmentEdit)
