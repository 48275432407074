import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import * as i18 from 'i18next'


const WorkflowCancelDialog = ({ navigation, workflowId, cancelWorkflow, setWorkflowCancelDialogIsOpen }) => {

	return (
		<Dialog style={{flex: 1}} open={true}>
			<DialogTitle><b>{i18.t('dialog_cancel_workflow_title')}</b></DialogTitle>
			<DialogContent style={{flex: 1}}>
				{i18.t('dialog_cancel_workflow_desc')}
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setWorkflowCancelDialogIsOpen(false)} color="secondary">
					{i18.t('dialog_cancel')}
				</Button>
				<Button onClick={()=>{cancelWorkflow();setWorkflowCancelDialogIsOpen(false)}} color="primary">
					{i18.t('dialog_ok')}
				</Button>
			</DialogActions>
		</Dialog>	
	)
}

export default WorkflowCancelDialog