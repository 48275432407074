import React, { useState } from 'react'
import { Grid } from '@mui/material'
import * as i18 from 'i18next'

import PermissionsView from '../containers/PermissionsView'


const WorkflowPermissions = ({ workflowId, designTime, disabled }) => {
 
//	console.log('WorkflowPermissions')
	
	if (!workflow) return null

	let height = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 50
	const tabbarHeight = document.getElementById('tabbar')?.clientHeight ? document.getElementById('tabbar')?.clientHeight : 50

	height = height - (toolbarHeight + tabbarHeight)

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='workflow-permissions' style={{width: width, height: height, position: 'absolute', overflow: 'auto'}} >				
			<PermissionsView
				sourceType={'workflow'}
				sourceId={workflowId}
				designTime={designTime}
				disabled={disabled}
			/>
		</Grid>
	)
}

export default WorkflowPermissions
