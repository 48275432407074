import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'

import ExpressionTuple from '../ExpressionTuple'


const SetPropertyValue = ({ me, workflowId, task, attachments, allProperties, milestoneActionIndex, focus, hover, designTime, disabled, updateTask, setFocus }) => {

//	console.log('SetPropertyValue')

	let milestoneAction = task.milestoneActions[milestoneActionIndex]

	if (!milestoneAction.expression) milestoneAction.expression = {"typeId": "ass::eq", "type": "any", "operator": "="}

	return (
		<Grid item >						
			<Grid container direction={'row'} alignItems='center' >

				<Typography style={{color: disabled ? 'silver' : 'black'}} >{i18.t('action_set_property_value')}</Typography>
				
				&nbsp;&nbsp;
				
				<ExpressionTuple
					me={me}
					expressionParentId={`${milestoneAction.actionId}::${milestoneActionIndex}`}
					rootparentId={workflowId}
					expressionTypes={me?.expressionTypes} 
					expression={milestoneAction.expression} 
					leftGroupFilter={['ref']} 
					middleGroupFilter={['ass']} 
					rightGroupFilter={['const','func','aop','ref']} 
					attachments={attachments}
					allProperties={allProperties} 
					strictMode={true}
					top={true}
					focus={focus}
					hover={hover}
					designTime={designTime}
					disabled={disabled}
					setExpression={(expr) => {
						milestoneAction.expression = expr
						updateTask(workflowId, task)
					}} 
					onDelete={null}
					setFocus={setFocus}
				/>
			</Grid>
		</Grid>
	)
}

export default SetPropertyValue
