
const styles = {
	elevation:			4,
	
	screen:				{ width: '100%', height: '100%', backgroundColor: 'white' },

	templatelist:		{ flex: 1 },
	workflowlist:		{ flex: 1 },
	assignmentlist:		{ flex: 1 },
	
	templateview:		{ flex: 1 },
	workflowview:		{ flex: 1 },
	assignmentview:		{ flex: 1 },
	
	template:			{ flex: 1, marginTop: 10, marginBottom: 5, marginLeft: 0, marginRight: 5 },
	workflow:			{ flex: 1, marginTop: 10, marginBottom: 5, marginLeft: 0, marginRight: 5 },
	assignment:			{ flex: 1, padding: 10 },
	propertylist:			{ flex: 1, marginTop: 0, marginBottom: 0 },
	propertyouter:			{ flex: 1, marginTop: 3, marginBottom: 3, borderLeftWidth: 1, borderLeftColor: 'lightgrey' },
	propertyinner:			{ flex: 1, marginTop: 5, marginBottom: 5, marginLeft: 10, marginRight: 10 },
	
	templateproperties:	{ marginTop: 5, marginBottom: 5, marginLeft: 5, marginRight: 5 },
	workflowproperties:	{ marginTop: 5, marginBottom: 5, marginLeft: 5, marginRight: 5 },

	formview:				{ marginTop: 10, marginBottom: 10 },
	propertylistview:			{ marginTop: 10, marginBottom: 10 },
	routeconditionlistview:	{ marginTop: 10, marginBottom: 10 },
	milestonelistview:		{ marginTop: 10, marginBottom: 10 },
	
	spacer:				{ marginTop: 10, marginBottom: 10 },   
	
	dashedgreytext:		{ flex: 1, fontSize: 12, fontWeight: 'normal', textAlign: 'left', marginTop: 5, marginBottom: 5, color: 'darkgrey', borderBottomWidth: 1, borderBottomColor: 'darkgrey', borderBottomStyle: 'dashed'},
	greytext:			{ flex: 1, fontSize: 12, fontWeight: 'normal', textAlign: 'left', marginTop: 5, marginBottom: 5, color: 'darkgrey', borderBottomWidth: 1, borderBottomColor: 'darkgrey'},
	
	listbox:			{ flex: 1, marginTop: 10, marginBottom: 10 },

}

export default styles
