import React from 'react'
import TaskView from '../containers/TaskView'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { Tooltip, Grid } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import * as i18 from 'i18next'


const TaskList = ({ workflowId, task, focusTaskId, level=0, setFocusTaskId, addTask, designTime }) => {

//	console.log('TaskList')

	if (!workflowId || !task) return null
		
	return (			
		<Droppable isDropDisabled={!designTime} droppableId={"taskdrop::" + task.taskId} type={`task::${level}`} >
			{(provided, snapshot) => (
				<div ref={provided.innerRef} >

					{ designTime ?
						<Grid container direction='column' alignItems='center'>
							<Tooltip title={i18.t('task_tooltip_add_task')} >
								<AddCircle style={{cursor: 'default', height: 20, width: 20}} onClick={(e)=>{
									addTask(workflowId, task.taskId, 0)
									e.stopPropagation()
								}}/>
							</Tooltip>
						</Grid>
					: <br/> }

					{task.tasks && task.tasks.map((taskId, index) => (

						<Grid item style={{padding: 0}} key={index}>

							<Draggable isDragDisabled={!designTime} key={`taskdrag::${workflowId}::${taskId}`} draggableId={`taskdrag::${workflowId}::${taskId}`} index={index} >
								{(provided, snapshot) => (
								<div
								  style={{background: 'green'}}
								  ref={provided.innerRef}
								  {...provided.draggableProps}
								  {...provided.dragHandleProps}
								>
								
									<TaskView
										workflowId={workflowId}
										taskId={taskId}
										focusTaskId={focusTaskId}
										index={index}
										level={level}
										setFocusTaskId={setFocusTaskId}
										designTime={designTime}
									/>
									
								</div>
								)}
							</Draggable>							

							{ designTime ?
								<Grid container direction='column' alignItems='center'>
									<Tooltip title={i18.t('task_tooltip_add_task')} >
										<AddCircle style={{cursor: 'default', height: 20, width: 20}} onClick={(e)=>{
											addTask(workflowId, task.taskId, index+1)
											e.stopPropagation()
										}}/>
									</Tooltip>
								</Grid>
							: <br/> }
							
						</Grid>
					))}

					{provided.placeholder}

				</div>
			)}
		</Droppable>
	)
}

export default TaskList
