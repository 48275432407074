import React, { useState } from 'react'
import CommentList from '../components/CommentList'
import { Grid, TextField, Typography, Button, Tooltip, CircularProgress } from '@mui/material'
import { Send } from '@mui/icons-material'
import * as i18 from 'i18next'


const Discussion = ({ comments, postComment, disabled }) => {
 
//	console.log('Discussion')
	
	const [listSelection, setListSelection] = useState()
	const [comment, setComment] = useState()
	const [commenting, setCommenting] = useState(false)

	return (
		<Grid item >

			<br/>
			
			{comments ?
				<CommentList 
					comments={comments} 
					listSelection={listSelection}
					setListSelection={setListSelection}
					disabled={disabled}
					postComment={postComment}
				/>
			: null }

			<Grid container direction='column' alignItems='flex-start' style={{paddingLeft: 55, paddingRight: 15}} >
			
				<TextField
					style={{backgroundColor: 'ghostwhite'}}
					value={comment ? comment : ''}
					fullWidth
					multiline
					rows={3}
					placeholder={i18.t('comment_enter_comment_here')}
					variant="outlined"
					disabled={disabled}
					onClick={() => {setListSelection(null)}}
					onChange={(e)=>{setComment(e.target.value)}}
					onKeyPress={async (e) => {
						if (e.key === 'Enter' && e.shiftKey) {
							await postComment(null, comment)
							setComment(null)
							e.stopPropagation()
						}
					}}																		
				/>
			
				<Tooltip title={i18.t('comment_post_tooltip')} >
					<div>
						<Button disabled={disabled || !comment || comment.length === 0} onClick={(e)=>{postComment(null, comment, setCommenting);setComment(null);}} >
							<Grid container direction='row' alignItems='center' >
								<Typography>{i18.t('comment_post_label')}</Typography>
								{commenting ? 
									<CircularProgress size={25} /> 
								: 
									<Send/>
								}
							</Grid>
						</Button>
					</div>
				</Tooltip>
				
			</Grid>
			
		</Grid>
	)
}

export default Discussion
