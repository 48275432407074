import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
//import { Droppable  } from 'react-beautiful-dnd'
import UUID from 'uuidjs'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

import FormItem from '../components/FormItem'


const cleanupParents = (items=[]) => {
	for (let i=0; i<items?.length; ++i) {
		let item = items[i]
		
		if (item.type === 'parentcontainer' && item.items.length === 1 && (item.items[0].type === 'container' || item.items[0].type === 'parentcontainer')) {
			item.items[0].xs = item.xs
			items.splice(i,1,item.items[0])
		} else if (item.type === 'parentcontainer' && item.items.length === 0) {
			item.type = 'container'
		} else {
			cleanupParents(item.items)
		}
	}
		
	if (items?.length === 1 && (items?.[0]?.type === 'container' || items?.[0]?.type === 'parentcontainer')) {
		items[0].xs = 12
	}
}

const Form = ({ workflowId, task, rootparentType, rootparentId, form, parentId, attachmentList, attachments,
				propertyList, properties, designTime=false, disabled=false, updateTask }) => {

//	console.log('Form', form)

	if (!rootparentId) return null

	let item = form?.pages?.[0]

	return (
		<Grid item>
			<FormItem 
				workflowId={workflowId}
				task={task}
				rootparentType={rootparentType} 
				rootparentId={rootparentId}
				parentId={parentId}
				item={item}
				attachmentList={attachmentList}
				attachments={attachments}
				propertyList={propertyList}
				properties={properties}
				designTime={designTime}
				disabled={disabled}
				updateTask={updateTask}
				cleanupParents={()=>cleanupParents(form?.pages?.[0].items)}
			/>
		</Grid>
	)
}

export default Form
