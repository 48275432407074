import React from 'react'
import { connect } from 'react-redux'
import SupportCaseList from '../components/SupportCaseList'
import { retrieveSupportCase, retrieveSupportCases, createSupportCase } from '../reducers/supportcases'


const SupportCaseListView = ({ navigation, tagFilter, app, supportcases, screen, retrieveSupportCases, retrieveSupportCase, createSupportCase }) => {
 
//	console.log('SupportCaseListView')

	return (
		<SupportCaseList 
			navigation={navigation}
			tagFilter={tagFilter}
			app={app}
			supportcases={supportcases} 
			screen={screen}
			refreshing={false} 
			retrieveSupportCases={retrieveSupportCases}
			retrieveSupportCase={retrieveSupportCase}
			createSupportCase={createSupportCase}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	app: state.app,
	supportcases: state.supportcases,
})

export default connect(mapStateToProps, { retrieveSupportCases, retrieveSupportCase, createSupportCase })(SupportCaseListView)
