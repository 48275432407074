import React, { useState } from 'react'
import { Grid, Tooltip, Icon } from '@mui/material'
import { DragIndicator, RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

import PropertyView from '../containers/PropertyView'

 
const PropertyListItem = ({ rootparentType, rootparentId, propertyId, selectedProperty,
							contacts, designTime, formProp=false, disabled, level, setSelectedProperty, setHighlightedRule=()=>{}, deleteProperty }) => {

//	console.log('PropertyListItem', rootparentId, propertyId)

	if (!rootparentId || !propertyId) return null

	const [hover, setHover] = useState(false)

	let hasFocus = (propertyId === selectedProperty)

	let borderLeft
	if (designTime && hasFocus) {
		borderLeft = '2px solid blue'
	} else if (designTime && !hasFocus) {
		borderLeft = '.5px solid lightgrey'
	}

	return (
		<Grid container 
			style={{paddingTop: 10, paddingBottom: 10}}
			direction={'row'} 
			alignItems="center" 
			wrap="nowrap"
			onMouseMove={(e) => {e.stopPropagation();if(!hover){setHover(true)};if (selectedProperty !== propertyId) {setHighlightedRule(propertyId)};}}
			onMouseEnter={(e) => {e.stopPropagation();setHover(true);if (selectedProperty !== propertyId) {setHighlightedRule(propertyId)};}}
			onMouseLeave={(e) => {e.stopPropagation();setHover(false);setHighlightedRule(null);}}
		>			
								
			{ (designTime || formProp) && hover ?
				<Tooltip title={i18.t('property_tooltip_drag_property')} >
					<DragIndicator style={{cursor: 'grab'}}/>
				</Tooltip>
			: 
				<Icon/>
			}
			
			<Grid 
				item
				style={{flex: 1, cursor: document.body.style.cursor, borderLeft: borderLeft}} 
				onClick={()=>setSelectedProperty(propertyId)} 
			>
				<PropertyView 
					rootparentType={rootparentType}
					rootparentId={rootparentId} 
					propertyId={propertyId}
					designTime={designTime}
					disabled={disabled}
					level={level}
				/>					
			</Grid>

			{ (designTime || formProp) && hover ?
				<RemoveCircle 
					style={{cursor: 'default', height: 20, width: 20}}
					onClick={()=>{
						deleteProperty(propertyId)
						setSelectedProperty(null)
					}}
				/>	
			: 
				<Icon/> 
			}
			
		</Grid>
	)
}

export default PropertyListItem