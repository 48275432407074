import React, { useState } from 'react'
import { Grid, Button, Tooltip, Hidden, Divider } from '@mui/material'
import { Refresh, ArrowBack, Save, Delete, GetApp } from '@mui/icons-material'
import * as i18 from 'i18next'

import TabBar from '../components/TabBar'
import ExtractDetail from '../components/ExtractDetail'
import PermissionsView from '../containers/PermissionsView'
import DiscussionView from '../containers/DiscussionView'


const Extract = ({ navigation, extract, documents, properties, memberProperties, tags, designTime,
					updateExtract, retrieveExtract, addProperty, deleteProperty, 
					updateProperty, moveToCompositeProperty, retrieveDocument, retrieveContent }) => {
 
//	console.log('Extract', extract)

	if (!extract) return null

	const [tab, setTab] = useState('tab_details')
	const [retrieveAttempted, setRetrieveAttempted] = useState(false)
	const [docRetrieveAttempted, setDocRetrieveAttempted] = useState(false)
	const [contentRetrieveAttempted, setContentRetrieveAttempted] = useState(false)

	const doc = documents?.[extract.documentId]
	if (!doc?.properties && !docRetrieveAttempted) {retrieveDocument(extract.documentId);setDocRetrieveAttempted(true);}
	if (doc && !doc.content?.data && !contentRetrieveAttempted) {retrieveContent(doc.documentId);setContentRetrieveAttempted(true);}

	if (!extract.properties) extract.properties = []

	let tabValues = ['tab_details','tab_permissions','tab_discussion']
	let tabLabels = [i18.t('tab_details'), i18.t('tab_permissions'), i18.t('tab_discussion')]

	if (!extract.created && !retrieveAttempted) {
		retrieveExtract(extract.extractId)
		setRetrieveAttempted(true)
	}

	let height = document.getElementById('outermain')?.clientHeight ? document.getElementById('outermain')?.clientHeight : 0
	let width = document.getElementById('outermain')?.clientWidth ? document.getElementById('outermain')?.clientWidth : 0
	const toolbarHeight = document.getElementById('toolbar')?.clientHeight ? document.getElementById('toolbar')?.clientHeight : 40

	height = height - toolbarHeight

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	return (
		<Grid item id='extract' style={{width: width, height: height}}>

			<TabBar tabValues={tabValues} tabLabels={tabLabels} tab={tab} setTab={setTab} />	
		
			{ tab === 'tab_details' ?
				<ExtractDetail
					rootparentId={extract.extractId}
					parentType={'extract'}
					parentId={extract.extractId}
					parent={extract}
					properties={properties}
					memberProperties={memberProperties}
					tags={tags}
					doc={doc}
					designTime={designTime}
					updateParent={(parent)=>{updateExtract(parent)}}
					addProperty={addProperty}
					deleteProperty={deleteProperty}
					updateProperty={updateProperty}
					moveToCompositeProperty={moveToCompositeProperty}
				/>				
			: tab === 'tab_permissions' ?
				<PermissionsView
					sourceType={'extract'}
					sourceId={extract.extractId}
					designTime={designTime}
				/>
			: tab === 'tab_discussion' ?
				<DiscussionView
					sourceType={'extract'}
					sourceId={extract.extractId}
				/>
			: null }

		</Grid>
	)
}

export default Extract

