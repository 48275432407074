import React, { useState } from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, Typography  } from '@mui/material'
import * as i18 from 'i18next'

import PropertyListItem from '../../components/PropertyListItem'


const PropertyAddDialog = ({ item, attachmentList, attachments, propertyList, properties, updateTask, setDialogIsOpen }) => {

	let selectedAttachments = item?.items?.map(item => item?.itemId).filter(id => id !== null)
	let selectedProperties = item?.items?.map(item => item?.itemId).filter(id => id !== null)
	
	const [showPropertyList, setShowPropertyList] = useState(false)
	const [selectedProperty, setFocusPropertyId] = useState(selectedProperties ? selectedProperties[0] : null)

	return (
		<Dialog open={true} fullWidth >
			<DialogTitle><b>{i18.t('dialog_add_property')}</b></DialogTitle>
			<DialogContent>

				<Typography  variant="subtitle1">{i18.t('task_attachments_select_label')}</Typography>
				{attachmentList && attachmentList.map((attachmentId, index) => (
					<Grid key={index} container direction='row' alignItems='center' >
						<Checkbox 
							style={{width: 36, height: 36}}
							checked={selectedAttachments ? (selectedAttachments.findIndex(id => id === attachmentId) >= 0) : false} 
							onChange={
								event =>{
									if (event.target.checked) {
										// add attachment to task
										if (!item.items) {item.items = []}
										item.items.push({type: "attachment", xs: -1, itemId: attachmentId})
										updateTask()
									} else {
										// remove attachment from task
										let index = item.items.findIndex(item2 => item2.itemId === attachmentId)
										item.items.splice(index, 1)
										updateTask()
									}
								}
							}
						/>
						<Typography>{attachments[attachmentId]?.pname}</Typography>
					</Grid>
				))}

				<Typography  variant="subtitle1">{i18.t('task_properties_select_label')}</Typography>
				{propertyList && propertyList.map((propertyId, index) => (
					<Grid key={index} container direction='row' alignItems='center' >
						<Checkbox 
							style={{width: 36, height: 36}}
							checked={selectedProperties ? (selectedProperties.findIndex(id => id === propertyId) >= 0) : false} 
							onChange={
								event =>{
									if (event.target.checked) {
										// add property to task
										if (!item.items) {item.items = []}
										item.items.push({type: "property", xs: -1, itemId: propertyId})
										updateTask()
									} else {
										// remove property from task
										let index = item.items.findIndex(item2 => item2.itemId === propertyId)
										item.items.splice(index, 1)
										updateTask()
									}
								}
							}
						/>
						<Typography>{properties[propertyId]?.pname}</Typography>
					</Grid>
				))}

			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setDialogIsOpen(false)} color="primary" variant="contained" >
					{i18.t('dialog_done')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default PropertyAddDialog