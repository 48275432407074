import React, { useState } from 'react'
import UUID from 'uuidjs'
import { Grid, FormControl, FormControlLabel, Typography, Switch, InputLabel, Select, MenuItem } from '@mui/material'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

import TaskWho from '../components/TaskWho'
import TaskWhat from '../components/TaskWhat'
import TaskWhen from '../components/TaskWhen'
import TaskWhere from '../components/TaskWhere'
import TaskList from '../components/TaskList'	
import MilestoneActionList from '../components/MilestoneActionList'
import TabBar from '../components/TabBar'


//						workflowId, workflowProperties, workflowAttachments, 

const TaskDetail = ({ me, contacts, actions, workflow,
						task, focusTaskId, parentTask, attachments, allProperties, documents, tabnav, designTime, level,
						setFocusTaskId, addTask, updateTask, onParallelExecutionChange,
						setAssignees, onAssigneeDelete, setAssigneesGroupProperty, setAssigneesType,
						onMilestoneActionAdd, retrieveContacts, setTabNav }) => {
	
//	console.log('TaskDetail')
		
	if (!workflow || !task) return null
	
	let taskId = task.taskId
	let workflowId = workflow.workflowId

	let taskTypeParent = task.type === 'parent'
	let taskTypeUser = task.type === 'user'
	let taskTypeStart = task.type === 'start'
	let taskTypeMilestone = task.type === 'milestone'
	let taskTypeFinish = task.type === 'finish'

	// Determine feature enablement based on this users settings
	let settings = me && me && me.settings ? [...me.settings] : []

	let setting = settings.find(setting => setting.name==='settings_enable_parallel_routing')
	let enableParallelRouting = setting ? setting.value : false
	
	setting = settings.find(setting => setting.name==='settings_enable_conditional_routing')
	let enableConditionalRouting = setting ? setting.value : false

	let tabValues=[]
	
	if (taskTypeStart) {
		tabValues.push('tab_what')
	} else if (taskTypeUser) {
		tabValues.push('tab_who')
		tabValues.push('tab_what')
		tabValues.push('tab_when')
	} else if (taskTypeMilestone) {
		tabValues.push('tab_what')
	} if (taskTypeParent) {
		tabValues.push('tab_what')
		tabValues.push('tab_when')
	}

	if (parentTask && parentTask.parallelExecution !== true && enableConditionalRouting) {
		tabValues.push('tab_where')
	} else {
		if (tabnav === 'tab_where') tabnav = 'tab_what'
	}

	let actionlist = actions ? Object.keys(actions).map(key => actions[key]) : []
	actionlist = actionlist.filter(action => action.targetTypes.includes('workflow'))

	return (
		<Grid item onClick={event=>event.stopPropagation()} >

			{/* If there are any validation errors - display them here. */}
			{(task.validationErrors && task.validationErrors.length > 0) ?
				task.validationErrors.map((err, index) => {
					if (err.warning)
						return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
								
					if (err.error)
						return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
				})
			: null }
																
			{ (taskTypeUser || taskTypeMilestone) ?
				<Grid container direction="row" spacing={1} alignItems="center">
					<Typography style={{color: taskTypeMilestone ? 'lightgrey' : null}}>{i18.t('task_type_user_label')}</Typography>
					<Switch 
						disabled={!designTime}
						checked={task.type === 'milestone'} 
						onChange={(e)=>{
							e.stopPropagation()

							if (taskTypeUser) {
								task.type = 'milestone'
								task.routeCondition.quorum = 'expr'
								task.routeCondition.options = []
								task.routeCondition.defaultOption = {
									type: 'nexttask'
								}
								setTabNav('tab_what')
							} else if (taskTypeMilestone) {
								task.type = 'user'
								task.routeCondition.quorum = 'any'
								setTabNav('tab_who')
							}
							
							updateTask(workflowId, task)
						}}
					/>
					<Typography style={{color: taskTypeUser ? 'lightgrey' : null}}>{i18.t('task_type_milestone_label')}</Typography>
				</Grid>
			: null }

			{!taskTypeFinish ?
			
				<Grid container direction={'column'} >				
										
					{(taskTypeStart || taskTypeUser || taskTypeMilestone || taskTypeParent) ?
						<TabBar tabValues={tabValues} tab={tabnav} setTab={setTabNav} />	
					: null }

					{/* Only user steps have assignees. */}
					{ taskTypeUser && tabnav === 'tab_who' ? 
						<TaskWho
							me={me}
							contacts={contacts}
							workflowId={workflowId} 
							workflowProperties={workflow.properties}
							allProperties={allProperties}
							task={task}
							designTime={designTime}
							disabled={workflow.state === 'completed' || workflow.state === 'canceled'}
							setAssignees={setAssignees}
							onAssigneeDelete={onAssigneeDelete}
							setAssigneesType={setAssigneesType}
							setAssigneesGroupProperty={setAssigneesGroupProperty}
							retrieveContacts={retrieveContacts}
						/>
					: null }							


					{/* Display properties for launch and users tasks. */}
					{ (taskTypeUser || taskTypeStart || taskTypeMilestone ) && tabnav === 'tab_what' ?
						<TaskWhat
							me={me}
							workflow={workflow}
							attachments={attachments}
							allProperties={allProperties}
							documents={documents}
							task={task}
							actionlist={actionlist}
							designTime={designTime}
							updateTask={updateTask}
							onMilestoneActionAdd={onMilestoneActionAdd}
						/>
					: null }

					{/* If this is a parent task - show a control to set whether subtasks run in parallel or serially */}
					{enableParallelRouting && (taskTypeStart || taskTypeParent) && (tabnav === 'tab_what') ?
						<FormControlLabel
							control={
								<Switch
									disabled={!designTime}
									checked={task.parallelExecution}
									onChange={()=>onParallelExecutionChange(!task.parallelExecution)}
									color="primary"
								/>
							}
							label={task.parallelExecution ? i18.t('task_parallel_execution_on') : i18.t('task_parallel_execution_off')}
						/>	
					: null }
					
					{/* If this is a start/parent task - show the subtasks as a flow */}
					{ taskTypeParent && task.tasks && task.tasks.length > 0 && tabnav === 'tab_what' ?
						<TaskList 
							workflowId={workflowId}
							task={task}
							focusTaskId={focusTaskId}
							level={level+1}
							designTime={designTime}
							setFocusTaskId={setFocusTaskId}
							addTask={addTask}
						/>
					: null }

					{/* The launch step has a pre-defined route to the finish step - no need for route options there */}
					{/* If the parent task has set parallelExecution to true - then there are no routing options either */}
					{enableConditionalRouting && tabnav === 'tab_where' ?
						<TaskWhere
							me={me}
							workflowId={workflowId} 
							task={task} 
							attachments={attachments}
							allProperties={allProperties}
							documents={documents}
							updateTask={updateTask} 
							designTime={designTime}
						/>
						
					 : null }
					
					{/* Display when a task is due. */}
					{(taskTypeUser || taskTypeParent) && tabnav === 'tab_when' ?
						<TaskWhen
							task={task}
						/>
					: null }

				</Grid>
			: taskTypeFinish ?
				<Grid container direction={'column'} >				
				
					<FormControl fullWidth >
						<InputLabel shrink={false} style={{color: 'black'}} >{i18.t('task_add_milestone_actions')}</InputLabel>
						<Select 
							variant='standard'
							disabled={!designTime}
							value={''} 
							multiple={false} 
							onChange={(event) => {
								let action = cloneDeep(event.target.value)
								action.actionId = UUID.genV4().toString()
								delete action.targetTypes
								onMilestoneActionAdd(action)
							}}
						>
							{actionlist.map((action, index) => (
								<MenuItem key={index} value={action} >{i18.t(`action_${action.type}`)}</MenuItem>
							))}
							
						</Select>
					</FormControl>
																	
					{(task.milestoneActions===undefined || task.milestoneActions === null || task.milestoneActions.length===0) ? 
						<Typography style={{marginLeft: 10, color: 'orange'}} >{i18.t('task_no_milestone_actions')}</Typography>
					:
						<MilestoneActionList
							me={me}
							workflowId={workflowId}
							task={task}
							attachments={attachments}
							allProperties={allProperties}
							documents={documents}
							designTime={designTime}
							updateTask={updateTask}
						/>
					}
						
				</Grid>
			: /*milestoneTask ?*/
				<Grid container direction={'column'} >				
				</Grid>
			}
			
		</Grid>
	)
}

export default TaskDetail
