import React, { useState } from 'react'
import { Grid, Button, Icon, Typography, TextField, Tooltip } from '@mui/material'
import { RemoveCircle, Settings, Visibility } from '@mui/icons-material'
import { Droppable, Draggable  } from 'react-beautiful-dnd'
import UUID from 'uuidjs'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

import PropertyListItem from '../components/PropertyListItem'
import AttachmentListItem from '../components/AttachmentListItem'
import PropertyAddDialog from '../components/dialogs/PropertyAddDialog'
import FormItemStyleDialog from '../components/dialogs/FormItemStyleDialog'
import AssignmentPreviewDialog from '../components/dialogs/AssignmentPreviewDialog'


const assert = (condition) => {if (!condition) {throw ('Assertion failed.')}}

export const evalGridSize = (xs) => {
	if (xs === -1) {
		return true
	} else if (xs === -2) {
		return 'auto'
	} else {
		return xs ? xs : 12
	}
}	

const FormItem = ({ workflowId, task, rootparentType, rootparentId, parentId, parentItem, item, index=0, 
					attachmentList, attachments, propertyList, properties, designTime, disabled,
					updateTask, cleanupParents, setXS, deleteProperty, deleteSection, splitHoriz, splitVert }) => {

//	console.log('FormItem', item)

	if (!item) return null

	const [showPreviewDialog, setShowPreviewDialog] = useState(false)
	const [showPropertyAddDialog, setShowPropertyAddDialog] = useState(false)
	const [showFormItemStyleDialog, setShowFormItemStyleDialog] = useState(false)
	const [hover, setHover] = useState(false)

	let direction = (item.type === 'page' || item.type === 'parentcontainer') ? 'row' : 'column'
	let style = item.style ? cloneDeep(item.style) : {}
	let headerStyle = item.header?.style ? cloneDeep(item.header.style) : {}
	let footerStyle = item.footer?.style ? cloneDeep(item.footer.style) : {}
	
	if (designTime) {

		style.borderStyle = 'dotted'
		if (!style.borderWidth || style.borderWidth === 0) style.borderWidth = 2
	
		if (item.type === 'page') {
			style.borderColor = 'blue'
		} else if (item.type === 'parentcontainer') {
			style.borderColor = 'green'
		} else if (item.type === 'container') {
			style.borderColor = 'orange'
		}
	}
	
	const onHeaderChange = (item, value) => {
		if (!item.header) {item.header = {itemId: UUID.genV4().toString(), type: 'header'}}
		item.header.value = value
		updateTask()
	}
	
	const onFooterChange = (item, value) => {
		if (!item.footer) {item.footer = {itemId: UUID.genV4().toString(), type: 'footer'}}
		item.footer.value = value
		updateTask()
	}
	
	return (
		<Grid 
			xs={item.xs ? evalGridSize(item.xs) : 12} 
			item 
			onMouseMove={() => {if(!hover){setHover(true)}}}
			onMouseEnter={() => {setHover(true)}}
			onMouseLeave={() => {setHover(false)}}
		>
			{ showPreviewDialog ?
				<AssignmentPreviewDialog 
					task={task}
					assignment={{...task, state: 'working', assignmentId: rootparentId}}
					properties={properties} 
					setShowPreviewDialog={setShowPreviewDialog}
				/>									
			: null }
			
			{ item.type === 'page' || item.type === 'parentcontainer' ?
				<Grid item>
					<Grid container direction='column' style={{...style, ...item?.style}} >

						{designTime ?
							<TextField
								variant='standard'
								fullWidth
								InputLabelProps={{shrink: true}}
								placeholder={i18.t(item.type === 'page' ? 'workflow_tooltip_form_page_header' : 'workflow_tooltip_form_section_header')}
								value={item?.header?.value ? item.header.value : ''}
								onChange={(event)=>onHeaderChange(item, event.target.value)}
							/>
						:
							<Typography style={headerStyle}>{item?.header?.value}</Typography>
						}
						
						<Grid container direction={direction} >

							{item.items?.map((item2, index) =>
								<FormItem
									key={index}
									workflowId={workflowId}
									rootparentType={rootparentType} 
									rootparentId={rootparentId}
									parentId={parentId}
									parentItem={item}
									item={item2} 
									index={index}
									attachmentList={attachmentList}
									attachments={attachments}
									propertyList={propertyList}
									properties={properties}
									designTime={designTime}
									disabled={disabled}

									updateTask={updateTask}
									cleanupParents={cleanupParents}
									setXS={(item.type === 'parentcontainer' && item.items.length > 1 && item.items[0].xs < 12) ? (xs)=>{
										let origXS = item.items[index].xs
										if (index === 0) {
											item.items[index+1].xs = item.items[index+1].xs + (origXS - xs)
											item.items[index].xs = xs
										} else {
											item.items[index-1].xs = item.items[index-1].xs + (origXS - xs)
											item.items[index].xs = xs
										}
										updateTask()
									} : null}

									deleteProperty = {()=> {
										if (item2.type === 'property' || item2.type === 'attachment') {
											item.items.splice(index,1)
											cleanupParents()
											updateTask()
										}
									}}
									
									deleteSection = {item.type === 'parentcontainer' ? ()=>{
										if (index === 0 && item.items.length > 2) {
											if ((item.items[index].type === 'container' || item.items[index].type === 'parentcontainer') && 
												(item.items[index+1].type === 'container' || item.items[index+1].type === 'parentcontainer')) {
												item.items[index+1].xs = Math.min(12, item.items[index+1].xs + item.items[index].xs)
											}
										} else if (index > 0 && item.items.length > 2) {
											if ((item.items[index].type === 'container' || item.items[index].type === 'parentcontainer') &&
												(item.items[index-1].type === 'container' || item.items[index-1].type === 'parentcontainer')) {
												item.items[index-1].xs = Math.min(12, item.items[index-1].xs + item.items[index].xs)
											}
										}
										item.items.splice(index, 1)

										if (parentItem?.items.length === 1 && (parentItem?.type === 'page' || parentItem?.type === 'parentcontainer') && item?.type === 'container') {
											parentItem.items = item.items
										}

										if (parentItem?.type === 'page' && parentItem.items.length === 0) {
											parentItem.items.push({itemId: UUID.genV4().toString(), type: 'container', xs: 12, items: []})
										}
										
										cleanupParents()
										updateTask()
									} : null}

									splitHoriz = {()=>{
										if (item2.type === 'container' || item2.type === 'parentcontainer') {
											
											let new1 = cloneDeep(item2)
											new1.xs = 6
											new1.itemId = UUID.genV4().toString()
											assert(new1.xs > 0)
											
											let new2 = cloneDeep(item2)
											new2.items = []
											new2.type = 'container'
											new2.xs = 6
											new2.itemId = UUID.genV4().toString()
											delete new2.header
											delete new2.footer
											assert(new2.xs > 0)

											item2.type = 'parentcontainer'
											item2.items = [new1, new2]
											delete item2.header
											delete item2.footer

											updateTask()
										}
									}}
									
									splitVert = {()=>{
										if (item2.type === 'container' || item2.type === 'parentcontainer') {
											
											let new1 = cloneDeep(item2)
											new1.xs = 12
											new1.itemId = UUID.genV4().toString()
											
											let new2 = cloneDeep(item2)
											new2.items = []
											new2.type = 'container'
											new2.xs = 12
											new2.itemId = UUID.genV4().toString()
											delete new2.header
											delete new2.footer

											item2.type = 'parentcontainer'
											item2.items = [new1, new2]
											delete item2.header
											delete item2.footer

											updateTask()
										}
									}}
								/>
							)}

							{ designTime ?
								<TextField
									variant='standard'
									fullWidth
									InputLabelProps={{shrink: true}}
									placeholder={i18.t(item.type === 'page' ? 'workflow_tooltip_form_page_footer' : 'workflow_tooltip_form_section_footer')}
									value={item?.footer?.value ? item.footer.value : ''}
									onChange={(event)=>onFooterChange(item, event.target.value)}
								/>
							:
								<Typography style={footerStyle} >{item?.footer?.value}</Typography>
							}
							
						</Grid>
					</Grid>
				</Grid>
			: (item.type === 'container') ?
				<Grid item>
					<Grid container direction='column' style={{...style, ...item?.style}} >

						{ designTime ?
							<TextField
								variant='standard'
								fullWidth
								InputLabelProps={{shrink: true}}
								placeholder={i18.t('workflow_tooltip_form_section_header')}
								value={item?.header?.value ? item.header.value : ''}
								onChange={(event)=>onHeaderChange(item, event.target.value)}
							/>
						:
							<Typography style={headerStyle} >{item?.header?.value}</Typography>
						}
						
						<Grid container direction={direction} >

							<Droppable style={{flex: 1}} isDropDisabled={!designTime} droppableId={`formpropertydrop::${parentId}::${item.itemId}`} type={`formcontainer::${parentId}`} key={index} >
								{(provided, snapshot) => (
									<div ref={provided.innerRef} {...provided.droppableProps} >
										{item.items?.map((item2, index) =>
											<FormItem
												key={index}
												workflowId={workflowId}
												rootparentType={rootparentType} 
												rootparentId={rootparentId} 
												parentId={parentId}
												parentItem={item}
												item={item2} 
												index={index}
												attachmentList={attachmentList}
												attachments={attachments}
												propertyList={propertyList}
												properties={properties}
												designTime={designTime}
												disabled={disabled}

												updateTask={updateTask}
												cleanupParents={cleanupParents}

												deleteProperty = {()=> {
													if (item2.type === 'property' || item2.type === 'attachment') {
														item.items.splice(index,1)
														cleanupParents()
														updateTask()
													}
												}}
												
												splitHoriz = {()=>{
													if (item2.type === 'container' || item2.type === 'parentcontainer') {
														
														let new1 = cloneDeep(item2)
														new1.xs = 6
														new1.itemId = UUID.genV4().toString()
														assert(new1.xs > 0)
														
														let new2 = cloneDeep(item2)
														new2.items = []
														new2.type = 'container'
														new2.xs = 6
														new2.itemId = UUID.genV4().toString()
														delete new2.header
														delete new2.footer
														assert(new2.xs > 0)

														item2.type = 'parentcontainer'
														item2.items = [new1, new2]
														delete item2.header
														delete item2.footer

														updateTask()
													}
												}}
												
												splitVert = {()=>{
													if (item2.type === 'container' || item2.type === 'parentcontainer') {
														
														let new1 = cloneDeep(item2)
														new1.xs = 12
														new1.itemId = UUID.genV4().toString()
														
														let new2 = cloneDeep(item2)
														new2.items = []
														new2.type = 'container'
														new2.xs = 12
														new2.itemId = UUID.genV4().toString()
														delete new2.header
														delete new2.footer

														item2.type = 'parentcontainer'
														item2.items = [new1, new2]
														delete item2.header
														delete item2.footer

														updateTask()
													}
												}}
											/>
										)}

										{provided.placeholder}

										{ (item.type === 'container') && designTime ?
											<Grid container direction='row' style={{alignItems: 'center', justifyContent: 'center'}} >
												<Tooltip title={i18.t('formitem_add_property_tooltip')} >
													<Button style={{paddingTop: 0, paddingBotton: 0}} size="small" color="primary" onClick={()=>{setShowPropertyAddDialog(true)}}>
														{i18.t('formitem_add_property_label')}
													</Button>
												</Tooltip>
											</Grid>
										: null }

									</div>
								)}								
							</Droppable>

							{ designTime ?
								<TextField
									variant='standard'
									fullWidth
									InputLabelProps={{shrink: true}}
									placeholder={i18.t('workflow_tooltip_form_section_footer')}
									value={item?.footer?.value ? item.footer.value : ''}
									onChange={(event)=>onFooterChange(item, event.target.value)}
								/>
							:
								<Typography style={footerStyle} >{item?.footer?.value}</Typography>
							}
							
						</Grid>
					</Grid>
				</Grid>
			: (item.type === 'property') ?
				<Grid item>
					<Draggable isDragDisabled={!designTime} key={`formpropertydrag::${parentItem.itemId}::${item.itemId}`} draggableId={`formpropertydrag::${parentItem.itemId}::${item.itemId}`} index={index} >
						{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
							<PropertyListItem
								rootparentType={rootparentType}
								rootparentId={rootparentId}
								propertyId={item?.itemId}
								selectedProperty={null}
								designTime={false}
								formProp={designTime}
								disabled={disabled}
								level={0}
								deleteProperty={deleteProperty}
								setSelectedProperty={()=>{}}
							/>
						</div>
						)}
					</Draggable>
				</Grid>
			: (item.type === 'attachment') ?
				<Grid item>
					<Draggable isDragDisabled={!designTime} key={`formpropertydrag::${parentItem.itemId}::${item.itemId}`} draggableId={`formpropertydrag::${parentItem.itemId}::${item.itemId}`} index={index} >
						{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
							<AttachmentListItem
								workflowId={workflowId}
								rootparentType={rootparentType}
								rootparentId={rootparentId}
								attachmentId={item?.itemId}
								selectedProperty={null}
								designTime={false}
								formProp={designTime}
								disabled={disabled}
								level={0}
								deleteAttachment={deleteProperty}
								setFocusId={()=>{}}
							/>
						</div>
						)}
					</Draggable>
				</Grid>
			: null }

			{ hover && designTime && item.type !== 'property' && item.type !== 'attachment' ?
				<Grid container direction='row' style={{flex: 1, justifyContent: 'space-between'}} >
					<Grid item>						
						<Grid container direction='row' style={{alignItems: 'center'}} >
							{ (item.type === 'page' || item.type === 'parentcontainer' || item.type === 'container') && designTime ?
								<Tooltip title={i18.t('formitem_settings_tooltip')} >
									<Button style={{paddingTop: 0, paddingBotton: 0}}><Settings onClick={()=>{setShowFormItemStyleDialog(true)}} color='primary' /></Button>
								</Tooltip>
							: null }

							{ (item.type === 'page') && designTime ?
								<Tooltip title={i18.t('task_tooltip_preview')} >
									<Button style={{paddingTop: 0, paddingBotton: 0}} color="primary" onClick={()=>setShowPreviewDialog(true)} ><Visibility/>&nbsp;{i18.t('task_preview_button')}</Button>
								</Tooltip>
							: null }

							{ (item.type === 'parentcontainer' || item.type === 'container') && designTime ?
								<Tooltip title={i18.t('formitem_split_vertical_tooltip')} >
									<Button style={{paddingTop: 0, paddingBotton: 0}} size="small" color="primary" onClick={splitVert}>
										{i18.t('formitem_split_vertical_label')}
									</Button>
								</Tooltip>
							: null }

							{ (item.type === 'parentcontainer' || item.type === 'container') && designTime ?
								<Tooltip title={i18.t('formitem_split_horizontal_tooltip')} >
									<Button style={{paddingTop: 0, paddingBotton: 0}} size="small" color="primary" onClick={splitHoriz}>
										{i18.t('formitem_split_horizontal_label')}
									</Button>
								</Tooltip>
							: null }
						</Grid>							
					</Grid>
				
					<Grid item>
						{ (item.type === 'container' || item.type === 'parentcontainer') && designTime && deleteSection ?
							<RemoveCircle color='primary' style={{cursor: 'default', height: 20, width: 20}} onClick={deleteSection} />
						: null }
					</Grid>
				</Grid>
			: designTime && item.type !== 'property' && item.type !== 'attachment' ? 
				<Icon style={{padding: 1}}/> 
			: null }
			
			{ showPropertyAddDialog && designTime ?
				<PropertyAddDialog
					item={item}
					attachmentList={attachmentList}
					attachments={attachments}
					propertyList={propertyList}
					properties={properties}
					updateTask={updateTask}
					setDialogIsOpen={setShowPropertyAddDialog}
				/>
			: null }
			
			{ showFormItemStyleDialog && designTime ?
				<FormItemStyleDialog
					item={item}
					updateTask={updateTask}
					setXS={setXS}
					setDialogIsOpen={setShowFormItemStyleDialog}
				/>
			: null }

		</Grid>
	)
}

export default FormItem
