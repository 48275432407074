import React from 'react'
import { Card, CardMedia, ListItem, Typography } from '@mui/material'
import * as i18 from 'i18next'

const AssignmentListItem = ({ navigation, me, assignment, retrieveAssignment }) => {

//	console.log('AssignmentListItem')

	if (!assignment) return null

	let name = assignment ? assignment.name : ''
							
	if (name && name.length > 30)
		name = name.substring(0, 30) + "..."

if (!assignment.state) console.log(assignment)
	
	return (

		<ListItem 
			onClick={()=> {
				if (!assignment.created) {retrieveAssignment(assignment.assignmentId)}
				navigation.navigate('assignment', {assignmentId: assignment.assignmentId, name: assignment.name})
			}} 
			button 
		>

			{ assignment.imageref && assignment.imageref !== null ?
				<Card><CardMedia style={{ height: 20, width: 20 }} image={assignment.imageref ? assignment.imageref : null} /></Card>
			: null}

			&nbsp;&nbsp;
			
			<Typography noWrap >{name} ({i18.t(`assignment_state_${assignment.state}`)})</Typography>
			
			{ assignment?.domain !== me?.domain ?
				<Typography style={{color: 'red'}}>*</Typography>
			:
				null
			}
			
		</ListItem>
	)
}

export default AssignmentListItem
