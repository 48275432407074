import React from 'react'
import { Grid, TextField } from '@mui/material'
import * as i18 from 'i18next'


// Runtime view of component
const PropertyDate = ({ nameTypeComponent, property, updateProperty, designTime, disabled }) => {
	
	if (!property)
		return null
	
	let def = property.default ? new Intl.DateTimeFormat("en-US", {dateStyle: 'full', timeStyle: 'long'}).format(Date.parse(property.default)) : ''
	let value = property.value ? new Intl.DateTimeFormat("en-US", {dateStyle: 'full', timeStyle: 'long'}).format(Date.parse(property.value)) : ''
	
	let placeholder
	if (designTime) 
		placeholder = i18.t(`property_${property.subType}_designtime_placeholder`)
	else
		placeholder = i18.t(`property_${property.subType}_runtime_placeholder`)
		
	return (
		<Grid container direction={'row'} alignItems={designTime ? 'flex-start' : 'center'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
	
			<Grid item style={{paddingLeft: 10}} >
				<TextField
					placeholder={placeholder}
					value={designTime ? def : value}
					type={'datetime-local'}
					onChange={(event)=>{
						designTime ?
							property.default = event.target.value
						:
							property.value = event.target.value
						updateProperty(property)
					}}
					disabled={disabled}
				/>
			</Grid>
		</Grid>
	)
}

export default (PropertyDate)
