import React, { useState } from 'react'
import { Grid } from '@mui/material'

import MemberSelect from '../../components/MemberSelect'


// Runtime view of component
const PropertyEmail = ({ nameTypeComponent, me, property, contacts=[], designTime, disabled, updateProperty, retrieveContacts }) => {

	// Re-retrieve contacts if needed
	retrieveContacts()
	
	let filteredContacts = [...contacts]
	if (filteredContacts.findIndex(contact => contact?.email === me?.email) === -1) {
		filteredContacts.push(me)	
	}

	let email
	if (designTime) {
		email = property.default
	} else {
		email = property.value
	}
	
	const update = (email) => {
		if (designTime) {
			property.default = email
		} else {
			property.value = email
		}
		updateProperty(property)
	}


	return (
		<Grid container direction={'row'} alignItems={designTime ? 'flex-start' : 'center'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
	
			<Grid item style={{flex:1, paddingLeft: 10}}>
				<MemberSelect initialValue={email} contacts={filteredContacts} designTime={designTime} update={update} />
			</Grid>
		</Grid>
	)
}

export default (PropertyEmail)
