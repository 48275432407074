import React, { useState } from 'react'
import { Grid, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material'
import * as i18 from 'i18next'
import { red } from '@mui/material/colors'

import PropertyView from '../../containers/PropertyView'


const PropertyTable = ({ nameTypeComponent, rootparentType, rootparentId, property, properties, designTime, disabled }) => {

//	console.log('PropertyTable', property, properties)
	
	if (!property) return null

	return (
		<Grid container direction={'row'} alignItems={designTime ? 'flex-start' : 'center'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
			
			<Grid item style={{flex: 1}} >
				<TableContainer component={Paper}>
					<Table sx={{minWidth: 650}} size="small" >
						<TableHead>
							<TableRow>
							{ property?.items?.properties?.map((prop, index) => (
								<TableCell key={index} align="center">{prop.name}</TableCell>
							))}
							</TableRow>
						</TableHead>
						<TableBody>
						{ property?.properties?.map((propId, index) => (
							<TableRow key={index} >
								{ properties[propId] && properties[propId].properties && properties[propId].properties.map((colpropId, index2) => (
									<TableCell key={index2} align="right">
										<PropertyView 
											rootparentType={rootparentType}
											rootparentId={rootparentId} 
											propertyId={colpropId}
											designTime={designTime}
											disabled={disabled}
											slimStyle={true}
										/>	
									</TableCell>
								))}
							</TableRow>
						))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			
		</Grid>
	)
}

export default PropertyTable
