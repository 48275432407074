import React, { useState } from 'react'
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Checkbox, Typography, CardMedia  } from '@mui/material'
import { List, ListItem, ListItemText, Select, MenuItem, Tooltip, Form, Divider, FormControl, Icon  } from '@mui/material'
import * as i18 from 'i18next'

import TabBar from '../TabBar'


export const openInNewTab = (url) => {
	if (url) {
		var win = window.open(url, '_blank')
		win.focus()
	}
}

const WorkflowAdvDetailsDialog = ({ navigation, workflow, versions, designTime, setDetailsDialogIsOpen, updateWorkflow, 
									retrieveAutomation,  createAutomation, retrieveVersions, restoreVersion }) => {

	const [optimize, setOptimize] = useState(workflow.optimizeForHVI)
	const [tab, setTab] = useState('workflow_advanced_details')
	const [versionsRetrieved, setVersionsRetrieved] = useState()
	const [hover, setHover] = useState()

	const automationInput = {
		eventId: "205df345-7d76-408e-9047-2b124313218e",
		actionId: "a6aaaa20-b75a-465b-9b69-8430b11028b6",
		actionData: {workflowId: workflow.workflowId},
	}

	if (tab === 'workflow_advanced_versions' && !versionsRetrieved) {
		retrieveVersions('workflow', workflow.workflowId)
		setVersionsRetrieved(true)
	}
	
	if (versions) {
		versions = versions.sort((a, b) => a.updated > b.updated ? -1 : 1)
	}

	return (
		<Dialog open={true} fullWidth >
			<DialogContent>
				<TabBar tabValues={['workflow_advanced_details','workflow_advanced_versions']} tab={tab} setTab={setTab} />	

				{tab === 'workflow_advanced_details' ?
					<Grid container direction={'column'} >

						<br/>

						<TextField
							variant='standard'
							InputLabelProps={{shrink: true}}				
							InputProps={{readOnly: true}}										
							label={i18.t('properties_id')}
							value={workflow.workflowId?workflow.workflowId:''}
						/>

						<br/>

						<Grid container direction='row' alignItems='center' justifyContent='space-between'>

							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_created')}
								value={workflow.created ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(workflow.created)) : ''}
							/>

							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_created_by')}
								value={workflow.createdBy?workflow.createdBy:''}
							/>

						</Grid>

						<br/>

						<Grid container direction='row' alignItems='center' justifyContent='space-between'>

							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_updated')}
								value={workflow.updated ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(workflow.updated)) : ''}
							/>
							
							<TextField
								variant='standard'
								InputLabelProps={{shrink: true}}				
								InputProps={{readOnly: true}}										
								label={i18.t('properties_updated_by')}
								value={workflow.updatedBy?workflow.updatedBy:''}
							/>

						</Grid>
						
						<br/>

						<Grid container direction='row' alignItems='center'>
							<Checkbox
								disabled={!designTime}
								checked={workflow.optimizeForHVI ? workflow.optimizeForHVI : false} 
								onChange={(event)=>{workflow.optimizeForHVI=event.target.checked; updateWorkflow(workflow); setOptimize(event.target.checked)}}
							/>
							<Typography>{i18.t('workflow_optimizeForHVI')}</Typography>
						</Grid>
						
						<Grid item>
							<Button size="small" color="primary" 
								disabled={!designTime}
								onClick={()=>{
									createAutomation(automationInput, (automationId)=>{
										retrieveAutomation(automationId)
										navigation.reset('automation_list')
										navigation.navigate('automation', {automationId: automationId})
									})
								}} 
							>
								<Tooltip title={i18.t('workflow_tooltip_create_automation')} >
									<div>{i18.t('workflow_create_automation')}</div>
								</Tooltip>
							</Button>
						</Grid>

					</Grid>
				: null }

				{tab === 'workflow_advanced_versions' && versions ?
					<Grid item>
						<br/>
						{ versions.map((version, index) => (
							<Grid 
								item key={index} 
								onMouseMove={() => setHover(index)}
								onMouseEnter={() => setHover(index)}
								onMouseLeave={() => setHover(null)}
							>
								<Grid container direction='row' alignItems='center' justifyContent='space-between' style={{backgroundColor: hover === index ? 'ghostwhite' : null}} >
									<Typography>{`${version.updated ? new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(Date.parse(version.updated)) : ''} (${version.size.toLocaleString()} bytes)`}</Typography>
									{ hover === index && ['draft','template'].includes(workflow.state) ?
										<Button 
											style={{padding: 0}}
											onClick={()=>{
												restoreVersion('workflow', workflow.workflowId, version.key, version.versionId)
												// TBD - adding spinning wheel next to restore button to show activity when restoring
											}}
										>
											{i18.t('workflow_advanced_restore_version')}
										</Button>
									: null }
								</Grid>
								<Divider/>
							</Grid>
						))}
					</Grid>
				: null}
				
			</DialogContent>
			<DialogActions>
				<Button onClick={()=>setDetailsDialogIsOpen(false)} color="primary" variant="contained" >
					{i18.t('dialog_close')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default WorkflowAdvDetailsDialog