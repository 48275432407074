import React from 'react'
import { Grid, TextField } from '@mui/material'
import * as i18 from 'i18next'
import { red } from '@mui/material/colors'


const validProperty = (property, value) => {
	try {
		if (property.pattern && value) {
			return (new RegExp(property.pattern, 'g')).test(value)
		} else if (property.subType === 'json') {
			try {
				JSON.parse(value)
				return true
			} catch (err) {
				return false
			}
		}
	} catch (err) {
		console.log(err)
		return false
	}
	
	return true
}

// Runtime view of component
const PropertyTextSingle = ({ nameTypeComponent, property, updateProperty, designTime, disabled }) => {

//	console.log('PropertyTextSingle')
	
	if (!property) return null
	
	let value = designTime ?  property.default : property.value

	let style
	if (value && value.length > 0) {
		if (!validProperty(property, value)) {
			style={backgroundColor: red[50]}
		}
	}
	
	let placeholder
	if (designTime) 
		placeholder = i18.t(`property_${property.subType}_designtime_placeholder`)
	else
		placeholder = i18.t(`property_${property.subType}_runtime_placeholder`)
	
	return (
		<Grid container direction={'row'} alignItems={designTime ? 'flex-start' : 'center'} >

			{/* Property name / type */}
			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>

			{/* Property value */}
			<TextField
				variant='standard'
				style={{...style, flex: 1}}
				placeholder={placeholder}
				value={value ? value : ''}
				onChange={(event)=>{
					designTime ?
						property.default = event.target.value
					:
						property.value = event.target.value
					updateProperty(property)
				}}
				disabled={disabled}
			/>
		</Grid>
	)
}

export default (PropertyTextSingle)
