import React from 'react'
import { Grid, Tooltip, Button, List, ListSubheader, ListItem, Typography } from '@mui/material'
import { Save, Refresh } from '@mui/icons-material'
import * as i18 from 'i18next'

import Setting from '../components/Setting'


const OrgSettings = ({ org, documents, save, retrieve, update }) => {
 
//	console.log('OrgSettings')
	
	if (!org) return null

	let groups=[], flags=[]
	for(let i=0; i<org?.settings?.length; i++) {
		if (flags[org.settings[i].group]) continue
		flags[org.settings[i].group] = true;
		groups.push(org.settings[i].group)
	}
	
	return (
		<Grid item >

			<Grid container direction={'row'}>
				<Tooltip title={i18.t('org_save_tooltip')} >
					<Button color="primary" onClick={save} ><Save/>{i18.t('org_save')}</Button>
				</Tooltip>
					
				<div style={{flex: 1}} />					

				<Tooltip title={i18.t('general_refresh_list_tooltip')} >
					<Button color="primary" onClick={retrieve} ><Refresh/></Button>
				</Tooltip>
			</Grid>

			<Grid item style={{width: '82%', height: 'calc(100% - 100px)', position: 'absolute', overflow: 'auto'}} >	
				<List subheader={<li />}>
					{groups.map((group, index) => (
						<li key={index} >
						<ul>
							<ListSubheader><Typography variant='h6'>{i18.t(group)}</Typography></ListSubheader>
							{org.settings.filter(setting1=>setting1.group===group).map((setting2, index) => (
								<ListItem key={index}>
									<Setting 
										setting={org.settings[org.settings.findIndex(setting3 => setting3.name === setting2.name)]} 
										orgType={org.type} 
										documents={documents}
										update={()=>update(org)} 
									/>
								</ListItem>
							))}
						</ul>
						</li>
					))}
				</List>					
			</Grid>
			
		</Grid>
	)
}

export default OrgSettings
