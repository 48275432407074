import React, { useState } from 'react'
import MilestoneActionListItem from '../components/MilestoneActionListItem'
import { Grid, Typography, Divider } from '@mui/material'
import { Droppable, Draggable  } from 'react-beautiful-dnd'
import * as i18 from 'i18next'


const MilestoneActionList = ({ me, workflowId, task, attachments, allProperties, documents, designTime, updateTask }) => {

//	console.log('MilestoneActionList')

	const [focus, setFocus] = useState()

	return (
		<Grid item style={{flex: 1}} id='ActionList' >		
		
			{(!task.milestoneActions || task.milestoneActions.length===0)?
				<Typography>{i18.t('task_no_actions_to_display')}</Typography>
			: null }

			{ designTime && task.milestoneActions ?
				<Droppable isDropDisabled={!designTime} droppableId={`actiondrop::${task.taskId}`} type={`milestoneaction`} >
					{(provided, snapshot) => (
						<div ref={provided.innerRef} >					
							{task.milestoneActions.map((maction, index) => 
								<Draggable isDragDisabled={!designTime} key={`actiondrag::${task.taskId}::${maction.actionId}`} draggableId={`actiondrag::${task.taskId}::${maction.actionId}`} index={index} >
									{(provided, snapshot) => (
										<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
											<Grid item style={{paddingTop: 5, paddingBottom: 5}} >
												<MilestoneActionListItem
													me={me}
													workflowId={workflowId}
													key={index}
													task={task}
													attachments={attachments}
													allProperties={allProperties}
													documents={documents}
													milestoneActionIndex={index}
													focus={focus}
													designTime={designTime}
													updateTask={updateTask}
													setFocus={setFocus}
												/>
											</Grid>
										</div>
									)}
								</Draggable>
							)}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			: null }
			
		</Grid>
	)
}

export default MilestoneActionList