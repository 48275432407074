import { RECEIVE_WORKFLOWS, RECEIVE_WORKFLOW } from './actiontypes'


// TaskInstance Functions



const initialState = {}

const taskinstances = (state=initialState, action) => {
	
	switch (action.type) {

// Workflow Eve

		case RECEIVE_WORKFLOW:
		case RECEIVE_WORKFLOWS:
			state = {...state, ...action.taskinstances}		// TBD - should this be a merge?
			break


// Task Instance Events

		default:
			break
			
	}
	
	return state
}

export default taskinstances