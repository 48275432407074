import { useState } from 'react'
import TaskList from '../components/TaskList'
import TaskView from '../containers/TaskView'
import { Grid, Typography } from '@mui/material'
import * as i18 from 'i18next'


/* Max height of list equal to VH minus combined height of AppBar */	
//const listHeight = 'calc(100vh - 135px)'

const WorkflowAuthored = ({ workflow, tasks, assignments, addTask, designTime }) => {
 
//	console.log('WorkflowAuthored')
	
	if (!workflow || !workflow.tasks || workflow.tasks.length === 0)
		return null

	let workflowId = workflow.workflowId
	
	let startTask = tasks[workflow.tasks[0]]
	let finishTask = tasks[workflow.tasks[1]]

	const [focusTaskId, setFocusTaskId] = useState(startTask && startTask.tasks ? startTask.tasks[0] : null)

//	let launchAssignment = workflow.launchAssignmentId && assignments ? assignments[workflow.launchAssignmentId] : null
	let launchAssignment = assignments[workflow.workflowId]

	return (						
		<Grid item style={{paddingRight: 5}} >
			
			{/* If there are any validation errors - display them here. */}
			{(workflow.validationErrors && workflow.validationErrors.length > 0) ?
				workflow.validationErrors.map((err, index) => {
					if (err.warning)
						return <Typography key={index} style={{color: 'orange'}} >{i18.t(err.warning, {data: err.data})}</Typography>
												
					if (err.error)
						return <Typography key={index} style={{color: 'red'}} >{i18.t(err.error, {data: err.data})}</Typography>
				})
			: null }

			{/* Start Task */}
			<TaskView
				workflowId={workflowId}
				taskId={startTask ? startTask.taskId : null}
				focusTaskId={focusTaskId}
				designTime={designTime}
				setFocusTaskId={setFocusTaskId}
			/>

			{/* Main flow */}
			<Grid item style={{marginLeft: 20}}>
				<TaskList
					workflowId={workflowId}
					task={startTask}
					focusTaskId={focusTaskId}
					setFocusTaskId={setFocusTaskId}
					addTask={addTask}
					designTime={designTime}
				/>
			</Grid>
					
			{/* Finish Task */}
			<TaskView
				workflowId={workflowId}
				taskId={finishTask ? finishTask.taskId : null}
				focusTaskId={focusTaskId}
				designTime={designTime}
				setFocusTaskId={setFocusTaskId}
			/>

		</Grid>				
	)
}

export default WorkflowAuthored
