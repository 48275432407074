import workflow_simple from './workflow_simple.json'
import workflow_base from './workflow_base.json'
import testfragments from './testfragments'


const stringify = (str) => {
	return JSON.stringify(str)?.replace(/"(\w+)"\s*:/g, '$1:')
}


const assignmentdetails = [{
	name: "Assignment details test collection",
	tests: [

		{
			id: 'AD-0001',
			name: 'create base workflow.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(workflow_simple)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'AD-0001a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {state, taskinstances {taskinstances {assignments {assignmentId}}}}}`, 
				assertResultIncludes: [`"state":"working"`],
				onEachItem: [{
					id: 'AD-0001a1',
					name: 'complete assignment.',
					type: 'positive',
					operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>" }) {state}}`,
					assertResultIncludes: [`"state":"completed"`],
				}]
			}]
		},

		{
			id: 'AD-0002',
			name: 'create base workflow.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(workflow_base)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [
				{
					id: 'AD-0002a',
					name: 'run workflow', 				
					type: 'setup',
					operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {workflowId state taskinstances {taskinstances {assignments {assignmentId}}}}}`, 
					assertResultIncludes: [`"state":"working"`],
					onEachItem: [

						{
							id: 'AD-0002a1',
							name: 'textSingleInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.textSingleInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_maxlength`],
						},
					
						{
							id: 'AD-0002a2',
							name: 'textSingleValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.textSingleValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"string":"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"`],
						},

						{
							id: 'AD-0002a3',
							name: 'textMultiInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.textMultiInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_maxlength`],
						},
						{
							id: 'AD-0002a4',
							name: 'textMultiValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.textMultiValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"string_multi":"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"`],
						},

						{
							id: 'AD-0002a5',
							name: 'dropdownSingleInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.dropdownSingleInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_enum`],
						},
						{
							id: 'AD-0002a6',
							name: 'dropdownSingleValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.dropdownSingleValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"dropdown_single":"Option 1"`],
						},

						{
							id: 'AD-0002a7',
							name: 'dropdownMultiInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.dropdownMultiInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_enum`],
						},
						{
							id: 'AD-0002a8',
							name: 'dropdownMultiValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.dropdownMultiValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"dropdown_multi":["Option 1","Option 2"]`],
						},

						{
							id: 'AD-0002a9',
							name: 'listSingleInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.listSingleInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_enum`,`validation_error_type`],
						},
						{
							id: 'AD-0002a10',
							name: 'listSingleValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.listSingleValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"list_single":"Option 1"`],
						},

						{
							id: 'AD-0002a11',
							name: 'listMultiInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.listMultiInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_enum`],
						},
						{
							id: 'AD-0002a12',
							name: 'listMultiValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.listMultiValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"list_multi":["Option 1","Option 2"]`],
						},

						{
							id: 'AD-0002a13',
							name: 'objectInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.objectInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_maxlength`],
						},
						{
							id: 'AD-0002a14',
							name: 'objectValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.objectValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"object":{"text1a":"a","text1b":"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"}`],
						},

//						{
//							id: 'AD-0002a15',
//							name: 'dateInvalid update assignment.',
//							type: 'negative',
//							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.dateInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
//							assertResultIncludes: [`aaa`],
//						},
//						{
//							id: 'AD-0002a16',
//							name: 'dateValid update assignment.',
//							type: 'positive',
//							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.dateValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
//							assertResultIncludes: [`aaa`],
//						},

						{
							id: 'AD-0002a17',
							name: 'numberInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.numberInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_type`,`validation_error_pattern`],
						},
						{
							id: 'AD-0002a18',
							name: 'numberValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.numberValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"number":0`],
						},

//						{
//							id: 'AD-0002a19',
//							name: 'memberGroupInvalid update assignment.',
//							type: 'negative',
//							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.memberGroupInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
//							assertResultIncludes: [`validation_error_property_schema`,`does not match pattern`],
//						},
//						{
//							id: 'AD-0002a20',
//							name: 'memberGroupValid update assignment.',
//							type: 'positive',
//							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.memberGroupValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
//							assertResultIncludes: [`"member_group":["michael_winter@yahoo.com"]`],
//						},

						{
							id: 'AD-0002a21',
							name: 'telephoneInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.telephoneInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_pattern`],
						},
						{
							id: 'AD-0002a22',
							name: 'telephoneValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.telephoneValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"telephone":"(555) 555-5555"`],
						},

						{
							id: 'AD-0002a23',
							name: 'stateInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.stateInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_pattern`],
						},
						{
							id: 'AD-0002a24',
							name: 'stateValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.stateValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"state":"CA"`],
						},

						{
							id: 'AD-0002a25',
							name: 'zipcodeInvalid update assignment.',
							type: 'negative',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.zipcodeInvalid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`validation_error_pattern`],
						},
						{
							id: 'AD-0002a26',
							name: 'zipcodeValid update assignment.',
							type: 'positive',
							operation: `mutation {updateAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.zipcodeValid)}}) {instanceData ${testfragments.propertyFragment}}}`,
							assertResultIncludes: [`"zipcode":"93012"`],
						},

						{
							id: 'AD-CLEANUP',
							name: 'cancel workflow.',
							operation: `mutation {cancelWorkflow (workflowId: "<<workflowId>>") {workflowId, state}}`,
							assertResultIncludes: [`"state":"canceled"`],
						}

					]
				}
			]
		},

		{
			id: 'AD-0003',
			name: 'create and process base workflow',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(workflow_base)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [{
				id: 'AD-0003a',
				name: 'run workflow', 				
				type: 'setup',
				operation: `mutation {runWorkflow (input: {workflowId: "<<workflowId>>"}) {state taskinstances {taskinstances {assignments {assignmentId}}}}}`, 
				assertResultIncludes: [`"state":"working"`],
				onEachItem: [
					{
						id: 'AD-0003a1',
						name: 'textSingleInvalid complete assignment.',
						type: 'negative',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.textSingleInvalid)}}) {state instanceData ${testfragments.propertyFragment}}}`,
						assertResultIncludes: [`validation_error_maxlength`],
					},
					{
						id: 'AD-0003a2',
						name: 'textSingleValid complete assignment.',
						type: 'positive',
						operation: `mutation {completeAssignment (input: {assignmentId: "<<taskinstances[0].taskinstances[0].assignments[0].assignmentId>>", instanceData: ${stringify(testfragments.textSingleValid)}}) {state instanceData ${testfragments.propertyFragment}}}`,
						assertResultIncludes: [`"state":"completed"`,`"instanceData":{"string":"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"}`],
					},
				]
			}]
		},

	],
}]

export default assignmentdetails


