import React from 'react'
import { Grid, FormControl, Select, MenuItem } from '@mui/material'


// Runtime view of component
const PropertyDropdownSingle = ({ nameTypeComponent, property, updateProperty, designTime, disabled }) => {

	let def = property.default ? property.default : ''
	let value = property.value ? property.value : ''
	
	return (
		<Grid container direction={'row'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
	
			<Grid item style={{paddingLeft: 10}} >
				<FormControl>
					<Select
						variant='standard'
						value={designTime ? def : value}
						onChange={(event)=>{
							designTime ?
								property.default = event.target.value
							:
								property.value = event.target.value
							updateProperty(property)
						}}
						disabled={disabled}
					>
						{property.enum && property.enum.map((item, index) =>
							<MenuItem key={index} value={item}>{item}</MenuItem>
						)}				
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	)
}

export default (PropertyDropdownSingle)