import { RECEIVE_VERSIONS, RECEIVE_ERRORS } from './actiontypes'
import { versionFragment } from '../reducers/graphql'
import { gqlExec } from '../reducers/graphql'
import { retrieveWorkflow } from '../reducers/workflows'
import { retrieveSupportCase } from '../reducers/supportcases'
import { retrieveExtract } from '../reducers/extracts'


export const retrieveVersions = (objectType, objectId) => async dispatch => {
	
//	console.log('retrieveVersions');

	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`query {versions (objectType: "${objectType}", objectId: "${objectId}") {...versionFields}} ${versionFragment}`)

		let versions = response.versions.map(version => ({...version, objectType: objectType}))

		if (versions) {
			dispatch({type: RECEIVE_VERSIONS, objectId: objectId, versions: versions})
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const restoreVersion = (objectType, objectId, key, versionId) => async dispatch => {
	
//	console.log('retrieveVersions');

	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`mutation {restoreVersion (objectType: "${objectType}", objectId: "${objectId}", key: "${key}", versionId: "${versionId}")}`)

		if (response.restoreVersion) {
			if (objectType === 'workflow') {
				await dispatch(retrieveWorkflow(objectId))
			} else if (objectType === 'supportcase') {
				await dispatch(retrieveSupportCase(objectId))
			} else if (objectType === 'extract') {
				await dispatch(retrieveExtract(objectId))
			}
			await dispatch(retrieveVersions(objectType, objectId))
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}




const initialState = {
}

const actions = (state = initialState, action) => {
	switch (action.type) {
		
// Version Actions

		case RECEIVE_VERSIONS:
			state = {...state, 
				[action.objectId]: action.versions
			}
			break

		default:
			break
	}

	return state;
}

export default actions