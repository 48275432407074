import React, { useState } from 'react'
import { Grid, TextField, Button, Typography, Tooltip, Accordion, AccordionSummary, AccordionDetails, Divider, Icon } from '@mui/material'
import { AspectRatio, AspectRatioTwoTone, ContentCopy, ExpandMore, ExpandLess } from '@mui/icons-material'
import * as i18 from 'i18next'
import UUID from 'uuidjs'
import cloneDeep from 'lodash.clonedeep'

import ExtractionRuleDetail from '../../components/ExtractionRuleDetail'


/*
const validateName = (value) => {
	if (!value || value.length === 0)
		return true
	
	return /^[A-Za-z_][0-9A-Za-z_ ]*$/.test(value)
}
*/

const TableExtractionRule = ({ property, properties, selectedProperty, highlightedRule, hover, orgProperties, avgwordheight, designTime, 
								setSelectedProperty, setSelectionAttribute, setUpdateOverlays, updateProperty,
								absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log("TableExtractionRule", property.name)

	if (!property || !property.extractionRule) return null

	let propertyId = property.propertyId

	const [columnCount, setColumnCount] = useState(property.items.properties ? property.items.properties.length : 0)
	const [updated, setUpdated] = useState()

	let subproperties = property.items.properties ? property.items.properties : []

	const [expanded, setExpanded] = useState(false)
	const [showSizing, setShowSizing] = useState()
	const [refCopied, setRefCopied] = useState()

	const resetTableColumnProps = () => {

		let tableRect = property.extractionRule.value ? property.extractionRule.value.rect : null
		let numParentProperties = (property.items.properties ? property.items.properties.length : 0)
		let colwidth = tableRect ? tableRect.width / numParentProperties : 0
		if (tableRect) {
			property.items.properties.map((prop, index) => {
				if (!prop.extractionRule.key) {prop.extractionRule.key = {}}
				prop.extractionRule.key.rect = {
					top: tableRect.top,
					left: tableRect.left + (colwidth * index),
					width: colwidth,
					height: avgwordheight,
				}

				if (!prop.extractionRule.value) {prop.extractionRule.value = {}}
				prop.extractionRule.value.rect = {
					top: prop.extractionRule.key.rect.top + prop.extractionRule.key.rect.height,
					left: tableRect.left + (colwidth * index),
					width: colwidth,
					height: avgwordheight,
				}

				prop.extractionRule.numIterations = 0
				if (tableRect?.height > 0 && prop?.extractionRule?.value?.rect?.height > 0) {
					let bodyHeight = tableRect.height - prop.extractionRule.key.rect.height
					prop.extractionRule.numIterations = Math.floor(bodyHeight / prop.extractionRule.value.rect.height)
				}
				
				updateProperty(property)
			})
		}
	}

	const addTableColumn = () => {
		if (!property.items.properties) property.items.properties=[]
		let keys = orgProperties ? Object.keys(orgProperties) : []
		for (const key of keys) {
			if (orgProperties[key].subType === 'string') {
				let stringProp = orgProperties[key]

				let newProp = cloneDeep(orgProperties[key])
				newProp.propertyId = UUID.genV4().toString()
				newProp.parentpropertyId = property.propertyId
				newProp.extractionRule = {
					type: 'tablecolumn',
					page: property.extractionRule.page
				}

				property.items.properties.push(newProp)
				resetTableColumnProps()
				setColumnCount(property.items.properties.length)
			}
		}
	}
	
	let propertyRefName = property.pname
	let parentpropertyId = property.parentpropertyId
	while (parentpropertyId) {
		propertyRefName = properties[parentpropertyId]?.pname + '.' + propertyRefName
		parentpropertyId = properties[parentpropertyId]?.parentpropertyId
	}

	const key = property.extractionRule.key
	const value = property.extractionRule.value

	return (		
		<Accordion 
			style={{boxShadow: 'none', backgroundColor: 'transparent'}} 
			expanded={expanded} 
			onClick={()=>{
				if (selectedProperty === propertyId) {
					setExpanded(!expanded)
				} else {
					setExpanded(true)
				}
			}} 
		>
			<AccordionSummary style={{padding: 0, backgroundColor: 'transparent'}} expandIcon={<Tooltip title={i18.t('property_expand_tooltip')}>{hover ? <ExpandMore/> : <Icon/>}</Tooltip>}>
				<Grid container direction='column'>
				
					<Grid container direction='row' alignItems='center' >
						{/* Property name */}
						<TextField
							variant='standard'
							placeholder={i18.t('property_name_placeholder')}
							value={property.name ? property.name : ''}
							onChange={(event)=>{
								property.name = event.target.value
								if (property.name) {
									property.pname = property.name.toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
								}
								updateProperty(property)
							}}
							disabled={!designTime}
							onClick={(e)=>{if (designTime) {setSelectedProperty(propertyId);setSelectionAttribute(null);e.stopPropagation()}}}
							required={true}
							InputProps={{readOnly: !designTime}}										
							style={{overflow: 'hidden', textOverflow: 'ellipsis'}}
						/>
					</Grid>
					
					<Grid container direction='row' alignItems='center' >

						{/* Key */}
						<Grid item xs={6} >
							<Grid style={{flexWrap: 'noWrap'}} container direction='row' alignItems='center' >
								<Button 
									size="small"
									style={{color: 'blue'}}
									onClick={(e)=>{
										if (designTime) {
											property.extractionRule.key = null
											updateProperty(property)
											setSelectionAttribute('key')
											setSelectedProperty(propertyId)
										}
										e.stopPropagation()
									}}
								>
									{key && key.rect ? i18.t('extraction_key_reset_label') : i18.t('extraction_key_select_label')}
								</Button>

								<Typography>{key && key.words ? key.words.join(' ') : i18.t('extraction_key_placeholder')}</Typography>
							</Grid>
						</Grid>
					
						{/* Table */}
						<Grid item xs={5} >
							<Button 
								size="small"
								style={{color: 'green'}}
								onClick={(e)=>{
									if (designTime) {
										property.extractionRule = {type: 'vtable', page: property.extractionRule.page}
										property.items.properties=[]
										setColumnCount(0)
										setSelectionAttribute('value')
										setSelectedProperty(propertyId)
										addTableColumn()
										updateProperty(property)
									}
									e.stopPropagation()
									setExpanded(true)
								}}
							>
								{property.extractionRule.value ? i18.t('extraction_table_reset_label') : i18.t('extraction_table_select_label')}
							</Button>
						</Grid>

					</Grid>				
				</Grid>
			</AccordionSummary>

			<AccordionDetails style={{padding: 0}}>
				<Grid container direction='column' >

					<Grid style={{paddingLeft: 10}} container direction='row' alignItems='flex-end' >

						<Grid item>
							<Grid container direction='column' >

								<Grid container direction='row' alignItems='center'>

									{/* Column counter */}
									<Typography>{i18.t('extraction_rule_column_count_label')}</Typography>
									<Button 
										style={{minWidth: 30}}
										disabled={property.items.properties && property.items.properties.length <= 1}
										onClick={(e) => {
											if (property.items.properties && property.items.properties.length > 1) {
												property.items.properties.splice(property.items.properties.length - 1, 1)
												resetTableColumnProps()
												setColumnCount(property.items.properties.length)							
											}
											e.stopPropagation()
										}}
									>
										-
									</Button> 
									<Typography>{columnCount}</Typography>
									<Button 
										style={{minWidth: 30}}
										disabled={property.items.properties && property.items.properties.length >= 20}
										onClick={(e) => {
											if (property.items.properties && property.items.properties.length < 20) {
												addTableColumn()
												updateProperty(property)
											}
											e.stopPropagation()
										}}
									>
										+
									</Button> 
									
								</Grid>
								
								{/* Property resource name (prn) */}
								<Grid container direction='row' >
									<Typography noWrap onClick={(e)=>e.stopPropagation()} >{propertyRefName}</Typography>
									<Tooltip title={refCopied ? i18.t('tooltip_prn_copied') : i18.t('tooltip_prn_copy')} >
										<ContentCopy fontSize="small" style={{marginLeft: 5}} onClick={(e)=>{e.stopPropagation();navigator.clipboard.writeText(propertyRefName);setRefCopied(true);setTimeout(()=>setRefCopied(false), 2000)}}/>
									</Tooltip>
								</Grid>
								
							</Grid>
						</Grid>

						<Grid item style={{paddingLeft: 10}} xs={4} md={5} xl={6}>
							<Grid container direction='column' >
							</Grid>
						</Grid>

						<Grid item style={{flex: 1}}/>

						{ showSizing ?
							<AspectRatioTwoTone onClick={(e)=>{e.stopPropagation();setShowSizing(false)}} style={{cursor: 'default'}} color='primary' />
						:
							<AspectRatio onClick={(e)=>{e.stopPropagation();setShowSizing(true)}} style={{cursor: 'default'}} color='primary' />
						}								
						
					</Grid>

					{ showSizing ?
						<Grid item style={{flex: 1}} >
							<Divider/>

							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >Key</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='key'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>

							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >Table</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='table'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>
					
							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >Header</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='header'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>
					
							<Grid container direction='row' alignItems='center' >
								<Grid item xs={2}>
									<Typography style={{color: 'grey'}} variant='body2' >First Row</Typography>
								</Grid>
								<Grid item xs={10}>
									<ExtractionRuleDetail
										type='row'
										property={property}
										updateProperty={()=>updateProperty(property)}
										setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
										absoluteToProportionalRect={absoluteToProportionalRect}
										proportionalToAbsoluteRect={proportionalToAbsoluteRect}
									/>
								</Grid>
							</Grid>

							<Divider/>
						</Grid>
					: null }

					<Grid item style={{marginLeft: 20, flex: 1}} onClick={(e)=>e.stopPropagation()} >
						{ subproperties.map((prop, index) =>
							<ExtractionRuleDetail
								key={index}
								type='column'
								property={prop}
								nextProperty={subproperties[index+1]}
								updateProperty={()=>updateProperty(property)}
								setUpdated={(now)=>{setUpdated(now);setUpdateOverlays(now);}}
								absoluteToProportionalRect={absoluteToProportionalRect}
								proportionalToAbsoluteRect={proportionalToAbsoluteRect}
							/>
						)}
					</Grid>
					
				</Grid>
			</AccordionDetails>
		</Accordion>
		
	)
}

export default TableExtractionRule