import React, { useState } from 'react'
import { Grid, TextField, Button, Typography, FormControl, FormLabel } from '@mui/material'
import * as i18 from 'i18next'

/*
const absoluteToProportionalRect = (doc, rect, zoom) => {

	if (!doc || !rect || !zoom) return {}

	let width = doc.imageMediaMetadata ? doc.imageMediaMetadata.width : 0
	let height = doc.imageMediaMetadata ? doc.imageMediaMetadata.height : 0

	let newRect = {
		top: (rect.top / zoom / height),
		left: (rect.left / zoom / width),
		height: (rect.height / zoom / height),
		width: (rect.width / zoom / width),
	}

	return newRect
}

const proportionalToAbsoluteRect = (doc, rect, zoom) => {

	if (!doc || !rect || !zoom) return {}

	let width = doc.imageMediaMetadata ? doc.imageMediaMetadata.width : 0
	let height = doc.imageMediaMetadata ? doc.imageMediaMetadata.height : 0

	let newRect = {
		top: (rect.top * height * zoom),
		left: (rect.left * width * zoom),
		height: (rect.height * height * zoom),
		width: (rect.width * width * zoom),
	}

	newRect.bottom = Math.round(newRect.top + newRect.height)
	newRect.right = Math.round(newRect.left + newRect.width)

	return newRect
}
*/

const roundToHalfDigitIncr = (num) => num ? ((Math.round((num * 10) / 5) * 5) / 10) : 0

const ExtractionRuleDetail = ({ type, property, nextProperty, updateProperty, setUpdated, absoluteToProportionalRect, proportionalToAbsoluteRect }) => {

//	console.log("ExtractionRuleDetail")

	if (!property || !property.extractionRule) return null

	let propertyId = property.propertyId

	let key = property.extractionRule.key
	let value = property.extractionRule.value

//	let absKeyRect = property.extractionRule.key ? proportionalToAbsoluteRect(doc, property.extractionRule.key.rect, zoom) : {}
//	let absValueRect = property.extractionRule.value ? proportionalToAbsoluteRect(doc, property.extractionRule.value.rect, zoom) : {}
	let absKeyRect = property.extractionRule.key ? proportionalToAbsoluteRect(property.extractionRule.key.rect) : {}
	let absValueRect = property.extractionRule.value ? proportionalToAbsoluteRect(property.extractionRule.value.rect) : {}

//	let absNextKeyRect = nextProperty ? proportionalToAbsoluteRect(doc, nextProperty?.extractionRule?.key?.rect, zoom) : {}
//	let absNextValueRect = nextProperty ? proportionalToAbsoluteRect(doc, nextProperty?.extractionRule?.value?.rect, zoom) : {}
	let absNextKeyRect = nextProperty ? proportionalToAbsoluteRect(nextProperty?.extractionRule?.key?.rect) : {}
	let absNextValueRect = nextProperty ? proportionalToAbsoluteRect(nextProperty?.extractionRule?.value?.rect) : {}

	let subproperties = property?.items?.properties ? property.items.properties : []
	let firstColProp = subproperties && subproperties.length > 0 ? subproperties[0] : null
//	let absFirstColKeyRect = firstColProp ? proportionalToAbsoluteRect(doc, firstColProp?.extractionRule?.key?.rect, zoom) : {}
//	let absFirstColValueRect = firstColProp ? proportionalToAbsoluteRect(doc, firstColProp?.extractionRule?.value?.rect, zoom) : {}
	let absFirstColKeyRect = firstColProp ? proportionalToAbsoluteRect(firstColProp?.extractionRule?.key?.rect) : {}
	let absFirstColValueRect = firstColProp ? proportionalToAbsoluteRect(firstColProp?.extractionRule?.value?.rect) : {}
	
	const decrementKeyTop = (e) => {
		if (absKeyRect.top > 1) {
			// Decrease the top of the key rect & increase the height an equivalent amount
			absKeyRect.top = (Math.round((absKeyRect.top * 10) / 5) * 5) / 10
			absKeyRect.top = absKeyRect.top - .5
			absKeyRect.height = absKeyRect.height + .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
			property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementKeyTop = (e) => {
		// Increase the top of the key rect & decrease the height an equivalent amount
		absKeyRect.top = (Math.round((absKeyRect.top * 10) / 5) * 5) / 10
		absKeyRect.top = absKeyRect.top + .5
		absKeyRect.height = absKeyRect.height - .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.key) property.extractionRule.key = {}
//		property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
		property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementKeyLeft = (e) => {
		if (absKeyRect.left > 1) {
			// Reduce the left of the key rect & increase the width an equivalent amount
			absKeyRect.left = (Math.round((absKeyRect.left * 10) / 5) * 5) / 10
			absKeyRect.left = absKeyRect.left - .5
			absKeyRect.width = absKeyRect.width + .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
			property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementKeyLeft = (e) => {
		// Increase the left of the key rect & decrease the width an equivalent amount
		absKeyRect.left = (Math.round((absKeyRect.left * 10) / 5) * 5) / 10
		absKeyRect.left = absKeyRect.left + .5
		absKeyRect.width = absKeyRect.width - .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.key) property.extractionRule.key = {}
//		property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
		property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementKeyWidth = (e) => {
		if (absKeyRect.width > 1) {
			// Reduce the width of the key rect
			absKeyRect.width = (Math.round((absKeyRect.width * 10) / 5) * 5) / 10
			absKeyRect.width = absKeyRect.width - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
			property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementKeyWidth = (e) => {
		// Increase the width of the key rect
		absKeyRect.width = (Math.round((absKeyRect.width * 10) / 5) * 5) / 10
		absKeyRect.width = absKeyRect.width + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.key) property.extractionRule.key = {}
//		property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
		property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementKeyHeight = (e) => {
		if (absKeyRect.height > 1) {
			// Reduce the height of the key rect
			absKeyRect.height = (Math.round((absKeyRect.height * 10) / 5) * 5) / 10
			absKeyRect.height = absKeyRect.height - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
			property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementKeyHeight = (e) => {
		// Increase the height of the key rect
		absKeyRect.height = (Math.round((absKeyRect.height * 10) / 5) * 5) / 10
		absKeyRect.height = absKeyRect.height + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.key) property.extractionRule.key = {}
//		property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
		property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementRegionTop = (e) => {
		if (absValueRect.top > 1) {
			// Decrease the top of the value rect & increase the height an equivalent amount
			absValueRect.top = (Math.round((absValueRect.top * 10) / 5) * 5) / 10
			absValueRect.top = absValueRect.top - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementRegionTop = (e) => {
		// Increase the top of the value rect & decrease the height an equivalent amount
		absValueRect.top = (Math.round((absValueRect.top * 10) / 5) * 5) / 10
		absValueRect.top = absValueRect.top + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementRegionLeft = (e) => {
		if (absValueRect.left > 1) {
			// Reduce the left of the value rect & increase the width an equivalent amount
			absValueRect.left = (Math.round((absValueRect.left * 10) / 5) * 5) / 10
			absValueRect.left = absValueRect.left - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementRegionLeft = (e) => {
		// Increase the left of the value rect & decrease the width an equivalent amount
		absValueRect.left = (Math.round((absValueRect.left * 10) / 5) * 5) / 10
		absValueRect.left = absValueRect.left + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementRegionWidth = (e) => {
		if (absValueRect.width > 1) {
			// Reduce the width of the value rect
			absValueRect.width = (Math.round((absValueRect.width * 10) / 5) * 5) / 10
			absValueRect.width = absValueRect.width - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementRegionWidth = (e) => {
		// Increase the width of the value rect
		absValueRect.width = (Math.round((absValueRect.width * 10) / 5) * 5) / 10
		absValueRect.width = absValueRect.width + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementRegionHeight = (e) => {
		if (absValueRect.height > 1) {
			// Reduce the height of the value rect
			absValueRect.height = (Math.round((absValueRect.height * 10) / 5) * 5) / 10
			absValueRect.height = absValueRect.height - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementRegionHeight = (e) => {
		// Increase the height of the value rect
		absValueRect.height = (Math.round((absValueRect.height * 10) / 5) * 5) / 10
		absValueRect.height = absValueRect.height + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementTableTop = (e) => {
		if (absValueRect.top > 1) {
			// Decrease the top of the value rect & increase the height an equivalent amount
			absValueRect.top = (Math.round((absValueRect.top * 10) / 5) * 5) / 10
			absValueRect.top = absValueRect.top - .5
			absValueRect.height = absValueRect.height + .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementTableTop = (e) => {
		// Increase the top of the value rect & decrease the height an equivalent amount
		absValueRect.top = (Math.round((absValueRect.top * 10) / 5) * 5) / 10
		absValueRect.top = absValueRect.top + .5
		absValueRect.height = absValueRect.height - .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementTableLeft = (e) => {
		if (absValueRect.left > 1) {
			// Reduce the left of the value rect & increase the width an equivalent amount
			absValueRect.left = (Math.round((absValueRect.left * 10) / 5) * 5) / 10
			absValueRect.left = absValueRect.left - .5
			absValueRect.width = absValueRect.width + .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementTableLeft = (e) => {
		// Increase the left of the value rect & decrease the width an equivalent amount
		absValueRect.left = (Math.round((absValueRect.left * 10) / 5) * 5) / 10
		absValueRect.left = absValueRect.left + .5
		absValueRect.width = absValueRect.width - .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementTableWidth = (e) => {
		if (absValueRect.width > 1) {
			// Reduce the width of the value rect
			absValueRect.width = (Math.round((absValueRect.width * 10) / 5) * 5) / 10
			absValueRect.width = absValueRect.width - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementTableWidth = (e) => {
		// Increase the width of the value rect
		absValueRect.width = (Math.round((absValueRect.width * 10) / 5) * 5) / 10
		absValueRect.width = absValueRect.width + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementTableHeight = (e) => {
		if (absValueRect.height > 1) {
			// Reduce the height of the value rect
			absValueRect.height = (Math.round((absValueRect.height * 10) / 5) * 5) / 10
			absValueRect.height = absValueRect.height - .5
				
			// Convert the absolute rects back to proportional
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementTableHeight = (e) => {
		// Increase the height of the value rect
		absValueRect.height = (Math.round((absValueRect.height * 10) / 5) * 5) / 10
		absValueRect.height = absValueRect.height + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
		property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementHeaderHeight = (e) => {
		if (absFirstColKeyRect && absFirstColKeyRect.height > 1) {
			// Reduce the height of the key rect
			absFirstColKeyRect.height = (Math.round((absFirstColKeyRect.height * 10) / 5) * 5) / 10
			absFirstColKeyRect.height = absFirstColKeyRect.height - .5
			absFirstColValueRect.top = absFirstColValueRect.top - .5

			// Convert the absolute rects back to proportional
//			firstColProp.extractionRule.key.rect = absoluteToProportionalRect(doc, absFirstColKeyRect, zoom)
//			firstColProp.extractionRule.value.rect = absoluteToProportionalRect(doc, absFirstColValueRect, zoom)
			firstColProp.extractionRule.key.rect = absoluteToProportionalRect(absFirstColKeyRect)
			firstColProp.extractionRule.value.rect = absoluteToProportionalRect(absFirstColValueRect)
			
			for (const prop of subproperties) {
				prop.extractionRule.key.rect.height = firstColProp.extractionRule.key.rect.height
				prop.extractionRule.value.rect.top = firstColProp.extractionRule.value.rect.top
			}
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementHeaderHeight = (e) => {
		// Increase the height of the key rect
		absFirstColKeyRect.height = (Math.round((absFirstColKeyRect.height * 10) / 5) * 5) / 10
		absFirstColKeyRect.height = absFirstColKeyRect.height + .5
		absFirstColValueRect.top = absFirstColValueRect.top + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.key) property.extractionRule.key = {}
//		firstColProp.extractionRule.key.rect = absoluteToProportionalRect(doc, absFirstColKeyRect, zoom)
		firstColProp.extractionRule.key.rect = absoluteToProportionalRect(absFirstColKeyRect)
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		firstColProp.extractionRule.value.rect = absoluteToProportionalRect(doc, absFirstColValueRect, zoom)
		firstColProp.extractionRule.value.rect = absoluteToProportionalRect(absFirstColValueRect)
		
		for (const prop of subproperties) {
			prop.extractionRule.key.rect.height = firstColProp.extractionRule.key.rect.height
			prop.extractionRule.value.rect.top = firstColProp.extractionRule.value.rect.top
		}
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementRowHeight = (e) => {
		if (absFirstColValueRect && absFirstColValueRect.height > 1) {
			// Reduce the height of the value rect
			absFirstColValueRect.height = roundToHalfDigitIncr(absFirstColValueRect.height)
			absFirstColValueRect.height = absFirstColValueRect.height - .5
				
			// Convert the absolute rects back to proportional
//			firstColProp.extractionRule.value.rect = absoluteToProportionalRect(doc, absFirstColValueRect, zoom)
			firstColProp.extractionRule.value.rect = absoluteToProportionalRect(absFirstColValueRect)
			
			// Make the heigh equivalent across all columns
			for (const prop of subproperties) {
				prop.extractionRule.value.rect.height = firstColProp.extractionRule.value.rect.height
			}
				
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}

	const incrementRowHeight = (e) => {
		// Increase the height of the value rect
		absFirstColValueRect.height = roundToHalfDigitIncr(absFirstColValueRect.height)
		absFirstColValueRect.height = absFirstColValueRect.height + .5
			
		// Convert the absolute rects back to proportional
		if (!property.extractionRule.value) property.extractionRule.value = {}
//		firstColProp.extractionRule.value.rect = absoluteToProportionalRect(doc, absFirstColValueRect, zoom)
		firstColProp.extractionRule.value.rect = absoluteToProportionalRect(absFirstColValueRect)
		
		// Make the heigh equivalent across all columns
		for (const prop of subproperties) {
			prop.extractionRule.value.rect.height = firstColProp.extractionRule.value.rect.height
		}
			
		setUpdated(Date.now())
		e.stopPropagation()
	}

	const decrementColWidth = (e) => {
		if (nextProperty && absNextValueRect.width > 1) {
			// Reduce the width of the key/value rects for this property
			absValueRect.width = roundToHalfDigitIncr(absValueRect.width)
			absValueRect.width = absValueRect.width - .5
			if (property.extractionRule.key) {
				absKeyRect.width = absValueRect.width
			}
			
			// Reduce the left attribute of the key/value rects for the next property
			absNextValueRect.left = absNextValueRect.left - .5
			if (nextProperty.extractionRule.key) {
				absNextKeyRect.left = absNextValueRect.left
			}
			
			// Increase the width attribute of the key/value rects for the next property
			absNextValueRect.width = absNextValueRect.width + .5
			if (nextProperty.extractionRule.key) {
				absNextKeyRect.width = absNextValueRect.width
			}
			
			// Convert the absolute rects back to proportional for both key/value rects for both this property and the next property
			if (property.extractionRule.key) {
//				property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
				property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
			}

//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRect)
			if (nextProperty.extractionRule.key) {
//				nextProperty.extractionRule.key.rect = absoluteToProportionalRect(doc, absNextKeyRect, zoom)
				nextProperty.extractionRule.key.rect = absoluteToProportionalRect(absNextKeyRect)
			}
//			nextProperty.extractionRule.value.rect = absoluteToProportionalRect(doc, absNextValueRect, zoom)
			nextProperty.extractionRule.value.rect = absoluteToProportionalRect(absNextValueRect)
			
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}
	
	const incrementColWidth = (e) => {
		if (nextProperty && absNextValueRect.width > 1) {
			// Reduce the width of the key/value rects for this property
			absValueRect.width = absValueRect.width + .5
			if (property.extractionRule.key) {
				absKeyRect.width = absValueRect.width
			}
			
			// Reduce the left attribute of the key/value rects for the next property
			absNextValueRect.left = absNextValueRect.left + .5
			if (nextProperty.extractionRule.key) {
				absNextKeyRect.left = absNextValueRect.left
			}
			
			// Increase the width attribute of the key/value rects for the next property
			absNextKeyRect.width = absNextKeyRect.width - .5
			if (nextProperty.extractionRule.key) {
				absNextValueRect.width = absNextKeyRect.width
			}
			
			// Convert the absolute rects back to proportional for both key/value rects for both this property and the next property
			if (property.extractionRule.key) {
//				property.extractionRule.key.rect = absoluteToProportionalRect(doc, absKeyRect, zoom)
				property.extractionRule.key.rect = absoluteToProportionalRect(absKeyRect)
			}
//			property.extractionRule.value.rect = absoluteToProportionalRect(doc, absValueRect, zoom)
			property.extractionRule.value.rect = absoluteToProportionalRect(absValueRectom)
			if (nextProperty.extractionRule.key) {
//				nextProperty.extractionRule.key.rect = absoluteToProportionalRect(doc, absNextKeyRect, zoom)
				nextProperty.extractionRule.key.rect = absoluteToProportionalRect(absNextKeyRect)
			}
//			nextProperty.extractionRule.value.rect = absoluteToProportionalRect(doc, absNextValueRect, zoom)
			nextProperty.extractionRule.value.rect = absoluteToProportionalRect(absNextValueRect)
			
			setUpdated(Date.now())
		}
		e.stopPropagation()
	}
	
	return (		
		<Grid item style={{flex: 1}} >

			{ (type === 'key') ?

				<Grid container direction='column' >
					<Grid container direction='row' style={{justifyContent: 'space-between', alignItems: 'center'}} >
						<FormControl>
							<FormLabel><Typography variant='caption' >Top</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementKeyTop();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absKeyRect.top)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementKeyTop();}}>+</Button>
							</Grid>
						</FormControl>							
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Left</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementKeyLeft();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absKeyRect.left)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementKeyLeft();}}>+</Button>
							</Grid>
						</FormControl>
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Width</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementKeyWidth();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absKeyRect.width)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementKeyWidth();}}>+</Button>
							</Grid>
						</FormControl>

						<FormControl>
							<FormLabel><Typography variant='caption' >Height</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementKeyHeight();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absKeyRect.height)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementKeyHeight();}}>+</Button>
							</Grid>
						</FormControl>
					</Grid>
				</Grid>

			: (type === 'value') ?

				<Grid container direction='column' >
					<Grid container direction='row' style={{justifyContent: 'space-between', alignItems: 'center'}} >
						<FormControl>
							<FormLabel><Typography variant='caption' >Top</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionTop();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.top)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionTop();}}>+</Button>
							</Grid>
						</FormControl>							
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Left</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionLeft();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.left)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionLeft();}}>+</Button>
							</Grid>
						</FormControl>
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Width</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionWidth();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.width)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionWidth();}}>+</Button>
							</Grid>
						</FormControl>

						<FormControl>
							<FormLabel><Typography variant='caption' >Height</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionHeight();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.height)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionHeight();}}>+</Button>
							</Grid>
						</FormControl>
					</Grid>
				</Grid>

			: (type === 'region') ?

				<Grid container direction='column' >
					<Grid container direction='row' style={{justifyContent: 'space-between', alignItems: 'center'}} >
						<FormControl>
							<FormLabel><Typography variant='caption' >Top</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionTop();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.top)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionTop();}}>+</Button>
							</Grid>
						</FormControl>							
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Left</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionLeft();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.left)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionLeft();}}>+</Button>
							</Grid>
						</FormControl>
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Width</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionWidth();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.width)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionWidth();}}>+</Button>
							</Grid>
						</FormControl>

						<FormControl>
							<FormLabel><Typography variant='caption' >Height</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRegionHeight();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.height)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRegionHeight();}}>+</Button>
							</Grid>
						</FormControl>
					</Grid>
				</Grid>
			
			: (type === 'table') ?

				<Grid container direction='column' >
					<Grid container direction='row' style={{justifyContent: 'space-between', alignItems: 'center'}} >
						<FormControl>
							<FormLabel><Typography variant='caption' >Top</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementTableTop();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.top)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementTableTop();}}>+</Button>
							</Grid>
						</FormControl>							
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Left</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementTableLeft();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.left)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementTableLeft();}}>+</Button>
							</Grid>
						</FormControl>
						
						<FormControl>
							<FormLabel><Typography variant='caption' >Width</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementTableWidth();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.width)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementTableWidth();}}>+</Button>
							</Grid>
						</FormControl>

						<FormControl>
							<FormLabel><Typography variant='caption' >Height</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementTableHeight();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.height)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementTableHeight();}}>+</Button>
							</Grid>
						</FormControl>
					</Grid>
				</Grid>
			
			: (type === 'header') ?

				<Grid container direction='column' >
					<Grid container direction='row-reverse' alignItems='center' >		
						<FormControl>
							<FormLabel><Typography variant='caption' >Height</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementHeaderHeight();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absFirstColKeyRect.height)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementHeaderHeight();}}>+</Button>
							</Grid>
						</FormControl>
					</Grid>
				</Grid>
			
			: (type === 'row') ?

				<Grid container direction='column' >
					<Grid container direction='row-reverse' alignItems='center' >		
						<FormControl>
							<FormLabel><Typography variant='caption' >Height</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementRowHeight();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absFirstColValueRect.height)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementRowHeight();}}>+</Button>
							</Grid>
						</FormControl>
					</Grid>
				</Grid>
			
			: (type === 'column') ?
			
				<Grid container direction='column' >

					<Grid container direction='row' alignItems='center' >
												
						{/* Property name */}
						<TextField
							variant='standard'
							style={{flex: 1}}
							label={i18.t('extraction_rule_col_name_label')}
							placeholder={i18.t('extraction_rule_col_name_placeholder')}
							value={property.name ? property.name : ''}
							onChange={(event)=>{
								property.name = event.target.value
								if (property.name) {
									property.pname = property.name.toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
								}
								updateProperty()
							}}
							required={true}
						/>
					
						<FormControl>
							<FormLabel><Typography variant='caption' >Width</Typography></FormLabel >
							<Grid container direction='row' alignItems='center' >
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();decrementColWidth();}}>-</Button>
								<Typography>{Number.parseFloat(roundToHalfDigitIncr(absValueRect.width)).toFixed(1)}</Typography>
								<Button style={{minWidth: 30}} onClick={(e)=>{e.stopPropagation();incrementColWidth();}}>+</Button>
							</Grid>
						</FormControl>
					
					</Grid>
					
					<Typography>{property.pname}</Typography>
					
					{/* Value */}
					<TextField
						variant='standard'
						style={{flex: 1}}
						label={i18.t('extraction_rule_value_label')}
						value={value && value.words ? value.words.join(' ') : value && value.rect ? '' : i18.t('extraction_value_placeholder')}
						InputLabelProps={{shrink: true}}				
						InputProps={{readOnly: true}}										
					/>
				</Grid>
			: null }
					
		</Grid>
	)
}

export default ExtractionRuleDetail