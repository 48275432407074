import React, { useState } from 'react'
import { Grid, Chip, Typography } from '@mui/material'
import * as i18 from 'i18next'


const TagFilterBar = ({ tagFilter=[] }) => (
	<Grid item>
		<Grid container style={{backgroundColor: 'lightyellow'}} direction={'row'} alignItems='center' >
			<Typography>{i18.t(`list_filtered_by`)}</Typography>
			{tagFilter?.map((tag, index) => 
				<Typography key={index} style={{paddingLeft: 5}}>{tag}</Typography>
			)}
		</Grid>
	</Grid>
)

export default TagFilterBar