import { CLEAR_ALL_WORKFLOWS, RECEIVE_WORKFLOWS, RECEIVE_WORKFLOW, DELETE_WORKFLOW, UPDATE_WORKFLOW } from './actiontypes'
import { WORKFLOW_ADD_PROPERTY, DELETE_PROPERTY_EVENT, MOVE_PROPERTY_EVENT, MOVE_TO_COMPOSITE_PROPERTY_EVENT } from './actiontypes'
import { CREATE_LAUNCH_ASSIGNMENT, ADD_TASK_EVENT, CREATE_ATTACHMENT, DELETE_ATTACHMENT, MOVE_ATTACHMENT, RECEIVE_ERRORS } from './actiontypes'
import { schema, normalize, denormalize } from 'normalizr'
import { workflowMinFragment, workflowMaxFragment, workflowReturnFragment, taskFragment, milestoneActionFragment, formFragment, formItemFragment } from '../reducers/graphql'
import { routeConditionFragment, expressionFragment, expressionBaseFragment } from '../reducers/graphql'
import { propertyFragment, attachmentFragment, permissionFragment, limitedOrgEntityFragment, taskInstanceFragment, assignmentMaxFragment } from '../reducers/graphql'
import { commentFragment, extractionRuleFragment } from '../reducers/graphql'
import { retrieveAssignments } from '../reducers/assignments'
import { store } from '../App'
import { gqlExec, gqlInput, gqlIdInput } from '../reducers/graphql'
import { generateHashCode } from '../reducers/utils'
import UUID from 'uuidjs'
import cloneDeep from 'lodash.clonedeep'

const DEVELOPER_KEY = 'AIzaSyD5Fo1rJa-rM0n4_Il2I2J8-bNFqiK6_0w'

export const attachmentSchema = new schema.Entity(
	'attachments', 
	{
	}, 
	{
		idAttribute: value => value.attachmentId
	}
)

const attachments = new schema.Array(attachmentSchema)
attachmentSchema.define({attachments})

export const propertySchema = new schema.Entity(
	'properties', 
	{
	}, 
	{
		processStrategy: (value, parent) => ({...value, parentpropertyId: parent.propertyId}),
		idAttribute: value => value.propertyId
	}
)

const properties = new schema.Array(propertySchema)
propertySchema.define({properties})

export const taskSchema = new schema.Entity(
	'tasks', 
	{
	}, 
	{
		processStrategy: (value, parent) => ({...value, parenttaskId: parent.taskId}),
		idAttribute: value => value.taskId
	}
)

const tasks = new schema.Array(taskSchema)
taskSchema.define({tasks})

export const taskInstanceSchema = new schema.Entity(
	'taskinstances', 
	{
	}, 
	{
		processStrategy: (value, parent) => ({...value, parenttaskinstanceId: parent.taskinstanceId}),
		idAttribute: value => value.taskinstanceId
	})

const taskinstances = new schema.Array(taskInstanceSchema)
taskInstanceSchema.define({taskinstances})

export const workflowSchema = new schema.Entity(
	'workflows', 
	{ 
	}, 
	{ 
		idAttribute: value => value.workflowId,
	}
)


// Utility Functions

export const removeMeta = (obj, propName) => {
	for(let prop in obj) {
		if (prop === propName) {
			delete obj[prop]
		} else if (typeof obj[prop] === 'object') {
			removeMeta(obj[prop], propName)
		}
	}
}

const updatePropertyUUID = (property) => {

	if (!property) return
	
	property.propertyId = UUID.genV4().toString()
	
	if (property.properties) {
		for (const prop of property.properties) {
			updatePropertyUUID(prop)	
		}
	}
}

// Normalize instance data into property definitions
export const setValuesFromInstanceData = (property, instanceData) => {

	if (!property) return
		
	switch (property.type) {

		case 'object':
			property.properties && property.properties.forEach(property => {
				setValuesFromInstanceData(property, instanceData?.[property.pname])
			})
		break
		
		case 'array':
			// TBD - For arrays - we have to create/expand the property object for each row (and column) since we only store the schema once (in items) for all rows.
/*
			if (!property.properties || property.properties.length === 0) {
				property.properties = []
				for (let i=0; i<instanceData?.length; ++i) {
					let item = instanceData[i]
					let prop = cloneDeep(property.items)
					updatePropertyUUID(prop)
					property.properties.push(prop)
				}
			}
			
			for (let i=0; i<instanceData?.length; ++i) {
				let item = instanceData[i]
				let prop = property.properties[i]
				setValuesFromInstanceData(prop, item)
			}
*/

			if (!property.properties) {property.properties = []}
			for (let i=0; i<instanceData?.length; ++i) {
				let item = instanceData[i]
				let prop
				if (!property.properties[i]) {
					prop = cloneDeep(property.items)
					updatePropertyUUID(prop)
					property.properties.push(prop)
				} else {
					prop = property.properties[i]
				}
				setValuesFromInstanceData(prop, item)
			}
		break

		default:
			if (property.subType === 'currency') {
				if (instanceData !== undefined && instanceData !== null) {
					if (typeof instanceData === 'string') instanceData = instanceData.replace(/[$,]+/g,'')
					property.value = Number(instanceData).toLocaleString('EN', {style: 'currency', currency: 'USD'})
				} else {
					property.value = 0
				}
			} else {
				property.value = instanceData
			}
		break
	}
}

// Normalize instance data into property definitions
export const setDefaultsFromDefaultInstanceData = (property, defaultInstanceData) => {

	if (!property) return
	
	switch (property.type) {

		case 'object':
			property.properties && property.properties.forEach(property => {
				setDefaultsFromDefaultInstanceData(property, defaultInstanceData?.[property.pname])
			})
		break
		
		case 'array':
			// For arrays - we have to create/expand the property object for each row (and column) since we only store the schema once (in items) for all rows.
/*
			if (!property.properties || property.properties.length === 0) {
				property.properties = []
				for (let i=0; i<defaultInstanceData?.length; ++i) {
					let prop = cloneDeep(property.items)
					updatePropertyUUID(prop)
					property.properties.push(prop)
				}
			}
*/			
			// Since we call setDefaultsFromDefaultInstanceData after setValuesFromInstanceData our array properties should already be defined/created	
			// ...now we simply have to set the "value" attribute on each property.
			if (!property.properties) {property.properties = []}
			for (let i=0; i<defaultInstanceData?.length; ++i) {
				let item = defaultInstanceData[i]
				let prop
				if (!property.properties[i]) {
					prop = cloneDeep(property.items)
					updatePropertyUUID(prop)
					property.properties.push(prop)
				} else {
					prop = property.properties[i]
				}
				setDefaultsFromDefaultInstanceData(prop, item)
			}
		break

		default:
			if (property.subType === 'currency') {
				if (defaultInstanceData !== undefined && defaultInstanceData !== null) {
					if (typeof defaultInstanceData === 'string') defaultInstanceData = defaultInstanceData.replace(/[$,]+/g,'')
					property.default = Number(defaultInstanceData).toLocaleString('EN', {style: 'currency', currency: 'USD'})
				} else {
					property.default = 0
				}
			} else {
				property.default = defaultInstanceData
			}
		break
	}
}

// Remove array properties
export const removeArrayProperties = (properties) => {

	if (!properties) return
	
	for (const property of properties) {

		switch (property.type) {

			case 'object':
				removeArrayProperties(property.properties)
				break
			
			case 'array':
				property.properties = null
				break

			default:
				break
		}
	}
}

export const removeValueAndDefault = (properties) => {

	if (!properties) return
	
	for (const property of properties) {

		switch (property.type) {

			case 'array':
				removeValueAndDefault(property.properties)
				break

			case 'object':
				removeValueAndDefault(property.properties)
				break

			default:
				delete property.default
				delete property.value
				break
		}
	}
}

// Denormalize instance data from property definitions
export const getInstanceDataFromValues = (property) => {

	if (!property) return

	let object
	switch (property.type) {

		case 'object':
			if (!object) object={}
			if (property.properties) {
				for (const prop of property.properties) {
					if (prop.pname) {
						object[prop.pname] = getInstanceDataFromValues(prop)
					}
				}
			}
		break

		case 'array':
			object = []
			if (property.properties) {
				for (const prop of property.properties) {
					let value = getInstanceDataFromValues(prop)
					if (value) {
						object.push(value)
					}
				}
			}
		break
		
		default:
//			if ((property.subType === 'currency' || property.subType === 'number') && typeof property.value === 'string') {
//				property.value = property.value.replace(/[^0-9.]+/g, '')  // Strip off any non-numeric characters ($, etc)
//				property.value = Number(property.value)
//			}
			object = property.value
		break
	}

	return object
}

// Denormalize default instance data from property definitions
export const getInstanceDataFromDefaults = (property) => {

	if (!property)
		return

	let object
	
	switch (property.type) {

		case 'object':
			if (!object) {object = {}}
			if (property.properties) {
				for (const prop of property.properties) {
					if (prop.pname) {
						object[prop.pname] = getInstanceDataFromDefaults(prop)
					}
				}
			}
		break
		
		case 'array':
			object = []
			if (property?.items?.type === 'object') {
				if (property.properties) {
					for (const prop of property.properties) {
						let value = getInstanceDataFromDefaults(prop)
						if (value) {
							object.push(value)
						}
					}
				}
			} else {
				object = property.default
			}
		break
		
		default:
			object = property.default
		break
	}

	return object
}

// Workflow Functions

export const refreshWorkflows = (tagFilter) => dispatch => {
	dispatch({type: CLEAR_ALL_WORKFLOWS})
	dispatch(retrieveWorkflows('workingWorkflows', 0, tagFilter))
	dispatch(retrieveWorkflows('draftWorkflows', 0, tagFilter))
	dispatch(retrieveWorkflows('templateWorkflows', 0, tagFilter))
	dispatch(retrieveWorkflows('completedWorkflows', 0, tagFilter))
}

const postWorkflowUpdates = (workflows) => {

//	console.log('postWorkflowUpdates', workflows)

	if (workflows) {

		let tasks={}
		let taskinstances={}
		let properties={}
		let attachments={}


		for (let i=0; i<workflows.length; ++i) {

			if(!workflows[i]) throw ("Invalid workflow")
			
			let workflow = workflows[i]
			
			// Set values from instance data
			if (workflow.instanceData && workflow.properties) {
				for (const property of workflow.properties) {
					setValuesFromInstanceData(property, workflow?.instanceData?.[property.pname])
				}
			}

			// Set defaults from default instance data
			if (workflow.defaultInstanceData && workflow.properties) {
				for (const property of workflow.properties) {
					setDefaultsFromDefaultInstanceData(property, workflow?.defaultInstanceData?.[property.pname])
				}
			}
		
			// Normalize the properties
			if (workflow.properties) {
				let propertydata = normalize(workflow.properties, [propertySchema])
				properties[workflow.workflowId] = propertydata.entities.properties
				workflow.properties = propertydata.result
			}

			// Normalize the attachments
			if (workflow.attachments) {
				let attachmentdata = normalize(workflow.attachments, [attachmentSchema])
				attachments[workflow.workflowId] = attachmentdata.entities.attachments
				workflow.attachments = attachmentdata.result
			}

			// Normalize the tasks
			if (workflow.tasks) {
				let taskdata = normalize(workflow.tasks, [taskSchema])
				tasks[workflow.workflowId] = taskdata.entities.tasks
				workflow.tasks = taskdata.result
			}

			// Normalize the taskinstances
			if (workflow.taskinstances) {
				let taskinstancedata = normalize(workflow.taskinstances, [taskInstanceSchema])
				taskinstances[workflow.workflowId] = taskinstancedata.entities.taskinstances
				workflow.taskinstances = taskinstancedata.result
			}

			workflow.detailRetrieved = Date.now()
		}
							
		// Now normalize the workflow data
		let workflowdata = normalize(workflows, [workflowSchema])
	
		return {list: workflowdata.result, workflows: workflowdata.entities.workflows, properties: properties, attachments: attachments, tasks: tasks, taskinstances: taskinstances}
	}
}

export const retrieveWorkflows = (listType, skip=0, tagFilter, scope) => async dispatch => {

//	console.log('retrieveWorkflows', listType)

	try {
		document.body.style.cursor = 'wait'

		let states = []
		states = (listType === 'draftWorkflows') ?
			states = ['draft']
		: (listType === 'templateWorkflows') ?
			states = ['template']
		: (listType === 'workingWorkflows') ?
			states = ['working']
		: (listType === 'completedWorkflows') ?
			states = ['completed','canceled']
		: null

		const response = await gqlExec(`{workflows ${gqlInput(states, tagFilter, skip, true, true, true)} {...workflowReturnFields}} ${workflowReturnFragment} ${workflowMinFragment}`)		

		let workflows = response && response.workflows ? response.workflows : null

		if (workflows) {
			let action = postWorkflowUpdates(workflows.items)
			action.type = RECEIVE_WORKFLOWS
			action.listType = listType
			action.skip = skip
			action.listCount = workflows.totalCount
			action.tagList = workflows.tagList
			dispatch(action)
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

function removeNullProps(data) {
	
	let keys = Object.keys(data)
	if (keys) {
		for (const key of keys) {
			let prop = data[key]
			if (prop === null) {
				delete data[key]
			} else if (typeof prop === 'object' || typeof prop === 'array') {
				removeNullProps(prop)
			}
		}
	}
}

export const retrieveWorkflow = (workflowId) => async dispatch => {

//	console.log('retrieveWorkflow');

	if (!workflowId)
		return
	
	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`query RetrieveWorkflow {workflows ${gqlIdInput('workflow', workflowId)} {...workflowReturnFields}} ${workflowReturnFragment} ${workflowMaxFragment} ${taskFragment} ${milestoneActionFragment} ${formFragment} ${formItemFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${propertyFragment} ${attachmentFragment} ${permissionFragment} ${taskInstanceFragment} ${commentFragment} ${limitedOrgEntityFragment} ${assignmentMaxFragment} ${extractionRuleFragment} `)

		let workflows = response && response.workflows ? response.workflows.items : null

		if (workflows && workflows[0]) {

			removeNullProps(workflows[0])

			workflows[0].hash = generateHashCode(workflows[0])

			let action = postWorkflowUpdates(workflows)
			action.type = RECEIVE_WORKFLOW
			dispatch(action)
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: [err]})
	} finally {
		document.body.style.cursor='default'
	}
}

export const retrieveAllWorkflowTags = () => async dispatch => {
	
//	console.log('retrieveAllWorkflowTags')

	try {
		document.body.style.cursor = 'wait'

		let states = ['draft', 'template', 'working', 'completed', 'canceled']

		const response = await gqlExec(`{workflows ${gqlInput(states, null, 0, false, false, true)} {...workflowReturnFields}} ${workflowReturnFragment} fragment workflowFields on WorkflowType {workflowId} `)		

		const workflows = response && response.workflows ? response.workflows : null

		if (workflows) {
			let action
			action = postWorkflowUpdates(workflows.items)
			action.type = RECEIVE_WORKFLOWS
			action.listType = 'allWorkflows'
			action.skip = 0
			action.listCount = workflows.totalCount
			action.tagList = workflows.tagList
			dispatch(action)
		}

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor = 'default'
	}
}

export const cancelWorkflow = (workflowId, navigation, setStatusIndicator) => async dispatch => {
	
//	console.log('cancelWorkflow');
	
	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`mutation {cancelWorkflow (workflowId: "${workflowId}") {...workflowFields}} ${workflowMaxFragment} ${taskFragment} ${milestoneActionFragment} ${formFragment} ${formItemFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${propertyFragment} ${attachmentFragment} ${permissionFragment} ${taskInstanceFragment} ${commentFragment} ${limitedOrgEntityFragment} ${assignmentMaxFragment} ${extractionRuleFragment} `)

		let workflow = response ? response.cancelWorkflow : null

		workflow.hash = generateHashCode(workflow)
		
		dispatch(refreshWorkflows())	// TBD - simply update this one workflow with the response rather than refreshing all workflows
		dispatch(retrieveAssignments("workingAssignments"))
		dispatch(retrieveAssignments("completedAssignments"))
		
		navigation.reset('workflow_completed_list')
		navigation.navigate('workflow', {workflowId: workflow.workflowId, name: workflow.name})
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor='default'
	}
}

export const createWorkflow = (navigation, input, tagFilter) => async dispatch => {
	
//	console.log('createWorkflow');
	
	if (input) {

		// Delete system properties not allowed on input		
		// TBD - replace this with an input definition that we filter against
		delete input.workflowId
		delete input.templateWorkflowId
		delete input.taskinstances
		delete input.comments
		delete input.tags
		delete input.extracts
		delete input.created
		delete input.createdBy
		delete input.updated
		delete input.updatedBy
		delete input.hash

		// Delete properties added for operational activities
		delete input.detailRetrieved

		// Remove additive properties
		removeMeta(input, 'validationErrors')
		removeMeta(input, 'modified')
		removeMeta(input, 'parenttaskId')
		removeMeta(input, 'parenttaskinstanceId')
		removeMeta(input, 'parentpropertyId')
		removeMeta(input, 'version')
		removeMeta(input, 'domain')
		removeMeta(input, 'terms')
		removeMeta(input, 'orgentityId')
		removeMeta(input.properties, 'tags')
		removeMeta(input.properties, 'default')
		removeMeta(input.properties, 'value')
//		removeMeta(input.tasks, 'extracts')	// get rid of extracts property from member in assignees
//		removeMeta(input.permissions, 'thumbnailPhotoUrl')
//		removeMeta(input.permissions, 'fullName')
//		removeMeta(input.permissions, 'resourceName')
//		removeMeta(input.tasks, 'thumbnailPhotoUrl')
//		removeMeta(input.tasks, 'fullName')
//		removeMeta(input.tasks, 'resourceName')
	}
	
	try {
		document.body.style.cursor = 'wait'

		const response = await gqlExec(`mutation {createWorkflow ${input ? `(input: ${JSON.stringify(input)})` : ``} {...workflowFields}} ${workflowMaxFragment} ${taskFragment} ${milestoneActionFragment} ${formFragment} ${formItemFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${propertyFragment} ${attachmentFragment} ${permissionFragment} ${taskInstanceFragment} ${commentFragment} ${limitedOrgEntityFragment} ${assignmentMaxFragment} ${extractionRuleFragment} `)

		let workflow = response ? response.createWorkflow : null

		workflow.hash = generateHashCode(workflow)

		let action = postWorkflowUpdates([workflow])

		action.type = RECEIVE_WORKFLOW

		await dispatch(action)
		
		if (workflow) {

			if (workflow.state === 'draft') {
				dispatch(retrieveWorkflows('draftWorkflows', 0, tagFilter))
				navigation.reset('workflow_draft_list')
			} else if (workflow.state === 'template') {
				dispatch(retrieveWorkflows('templateWorkflows', 0, tagFilter))
				navigation.reset('workflow_template_list')
			}
			navigation.navigate('workflow', {workflowId: workflow.workflowId, name: workflow.name})
		}
		
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const denormalizeWorkflow = (workflow) => {

	if (!workflow) return

	let tasks = cloneDeep(store.getState().tasks[workflow.workflowId])
	let taskinstances = cloneDeep(store.getState().taskinstances[workflow.workflowId])
	let properties = cloneDeep(store.getState().properties[workflow.workflowId])
	let attachments = cloneDeep(store.getState().attachments[workflow.workflowId])

	// De-normalize the task list back into the workflow
	workflow.tasks = denormalize(workflow.tasks, [taskSchema], {tasks: tasks});

	// De-normalize the property list back into the workflow
	workflow.properties = denormalize(workflow.properties, [propertySchema], {properties: properties});

	// De-normalize the attachment list back into the workflow
	workflow.attachments = denormalize(workflow.attachments, [attachmentSchema], {attachments: attachments});

	// De-normalize the taskinstance list back into the workflow
	workflow.taskinstances = denormalize(workflow.taskinstances, [taskInstanceSchema], {taskinstances: taskinstances});

	// Extract the instance data from the properties
	workflow.instanceData = {}
	workflow.defaultInstanceData = {}
	if (workflow.properties) {
		for (const property of workflow.properties) {
			if (property.pname) {
				workflow.instanceData[property.pname] = getInstanceDataFromValues(property)
				workflow.defaultInstanceData[property.pname] = getInstanceDataFromDefaults(property)
			}
		}
	}

	return workflow
}

export const saveWorkflow = (workflowId, setStatusIndicator) => async dispatch => {
	
//	console.log('saveWorkflow', workflowId)

	try {
		document.body.style.cursor = 'wait'

		if (setStatusIndicator) {setStatusIndicator(true)}

		const workflow = denormalizeWorkflow(cloneDeep(store.getState().workflows[workflowId]))

		if (workflow) {
		
			if (!workflow.created) throw(`Attempting to save workflow that hasn't been fully retrieved.`)

			const hash = workflow.hash

			// Delete properties added for operational activities
			delete workflow.hash
			delete workflow.detailRetrieved
			removeMeta(workflow, 'parenttaskId')
			removeMeta(workflow, 'parenttaskinstanceId')
			removeMeta(workflow, 'parentpropertyId')
			removeMeta(workflow, 'modified')

			if (generateHashCode(workflow) === hash) {
console.log(`Save not needed!`)
				return
			}

console.log(`Saving workflow at ${new Intl.DateTimeFormat("en-US", {dateStyle: 'medium', timeStyle: 'long'}).format(new Date())}`)

			// Delete document and defaultDocument from attachments since they cannot be input
			removeMeta(workflow.attachments, 'defaultDocument')
			removeMeta(workflow.attachments, 'document')
			
			// Remove default/value from each since these are pulled into instanceData and defaultInstanceData respectively
			removeValueAndDefault(workflow.properties)
			removeArrayProperties(workflow.properties)			

			if (workflow.state === 'working') {
				// Can't update tasks, properties, or defaultInstanceData on a working workflow
				delete workflow.tasks
				delete workflow.properties
				delete workflow.defaultInstanceData
			} else {
				// Can't update instanceData on a non-working workflow
				delete workflow.instanceData
			}

			// Delete system properties not allowed on input		
			// TBD - replace this with an input definition that we filter against
			delete workflow.state
			delete workflow.templateWorkflowId
			delete workflow.taskinstances
			delete workflow.comments
			delete workflow.extracts
			delete workflow.created
			delete workflow.createdBy
			delete workflow.updatedBy

			// Remove additive properties
			removeMeta(workflow, 'validationErrors')
			removeMeta(workflow, 'version')
			removeMeta(workflow, 'domain')
			removeMeta(workflow, 'terms')
			removeMeta(workflow.properties, 'tags')
			removeMeta(workflow.properties, 'default')


			const response = await gqlExec(`mutation {updateWorkflow (input: ${JSON.stringify(workflow)}){...workflowFields}} ${workflowMaxFragment} ${taskFragment} ${milestoneActionFragment} ${formFragment} ${formItemFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${propertyFragment} ${attachmentFragment} ${permissionFragment} ${taskInstanceFragment} ${commentFragment} ${limitedOrgEntityFragment} ${assignmentMaxFragment} ${extractionRuleFragment} `)
		
			let workflow2 = response.updateWorkflow

			if (workflow2) {

				workflow2.hash = generateHashCode(workflow2)
				
				let action = postWorkflowUpdates([workflow2])

				action.type = RECEIVE_WORKFLOW
//				if (['draft'].includes(workflow2.state)) {action.listType = 'draftWorkflows'}
//				if (['template'].includes(workflow2.state)) {action.listType = 'templateWorkflows'}
//				if (['working'].includes(workflow2.state)) {action.listType = 'workingWorkflows'}
//				if (['completed','canceled'].includes(workflow2.state)) {action.listType = 'completedWorkflows'}
//				action.listCount = response?.workflows?.totalCount

				dispatch(action)
				
				return workflow2
			}
		}
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor = 'default'
	}
}

export const saveWorkflowAs = (workflowId, state='template', navigation, setStatusIndicator) => async dispatch => {
	
//	console.log('saveWorkflowAs');
	
	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`mutation {saveWorkflowAs (workflowId: "${workflowId}", state: "${state}") {...workflowFields}} ${workflowMaxFragment} ${taskFragment} ${milestoneActionFragment} ${formFragment} ${formItemFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${propertyFragment} ${attachmentFragment} ${permissionFragment} ${taskInstanceFragment} ${commentFragment} ${limitedOrgEntityFragment} ${assignmentMaxFragment} ${extractionRuleFragment} `)

		let workflow = response.saveWorkflowAs

		workflow.hash = generateHashCode(workflow)
		
		let action = postWorkflowUpdates([workflow])

		action.type = RECEIVE_WORKFLOW

		await dispatch(action)

		dispatch(retrieveWorkflows('templateWorkflows', 0))
		dispatch(retrieveWorkflows('draftWorkflows', 0))				// need to update drafts for the case where we save a draft as a template, in which case the draft no longer exists

		if (workflow?.state === 'template') {
			navigation.reset('workflow_template_list')
			navigation.navigate('workflow', {workflowId: workflow.workflowId, name: workflow.name})
		} else if (workflow?.state === 'draft') {
			navigation.reset('workflow_draft_list')
			navigation.navigate('workflow', {workflowId: workflow.workflowId, name: workflow.name})
		}

		return workflow

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		if (setStatusIndicator) {setStatusIndicator(false)}
		document.body.style.cursor='default'
	}
}

export const runWorkflow = (workflowId, navigation, setStatusIndicator) => async dispatch => {

//	console.log('runWorkflow')

	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		let workflow = cloneDeep(store.getState().workflows[workflowId])

		// Update mutation if there are initial properties
		if (workflow && workflow.properties) {

			const properties = store.getState().properties[workflowId]

			if (properties) {
				workflow.properties = denormalize(workflow.properties, [propertySchema], {properties: properties});

				workflow.instanceData = {}
				if (workflow.properties) {
					for (const property of workflow.properties) {
						if (property.pname) {
							workflow.instanceData[property.pname] = getInstanceDataFromValues(property)
						}
					}
				}	
			}
		}

		if (workflow && workflow.attachments) {

			const attachments = store.getState().attachments[workflowId]
	
			// De-normalize the attachment list back into the workflow
			workflow.attachments = denormalize(workflow.attachments, [attachmentSchema], {attachments: attachments});
		}

		removeMeta(workflow, 'modified')
		removeMeta(workflow, 'validationErrors')
		
		let input = {
			workflowId: workflow.workflowId,
			attachments: workflow.attachments,
			instanceData: workflow.instanceData,
		}

		const response = await gqlExec(`mutation {runWorkflow (input: ${JSON.stringify(input)}) {...workflowFields}} ${workflowMaxFragment} ${taskFragment} ${milestoneActionFragment} ${formFragment} ${formItemFragment} ${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${propertyFragment} ${attachmentFragment} ${permissionFragment} ${taskInstanceFragment} ${commentFragment} ${limitedOrgEntityFragment} ${assignmentMaxFragment} ${extractionRuleFragment} `)

		workflow = response.runWorkflow

		if (workflow) {
			workflow.hash = generateHashCode(workflow)
			let action = postWorkflowUpdates([workflow])
			action.type = RECEIVE_WORKFLOW
			await dispatch(action)

			dispatch(retrieveAssignments("workingAssignments"))
			dispatch(retrieveWorkflows('workingWorkflows', 0))
			dispatch(retrieveWorkflows('draftWorkflows', 0))

			return workflow
		}
				
	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}

}

export const deleteWorkflow  = (workflowId, state, navigation, setStatusIndicator) => async dispatch => {
	
//	console.log('deleteWorkflow');
	
	try {
		document.body.style.cursor = 'wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		const response = await gqlExec(`mutation DeleteWorkflow {deleteWorkflow (workflowId: "${workflowId}") {workflowId}} `)

		await dispatch(refreshWorkflows())	// TBD - just refresh redux by deleting this one workflow rather than refreshing all

		navigation.reset(state === 'template' ? 'workflow_template_list' : 'workflow_draft_list')

	}
	catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}

export const createLaunchAssignment = (workflowId, tasklist, tasks) => async dispatch => {

	if (!workflowId || !tasklist || !tasks) return
	
//	console.log('createLaunchAssignment')
		
	try {
		document.body.style.cursor = 'wait'

// TBD - force save of workflow here before creating launch assignment
		await dispatch(saveWorkflow(workflowId))
		
		let workflow = store.getState().workflows[workflowId]
		let workflowProperties = store.getState().properties[workflowId]
		let workflowAttachments = store.getState().attachments[workflowId]

		let properties = {}
		let attachments = {}
		
		if (workflow.properties) {

			// Denormalize the properties
			workflow.properties = denormalize(workflow.properties, [propertySchema], {properties: workflowProperties})

			// Copy over the default values for each property
			if (workflow.properties) {
				for (const property of workflow.properties) {
					if (property.pname) {
						workflow.defaultInstanceData[property.pname] = getInstanceDataFromDefaults(property)
					}
				}
			}	

			for (const property of workflow.properties) {
				setValuesFromInstanceData(property, workflow.defaultInstanceData?.[property.pname])
			}

			// Normalize the properties
			let propertydata = normalize(workflow.properties, [propertySchema])
			properties[workflow.workflowId] = propertydata.entities.properties
			workflow.properties = propertydata.result
		}

		await dispatch({type: CREATE_LAUNCH_ASSIGNMENT, properties: properties, attachments: attachments})

	} catch(err) {		
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
	} finally {
		document.body.style.cursor='default'
	}
}

export const analyzeWorkflowAttachment = (workflowId, propertyId, runtime, setShowAttachmentAnalyzeFailDialog, setStatusIndicator) => async dispatch => {

//	console.log('analyzeWorkflowAttachment')

	if (!workflowId || !propertyId) return

	try {
		document.body.style.cursor='wait'
		if (setStatusIndicator) {setStatusIndicator(true)}

		await dispatch(saveWorkflow(workflowId))
		const response = await gqlExec(`mutation AnalyzeWorkflowDocument {analyzeWorkflowAttachment ( workflowId: "${workflowId}", propertyId: "${propertyId}", runtime: ${runtime}) {...workflowFields}} ${workflowMaxFragment} ${taskFragment} ${formFragment} ${formItemFragment} ${milestoneActionFragment}${routeConditionFragment} ${expressionFragment} ${expressionBaseFragment} ${propertyFragment} ${attachmentFragment} ${permissionFragment} ${taskInstanceFragment} ${commentFragment} ${limitedOrgEntityFragment} ${assignmentMaxFragment} ${extractionRuleFragment} `)

		let workflow = response.analyzeWorkflowAttachment

		workflow.hash = generateHashCode(workflow)

		let action = postWorkflowUpdates([workflow])

		action.type = RECEIVE_WORKFLOW
//		if (['draft'].includes(workflow.state)) {action.listType = 'draftWorkflows'}
//		if (['template'].includes(workflow.state)) {action.listType = 'templateWorkflows'}
//		if (['working'].includes(workflow.state)) {action.listType = 'workingWorkflows'}
//		if (['completed','canceled'].includes(workflow.state)) {action.listType = 'completedWorkflows'}

		await dispatch(action)

		return workflow
		
	} catch(err) {
		console.log(err)
		dispatch({type: RECEIVE_ERRORS, errors: err.errors})
		setShowAttachmentAnalyzeFailDialog(true)
	} finally {
		document.body.style.cursor = 'default'
		if (setStatusIndicator) {setStatusIndicator(false)}
	}
}

export const updateWorkflow = (workflow) => dispatch => {
	workflow.modified = Date.now()
	dispatch({type: UPDATE_WORKFLOW, workflow: workflow})
}


const initialState = {
}

const workflows = (state = initialState, action) => {
	switch (action.type) {
		
// Workflow Events

		case CLEAR_ALL_WORKFLOWS:
			state = {}
			break
			
		case RECEIVE_WORKFLOW:
		case RECEIVE_WORKFLOWS:
			state = {...state}
			for (let i=0; action.list && i<action.list.length; ++i) {
				state[action.list[i]] = {...state[action.list[i]], ...action.workflows[action.list[i]]}
			}
			break

		case UPDATE_WORKFLOW:
			state = {...state,
				[action.workflow.workflowId]: action.workflow,
			}			
			break

		case DELETE_WORKFLOW:
			delete state[action.workflowId]
			break


// Task Events

		case ADD_TASK_EVENT:
			state = {...state}			
			break
			
			
// Property Events

		case WORKFLOW_ADD_PROPERTY:
			state = {...state}
			let workflow = state[action.workflowId]
			if (!workflow.properties) {workflow.properties = []}
			workflow.properties = [...workflow.properties, ...action.propertylist]
			break

		case DELETE_PROPERTY_EVENT:
			state = {...state}
			state[action.rootparentId] = {...state[action.rootparentId]}
			if (state[action.rootparentId] && state[action.rootparentId].properties) {
				let index = state[action.rootparentId].properties.findIndex(propertyId => propertyId === action.propertyId)
				state[action.rootparentId].properties.splice(index, 1)
			}
			break

		case MOVE_PROPERTY_EVENT: {
			state = {...state}
			if (state[action.rootparentId]  && state[action.rootparentId].properties) {
				state[action.rootparentId].properties.splice(action.toIndex, 0, state[action.rootparentId].properties.splice(action.fromIndex, 1)[0])
			}
		} 
		break

		case MOVE_TO_COMPOSITE_PROPERTY_EVENT: {
			// If this property was a child of the root parent - remove it from there
			state = {...state}
			let keys = Object.keys(state)
			for (const key of keys) {
				let rootParent = state[key][action.rootparentId]
				let index = rootParent && rootParent.properties ? rootParent.properties.indexOf(action.propertyId) : -1
				if (index !== -1) rootParent.properties.splice(index,1)
			}
		} break

// Attachment Events

		case CREATE_ATTACHMENT:
			state = {...state}
			if (state[action.rootparentId]) {
				if (!state[action.rootparentId].attachments) {state[action.rootparentId].attachments=[]}
				state[action.rootparentId].attachments.push(action.attachment.attachmentId)
			}
			break

		case DELETE_ATTACHMENT:
			state = {...state}
			let index = state[action.rootparentId].attachments.indexOf(action.attachmentId)
			if (index !== -1) {
				state[action.rootparentId].attachments.splice(index, 1)
			}
			break
		
		case MOVE_ATTACHMENT: {
			state = {...state}
			if (state[action.rootparentId]  && state[action.rootparentId].attachments) {
				state[action.rootparentId].attachments.splice(action.toIndex, 0, state[action.rootparentId].attachments.splice(action.fromIndex, 1)[0])
			}
		} 
		break

		default:
			break
	}

	return state;
}

export default workflows