import document_simple from './document_simple.json'
import testfragments from './testfragments'
import cloneDeep from 'lodash.clonedeep'


const stringify = (str) => {
	return JSON.stringify(str).replace(/"(\w+)"\s*:/g, '$1:')
}

let doc = {...document_simple, documentId: "<<documentId>>"}

// Test document name

// DD-0001
var nullname = cloneDeep(doc)
nullname.name = null

// DD-0002
var shortname = cloneDeep(doc)
shortname.name = testfragments.text1

// DD-0003
var longname = cloneDeep(doc)
longname.name = testfragments.text100

// DD-0004
var toolongname = cloneDeep(doc)
toolongname.name = testfragments.text101

// DD-0005
var specialcharname = cloneDeep(doc)
specialcharname.name = testfragments.textspecial


// Test document description

// DD-0006
var nulldesc = cloneDeep(doc)
nulldesc.description = null

// DD-0007
var shortdesc = cloneDeep(doc)
shortdesc.description = testfragments.text1

// DD-0008
var longdesc = cloneDeep(doc)
longdesc.description = testfragments.text1000

// DD-0009
var toolongdesc = cloneDeep(doc)
toolongdesc.description = testfragments.text1001

// DD-0010
var specialchardesc = cloneDeep(doc)
specialchardesc.description = testfragments.textspecial


let prop

// Test property name

// DD-0011
var nullpropname = cloneDeep(doc)
prop = {...testfragments.string}
prop.name = null
nullpropname.properties.push(prop)

// DD-0012
var shortpropname = cloneDeep(doc)
prop = {...testfragments.string}
prop.name = testfragments.text1
shortpropname.properties.push(prop)

// DD-0013
var longpropname = cloneDeep(doc)
prop = {...testfragments.string}
prop.name = testfragments.text100
longpropname.properties.push(prop)

// DD-0014
var toolongpropname = cloneDeep(doc)
prop = {...testfragments.string}
prop.name = testfragments.text101
toolongpropname.defaultInstanceData = {[testfragments.text101]: 'abc'}
toolongpropname.properties.push(prop)

// DD-0015
var specialcharpropname = cloneDeep(doc)
prop = {...testfragments.string}
prop.name = testfragments.textspecial
specialcharpropname.properties.push(prop)


// Test property description

// DD-0016
var nullpropdesc = cloneDeep(doc)
prop = {...testfragments.string}
prop.description = null
nullpropdesc.properties.push(prop)

// DD-0017
var shortpropdesc = cloneDeep(doc)
prop = {...testfragments.string}
prop.description = testfragments.text1
shortpropdesc.properties.push(prop)

// DD-0018
var longpropdesc = cloneDeep(doc)
prop = {...testfragments.string}
prop.description = testfragments.text1000
longpropdesc.properties.push(prop)

// DD-0019
var toolongpropdesc = cloneDeep(doc)
prop = {...testfragments.string}
prop.description = testfragments.text1001
toolongpropdesc.properties.push(prop)

// DD-0020
var specialcharpropdesc = cloneDeep(doc)
prop = {...testfragments.string}
prop.description = testfragments.textspecial
specialcharpropdesc.properties.push(prop)


// Test property types

// DD-0021
var stringtype = cloneDeep(doc)
prop = {...testfragments.string}
stringtype.properties.push(prop)

// DD-0022
var string_multitype = cloneDeep(doc)
prop = {...testfragments.string_multi}
string_multitype.properties.push(prop)

// DD-0023
var dropdown_multitype = cloneDeep(doc)
prop = {...testfragments.dropdown_multi}
dropdown_multitype.properties.push(prop)

// DD-0024
var list_multitype = cloneDeep(doc)
prop = {...testfragments.list_multi}
list_multitype.properties.push(prop)

// DD-0025
var list_singletype = cloneDeep(doc)
prop = {...testfragments.list_single}
list_singletype.properties.push(prop)

// DD-0026
var dropdown_singletype = cloneDeep(doc)
prop = {...testfragments.dropdown_single}
dropdown_singletype.properties.push(prop)

// DD-0028
//var passwordtype = cloneDeep(doc)
//prop = {...testfragments.password}
//passwordtype.properties.push(prop)

// DD-0029
var telephonetype = cloneDeep(doc)
prop = {...testfragments.telephone}
telephonetype.properties.push(prop)

// DD-0030
var usstateabbrevtype = cloneDeep(doc)
prop = {...testfragments.usstateabbrev}
usstateabbrevtype.properties.push(prop)

// DD-0031
var emailtype = cloneDeep(doc)
prop = {...testfragments.email}
emailtype.properties.push(prop)

// DD-0032
var uszipcodetype = cloneDeep(doc)
prop = {...testfragments.uszipcode}
uszipcodetype.properties.push(prop)

// DD-0033
var numbertype = cloneDeep(doc)
prop = {...testfragments.number}
numbertype.properties.push(prop)

// DD-0034
var objecttype = cloneDeep(doc)
prop = {...testfragments.object}
objecttype.properties.push(prop)

// DD-0035
var object_maxtype = cloneDeep(doc)
prop = {...testfragments.object_max}
object_maxtype.properties.push(prop)

// DD-0036
var object_overmaxtype = cloneDeep(doc)
prop = {...testfragments.object_overmax}
object_overmaxtype.properties.push(prop)

// DD-0037
var jsontype = cloneDeep(doc)
prop = {...testfragments.json}
jsontype.properties.push(prop)



// Test property types - default values

// DD-0038
var stringtype_max = cloneDeep(doc)
prop = {...testfragments.string}
prop.name = `prop1`
stringtype_max.defaultInstanceData = {prop1: testfragments.text1000}
stringtype_max.properties.push(prop)

// DD-0039
var stringtype_overmax = cloneDeep(doc)
prop = {...testfragments.string}
prop.name = `prop1`
stringtype_overmax.defaultInstanceData = {prop1: testfragments.text1001}
stringtype_overmax.properties.push(prop)

// DD-0040
var string_multitype_max = cloneDeep(doc)
prop = {...testfragments.string_multi}
prop.name = `prop1`
string_multitype_max.defaultInstanceData = {prop1: testfragments.text2500}
string_multitype_max.properties.push(prop)

// DD-0041
var string_multitype_overmax = cloneDeep(doc)
prop = {...testfragments.string_multi}
prop.name = `prop1`
string_multitype_overmax.defaultInstanceData = {prop1: testfragments.text2501}
string_multitype_overmax.properties.push(prop)

// DD-0042
var dropdown_multitype_valid = cloneDeep(doc)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
dropdown_multitype_valid.defaultInstanceData = {prop1: ['Option 1', 'Option 2']}
dropdown_multitype_valid.properties.push(prop)

// DD-0043
var dropdown_multitype_invalid = cloneDeep(doc)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
dropdown_multitype_invalid.defaultInstanceData = {prop1: ['Option 1', 'Option A']}
dropdown_multitype_invalid.properties.push(prop)

// DD-0044
var list_multitype_valid = cloneDeep(doc)
prop = {...testfragments.list_multi}
prop.name = `prop1`
list_multitype_valid.defaultInstanceData = {prop1: ['Option 1','Option 2']}
list_multitype_valid.properties.push(prop)

// DD-0045
var list_multitype_invalid = cloneDeep(doc)
prop = {...testfragments.list_multi}
prop.name = `prop1`
list_multitype_invalid.defaultInstanceData = {prop1: ['Option 1', 'Option A']}
list_multitype_invalid.properties.push(prop)

// DD-0046
var list_singletype_valid = cloneDeep(doc)
prop = {...testfragments.list_single}
prop.name = `prop1`
list_singletype_valid.defaultInstanceData = {prop1: 'Option 1'}
list_singletype_valid.properties.push(prop)

// DD-0047
var list_singletype_invalid = cloneDeep(doc)
prop = {...testfragments.list_single}
prop.name = `prop1`
list_singletype_invalid.defaultInstanceData = {prop1: 'Option A'}
list_singletype_invalid.properties.push(prop)

// DD-0048
var dropdown_singletype_valid = cloneDeep(doc)
prop = {...testfragments.dropdown_single}
prop.name = `prop1`
dropdown_singletype_valid.defaultInstanceData = {prop1: 'Option 1'}
dropdown_singletype_valid.properties.push(prop)

// DD-0049
var dropdown_singletype_invalid = cloneDeep(doc)
prop = {...testfragments.dropdown_single}
prop.name = `prop1`
dropdown_singletype_invalid.defaultInstanceData = {prop1: 'Option A'}
dropdown_singletype_invalid.properties.push(prop)

// DD-0050
//var membergrouptype = cloneDeep(doc)
//prop = {...testfragments.membergroup}
//membergrouptype.properties.push(prop)

// DD-0051
//var passwordtype = cloneDeep(doc)
//prop = {...testfragments.password}
//passwordtype.properties.push(prop)

// DD-0052
var telephonetype_valid = cloneDeep(doc)
prop = {...testfragments.telephone}
prop.name = `prop1`
telephonetype_valid.defaultInstanceData = {prop1: '555-555-5555'}
telephonetype_valid.properties.push(prop)

// DD-0053
var telephonetype_invalid = cloneDeep(doc)
prop = {...testfragments.telephone}
prop.name = `prop1`
telephonetype_invalid.defaultInstanceData = {prop1: '555-555-55554'}
telephonetype_invalid.properties.push(prop)

// DD-0054
var usstateabbrevtype_valid = cloneDeep(doc)
prop = {...testfragments.usstateabbrev}
prop.name = `prop1`
usstateabbrevtype_valid.defaultInstanceData = {prop1: 'CA'}
usstateabbrevtype_valid.properties.push(prop)

// DD-0055
var usstateabbrevtype_invalid = cloneDeep(doc)
prop = {...testfragments.usstateabbrev}
prop.name = `prop1`
usstateabbrevtype_invalid.defaultInstanceData = {prop1: 'ZZ'}
usstateabbrevtype_invalid.properties.push(prop)

// DD-0056
var emailtype_valid = cloneDeep(doc)
prop = {...testfragments.email}
prop.name = `prop1`
emailtype_valid.defaultInstanceData = {prop1: 'joe@acme.com'}
emailtype_valid.properties.push(prop)

// DD-0057
var emailtype_invalid = cloneDeep(doc)
prop = {...testfragments.email}
prop.name = `prop1`
emailtype_invalid.defaultInstanceData = {prop1: 'joe@acme@com'}
emailtype_invalid.properties.push(prop)

// DD-0058
var uszipcodetype_valid = cloneDeep(doc)
prop = {...testfragments.uszipcode}
prop.name = `prop1`
uszipcodetype_valid.defaultInstanceData = {prop1: '90000'}
uszipcodetype_valid.properties.push(prop)

// DD-0059
var uszipcodetype_invalid = cloneDeep(doc)
prop = {...testfragments.uszipcode}
prop.name = `prop1`
uszipcodetype_invalid.defaultInstanceData = {prop1: '90000A'}
uszipcodetype_invalid.properties.push(prop)

// DD-0060
var numbertype_valid = cloneDeep(doc)
prop = {...testfragments.number}
prop.name = `prop1`
numbertype_valid.defaultInstanceData = {prop1: 100}
numbertype_valid.properties.push(prop)

// DD-0061
var numbertype_invalid = cloneDeep(doc)
prop = {...testfragments.number}
prop.name = `prop1`
numbertype_invalid.defaultInstanceData = {prop1: 'AA'}
numbertype_invalid.properties.push(prop)

// DD-0062
var dropdown_multitype_valid2 = cloneDeep(doc)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
prop.maxItems = 2
dropdown_multitype_valid2.defaultInstanceData = {prop1: ['Option 1', 'Option 2']}
dropdown_multitype_valid2.properties.push(prop)

// DD-0063
var dropdown_multitype_invalid2 = cloneDeep(doc)
prop = {...testfragments.dropdown_multi}
prop.name = `prop1`
prop.maxItems = 2
dropdown_multitype_invalid2.defaultInstanceData = {prop1: ['Option 1', 'Option 2', 'Option 3']}
dropdown_multitype_invalid2.properties.push(prop)

// DD-0064
var list_multitype_valid2 = cloneDeep(doc)
prop = {...testfragments.list_multi}
prop.name = `prop1`
prop.maxItems = 2
list_multitype_valid2.defaultInstanceData = {prop1: ['Option 1','Option 2']}
list_multitype_valid2.properties.push(prop)

// DD-0065
var list_multitype_invalid2 = cloneDeep(doc)
prop = {...testfragments.list_multi}
prop.name = `prop1`
prop.maxItems = 2
list_multitype_invalid2.defaultInstanceData = {prop1: ['Option 1', 'Option 2', 'Option 3']}
list_multitype_invalid2.properties.push(prop)


// DD-0066
//var objecttype = cloneDeep(doc)
//prop = {...testfragments.object}
//objecttype.properties.push(prop)

// DD-0067
//var object_maxtype = cloneDeep(doc)
//prop = {...testfragments.object_max}
//object_maxtype.properties.push(prop)

// DD-0068
//var object_overmaxtype = cloneDeep(doc)
//prop = {...testfragments.object_overmax}
//object_overmaxtype.properties.push(prop)

// DD-0069
var jsontype_valid = cloneDeep(doc)
prop = {...testfragments.json}
prop.name = `prop1`
jsontype_valid.defaultInstanceData = {prop1: `{"validjsonprop": "validjsonvalue"}`}
jsontype_valid.properties.push(prop)

// DD-0070
var jsontype_invalid = cloneDeep(doc)
prop = {...testfragments.json}
prop.name = `prop1`
jsontype_invalid.defaultInstanceData = {prop1: `{invalidjsonprop: "validjsonvalue"}`}
jsontype_invalid.properties.push(prop)

// DD-0071
var jsontype_invalid2 = cloneDeep(doc)
prop = {...testfragments.json}
prop.name = `prop1`
jsontype_invalid2.defaultInstanceData = {prop1: `{"validjsonprop": invalidjsonvalue}`}
jsontype_invalid2.properties.push(prop)




const documentdetails = [{
	name: "Document details test collection",
	tests: [

		{
			id: 'DD-SETUP',
			name: 'create simple document.',
			type: 'setup',
			operation: `mutation {createDocument (input: ${stringify(document_simple)}) {documentId state}}`,
			assertResultIncludes: [`"state":"template"`],
			onEachItem: [


				// null name
				{
					id: 'DD-0001',
					name: 'null name',
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(nullname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				// short name
				{
					id: 'DD-0002',
					name: 'short name', 			
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(shortname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},

				// long name
				{
					id: 'DD-0003',
					name: 'long name', 			
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(longname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// too long name
				{
					id: 'DD-0004',
					name: 'too long name', 			
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(toolongname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},
			
				// special char name
				{
					id: 'DD-0005',
					name: 'special char name', 	
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(specialcharname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},

				// null description
				{
					id: 'DD-0006',
					name: 'null desc', 				
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(nulldesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// short description
				{
					id: 'DD-0007',
					name: 'short desc', 			
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(shortdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// long description
				{
					id: 'DD-0008',
					name: 'long desc', 			
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(longdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},
				
				// too long description
				{
					id: 'DD-0009',
					name: 'too long desc', 			
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(toolongdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},
				
				// special char description
				{
					id: 'DD-0010',
					name: 'special char desc', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(specialchardesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[]}`
				},

				// null prop name
				{
					id: 'DD-0011',
					name: 'null prop name', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(nullpropname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_invalid_property_name"`]
				},
													
				
				// short prop name
				{
					id: 'DD-0012',
					name: 'short prop name', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(shortpropname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// long prop name
				{
					id: 'DD-0013',
					name: 'long prop name', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(longpropname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// too long prop name
				{
					id: 'DD-0014',
					name: 'too long prop name', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(toolongpropname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},

				// special char prop name
				{
					id: 'DD-0015',
					name: 'special char prop name', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(specialcharpropname)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// null prop description
				{
					id: 'DD-0016',
					name: 'null prop description', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(nullpropdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// short prop description
				{
					id: 'DD-0017',
					name: 'short prop description', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(shortpropdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// long prop description
				{
					id: 'DD-0018',
					name: 'long prop description', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(longpropdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},
				
				// too long prop description
				{
					id: 'DD-0019',
					name: 'too long prop description', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(toolongpropdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`"validation_error_maxlength"`]
				},

				// special char prop description
				{
					id: 'DD-0020',
					name: 'special char prop description', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(specialcharpropdesc)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},


				// stringtype
				{
					id: 'DD-0021',
					name: 'stringtype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(stringtype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// string_multitype
				{
					id: 'DD-0022',
					name: 'string_multitype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(string_multitype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// dropdown_multitype
				{
					id: 'DD-0023',
					name: 'dropdown_multitype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_multitype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":null},"validationErrors":[],"properties":null}]}`
				},

				// list_multitype
				{
					id: 'DD-0024',
					name: 'list_multitype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(list_multitype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":null},"validationErrors":[],"properties":null}]}`
				},

				// list_singletype
				{
					id: 'DD-0025',
					name: 'list_singletype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(list_singletype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// dropdown_singletype
				{
					id: 'DD-0026',
					name: 'dropdown_singletype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_singletype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// passwordtype
//				{
//					id: 'DD-0028',
//					name: 'passwordtype', 
//					type: 'positive',
//					operation: `mutation {updateDocument (input: ${stringify(passwordtype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
//					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
//				},

				// telephonetype
				{
					id: 'DD-0029',
					name: 'telephonetype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(telephonetype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// usstateabbrevtype
				{
					id: 'DD-0030',
					name: 'usstateabbrevtype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(usstateabbrevtype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// emailtype
				{
					id: 'DD-0031',
					name: 'emailtype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(emailtype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// uszipcodetype
				{
					id: 'DD-0032',
					name: 'uszipcodetype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(uszipcodetype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// numbertype
				{
					id: 'DD-0033',
					name: 'numbertype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(numbertype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// objecttype
				{
					id: 'DD-0034',
					name: 'objecttype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(objecttype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null},{"items":null,"validationErrors":[],"properties":null}]}]}`
				},

				// object_maxtype
				{
					id: 'DD-0035',
					name: 'object_maxtype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(object_maxtype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null},{"items":null,"validationErrors":[],"properties":null}]}]}]}]}`
				},

				// object_overmaxtype
				{
					id: 'DD-0036',
					name: 'object_overmaxtype', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(object_overmaxtype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_property_nested_too_deeply`]
				},

				// jsontype
				{
					id: 'DD-0037',
					name: 'jsontype', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(jsontype)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},



				// stringtype_max
				{
					id: 'DD-0038',
					name: 'stringtype_max', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(stringtype_max)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// stringtype_overmax
				{
					id: 'DD-0039',
					name: 'stringtype_overmax', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(stringtype_overmax)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxlength`]
				},

				// string_multitype_max
				{
					id: 'DD-0040',
					name: 'string_multitype_max', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(string_multitype_max)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// string_multitype_overmax
				{
					id: 'DD-0041',
					name: 'string_multitype_overmax', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(string_multitype_overmax)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxlength`]
				},


				// dropdown_multitype_valid
				{
					id: 'DD-0042',
					name: 'dropdown_multitype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_multitype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},


				// dropdown_multitype_invalid
				{
					id: 'DD-0043',
					name: 'dropdown_multitype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_multitype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// list_multitype_valid
				{
					id: 'DD-0044',
					name: 'list_multitype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(list_multitype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},

				// list_multitype_invalid
				{
					id: 'DD-0045',
					name: 'list_multitype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(list_multitype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// list_singletype_valid
				{
					id: 'DD-0046',
					name: 'list_singletype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(list_singletype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// list_singletype_invalid
				{
					id: 'DD-0047',
					name: 'list_singletype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(list_singletype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// dropdown_singletype_valid
				{
					id: 'DD-0048',
					name: 'dropdown_singletype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_singletype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// dropdown_singletype_invalid
				{
					id: 'DD-0049',
					name: 'dropdown_singletype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_singletype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},


//50
//var membergrouptype = cloneDeep(doc)
//prop = {...testfragments.membergroup}
//membergrouptype.properties.push(prop)

//51
//var passwordtype = cloneDeep(doc)
//prop = {...testfragments.password}
//passwordtype.properties.push(prop)

				// telephonetype_valid
				{
					id: 'DD-0052',
					name: 'telephonetype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(telephonetype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// telephonetype_invalid
				{
					id: 'DD-0053',
					name: 'telephonetype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(telephonetype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// usstateabbrevtype_valid
				{
					id: 'DD-0054',
					name: 'usstateabbrevtype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(usstateabbrevtype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// usstateabbrevtype_invalid
				{
					id: 'DD-0055',
					name: 'usstateabbrevtype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(usstateabbrevtype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_enum`]
				},

				// emailtype_valid
				{
					id: 'DD-0056',
					name: 'emailtype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(emailtype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// emailtype_invalid
				{
					id: 'DD-0057',
					name: 'emailtype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(emailtype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// uszipcodetype_valid
				{
					id: 'DD-0058',
					name: 'uszipcodetype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(uszipcodetype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// uszipcodetype_invalid
				{
					id: 'DD-0059',
					name: 'uszipcodetype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(uszipcodetype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// numbertype_valid
				{
					id: 'DD-0060',
					name: 'numbertype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(numbertype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// numbertype_invalid
				{
					id: 'DD-0061',
					name: 'numbertype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(numbertype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_pattern`]
				},

				// dropdown_multitype_valid2
				{
					id: 'DD-0062',
					name: 'dropdown_multitype_valid2', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_multitype_valid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},

				// dropdown_multitype_invalid2
				{
					id: 'DD-0063',
					name: 'dropdown_multitype_invalid2', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(dropdown_multitype_invalid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxitems`]
				},

				// list_multitype_valid2
				{
					id: 'DD-0064',
					name: 'list_multitype_valid2', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(list_multitype_valid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":{"validationErrors":[]},"validationErrors":[],"properties":null}]}`
				},

				// list_multitype_invalid2
				{
					id: 'DD-0065',
					name: 'list_multitype_invalid2', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(list_multitype_invalid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_maxitems`]
				},


//66
//var objecttype = cloneDeep(doc)

//67
//var object_maxtype = cloneDeep(doc)

//68
//var object_overmaxtype = cloneDeep(doc)


				// jsontype_valid
				{
					id: 'DD-0069',
					name: 'jsontype_valid', 
					type: 'positive',
					operation: `mutation {updateDocument (input: ${stringify(jsontype_valid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResult: `{"validationErrors":[],"properties":[{"items":null,"validationErrors":[],"properties":null}]}`
				},

				// jsontype_invalid
				{
					id: 'DD-0070',
					name: 'jsontype_invalid', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(jsontype_invalid)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_invalid_json`]
				},

				// jsontype_invalid2
				{
					id: 'DD-0071',
					name: 'jsontype_invalid2', 
					type: 'negative',
					operation: `mutation {updateDocument (input: ${stringify(jsontype_invalid2)}) {validationErrors {error warning data} ${testfragments.propertyFragment}}}`, 
					assertResultIncludes: [`validation_error_invalid_json`]
				},


				// now delete the template document
				{
					id: 'DD-CLEANUP',
					name: 'delete base document',
					type: 'cleanup',
					operation: `mutation {deleteDocument (documentId: "<<documentId>>") {documentId}}`, 
				},

			]
		},

	],
}]

export default documentdetails

