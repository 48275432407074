import React from 'react'
import Assignment from '../../components/Assignment'
import AssignmentDetail from '../../components/AssignmentDetail'
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import * as i18 from 'i18next'


const AssignmentPreviewDialog = ({ task, assignment, properties, setShowPreviewDialog }) => {

	return (
		<Dialog open={true} fullWidth maxWidth="md" PaperProps={{sx: {height: '100%'}}} >
			<DialogContent>
		<Grid item id='assignment'>
			{ task.type === 'start' ?
				<AssignmentDetail
					rootparentType={'launchassignment'}
					assignment={assignment}
					designTime={false}
					disabled={false}
					preview={true}
					updateAssignment={()=>{}}	// updateAssignment is only needed for updating the route condition which doesn't exist on launch assignments
				/>
			:
				<Assignment
					assignment={assignment}
					properties={properties}
					designTime={false}
					disabled={true}
					preview={true}
				/>	
			}
		</Grid>
			</DialogContent>
			<DialogActions>
				<Button style={{paddingTop: 0, paddingBotton: 0}} onClick={()=>setShowPreviewDialog(false)} color="primary" variant="contained" >
					{i18.t('dialog_close')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default AssignmentPreviewDialog