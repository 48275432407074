import React from 'react'
import TaskInstanceView from '../containers/TaskInstanceView'
import { Grid, Box, Typography } from '@mui/material'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'


const WorkflowRuntime = ({ workflow, taskinstances }) => {
 
//	console.log('WorkflowRuntime')

	if (!workflow || !taskinstances) return null

	let taskinstances2 = cloneDeep(workflow.taskinstances)
	taskinstances2 = taskinstances2?.sort((taskinstanceId1, taskinstanceId2) => Date.parse(taskinstances?.[taskinstanceId1]?.updated) - Date.parse(taskinstances?.[taskinstanceId2]?.updated))
	
	return (			
		<Grid item >
			{taskinstances2?.map((taskinstanceId, index) => (
				<TaskInstanceView 
					key={index}
					workflowId={workflow.workflowId}
					taskinstanceId={taskinstanceId} 
				/>
			))}
		</Grid>
	)
}

export default WorkflowRuntime
