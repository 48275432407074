import React from 'react'
import { connect } from 'react-redux'
import { Grid, Tooltip, Button } from '@mui/material'

import SupportCase from '../components/SupportCase'

import { retrieveSupportCase, saveSupportCase, updateSupportCase, closeSupportCase } from '../reducers/supportcases'


const SupportCaseView = ({ navigation, supportcase, retrieveSupportCase, saveSupportCase, updateSupportCase, closeSupportCase }) => {

//	console.log('SupportCaseView')
	
	if (!supportcase)
		return null

	return (
		<SupportCase
			navigation={navigation}
			supportcase={supportcase}
			retrieveSupportCase={retrieveSupportCase}
			saveSupportCase={saveSupportCase}
			updateSupportCase={updateSupportCase}
			closeSupportCase={closeSupportCase}
		/>
	)
}

const mapStateToProps = (state, props) => ({
	...props,
	supportcase: state.supportcases[props.navigation.params.supportcaseId],
})

export default connect(mapStateToProps, { retrieveSupportCase, saveSupportCase, updateSupportCase, closeSupportCase })(SupportCaseView)
