import React from 'react'
import { Grid, FormControl, FormControlLabel, Radio } from '@mui/material'


// Runtime view of component
const PropertyListSingle = ({ nameTypeComponent, property, updateProperty, designTime, disabled }) => {
	
//	console.log('PropertyListSingle')
	
	return (
		<Grid container direction={'row'} alignItems={designTime ? 'flex-start' : 'center'} >

			<Grid item xs={6} md={5} xl={4}>
				{nameTypeComponent}
			</Grid>
			
			<Grid item style={{paddingLeft: 10}} >
				<FormControl>
					{property.enum.map((option, index) =>
						<FormControlLabel 
							key={index} 
							label={option} 
							control={
								<Radio 
									value={option ? option : ''} 
									checked={designTime ? property.default===option : property.value===option} 
									disabled={disabled} 
								/>
							}
							onChange={(event)=>{
								designTime ?
									property.default=event.target.value
								:						
									property.value=event.target.value
								updateProperty(property)
							}}
							disabled={disabled}
						/>
					)}
				</FormControl>
			</Grid>
		</Grid>
	)
}

export default (PropertyListSingle)
