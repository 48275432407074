import React, { useState } from 'react'
import { Grid, Button, Typography, TextField, Select, MenuItem, Tooltip, Divider } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

import TaskListView from '../containers/TaskListView'
import TaskRouteConditionOption from '../components/TaskRouteConditionOption'
import TaskRouteConditionExpression from '../components/TaskRouteConditionExpression'
import TaskRouteConditionElse from '../components/TaskRouteConditionElse'


const TaskRouteCondition = ({ me, workflowId, task, attachments, allProperties, documents, updateTask, tasktemplates, designTime }) => {

//	console.log('TaskRouteCondition')

	const [focus, setFocus] = useState()
	
	if (!task.routeCondition) {
		task.routeCondition = {
			quorum: 'any',
			options: [{name: i18.t('route_condition_option_prefix') + '1', type: 'nexttask'}],
		}
	}
	
	let conditions
	let numOptions = task.routeCondition.options.length

	if (task.type === 'user') {
		if(numOptions > 1)
			conditions=[
				{type: 'any', description: i18.t('route_condition_anyselect')},
				{type: 'all', description: i18.t('route_condition_allselect')}, 
				{type: 'majority', description: i18.t('route_condition_majorityselect')},
				{type: 'any+expr', description: i18.t('route_condition_expression')},
			]
		else // numOptions === 1
			conditions=[
				{type: 'any', description: i18.t('route_condition_anycomplete')},
				{type: 'all', description: i18.t('route_condition_allcomplete')},
				{type: 'majority', description: i18.t('route_condition_majoritycomplete')},
				{type: 'any+expr', description: i18.t('route_condition_expression')},
			]
	} else if (task.type === 'milestone') {
		conditions=[
			{type: 'expr', description: i18.t('route_condition_expression')}
		]
	} else if (task.type === 'parent') {
		conditions=[
			{type: 'all', description: i18.t('route_condition_allsubcomplete')},
			{type: 'all+expr', description: i18.t('route_condition_expression2')}
		]
	}
	
	let quorumIndex = 0
	if (conditions && task.routeCondition && task.routeCondition.quorum) {
		quorumIndex = conditions.findIndex(condition => condition.type === task.routeCondition.quorum)
	}

	return (
		<Grid item>
			<Grid container direction={numOptions > 1 || task.routeCondition.quorum === 'expr' || task.routeCondition.quorum === 'any+expr' || task.routeCondition.quorum === 'all+expr' || task.routeCondition.quorum === 'majority+expr' ? 'column' : 'row'} style={{cursor: 'default'}} >

				<Grid item >
					{ task.type === 'user' || task.type === 'milestone' || task.type === 'parent' ?

						<Grid container direction={'row'} alignItems="center" >
						
							<Typography style={{marginLeft: 5, fontWeight: "bold"}} >{i18.t('route_condition_if')}</Typography>

							<Select
								variant='standard'
								style={{marginLeft: 5}}
								value={quorumIndex}
								disabled={!designTime}
								onChange={(event) => {
									task.routeCondition.quorum = conditions[event.target.value].type
									task.routeCondition.options=[]
									if (['expr','any+expr','all+expr','majority-expr'].includes(task.routeCondition.quorum) && !task.routeCondition.defaultOption) {
										task.routeCondition.defaultOption = {type: 'nexttask'}
									}
									if (['any','all','majority'].includes(task.routeCondition.quorum) && (!task.routeCondition.options || task.routeCondition.options.length === 0)) {
										task.routeCondition.options = [{name: i18.t('route_condition_option_prefix') + '1', type: 'nexttask'}]
									}
									updateTask(workflowId, task)
								}}
							>
								{conditions?.map((condition, quorumIndex) => <MenuItem key={quorumIndex} value={quorumIndex} >{condition.description}</MenuItem> )}
							</Select>
							
						</Grid>
			
					: null }
				</Grid>

				{(['any','majority'].includes(task.routeCondition.quorum) && (!task.routeCondition.options || task.routeCondition.options.length===0)) ?

						<Typography>{i18.t('route_condition_no_routes')}</Typography>

				: ['expr','any+expr','all+expr','majority-expr'].includes(task.routeCondition.quorum) && task.routeCondition.options ? task.routeCondition.options.map((option, index) =>

					<Grid item key={index}>
						<TaskRouteConditionExpression me={me} workflowId={workflowId} task={task} index={index} attachments={attachments} allProperties={allProperties} documents={documents} focus={focus} updateTask={updateTask} designTime={designTime} setFocus={setFocus} />
					</Grid>

				) : ['any','all','majority'].includes(task.routeCondition.quorum) && task.routeCondition.options ? task.routeCondition.options.map((option, index) =>

					<Grid item key={index}>
						<TaskRouteConditionOption workflowId={workflowId} task={task} rcIndex={index} updateTask={updateTask} designTime={designTime} />
					</Grid>

				) : null }
			</Grid>
		
		
			{ ['expr','any+expr','all+expr','majority-expr'].includes(task.routeCondition.quorum) ?
				<Grid item>
					<TaskRouteConditionElse workflowId={workflowId} task={task} updateTask={updateTask} designTime={designTime} />
					<Divider style={{marginTop: 5, marginBottom: 5}}/>
				</Grid>
			: null }

			{ designTime ?
				['expr','any+expr','all+expr','majority-expr'].includes(task.routeCondition.quorum) ?
					<Grid container direction="column">
						<Tooltip title={i18.t('route_condition_add_expr_tooltip')} >
							<div>
								<AddCircle
									disabled={!designTime}
									style={{cursor: 'default', height: 20, width: 20}}
									onClick={()=>{task.routeCondition.options.push({expression: me.expressionTypes[me.expressionTypes.findIndex(expr => expr.typeId === 'cop::eq')], type: 'nexttask'});updateTask(workflowId, task);}} 
								/>
							</div>
						</Tooltip>
					</Grid>
				: task.type !== 'parent' ?
					<Grid container direction="column">
						<Tooltip title={i18.t('route_condition_add_route_tooltip')} >
							<div>
								<AddCircle 
									disabled={!designTime}
									style={{cursor: 'default', height: 20, width: 20}}
									onClick={()=>{task.routeCondition.options.push({name: i18.t('route_condition_option_prefix') + (task.routeCondition.options.length + 1), type: 'nexttask'});updateTask(workflowId, task);}} 						
								/>
							</div>
						</Tooltip>
					</Grid>
				: null
			: null }
			
		</Grid>
	)

}

export default TaskRouteCondition