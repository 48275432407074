import workflow_milestone from './workflow_milestone.json'
import testfragments from './testfragments'
import cloneDeep from 'lodash.clonedeep'


const stringify = (str) => {
	return JSON.stringify(str).replace(/"(\w+)"\s*:/g, '$1:')
}

let workflow = {...workflow_milestone, workflowId: "<<workflowId>>"}


// Milestones

// WFM-0001
var emptymilestones = cloneDeep(workflow)

// WFM-0002
var addtagmilestone = cloneDeep(workflow)
addtagmilestone.tasks[0].tasks[0].milestoneActions = []
addtagmilestone.tasks[0].tasks[0].milestoneActions.push(testfragments.milestoneAddTag)

// WFM-0003
var nulltypemilestone = cloneDeep(workflow)
nulltypemilestone.tasks[0].tasks[0].milestoneActions = []
nulltypemilestone.tasks[0].tasks[0].milestoneActions.push(testfragments.milestoneNullType)

// WFM-0004
var invalidtypemilestone = cloneDeep(workflow)
invalidtypemilestone.tasks[0].tasks[0].milestoneActions = []
invalidtypemilestone.tasks[0].tasks[0].milestoneActions.push(testfragments.milestoneInvalidType)

// WFM-0005
var nullexpressionmilestone = cloneDeep(workflow)
nullexpressionmilestone.tasks[0].tasks[0].milestoneActions = []
nullexpressionmilestone.tasks[0].tasks[0].milestoneActions.push(testfragments.milestoneNullExpression)



const workflowmilestones = [{
	name: "Workflow milestones test collection",
	tests: [

// Task Who

		{
			// create draft workflow from base workflow template and test various updates
			id: 'WFM-SETUP',
			name: 'create base workflow.',
			type: 'setup',
			operation: `mutation {createWorkflow (input: ${stringify(workflow_milestone)}) {workflowId state}}`,
			assertResultIncludes: [`"state":"draft"`],
			onEachItem: [

				// empty milestones
				{
					id: 'WFM-0001',
					name: 'empty milestones', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(emptymilestones)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResultIncludes: `validation_warning_no_milestone_actions`
				},

				// add tag milestone
				{
					id: 'WFM-0002',
					name: 'add tag milestone', 
					type: 'positive',
					operation: `mutation {updateWorkflow (input: ${stringify(addtagmilestone)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResult: `{"validationErrors":[],"tasks":[{"assignees":null,"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":[{"assignees":{"validationErrors":null},"routeCondition":{"options":[],"validationErrors":null},"validationErrors":[],"tasks":null},{"assignees":{"validationErrors":null},"routeCondition":{"options":[{"validationErrors":null}],"validationErrors":null},"validationErrors":[],"tasks":null}]},{"assignees":null,"routeCondition":null,"validationErrors":[],"tasks":null}]}`
				},

				// null type milestone
				{
					id: 'WFM-0003',
					name: 'null type milestone', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(nulltypemilestone)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`In field \\"milestoneActions\\": In element #0: In field \\"type\\": Expected \\"String!\\", found null.`]
				},

				// invalid type milestone
				{
					id: 'WFM-0004',
					name: 'invalid type milestone', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(invalidtypemilestone)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`validation_error_invalid_milestone`]
				},

				// null expression milestone
				{
					id: 'WFM-0005',
					name: 'null expression milestone', 
					type: 'negative',
					operation: `mutation {updateWorkflow (input: ${stringify(nullexpressionmilestone)}) {validationErrors {error warning data} ${testfragments.taskFragment}}}`, 
					assertResultIncludes: [`validation_error_invalid_milestone`]
				},

				// now delete the draft workflow
				{
					id: 'WFM-CLEANUP',
					name: 'delete base workflow', 
					type: 'cleanup',
					operation: `mutation {deleteWorkflow (workflowId: "<<workflowId>>") {validationErrors {error warning data}}}`, 
					assertResult: `{"validationErrors":null}`
				},
			]
		},

	],
}]

export default workflowmilestones

