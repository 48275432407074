import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import * as i18 from 'i18next'


const AttachmentAnalyzeFailDialog = ({ documentId, createExtract, closeDialog }) => {

//	console.log('AttachmentAnalyzeFailDialog')

	const [selection, setSelection] = useState("1")

	return (
			<Dialog style={{flex: 1}} open={true} >
				<DialogTitle><b>{i18.t('dialog_unable_to_extract_data_title')}</b></DialogTitle>
				<DialogContent style={{flex: 1}}>

					{i18.t('dialog_unable_to_extract_data_desc')}
			
				</DialogContent>
				<DialogActions>
					<Button 
						onClick={()=> {
							createExtract(documentId)
							closeDialog()
						}}
						color="primary"
					>
						{i18.t('dialog_yes')}
					</Button>
					<Button onClick={()=>closeDialog()} color="secondary">
						{i18.t('dialog_no')}
					</Button>
				</DialogActions>
			</Dialog>	
	)
}

export default AttachmentAnalyzeFailDialog