import React, { useState } from 'react'
import { Grid, Tooltip, Icon } from '@mui/material'
import { DragIndicator, RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'

import AttachmentView from '../containers/AttachmentView'

 
const AttachmentListItem = ({ workflowId, rootparentType, rootparentId, rootparentState, attachmentId, focusId, contacts, designTime, formProp=false, disabled, 
								setFocusId, deleteAttachment }) => {

//	console.log('AttachmentListItem', rootparentId, attachmentId)

	if (!rootparentId || !attachmentId) return null

	const [hover, setHover] = useState(false)

	let hasFocus = (attachmentId === focusId)

	let borderLeft
	if (designTime && hasFocus) {
		borderLeft = '2px solid blue'
	} else if (designTime && !hasFocus) {
		borderLeft = '.5px solid lightgrey'
	}

	return (
		<Grid container 
			style={{paddingTop: 10, paddingBottom: 10}}
			direction={'row'} 
			alignItems="center" 
			wrap="nowrap"
			onMouseMove={() => {if(!hover){setHover(true)}}}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>			
								
			{ (designTime || formProp) && hover ?
				<Tooltip title={i18.t('property_tooltip_drag_property')} >
					<DragIndicator style={{cursor: 'grab'}}/>
				</Tooltip>
			: 
				<Icon/>
			}
			
			<Grid 
				item
				style={{flex: 1, cursor: document.body.style.cursor, borderLeft: borderLeft}} 
				onClick={()=>setFocusId(attachmentId)} 
			>
				<AttachmentView 
					workflowId={workflowId}
					rootparentType={rootparentType}
					rootparentId={rootparentId} 
					rootparentState={rootparentState}
					attachmentId={attachmentId}
					designTime={designTime}
					disabled={disabled}
				/>					
			</Grid>

			{ (designTime || formProp) && hover ?
				<RemoveCircle 
					style={{cursor: 'default', height: 20, width: 20}}
					onClick={(e)=>{
						deleteAttachment(attachmentId)
						setFocusId(null)
						e.stopPropagation()
					}}
				/>	
			: <Icon/> }
			
		</Grid>
	)
}

export default AttachmentListItem