import React, { useState } from 'react'
import { Select, TextField, MenuItem, Avatar, Typography } from '@mui/material'
import { red, green } from '@mui/material/colors'
import * as i18 from 'i18next'


const validEmail = (email) => {
	if (!email) return true // empty email is allowed
	return new RegExp(`^(\\D)+(\\w)*((\\.(\\w)+)?)+@(\\D)+(\\w)*((\\.(\\D)+(\\w)*)+)?(\\.)[a-z]{2,}$`, 'g').test(email)
}

const MemberSelect = ({ initialValue, targetList, contacts, clearAfterSelect=false, designTime, disabled, update }) => {

	const [textValue, setTextValue] = useState(initialValue ? initialValue : '')

	const targetEmails = targetList?.map(target => target?.grantee?.email)
	
	contacts = contacts?.filter(contact => !targetEmails?.includes(contact.email))

	return (
		<Select
			disabled={disabled}
			variant='standard'
			fullWidth
			value={' '}
			onChange={(e)=>{update(e.target.value);setTextValue(clearAfterSelect ? null : e.target.value);}}
			renderValue={(selected) => {
				return (<TextField
					disabled={!designTime}
					variant='standard'
					value={textValue ? textValue : ''}
					placeholder={i18.t('member_select_placeholder')}
					fullWidth
					InputProps={{disableUnderline: true, style: {backgroundColor: !validEmail(textValue) ? red[50] : null}}} 
					onMouseDown={(e)=>{e.stopPropagation()}}
					onChange={(e)=>{setTextValue(e.target.value);}}
					onKeyDown={(e)=>{
						if (e.keyCode === 32) {e.stopPropagation()}		// space key
						if (e.keyCode === 13) {							// return key
							e.stopPropagation()
							update(e.target.value)
							setTextValue(clearAfterSelect ? null : e.target.value)
						}
					}}
				/>
			)}}		
		>
			<MenuItem value={' '} disabled selected hidden >{i18.t('member_select_choose')}</MenuItem>
			{ contacts.map(contact =>
				<MenuItem key={contact?.email} value={contact?.email} >
					<Avatar style={{marginRight: 5, width: 30, height: 30}} src={contact?.thumbnailPhotoUrl ? contact?.thumbnailPhotoUrl : ''} />
					<Typography style={{marginRight: 5}}>{contact?.email}</Typography>
				</MenuItem>
			)}
		</Select>
	)
}
export default MemberSelect