import React, { useState } from "react";
import { connect } from 'react-redux'
import * as i18 from 'i18next'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

import { store } from '../App'
import MainMenu from '../components/MainMenu'
import Main from '../components/Main'
import MainAppBar from '../components/MainAppBar'
import TermsAndConditionsDialog from '../components/dialogs/TermsAndConditionsDialog'

import { Grid, Typography, Button, Hidden, Divider } from '@mui/material'
import { navigate, pop, reset, closeError, setScope } from '../reducers/appnav'
import { acceptTerms, retrieveMe, updateMe, updateScope, saveMe, retrieveRoles, retrieveContacts } from '../reducers/app'
import { createExtract, updateExtract, deleteExtract } from '../reducers/extracts'
import { retrieveOrgs, retrieveOrg, updateOrg, saveOrg } from '../reducers/orgs'
import { createWorkflow, retrieveWorkflows, retrieveAllWorkflowTags } from '../reducers/workflows'
import { retrieveDocuments } from '../reducers/documents'
import { retrieveAssignments } from '../reducers/assignments'
import { retrieveSupportCases } from '../reducers/supportcases'
import { retrieveActions } from '../reducers/actions'
import { retrieveEvents } from '../reducers/events'
import { retrieveAutomations } from '../reducers/automations'
import { retrieveExtracts } from '../reducers/extracts'
import { executeTests } from '../reducers/testing'

/*
function getParameterByName(location, name) {
    name = name.replace(/[[\]]/g, '\\$&')
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
	results = regex.exec(location.search)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
*/

const MainView = ({ location, appnav, app, termsAccepted, assignments, orgs, supportcases, documents, orgproperties, supportdata, 
					navigate, pop, reset, acceptTerms,  retrieveMe, updateMe, updateScope, saveMe, retrieveOrgs, retrieveOrg, updateOrg, saveOrg,
					createWorkflow, retrieveWorkflows, retrieveDocuments, retrieveAllWorkflowTags, retrieveAssignments, retrieveActions, retrieveEvents,
					retrieveAutomations, retrieveSupportCases, retrieveExtracts, retrieveRoles, retrieveContacts,
					executeTests, closeError }) => {
		
//	console.log('MainView')

	const [mainMenuIsOpen, setMainMenuIsOpen] = useState(true)

	let navigation = {
		navigate: navigate, 
		pop: pop, 
		reset: reset, 
	}
	
	let stack = appnav.main ? appnav.main.stack : null
		
	const [initialized1, setInitialized1] = useState()
	const [initialized2, setInitialized2] = useState()
	const [tagFilter, setTagFilter] = useState([])
	const [scopeIndex, setScopeIndex] = useState(0)

//	if (appnav?.main?.errors && appnav?.main?.errors.length > 0 && appnav.main.stack[appnav.main.stack.length-1].screen !== 'errors') {
//		navigate('errors')
//	}

	const showSettings = ['settings', 'profile', 'settings_list', 'propertydefinition_list', 'extract_list', 'automation_list', 
							'graphiql', 'test', 'supportcase_list', 'supportcase_working_list', 'supportcase_completed_list'].includes(stack[1]?.screen)
							
	let propertyTags = []
	if (stack?.[0]?.screen === 'propertydefinition_list') {
		for (const propId of app.me?.properties) {
			let prop = orgproperties[propId]
			if (prop.tags) {
				propertyTags = [...propertyTags, ...prop.tags]
			}
		}
		propertyTags = [...new Set(propertyTags)]
	}

	let tags
	switch (stack?.[1]?.screen) {
		case 'assignment_working_list': tags = app.workingAssignmentsTagList ? app.workingAssignmentsTagList : []; break
		case 'assignment_completed_list': tags = app.completedAssignmentsTagList ? app.completedAssignmentsTagList : []; break
		case 'document_working_list': tags = app.workingDocumentsTagList ? app.workingDocumentsTagList : []; break
		case 'document_template_list': tags = app.templateDocumentsTagList ? app.templateDocumentsTagList : []; break
		case 'workflow_draft_list': tags = app.draftWorkflowsTagList ? app.draftWorkflowsTagList : []; break
		case 'workflow_working_list': tags = app.workingWorkflowsTagList ? app.workingWorkflowsTagList : []; break
		case 'workflow_completed_list': tags = app.completedWorkflowsTagList ? app.completedWorkflowsTagList : []; break
		case 'workflow_template_list': tags = app.templateWorkflowsTagList ? app.templateWorkflowsTagList : []; break
		case 'supportcase_working_list': tags = app.openSupportCasesTagList ? app.openSupportCasesTagList : []; break
		case 'supportcase_completed_list': tags = app.closedSupportCasesTagList ? app.closedSupportCasesTagList : []; break
		case 'extract_list': tags = app.extractsTagList ? app.extractsTagList : []; break
		case 'propertydefinition_list': tags = propertyTags; break
	}

	let refreshList = ()=>{}
	switch (stack?.[1]?.screen) {
		case 'assignment_working_list': refreshList = (tagFilter) => retrieveAssignments('workingAssignments', 0, tagFilter); break
		case 'assignment_completed_list': refreshList = (tagFilter) => retrieveAssignments('completedAssignments', 0, tagFilter); break
		case 'document_working_list': refreshList = (tagFilter) => retrieveDocuments('workingDocuments', 0, tagFilter); break
		case 'document_template_list': refreshList = (tagFilter) => retrieveDocuments('templateDocuments', 0, tagFilter); break
		case 'workflow_draft_list': refreshList = (tagFilter) => retrieveWorkflows('draftWorkflows', 0, tagFilter); break
		case 'workflow_working_list': refreshList = (tagFilter) => retrieveWorkflows('workingWorkflows', 0, tagFilter); break
		case 'workflow_completed_list': refreshList = (tagFilter) => retrieveWorkflows('completedWorkflows', 0, tagFilter); break
		case 'workflow_template_list': refreshList = (tagFilter) => retrieveWorkflows('templateWorkflows', 0, tagFilter); break
		case 'supportcase_working_list': refreshList = (tagFilter) => retrieveSupportCases('openSupportCases', 0, tagFilter); break
		case 'supportcase_completed_list': refreshList = (tagFilter) => retrieveSupportCases('closedSupportCases', 0, tagFilter); break
		case 'extract_list': refreshList = (tagFilter) => retrieveExtracts('extracts', 0, tagFilter); break
	}

	const googleSession = JSON.parse(localStorage.getItem('googleSession'))
	
	if (googleSession) {

		if (!initialized1) {
			retrieveMe(true)
			navigation.reset('assignment_working_list')			
			setInitialized1(true)
		}

		if (initialized1 && termsAccepted && !initialized2) {

			setInitialized2(true)

			retrieveAssignments('workingAssignments', 0, tagFilter)
			retrieveWorkflows('draftWorkflows', 0, tagFilter)
			retrieveWorkflows('templateWorkflows', 0, tagFilter)
			retrieveWorkflows('workingWorkflows', 0, tagFilter)
			retrieveDocuments('workingDocuments', 0, tagFilter)
			retrieveDocuments('templateDocuments', 0, tagFilter)
			retrieveExtracts('extracts', 0, tagFilter)
			retrieveOrgs()
			retrieveSupportCases('openSupportCases', 0, tagFilter)

			retrieveAssignments('completedAssignments', 0, tagFilter)
			retrieveWorkflows('completedWorkflows', 0, tagFilter)
			retrieveSupportCases('closedSupportCases', 0, tagFilter)
			retrieveAllWorkflowTags()

			retrieveRoles()
			retrieveActions()			
			retrieveEvents()
			retrieveAutomations()

			retrieveContacts(true)
		}
	}

/*
	// Check for a query string and route accordingly (initially we only have assignment links)
	let linkId = getParameterByName(location, 'assignment')
	
	if (linkId && assignments && Object.keys(assignments).length > 0) {
		let keys = assignments ? Object.keys(assignments) : null
		let assignmentId = keys ? keys.find(key => assignments[key].linkId === linkId) : null
		if (assignmentId) {
			stack = [
				{screen: assignments[key].state === 'working' ? 'assignment_working_list' : 'assignment_completed_list', params: {}}, 
				{screen: 'assignment', params: {assignmentId: assignmentId}},
			]
		}
	}
*/

	const name = null
	
	const keys = Object.keys(orgs)
	let scopes = keys.map(key => orgs[key])
	scopes.splice(0,0,app.me)

	let mainViewHeight = document.getElementById('main-view')?.clientHeight ? document.getElementById('main-view')?.clientHeight : 0
	let mainViewWidth = document.getElementById('main-view')?.clientWidth ? document.getElementById('main-view')?.clientWidth : 0
	let mainViewAppbarHeight = document.getElementById('main-view-appbar')?.clientHeight ? document.getElementById('main-view-appbar')?.clientHeight : 0

	let mainViewRowHeight = mainViewHeight - mainViewAppbarHeight	
	let mainViewRowWidth = mainViewWidth							// Not sure why we need -1, rounding issue on width???

	const theme = useTheme()
	const xs = useMediaQuery(theme.breakpoints.only('xs'))

	const setTagFilter2 = async (tagFilter)=>{await refreshList(tagFilter);setTagFilter(tagFilter);}

	const [size, setSize] = useState()
	window.addEventListener('resize', setSize)

	// Not sure why we need -1 on the calc(100vh/100vw), rounding issue on height???
	return (
			<Grid item id='main-view' style={{width: 'calc(100vw - 1px)', height: 'calc(100vh - 1px)'}} overflow={'hidden'} >

				<MainAppBar  
					stack={stack}
					navigation={navigation}
					xs={xs}
					app={app}
					appnav={appnav}
					scopes={scopes}
					scopeIndex={scopeIndex}
					mainMenuIsOpen={mainMenuIsOpen}
					showSettings={showSettings}
					setScopeIndex={setScopeIndex}
					setMainMenuIsOpen={setMainMenuIsOpen}
					updateMe={updateMe}
					updateScope={updateScope}
				/>
			
				{ !googleSession ?

					<Grid container id='main-view-row' direction='row' style={{height: mainViewRowHeight}} >
						<Typography variant="h6" >{i18.t('main_view_uninitialized')}</Typography>
					</Grid>

				:
					
					<Grid container id='main-view-row' direction='row' style={{height: mainViewRowHeight}} >
						
						{ !termsAccepted && !appnav.main.errors?.length > 0 ?
								<TermsAndConditionsDialog 
									me={app.me} 
									acceptTerms={acceptTerms}
								/>
						: xs ?
							(stack[stack.length-1]?.screen === 'main_menu') ?
								<Grid 
									item 
									id='outermain-menu' 
									style={{borderLeft: '5px solid transparent', borderRight: '2px solid lightgrey', borderTop: '5px solid transparent', borderBottom: '5px solid transparent', width: mainViewRowWidth, height: mainViewRowHeight}} 
								>
									<MainMenu 
										stack={stack}
										navigation={navigation}
										showSettings={showSettings}
										app={app}
										tags={tags}
										tagFilter={tagFilter}
										createWorkflow={createWorkflow}
										setTagFilter={setTagFilter2}
									/>
								</Grid>
							:
								<Grid item id='outermain' style={{border: '5px solid transparent', width: mainViewRowWidth, height: mainViewRowHeight}} >
									<Main 
										stack={stack} 
										navigation={navigation}
										scope={scopes ? scopes[scopeIndex] : null}
										tags={tags}
										tagFilter={tagFilter} 
										app={app}
										orgs={orgs}
										supportcases={supportcases}
										documents={documents}
										setTagFilter={setTagFilter2}
										retrieveOrg={retrieveOrg}
										updateOrg={updateOrg}
										saveOrg={saveOrg}
										retrieveMe={retrieveMe}
										updateMe={updateMe}
										saveMe={saveMe}
										executeTests={executeTests}
									/>
								</Grid>
						:
							<Grid container id='main-view-row2' direction='row' style={{height: mainViewRowHeight}} >

								{ mainMenuIsOpen ?
									<Grid 
										item 
										id='outermain-menu' 
										style={{borderLeft: '5px solid transparent', borderRight: '2px solid lightgrey', borderTop: '5px solid transparent', borderBottom: '5px solid transparent', width: (mainViewRowWidth * .2), height: mainViewRowHeight}} 
									>
										<MainMenu 
											stack={stack}
											navigation={navigation}
											showSettings={showSettings}
											app={app}
											tags={tags}
											tagFilter={tagFilter}
											createWorkflow={createWorkflow}
											setTagFilter={setTagFilter2}
										/>
									</Grid>
								: null}

								<Grid item id='outermain' style={{border: '5px solid transparent', width: (mainViewRowWidth * .8), height: mainViewRowHeight}} >
									<Main 
										stack={stack} 
										navigation={navigation}
										scope={scopes ? scopes[scopeIndex] : null}
										showSettings={showSettings}
										tagFilter={tagFilter} 
										app={app}
										orgs={orgs}
										supportcases={supportcases}
										documents={documents}
										setTagFilter={setTagFilter2}
										retrieveOrg={retrieveOrg}
										updateOrg={updateOrg}
										saveOrg={saveOrg}
										retrieveMe={retrieveMe}
										updateMe={updateMe}
										saveMe={saveMe}
										executeTests={executeTests}
									/>
								</Grid>

							</Grid>
						}
					</Grid>
				}
			</Grid>
	)
}

const mapStateToProps = (state, props) => ({
	app: state.app,
	appnav: state.appnav,
	modified: state.app.modified,							// TBD - Not sure why this is needed, should refresh from app updating??????
	termsAccepted: state.app.me?.terms?.acceptedDate,
	assignments: state.assignments,
	orgs: state.orgs,
	supportcases: state.supportcases,
	documents: state.documents,
	orgproperties: state.properties?.[state.app.me?.orgentityId],
})

export default connect(mapStateToProps, { navigate, pop, reset, acceptTerms, retrieveMe, updateMe, updateScope, saveMe, retrieveOrgs, retrieveOrg, updateOrg, saveOrg,
										createWorkflow, retrieveWorkflows, retrieveDocuments, retrieveAllWorkflowTags, retrieveAssignments, retrieveActions, retrieveEvents,
										retrieveSupportCases, retrieveExtracts, retrieveAutomations, retrieveRoles, retrieveContacts,
										executeTests, closeError })(MainView)

