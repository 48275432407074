import React from 'react'
import { connect } from 'react-redux'
import AssignmentInstance from '../components/AssignmentInstance'
import { setExpanded } from '../reducers/appnav'


const AssignmentInstanceView = ({ assignment, expanded=false, setExpanded }) => {

//	console.log('AssignmentInstanceView')

	if (!assignment) return null

	return (
		<AssignmentInstance
			assignment={assignment}
			expanded={expanded}
			setExpanded={setExpanded}
		/>	
	)
		
}

const mapStateToProps = (state, props) => ({
	...props,
	expanded: state.appnav.expanded?.[props.assignment.assignmentId],
})

export default connect(mapStateToProps, { setExpanded })(AssignmentInstanceView)
