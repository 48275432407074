import React, { useState } from 'react'
import { Select, ListSubheader, ListItem, ListItemText, Typography } from '@mui/material'
import * as i18 from 'i18next'

import { getUniqueId } from '../reducers/app'


const exprDisplayText = (expr) => {
	if (expr && expr.typeId) {
		if (expr.typeId.startsWith('ref::')) {
			return expr.value
		} else {
			return i18.t(`expression_${expr.typeId}`.replace(/[^A-Za-z0-9]/ig, "_"))
		}
	}
}

const getPropertyName = (propId, properties) => {
	let name = properties[propId].pname
	let parentpropertyId = properties[propId].parentpropertyId
	while (parentpropertyId) {
		name = properties[parentpropertyId].pname + '.' + name
		parentpropertyId = properties[parentpropertyId].parentpropertyId
	}
	return name
}


const Expression = ({ rootparentId, expressionTypes, strictMode=false, expression, type, subType, groupFilter=["const","func","lop","cop","aop","ass"], attachments, allProperties, documents, designTime, disabled, 
						setExpression }) => {
	
//	console.log('Expression')

	const properties = allProperties?.[rootparentId]
	const propertyIds = Object.keys(properties ? properties : [])
	let items = [...expressionTypes ? expressionTypes : [], ...propertyIds.filter(propId => properties[propId].subType !== 'object').map(propId => ({typeId: `ref::property`, value: `${getPropertyName(propId, properties)}`, type: properties[propId].type, subType: properties[propId].subType}))]

	const attachmentIds = Object.keys(attachments ? attachments : [])
	for (const attachmentId of attachmentIds) {
		const attachment = attachments[attachmentId]
		const properties = allProperties[attachment.defaultDocumentId]
		const propertyIds = properties ? Object.keys(properties) : []
		items = [...items, ...propertyIds.filter(propId => properties[propId].subType !== 'object').map(propId => ({typeId: `ref::attachment`, value: `${attachment.pname}.${getPropertyName(propId, properties)}`, type: properties[propId].type, subType: properties[propId].subType}))]
	}
		
	if (type) {
		if (strictMode) {
			items = items.filter(item => item.typeId.startsWith('const') || item.typeId.startsWith('aop') || item.typeId.startsWith('func') ? item.type === type : item.subType === subType || item.type === subType)
		} else {
			items = items.filter(item => item.type === type)
		}
	}
	
	let groups = [...new Set(items.map(expr => expr.typeId.split('::')[0]))]

	if (groupFilter) {
		groups = groups.filter(group => groupFilter.includes(group))
	}

	let result = []

	result.push(
		<Select
			variant='standard'
			disabled={disabled ? disabled : false}
			key={getUniqueId()}
			style={{minWidth: 60}}
			disableUnderline
			native={false}
			displayEmpty={true}
			renderValue={() => (<Typography>{exprDisplayText(expression)}</Typography>)}
		>
			{groups.map((group, index) => (
				<li key={getUniqueId()} >
					<ul>
						<ListSubheader inset>{group}</ListSubheader>
							{ items.filter(expr => expr?.typeId?.split('::')[0] === group).map((expr, index2) => (
								<ListItem
									key={`${index}::${index2}`}
									value={expr}
									selected={JSON.stringify(expression) === JSON.stringify(expr)}
									onClick={(e)=>{setExpression(expr);}}
									button
								>
									<ListItemText>{exprDisplayText(expr)}</ListItemText>
								</ListItem>
							))}
					</ul>
				</li>
			))}
		</Select>
	)

	return result
}


export default Expression