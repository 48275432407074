import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { RemoveCircle } from '@mui/icons-material'
import * as i18 from 'i18next'
import cloneDeep from 'lodash.clonedeep'

import Expression from '../components/Expression'
import ExpressionConst from '../components/ExpressionConst'
import ExpressionTuple from '../components/ExpressionTuple'
import { getUniqueId } from '../reducers/app'

const exprDisplayText = (expr) => {
	if (expr && expr.typeId) {
		if (expr.typeId.startsWith('ref::')) {
			return expr.typeId.split('::')[1]
		} else {
			return i18.t(`expression_${expr.typeId}`.replace(/[^A-Za-z0-9]/ig, "_"))
		}
	}
}

const ExpressionFunc = ({ me, expressionParentId, rootparentId, expressionTypes, strictMode=false, expression, attachments, allProperties, 
							documents, groupFilter, hideLabel=false, focus, hover, designTime, disabled, 
							setExpression, setFocus }) => {
	
//	console.log('ExpressionFunc')

	if (!expression) return null

	let expressionType = expressionTypes?.filter(expr => expr.typeId === expression.typeId)?.[0]
	
	let result = []

	if (!hideLabel) {
		result.push(<Typography key={getUniqueId()} >{exprDisplayText(expression)}</Typography>)
	}

	result.push(<Typography key={getUniqueId()} style={{fontSize: 24}} >&nbsp;(</Typography>)
	
	if (!expression.params) {expression.params = cloneDeep(expressionType.params)}

	for (let i=0; i<expressionType?.params?.length; ++i) {
		if (!expression.params) expression.params=[]
		if (!expression.params[i]) expression.params[i] = cloneDeep(expressionType.params[i])
		let setExpr = (expr) => {
			expression.params[i] = cloneDeep(expr)
			setExpression(expression)
		}

		let paramType = expression.params?.[i]?.type
		if (strictMode && expression.params?.[i]?.subType) {
			paramType = expression.params?.[i]?.subType
		}
		
		result.push(
			expression.params[i]?.typeId?.startsWith('cop') || expression.params[i]?.typeId?.startsWith('lop') || expression.params[i]?.typeId?.startsWith('aop')  ?
				<ExpressionTuple key={getUniqueId()} me={me} expressionParentId={expressionParentId + `::${i}`} rootparentId={rootparentId} expressionTypes={expressionTypes} expression={expression.params[i]} attachments={attachments} allProperties={allProperties} documents={documents} typeId={expression.params?.[i]?.typeId} groupFilter={groupFilter} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setExpr} onDelete={()=>setExpression(null)} setFocus={setFocus} />
			: expression.params[i]?.typeId?.startsWith('func') ?
				<ExpressionFunc key={getUniqueId()} me={me} expressionParentId={expressionParentId + `::${i}`} rootparentId={rootparentId} expressionTypes={expressionTypes} expression={expression.params[i]} attachments={attachments} allProperties={allProperties} documents={documents} groupFilter={groupFilter} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setExpr} setFocus={setFocus} />
			: expression.params[i]?.typeId?.startsWith('const') ?
				<ExpressionConst key={getUniqueId()} me={me} expressionParentId={expressionParentId + `::${i}`} strictMode={strictMode} expression={expression.params[i]} allProperties={allProperties} focus={focus} hover={hover} designTime={designTime} disabled={disabled} setExpression={setExpr} setFocus={setFocus} />
			: 
				<Expression key={getUniqueId()} rootparentId={rootparentId} expressionTypes={expressionTypes} expression={expression.params[i]} type={paramType} groupFilter={groupFilter} attachments={attachments} allProperties={allProperties} documents={documents} designTime={designTime} disabled={disabled} setExpression={setExpr} />
		)
	}

	result.push(<Typography key={getUniqueId()} style={{fontSize: 24}} >)</Typography>)

	designTime && expression && hover ?
		result.push(<RemoveCircle style={{cursor: 'default', height: 20, width: 20}} key={getUniqueId()} onClick={()=>setExpression(null)} />)
	: null

	return result
}

export default ExpressionFunc
