import React from 'react'
import { connect } from 'react-redux'
import TaskInstance from '../components/TaskInstance'
import { setExpanded } from '../reducers/appnav'


const TaskInstanceView = ({ me, workflowId, taskinstance, taskinstances, assignments, expanded=false, setExpanded }) => {

//	console.log('TaskInstanceView')

	if (!taskinstance) return null

	return (
		<TaskInstance
			me={me}
			workflowId={workflowId}
			taskinstance={taskinstance}
			taskinstances={taskinstances}
			assignments={assignments}
			expanded={expanded}
			setExpanded={setExpanded}
		/>	
	)
		
}

const mapStateToProps = (state, props) => ({
	...props,
	me: state.app.me,
	taskinstance: state.taskinstances[props.workflowId][props.taskinstanceId],
	taskinstances: state.taskinstances[props.workflowId],
	assignments: state.assignments,
	expanded: state.appnav.expanded?.[props.taskinstanceId],
})

export default connect(mapStateToProps, { setExpanded })(TaskInstanceView)
