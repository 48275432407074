import { normalize, denormalize } from 'normalizr'
import UUID from 'uuidjs'
import cloneDeep from 'lodash.clonedeep'

import { propertySchema } from '../reducers/workflows'

import { RECEIVE_WORKFLOWS, RECEIVE_WORKFLOW, RECEIVE_ORGS, RECEIVE_ME, RECEIVE_ASSIGNMENTS, RECEIVE_ASSIGNMENT, CREATE_LAUNCH_ASSIGNMENT } from './actiontypes'
import { WORKFLOW_ADD_PROPERTY, MOVE_PROPERTY_EVENT} from './actiontypes'
import { ADD_PROPERTY_EVENT, DELETE_PROPERTY_EVENT, RECEIVE_PROPERTIES, RECEIVE_DOCUMENT, RECEIVE_DOCUMENTS } from './actiontypes'
import { ADD_COMPOSITE_PROPERTY_EVENT, DELETE_COMPOSITE_PROPERTY_EVENT, MOVE_COMPOSITE_PROPERTY_EVENT } from './actiontypes'
import { MOVE_TO_COMPOSITE_PROPERTY_EVENT, RESET_COMPOSITE_PROPERTY_EVENT, UPDATE_PROPERTY_EVENT, CHANGE_PROPERTY_TYPE_EVENT } from './actiontypes'
import { RECEIVE_EXTRACTS, RECEIVE_EXTRACT, ADD_EXTRACT_PROPERTY } from './actiontypes'
import { store } from '../App'



// Property Functions

export const addProperty = (rootparentId, property) => dispatch => {
	dispatch({type: ADD_PROPERTY_EVENT, rootparentId: rootparentId, property: property})
}

export const addWorkflowProperty = (workflowId, newTypeId='string', defaultValue) => dispatch => {

	let properties = store.getState().properties
	let member = store.getState().app.me	// TBD - handle other org selections???
	let orgProperties = properties[member.orgentityId]

	let dnProps = denormalize(Object.keys(orgProperties), [propertySchema], {properties: orgProperties});

	let index = dnProps.findIndex(prop => prop.subType === newTypeId)
	let newProp = {...dnProps[index]}
	newProp.propertyId = UUID.genV4().toString()
	newProp.default = defaultValue

	setNewPropertyIds(newProp.properties)	
	
	let propertydata = normalize([newProp], [propertySchema])

	dispatch({type: WORKFLOW_ADD_PROPERTY, workflowId: workflowId, propertylist: propertydata.result, properties: propertydata.entities.properties})
	
	return newProp.propertyId
}

export const deleteProperty = (rootparentId, propertyId) => dispatch => {
	dispatch({type: DELETE_PROPERTY_EVENT, rootparentId: rootparentId, propertyId: propertyId})
}

export const deleteExtractProperty = (extractId, propertyId) => dispatch => {

	let property = store.getState().properties[extractId][propertyId]

	if (property.subType === 'object') {
		dispatch(resetCompositeProperty(extractId, property))
	}
							
	dispatch({type: DELETE_PROPERTY_EVENT, rootparentId: extractId, propertyId: propertyId})
}

export const updateProperty = (rootparentId, property) => dispatch => {
	dispatch({type: UPDATE_PROPERTY_EVENT, rootparentId: rootparentId, property: property})
}

export const moveProperty = (rootparentId, fromIndex, toIndex) => dispatch => {
	dispatch({type: MOVE_PROPERTY_EVENT, rootparentId: rootparentId, fromIndex: fromIndex, toIndex: toIndex})
}


export const addCompositeProperty = (rootparentId, parentId, property) => dispatch => {
	property.parentpropertyId = parentId
	dispatch({type: ADD_COMPOSITE_PROPERTY_EVENT, rootparentId: rootparentId, parentId: parentId, property: property})
}

export const moveCompositeProperty = (parentId, propertyId, fromIndex, toIndex) => dispatch => {
	dispatch({type: MOVE_COMPOSITE_PROPERTY_EVENT, parentId: parentId, propertyId: propertyId, fromIndex: fromIndex, toIndex: toIndex})
}

export const moveToCompositeProperty = (rootparentId, newparentId, propertyId) => dispatch => {
	dispatch({type: MOVE_TO_COMPOSITE_PROPERTY_EVENT, rootparentId: rootparentId, newparentId: newparentId, propertyId: propertyId})
}

export const deleteCompositeProperty = (rootparentId, parentId, propertyId) => dispatch => {
	dispatch({type: DELETE_COMPOSITE_PROPERTY_EVENT, rootparentId: rootparentId, parentId: parentId, propertyId: propertyId})
}

export const resetCompositeProperty = (rootparentId, property) => dispatch => {
	dispatch({type: RESET_COMPOSITE_PROPERTY_EVENT, rootparentId: rootparentId, property: property})
}

export const setNewPropertyIds = (properties) => {
	for (let i=0; properties && i<properties.length; ++i) {
		properties[i].propertyId = UUID.genV4().toString()
		setNewPropertyIds(properties[i].properties)
	}
}

export const changePropertyType = (parentId, orgProperties, property, newType) => async dispatch => {

	let oldDisplayName = property.name
	
	let dnProps = denormalize(Object.keys(orgProperties), [propertySchema], {properties: orgProperties});

	let index = dnProps.findIndex(prop => prop.subType === newType)

	let newProp = cloneDeep(dnProps[index])
	newProp.propertyId = property.propertyId
	newProp.name = oldDisplayName
	if (newProp.name) {
		newProp.pname = newProp.name?.toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
	}
	newProp.extractionRule = cloneDeep(property.extractionRule)

//	if (newProp.subType === 'attachment' && newProp.properties && newProp.properties[0] && newProp.properties[1]) {
//		newProp.properties[0].pname = i18.t(newProp.properties[0].subType).toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
//		newProp.properties[1].pname = i18.t(newProp.properties[1].subType).toLowerCase().replace(/[^A-Z0-9]/ig, "_").replace(/(^[^A-Z_])/ig, "_$1")
//	}

	setNewPropertyIds(newProp.properties)
	
	let propertydata = normalize([newProp], [propertySchema])

	dispatch({type: CHANGE_PROPERTY_TYPE_EVENT, parentId: parentId, properties: propertydata.entities.properties})
}

const getSubProperties = (properties, propertyId) => {
	if (!properties || !propertyId) return []
	
	let property = properties[propertyId]
	let subprops = property && property.properties ? property.properties : []
	for (let i=0; property && property.properties && i<property.properties.length; ++i) {
		subprops = [...subprops, ...getSubProperties(properties, property.properties[i])]
	}
	return subprops
}


const initialState = {}

const properties = (state=initialState, action) => {
	
	switch (action.type) {

// Property Events

		case ADD_PROPERTY_EVENT:
			state = {...state}
			if (!state[action.rootparentId]) {state[action.rootparentId] = {}}
			state[action.rootparentId][action.property.propertyId] = action.property
			break

		case WORKFLOW_ADD_PROPERTY:
			state = {...state,
				[action.workflowId]: {...state[action.workflowId], ...action.properties}
			}
			break

		case UPDATE_PROPERTY_EVENT:
			state = {...state}
			state[action.rootparentId][action.property.propertyId] = action.property
			state[action.rootparentId][action.property.propertyId].modified = Date.now()
			break

		case DELETE_PROPERTY_EVENT: {
			state = {...state}
			let subprops = getSubProperties(state[action.rootparentId], action.propertyId)
			for (let i=0; i<subprops.length; ++i) {
				delete state[action.rootparentId][subprops[i]]
			}
			delete state[action.rootparentId][action.propertyId]
		} break

		case CHANGE_PROPERTY_TYPE_EVENT:
			state = {...state,
				[action.parentId]: {...state[action.parentId], ...action.properties}
			}
			break

		case ADD_COMPOSITE_PROPERTY_EVENT:
			state = {...state}
			state[action.rootparentId][action.property.propertyId] = action.property
			if (!state[action.rootparentId][action.parentId].properties) state[action.rootparentId][action.parentId].properties = []
			if (state[action.rootparentId][action.parentId].properties.indexOf(action.property.propertyId) === -1) {
				state[action.rootparentId][action.parentId].properties.push(action.property.propertyId)
			}
			state[action.rootparentId][action.parentId].modified = Date.now()
			break

		case DELETE_COMPOSITE_PROPERTY_EVENT: {
			state = {...state}
			let subprops = getSubProperties(state[action.rootparentId], action.propertyId)
			for (let i=0; i<subprops.length; ++i) {
				delete state[action.rootparentId][subprops[i]]
			}
			delete state[action.rootparentId][action.propertyId]
			let index = state[action.rootparentId][action.parentId].properties.findIndex(propertyId => propertyId === action.propertyId)
			state[action.rootparentId][action.parentId].properties.splice(index, 1)
			state[action.rootparentId][action.parentId].modified = Date.now()
		} break

		case MOVE_COMPOSITE_PROPERTY_EVENT:
			state = {...state}
			state[action.parentId][action.propertyId] = cloneDeep(state[action.parentId][action.propertyId])
			// If this is an attachment - we need to increment the to/from index to account for hidden docref property at index 0
			if (state[action.parentId][action.propertyId].subType === 'attachment') {
				action.fromIndex++
				action.toIndex++
			}
			state[action.parentId][action.propertyId].properties.splice(action.toIndex, 0, state[action.parentId][action.propertyId].properties.splice(action.fromIndex, 1)[0])
			break
			
		case MOVE_TO_COMPOSITE_PROPERTY_EVENT:
			state = {...state}

			// If this property was a child of another composite property - remove it from there
			let keys = Object.keys(state[action.rootparentId])
			for (const propId of keys) {
				let prop = state[action.rootparentId][propId]
				let index = prop.properies ? prop.properties.indexOf(action.propertyId) : -1
				if (index !== -1) prop.properties.splice(index,1)
			}			

			if (state[action.rootparentId][action.newparentId].properties.indexOf(action.propertyId) === -1) {
				state[action.rootparentId][action.newparentId].properties.push(action.propertyId)
			}
			
			state[action.rootparentId][action.propertyId].parentpropertyId = action.newparentId
			break
		
		case RESET_COMPOSITE_PROPERTY_EVENT: {
			if (action.property && action.property.properties) {
				if (action.property.parentpropertyId) {
					for (const propertyId of action.property.properties) {
						if (state[action.rootparentId][action.property.parentpropertyId].properties.indexOf(propertyId) === -1) {
							state[action.rootparentId][action.property.parentpropertyId].properties.push(propertyId)
						}
					}
				}
				state[action.rootparentId][action.property.propertyId].properties = []
				state[action.rootparentId][action.property.propertyId].extractionRule.value = null
			}
		} break

				
// Workflow Events

		case RECEIVE_PROPERTIES:
		case RECEIVE_WORKFLOW:
		case RECEIVE_WORKFLOWS:
		case RECEIVE_ORGS:
		case RECEIVE_ME:
		case CREATE_LAUNCH_ASSIGNMENT:
		case RECEIVE_ASSIGNMENT:
		case RECEIVE_ASSIGNMENTS:
		case RECEIVE_EXTRACT:
		case RECEIVE_EXTRACTS:
		case RECEIVE_DOCUMENT:
		case RECEIVE_DOCUMENTS:
			state = {...state, ...action.properties}		// TBD - should this be a merge?
			break


		default:
			break
			
	}
	
	return state
}

export default properties