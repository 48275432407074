import React, { useState } from 'react'
import { Grid, ListItem, Typography } from '@mui/material'
import { InsertDriveFile, Image, PictureAsPdf } from '@mui/icons-material'


const DocumentListItem = ({ navigation, doc, documents }) => {

//	console.log('DocumentListItem')

	if (!doc) return null

	return (
		<ListItem onClick={()=>navigation.navigate('document', {documentId: doc.documentId})} button>
			<Grid container direction='row' >
			
				{ doc?.content?.mimeType.startsWith('image') ?
					<Image style={{ height: 20, width: 20 }} />
				: doc?.content?.mimeType.includes('pdf') ?
					<PictureAsPdf style={{ height: 20, width: 20 }} />
				: doc?.created ?
					<InsertDriveFile style={{ height: 20, width: 20 }} />
				: null }
				
				<Typography noWrap>{`${documents[doc.documentId]?.name ? documents[doc.documentId]?.name : '<unknown>'}`}</Typography>
				
			</Grid>
		</ListItem>
	)
}

export default DocumentListItem
